import React from 'react';
import Order from 'App/resources/Model/Order';
import RequestBuilder from 'Resources/RequestBuilder';
import OrderResource from 'App/resources/Order';
import OrderAttributes from 'App/babel/attributes/Order';
import ComparisonTypes from 'Resources/ComparisonTypes';
import SimpleListView from '../../SimpleListView';
import moment from 'moment';
import { ContentPageProps } from '../../ClientCardContent';
import { Flex, Headline, Icon, Row, Tooltip } from '@upsales/components';
import T, { useTranslation } from 'Components/Helpers/translate';
import { PrimaryButton } from '@upsales/components/Buttons';
import Attribute, { Type } from 'App/babel/attributes/Attribute';
import { useSelector } from 'App/components/hooks';
import { InlineDeleteButton } from '../../SimpleListView/SimpleListView';
import openModal from 'App/services/Modal';

const COLUMNS = ['date', 'description', 'contact', 'value', 'stage', 'user', 'clientConnection', 'tools'];

const getData = (
	rb: RequestBuilder,
	clientId: number
): Promise<{
	data: Order[];
	metadata: {
		total: number;
	};
}> => {
	rb.addFilter(OrderAttributes.probability, ComparisonTypes.NotEquals, 100);
	const clientOr = rb.orBuilder();
	clientOr.next();
	clientOr.addFilter(OrderAttributes.account, ComparisonTypes.Equals, clientId);
	clientOr.next();
	clientOr.addFilter(OrderAttributes.clientConnection, ComparisonTypes.Equals, clientId);
	clientOr.done();

	return OrderResource.find(rb.build());
};

const generateSubHeader = (item: Order, sorting: string) => {
	switch (sorting) {
		case 'date':
			return moment(item.date).year().toString();
		case 'user.name':
			return item.user.name;
		case 'stage.id':
			return item.stage.name;
	}
	return '';
};

const onRowClick =
	({ id }: Order) =>
	() => {
		Tools.$upModal.open('editOrder', { id, type: 'opportunity' });
	};

const onDelete = (item: Order) => {
	return OrderResource.delete(item.id);
};

const ClientConnection = ({ item }: { item: Order }) => {
	const clientConnectionName = useSelector(({ App }) => App.metadata?.params.clientOrderRelation || '');

	if (!item.clientConnection || item.clientConnection.id !== item.client.id) {
		return null;
	}

	return (
		<Tooltip title={`${item.client.name} ${T('default.is').toLowerCase()} ${clientConnectionName.toLowerCase()}`}>
			<Icon name="sitemap" />
		</Tooltip>
	);
};

const CreateDocumentButton = ({ item }: { item: Order }) => {
	const templates = useSelector(({ App }) => App.documentTemplates['order'] || []);

	return (
		<Tooltip title={T('default.createDocument')} position="left">
			<Icon
				color="grey-10"
				space="plm prm ptm pbm"
				name="file-o"
				onClick={e => {
					e.stopPropagation();
					if (Tools.FeatureHelper.hasSoftDeployAccess('CREATE_DOCUMENT_REACT')) {
						openModal('CreateDocumentModal', {
							type: 'order',
							id: item.id,
							accountId: item.client.id,
							templates
						});
					} else {
						Tools.$upModal.open('createDocument', {
							type: 'order',
							id: item.id,
							accountId: item.client.id,
							templates
						});
					}
				}}
			/>
		</Tooltip>
	);
};

const customRenders = {
	clientConnection: item => <ClientConnection item={item} />,
	tools: (item, provided) => (
		<Flex justifyContent="end" alignItems="center">
			<CreateDocumentButton item={item} />
			<InlineDeleteButton
				deleteFn={onDelete}
				entityName={'default.opportunity'}
				isDeletable={() => true}
				item={item}
				provided={provided}
			/>
		</Flex>
	)
} satisfies GetProp<typeof SimpleListView<Order>, 'customColumnRenders'>;

const attributes = {
	...OrderAttributes,
	contact: {
		...OrderAttributes.contact,
		placeholder: 'default.noContact'
	},
	clientConnection: Attribute({
		title: '',
		type: Type.String,
		field: ''
	}),
	tools: Attribute({
		title: '',
		type: Type.String,
		field: ''
	})
};

const ClientCardOpportunities = ({ client }: ContentPageProps) => {
	const { t } = useTranslation();
	return (
		<SimpleListView<Order>
			getData={(rb: RequestBuilder) => getData(rb, client.id)}
			columns={COLUMNS}
			subHeaderFn={generateSubHeader}
			renderHeader={() => (
				<Row align="center" className="SimpleListView__header">
					<Headline space="plm" size="sm">
						{t('default.opportunities')}
					</Headline>
					<div className="FlexPadder" />
					<PrimaryButton
						onClick={() => {
							Tools.$upModal.open('editOrder', {
								type: 'opportunity',
								clientId: client?.id
							});
						}}
						size="sm"
					>
						<Icon name="plus" space="mrs" />
						{t('default.createOpportunity')}
					</PrimaryButton>
				</Row>
			)}
			attributes={attributes}
			rowStyles={{
				inactive: item => item.probability === 0
			}}
			customColumnRenders={customRenders}
			onRowClick={onRowClick}
			broadcastType={['order', 'opportunity']}
			initialSorting={[{ attribute: 'date', ascending: false }]}
			tableLimitOptions={[25]}
		/>
	);
};

export default ClientCardOpportunities;
