import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Bem from '@upsales/components/Utils/bemClass';
import { Text, Button, Row, Icon, Input, Progress, Label } from '@upsales/components';
import { columnConfigs, saveColumn, deleteColumn } from 'Store/reducers/AdminEditSalesboardReducer';
import { makeCancelable } from '../../../helpers/promise';
import StackedDateInput from '../../Inputs/StackedDateInput/StackedDateInput';

import './EditSalesboardColumn.scss';
import { openFileBrowserModal } from 'Components/FileBrowser';

const TITLE_MAX_LENGTH = 64;

const mapStateToProps = () => ({});
const mapDispatchToProps = { saveColumn, deleteColumn };

const getFilterItems = (filters, config) => {
	const filter = _.find(filters, { field: config.field });

	if (!filter) {
		return null;
	}

	const valueArray = Array.isArray(filter.value) ? filter.value : [filter.value];
	const data = config.data();
	const isDateFilter = config.filterName === 'Date';

	const mappedValues = valueArray.map(value => {
		const idValue = value && value.preset ? value.preset : value;
		const found = _.find(data, { id: idValue });
		return found
			? found
			: { id: idValue, name: Tools.$translate(isDateFilter ? `date.${idValue}` : 'default.noName') };
	});

	return config.multiple ? mappedValues : mappedValues[0];
};

const findSelectedOption = (options, id) => {
	return _.find(options, { id }) || null;
};

const filterMatcher = (term, undef, item) => {
	const name = item && item.name ? item.name : '';
	return name.toLowerCase().indexOf(term.toLowerCase()) !== -1;
};

class EditSalesboardColumn extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			type: t('default.type'),
			sorting: t('default.sorting'),
			save: t('default.done'),
			deleteColumn: t('admin.salesboard.deleteColumn'),
			name: t('default.name'),
			noTimePeriod: t('date.noTimePeriod'),
			addSorting: t('admin.salesboard.addSorting'),
			abort: t('default.abort'),
			selectImage: t('mail.selectImage')
		};

		this.selectableTypes = [
			{ id: 'order', name: t('default.order') },
			{ id: 'opportunity', name: t('default.opportunity') },
			{ id: 'appointment', name: t('default.appointment') }
		];

		const { column } = this.props;

		this.state = {
			column: _.cloneDeep(column),
			selectSortOpen: false
		};

		this.updateTitle = this.updateTitle.bind(this);
		this.updateFilter = this.updateFilter.bind(this);
		this.updateDateFilter = this.updateDateFilter.bind(this);
		this.updateSorting = this.updateSorting.bind(this);
		this.toggleSortOrder = this.toggleSortOrder.bind(this);
		this.removeSorting = this.removeSorting.bind(this);
		this.selectBackgroundImage = this.selectBackgroundImage.bind(this);
		this.updateType = this.updateType.bind(this);
		this.deleteColumn = this.deleteColumn.bind(this);
		this.saveColumn = this.saveColumn.bind(this);

		this.setType(column.type);
	}

	setType(type) {
		this.config = columnConfigs[type];
		this.filterData = this.config.filter.data();
		this.dateFilterData = this.config.dateFilter.data();
		this.sortData = this.config.getSortable();
	}

	updateType(event) {
		const value = event.target.value;
		this.setType(value); // does not trigger a rerender
		/* setTimeout so that the select2 is not unmounted before it has finished all its internal logic */
		setTimeout(() => this.setState({ column: { ...this.state.column, sorting: [], filters: [], type: value } }), 0);
	}

	updateTitle(event) {
		const value = event.target.value;
		this.setState({ column: { ...this.state.column, title: value } });
	}

	updateFilter(event) {
		const column = this.state.column;
		const field = this.config.filter.field;

		const clonedFilters = [...column.filters];

		const filter = _.find(clonedFilters, { field });
		const { added, removed } = event.target;
		let value = filter?.value || [];
		if (added && !value.includes(added.id)) {
			value.push(added.id);
		} else if (removed) {
			value = value.filter(v => v !== removed.id);
		}

		if (filter) {
			filter.value = value;
		} else {
			clonedFilters.push({ comparisonType: 'Equals', field, value, filterName: this.config.filter.filterName });
		}

		this.setState({ column: { ...column, filters: clonedFilters } });
	}

	updateDateFilter({ value }) {
		const column = this.state.column;
		const field = this.config.dateFilter.field;
		const hasValue = value.preset !== 'whenever';

		const clonedFilters = hasValue
			? [...column.filters]
			: _.filter(column.filters, filter => filter.field !== field);

		if (hasValue) {
			const filter = _.find(clonedFilters, { field });

			if (filter) {
				filter.value = value;
			} else {
				clonedFilters.push({
					comparisonType: 'Equals',
					field,
					value,
					filterName: this.config.dateFilter.filterName
				});
			}
		}

		this.setState({ column: { ...column, filters: clonedFilters } });
	}

	setSelectSortOpen(value) {
		/* setTimeout so that the select2 is not unmounted before it has finished all its internal logic */
		setTimeout(() => this.setState({ selectSortOpen: value }), 0);
	}

	updateSorting(event) {
		const value = event.target.value;
		/* setTimeout so that the select2 is not unmounted before it has finished all its internal logic */
		setTimeout(
			() => this.setState({ column: { ...this.state.column, sorting: [{ attribute: value, ascending: true }] } }),
			0
		);
	}

	toggleSortOrder() {
		const column = this.state.column;

		if (column.sorting && column.sorting.length) {
			const clonedSorting = [...column.sorting];
			clonedSorting[0].ascending = !clonedSorting[0].ascending;
			this.setState({ column: { ...column, sorting: clonedSorting } });
		}
	}

	removeSorting() {
		this.setState({ column: { ...this.state.column, sorting: [] } });
	}

	selectBackgroundImage() {
		const promise = openFileBrowserModal({
			public: true,
			multiple: false,
			types: ['image']
		});

		this.cancelablePromise = makeCancelable(promise);
		this.cancelablePromise.promise
			.then(response => {
				const url = _.get(response, '[0].$$publicUrl');
				if (url) {
					this.setState({ column: { ...this.state.column, image: url } });
				}
			})
			.catch(error => {
				if (
					!error.isCanceled &&
					/* You get an SynteticEvent if you cancel with the x button */ !error.isDefaultPrevented
				) {
					console.error(error);
				}
			});
	}

	componentWillUnmount() {
		if (this.cancelablePromise) {
			this.cancelablePromise.cancel();
		}
	}

	deleteColumn() {
		const { deleteColumn, column } = this.props;
		deleteColumn(column);
	}

	saveColumn() {
		const { saveColumn } = this.props;
		const clone = { ...this.state.column };
		delete clone.isNew;

		saveColumn(clone);
	}

	stopEdit = () => {
		const { column, stopEdit } = this.props;
		stopEdit(column.$id);
	};

	render() {
		const column = this.state.column;
		const lang = this.lang;
		const t = Tools.$translate;

		const config = this.config;

		const EditSalesboardColumnBem = new Bem('EditSalesboardColumn');
		const percentage = Math.min((column.title.length / TITLE_MAX_LENGTH) * 100, 100);
		const titleToLong = column.title.length > TITLE_MAX_LENGTH;
		const selectedFilterItems = getFilterItems(column.filters, config.filter);
		const selectedDateFilterItems = _.find(column.filters, { field: config.dateFilter.field }) || {
			value: { preset: 'whenever' }
		};
		const selectedSorting =
			(column.sorting &&
				column.sorting.length &&
				_.find(this.sortData, sort => sort.id === column.sorting[0].attribute)) ||
			null;
		const imageSelectStyle = column.image ? { backgroundImage: `url(${column.image})` } : {};
		// Order and opportunity columns need to have atleast one stage selected
		const saveDisabled =
			titleToLong ||
			!column.title ||
			(column.type !== 'appointment' && (!selectedFilterItems || !selectedFilterItems.length));

		return (
			/* key={column.type} makes the whole shite remount, which is needed for the selec2s to get the new data */
			<div className={EditSalesboardColumnBem.b()} key={column.type}>
				<Row>
					<Label>{lang.type}</Label>
				</Row>
				<Row className="margin-bottom">
					<ReactTemplates.INPUTS.upSelect
						onChange={this.updateType}
						options={{
							minimumResultsForSearch: -1,
							allowClear: false
						}}
						defaultValue={findSelectedOption(this.selectableTypes, column.type)}
						className="form-control"
						data={this.selectableTypes}
					/>
				</Row>
				<Row className="EditSalesboardColumn__title">
					<Label required={true}>{lang.name}</Label>
					<Progress
						percentage={percentage}
						strokeWidth={10}
						hideText
						state={titleToLong ? 'error' : undefined}
					/>
				</Row>
				<Row className="margin-bottom" direction="column">
					<Input value={column.title} onChange={this.updateTitle} />
				</Row>
				<Row>
					<Label required={column.type !== 'appointment'}>{t(config.filter.title)}</Label>
				</Row>
				<Row className="margin-bottom">
					<ReactTemplates.INPUTS.upSelect
						storeIdInState={true}
						onChange={this.updateFilter}
						multiple={config.filter.multiple || false}
						defaultValue={selectedFilterItems}
						formatResult={(stage, container, query, escape) =>
							column.type !== 'appointment'
								? `<div>${escape(stage.name)}<span class="pull-right grey">${escape(
										stage.probability
								  )}%</span></div>`
								: escape(stage.name)
						}
						className="form-control"
						data={this.filterData}
						matcher={filterMatcher}
					/>
				</Row>
				<Row>
					<Label>{t(config.dateFilter.title)}</Label>
				</Row>
				<Row className="margin-bottom">
					<StackedDateInput
						config={{ presets: this.dateFilterData }}
						filter={selectedDateFilterItems}
						onChange={this.updateDateFilter}
					/>
				</Row>
				{selectedSorting ? (
					<div style={{ marginBottom: 28 }}>
						<Row style={{ position: 'relative' }} key="title">
							<Label>{lang.sorting}</Label>
							<Button
								className={EditSalesboardColumnBem.elem('remove-sort-button').b()}
								onClick={this.removeSorting}
								type="link"
							>
								<Icon name="times" />
							</Button>
						</Row>
						<Row key="value">
							<Button
								className={EditSalesboardColumnBem.elem('toggle-sortdirection-button').b()}
								onClick={this.toggleSortOrder}
								type="link"
							>
								{selectedSorting.name}{' '}
								<Icon name={column.sorting[0].ascending ? 'sort-numeric-asc' : 'sort-numeric-desc'} />
							</Button>
						</Row>
					</div>
				) : (
					<Row style={{ marginBottom: 40 }}>
						{this.state.selectSortOpen ? (
							<div className={EditSalesboardColumnBem.elem('sorting-select-wrap').b()}>
								<ReactTemplates.INPUTS.upSelect
									options={{
										placeholder: lang.addSorting,
										minimumResultsForSearch: -1,
										allowClear: false
									}}
									onChange={this.updateSorting}
									defaultValue={null}
									className="form-control"
									data={this.sortData}
									autoOpen={true}
									eventListeners={{
										'select2-close': () => this.setSelectSortOpen(false)
									}}
								/>
							</div>
						) : (
							<Button
								className={EditSalesboardColumnBem.elem('choose-sort-button').b()}
								style={{ paddingLeft: 0 }}
								onClick={() => this.setSelectSortOpen(true)}
								type="link"
							>
								<Icon name="plus" /> {lang.addSorting}
							</Button>
						)}
					</Row>
				)}
				<Row>
					<div
						style={imageSelectStyle}
						onClick={this.selectBackgroundImage}
						className={EditSalesboardColumnBem.elem('image-select').b()}
					>
						{column.image ? <div className={EditSalesboardColumnBem.elem('overlay').b()} /> : null}
						<div className={EditSalesboardColumnBem.elem('image-select-icon-row').b()}>
							<Icon color={column.image ? 'white' : 'bright-blue'} name="plus-circle" />
						</div>
						<div className={EditSalesboardColumnBem.elem('image-select-text-row').b()}>
							<Text color={column.image ? 'white' : 'bright-blue'}>{lang.selectImage}</Text>
						</div>
					</div>
				</Row>
				{column.isNew ? (
					<Row>
						<Button
							className={EditSalesboardColumnBem.elem('save-button').b()}
							onClick={this.saveColumn}
							disabled={saveDisabled}
						>
							{lang.save}
						</Button>
						<Button
							className={EditSalesboardColumnBem.elem('abort-button').b()}
							onClick={this.deleteColumn}
							type="link"
							color="grey"
						>
							{lang.abort}
						</Button>
					</Row>
				) : (
					<Row>
						<Button
							className={EditSalesboardColumnBem.elem('save-button').b()}
							onClick={this.saveColumn}
							disabled={saveDisabled}
						>
							{lang.save}
						</Button>
						<Button
							className={EditSalesboardColumnBem.elem('abort-button').b()}
							onClick={this.stopEdit}
							type="link"
							color="grey"
						>
							{lang.abort}
						</Button>
						<Button
							className={EditSalesboardColumnBem.elem('delete-button').b()}
							onClick={this.deleteColumn}
							type="link"
							color="grey"
						>
							<Icon style={{ marginRight: 5 }} name="trash" />
							{lang.deleteColumn}
						</Button>
					</Row>
				)}
			</div>
		);
	}
}

EditSalesboardColumn.propTypes = {
	saveColumn: PropTypes.func,
	deleteColumn: PropTypes.func,
	stopEdit: PropTypes.func,
	column: PropTypes.object
};

export const detached = EditSalesboardColumn;
const Component = connect(mapStateToProps, mapDispatchToProps)(EditSalesboardColumn);
export default Component;
