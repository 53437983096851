import React from 'react';
import PropTypes from 'prop-types';
import Bem from '@upsales/components/Utils/bemClass';

import StepTypes from './StepTypes';
import WistiaVideo from '../WistiaVideo';
import ImportApp from './ImportApp';

import { connect } from 'react-redux';
import { Button, Icon, Text, Title, Loader, Row } from '@upsales/components';
import {
	goFullScreen,
	navigateStep,
	navigateNext,
	goBackToMainStep,
	init,
	startOnboarding,
	skipStep,
	toggleFullscreen
} from 'Store/reducers/OnboardingReducer';

import './Onboarding.scss';
import { openGenericModal } from 'App/components/GenericModal/GenericModal';

const mapStateToProps = state => ({
	onboarding: state.Onboarding.onboarding,
	lastUpdate: state.Onboarding.lastUpdate,
	currentStepId: state.Onboarding.currentStepId,
	isFullscreen: state.Onboarding.fullscreen,
	isSubStepActive: state.Onboarding.isSubStepActive,
	currentSubstepId: state.Onboarding.currentSubstepId,
	currentStepType: state.Onboarding.currentStepType,
	currentStepComponent: state.Onboarding.currentStepComponent,
	hasStarted: state.Onboarding.hasStarted,
	customSteps: state.Onboarding.customSteps,
	currentCustomStepId: state.Onboarding.currentCustomStepId,
	importStepCompletion: state.Onboarding.importStepCompletion,
	companyProfile: state.Onboarding.companyProfile,
	activeImportApp: state.Onboarding.activeImportApp,
	isLastUnfinishedStep: state.Onboarding.isLastUnfinishedStep
});

const mapDispatchToProps = {
	goFullScreen,
	navigateStep,
	navigateNext,
	goBackToMainStep,
	init,
	startOnboarding,
	skipStep,
	toggleFullscreen
};

class Onboarding extends React.Component {
	constructor(props) {
		super(props);

		const T = Tools.$translate;
		this.lang = {
			skip: T('onboarding.skip'),
			back: T('back'),
			start: T('default.start'),
			notStartedTitle: T('onboarding.not.started.title'),
			notStartedDescription: T('onboarding.not.started.desc'),
			notStartedSkipText: T('onboarding.not.started.skiptext'),
			next: T('default.next'),
			readArticle: T('default.readArticle'),
			saveAndFinish: T('default.saveAndFinish')
		};

		this.getCurrentComponent = this.getCurrentComponent.bind(this);
	}

	componentDidMount() {
		const { init, goFullScreen, openFullScreen, initialSubstepId, initialImportApp, oauthCode } = this.props;

		if (openFullScreen) {
			goFullScreen();
		}

		init(Tools.AppService.getMetadata().onboarding, { initialImportApp, initialSubstepId, oauthCode });
	}

	getCurrentStep() {
		const { onboarding, customSteps, currentStepId, currentCustomStepId } = this.props;

		return currentCustomStepId !== null
			? customSteps.find(step => step.id === currentCustomStepId)
			: onboarding
			? onboarding.steps.find(step => step.id === currentStepId)
			: null;
	}

	renderHelperText(OnboardingClass) {
		const { currentStepId, goBackToMainStep, activeImportApp } = this.props;
		const currentStep = this.getCurrentStep(currentStepId);
		const isImportStep = currentStep.isImportStep;
		const T = Tools.$translate;

		return (
			<div className={OnboardingClass.elem('HelperText').mod({ 'import-step': isImportStep }).b()}>
				<Button key="button" size="lg" type="link" color="grey" onClick={() => goBackToMainStep()}>
					{this.lang.back}
				</Button>
				<Row>
					{isImportStep && <ImportApp size={60} hideName={true} app={activeImportApp} />}
					{currentStep.substepTitle && <Title key={'title'}>{T(currentStep.substepTitle)}</Title>}
				</Row>
				{currentStep.substepTitle && <Text key={'description'}>{T(currentStep.substepDescription)}</Text>}
			</div>
		);
	}

	isImportStepClickable(substepId, importStepCompletion) {
		// If this is true we have started an import
		if (importStepCompletion[2]) {
			return substepId === 3;
		} else {
			return substepId === 1 || (importStepCompletion[1] && substepId === 2) ? true : false;
		}
	}

	renderTrackItems() {
		const { onboarding, currentStepId, navigateStep, isSubStepActive, currentSubstepId, importStepCompletion } =
			this.props;
		const T = Tools.$translate;

		if (isSubStepActive) {
			const currentStep = this.getCurrentStep();

			return currentStep.substeps.map((substep, index) => {
				const SubStepClass = new Bem('Track__Substep');
				const substepIndex = index + 1;

				const completed = substep.isImportStep ? importStepCompletion[substep.id] : substep.completed;
				const subStepNumber = completed ? (
					<span className={SubStepClass.elem('Number').mod({ Completed: completed })}>
						<Icon name="check" />
					</span>
				) : (
					<span className={SubStepClass.elem('Number')}>{substepIndex}</span>
				);
				const clickable = currentStep.isImportStep
					? this.isImportStepClickable(substep.id, importStepCompletion)
					: true;
				const onClick = clickable ? () => navigateStep(substep) : () => {};

				return (
					<div
						key={substep.id}
						onClick={onClick}
						className={SubStepClass.mod({ Active: substep.id === currentSubstepId, Completed: completed })}
					>
						<Title bold={substep.id === currentSubstepId}>
							{subStepNumber}
							{T(substep.title)}
						</Title>
					</div>
				);
			});
		}

		if (!onboarding.steps) {
			return;
		}

		return onboarding.steps.map((step, i) => {
			const TrackClass = new Bem('Track');
			const index = i + 1;
			let allSubStepsIsCompleted = false;

			let stepNumber = step.completed ? (
				<span className={TrackClass.elem('Number').mod({ Completed: step.completed })}>
					<Icon name="check" />
				</span>
			) : (
				<span className={TrackClass.elem('Number')}>{index}</span>
			);

			if (!step.completed && step.hasSubSteps) {
				for (const substep of step.substeps) {
					if (substep.completed && substep.completed === true) {
						allSubStepsIsCompleted = true;
					} else {
						allSubStepsIsCompleted = false;
					}
				}

				if (allSubStepsIsCompleted === true) {
					stepNumber = (
						<span className={TrackClass.elem('Number').mod({ Completed: allSubStepsIsCompleted })}>
							<Icon name="check" />
						</span>
					);
				}
			}

			if (currentStepId === step.id) {
				return (
					<div key={step.title} className={TrackClass.mod({ Active: currentStepId === step.id }).b()}>
						<Title color="white">
							{stepNumber}
							{T(step.title)}
						</Title>
						<Text color="super-light-green">{T(step.description)}</Text>
						{step.videoThumbnail && (
							<div className={TrackClass.elem('Article')}>
								<img
									onClick={() => {
										openGenericModal({
											Component: WistiaVideo,
											video: step.videoLink,
											thumbnail: step.videoThumbnail,
											constantHeight: 500,
											/* Styles for angular modal */
											inlineStyle: {
												width: '890px',
												height: '500px',
												marginLeft: '-445px',
												top: '180px',
												zIndex: '9999'
											},
											/* Styles for react modal */
											style: {
												width: '890px',
												height: '500px'
											}
										});
									}}
									src={`${step.videoThumbnail}?image_play_button_size=1x&amp;image_crop_resized=130x80&amp;image_play_button=1&amp;image_play_button_color=54bbffe0`}
								/>
								<div className={TrackClass.elem('Article').elem('RightSide')}>
									<Title onClick={() => window.open(step.articleLink, '_blank')}>
										{T(step.articleTitle)}
									</Title>
									<a
										onClick={e => {
											e.preventDefault();
											window.open(step.articleLink, '_blank');
										}}
									>
										{this.lang.readArticle}
										<Icon name="external-link" />
									</a>
								</div>
							</div>
						)}
					</div>
				);
			}

			return (
				<div
					key={step.id}
					className={TrackClass.mod({
						Active: currentStepId === step.id,
						Completed: step.completed || allSubStepsIsCompleted
					}).b()}
					onClick={() => navigateStep(step)}
				>
					<Title>
						{stepNumber}
						{T(step.shadowTitle)}
					</Title>
				</div>
			);
		});
	}

	getCurrentComponent(currentStepType, currentStepComponent) {
		if (!currentStepComponent) {
			currentStepComponent = 'default';
		}

		if (currentStepType && currentStepComponent) {
			return StepTypes[currentStepType][currentStepComponent];
		}

		return Loader;
	}

	CheckForCustomButton(Component) {
		return (
			typeof _.get(Component, 'type.prototype.specialButton') === 'function' ||
			typeof _.get(Component, 'type.WrappedComponent.prototype.specialButton') === 'function'
		);
	}

	CheckForDisabledButton(Component) {
		if (Component.type && !Component.type.buttonDisabledCriterias) {
			return false;
		}

		const { companyProfile } = this.props;
		const boolArray = [];

		const shouldStillBeDisabled = array => {
			for (const entry of array) {
				if (!entry) {
					return true;
				}
			}

			return false;
		};

		for (const criteria of Component.type.buttonDisabledCriterias) {
			boolArray.push(!!_.get(companyProfile, criteria));
		}

		return shouldStillBeDisabled(boolArray);
	}

	CheckForCompleteOnNext(Component) {
		return (
			_.get(Component, 'type.setAsCompleteOnNext', false) ||
			_.get(Component, 'type.WrappedComponent.setAsCompleteOnNext', false)
		);
	}

	render() {
		const {
			hasStarted,
			toggleFullscreen,
			isFullscreen,
			isSubStepActive,
			navigateNext,
			skipStep,
			startOnboarding,
			currentStepType,
			currentStepComponent,
			isLastUnfinishedStep
		} = this.props;

		const OnboardingClass = new Bem('Onboarding');
		const StepWrapClass = new Bem('StepWrap');

		const currentStep = this.getCurrentStep();
		const CurrentComponent = this.getCurrentComponent(currentStepType, currentStepComponent);
		const currentComponent = <CurrentComponent key={`${currentStepType}-${currentStepComponent || 'default'}`} />;

		const hasCustomButton = this.CheckForCustomButton(currentComponent);
		const setAsComplete = this.CheckForCompleteOnNext(currentComponent);
		const disabledButton = this.CheckForDisabledButton(currentComponent);

		const nextButton = hasCustomButton ? null : (
			<Button
				block
				size="xl"
				disabled={disabledButton}
				onClick={() => navigateNext({ setAsComplete })}
				className={StepWrapClass.elem('Button')}
			>
				{isLastUnfinishedStep ? this.lang.saveAndFinish : this.lang.next}
			</Button>
		);

		if (!hasStarted) {
			return (
				<div className={OnboardingClass.mod({ isFullscreen: isFullscreen, startView: !hasStarted })}>
					<div className={OnboardingClass.elem('StartPageLeft')}>
						<Title color="white">{this.lang.notStartedTitle}</Title>
						<Text color="super-light-green">{this.lang.notStartedDescription}</Text>
						<Button size="xl" onClick={startOnboarding} block color="white">
							{this.lang.start}
						</Button>
						<Button size="lg" onClick={toggleFullscreen} type="link" color="super-light-green">
							{this.lang.notStartedSkipText}
						</Button>
					</div>
					<div className={OnboardingClass.elem('StartPageRight')}>
						<img
							onClick={() => {
								openGenericModal({
									Component: WistiaVideo,
									video: 'https://upsales.wistia.com/medias/77cef4i5kp?wvideo=77cef4i5kp',
									thumbnail:
										'https://embedwistia-a.akamaihd.net/deliveries/18ef3072efde65b0222a10026a90d3ac9ffec661.jpg',
									constantHeight: 500,
									/* Styles for angular modal */
									inlineStyle: {
										width: '890px',
										height: '500px',
										marginLeft: '-445px',
										top: '180px',
										zIndex: '9999'
									},
									/* Styles for react modal */
									style: {
										width: '890px',
										height: '500px'
									}
								});
							}}
							src={
								'https://embedwistia-a.akamaihd.net/deliveries/18ef3072efde65b0222a10026a90d3ac9ffec661.jpg?image_play_button_size=1x&amp;image_crop_resized=370x210&amp;image_play_button=1&amp;image_play_button_color=54bbffe0'
							}
						/>
					</div>
				</div>
			);
		}

		if (!currentStep) {
			return (
				<div className={OnboardingClass.mod({ isFullscreen: isFullscreen })}>
					<div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flex: 1 }}>
						<Loader />
					</div>
				</div>
			);
		}

		return (
			<div className={OnboardingClass.mod({ isFullscreen: isFullscreen })}>
				<div className={OnboardingClass.elem('RightNavigation').b()}>
					{currentStep && currentStep.isImportStep ? null : (
						<Button onClick={() => skipStep()} type="link" color="grey">
							{this.lang.skip}
						</Button>
					)}
					<Button id="expand-button" size="lg" type="link" color="grey" onClick={toggleFullscreen}>
						<Icon name={isFullscreen ? 'compress' : 'expand'} />
					</Button>
				</div>

				<div className={OnboardingClass.elem('Sidebar').mod({ Substep: isSubStepActive })}>
					{isSubStepActive && this.renderHelperText(OnboardingClass)}
					{this.renderTrackItems()}
				</div>

				<div className={OnboardingClass.elem('MainArea').mod({ Substep: isSubStepActive })}>
					<div className={StepWrapClass}>
						<div className={StepWrapClass.elem('Holder')}>
							<div className={StepWrapClass.elem('Component')}>{currentComponent}</div>
							{currentStep ? nextButton : null}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Onboarding.propTypes = {
	onboarding: PropTypes.object,
	goFullScreen: PropTypes.func,
	isFullscreen: PropTypes.bool,
	navigateStep: PropTypes.func,
	currentStepId: PropTypes.number,
	currentSubstepId: PropTypes.number,
	currentCustomStepId: PropTypes.number,
	isSubStepActive: PropTypes.bool,
	currentStepComponent: PropTypes.any,
	currentStepType: PropTypes.any,
	navigateNext: PropTypes.func,
	goBackToMainStep: PropTypes.func,
	openFullScreen: PropTypes.bool,
	hasStarted: PropTypes.bool,
	init: PropTypes.func,
	startOnboarding: PropTypes.func,
	skipInit: PropTypes.bool,
	customSteps: PropTypes.array,
	importStepCompletion: PropTypes.object,
	skipStep: PropTypes.func,
	companyProfile: PropTypes.object,
	initialImportApp: PropTypes.number,
	initialSubstepId: PropTypes.number,
	oauthCode: PropTypes.string,
	activeImportApp: PropTypes.object,
	isLastUnfinishedStep: PropTypes.bool,
	toggleFullscreen: PropTypes.func
};

export const detached = Onboarding;
const Component = connect(mapStateToProps, mapDispatchToProps)(Onboarding);
window.Onboarding = Component;
export default Component;
