import ClientCardOpportunities from 'App/pages/ClientCard/ContentPages/Opportunities/Opportunities';
import { Detached as OpportunityTab } from 'App/pages/OpportunityTab';

angular.module('domain.account').controller('AccountOpportunities', [
	'$scope',
	'$location',
	'$filter',
	'$stateParams',
	'User',
	'RequestBuilder',
	'Opportunity',
	'SaveInline',
	'$translate',
	'FixOrder',
	'AppService',
	'FilterHelper',
	'$state',
	'$upModal',
	'meta',
	function (
		$scope,
		$location,
		$filter,
		$stateParams,
		User,
		RequestBuilder,
		Opportunity,
		SaveInline,
		$translate,
		FixOrder,
		AppService,
		FilterHelper,
		$state,
		$upModal
	) {
		var AccountCtrl = $scope.AccountCtrl;

		AccountCtrl.useReactOpportunities = Tools.FeatureHelper.hasSoftDeployAccess(
			'ACCOUNT_CARD_OPPORTUNITIES_USE_REACT_TAB'
		);

		AccountCtrl.hasSplitButtonClientCard = Tools.FeatureHelper.hasSoftDeployAccess('SPLIT_BUTTON_CLIENT_CARD');
		AccountCtrl.hasSubaccounts = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
		AccountCtrl.hasSubaccountsV2 = Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2');
		AccountCtrl.useNewOpportunityTab = AccountCtrl.hasSubaccounts && AccountCtrl.hasSubaccountsV2;
		AccountCtrl.useReactOpportunities = AccountCtrl.useNewOpportunityTab ? true : AccountCtrl.useReactOpportunities;

		AccountCtrl.ClientCardOpportunities = ClientCardOpportunities;
		AccountCtrl.OpportunityTab = OpportunityTab;

		$scope.selectedGrouping = 0;
		$scope.tableLoading = true;

		var $date = $filter('date');
		var accountOpportunities;

		var deleted = function (e, deleted) {
			var index = _.findIndex(accountOpportunities, { id: deleted.id });

			if (index !== -1) {
				accountOpportunities.splice(index, 1);

				// If no more activities - go to dashboard
				if (!accountOpportunities.length) {
					$state.go('account.dashboard', { id: AccountCtrl.account.id });
				} else {
					$scope.groupChange();
				}
			}
		};

		var updated = function (e, updated) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(updated.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(updated.clientConnection)
			) {
				var opportunity = _.find(accountOpportunities, { id: updated.id });

				if (opportunity) {
					if (FilterHelper.match($scope.filter.q, updated, 'opportunity')) {
						angular.extend(opportunity, updated);
					} else {
						_.remove(accountOpportunities, opportunity);
					}
					$scope.groupChange();
				}
			}
		};

		var added = function (e, added) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(added.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(added.clientConnection)
			) {
				accountOpportunities.push(added);
				$scope.groupChange();
			}
		};

		$scope.createOpportunity = function () {
			var options = {
				customerId: AppService.getCustomerId(),
				clientId: AccountCtrl.account.id,
				type: 'opportunity'
			};
			$upModal.open('editOrder', options);
		};

		$scope.createActivity = function () {
			var options = {
				activity: {
					client: AccountCtrl.account
				}
			};

			$upModal.open('editActivity', options);
		};

		$scope.$on('opportunity.deleted', deleted);
		$scope.$on('order.deleted', deleted);

		$scope.$on('opportunity.added', added);
		$scope.$on('order.added', added);

		$scope.$on('opportunity.updated', updated);
		$scope.$on('order.updated', updated);

		$scope.$on('account.merged', function (e, res) {
			if (res.merged.id === AccountCtrl.account.id) {
				// Reload stuff
				$scope.getOpportunities();
			}
		});

		$scope.$on('account.merged', function (e, res) {
			if (res.merged.id === AccountCtrl.account.id) {
				// Reload stuff
				$scope.getOpportunities();
			}
		});

		$scope.groupable2 = {
			data: [
				{
					id: 1,
					hash: 'month',
					text: $translate.instant('date.month'),
					groupOn: function (obj) {
						return $date(obj.date, 'MMMM yyyy');
					}
				},
				{
					id: 2,
					hash: 'year',
					text: $translate.instant('date.year'),
					groupOn: function (obj) {
						return $date(obj.date, 'yyyy');
					}
				},
				{
					id: 3,
					hash: 'user',
					text: $translate.instant('default.user'),
					groupOn: 'user.id',
					textOn: 'user.name'
				},
				{
					id: 4,
					hash: 'stage',
					text: $translate.instant('default.stage'),
					groupOn: 'stage.id',
					textOn: 'stage.name'
				},
				{
					id: 5,
					hash: 'activeLost',
					text: $translate.instant('stage.activeLost'),
					groupOn: function (obj) {
						var found = _.find(AccountCtrl.orderStages, { id: obj.stage ? obj.stage.id : null });
						return found && found.probability > 0
							? $translate.instant('stage.active')
							: $translate.instant('stage.lost');
					}
				}
			],
			allowClear: true,
			placeholder: $translate.instant('default.groupBy')
		};

		// opportunity inline save
		$scope.saveInlineOpportunity = function (value, promise, field, opportunityId) {
			SaveInline(value, promise, field, opportunityId, 'Opportunity');
		};

		// open opportunity
		$scope.openOpportunity = function (opportunity) {
			$location.path($scope.customerId + '/opportunities/' + opportunity.id);
		};

		$scope.groupChange = function () {
			$scope.accountOpportunities = $filter('groupBy')(accountOpportunities, $scope.grouping);
			if (
				$scope.grouping &&
				$scope.grouping?.hash === 'activeLost' &&
				$scope.accountOpportunities &&
				$scope.accountOpportunities[0] &&
				$scope.accountOpportunities[0].items.length
			) {
				var stage = _.find(AccountCtrl.orderStages, { id: $scope.accountOpportunities[0].items[0].stage.id });
				if (stage && stage.probability === 0) {
					$scope.accountOpportunities.reverse();
				}
			}
		};

		$scope.getOpportunities = function () {
			// eslint-disable-next-line promise/catch-or-return
			Opportunity.customer($scope.customerId)
				.find($scope.filter)
				.then(
					function (response) {
						$scope.nrOfOpportunities = response.metadata.total;
						accountOpportunities = response.data;

						// sum order rows
						angular.forEach(accountOpportunities, function (opportunity) {
							FixOrder.print(opportunity);
						});
						$scope.groupChange();
						$scope.tableLoading = false;

						// Scroll to top
						angular.element(window).scrollTop(0);
					},
					function () {
						$scope.tableLoading = false;
					}
				); //.then(updateList);
		};

		var getGroupingFromHash = function () {
			var hash = $location.search();
			var groupBy = hash.groupBy ? hash.groupBy : 'activeLost';
			return _.find($scope.groupable2.data, { hash: groupBy });
		};

		var init = function () {
			var rb = new RequestBuilder();

			rb.addFilter(Opportunity.attr.probability, rb.comparisonTypes.NotEquals, 100);

			var clientOr = rb.orBuilder();
			clientOr.next();
			clientOr.addFilter(Opportunity.attr.account.attr.id, rb.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);
			clientOr.next();
			clientOr.addFilter(Opportunity.attr.clientConnection, rb.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);
			clientOr.done();

			rb.addSort(Opportunity.attr.date, false);
			rb.limit = 50;
			rb.offset = 0;

			$scope.filter = rb.build();

			$scope.grouping = getGroupingFromHash();
			$scope.getOpportunities();
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);
