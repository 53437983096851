import logError from 'App/babel/helpers/logError';
import openModal from 'App/services/Modal';

angular.module('domain.contact').controller('ContactFiles', [
	'$scope',
	'AppService',
	'File',
	'RequestBuilder',
	'$upModal',
	'Esign',
	'$translate',
	'$q',
	'$state',
	function ($scope, AppService, File, RequestBuilder, $upModal, Esign, $translate, $q, $state) {
		var ContactCtrl = $scope.ContactCtrl;
		var ContactFiles = this;
		var customerId;
		ContactFiles.esigns = [];
		ContactFiles.files = [];

		var goToDashboardIfNoData = function () {
			// If no more activities - go to dashboard
			if (!ContactFiles.files.length && !ContactFiles.esigns.length) {
				$state.go('contact.dashboard', { id: ContactCtrl.contact.id });
			}
		};

		$scope.$on('file.uploaded', function (e, file) {
			if (file.entity === File.entityTypes.CONTACT && file.entityId === ContactCtrl.contact.id) {
				ContactFiles.files.push(file);
			}
		});

		$scope.$on('file.deleted', function (e, file) {
			var index = _.findIndex(ContactFiles.files, { id: file.id });

			if (index !== -1) {
				ContactFiles.files.splice(index, 1);
				goToDashboardIfNoData();
			}
		});

		$scope.$on('esign.added', function (e, esign) {
			// If this dude was involved
			var foundContact = _.find(esign.involved, { contactId: ContactCtrl.contact.id });
			if (foundContact) {
				ContactFiles.esigns.push(esign);
				ContactFiles.esignsTotal++;
			}
		});

		$scope.$on('esign.deleted', function (e, deleted) {
			// If this dude was involved
			if (deleted.involved && _.find(deleted.involved, { contactId: ContactCtrl.contact.id })) {
				var index = _.findIndex(ContactFiles.esigns, { id: deleted.id });

				if (index !== -1) {
					ContactFiles.esigns.splice(index, 1);
					ContactFiles.esignsTotal--;
					goToDashboardIfNoData();
				}
			}
		});

		$scope.uploadFile = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_UPLOAD_FILE_MODAL')) {
				return openModal('UploadFileModal', { contactId: ContactCtrl.contact.id });
			}
			$upModal.open('uploadFile', {
				contactId: ContactCtrl.contact.id
			});
		};

		$scope.$on('esign.updated', function (e, esign) {
			// If this dude was involved
			var foundContact = _.find(esign.involved, { contactId: ContactCtrl.contact.id });
			if (foundContact) {
				var found = _.find(ContactFiles.esigns, { id: esign.id });

				if (found) {
					angular.extend(found, esign);
				}
			}
		});

		var preventEvent = function (e) {
			e.preventDefault();
			e.stopPropagation();
		};

		ContactFiles.removeFile = function (file, e) {
			preventEvent(e);

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					title: 'file.file',
					body: 'file.removeFileConfirm',
					onClose: confirmed => {
						if (confirmed) {
							// Delete file here
							File.customer(customerId).delete(file);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: 'file.removeFile',
					body: 'file.removeFileConfirm',
					resolveTrue: 'default.remove',
					icon: 'fa-warning'
				})
				.then(function () {
					// Delete file here
					File.customer(customerId).delete(file);
				});
		};

		ContactFiles.removeEsign = function (esign, e) {
			preventEvent(e);

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					title: 'default.eSign',
					body: 'esign.removeEsignConfirm',
					onClose: confirmed => {
						if (confirmed) {
							Esign.delete(esign);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: 'esign.removeEsign',
					body: 'esign.removeEsignConfirm',
					resolveTrue: 'default.remove',
					icon: 'fa-warning'
				})
				.then(function () {
					// Delete file here
					Esign.delete(esign);
				});
		};

		ContactFiles.getRejectedInitials = function (esign) {
			var rejectee = _.find(esign.involved, function (p) {
				return p.declineDate;
			});

			if (rejectee) {
				return (rejectee.fstname[0] || '') + (rejectee.sndname[0] || '');
			} else {
				return $translate.instant('default.someone').toLowerCase();
			}
		};

		ContactFiles.getEsignState = function (stateInt) {
			return Esign.state[stateInt];
		};

		ContactFiles.openEsign = function (esign) {
			return Esign.getDownloadUrl(esign);
		};

		ContactFiles.downloadFile = function (file, e) {
			preventEvent(e);

			File.download(file.id);
		};

		ContactFiles.openFile = function (id, e) {
			preventEvent(e);

			File.open(id);
		};

		var getFiles = function () {
			var filters = new RequestBuilder();

			filters.addFilter(File.attr.entityId, filters.comparisonTypes.Equals, ContactCtrl.contact.id);
			filters.addFilter(File.attr.entity, filters.comparisonTypes.Equals, File.entityTypes.CONTACT);
			filters.addSort(File.attr.uploadDate, true);

			return File.customer(customerId)
				.find(filters.build())
				.then(function (res) {
					ContactFiles.files = res.data;
					return res;
				});
		};

		var getEsign = function () {
			var esignFilter = new RequestBuilder();
			esignFilter.addSort(Esign.attr.sortDate, false);
			esignFilter.addFilter(
				Esign.attr.involved.attr.contactId,
				esignFilter.comparisonTypes.Equals,
				ContactCtrl.contact.id
			);

			return Esign.find(esignFilter.build()).then(function (res) {
				ContactFiles.esignsTotal = res.metadata.total;
				ContactFiles.esigns = res.data;
				return res;
			});
		};

		var getData = function () {
			var promises = {};
			ContactFiles.loading = true;

			if (ContactCtrl.hasDocumentFeature) {
				promises.files = getFiles();
			}

			if (ContactCtrl.hasEsign) {
				promises.esign = getEsign();
			}

			$q.all(promises)
				.then(function (res) {
					if (res.files && res.files.metadata) {
						ContactCtrl.filesTotal = res.files.metadata.total;
					} else {
						ContactCtrl.filesTotal = 0;
					}

					if (res.esign && res.esign.metadata) {
						ContactCtrl.filesTotal += res.esign.metadata.total;
					}

					ContactFiles.loading = false;
				})
				.catch(e => logError(e, 'Failed to load contactFiles'));
		};

		var init = function () {
			customerId = AppService.getCustomerId();

			getData();
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);
