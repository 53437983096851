import RequestBuilder, { comparisonTypes as ComparisonTypes } from 'Resources/RequestBuilder';
import { useCompanyGroupSelector } from '../CompanyGroupCard/Context/CompanyGroupContext';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import { useTranslation } from 'Components/Helpers/translate';
import ContactResource from 'App/resources/Contact';
import ClientResource from 'App/resources/Client';
import { useGetData } from 'App/components/hooks';
import React, { useEffect, useRef } from 'react';
import Client from 'App/resources/Model/Client';
import fetcher from './BoardMembers/fetcher';
import { openDrawer } from 'Services/Drawer';
import BoardMembers from './BoardMembers';
import ListTab from 'Components/ListTab';
import ContactList from './ContactList';
import CompanyList from '../CompanyList';
import logError from 'Helpers/logError';
import { getColumns, getFilterFn } from 'Components/ListTab/ListTabHelper';

const getSubaccountOrgNumbers = async (client: Pick<Client, 'id' | 'orgNo'>, isGroupBySubaccount: boolean) => {
	if (isGroupBySubaccount) {
		return [client.orgNo];
	}

	const rb = new RequestBuilder();

	rb.addFilter({ field: 'operationalAccount.id' }, ComparisonTypes.Equals, client.id);
	rb.addFilter({ field: 'orgNo' }, ComparisonTypes.NotEquals, null);
	rb.fields = ['orgNo'];

	const { data } = await ClientResource.find(rb.build());
	return [client.orgNo].concat(data.map(({ orgNo }) => orgNo)).filter(Boolean);
};

const getBoardMembersColumns = (isDrawer: boolean) => {
	const columns = ['name', 'clientName', 'role', 'memberSince', ''];
	return getColumns(columns, {}, isDrawer ? 'clientName' : undefined);
};

const getContactColumns = (isGroupBySubaccount: boolean, isDrawer: boolean) => {
	const columns = ['name', 'title', 'operationalAccount', 'history', 'email', 'phone', ''];
	const replacementMap = isGroupBySubaccount ? { operationalAccount: 'account' } : undefined;
	const removeColumn = isDrawer ? 'operationalAccount' : undefined;

	return getColumns(columns, replacementMap, removeColumn);
};

const getCompanyColumns = (isGroupBySubaccount: boolean) => {
	const columns = ['nameWithLocation', 'numberOfContactsTotal', 'salesHistory', 'lastMetSubaccount', 'users'];
	const replacementMap = isGroupBySubaccount
		? {
				numberOfContactsTotal: 'numberOfContacts',
				lastMetSubaccount: 'lastMet'
		  }
		: undefined;

	return getColumns(columns, replacementMap);
};

type Props = {
	orgNumbers: string[];
	numberOfContacts: number | null;
	isGroupBySubaccount?: boolean;
	addClientId?: (id: number) => void;
	prospectingId?: string;
	clientIds?: number[];
};

export const Detached = ({
	orgNumbers,
	addClientId,
	prospectingId,
	numberOfContacts,
	isGroupBySubaccount = false,
	clientIds: propsClientIds
}: Props) => {
	const { clientIds: stateClientIds } = useCompanyGroupSelector(({ clientIds }) => ({
		clientIds
	}));
	const clientIds = propsClientIds || stateClientIds;

	const { t } = useTranslation();
	const [showInactive, setShowInactive] = React.useState(false);
	const [contactCount, setContactCount] = React.useState(numberOfContacts);
	const isContactsTab = true;

	const contactReq = useRef<null | CancelablePromise<Awaited<ReturnType<typeof ContactResource.find>>>>(null);

	const {
		metadata: { total: totalBoardmembers }
	} = useGetData({
		fetcher,
		limit: 0,
		fetcherProps: { orgNumbers, prospectingId }
	});

	const filterFn = getFilterFn(!isGroupBySubaccount);

	useEffect(() => {
		if (!clientIds.length) {
			return;
		}
		const updateNumberOfContacts = () => {
			contactReq.current?.cancel();

			const rb = new RequestBuilder();
			filterFn(rb, clientIds);
			rb.limit = 0;

			contactReq.current = makeCancelable(ContactResource.find(rb.build()));
			contactReq.current.promise
				.then(({ metadata }) => {
					setContactCount(metadata.total);
				})
				.catch(e => logError(e, 'Failed to fetch contacts'));
		};

		updateNumberOfContacts();
		const listeners = [
			// f u elastic
			Tools.$rootScope.$on('contact.updated', () => setTimeout(() => updateNumberOfContacts(), 1000)),
			Tools.$rootScope.$on('contact.added', () => setTimeout(() => updateNumberOfContacts(), 1000)),
			Tools.$rootScope.$on('contact.deleted', () => setTimeout(() => updateNumberOfContacts(), 1000))
		];

		return () => {
			contactReq.current?.cancel();
			listeners.forEach(unsub => unsub());
		};
	}, [clientIds]);

	const lists = [
		{
			value: 'contacts',
			title: `${clientIds.length ? contactCount ?? 0 : 0} ${t('default.contacts')}`,
			component: (
				<ContactList
					getDataFilter={filterFn}
					columns={getContactColumns(isGroupBySubaccount, false)}
					clientIds={clientIds}
					showInactive={showInactive}
					setShowInactive={setShowInactive}
				/>
			)
		}
	];

	if (prospectingId && orgNumbers.length) {
		lists.push({
			value: 'boardMembers',
			title: `${totalBoardmembers ?? 0} ${t('companyGroup.boardMembers')}`,
			component: (
				<BoardMembers
					columns={getBoardMembersColumns(false)}
					prospectingId={prospectingId}
					addClientId={addClientId}
					orgNumbers={orgNumbers}
				/>
			)
		});
	}

	const onRowClick = async (account: Client) => {
		const lists = [
			{
				value: 'contacts',
				title: t('default.contacts'),
				component: (
					<ContactList
						getDataFilter={filterFn}
						columns={getContactColumns(isGroupBySubaccount, true)}
						clientIds={[account.id]}
						hideHeader
					/>
				)
			}
		];

		const prospectingId = account.prospectingId;
		if (prospectingId) {
			const orgNumbers = await getSubaccountOrgNumbers(account, isGroupBySubaccount);

			if (orgNumbers?.length > 0) {
				lists.push({
					value: 'boardMembers',
					title: t('companyGroup.boardMembers'),
					component: (
						<BoardMembers
							columns={getBoardMembersColumns(true)}
							prospectingId={prospectingId}
							addClientId={addClientId}
							orgNumbers={orgNumbers}
							hideHeader
						/>
					)
				});
			}
		}

		openDrawer('ListDrawer', { account, lists, isContactsTab: isContactsTab });
	};

	const groupList =
		clientIds.length > 1 ? (
			<CompanyList
				columns={getCompanyColumns(isGroupBySubaccount)}
				clientIds={clientIds}
				onRowClick={onRowClick}
				getDataFilter={(rb: RequestBuilder) => {
					if (!isGroupBySubaccount) {
						rb.addFilter({ field: 'operationalAccount.id' }, ComparisonTypes.Equals, null);
						rb.addFilter({ field: 'numberOfContactsTotal' }, ComparisonTypes.GreaterThan, 0);
					} else {
						rb.addFilter({ field: 'numberOfContacts' }, ComparisonTypes.GreaterThan, 0);
					}
				}}
			/>
		) : undefined;

	return (
		<ListTab
			lists={lists}
			title={t('companyGroup.showContacts')}
			isGroupBySubaccount={isGroupBySubaccount}
			groupList={groupList}
		/>
	);
};

const ContactsTab = ({ ...props }: Omit<Props, 'clientIds'>) => {
	const { clientIds } = useCompanyGroupSelector(({ clientIds }) => ({
		clientIds
	}));

	return <Detached {...props} clientIds={clientIds} />;
};

export default ContactsTab;
