import BemClass from '@upsales/components/Utils/bemClass';
import Client from 'App/resources/Model/Client';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import Prospecting from 'App/babel/resources/Prospecting';
import ProspectingClient from 'App/resources/Model/ProspectingClient';
import React, { useEffect, useState, useRef } from 'react';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import SalesHistory, { HistoryProperties } from 'App/components/SalesHistory/SalesHistory';
import T from 'Components/Helpers/translate';
import logError from 'Helpers/logError';
import {
	Link,
	Block,
	Flex,
	Loader,
	Title,
	Text,
	Tooltip,
	Progressbar,
	Icon,
	EllipsisTooltip
} from '@upsales/components';
import { SlideFade } from '@upsales/components/animations';
import { makeCancelable } from 'App/babel/helpers/promise';
import history from 'App/pages/routes/history';
import { useSoftDeployAccess } from 'App/components/hooks';
import { openDrawer } from 'Services/Drawer';
import { CreateRelationView } from 'Components/CreateRelation/CreateRelation';

import './CompanyGroup.scss';

function* getCompanyIterator(companies: ProspectingClient[]): Generator<ProspectingClient, void, unknown> {
	for (const company of companies) {
		yield company;

		for (const branch of company.branches) {
			yield branch;
		}

		yield* getCompanyIterator(company.children);
	}
}

const selectValue = (resultValue: Date | null | undefined | boolean, otherValue: Date | null | undefined | boolean) => {
	if (otherValue) {
		if (resultValue === null || resultValue === undefined) {
			return true;
		} else if (typeof otherValue === 'string' && typeof resultValue === 'boolean') {
			return true;
		} else if (otherValue! > resultValue!) {
			return true;
		}
	}

	return false;
};

const mergeHistoryProps = (result: HistoryProperties, other: HistoryProperties) => {
	if (selectValue(result.hasActivity, other.hasActivity)) result.hasActivity = other.hasActivity;
	if (selectValue(result.hadActivity, other.hadActivity)) result.hadActivity = other.hadActivity;
	if (selectValue(result.hasAppointment, other.hasAppointment)) result.hasAppointment = other.hasAppointment;
	if (selectValue(result.hadAppointment, other.hadAppointment)) result.hadAppointment = other.hadAppointment;
	if (selectValue(result.hasOpportunity, other.hasOpportunity)) result.hasOpportunity = other.hasOpportunity;
	if (selectValue(result.hadOpportunity, other.hadOpportunity)) result.hadOpportunity = other.hadOpportunity;
	if (selectValue(result.hasOrder, other.hasOrder)) result.hasOrder = other.hasOrder;
	if (selectValue(result.hadOrder, other.hadOrder)) result.hadOrder = other.hadOrder;
};

const getDefaultHistoryProps = (): HistoryProperties => ({
	hasActivity: null,
	hadActivity: null,
	hasAppointment: null,
	hadAppointment: null,
	hasOpportunity: null,
	hadOpportunity: null,
	hasOrder: null,
	hadOrder: null
});

const getCompanyGroupData = (companies: ProspectingClient[]) => {
	const historyProps = getDefaultHistoryProps();
	const clientIds: number[] = [];
	const customersNames: string[] = [];
	let size = 0;
	let numberOfCustomers = 0;

	for (const company of getCompanyIterator(companies)) {
		if (company.headquarters) {
			++size;

			const isCustomer =
				company.matchInUpsales?.journeyStep === 'customer' ||
				company.branches.some(branch => branch.matchInUpsales?.journeyStep === 'customer');

			if (isCustomer) {
				++numberOfCustomers;
				customersNames.push(company.name);
			}
		}

		if (company.matchInUpsales) {
			clientIds.push(company.matchInUpsales.id);
			mergeHistoryProps(historyProps, company.matchInUpsales);
		}
	}

	return { size, numberOfCustomers, clientIds, customersNames, historyProps };
};

const getProspectingGroup = (prospectingId: string) => {
	const rb = new RequestBuilder();
	rb.addFilter({ field: 'prospectingId' }, 'eq', prospectingId);
	rb.extraParams.push({
		key: 'country',
		value: Prospecting.getCountryFromProspectingId(prospectingId)
	});

	return makeCancelable<{ data: ProspectingClient[] }>(Prospecting.findGroupstructure(rb.build()));
};

const getTooltip = (customerNames: string[], hasProspectingPro: boolean) => {
	const tooltipHint = hasProspectingPro ? '' : T('account.companyGroup.buyAddonHint');

	if (customerNames.length === 0) {
		return tooltipHint;
	} else {
		return (
			T('account.companyGroup.customersInGroup') +
			'\n' +
			customerNames.join('\n') +
			(tooltipHint ? '\n\n(' + tooltipHint + ')' : '')
		);
	}
};

const hasParent = (client: Client) => {
	// In company database
	if (client.parent && client.parent.id !== 0) return true;

	// Creditsafe
	if (client.prospecting?.parent && client.prospecting?.parent?.prospectingId.startsWith('SE')) return true;

	// Soliditet
	if (client.soliditet?.profileData?.parentCompanyName && client.soliditet?.profileData?.parentCompanyDunsNumber)
		return true;

	return false;
};

// direct copy from ui/app/upsales/domain/account/controllers/account.js
const parentCompanyClick = (client: Client) => {
	// Go to existing parent
	if (client.parent) {
		return Tools.$state.go('account.dashboard', { id: client.parent.id });
	}

	// Check for company in db
	const prospectingId = client.prospecting?.parent?.prospectingId;
	const duns = client.soliditet?.profileData?.parentCompanyDunsNumber;
	const filters = new RequestBuilder();
	filters.limit = 1;
	if (prospectingId) {
		filters.addFilter({ field: 'prospectingId' }, comparisonTypes.Equals, prospectingId);
		filters.fields = ['prospectingId'];
	} else if (duns) {
		filters.addFilter({ field: 'dunsNo' }, comparisonTypes.Equals, duns.toString());
		filters.fields = ['id'];
	}

	const { Account } = Tools;
	const customerId = Tools.AppService.getCustomerId();

	Account.customer(customerId)
		.find(filters.build())
		.then(function (results) {
			// If results
			if (results.data.length) {
				// Save account with found parent and go there
				const data = { id: client.id, parent: { id: results.data[0].id } };
				Account.customer(customerId).save(data, {
					skipNotification: true,
					skipErrorNotification: true
				});
				Tools.$state.go('account.dashboard', { id: results.data[0].id });
			} else {
				if (prospectingId) {
					// eslint-disable-next-line promise/catch-or-return
					Tools.$upModal
						.open('confirmParentProspecting', {
							customerId: customerId,
							name: client.prospecting?.parent?.name,
							prospectingId: prospectingId
						})
						.then(account => {
							const data = { id: client.id, parent: { id: account.id } };
							Account.customer(customerId).save(data, {
								skipNotification: true,
								skipErrorNotification: true
							});
							// Go to account
							Tools.$state.go('account.dashboard', { id: account.id });
						});
				} else {
					// Ask to buy the account from soliditet
					// eslint-disable-next-line promise/catch-or-return
					Tools.$upModal
						.open('confirmSoliditetBuyParent', {
							customerId: customerId,
							name: client?.soliditet?.profileData.parentCompanyName,
							duns: duns
						})
						.then(function (account) {
							// Set parent on account after buy
							const data = { id: client.id, parent: { id: account.id } };

							Account.customer(customerId).save(data, {
								skipNotification: true,
								skipErrorNotification: true
							});
							// Go to account
							Tools.$state.go('account.dashboard', { id: account.id });
						});
				}
			}
		})
		.catch(e => {
			logError(e, 'Failed to get account', { filters: filters.build() });
		});
};

const CompanyGroup = ({ client }: { client: Client }) => {
	const classes = new BemClass('ClientCardSidebar__CompanyGroup');
	const hasProspectingPro = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO);
	const hasGroupBonaza = useSoftDeployAccess('GROUP_BONANZA');

	const [loading, setLoading] = useState<boolean>(true);
	const [size, setSize] = useState<number>(1);
	const [numberOfCustomers, setNumberOfCustomers] = useState<number>(0);
	const clientIdsRef = useRef<number[]>([]);
	const [customerNames, setCustomerNames] = useState<string[]>([]);
	const [historyProps, setHistoryProps] = useState<HistoryProperties>(getDefaultHistoryProps());
	const [isHovering, setIsHovering] = useState<boolean>(false);
	const [refetchTrigger, setRefetchTrigger] = useState<number>(0);

	useEffect(() => {
		if (client.prospecting?.groupSize! > 1) {
			setLoading(true);

			const { cancel, promise } = getProspectingGroup(client.prospectingId!);
			promise
				.then(({ data }) => {
					const { size, numberOfCustomers, clientIds, customersNames, historyProps } =
						getCompanyGroupData(data);

					setSize(size);
					setNumberOfCustomers(numberOfCustomers);
					setCustomerNames(customersNames);
					setHistoryProps(historyProps);
					clientIdsRef.current = clientIds;

					setLoading(false);
				})
				.catch((error: unknown) => logError(error));

			return () => cancel();
		}
	}, [client.prospectingId, client.prospecting, refetchTrigger]);

	useEffect(() => {
		const $rootScope = getAngularModule('$rootScope');
		// Trying to sync this in the UI got to complicated, I will go for the old refetch approach here
		const eventHandler = (event: any, item: { client?: { id: number }; id: number }) => {
			if (clientIdsRef.current.includes(item.client?.id ?? item.id)) {
				setRefetchTrigger(refetchTrigger => refetchTrigger + 1);
			}
		};
		const eventListeners = [
			$rootScope.$on('activity.added', eventHandler),
			$rootScope.$on('activity.updated', eventHandler),
			$rootScope.$on('appointment.added', eventHandler),
			$rootScope.$on('appointment.updated', eventHandler),
			$rootScope.$on('opportunity.added', eventHandler),
			$rootScope.$on('order.added', eventHandler),
			$rootScope.$on('order.updated', eventHandler),
			$rootScope.$on('account.updated', eventHandler)
		];

		return () => eventListeners.forEach(listener => listener());
	}, [client.prospectingId, client.prospecting]);

	const onClick = () => {
		if (!loading) {
			const FeatureHelper = getAngularModule('FeatureHelper');
			const $state = getAngularModule('$state');

			if (FeatureHelper.hasSoftDeployAccess('GROUP_BONANZA')) {
				history.push(`/companyGroup/${client.prospectingId}/`);
			} else {
				$state.go('account.relations', { id: client.id });
			}
		}
	};

	if (!(client.prospecting?.groupSize! > 1) || !client.prospectingId) {
		return null;
	}

	const progress = numberOfCustomers > 0 ? (numberOfCustomers / size) * 100 : 0;
	const tooltip = getTooltip(customerNames, hasProspectingPro);

	const showParentLink =
		client.parent ||
		client.soliditet?.profileData?.parentCompanyCountryCode === 'SE' ||
		(client.prospecting && client.prospecting.parent);

	const showParentText = !client.parent && client.soliditet?.profileData?.parentCompanyCountryCode !== 'SE';
	const linkText =
		client.parent?.name || client.prospecting?.parent?.name || client.soliditet?.profileData?.parentCompanyName;
	const parentText = client.parent?.name || client.soliditet?.profileData?.parentCompanyName;

	return (
		<Block
			className={classes.b()}
			onClick={onClick}
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
			backgroundColor={isHovering ? 'grey-1' : 'white'}
		>
			<Title>{T('account.companyGroup.companyGroup')}</Title>
			<Block>
				{loading ? (
					<Flex justifyContent="center" space="mtxl mbl">
						<Loader size="xs" />
					</Flex>
				) : (
					<>
						<Tooltip title={tooltip} disabled={!tooltip.length}>
							<Block space="mtl">
								<Text color="grey-11" size="sm">
									{size === 1
										? T('account.companyGroup.customerCountProgressOne', {
												size,
												numberOfCustomers
										  })
										: T('account.companyGroup.customerCountProgressMany', {
												size,
												numberOfCustomers
										  })}
								</Text>
							</Block>
							<Block space="mts">
								<Progressbar size="sm" color="green" value={progress} />
							</Block>
						</Tooltip>
						{hasGroupBonaza && hasParent(client) ? (
							<Flex
								space="ptl"
								alignItems="flex-start"
								justifyContent="space-between"
								className={classes.elem('parent').b()}
							>
								<Flex gap="u1">
									<Text size="sm">{T('default.parentCompany') + ':'}</Text>
									{showParentLink ? (
										<EllipsisTooltip title={linkText}>
											<Link
												onClick={e => {
													e.stopPropagation();
													parentCompanyClick(client);
												}}
											>
												{linkText}
											</Link>
										</EllipsisTooltip>
									) : null}
									{showParentText ? (
										<EllipsisTooltip title={parentText}>
											<Text size="sm">{parentText}</Text>
										</EllipsisTooltip>
									) : null}
								</Flex>
								<Tooltip position="bottom" title={T('account.companyGroup.changeParentCompany')}>
									<Flex
										className={classes.elem('parent').elem('edit').b()}
										justifyContent="center"
										onClick={e => {
											e.stopPropagation();

											openDrawer('CreateRelation', {
												client,
												defaultView: CreateRelationView.ParentCompany
											});
										}}
										alignItems="center"
									>
										<Icon name="edit" />
									</Flex>
								</Tooltip>
							</Flex>
						) : null}
						<Block space="mtl">
							<SalesHistory entity={historyProps} entityName={'companyGroup'} />
						</Block>

						<SlideFade direction="top" visible={isHovering} maxHeight={17} height={true}>
							<Block space="mtl">
								<Text>
									<Link>{T('account.companyGroup.clickToShow')}</Link>
								</Text>
							</Block>
						</SlideFade>
					</>
				)}
			</Block>
		</Block>
	);
};

export default CompanyGroup;
