import logError from 'App/babel/helpers/logError';
import PhoneInput from 'Components/Inputs/PhoneInput';
import JourneyStepDisplay from 'Components/JourneyStepDisplay';
import openModal from 'App/services/Modal';

ReactTemplates.editActivity.sidebarContact = window.ReactCreateClass({
	focusedId: null,
	getInitialState: function () {
		return {
			editing: false,
			isEdit: false,
			editedContact: {}
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		// If we done savign
		if (this.state.editing && this.props.contactSaving && !nextProps.contactSaving) {
			this.setState({ editing: false });
		}
		if (this.props.activity.id !== nextProps.activity.id) {
			this.setState({ editing: false });
		}
	},
	UNSAFE_componentWillMount: function () {
		var t = window.Tools.$translate;
		this.lang = {
			contact: t('default.contact'),
			isRemoved: t('contact.isRemoved').toLowerCase(),
			new: t('default.new'),
			noSet: t('default.noSet'),
			selectContact: t('default.selectContact'),
			cellPhone: t('default.cellPhone'),
			phone: t('default.phone'),
			email: t('default.email'),
			addOptIn: t('default.addOptIn'),
			searchOptIn: t('default.searchOptIn'),
			noEmail: t('default.noEmail'),
			noManualOptIns: t('default.noManualOptIns'),
			and: t('default.and').toLowerCase(),
			more: t('filters.more').toLowerCase(),
			edit: t('default.edit'),
			editContact: t('default.editContact'),
			createContact: t('default.createContact'),
			name: t('default.name'),
			firstName: t('default.firstName'),
			lastName: t('default.lastName'),
			save: t('default.save'),
			abort: t('default.abort'),
			title: t('default.title'),
			noContact: t('default.noContact')
		};
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	contactChange: function (contact) {
		this.props.onChange(contact);
	},
	setFocus: function () {
		if (this.focusedId !== this.props.activity.id && !this.props.activity.client) {
			var input = jQuery('#contact-select');
			if (input.length) {
				setTimeout(function () {
					input.select2('open');
				}, 200);
			}
		}
	},
	componentDidMount: function () {
		this.setFocus();
		this.addNewContactButton();
	},
	componentDidUpdate: function () {
		this.setFocus();
		this.props.updateSize();
	},
	parentCompanyClick: function () {
		this.props.parentCompanyClick();
	},
	createContact: function () {
		this.props.onCreateContact();
	},
	getTooltip: function (items) {
		return _.map(items, function (item) {
			return <div>{item.name}</div>;
		});
	},
	addNewContactButton: function () {
		const t = Tools.$translate;
		const hasActivityOutcomeAccess = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ACTIVITY_OUTCOMES);
		if (hasActivityOutcomeAccess) {
			setTimeout(() => {
				const select2Drop = jQuery('.select-contact-wrapper .select2-drop');
				const addNewContactBtn = jQuery(
					'<div class="add-new-contact Button up-btn btn-bright-blue btn-link btn-block"/>'
				).text(t('default.createAContact'));
				select2Drop.append(addNewContactBtn);
				select2Drop.on('click', '.add-new-contact', () => {
					jQuery('#contact-select').select2('close');
					this.createContact();
				});
			}, 500);
		}
	},
	showContactEdit: function (edit) {
		var activity = this.props.activity;
		var contact = activity.contacts && activity.contacts.length ? activity.contacts[0] : null;
		if (!contact.userEditable) {
			return;
		}

		const optinsArr = contact && _.isArray(contact.optins) ? contact.optins : [];
		var optins = optinsArr.reduce(
			function (out, optin) {
				out[optin.type === 'manual' ? 'manual' : 'restOptins'].push(optin);
				return out;
			},
			{ manual: [], restOptins: [] }
		);

		this.setState({
			editing: true,
			isEdit: !!edit,
			editedContact: {
				name: contact ? contact.name : '',
				firstName: contact ? contact.firstName : '',
				lastName: contact ? contact.lastName : '',
				title: contact ? contact.title : '',
				phone: contact ? contact.phone : '',
				cellPhone: contact ? contact.cellPhone : '',
				email: contact ? contact.email : '',
				manualOptins: optins.manual,
				restOptins: optins.restOptins
			}
		});
	},
	emailContact: function (e) {
		e.preventDefault();
		e.stopPropagation();

		this.props.onEmailContact();
	},
	showMoreOptIns: function (manualOptins, e) {
		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_LIST')) {
			openModal('ListModal', {
				title: 'default.optIns',
				columns: [
					{ title: 'default.name', value: 'title' },
					{ title: 'default.regDate', value: 'date' }
				],
				data: _.map(manualOptins, function (optin) {
					optin.date = moment(optin.regDate).format('L LT');
					return optin;
				})
			});
		} else {
			Tools.$upModal.open(
				'list',
				{
					title: 'default.optIns',
					columns: [
						{ title: 'default.name', value: 'title' },
						{ title: 'default.regDate', value: 'date' }
					],
					data: _.map(manualOptins, function (optin) {
						optin.date = moment(optin.regDate).format('L LT');
						return optin;
					})
				},
				e
			);
		}
	},
	getOptInText: function (manualOptins) {
		if (!manualOptins.length) {
			return null;
		}
		var title = manualOptins[0].title;

		if (manualOptins.length > 1) {
			return title + ' ' + this.lang.and + ' ' + (manualOptins.length - 1) + ' ' + this.lang.more;
		}
		return title;
	},
	onContactSave: function () {
		var self = this;
		var editedContact = Object.assign({}, this.state.editedContact);
		// Concat manual optins with restOptins
		editedContact.optins = editedContact.restOptins.concat(editedContact.manualOptins);
		delete editedContact.restOptins;
		delete editedContact.manualOptins;

		this.props
			.onContactSave(editedContact, this.state.isEdit)
			.then(function () {
				self.setState({
					editing: false
				});
			})
			.catch(e => {
				logError(e, 'Failed to save contact');
			});
	},
	saveContactOnEnter: function (event) {
		if (event.keyCode === 13) {
			event.preventDefault();
			event.stopPropagation();
			if (!self.contactFormInvalid) {
				this.onContactSave();
			}
		}
	},
	contactFormChange: function (field, e) {
		var editedContact = this.state.editedContact;
		editedContact[field] = e.target.value;
		this.setState({ editedContact: editedContact });
	},
	contactFormChangeUpSelect: function (field, value) {
		var editedContact = this.state.editedContact;
		editedContact[field] = value;
		this.setState({ editedContact: editedContact });
	},
	contactFormInvalid: function () {
		const { email, phone, cellPhone, name, title } = this.state.editedContact;
		var re =
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
		var emailValid = re.test(this.state.editedContact.email);
		const isPhoneValid = !phone || window.phoneNumberHelper.isValidNumber(phone);
		const isCellPhoneValid = !cellPhone || window.phoneNumberHelper.isValidNumber(cellPhone);

		return (
			!name ||
			(this.props.requiredFieldsContact.Email && !email) ||
			(email && !emailValid) ||
			(this.props.requiredFieldsContact.Phone && !phone) ||
			(this.props.requiredFieldsContact.Cellphone && !cellPhone) ||
			(this.props.requiredFieldsContact.Title && !title) ||
			!isPhoneValid ||
			!isCellPhoneValid
		);
	},
	abortContactEdit: function () {
		this.setState({ editing: false });
	},
	render: function () {
		var self = this;
		var activity = self.props.activity;
		var contact = activity.contacts && activity.contacts.length ? activity.contacts[0] : null;
		var requiredFields = self.props.requiredFields;
		var contactRemoved = self.props.contactRemoved;
		var disabled = self.props.disabled;
		var formDirty = self.props.formDirty;
		var formSubmitted = self.props.formSubmitted;
		var rootClass = 'sidebar-section sidebar-contact-select-wrap show-when-account';
		var contactSelectWrapClass = 'form-group';
		var displayContact = null;
		var editContact = null;

		var manualOptins = _.filter(contact ? contact.optins : [], function (o) {
			return o.type === 'manual';
		});

		if (!contact && (formDirty || formSubmitted) && requiredFields.Contact) {
			contactSelectWrapClass += ' has-error';
		}

		var cellPhoneClass;
		var phoneClass;
		var emailClass;
		var optinClass;
		var hasNewFields = Tools.FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS);
		if (contact) {
			if (self.state.editing) {
				rootClass += ' is-form';

				var nameClass = 'floating-label-input';
				var firstNameClass = 'floating-label-input';
				var lastNameClass = 'floating-label-input';
				var titleClass = 'floating-label-input';
				cellPhoneClass = 'floating-label-input';
				phoneClass = 'floating-label-input';
				emailClass = 'floating-label-input';
				optinClass = 'floating-label-input with-up-select';
				var btnsDisabled = this.props.contactSaving || self.contactFormInvalid();
				var requiredFieldsContact = this.props.requiredFieldsContact;

				if (self.state.editedContact.name) {
					nameClass += ' has-value';
				}
				if (self.state.editedContact.firstName) {
					firstNameClass += ' has-value';
				}
				if (self.state.editedContact.lastName) {
					lastNameClass += ' has-value';
				}
				if (self.state.editedContact.title) {
					titleClass += ' has-value';
				}
				cellPhoneClass += ' has-value';
				phoneClass += ' has-value';
				if (self.state.editedContact.email) {
					emailClass += ' has-value';
				}
				if (manualOptins) {
					optinClass += ' has-value';
				}

				editContact = (
					<div id="edit-contact-form">
						<h3>
							<span>{self.state.isEdit ? self.lang.editContact : self.lang.createContact}</span>
						</h3>

						{hasNewFields ? (
							<div className={firstNameClass} key="contact-edit-firstName">
								<label>
									{self.lang.firstName} <b className="text-danger">{'*'}</b>
								</label>
								<input
									type="text"
									value={self.state.editedContact.firstName}
									disabled={self.props.contactSaving}
									onKeyDown={self.saveContactOnEnter}
									onChange={self.contactFormChange.bind(self, 'firstName')}
									maxLength="50"
								/>
							</div>
						) : null}

						{hasNewFields ? (
							<div className={lastNameClass} key="contact-edit-lastName">
								<label>
									{self.lang.lastName} <b className="text-danger">{'*'}</b>
								</label>
								<input
									type="text"
									value={self.state.editedContact.lastName}
									disabled={self.props.contactSaving}
									onKeyDown={self.saveContactOnEnter}
									onChange={self.contactFormChange.bind(self, 'lastName')}
									maxLength="50"
								/>
							</div>
						) : null}

						{!hasNewFields ? (
							<div className={nameClass} key="contact-edit-name">
								<label>
									{self.lang.name} <b className="text-danger">{'*'}</b>
								</label>
								<input
									type="text"
									value={self.state.editedContact.name}
									disabled={self.props.contactSaving}
									onKeyDown={self.saveContactOnEnter}
									onChange={self.contactFormChange.bind(self, 'name')}
									maxLength="50"
								/>
							</div>
						) : null}

						<div className={titleClass}>
							<label>
								{self.lang.title}{' '}
								{requiredFieldsContact.Title ? <b className="text-danger">{'*'}</b> : null}
							</label>
							<input
								type="text"
								value={self.state.editedContact.title}
								disabled={self.props.contactSaving}
								onKeyDown={self.saveContactOnEnter}
								onChange={self.contactFormChange.bind(self, 'title')}
							/>
						</div>

						<div className={cellPhoneClass}>
							<label>
								<b className="fa fa-mobile" /> {self.lang.cellPhone}{' '}
								{requiredFieldsContact.Cellphone ? <b className="text-danger">{'*'}</b> : null}
							</label>
							<PhoneInput
								onChange={value =>
									self.contactFormChange.call(self, 'cellPhone', { target: { value: value } })
								}
								name="cellPhone"
								phone={self.state.editedContact.cellPhone}
								disabled={self.props.contactSaving}
								onKeyDown={self.saveContactOnEnter}
							/>
						</div>

						<div className={phoneClass}>
							<label>
								<b className="fa fa-phone" /> {self.lang.phone}{' '}
								{requiredFieldsContact.Phone ? <b className="text-danger">{'*'}</b> : null}
							</label>
							<PhoneInput
								onChange={value =>
									self.contactFormChange.call(self, 'phone', { target: { value: value } })
								}
								name="phone"
								phone={self.state.editedContact.phone}
								disabled={self.props.contactSaving}
								onKeyDown={self.saveContactOnEnter}
							/>
						</div>

						<div className={emailClass}>
							<label>
								<b className="fa fa-envelope-o" /> {self.lang.email}{' '}
								{requiredFieldsContact.Email ? <b className="text-danger">{'*'}</b> : null}
							</label>
							<input
								type="text"
								value={self.state.editedContact.email}
								disabled={self.props.contactSaving}
								onKeyDown={self.saveContactOnEnter}
								onChange={self.contactFormChange.bind(self, 'email')}
							/>
						</div>

						<div className={optinClass}>
							<label>
								<b className="fa fa-share" /> {self.lang.addOptIn}{' '}
								{requiredFieldsContact.OptIn ? <b className="text-danger">{'*'}</b> : null}
							</label>
							<div className="select2-wrapper">
								<ReactTemplates.INPUTS.upOptins
									value={self.state.editedContact.manualOptins}
									onChange={self.contactFormChangeUpSelect.bind(self, 'manualOptins')}
									placeholder={self.lang.searchOptIn}
									optinType="manual"
									multiple
								/>
							</div>
						</div>

						<div className="contact-sidebar-save">
							<div className="pull-right">
								<button
									type="button"
									className="up-btn btn btn-sm btn-bright-blue no-shadow"
									onClick={self.onContactSave}
									disabled={btnsDisabled}
								>
									{self.props.contactSaving ? <b className="fa fa-spin fa-refresh" /> : null}
									<span> {self.lang.save}</span>
								</button>
								<button
									type="button"
									className="up-btn btn btn-sm btn-grey btn-link"
									onClick={self.abortContactEdit}
									disabled={btnsDisabled}
								>
									{self.lang.abort}
								</button>
							</div>
						</div>
					</div>
				);
			} else {
				var cellPhoneLink = null;
				var phoneLink = null;
				cellPhoneClass = 'modal-sidebar-item';
				phoneClass = 'modal-sidebar-item';
				emailClass = 'modal-sidebar-item';
				optinClass = 'modal-sidebar-item';

				var related = {
					id: activity.id,
					type: 'Activity',
					closeDate: activity.closeDate
				};

				if (contact.cellPhone) {
					cellPhoneClass += ' has-input';
					cellPhoneLink = ReactTemplates.TOOLS.upVoice(contact, 'contact', contact.cellPhone, related);
				}
				if (contact.phone) {
					phoneClass += ' has-input';
					phoneLink = ReactTemplates.TOOLS.upVoice(contact, 'contact', contact.phone, related);
				}
				if (contact.email) {
					emailClass += ' has-input';
				}
				if (manualOptins) {
					optinClass += ' has-input';
				}
				displayContact = (
					<div id="contact-display">
						{contact ? (
							<JourneyStepDisplay
								journeyStep={contact.journeyStep}
								onSetJourneyStep={this.props.onSetJourneyStep}
								type="contact"
								id={contact.id}
							/>
						) : null}

						{contact.title ? (
							<div
								className="modal-sidebar-item has-input"
								onClick={self.showContactEdit.bind(self, true)}
							>
								<span className="item-value no-icon ellipsis">{contact.title.toUpperCase()}</span>
							</div>
						) : null}

						<div className={cellPhoneClass} key="cellphone-section">
							<b className="fa fa-mobile" />
							<span onClick={self.showContactEdit.bind(self, true)}>
								<span>{self.lang.noSet}</span> <span>{self.lang.cellPhone.toLowerCase()}</span>
							</span>
							{cellPhoneLink ? (
								<a
									className="ellipsis"
									href={cellPhoneLink.href}
									target={cellPhoneLink.telTarget}
									onClick={cellPhoneLink.onClick}
								>
									{contact.cellPhone}
								</a>
							) : null}
						</div>

						<div className={phoneClass} key="phone-section">
							<b className="fa fa-phone" />
							<span onClick={self.showContactEdit.bind(self, true)}>
								<span>{self.lang.noSet}</span> <span>{self.lang.phone.toLowerCase()}</span>
							</span>
							{phoneLink ? (
								<a
									className="ellipsis"
									href={phoneLink.href}
									target={phoneLink.telTarget}
									onClick={phoneLink.onClick}
								>
									{contact.phone}
								</a>
							) : null}
						</div>

						<div className={emailClass} key="email-section">
							<b className="fa fa-envelope" />
							<span onClick={self.showContactEdit.bind(self, true)}>{self.lang.noEmail}</span>
							<a className="ellipsis pointer select" onClick={self.emailContact}>
								{contact.email}
							</a>
						</div>

						<div className={optinClass} key="optin-section">
							<b className="fa fa-share" />
							<span onClick={self.showContactEdit.bind(self, true)}>{self.lang.noManualOptIns}</span>
							<a className="ellipsis pointer" onClick={self.showMoreOptIns.bind(self, manualOptins)}>
								{self.getOptInText(manualOptins)}
							</a>
						</div>

						<div className="modal-sidebar-item center" key="edit-contact-btn">
							<div id="sidebar-contact-actions">
								{contact.userEditable ? (
									<button
										type="button"
										className="up-btn btn-grey btn-link no-shadow btn btn-sm"
										onClick={self.showContactEdit.bind(self, true)}
										disabled={disabled}
									>
										<b className="fa fa-edit" /> <span>{self.lang.edit}</span>
									</button>
								) : null}
							</div>
						</div>
					</div>
				);
			}
		}

		return (
			<div className={rootClass}>
				<div id="select-contact-init" className={contactSelectWrapClass}>
					<label htmlFor="contact-select">
						{self.lang.contact} {requiredFields.Contact ? <b className="text-danger">{'*'}</b> : null}
						{contactRemoved
							? ReactTemplates.TOOLS.withTooltip(
									<b className="fa fa-warning text-orange" />,
									contactRemoved.name + ' ' + self.lang.isRemoved,
									{ placement: 'right' }
							  )
							: null}
					</label>

					{!activity.closeDate ? (
						<button
							onClick={self.createContact}
							className="btn up-btn btn-link btn-sm btn-bright-blue pull-right add-contact-btn"
							tabIndex="-1"
						>
							{self.lang.new}
						</button>
					) : null}

					<div className="clearfix" />

					{React.createElement(ReactTemplates.INPUTS.upContacts, {
						id: 'contact-select',
						name: 'contact',
						value: contact,
						linked: true,
						onlyActive: true,
						required: requiredFields.Contact,
						tabIndex: 2,
						onChange: self.contactChange,
						className: 'form-control no-transition select-contact-wrapper',
						disabled: disabled,
						placeholder: self.lang.selectContact,
						accountId: activity.client ? activity.client.id : null,
						formatResult: function (contact) {
							if (!contact.id) {
								return '<i class="grey">' + self.lang.noContact + '</i>';
							}
							return window.Tools.utils.select2.clientContactsAndRelations.formatResult(
								contact,
								self.props.activity.client ? self.props.activity.client.id : null
							);
						},
						beforeGoTo: self.props.beforeGoTo,
						fields: ['title', 'phone', 'cellPhone', 'email']
					})}
				</div>

				{displayContact}

				{editContact}
			</div>
		);
	}
});
