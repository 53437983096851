import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
	avatar: PropTypes.object,
	avatarService: PropTypes.object.isRequired,
	className: PropTypes.string,
	id: PropTypes.string,
	innerStyle: PropTypes.object,
	key: PropTypes.string,
	size: PropTypes.number,
	style: PropTypes.object,
	user: PropTypes.object.isRequired
};

// can be deleted together with delete with EXPORT_CONTACT_DATA_REACT
/**
 * @deprecated Use Components/Avatar
 */
class Avatar extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			user: props.user,
			size: props.size ? props.size : 30,
			avatar: props.avatar || { initials: '', url: '', gravatar: false }
		};

		// eslint-disable-next-line promise/catch-or-return
		props.avatarService.getAvatar(props.user).then(avatar => {
			this.setState({ avatar });
		});

		this.onError = this.onError.bind(this);
	}

	/**  Needs to be looked at if it should be replaced with old Avatar component **/
	// shouldComponentUpdate(newProps, newState) {
	// 	var shallUpdate = (newProps.user && newProps.user.id !== this.state.user.id) ||
	// 						newState.avatar.url !== this.state.avatar.url ||
	// 						newProps.avatar.url !== this.props.avatar.url ||
	// 						(newProps.avatar.url && newProps.avatar.url !== this.state.avatar.url);

	// 	return shallUpdate;
	// }

	// UNSAFE_componentWillUpdate(newProps, newState) {
	// 	Set the state text if it was changed --> Only check if not assign before.. shallComponentUpdate check if visit has an assign
	// 	if(newProps.user && newProps.user.id) {
	// 		var avatar = this.props.avatar.url ? this.props.avatar : newState.avatar;
	// 		if(newProps.avatar && newProps.avatar.url) {
	// 			avatar.url = newProps.avatar.url;
	// 		}

	// 		if(this.state.avatar.url !== avatar.url) {
	// 			this.setState({'user':this.props.user, 'avatar': avatar});
	// 		}
	// 	}
	// }

	onError() {
		if (this.state.avatar.url.indexOf('gravatar') === -1) {
			this.setState({
				avatar: this.props.avatarService.getGravatar(this.props.user, { notFoundError: false }),
				hasError: true
			});
		}
	}

	render() {
		const { user, innerStyle, style, className, key, id } = this.props;
		const { size, avatar } = this.state;

		if (user && user.name) {
			const tag = avatar.gravatar ? <img className="img" src={avatar.url} onError={this.onError} /> : null;
			const innerDivStyle = _.merge(
				{
					display: avatar.initials.length ? 'block' : 'none',
					lineHeight: size + 'px'
				},
				innerStyle || {}
			);

			const outerDivStyle = _.merge(
				{
					width: size,
					height: size,
					fontSize: parseInt(size) / 2 + 'px',
					marginTop: '3px'
				},
				style || {}
			);

			const spanStyle = {
				lineHeight: size + 'px'
			};

			const classNames = `generic-avatar round${className ? ' ' + className : ''}`;

			return (
				<div key={key || null} className={classNames} style={outerDivStyle} id={id || null}>
					<div style={innerDivStyle} className="color">
						<span className="name" style={spanStyle}>
							{avatar.initials}
						</span>
					</div>
					{tag}
				</div>
			);
		} else {
			return <div />;
		}
	}
}

Avatar.propTypes = propTypes;

window.Avatar = Avatar;
export default window.Avatar;
