import RequestBuilder, { comparisonTypes as ComparisonTypes } from 'Resources/RequestBuilder';
import DistributionBar from '../Reportcenter/ReportcenterWidget/Components/DistributionBar';
import { Flex, Icon, TableColumn, TableRow } from '@upsales/components';
import { ListViewDefaultColumn } from 'App/components/ListView/ListViewRenderHelpers';
import ListView, { ListViewPropsExternal } from 'App/components/ListView';
import { currencyFormat } from 'Components/Filters/Currencies';
import { useTranslation } from 'Components/Helpers/translate';
import { useMetadata } from 'App/components/hooks/appHooks';
import ClientAttributes from 'App/babel/attributes/Client';
import clientFilters from 'App/babel/filterConfigs/Client';
import ClientResource from 'App/resources/Client';
import Client from 'App/resources/Model/Client';
import React, { useCallback, useMemo } from 'react';

const validExtraFields = ['pipelineDistribution', 'subaccountPipelineDistribution'];

type Props = {
	columns: string[];
	clientIds: number[];
	getDataFilter?: (rb: RequestBuilder) => void;
	onRowClick: (account: Client) => void;
};

const CompanyList = ({ columns, clientIds, getDataFilter, onRowClick }: Props) => {
	const { t } = useTranslation();
	const ClientFilters = useMemo(() => clientFilters(), []);
	const metadata = useMetadata();

	const getRowRenderFn =
		(): ListViewPropsExternal<Client>['renderTableRow'] =>
		(account: Client, { columns, attributes }) => {
			const columnElements = columns.map(column => {
				if (column === 'pipelineDistribution' || column === 'subaccountPipelineDistribution') {
					const distribution = account.extraFields?.[column];
					return (
						<TableColumn key={column + account.id}>
							<DistributionBar
								datatype="currency"
								distribution={distribution}
								currency={metadata?.defaultCurrency.iso}
								openedFromCompanyGroupCard
							/>
						</TableColumn>
					);
				}

				if (column === 'subaccountPipelineValueTotal' || column === 'pipelineValueTotal') {
					const decideDistributionColumn =
						column === 'subaccountPipelineValueTotal'
							? 'subaccountPipelineDistribution'
							: 'pipelineDistribution';
					const distribution = account.extraFields?.[decideDistributionColumn];

					const totalValue = distribution?.reduce((acc, item) => acc + item.value, 0) || 0;

					return (
						<TableColumn key={column + account.id}>
							<Flex alignItems="center" gap="u3">
								<Icon name="opportunity" />
								{currencyFormat(totalValue, metadata?.defaultCurrency.iso || 'SEK')}
							</Flex>
						</TableColumn>
					);
				}

				return (
					<ListViewDefaultColumn<Client>
						key={column}
						item={account}
						attributes={attributes}
						column={column}
					/>
				);
			});

			return (
				<TableRow key={account.id} onClick={() => onRowClick(account)}>
					{columnElements}
				</TableRow>
			);
		};

	const getData = useCallback(
		async (rb: RequestBuilder) => {
			if (clientIds.length === 0) {
				return {
					data: [],
					metadata: { total: 0, limit: 50, offset: 0 }
				};
			}

			rb.addFilter({ field: 'id' }, ComparisonTypes.Equals, clientIds);
			getDataFilter?.(rb);

			const extraFields: string[] = columns.filter(column => validExtraFields.indexOf(column) >= 0);

			rb.addExtraParam('extraFields', extraFields);

			return ClientResource.find(rb.build());
		},
		[columns]
	);

	return (
		<ListView<Client>
			filterConfigs={ClientFilters}
			hideFilters
			broadcastType="client"
			getData={getData}
			attributes={ClientAttributes}
			renderTableRow={getRowRenderFn()}
			columns={columns}
			skipSortById
			quickSearchFilter="ListSearch"
			quickSearchPlaceholder={t('client.search')}
			renderToolsColumn={false}
			canSortCustomFields={false}
			initialSorting={[{ attribute: 'name', ascending: true }]}
			renderHeaderFirstExtra={() => <div />}
		/>
	);
};

export default CompanyList;
