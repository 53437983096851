import {
	Block,
	Button,
	Card,
	CardContent,
	CardHeader,
	DropDownMenu,
	Flex,
	Help,
	Icon,
	Label,
	Loader,
	Row,
	Select,
	Text,
	Toggle
} from '@upsales/components';
import Bem from '@upsales/components/Utils/bemClass';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import sortAlphabetically from 'App/babel/utils/sortAlphabetically';
import SaveBtn from 'Components/Admin/SaveBtn';
import T from 'Components/Helpers/translate';
import BrokenFiscalYearSelect from 'Components/Misc/BrokenFiscalYearSelect';
import store from 'Store/index';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import UpSelect from '../Inputs/UpSelect';
import './AccountSettings.scss';
import AdminHeader from './AdminHeader';
import EmailSyncCard from './EmailSyncCard';
import SalesModelSettings from './SalesModelSettings';
import { openToggleFiscalYearModal } from './ToggleFiscalYearModal/ToggleFiscalYearModal';

const getCountries = () => {
	const countries = Tools.CountryCodes.countries();

	const translated = countries.map(cc => {
		return { ...cc, translated: Tools.$translate(cc.lang) };
	});

	const language = Tools.AppService.getSelf().language;

	if (language) {
		translated.sort(sortAlphabetically('translated', false, language));
	}

	return translated;
};

const formatWithFlag = function (lang: { country: string; name: string }) {
	const icon = <i style={{ marginRight: 5 }} className={'flag-icon flag-icon-' + lang.country} />;
	const text = Tools.$translate(lang.name);

	return window.renderToStaticMarkup(
		<span>
			{icon}
			{text}
		</span>
	);
};

type Select2Item = {
	name: string;
};

type SelectOptions = {
	id: number;
	title: string;
};

const matcher = (term: string, _: any, item: Select2Item) => {
	const name = Tools.$translate(item.name).toLowerCase();
	return name.indexOf(term.toLowerCase()) !== -1;
};

const translateField = (fieldName: string, callback: () => void) => {
	// eslint-disable-next-line promise/catch-or-return
	Tools.$upModal.open('translateFields', { fieldName }).then(() => callback());
};
const updateMetadata = (key: string, value: any) => {
	const meta = Tools.AppService.getMetadata();
	meta.params[key] = value;
	Tools.AppService.setMetadata(meta);
};

const MissingTypeTag = (props: { type: string; callback: () => void }) => {
	const name = Tools.$translate(`default.${props.type}`);

	return (
		<Text
			size="sm"
			key={props.type}
			className="admin-table-token"
			onClick={() => translateField(props.type, props.callback)}
		>
			{name}
		</Text>
	);
};

MissingTypeTag.propTypes = {
	type: PropTypes.string,
	language: PropTypes.string,
	callback: PropTypes.func
};

type Props = {
	loading: boolean;
	actions: {
		changeMasterLanguage: () => void;
		languageChange: () => void;
		saveLanguage: () => void;
		cancelAddLanguage: () => void;
		startAddLanguage: () => void;
		selectBrokenFiscalYearOffset: (offset: number) => void;
		showFiscalyearSelect: () => void;
		salesModelActions: () => void;
		saveMasterLanguage: () => void;
		getLanguages: () => void;
		removeLanguage: (language: Props['selectedLanguages'][0]) => void;
		toggleBrokenFiscalYear: (value: boolean) => void;
		toggleAutoAddProspectingData: (value: boolean) => void;
	};
	selectedLanguages: Array<{
		id: string;
		isMaster: boolean;
		missing: Array<string>;
		language: string;
		used: Array<{}>;
	}>;
	languages: Array<{ id: string; name: string; country: string }>;
	tmpMasterLanguage: string;
	savingMasterLanguage: boolean;
	saving: boolean;
	isAdding: boolean;
	tmpLanguage: {};
	masterLanguage: {
		language: string;
	};
	currentlyDeleting: string;
	brokenFiscalYearEnabled: boolean;
	brokenFiscalYearOffset: number;
	savingBrokenFiscalYear: boolean;
	showFiscalYearSelect: boolean;
	salesModel: string;
	salesModelOption: string;
	salesModelOption2: string;
	salesModelOption3: string;
	tmpSalesModel: string;
	tmpSalesModelOption: string;
	tmpSalesModelOption2: string;
	tmpSalesModelOption3: string;
	tmpGroupARRChangesByUser: boolean;
	groupARRChangesByUser: boolean;
	autoAddProspectingDataEnabled: boolean;
	savingAutoAddProspectingData: boolean;
	anchor: Element | null;
};

class AccountSettings extends React.Component<Props> {
	static propTypes = {
		loading: PropTypes.bool,
		actions: PropTypes.object,
		selectedLanguages: PropTypes.array,
		languages: PropTypes.array,
		tmpMasterLanguage: PropTypes.string,
		savingMasterLanguage: PropTypes.bool,
		saving: PropTypes.bool,
		isAdding: PropTypes.bool,
		tmpLanguage: PropTypes.object,
		masterLanguage: PropTypes.object,
		currentlyDeleting: PropTypes.number,
		brokenFiscalYearEnabled: PropTypes.bool,
		brokenFiscalYearOffset: PropTypes.number,
		savingBrokenFiscalYear: PropTypes.bool,
		showFiscalYearSelect: PropTypes.bool,
		salesModel: PropTypes.string,
		salesModelOption: PropTypes.string,
		salesModelOption2: PropTypes.string,
		salesModelOption3: PropTypes.string,
		tmpSalesModel: PropTypes.string,
		tmpSalesModelOption: PropTypes.string,
		tmpSalesModelOption2: PropTypes.string,
		tmpSalesModelOption3: PropTypes.string,
		tmpGroupARRChangesByUser: PropTypes.bool,
		groupARRChangesByUser: PropTypes.bool,
		autoAddProspectingDataEnabled: PropTypes.bool,
		savingAutoAddProspectingData: PropTypes.bool
	};

	lang: { [key: string]: string };

	hasNewFields: boolean;
	hasBrokenFiscalYear: boolean;
	hasAgreements: boolean;
	hasEmailSyncLogs: boolean;
	hasAutoAddProspectingData: boolean;
	hasSessionTimeout: boolean;

	constructor(props: Props) {
		super(props);

		const FeatureHelper = getAngularModule('FeatureHelper');
		const t = getAngularModule('$translate');

		this.lang = {
			accountSettings: t('admin.accountSettings'),
			description1: t('admin.accountSettings.description-1'),
			description2: t('admin.accountSettings.description-2'),
			missingTranslations: t('admin.languageSettings.missingTranslations'),
			standard: t('admin.languageSettings.standard'),
			standardDescription: t('admin.languageSettings.standardDescription'),
			formatNoMatches: t('admin.languageSettings.formatNoMatches'),
			readMore: t('default.readMore'),
			addLanguage: t('default.addLanguage'),
			language: t('default.language'),
			translations: t('admin.languageSettings.translations'),
			no: t('default.no'),
			selectLanguage: t('default.selectLanguage'),
			noMasterLanguage: t('admin.languageSettings.noMasterLanguage'),
			saveStandard: t('admin.languageSettings.saveStandard'),
			save: t('default.save'),
			abort: t('default.abort'),
			remove: t('default.remove'),
			change: t('default.change'),
			confirmDelete: t('admin.languageSettings.confirmDelete'),
			brokenFiscalYear: t('default.brokenFiscalYear'),
			activateBrokenFiscalYear: t('admin.accountSettings.activateBrokenFiscalYear'),
			brokenFiscalYearDescription: t('admin.accountSettings.brokenFiscalYearDescription'),
			brokenFiscalYearReadMore: t('admin.accountSettings.brokenFiscalYearReadMore'),
			brokenFiscalYearQuestion: t('admin.accountSettings.brokenFiscalYearQuestion', { year: moment().year() }),
			yourFiscalYear: t('admin.accountSettings.yourFiscalYear', { year: moment().year() }),
			autoAddProspectingDataTitle: t('admin.accountSettings.autoAddProspectingData.title'),
			autoAddProspectingDataDescription: t('admin.accountSettings.autoAddProspectingData.description')
		};
		this.hasNewFields = FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
		this.hasBrokenFiscalYear = FeatureHelper.isAvailable(FeatureHelper.Feature.BROKEN_FISCAL_YEAR);
		this.hasAgreements =
			Tools.AppService.getMetadata().params.AgreementEnabled &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.RECURRING_ORDER) &&
			Tools.AppService.getAccessRights().Agreement;
		this.hasEmailSyncLogs = FeatureHelper.hasSoftDeployAccess('EMAIL_SYNC_LOGS');
		this.hasAutoAddProspectingData =
			FeatureHelper.hasSoftDeployAccess('AUTO_ADD_PROSPECTING_DATA') &&
			FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_BASIC);
		this.hasSessionTimeout = FeatureHelper.hasSoftDeployAccess('SESSION_TIMEOUT');
	}
	onFiscalYearToggle = () => {
		// eslint-disable-next-line promise/catch-or-return
		openToggleFiscalYearModal({}).then(() => {
			this.props.actions.toggleBrokenFiscalYear(!this.props.brokenFiscalYearEnabled);
			updateMetadata('brokenFiscalYearEnabled', !this.props.brokenFiscalYearEnabled);
		});
	};

	noMasterLanguageSelectedCard() {
		const { languages, actions, selectedLanguages } = this.props;

		return (
			<div className="admin-table language__no-master-language">
				<div className="admin-table-top">
					<span className="admin-table-title">{this.lang.standard} </span>
				</div>
				<div className="admin-table-content">
					<div className="language__no-master-language__left">
						<div>{this.lang.noMasterLanguage}</div>
						<div>{this.lang.standardDescription}</div>
						<div className="standard-language-wrap">
							<div className="label-elevio-wrap">
								<label>{this.lang.standard}</label>
								<ReactTemplates.elevio articleId={920} sidebar={true} />
							</div>
							<UpSelect
								className="form-control"
								placeholder={this.lang.selectLanguage}
								defaultValue={null}
								formatSelection={formatWithFlag}
								formatResult={formatWithFlag}
								matcher={matcher}
								data={() => {
									const filteredData = _.filter(
										languages,
										language => _.find(selectedLanguages, { language: language.id }) === undefined
									);
									return { results: filteredData };
								}}
								required={true}
								onChange={actions.changeMasterLanguage}
							/>
						</div>
					</div>
					<div className="language__no-master-language__right">
						<img src="img/empty-briefcase.svg" />
					</div>
				</div>
			</div>
		);
	}

	masterLanguageCard() {
		const { languages, actions, tmpMasterLanguage, masterLanguage } = this.props;

		const masterLanguageInData = _.find(languages, {
			id: tmpMasterLanguage || (masterLanguage && masterLanguage.language)
		});

		return (
			<div className="admin-table" style={{ margin: 0, marginBottom: 20 }}>
				<div className="admin-table-top">
					<span className="admin-table-title">{this.lang.standard}</span>
				</div>
				<div className="admin-info-row">
					<div className="info-row-info">{this.lang.standardDescription}</div>
					<div className="info-row-content">
						<div className="standard-language-wrap">
							<div className="label-elevio-wrap">
								<label>{this.lang.standard}</label>
								<ReactTemplates.elevio articleId={920} sidebar={true} />
							</div>
							<UpSelect
								className="form-control"
								placeholder={this.lang.selectLanguage}
								defaultValue={masterLanguageInData}
								formatSelection={formatWithFlag}
								formatResult={formatWithFlag}
								options={{ formatNoMatches: this.lang.formatNoMatches }}
								matcher={matcher}
								data={() => {
									const filteredLanguages = _.filter(languages, language => {
										const selectedLanguage = _.find(this.props.selectedLanguages, {
											language: language.id
										});
										return selectedLanguage
											? !selectedLanguage.missing.length || selectedLanguage.isMaster
											: false;
									});

									return { results: filteredLanguages };
								}}
								required={true}
								onChange={actions.changeMasterLanguage}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	otherLanguagesCard() {
		const { selectedLanguages, languages, actions, saving, isAdding, tmpLanguage, currentlyDeleting } = this.props;

		const rows = _.reduce<(typeof selectedLanguages)[0], Array<JSX.Element>>(
			selectedLanguages,
			(result, language) => {
				if (!language.isMaster) {
					const selectedLanguage = _.find(languages, { id: language.language });
					const text = selectedLanguage ? selectedLanguage.name : language.language;
					const count = language.used.length;

					let content;

					if (language.missing.length) {
						const tags = (
							<Flex gap={4}>
								{language.missing.map(type => (
									<MissingTypeTag
										key={type}
										type={type}
										language={language.language}
										callback={actions.getLanguages}
									/>
								))}
							</Flex>
						);

						content = <td>{tags}</td>;
					} else {
						content = <td className="no-missing">{this.lang.no}</td>;
					}

					result.push(
						<tr className={language.isMaster ? 'inactive-row' : ''} key={language.language}>
							<td>
								<i
									style={{ marginRight: 5 }}
									className={'flag-icon flag-icon-' + selectedLanguage?.country}
								/>
								{text}
							</td>
							{content}
							{language.isMaster ? (
								<td />
							) : (
								<td className="language__row-tools">
									{currentlyDeleting === language.id ? (
										<b className="fa fa-spinner fa-spin" />
									) : (
										<DropDownMenu
											className="language__row-tools-dropdown"
											align="right"
											renderTrigger={(isExpanded, setExpanded) => {
												return (
													<Button
														color="grey"
														shadow="none"
														type="link"
														className={
															isExpanded
																? 'is_expanded language__row--delete-btn'
																: 'language__row--delete-btn'
														}
														onClick={setExpanded}
													>
														<Icon name="trash-o" />
													</Button>
												);
											}}
										>
											<Card>
												<CardContent>
													{count > 0 ? (
														<Text center={true}>
															{Tools.$translate(this.lang.confirmDelete, {
																count: count
															})}
														</Text>
													) : null}
													<div className="language__row--delete-confirm">
														<Button
															color="red"
															shadow="none"
															onClick={() => actions.removeLanguage(language)}
														>
															{`${this.lang.remove} ${text}`}
														</Button>
													</div>
												</CardContent>
											</Card>
										</DropDownMenu>
									)}
								</td>
							)}
						</tr>
					);
				}

				return result;
			},
			[]
		);

		if (isAdding) {
			rows.unshift(
				<tr className="language__add-row" key={'new-language'}>
					<td colSpan={3}>
						<div className="language__add-row--content-wrap">
							<div className="inline-edit-select2--wrap">
								<label
									className={
										tmpLanguage
											? 'inline-edit-select2--label has-value'
											: 'inline-edit-select2--label'
									}
								>
									{this.lang.language}
								</label>
								<UpSelect
									className="form-control inline-edit-select2"
									placeholder={this.lang.selectLanguage}
									formatSelection={formatWithFlag}
									formatResult={formatWithFlag}
									defaultValue={tmpLanguage}
									matcher={matcher}
									options={{ dropdownCssClass: 'inline-edit-select2--dropdown' }}
									data={() => {
										const filteredData = _.filter(
											languages,
											language =>
												_.find(selectedLanguages, { language: language.id }) === undefined
										);
										return { results: filteredData };
									}}
									required={true}
									onChange={actions.languageChange}
								/>
							</div>
							<div className="language__add-row--btn-wrap">
								<Button shadow="none" size="sm" onClick={actions.saveLanguage} disabled={!tmpLanguage}>
									{this.lang.save}
								</Button>
								<Button
									type="link"
									color="grey"
									shadow="none"
									size="sm"
									onClick={actions.cancelAddLanguage}
								>
									{this.lang.abort}
								</Button>
							</div>
						</div>
					</td>
				</tr>
			);
		}

		return (
			<div className="admin-table">
				<div className="admin-table-top">
					<span className="admin-table-title">{this.lang.translations}</span>
					<div className="pull-right">
						<Button
							size="sm"
							shadow="none"
							onClick={actions.startAddLanguage}
							disabled={saving || isAdding}
						>
							{this.lang.addLanguage}
						</Button>
					</div>
				</div>

				<table>
					<thead>
						<tr>
							<th className="language-col">{this.lang.language}</th>
							<th className="translation-col">{this.lang.missingTranslations}</th>
							<th className="button-col" />
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</table>
			</div>
		);
	}

	getLanguageSettingsCard() {
		if (!this.hasNewFields) {
			return null;
		}

		const { loading, masterLanguage } = this.props;

		return (
			<React.Fragment>
				<div style={{ display: masterLanguage ? 'none' : 'block' }}>
					{loading ? null : this.noMasterLanguageSelectedCard()}
				</div>
				<div style={{ display: !masterLanguage ? 'none' : 'block' }}>
					{loading ? null : this.masterLanguageCard()}
				</div>
				<div style={{ display: !masterLanguage ? 'none' : 'block' }}>
					{loading ? null : this.otherLanguagesCard()}
				</div>
			</React.Fragment>
		);
	}

	getAutoAddProspectingDataCard() {
		if (!this.hasAutoAddProspectingData) {
			return null;
		}

		const classes = new Bem('AccountSettings__AutoAddProspectingData');

		return (
			<Card space="mtxl" className={classes.b()}>
				<CardHeader
					title={T(this.lang.autoAddProspectingDataTitle)}
					subtitle={T(this.lang.autoAddProspectingDataDescription)}
				>
					<Toggle
						size="lg"
						color="medium-green"
						space="mlm"
						onChange={value => this.props.actions.toggleAutoAddProspectingData(value)}
						checked={this.props.autoAddProspectingDataEnabled}
						disabled={this.props.savingAutoAddProspectingData}
					/>
					<Block space="mll">
						<Help articleId={1438} sidebar />
					</Block>
				</CardHeader>
			</Card>
		);
	}

	getBrokenFiscalYearCard() {
		if (!this.hasBrokenFiscalYear) {
			return null;
		}

		const {
			brokenFiscalYearEnabled,
			brokenFiscalYearOffset,
			savingBrokenFiscalYear,
			showFiscalYearSelect,
			actions
		} = this.props;
		const classes = new Bem('AdminBrokenFiscalYear');

		return (
			<div className={`admin-table ${classes.b()}`}>
				<div className="admin-table-top">
					<span className="admin-table-title">{this.lang.brokenFiscalYear}</span>
					<div className="pull-right">
						<span>{this.lang.activateBrokenFiscalYear}</span>
						<div onClick={this.onFiscalYearToggle}>
							<Toggle
								size="lg"
								color="medium-green"
								space="mlm"
								checked={brokenFiscalYearEnabled}
								disabled={savingBrokenFiscalYear}
							/>
						</div>
					</div>
				</div>
				{brokenFiscalYearEnabled ? (
					<div className="admin-table-content">
						<div className="admin-info-row">
							<div className="info-row-info">
								{this.lang.brokenFiscalYearDescription}
								<div className={'AccountSettings__elevio-wrap'}>
									<ReactTemplates.elevio
										sidebar={true}
										articleId={1000}
										text={this.lang.brokenFiscalYearReadMore}
									/>
								</div>
							</div>
							<div className="info-row-content">
								{showFiscalYearSelect ? (
									<React.Fragment>
										<div className={classes.elem('question-label').b()}>
											<Text>{this.lang.brokenFiscalYearQuestion}</Text>
											<ReactTemplates.elevio sidebar={true} articleId={1000} />
										</div>
										<BrokenFiscalYearSelect
											offset={brokenFiscalYearOffset}
											onSelect={(v: number) => {
												// eslint-disable-next-line promise/catch-or-return
												openToggleFiscalYearModal({}).then(() => {
													actions.selectBrokenFiscalYearOffset(v);
												});
											}}
										/>
									</React.Fragment>
								) : (
									<div className={classes.elem('display-card').b()}>
										<Row>
											<Text bold={true} size="lg">
												{this.lang.yourFiscalYear}
											</Text>
											<Button type="link" onClick={actions.showFiscalyearSelect}>
												{this.lang.change}
											</Button>
										</Row>
										<Row>
											<Text color="grey-10">
												{`${moment()
													.startOf('year')
													.add(brokenFiscalYearOffset, 'month')
													.format('YYYY-MM-DD')} - ${moment()
													.endOf('year')
													.add(brokenFiscalYearOffset, 'month')
													.format('YYYY-MM-DD')}`}
											</Text>
										</Row>
									</div>
								)}
							</div>
						</div>
					</div>
				) : null}
			</div>
		);
	}

	getSalesModelCard() {
		return (
			<Provider store={store}>
				<SalesModelSettings
					actions={this.props.actions.salesModelActions}
					activeSalesModel={this.props.salesModel}
					activeSalesModelOption={this.props.salesModelOption}
					activeSalesModelOption2={this.props.salesModelOption2}
					activeSalesModelOption3={this.props.salesModelOption3}
					selectedSalesModel={this.props.tmpSalesModel}
					selectedSalesModelOption={this.props.tmpSalesModelOption}
					selectedSalesModelOption2={this.props.tmpSalesModelOption2}
					selectedSalesModelOption3={this.props.tmpSalesModelOption3}
					selectedGroupARRChangesByUser={this.props.tmpGroupARRChangesByUser}
					activeGroupARRChangesByUser={this.props.groupARRChangesByUser}
				/>
			</Provider>
		);
	}

	getEmailSyncCard() {
		if (!this.hasEmailSyncLogs) {
			return null;
		}
		return <EmailSyncCard />;
	}

	getRegionSettingsCard() {
		return (
			<Card>
				<CardHeader
					title={T('admin.accountSettings.region')}
					subtitle={T('admin.accountSettings.regionSubtitle')}
				>
					<UpSelect
						className={'AccountSettings--regionSelect'}
						data={getCountries()}
						formatSelection={(c: ReturnType<typeof getCountries>[0]) => c.translated}
						formatResult={(c: ReturnType<typeof getCountries>[0]) => c.translated}
						defaultValue={Tools.AppService.getMetadata().params.CustomerRegion}
						placeholder={T('admin.billing.selectCountry')}
						onChange={async (e: { target: { value: string } }) => {
							await Tools.ClientParam.save(240, e.target.value);
							updateMetadata('CustomerRegion', e.target.value);
						}}
						matcher={(term: string, _u: any, { translated }: ReturnType<typeof getCountries>[0]) => {
							return translated.toLowerCase().indexOf(term.toLowerCase()) !== -1;
						}}
					/>
				</CardHeader>
			</Card>
		);
	}

	getSessionTimeoutCard() {
		if (!this.hasSessionTimeout) {
			return null;
		}
		const classes = new Bem('SessionTimeout');
		const optionCount = 10;
		const selectOptions: SelectOptions[] = [];

		// Create the list dropdown from values 1-10
		for (let i = 1; i <= optionCount; i++) {
			const hours = i;
			const title = `${hours} ${hours === 1 ? T('hour') : T('hours')}`;
			selectOptions.push({ id: i, title });
		}

		// Translates stored data from minutes to dropdown value
		const sessionTimeValue = Tools.AppService.getMetadata().params.sessionTime;
		const defaultOption = {
			id: sessionTimeValue / 60,
			title: `${sessionTimeValue / 60} ${sessionTimeValue / 60 === 1 ? T('hour') : T('hours')}`
		};

		const handleSelectChange = async (selectedOption: SelectOptions) => {
			const storingValue = selectedOption.id * 60;
			await Tools.ClientParam.save(18, storingValue);
			defaultOption.id = selectedOption.id;
			defaultOption.title = `${selectedOption.id} ${selectedOption.id === 1 ? T('hour') : T('hours')}`;
		};

		return (
			<div className={`admin-table ${classes.b()}`}>
				<Card>
					<CardHeader title={T('admin.sessionTimeout.title')} />
					<div className="admin-info-row">
						<div className="info-row-info">{T('admin.sessionTimeout.info')}</div>
						<div className="info-row-content">
							<Label>{T('admin.sessionTimeout.label')}</Label>
							<Select
								anchor={document.querySelector('#admin-content')}
								placeholder={T('admin.sessionTimeout.placeholder')}
								options={selectOptions}
								value={defaultOption}
								onChange={handleSelectChange}
							/>
						</div>
					</div>
				</Card>
			</div>
		);
	}

	render() {
		const { loading, tmpMasterLanguage, actions, savingMasterLanguage, masterLanguage } = this.props;

		const saveBtnVisible = !!(
			tmpMasterLanguage && tmpMasterLanguage !== (masterLanguage && masterLanguage.language)
		);
		const rootWrapperClassName = Tools.browserService.isTouchDevice
			? 'admin-root-wrapper is-touch'
			: 'admin-root-wrapper';

		const salesModelSettings = this.getSalesModelCard();
		const languageSettings = this.getLanguageSettingsCard();
		const brokenFiscalYear = this.getBrokenFiscalYearCard();
		const emailSync = this.getEmailSyncCard();
		const regionSettings = this.getRegionSettingsCard();
		const sessionTime = this.getSessionTimeoutCard();

		return (
			<div className={rootWrapperClassName}>
				<div id="component-wrapper" className={loading ? undefined : 'visible'}>
					<div id="admin-page-languagesettings">
						<AdminHeader
							title={this.lang.accountSettings}
							description={this.hasNewFields ? this.lang.description1 : this.lang.description2}
							image="accountSettings.svg"
						/>
						<div id="admin-content">
							{salesModelSettings}
							{languageSettings}
							{sessionTime}
							{brokenFiscalYear}
							{emailSync}
							{regionSettings}
							{this.getAutoAddProspectingDataCard()}
						</div>
					</div>
				</div>
				<SaveBtn
					visible={saveBtnVisible}
					saving={savingMasterLanguage}
					onClick={actions.saveMasterLanguage}
					text={this.lang.saveStandard}
				/>
				<div id="admin-loader" className={loading ? 'visible' : undefined}>
					<Loader />
				</div>
			</div>
		);
	}
}

export default AccountSettings;
