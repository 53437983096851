// This can be our future entry point
import './babel/modules';
import './fonts/upicons.scss';
import './styles/legacy-dynamic-styles';
import './babel/angularConfig';
import './babel/angular/general';
import './babel/angular/resources';
import './babel/angular/filters';
import './babel/angular/security';
import './babel/angular/components';
import App from './App';
import ReactDOM from 'react-dom';
import './index.scss';
import { mapDate, mapDates } from './resources/genericMapper';
import { parseDate, parseDates } from './resources/genericParser';
import LZString from 'lz-string';
import history from 'App/pages/routes/history';
import findAll from './babel/helpers/findAll';
import DataCache from 'App/babel/services/DataCache';
import { WithSplitProvider } from 'App/services/feature';

// NEW_LOGIN. Default value is false. This will be overridden by the feature flag in App
window.useReactInit = false;

const renderRoot = () => {
	ReactDOM.render(
		<WithSplitProvider>
			<App />
		</WithSplitProvider>,
		document.getElementById('react-root')
	);
};

renderRoot();

// TODO: Remove when getting rid of Tools
window.Tools.mapDate = mapDate;
window.Tools.mapDates = mapDates;
window.Tools.parseDate = parseDate;
window.Tools.parseDates = parseDates;
window.Tools.LZString = LZString;
window.Tools.routerHistory = history;
window.Tools.DataCache = DataCache;
window.Tools.findAll = findAll;

require('./babel/legacy-dynamic-load');
require('./babel/legacy-react');
require('./babel/angular/directives');
require('./babel/angular/common');
require('./babel/angular/domain');
