'use strict';

const { openFileBrowserModal } = require('Components/FileBrowser');

ReactTemplates.bannerEditor.editorElement = window.ReactCreateClass({
	getInitialState: function () {
		return {
			element: this.props.element
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState({ element: nextProps.element });
	},
	elementChanged: function (element) {
		this.setState({ element: element });
		this.props.elementChanged(element);
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			setImage: t('bannerEditor.setImage'),
			changeImage: t('bannerEditor.changeImage')
		};
	},
	toggleEnabled: function () {
		var element = this.state.element;
		element.enabled = !element.enabled;
		this.elementChanged(element);
	},
	valueChanged: function (e) {
		var element = this.state.element;
		element.value = e.target.value;
		this.elementChanged(element);
	},
	colorChange: function (key, value) {
		var element = this.state.element;
		element.style[key] = value;
		this.elementChanged(element);
	},
	calcTextareaHeight: function () {
		if (this.state.element.type === 'text' || this.state.element.type === 'button') {
			var textarea = this._textarea;
			textarea.style.height = 'auto';
			textarea.style.height = textarea.scrollHeight + 'px';
		}
	},
	componentDidMount: function () {
		this.calcTextareaHeight();
	},
	componentDidUpdate: function () {
		this.calcTextareaHeight();
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	browseImages: function () {
		var self = this;
		openFileBrowserModal({
			types: ['image'],
			public: true,
			selectOnUpload: true,
			sizeLimit: 100000 // limit to 100k in size
		})
			.then(function (files) {
				var element = self.state.element;
				element.value = files[0].$$publicUrl;
				element.fileSize = files[0].size;
				self.elementChanged(element);
			})
			.catch(err => {
				console.error(err);
			});
	},
	viewImg: function () {
		var win = window.open(this.state.element.value, '_blank');
		win.focus();
	},
	render: function () {
		var self = this;
		var element = this.state.element;
		var t = Tools.$translate;
		var inputs = null;
		var elemTools = [];

		if (!element.required) {
			elemTools.push(
				<ReactTemplates.upFilters.components.toggle
					key="toggle"
					checked={element.enabled}
					onChange={this.toggleEnabled}
					className="toggle-bright-blue"
				/>
			);
		}

		switch (element.type) {
			case 'button':
			case 'text':
				if (element.style.backgroundColor !== undefined) {
					elemTools.push(
						React.createElement(ReactTemplates.bannerEditor.colorPicker, {
							value: element.style.backgroundColor,
							isText: false,
							key: 'backgroundColor',
							presets: self.props.colorVariables,
							addPreset: self.props.addPreset,
							onChange: self.colorChange.bind(self, 'backgroundColor')
						})
					);
				}
				elemTools.push(
					React.createElement(ReactTemplates.bannerEditor.colorPicker, {
						value: element.style.color,
						key: 'color',
						isText: true,
						presets: self.props.colorVariables,
						addPreset: self.props.addPreset,
						onChange: self.colorChange.bind(self, 'color')
					})
				);

				inputs = (
					<div>
						<textarea
							value={element.value}
							onChange={this.valueChanged}
							ref={self.setRef.bind(self, '_textarea')}
						/>
					</div>
				);
				break;
			case 'image':
				var hasImg = element.value && element.value.length;
				var imageName = '';
				if (hasImg) {
					var splt = element.value.split('/');
					imageName = splt[splt.length - 1];
				}
				inputs = (
					<div>
						{hasImg ? (
							<button
								key="view-btn"
								type="button"
								className="up-btn btn-link btn-sm btn-bright-blue btn-block change-img-btn"
								onClick={this.browseImages}
							>
								<b className="fa fa-image"></b> {imageName}
								<span className="text-grey on-hover pull-right">{self.lang.changeImage}</span>
							</button>
						) : (
							<button
								type="button"
								className="up-btn btn-link btn-sm btn-bright-blue"
								onClick={this.browseImages}
							>
								{self.lang.setImage}
							</button>
						)}
					</div>
				);
		}

		return (
			<div className="sidebar-section">
				<div className="sidebar-header">
					<h2>{t(element.lang)}</h2>
					<div className="element-tools">{elemTools}</div>
				</div>
				{inputs}
			</div>
		);
	}
});
