import Prospecting from 'App/babel/resources/Prospecting';
import RequestBuilder, { comparisonTypes as ComparisonTypes } from 'Resources/RequestBuilder';

const fetcher = (
	rb: RequestBuilder,
	{ orgNumbers, prospectingId }: { orgNumbers: string[]; prospectingId?: string }
) => {
	const countryCode = (prospectingId ?? '').split('.')[0];
	if (!orgNumbers.length || !countryCode) {
		return Promise.resolve({ data: [], metadata: { total: 0 } });
	}
	rb.addFilter({ field: 'orgNumber' }, ComparisonTypes.Equals, orgNumbers);

	if (countryCode === 'SE') {
		const orBuilder = rb.orBuilder();
		orBuilder.next();
		orBuilder.addFilter({ field: 'toDate' }, ComparisonTypes.Equals, '0000-00-00');
		orBuilder.next();
		orBuilder.addFilter({ field: 'toDate' }, ComparisonTypes.Equals, null);
		orBuilder.done();
	}

	rb.extraParams.push({
		key: 'country',
		value: countryCode
	});
	return Prospecting.findDirectors(rb.build());
};
export default fetcher;
