'use strict';

const { Toggle } = require('@upsales/components');
const { openFileBrowserModal } = require('Components/FileBrowser');

ReactTemplates.form.cardContentType = window.ReactCreateClass({
	getInitialState: function () {
		return {
			element: this.props.element
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState({ element: nextProps.element });
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			image: t('bannereditor.image'),
			setImage: t('bannerEditor.setImage'),
			changeImage: t('bannerEditor.changeImage'),
			opacity: t('bannerEditor.opacity'),
			title: t('default.title'),
			description: t('default.description'),
			style: t('landingpage.style'),
			subtitle: t('landingpage.subtitle'),
			text: t('bannerEditor.text'),
			background: t('bannerEditor.background'),
			backgroundColor: t('form.backgroundColor'),
			imgSize: t('landingpage.imgSize')
		};

		var sizeOpts = ['initial', 'cover', 'contain'];
		this.sizeOpts = _.map(sizeOpts, function (size, i) {
			return (
				<option key={'size' + i} value={size}>
					{t('landingpage.img.' + size)}
				</option>
			);
		});
	},
	browseImages: function () {
		var self = this;
		openFileBrowserModal({
			types: ['image'],
			public: true,
			selectOnUpload: true
		})
			.then(function (files) {
				var element = self.state.element;
				element.value.imgSrc = files[0].$$publicUrl;
				self.setState({ element: element });
				self.props.onChange(element);
			})
			.catch(err => {
				console.error(err);
			});
	},
	opacityChanged: function (value) {
		var element = this.state.element;

		element.value.imgOpacity = value;

		this.setState({ element: element });
		this.props.onChange(element);
	},
	valuePropChanged: function (key, e) {
		var element = this.state.element;
		element.value[key] = e.target.value;
		this.setState({ element: element });
		this.props.onChange(element);
	},
	removeImg: function () {
		var element = this.state.element;
		element.value.imgSrc = '';
		this.setState({ element: element });
		this.props.onChange(element);
	},
	colorChange: function (key, value) {
		var element = this.state.element;

		element.value[key] = value;
		this.setState({ element: element });
		this.props.onChange(element);
	},
	useBgChanged: function () {
		if (this.state.element.value.backgroundColor === 'transparent') {
			this.colorChange('backgroundColor', '#ffffff');
		} else {
			this.colorChange('backgroundColor', 'transparent');
		}
	},
	render: function () {
		var self = this;
		var imgElem = null;
		var element = self.state.element;
		var hasImg = element.value.imgSrc && element.value.imgSrc.length;

		if (hasImg) {
			var style = {
				opacity: element.value.imgOpacity / 100,
				backgroundImage: "url('" + element.value.imgSrc + "')"
			};
			imgElem = <div className="img-element" style={style}></div>;
		}

		var useBg = true;

		if (element.value.backgroundColor === 'transparent') {
			useBg = false;
		}

		var titleClass = 'floating-label-input';
		if (element.value.title && element.value.title.length) {
			titleClass += ' has-value';
		}

		var subtitleClass = 'floating-label-input';
		if (element.value.subtitle && element.value.subtitle.length) {
			subtitleClass += ' has-value';
		}

		var textClass = 'floating-label-textarea';
		if (element.value.text && element.value.text.length) {
			textClass += ' has-value';
		}

		return (
			<div>
				<div className="tool-main-section">
					<div className="tool-section" key="img-row">
						<label>{self.lang.image}</label>
						{hasImg ? (
							<button
								className="up-btn btn-grey btn-link btn-hover-red btn-sm pull-right"
								onClick={self.removeImg}
							>
								<b className="fa fa-trash-o"></b>
							</button>
						) : null}
						<div className="img-preview">
							{imgElem}

							<button className="up-btn btn-green" onClick={self.browseImages}>
								{imgElem ? self.lang.changeImage : self.lang.setImage}
							</button>
						</div>
					</div>
					{hasImg ? (
						<div className="tool-section" key="opacity-row">
							<label>{self.lang.opacity}</label>
							<div className="pull-right">
								<ReactTemplates.upStepInt
									min={0}
									max={100}
									value={element.value.imgOpacity}
									className="input-sm pull-right"
									onChange={self.opacityChanged}
								/>
							</div>
						</div>
					) : null}
					{hasImg ? (
						<div className="tool-section" key="size-row">
							<label>{self.lang.imgSize}</label>
							<select
								className="form-control inline-select input-sm pull-right"
								value={element.value.imgSize}
								onChange={self.valuePropChanged.bind(self, 'imgSize')}
							>
								{self.sizeOpts}
							</select>
						</div>
					) : null}
					<div className="tool-section" key="title-row">
						<div className={titleClass}>
							<label>{self.lang.title}</label>
							<input
								type="text"
								value={element.value.title}
								onChange={self.valuePropChanged.bind(self, 'title')}
							/>
						</div>
					</div>
					<div className="tool-section" key="subtitle-row">
						<div className={subtitleClass}>
							<label>{self.lang.subtitle}</label>
							<input
								type="text"
								value={element.value.subtitle}
								onChange={self.valuePropChanged.bind(self, 'subtitle')}
							/>
						</div>
					</div>

					<div className="tool-section">
						<div className={textClass}>
							<label>{self.lang.text}</label>
							<Input.textarea
								floating
								value={element.value.text}
								onChange={self.valuePropChanged.bind(self, 'text')}
							></Input.textarea>
						</div>
					</div>
				</div>

				<div className="tool-header">
					<h3>{this.lang.style}</h3>
				</div>

				<div className="tool-main-section no-margin">
					<div className="tool-header">
						<h3>{this.lang.background}</h3>
						<div className="pull-right">
							<Toggle checked={useBg} onChange={self.useBgChanged} color="medium-green" />
						</div>
					</div>
					{useBg ? (
						<div className="tool-section" key="bg">
							<label>{this.lang.backgroundColor}</label>
							<div className="pull-right">
								{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
									value: element.value.backgroundColor || '#ffffff',
									isText: false,
									presets: self.props.colorVariables,
									addPreset: self.props.addPreset,
									onChange: self.colorChange.bind(self, 'backgroundColor')
								})}
							</div>
						</div>
					) : null}
				</div>
				<div className="tool-main-section">
					<div className="tool-header">
						<h3>{this.lang.text}</h3>
					</div>
					<div className="tool-section">
						<label>{this.lang.title}</label>
						<div className="pull-right">
							{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
								value: element.value.titleColor || '#000000',
								isText: false,
								presets: self.props.colorVariables,
								addPreset: self.props.addPreset,
								onChange: self.colorChange.bind(self, 'titleColor')
							})}
						</div>
					</div>
					<div className="tool-section">
						<label>{this.lang.description}</label>
						<div className="pull-right">
							{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
								value: element.value.textColor || '#000000',
								isText: false,
								presets: self.props.colorVariables,
								addPreset: self.props.addPreset,
								onChange: self.colorChange.bind(self, 'textColor')
							})}
						</div>
					</div>
				</div>
			</div>
		);
	}
});
