import React from 'react';
import PropTypes from 'prop-types';
import Input from '@upsales/components/Input';
import Button from '@upsales/components/Button';
import bemClass from '@upsales/components/Utils/bemClass';
import { LeadStatus, TableIndicator, Toggle, Loader, ButtonSelect, Icon, Tooltip } from '@upsales/components';
import AccountListWrapper from '../AccountListWrapper/AccountListWrapper';
import List from '../AccountList/AccountList';
import { leadStatus as leadStatusTooltips } from '../../Helpers/TooltipHelper';

import Name from './Columns/Name';
import Phone from './Columns/Phone';
import Email from './Columns/Email';
import Controls from './Columns/Controls';
import SubaccountsColumn from './Columns/SubaccountsColumn';

import './AccountListContacts.scss';
import _ from 'lodash';
import T from 'Components/Helpers/translate';

const propTypes = {
	actions: PropTypes.object.isRequired,
	accountContacts: PropTypes.array.isRequired,
	searchByRelevance: PropTypes.bool,
	showInactiveContacts: PropTypes.bool,
	accountId: PropTypes.number.isRequired,
	tableLoading: PropTypes.bool,
	account: PropTypes.object,
	subAccountIds: PropTypes.array
};

class AccountListContacts extends React.Component {
	constructor(props) {
		super(props);

		const statics = ReactTemplates.ROWSTATICS.contactRow({ $translate: T });
		const { editContact, removeContact } = this.props.actions;
		const subAccountIds = this.props.subAccountIds;
		const tooltips = {
			market: contact => ['visit', 'mail', 'form'].map(type => T(leadStatusTooltips(type, contact))),
			sales: contact => ['activity', 'opportunity', 'order'].map(type => T(leadStatusTooltips(type, contact)))
		};

		this.columns = [
			{
				key: 'name',
				title: T('default.name'),
				render: item => (
					<Name key={'name-' + item.id} contact={item} client={item.client} accountId={props.accountId} />
				),
				grow: 4,
				width: 0
			},
			{
				key: 'actualphone',
				title: T('default.phone'),
				render: item => <Phone key={'actualphone-' + item.id} contact={item} />,
				grow: 2,
				width: 0
			},
			{
				key: 'cellphone',
				title: T('default.cellPhone'),
				render: item => <Phone key={'cellphone-' + item.id} contact={item} cellphone />,
				grow: 2,
				width: 0
			}
		];

		if (Tools.FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS') && subAccountIds?.length > 0) {
			this.columns.splice(1, 0, {
				key: 'subaccount',
				title: T('account.subaccount'),
				render: item =>
					item.client?.operationalAccount?.id ? <SubaccountsColumn client={item.client} /> : null,
				grow: 2,
				width: 0
			});
		}

		if (
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.VISITS) &&
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.EMAIL) &&
			Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FORMS)
		) {
			this.columns.push({
				key: 'markethistory',
				title: T('default.marketActivity'),
				render: item => (
					<LeadStatus
						key={'markethistory-' + item.id + (item.searchByRelevance ? '-searchByRelevance' : '-alpha')}
						className="Column"
						contact={item}
						market
						size={'small'}
						tooltips={tooltips.market(item)}
					/>
				),
				grow: 2,
				width: 0,
				hideOnWidth: 1366
			});
		}

		this.columns.push({
			key: 'score',
			title: T('default.score'),
			render: item => <div key={'score-' + item.id} className="Column">{`${item.score || 0}p`}</div>,
			grow: 1,
			width: 0,
			hideOnWidth: 1199
		});
		this.columns.push({
			key: 'history',
			title: T('default.history'),
			render: item => (
				<LeadStatus
					key={'saleshistory-' + item.id + (item.searchByRelevance ? '-searchByRelevance' : '-alpha')}
					contact={item}
					size={'small'}
					tooltips={tooltips.sales(item)}
				/>
			),
			grow: 2,
			width: 0,
			hideOnWidth: 1022
		});
		this.columns.push({
			key: 'email',
			title: T('default.email'),
			render: item => <Email key={'email-' + item.id} contact={item} statics={statics} />,
			grow: 2,
			width: 100
		});
		this.columns.push({
			key: 'controls',
			title: '',
			render: item => (
				<Controls
					key={'controls' + item.id}
					contact={item}
					statics={statics}
					entity={'default.contact'}
					removeEntity={() => removeContact(item)}
					editEntity={() => editContact(item)}
				/>
			),
			grow: 0,
			width: 64,
			marginRight: '0px'
		});

		this.lang = {
			contacts: T('default.contacts'),
			addContact: T('default.addContact'),
			activeContacts: T('default.activeContacts'),
			searchContact: T('default.searchContact'),
			relevance: T('default.relevance'),
			aToZ: T('default.aToZ'),
			showInactiveContacts: T('default.showInactiveContacts'),
			showBoardMembers: T('prospecting.showBoardMembers')
		};

		this.charArray = _.map(T('CHARARRAY'), c => c);
		this.doOnScroll = true;

		this.state = {
			rows: [],
			numActiveContacts: 0,
			indicatorIndex: 0,
			showDirectorsButton: true,
			toggleTooltipActive: false
		};

		this.onScroll = this.onScroll.bind(this);
		this.renderRow = this.renderRow.bind(this);
		this.scrollToRow = this.scrollToRow.bind(this);
		this.getRowHeight = this.getRowHeight.bind(this);
		this.calcScollHeight = _.throttle(this.calcScollHeight.bind(this), 500);
		this.renderTableHeader = this.renderTableHeader.bind(this);
	}
	onResize = _.debounce(() => {
		const controlsWrapper = document.getElementsByClassName('AccountListContacts__control')[0];
		const wrapperHeader = document.getElementsByClassName('wrapper-header')[0];

		if (wrapperHeader?.getBoundingClientRect?.().right > controlsWrapper?.getBoundingClientRect?.().left + 25) {
			if (!this.state.toggleTooltipActive) {
				this.setState({ toggleTooltipActive: true });
			}
		} else {
			if (this.state.toggleTooltipActive) {
				this.setState({ toggleTooltipActive: false });
			}
		}
	}, 100);

	componentDidMount() {
		window.addEventListener('resize', this.onResize);
		this.onResize();
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillReceiveProps(newProps) {
		if (newProps.accountContacts.length !== this.props.accountContacts.length) {
			const numActiveContacts = _.filter(newProps.accountContacts, c => c.active).length;
			this.setState({ numActiveContacts });
		}
	}

	onScroll({ scrollTop }) {
		const listWrapper = document.getElementsByClassName('AccountListBoardOfDirectors')[0];
		if (listWrapper && listWrapper.offsetTop < scrollTop + 80) {
			this.setState({ showDirectorsButton: false });
		} else if (listWrapper && !this.state.showDirectorsButton && listWrapper.offsetTop >= scrollTop + 80) {
			this.setState({ showDirectorsButton: true });
		}
		const { searchByRelevance, accountContacts } = this.props;
		if (!searchByRelevance && this.doOnScroll) {
			if (scrollTop === 0) {
				this.setState({
					indicatorIndex: 0
				});
			} else {
				const index = this.calcScollHeight(accountContacts, scrollTop);
				if (index !== null && accountContacts.length) {
					const indicatorIndex = _.findIndex(
						this.charArray,
						c => c.toLowerCase() === (accountContacts[index].name[0] || '').toLowerCase()
					);
					this.setState({
						indicatorIndex: indicatorIndex >= 0 ? indicatorIndex : 0
					});
				}
			}
		}
	}

	calcScollHeight(accountContacts, scrollTop) {
		let calcHeight = 0;
		let index = null;
		for (index = 0; index < accountContacts.length - 1; index++) {
			calcHeight += this.getRowHeight({ index });
			if (calcHeight >= scrollTop) {
				break;
			}
		}
		return index;
	}

	findClosestRow(char) {
		const { accountContacts } = this.props;
		let scrollTop = 0;
		for (var i = 0; i < accountContacts.length; i++) {
			if (accountContacts[i].delimiter && accountContacts[i].name.toLowerCase() === char.toLowerCase()) {
				break;
			} else if (i === accountContacts.length - 1) {
				// Char not found
				const index = _.findIndex(this.charArray, c => c === char);
				if (index > 0) {
					// Search char before in char array
					const charBefore = this.charArray[index - 1];
					return this.findClosestRow(charBefore);
				} else {
					// Return first
					return 0;
				}
			}
			scrollTop += this.getRowHeight({ index: i });
		}
		return scrollTop;
	}

	scrollToRow(char, indicatorIndex) {
		this.doOnScroll = false;

		const scrollTop = this.findClosestRow(char);
		window.scrollTo({ top: scrollTop });

		// Execute after onScroll has been triggered
		setTimeout(() => {
			this.setState({ indicatorIndex });
			this.doOnScroll = true;
			if (scrollTop !== document.documentElement.scrollTop) {
				window.scrollTo({ top: scrollTop });
			}
		}, 100);
	}

	getRowHeight({ index }) {
		const item = this.props.accountContacts[index];
		return item.delimiter ? 20 : 40;
	}
	scrollToListBoadOfDirectors() {
		const element = document.getElementsByClassName('AccountListBoardOfDirectors')[0];
		const yOffset = -150;
		const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

		window.scrollTo({ top: y, behavior: 'smooth', block: 'start' });
	}
	renderRow({ index, key, style }) {
		const item = this.props.accountContacts[index];
		const searchByRelevance = this.props.searchByRelevance;
		const openContact = this.props.actions.openContact;
		const rowClassNames = ['AccountListContacts__List-row'];
		if (item.active === 0) {
			rowClassNames.push('AccountListContacts__List-row-inactive');
		}
		item.searchByRelevance = searchByRelevance;
		return item.delimiter ? (
			<div className="AccountListContacts__List-delimiter-row" key={key} style={style}>
				{item.name}
			</div>
		) : (
			<div
				id={item.id}
				className={rowClassNames.join(' ')}
				key={key}
				style={style}
				onClick={() => openContact(item.id)}
			>
				{this.columns.map((col, i) =>
					window.innerWidth < col.hideOnWidth ? null : (
						<div
							key={'contact-row-' + i}
							style={{ flex: `${col.grow} 1 ${col.width}px`, marginRight: col.marginRight || '10px' }}
						>
							{col.render(item)}
						</div>
					)
				)}
			</div>
		);
	}

	renderTableHeader() {
		return (
			<div className="AccountListContacts__TableHeader">
				{this.columns.map((c, i) => (
					<div key={'header-' + i} style={{ flex: `${c.grow} 1 ${c.width}px`, marginRight: '10px' }}>
						{c.title}
					</div>
				))}
			</div>
		);
	}

	render() {
		const classes = new bemClass('AccountListContacts');
		const {
			account,
			searchByRelevance,
			showInactiveContacts,
			tableLoading,
			actions: { searchChanged, toogleSearchBy, toogleshowInactiveContacts, createContact }
		} = this.props;
		const { numActiveContacts, indicatorIndex } = this.state;
		const hasProOrAdanced = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO);
		const hasSplitButtonClientCard = Tools.FeatureHelper.hasSoftDeployAccess('SPLIT_BUTTON_CLIENT_CARD');

		const hasDirectors =
			account && account.prospecting && account.prospecting.directors && account.prospecting.directors.length > 0;

		return (
			<div className="AccountListContacts">
				<AccountListWrapper
					title={this.lang.contacts}
					subtitle={`${numActiveContacts} ${this.lang.activeContacts}`}
					subtitleElement={
						hasProOrAdanced && this.state.showDirectorsButton && hasDirectors ? (
							<Button
								className="AccountListContacts__showDirectorsButton"
								type="link"
								onClick={this.scrollToListBoadOfDirectors}
							>
								{this.lang.showBoardMembers}
							</Button>
						) : null
					}
				>
					<div className="AccountListContacts__control">
						<div className="only-active-toggle-container">
							<span
								className={`only-active-toggle-container-label${
									this.state.toggleTooltipActive ? '--hide' : ''
								}`}
							>
								{this.lang.showInactiveContacts}
							</span>
							<Tooltip disabled={!this.state.toggleTooltipActive} title={this.lang.showInactiveContacts}>
								<Toggle
									icon={this.state.toggleTooltipActive ? 'user-slash' : ''}
									checked={showInactiveContacts}
									onChange={value => toogleshowInactiveContacts(value)}
								/>
							</Tooltip>
						</div>
						<div className="search-container">
							<Input
								icon="search"
								autoFocus
								onChange={e => searchChanged(e.target.value)}
								placeholder={this.lang.searchContact}
								onFocus={this.onResize}
								onBlur={this.onResize}
							/>
						</div>
						<ButtonSelect
							disabled={tableLoading}
							options={[
								{ value: 'relevance', title: this.lang.relevance },
								{ value: 'aToZ', title: this.lang.aToZ }
							]}
							value={searchByRelevance ? 'relevance' : 'aToZ'}
							onChange={v => toogleSearchBy(v === 'relevance')}
						/>
						{account?.createRights?.Contact && !hasSplitButtonClientCard ? (
							<Button
								className={classes.elem('createContactButton').b()}
								style={{ marginLeft: '10px' }}
								shadow="none"
								color="green"
								onClick={() => createContact()}
							>
								<Icon name="plus" />
								{' ' + this.lang.addContact}
							</Button>
						) : null}
					</div>
					{searchByRelevance || !this.state.showDirectorsButton ? null : (
						<div className="AccountListContacts__TableIndicator">
							<TableIndicator
								array={this.charArray}
								index={indicatorIndex}
								color="grey"
								onClick={this.scrollToRow}
							/>
						</div>
					)}
					{this.renderTableHeader()}
				</AccountListWrapper>
				{tableLoading ? (
					<div>
						<Loader size="sm" className="AccountListContacts__loader" />
					</div>
				) : (
					<List
						key={`contact-list-${searchByRelevance ? 'relevance' : 'alpha'}`}
						size={this.props.accountContacts.length}
						marginTop={searchByRelevance ? 86 : 104}
						getRowHeight={this.getRowHeight}
						rowRenderer={this.renderRow}
						onScroll={this.onScroll}
						// Used to force update list
						forceUpdate={searchByRelevance}
					/>
				)}
			</div>
		);
	}
}

AccountListContacts.propTypes = propTypes;
export default AccountListContacts;
