import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { CancelablePromise, makeCancelable } from 'Helpers/promise';
import { fixProspectingData } from 'Store/reducers/AccountReducer';
import { currencyFormat } from 'Components/Filters/Currencies';
import { useMetadata } from 'App/components/hooks/appHooks';
import { getSalesReport, getSalesTabSubtitle } from 'App/pages/CompanyGroupCard/Helpers/CompanyGroupCardHelpers';
import Client from 'App/resources/Model/Client';
import logError from 'Helpers/logError';
import CompanyGroupTreeHeader from 'App/pages/CompanyGroupCard/CompanyGroupTreeHeader';
import history from 'App/pages/routes/history';
import _ from 'lodash';

import {
	fetchContactNumbers,
	fetchPipelineNumbers,
	fetchPipelineExistingNumbers,
	getProspectingGroup,
	fetchCompanies,
	handleRequest
} from 'App/pages/CompanyGroupCard/Helpers/CompanyGroupCardHelpers';

const MAGIC_ELASTIC_DELAY = 5000;

type Props = {
	tree: any;
	prospectingId: string;
	view: string;
	setView: (view: string) => void;
	addAllAccounts: () => void;
	manualRelationsCounter: number;
};

const defaultTreeMeta = { total: 0, unknowns: [], unbought: [], unboughtKnown: [] };

const CompanyGroupAccountRelations = ({
	prospectingId,
	tree,
	view,
	setView,
	addAllAccounts,
	manualRelationsCounter
}: Props) => {
	const headerReq = useRef<CancelablePromise | null>(null);
	const prospectingReq = useRef<CancelablePromise | null>(null);
	const contactRef = useRef<CancelablePromise | null>(null);
	const pipelineRef = useRef<CancelablePromise | null>(null);

	const [companies, setCompanies] = useState<Client[]>([]);
	const [treeMeta, setTreeMeta] = useState(defaultTreeMeta);
	const [companyTree, setCompanyTree] = useState<any>(null);
	const [clientIds, setClientIds] = useState<number[]>([]);
	const [numberOfContacts, setNumberOfContacts] = useState<number | null>(null);
	const [salesData, setSalesData] = useState<{ total: null | number }>({ total: null });
	const [opportunityData, setOpportunityData] = useState<{
		count: number;
		total: null | number;
		existingPipeline: number;
	}>({ count: 0, total: null, existingPipeline: 0 });

	const currency = useMetadata()?.defaultCurrency.iso || 'SEK';
	const metadata = useMetadata();

	const clientIdsString = clientIds.join(',');

	const onTreeFetch = (tree: any) => {
		const clonedTreeMeta = _.cloneDeep(defaultTreeMeta);
		const clonedTree = _.cloneDeep(tree);
		const updatedTreeMeta = fixProspectingData(clonedTreeMeta, clonedTree);

		setCompanyTree(clonedTree);
		setTreeMeta(updatedTreeMeta);
	};

	const { SalesModel: salesModel, SalesModelOption: salesModelOption } = metadata?.params || { SalesModel: 'sales' };

	const salesSubTitle =
		salesData.total === null
			? undefined
			: currencyFormat(salesData.total, currency) + ' ' + getSalesTabSubtitle(salesModel, salesModelOption);

	const fetchProspectingData = useCallback(() => {
		if (!prospectingId) {
			return;
		}
		if (prospectingReq.current) {
			prospectingReq.current.cancel();
		}

		prospectingReq.current = makeCancelable(getProspectingGroup(prospectingId));

		prospectingReq.current.promise
			.then(clients => {
				setClientIds(clients.data.clientIds);
			})
			.catch(e => {
				logError(e, 'Failed to fetch clients');
			});
	}, [prospectingId]);

	useEffect(() => {
		const companyReq = handleRequest(clientIds, fetchCompanies, setCompanies, 'companies');

		return () => {
			companyReq.cancel();
		};
	}, [clientIdsString]);

	useEffect(() => {
		onTreeFetch(tree);
	}, [tree]);

	const numberOfCustomers = useMemo(() => {
		if (!companies) {
			return null;
		}

		return companies.filter(company => company.journeyStep === 'customer' && !company.operationalAccount).length;
		// Update the number of customers when a new company is added from the tree
	}, [companies.length]);

	useEffect(() => {
		fetchProspectingData();

		return () => {
			if (prospectingReq.current) {
				prospectingReq.current.cancel();
			}
		};
	}, [prospectingId]);

	useEffect(() => {
		headerReq.current?.cancel?.();
		contactRef.current?.cancel?.();
		pipelineRef.current?.cancel?.();

		if (clientIds.length === 0) {
			setNumberOfContacts(0);
			setSalesData({ total: 0 });
			setOpportunityData({
				total: 0,
				count: 0,
				existingPipeline: 0
			});
			return;
		}

		const updateNumberNumbers = () => {
			const contactReq = fetchContactNumbers(clientIds);
			const salesReq = getSalesReport(clientIds, salesModel, salesModelOption).promise;
			const pipelineReq = fetchPipelineNumbers(clientIds);
			const pipelineExistingReq = fetchPipelineExistingNumbers(clientIds);

			headerReq.current = makeCancelable(Promise.all([contactReq, salesReq, pipelineReq, pipelineExistingReq]));

			headerReq.current.promise
				.then(([contact, sales, pipeline, pipelineExisting]) => {
					setNumberOfContacts(contact.metadata.total);
					setSalesData({ total: sales.data.result.value });
					setOpportunityData({
						total: pipeline.data.sum_valueInMasterCurrency.value,
						count: pipeline.data.sum_valueInMasterCurrency.doc_count,
						existingPipeline: pipelineExisting.data.sum_valueInMasterCurrency.value
					});
				})
				.catch(e => logError(e, 'Failed to fetch data - CompanyGroupAccountRelations'));
		};

		updateNumberNumbers();

		const updateContactNumbers = () => {
			const contactReq = fetchContactNumbers(clientIds);
			contactRef.current = makeCancelable(contactReq);

			contactRef.current.promise
				.then(contact => {
					setNumberOfContacts(contact.metadata.total);
				})
				.catch(e => logError(e, 'Failed to fetch data - CompanyGroupAccountRelations'));
		};

		const updatePipelineNumbers = () => {
			const pipelineReq = fetchPipelineNumbers(clientIds);
			const pipelineExistingReq = fetchPipelineExistingNumbers(clientIds);
			pipelineRef.current = makeCancelable(Promise.all([pipelineReq, pipelineExistingReq]));

			pipelineRef.current.promise
				.then(([pipeline, pipelineExisting]) => {
					setOpportunityData({
						total: pipeline.data.sum_valueInMasterCurrency.value,
						count: pipeline.data.sum_valueInMasterCurrency.doc_count,
						existingPipeline: pipelineExisting.data.sum_valueInMasterCurrency.value
					});
				})
				.catch(e => logError(e, 'Failed to fetch data - CompanyGroupAccountRelations'));
		};

		const listeners = [
			// f u elastic
			//Contacts
			Tools.$rootScope.$on('contact.added', () => setTimeout(() => updateContactNumbers(), MAGIC_ELASTIC_DELAY)),
			Tools.$rootScope.$on('contact.updated', () =>
				setTimeout(() => updateContactNumbers(), MAGIC_ELASTIC_DELAY)
			),
			Tools.$rootScope.$on('contact.deleted', () =>
				setTimeout(() => updateContactNumbers(), MAGIC_ELASTIC_DELAY)
			),
			//Oppurtunities
			Tools.$rootScope.$on('order.added', () => setTimeout(() => updatePipelineNumbers(), MAGIC_ELASTIC_DELAY)),
			Tools.$rootScope.$on('order.updated', () => setTimeout(() => updatePipelineNumbers(), MAGIC_ELASTIC_DELAY)),
			Tools.$rootScope.$on('order.deleted', () => setTimeout(() => updatePipelineNumbers(), MAGIC_ELASTIC_DELAY)),
			Tools.$rootScope.$on('opportunity.added', () =>
				setTimeout(() => updatePipelineNumbers(), MAGIC_ELASTIC_DELAY)
			),
			Tools.$rootScope.$on('opportunity.updated', () =>
				setTimeout(() => updatePipelineNumbers(), MAGIC_ELASTIC_DELAY)
			),
			Tools.$rootScope.$on('opportunity.deleted', () =>
				setTimeout(() => updatePipelineNumbers(), MAGIC_ELASTIC_DELAY)
			)
		];

		return () => {
			listeners.forEach(unsub => unsub());

			headerReq.current?.cancel?.();
			contactRef.current?.cancel?.();
			pipelineRef.current?.cancel?.();
		};
	}, [clientIds]);

	const goToCompanyGroup = () => {
		history.push(`/companyGroup/${prospectingId}/`);
	};

	return (
		<CompanyGroupTreeHeader
			groupSales={salesSubTitle}
			groupPipeline={opportunityData.total === null ? undefined : currencyFormat(opportunityData.total, currency)}
			numberOfCustomers={numberOfCustomers}
			numberOfContacts={numberOfContacts}
			tree={companyTree}
			treeMeta={treeMeta}
			showConsolidated
			setView={setView}
			view={view}
			isRelationsTab
			addAllAccounts={addAllAccounts}
			goToCompanyGroup={goToCompanyGroup}
			manualRelationsCounter={manualRelationsCounter}
		/>
	);
};

export default CompanyGroupAccountRelations;
