import { openDrawer } from 'Services/Drawer';
import EventActivity from 'App/babel/components/EventActivity';
import logError from 'App/babel/helpers/logError';
import ActivityResource from 'Resources/Activity';
import { activityOutcomeTracker } from 'App/babel/helpers/Tracker';
import { DISQUALIFIED, statusDisqualifiable } from 'Components/Helpers/journeyStep';
import {
	ANSWER_ACTIONS,
	FOUND_LEAD_ACTIONS,
	OUTCOME_TYPES,
	ANSWER_ACTIONS_GREAT
} from 'App/babel/enum/activityOutcome';
import Comment from 'Resources/Comment';
import { TYPES } from 'Components/Helpers/SourceHelper';
import FieldTranslation from 'App/babel/resources/FieldTranslations';
import openModal from 'App/services/Modal';

angular.module('upDirectives').directive('upEventActivity', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventActivity.tpl.html?file'),
		require: 'upEventActivity',
		scope: {
			event: '=upEventActivity',
			noIcon: '@'
		},
		controller: [
			'$scope',
			'$stateParams',
			'$upModal',
			'Activity',
			'$safeDigest',
			'$timeout',
			'FeatureHelper',
			'Contact',
			'ScriptService',
			'$translate',
			'$safeApply',
			'Appointment',
			'Account',
			function (
				$scope,
				$stateParams,
				$upModal,
				Activity,
				$safeDigest,
				$timeout,
				FeatureHelper,
				Contact,
				ScriptService,
				$translate,
				$safeApply,
				Appointment,
				Account
			) {
				$scope.customerId = Tools.AppService.getCustomerId();
				$scope.metadata = Tools.AppService.getMetadata();
				$scope.hasPriority =
					FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION') &&
					$scope.event.activity.hasHighPriority;
				$scope.selectedActivityId = $stateParams.activityId ? parseInt($stateParams.activityId) : null;
				$scope.hasActivityOutcomeAccess = FeatureHelper.isAvailable(
					Tools.FeatureHelper.Feature.ACTIVITY_OUTCOMES
				);
				$scope.rootData = {};

				const outcomeContactActions = [ANSWER_ACTIONS.TALK_TO_SOMEONE, FOUND_LEAD_ACTIONS.ADDED_CONTACT];

				if ($scope.selectedActivityId === $scope.event.entityId && $scope.hasActivityOutcomeAccess) {
					$scope.event.$$loading = true;
					getActivity($stateParams.customerId);
				}
				const activityTypeId = $scope.event.activity?.activityType?.id;
				const tdt = Tools.AppService.getTodoTypes();

				$scope.isTodo = activityTypeId === tdt.TODO.id;
				const tracker = outcome => {
					if (!outcome) {
						return;
					}

					let datePreset = 'no_postpone';
					if (outcome.type === 'postpone' || outcome.outcome === 'postponed') {
						datePreset = outcome.date;
					}
					activityOutcomeTracker.track(activityOutcomeTracker.events.CREATE_EVENT, {
						location: 'timeline',
						type: outcome.type,
						outcome: outcome.outcome,
						date_preset: datePreset
					});
				};

				const closeAndApply = () => {
					$scope.event.$$expand = false;
					$safeApply($scope);
				};

				$scope.openActivity = () => {
					var options = {
						id: $scope.event.entityId
					};

					if ($scope.isTodo) {
						openDrawer('EditTodo', { todo: { id: $scope.event.entityId } });
						return;
					}
					if (tdt && tdt.PHONE_CALL && tdt.PHONE_CALL.id === activityTypeId) {
						openDrawer('EditPhonecall', {
							activity: {
								id: $scope.event.entityId,
								...$scope.event.activity,
								client: $scope.event.client,
								contacts: $scope.event.contacts
							}
						});
						return;
					}

					$upModal.open('editActivity', options);
				};

				$scope.editOpportunity = function () {
					var options = {
						customerId: $stateParams.customerId,
						type: 'opportunity',
						id: $scope.rootData.eventDetails.opportunity.id
					};

					$upModal.open('editOrder', options);
				};

				$scope.isPassed = function () {
					if (moment($scope.event.date).endOf('day').isBefore()) {
						return true;
					}
					return false;
				};

				$scope.printActivityType = function (isOverdue) {
					const activityTypeId = $scope.event.activity?.activityType?.id;
					if (activityTypeId === tdt.PHONE_CALL.id) {
						return isOverdue
							? $translate.instant('default.shouldHaveCompletedPhonecall')
							: $translate.instant('default.hasPlannedPhonecall');
					} else if (activityTypeId === tdt.TODO.id) {
						return isOverdue
							? $translate.instant('default.shouldHaveCompletedTodo')
							: $translate.instant('default.hasPlannedTodo');
					} else {
						return isOverdue
							? $translate.instant('default.shouldHaveCompletedActivity')
							: $translate.instant('default.hasPlannedActivity');
					}
				};

				$scope.isPhonecall = function () {
					const activityType = $scope.event.activity?.activityType;
					return activityType?.id === tdt.PHONE_CALL.id;
				};

				$scope.getClosedText = function () {
					let text = 'default.phonecall';
					if ($scope.isPhonecall()) {
						text = $scope.event.contacts?.length
							? $scope.event.activity?.outcomeType === OUTCOME_TYPES.NO_ANSWER
								? 'activity.outcomeLog.didntReached'
								: 'activity.outcomeLog.reached'
							: '';
					}
					return $translate.instant(text).toLowerCase();
				};

				$scope.getActivityType = function () {
					if ($scope.isPhonecall()) {
						return $translate.instant('default.phonecall');
					}
					return $scope.event.activity?.activityType?.name || '';
				};

				$scope.expand = function () {
					if (!$scope.isTodo) {
						if ($scope.event.$$expand) {
							$scope.event.$$expand = false;
							return $timeout(function () {
								$safeDigest($scope);
							}, 400);
						}
						$scope.event.$$loading = true;

						getActivity($stateParams.customerId);
					}
				};

				$scope.event.createCommentIfExists = async function (commentDesc) {
					let comment;
					if (commentDesc) {
						({ data: comment } = await Comment.save({
							description: commentDesc,
							client: $scope.rootData.eventDetails.contact?.client || $scope.rootData.eventDetails.client,
							activity: $scope.rootData.eventDetails,
							user: Tools.AppService.getSelf(),
							outcomeType: $scope.rootData.eventDetails.outcome.type
						}));
					}
					return comment;
				};

				$scope.EventActivityComponent = EventActivity;

				function getActivity(customerId) {
					Activity.customer(customerId)
						.get($scope.event.entityId)
						.then(function (res) {
							if (!res.data) {
								$scope.event.$$loading = false;
								return;
							}

							$scope.rootData.eventDetails = res.data;
							$scope.event.$$expand = true;
							$scope.event.$$loading = false;
							$scope.rootData.eventDetails.requiredFieldsContact = $scope.metadata.requiredFields.Contact;
							if (
								$scope.rootData.eventDetails.contacts != null &&
								$scope.rootData.eventDetails.contacts.length !== 0
							) {
								getContact($scope.rootData.eventDetails.contacts[0].id);
							}

							getTitleCategories();
							getActivityTypeAccess($scope.rootData.eventDetails.activityType);
						})
						.catch(err => console.error(err));
				}

				function getContact(contactId) {
					Contact.customer(contactId)
						.get(contactId)
						.then(function (contact) {
							$scope.rootData.eventDetails.contact = contact.data;
							$safeApply($scope);
						})
						.catch(function (err) {
							console.warn(err);
						});
				}

				$scope.event.showMoreOptIns = () => {
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_LIST')) {
						openModal('ListModal', {
							title: 'default.optIns',
							columns: [
								{ title: 'default.name', value: 'title' },
								{ title: 'default.regDate', value: 'date' }
							],
							data: _.map($scope.rootData.eventDetails.contact.optins, function (optin) {
								optin.date = moment(optin.regDate).format('L LT');
								return optin;
							})
						});
					} else {
						$upModal.open('list', {
							title: 'default.optIns',
							columns: [
								{ title: 'default.name', value: 'title' },
								{ title: 'default.regDate', value: 'date' }
							],
							data: _.map($scope.rootData.eventDetails.contact.optins, function (optin) {
								optin.date = moment(optin.regDate).format('L LT');
								return optin;
							})
						});
					}
				};

				$scope.event.saveActivityContact = contact => {
					if (contact != null) {
						$scope.rootData.eventDetails.contacts = [contact];
						getContact($scope.rootData.eventDetails.contacts[0].id);
					} else {
						$scope.rootData.eventDetails.contacts = null;
						$scope.rootData.eventDetails.contact = null;
					}

					if ($scope.hasActivityOutcomeAccess) {
						const outcome = $scope.rootData.eventDetails.outcome;
						if (outcome && !outcomeContactActions.includes(outcome.outcome)) {
							$scope.rootData.eventDetails.outcome = null;
						}
					}

					Activity.customer($stateParams.customerId)
						.save($scope.rootData.eventDetails)
						.catch(err => logError(err, 'Failed to updated activity with new contact'));
				};

				$scope.event.onCreateContact = () => {
					$upModal
						.open('editContact', {
							customerId: $stateParams.customerId,
							account: $scope.rootData.eventDetails.client
						})
						.then(function (contact) {
							if (contact.active) {
								$scope.event.saveActivityContact(contact);
							}
						})
						.catch(err => console.error(err));
				};

				$scope.event.editContact = e => {
					var params = {
						contact: angular.copy($scope.rootData.eventDetails.contact),
						customerId: $stateParams.customerId
					};
					$upModal
						.open('editContact', params, e)
						.then(function (response) {
							$scope.rootData.eventDetails.contact = response;
						})
						.catch(err => console.error(err));
				};

				$scope.event.setJourneyStep = journeyStep => {
					return Contact.customer($stateParams.customerId)
						.save({
							id: $scope.rootData.eventDetails.contact.id,
							journeyStep: journeyStep
						})
						.then(function (res) {
							$scope.rootData.eventDetails.contact.journeyStep = res.data.journeyStep;
							$scope.rootData.eventDetails.contact = angular.copy($scope.rootData.eventDetails.contact);
						})
						.catch(err => console.error(err));
				};

				function getTitleCategories() {
					FieldTranslation.find({ type: 'titlecategory' })
						.then(res => {
							if (!res.data) {
								$scope.event.$$loading = false;
								return;
							}

							if (res.data) {
								const categories = [];
								res.data.forEach((data, key) => {
									var category = {
										id: key,
										name: data.value,
										tagId: data.tagId,
										type: data.type,
										usedCount: data.usedCount
									};
									categories.push(category);
								});
								categories.push({
									id: categories.length + 1,
									name: $translate.instant('default.removeTitle'),
									type: 'remove',
									usedCount: null
								});
								$scope.rootData.eventDetails.titleCategories = categories;
							}
						})
						.catch(err => {
							console.error(err);
						});
				}

				$scope.event.updateContact = (value, type) => {
					if (type === 'titleCategory') {
						var selectedCategory = $scope.rootData.eventDetails.titleCategories.filter(function (category) {
							if (value === category.name) {
								return {
									tagId: category.tagId,
									type: category.type,
									usedCount: category.usedCount,
									value: category.name
								};
							}
							return false;
						});
						$scope.rootData.eventDetails.contact.titleCategory = selectedCategory;
					} else if (type === 'title') {
						$scope.rootData.eventDetails.contact.title = value;
					} else if (type === 'cellphone') {
						$scope.rootData.eventDetails.contact.cellPhone = value;
					} else if (type === 'phone') {
						$scope.rootData.eventDetails.contact.phone = value;
					} else if (type === 'email') {
						$scope.rootData.eventDetails.contact.email = value;
					}
					var contactToSave = $scope.rootData.eventDetails.contact;
					ScriptService.contact
						.save(contactToSave)
						.then(function () {
							Contact.customer($stateParams.customerId)
								.save(contactToSave)
								.then(function (response) {
									$scope.rootData.eventDetails.contact = response.data;
								})
								.catch(function (error) {
									console.error(error);
								});
						})
						.catch(function (error) {
							console.error(error);
						});
				};

				$scope.event.updateNotes = notes => {
					if ($scope.rootData?.eventDetails) {
						$scope.rootData.eventDetails.notes = notes;
						// only save notes
						Activity.customer($stateParams.customerId).save({ id: $scope.rootData.eventDetails.id, notes });
					}
				};

				// Outcomes
				$scope.event.createActivityFollowUp = async function (isAppointment) {
					// Build init-data and open modal
					let item;
					if (isAppointment) {
						item = Appointment.new().data;
						item.activityType = null;
					} else {
						item = Activity.new().data;
						item.activityType = $scope.rootData.eventDetails.activityType;
					}
					item.notes = $scope.rootData.eventDetails.notes;
					item.client = $scope.rootData.eventDetails.client;
					item.project = $scope.rootData.eventDetails.project;
					item.description = $scope.rootData.eventDetails.description;
					item.users = [_.pick(Tools.AppService.getSelf(), ['id', 'name', 'email'])];

					$scope.rootData.eventDetails.closeDate = moment.utc().format();

					if (isAppointment) {
						item.contacts = $scope.rootData.eventDetails.contact
							? [$scope.rootData.eventDetails.contact]
							: null;
						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('editAppointment', {
								appointment: item
							})
							.then(() => {
								closeAndApply();
								return Activity.customer($stateParams.customerId)
									.save($scope.rootData.eventDetails)
									.finally(() => {
										tracker($scope.rootData.eventDetails.outcome);
									});
							});
					} else {
						item.contacts = $scope.rootData.eventDetails.contacts;
						if (
							Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') &&
							item.activityType?.name === 'Phonecall'
						) {
							await ActivityResource.save(item)
								.then(res => {
									closeAndApply();
									if (res) {
										openDrawer('EditPhonecall', { activity: res.data });
										Activity.customer($stateParams.customerId).save($scope.rootData.eventDetails);
									}
								})
								.catch(err => logError(err, 'Failed to save activity'));
						} else {
							closeAndApply();
							// eslint-disable-next-line promise/catch-or-return
							$upModal
								.open('editActivity', {
									activity: item
								})
								.then(() => {
									Activity.customer($stateParams.customerId).save($scope.rootData.eventDetails);
								});
						}
					}
				};

				$scope.event.createCallFollowUp = function () {
					$scope.rootData.eventDetails.closeDate = moment.utc().format();
					closeAndApply();
					openDrawer('CreateCall', {
						initialDescription: $scope.rootData.eventDetails.description,
						client: $scope.rootData.eventDetails.client,
						contact: $scope.rootData.eventDetails.contact,
						notes: $scope.rootData.eventDetails.notes,
						activity: $scope.rootData.eventDetails,
						source: { id: $scope.rootData.eventDetails.id, type: TYPES.activity },
						onSave: () => {
							Activity.customer($stateParams.customerId).save($scope.rootData.eventDetails);
						}
					});
				};

				$scope.event.createTodoFollowUp = function () {
					$scope.rootData.eventDetails.closeDate = moment.utc().format();
					closeAndApply();
					openDrawer('CreateTodo', {
						description: $scope.rootData.eventDetails.description,
						client: $scope.rootData.eventDetails.client,
						contact: $scope.rootData.eventDetails.contact,
						notes: $scope.rootData.eventDetails.notes,
						activity: $scope.rootData.eventDetails,
						source: { id: $scope.rootData.eventDetails.id, type: TYPES.activity },
						onSave: () => {
							Activity.customer($stateParams.customerId).save($scope.rootData.eventDetails);
						}
					});
				};

				$scope.event.bookAppointment = function (commentDesc) {
					const appointment = Appointment.new().data;

					appointment.notes = $scope.rootData.eventDetails.notes;
					appointment.client = $scope.rootData.eventDetails.client;
					appointment.project = $scope.rootData.eventDetails.project;
					appointment.users = [_.pick(Tools.AppService.getSelf(), ['id', 'name', 'email'])];
					appointment.activityType = null;

					$scope.rootData.eventDetails.closeDate = moment.utc().format();
					appointment.contacts = $scope.rootData.eventDetails.contact
						? [$scope.rootData.eventDetails.contact]
						: null;

					// eslint-disable-next-line promise/catch-or-return
					$upModal.open('editAppointment', { appointment }).then(async (appointment = {}) => {
						const comment = await $scope.event.createCommentIfExists(commentDesc);

						$scope.event.setOutcome({
							type: OUTCOME_TYPES.ANSWER,
							outcome: ANSWER_ACTIONS_GREAT.BOOK_APPOINTMENT,
							appointmentId: _.get(appointment, 'id'),
							...(comment ? { commentId: comment.id } : {})
						});
						closeAndApply();
						Activity.customer($stateParams.customerId).save($scope.rootData.eventDetails);
					});
				};

				const addTime = function (time) {
					if (typeof time === 'string') {
						time = new Date(time);
					}
					if (typeof $scope.rootData.eventDetails.date === 'string') {
						$scope.rootData.eventDetails.date = new Date($scope.rootData.eventDetails.date);
					}
					$scope.rootData.eventDetails.time = time;
					if (time) {
						$scope.rootData.eventDetails.date.setHours(time.getHours(), time.getMinutes(), 0, 0);
					}
					$scope.rootData.eventDetails.showTime = !!time;
				};

				$scope.event.updateTime = function (time) {
					addTime(time);
					if (time) {
						time = moment(time).format('HH:mm:ss');
					}
					$scope.rootData.eventDetails.time = time;
					$safeApply($scope);
				};

				const addDate = function (date) {
					$scope.rootData.eventDetails.date = date;
					if ($scope.rootData.eventDetails.time && typeof $scope.rootData.eventDetails.time != 'string') {
						$scope.rootData.eventDetails.date.setHours($scope.rootData.eventDetails.time.getHours());
						$scope.rootData.eventDetails.date.setMinutes($scope.rootData.eventDetails.time.getMinutes());
					}
					$safeApply($scope);
				};

				$scope.event.addDate = addDate;

				$scope.event.presetDateChange = function (date) {
					$scope.rootData.eventDetails.time = null;
					addDate(date);
				};

				$scope.event.setOutcome = (outcome, cb = () => {}) => {
					const dateTimeStr = moment.utc().format();
					const clientJourney = _.get($scope.rootData.eventDetails, 'client.journeyStep');
					$scope.rootData.eventDetails.outcome = { ...outcome, date: dateTimeStr, clientJourney };
					$safeApply($scope);
					cb();
				};

				$scope.event.onSetJourneyStepClient = journeyStep => {
					const account = $scope.rootData.eventDetails.client;

					if (!account) {
						return Promise.resolve();
					}
					return Account.customer($scope.customerId)
						.save({
							id: account.id,
							journeyStep
						})
						.then(function (res) {
							account.journeyStep = res.data.journeyStep;
							$scope.rootData.company = angular.copy(account);
						});
				};

				$scope.event.setDisqualifyToClient = () => {
					const journeyStep = _.get($scope.rootData.eventDetails, 'client.journeyStep');
					const disqualify = _.get($scope.rootData.eventDetails, 'outcome.disqualify');

					if (disqualify && journeyStep && statusDisqualifiable(journeyStep)) {
						$scope.event
							.onSetJourneyStepClient(DISQUALIFIED)
							.catch(e => logError(e, 'Failed to disqualify on close activity'));
					}
				};

				$scope.event.confirm = () => {
					$scope.rootData.saving = true;

					const activity = angular.copy($scope.rootData.eventDetails);
					if ($scope.hasActivityOutcomeAccess && activity.outcome) {
						$scope.event.setDisqualifyToClient();

						const shouldClose = _.get(activity.outcome, 'closeActivity', false);
						if (shouldClose) {
							activity.closeDate = new Date();
						}
					}

					closeAndApply();

					Activity.customer($stateParams.customerId)
						.save({
							id: activity.id,
							closeDate: activity.closeDate,
							outcome: activity.outcome,
							date: activity.date,
							time: activity.time
						})
						.then(() => {
							tracker($scope.rootData.eventDetails.outcome);
						})
						.catch(err => {
							logError(err, 'Error saving event');
						});
					$scope.rootData.saving = false;
					if ($scope.selectedActivityId) {
						Tools.$state.go('activities', Tools.AppService.getCustomerId());
					}
				};

				$scope.event.openContactDropdown = () => {
					$scope.event.addNewContactButton();
					angular.element('#contact-select').select2('open');
				};

				$scope.event.addNewContactButton = () => {
					if ($scope.hasActivityOutcomeAccess) {
						setTimeout(() => {
							const select2Drop = angular.element('.select2-with-searchbox');
							const newBtn = angular.element('.add-new-contact-activity');
							const addNewContactBtn = jQuery(
								'<div class="add-new-contact-activity Button up-btn btn-bright-blue btn-link btn-block"/>'
							).text($translate.instant('default.createAContact'));
							if (newBtn.length !== 0) {
								jQuery(newBtn).remove();
								select2Drop.append(addNewContactBtn);
							} else if (newBtn.length === 0) {
								select2Drop.append(addNewContactBtn);
							}
							select2Drop.on('click', '.add-new-contact-activity', () => {
								angular.element('#contact-select').select2('close');
								$scope.event.onCreateContact();
								$safeApply($scope);
							});
						}, 100);
					}
				};

				$scope.event.createOrderAndSave = () => {
					const options = {
						customerId: $stateParams.customerId,
						clientId: $scope.rootData.eventDetails.client ? $scope.rootData.eventDetails.client.id : null,
						contactId: $scope.rootData.eventDetails.contact
							? $scope.rootData.eventDetails.contact.id
							: null,
						campaign: $scope.rootData.eventDetails.project ? $scope.rootData.eventDetails.project : null,
						notes: $scope.rootData.eventDetails.notes,
						type: 'order',
						resolveOnSave: true
					};
					$scope.rootData.eventDetails.closeDate = moment.utc().format();

					closeAndApply();

					return $upModal
						.open('editOrder', options)
						.then(order => {
							$scope.rootData.eventDetails.opportunity = order;
							$scope.event.$$eventDetail = {
								type: OUTCOME_TYPES.ANSWER,
								outcome: ANSWER_ACTIONS_GREAT.CREATE_ORDER
							};
							Activity.customer($stateParams.customerId).save($scope.rootData.eventDetails);
						})
						.finally(() => {
							tracker($scope.rootData.eventDetails.outcome);
						});
				};

				$scope.event.createOpportunity = function (skipSaveActivity) {
					var options = {
						customerId: $stateParams.customerId,
						clientId: $scope.rootData.eventDetails.client ? $scope.rootData.eventDetails.client.id : null,
						contactId: $scope.rootData.eventDetails.contact
							? $scope.rootData.eventDetails.contact.id
							: null,
						campaign: $scope.rootData.eventDetails.project ? $scope.rootData.eventDetails.project : null,
						notes: $scope.rootData.eventDetails.notes,
						type: 'opportunity',
						resolveOnSave: true,
						activityId: !skipSaveActivity ? $scope.rootData.eventDetails.id : null
					};

					// eslint-disable-next-line promise/catch-or-return
					return $upModal.open('editOrder', options).then(function (opportunity) {
						$scope.rootData.eventDetails.opportunity = opportunity;
						$scope.rootData.eventDetails.closeDate = moment.utc().format();
					});
				};

				$scope.event.createOpportunityAndSave = () => {
					$scope.event
						.createOpportunity(true)
						.then(() => {
							$scope.rootData.eventDetails.outcome = {
								type: OUTCOME_TYPES.ANSWER,
								outcome: ANSWER_ACTIONS_GREAT.CREATE_OPPORTUNITY
							};
							return Activity.customer($stateParams.customerId)
								.save($scope.rootData.eventDetails)
								.then(closeAndApply);
						})
						.finally(() => {
							tracker($scope.rootData.eventDetails.outcome);
						})
						.catch(err => {
							logError(err, 'Error saving opportunity');
						});
				};

				function getActivityTypeAccess(activityType) {
					const activityTypes = Tools.AppService.getActivityTypes('activity', true);
					const todoTypes = Tools.AppService.getTodoTypes();
					if (activityType) {
						$scope.rootData.isOutcomeEnabled = _.get(
							_.find({ ...activityTypes, ...todoTypes }, { id: activityType.id }),
							'hasOutcome',
							false
						);
					}
				}

				$scope.event.setAppointment = function (id, notes) {
					if (!id) return;
					$scope.rootData.eventDetails.closeDate = moment.utc().format();
					closeAndApply();
					Activity.customer($stateParams.customerId)
						.save($scope.rootData.eventDetails)
						.then(
							() =>
								$upModal.open('editAppointment', {
									id,
									appointment: { notes: `${notes}\n${$scope.rootData.eventDetails.notes}` }
								}),
							tracker($scope.rootData.eventDetails.outcome)
						)
						.catch(err => {
							logError(err, 'Error saving event');
						});
				};

				$scope.event.planPhonecall = async function (commentDesc) {
					$scope.rootData.eventDetails.closeDate = moment.utc().format();
					$scope.rootData.eventDetails.outcome = {
						type: OUTCOME_TYPES.ANSWER,
						outcome: ANSWER_ACTIONS_GREAT.PLAN_PHONE_CALL
					};

					openDrawer('CreateCall', {
						contact: $scope.rootData.eventDetails.contact,
						client: $scope.rootData.eventDetails.client,
						source: { id: $scope.rootData.eventDetails.id, type: TYPES.activity },
						onSave: async activity => {
							$scope.rootData.eventDetails.outcome.activityId = activity.id;
							const comment = await $scope.event.createCommentIfExists(commentDesc);
							closeAndApply();
							Activity.customer($stateParams.customerId).save({
								...$scope.rootData.eventDetails,
								outcome: {
									...$scope.rootData.eventDetails.outcome,
									...(comment ? { commentId: comment.id } : {})
								}
							});
						}
					});
				};

				$scope.rootData = {
					...$scope.rootData,
					openActivity: $scope.openActivity,
					showMoreOptIns: $scope.event.showMoreOptIns,
					saveContact: $scope.event.saveActivityContact,
					createContact: $scope.event.onCreateContact,
					editContact: $scope.event.editContact,
					setJourneyStep: $scope.event.setJourneyStep,
					updateContact: $scope.event.updateContact,
					updateNotes: $scope.event.updateNotes,
					confirm: $scope.event.confirm,
					setOutcome: $scope.event.setOutcome,
					onPresetDateChange: $scope.event.presetDateChange,
					addDate,
					updateTime: $scope.event.updateTime,
					bookAppointment: $scope.event.bookAppointment,
					openContactDropdown: $scope.event.openContactDropdown,
					addNewContactButton: $scope.event.addNewContactButton,
					createOrderAndSave: $scope.event.createOrderAndSave,
					createOpportunityAndSave: $scope.event.createOpportunityAndSave,
					setAppointment: $scope.event.setAppointment,
					planPhonecall: $scope.event.planPhonecall,
					createActivityFollowUp: $scope.event.createActivityFollowUp,
					createTodoFollowUp: $scope.event.createTodoFollowUp,
					createCallFollowUp: $scope.event.createCallFollowUp
				};
			}
		]
	};
});
