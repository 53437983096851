import MailIntegration from 'App/babel/resources/MailIntegration';
import store from 'Store';
import { initFromId, getAndSaveOauthToken } from 'App/babel/store/reducers/ConfigIntegration';
import { mailIntegrationIsValid } from 'App/helpers/mailHelpers';
import openModal from 'App/services/Modal/Modal';

angular.module('domain.mail').factory('SendEmailMeta', [
	'$q',
	'Contact',
	'Campaign',
	'RequestBuilder',
	'MailTemplate',
	'Mail',
	'$upModal',
	'$translate',
	'MailCampaign',
	'MailAccount',
	'AppService',
	'$state',
	function (
		$q,
		Contact,
		Campaign,
		RequestBuilder,
		MailTemplate,
		Mail,
		$upModal,
		$translate,
		MailCampaign,
		MailAccount,
		AppService,
		$state
	) {
		return function ($modalParams) {
			async function handleIntegration(mailIntegration) {
				const customerId = AppService.getCustomerId();
				const self = AppService.getSelf();
				const promises = {
					users: $q.when({ data: AppService.getActiveUsers() }),
					mailIntegration
				};

				if (mailIntegration) {
					promises.integrationProfile = MailIntegration.getProfile();
				} else {
					const mailAccountPromise = $q.defer();

					MailAccount.customer(customerId)
						.get()
						.then(function (res) {
							mailAccountPromise.resolve(res.data);
						})
						.catch(function (err) {
							if (err.status === 404) {
								// eslint-disable-next-line promise/catch-or-return
								$upModal
									.open('warningConfirm', {
										icon: 'fa-warning',
										title: 'default.warning',
										body: 'default.featureNotActivated',
										resolveTrue: 'navbar.settings'
									})
									.then(function () {
										$state.go('administration.mailSettings', {});
									});
							}

							mailAccountPromise.reject(err);
						});

					promises.mailAccount = mailAccountPromise.promise;
				}

				var templateFilter = new RequestBuilder();
				var orFilter = templateFilter.orBuilder();

				orFilter.next();
				orFilter.addFilter(MailTemplate.attr.private, templateFilter.comparisonTypes.Equals, false);
				orFilter.next();
				orFilter.addFilter(MailTemplate.attr.private, templateFilter.comparisonTypes.Equals, true);
				orFilter.addFilter(MailTemplate.attr.user.attr.id, templateFilter.comparisonTypes.Equals, self.id);

				templateFilter.addFilter(MailTemplate.attr.socialEvent, templateFilter.comparisonTypes.Equals, null);
				templateFilter.addSort(MailTemplate.attr.name, true);
				templateFilter.fields = ['id', 'name', 'subject', 'private'];
				orFilter.done();

				promises.templates = window.fetchAll(MailTemplate, templateFilter);

				var resource = $modalParams.contactId || $modalParams.type === 'mail' ? Mail : MailCampaign;

				if ($modalParams.copy) {
					promises.copy = resource
						.customer(customerId)
						.get($modalParams.copy)
						.then(function (res) {
							var key,
								data = {},
								attributes = [
									'body',
									'from',
									'fromName',
									'subject',
									'tags',
									'templates',
									'users',
									'type',
									'attachments'
								];
							for (key in attributes) {
								data[attributes[key]] = res.data[attributes[key]];
							}

							res.data = data;
							return res;
						});
				}

				if ($modalParams.edit) {
					promises.edit = resource.customer(customerId).get($modalParams.edit);
				}

				if ($modalParams.campaignId) {
					promises.campaign = Campaign.customer(customerId).get($modalParams.campaignId);

					// also get number of recievers
					var contactFilter = new RequestBuilder();
					contactFilter.addFilter(
						Contact.attr.campaigns,
						contactFilter.comparisonTypes.Equals,
						$modalParams.campaignId
					);
					contactFilter.addFilter(Contact.attr.email, contactFilter.comparisonTypes.NotEquals, null);
					contactFilter.limit = 0;
					contactFilter.fields = ['id', 'client'];
					promises.campaignContacts = Contact.customer(customerId).find(contactFilter.build());
				} else if ($modalParams.contactId) {
					// It is possible to send in a contact object without all necessary data like { id: some_id, email: some_email } then we need to fetch it anyways
					if ($modalParams.contact?.id && $modalParams.contact?.email && $modalParams.contact?.client?.id) {
						promises.contact = $q.when({ data: $modalParams.contact });
					} else {
						promises.contact = Contact.customer(customerId).get($modalParams.contactId);
					}
				}

				// If we get an accountId we get all active contacts on that account
				if ($modalParams.accountId) {
					var contactsFilter = new RequestBuilder();
					contactsFilter.addFilter(Contact.attr.email, contactsFilter.comparisonTypes.NotEquals, null);
					contactsFilter.addFilter(Contact.attr.active, contactsFilter.comparisonTypes.Equals, true);

					var or = contactsFilter.orBuilder();
					or.next();
					or.addFilter(
						Contact.attr.account.attr.id,
						contactsFilter.comparisonTypes.Equals,
						$modalParams.accountId
					);
					or.next();
					or.addFilter(
						Contact.attr.connectedClients,
						contactsFilter.comparisonTypes.Equals,
						$modalParams.accountId
					);
					or.done();

					contactsFilter.fields = ['id', 'name', 'email', 'client'];
					promises.contacts = Contact.customer(customerId).find(contactsFilter.build());
				}

				if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.MAIL_SIGNATURE)) {
					promises.mailsignatures = Tools.MailSignatures.getActiveSignatures();
				}

				return $q.all(promises).then(function (results) {
					var metadata = AppService.getMetadata();
					var requiredCredits = 0; // Single mail uses the mail apps and does not require any credits
					var currentCredits = metadata.map.mailCredits;
					const hasUnlimitedMailAddon =
						Tools.FeatureHelper.isAvailableAddon(
							Tools.FeatureHelper.Addon.MARKETING_AUTOMATION_ENTERPRISE
						) ||
						Tools.FeatureHelper.isAvailableAddon(Tools.FeatureHelper.Addon.MARKETING_AUTOMATION) ||
						Tools.FeatureHelper.isAvailableAddon(Tools.FeatureHelper.Addon.GROUP_MAIL) ||
						Tools.FeatureHelper.isAvailableAddon(Tools.FeatureHelper.Addon.BRANDS);

					// The campaignId seems to create an email campaign so i guess it should still use credits
					if ($modalParams.campaignId) {
						requiredCredits = results.campaignContacts.metadata.total * requiredCredits;
					}

					var accountSelf = AppService.getAccountSelf();
					if (accountSelf.versionData && accountSelf.versionData.unlimitedEmails) {
						requiredCredits = 0;
					}

					// check if we have credits or addon with unlimited emails
					if (requiredCredits > currentCredits && !hasUnlimitedMailAddon) {
						// we don't have the credits required to send this.. Notify user
						var opts = {
							title: 'mail.notEnoughCredits',
							icon: 'fa-warning'
						};

						var creditsStr = $translate.instant('mail.credits').toLowerCase();
						var reqStr = $translate.instant('mail.mailRequires');
						var currCredStr = $translate.instant('mail.currentCredits');

						if (metadata.user.administrator) {
							//FIXME: (hasse) open buy modal here later
							opts.body =
								reqStr +
								' <b>' +
								requiredCredits +
								'</b> ' +
								creditsStr +
								'.<br>' +
								currCredStr +
								': <b>' +
								currentCredits +
								'</b>';
							$upModal.open('warningAlert', opts);
						} else {
							opts.body = 'mail.notEnoughCreditsWarning';
							$upModal.open('warningAlert', opts);
						}
						return $q.reject();
					}

					// If this is not edit or mail-campaign
					if (!$modalParams.edit && !$modalParams.campaignId) {
						if (results.contacts) {
							$modalParams.contacts = results.contacts.data;
						}

						var contactName = '';
						if (results.contact && (!results.contact.data.email || !results.contact.data.email.length)) {
							contactName = results.contact.data.name;
							results.contact = null;
						}

						// If the selected contact does not have an email but we have other contacts
						if (
							$modalParams.contactId &&
							!results.contact &&
							results.contacts &&
							results.contacts.data.length
						) {
							var newDefer = $q.defer();

							// eslint-disable-next-line promise/catch-or-return
							$upModal
								.open('warningAlert', {
									title: 'default.noEmail',
									icon: 'fa-warning',
									body: '<b>' + contactName + '</b> ' + $translate.instant('warning.noEmailContact')
								})
								.finally(function () {
									newDefer.resolve(results);
								});

							return newDefer.promise;
						}

						if (
							$modalParams.contactId &&
							!results.contact &&
							(!results.contacts || !results.contacts.data.length)
						) {
							return $upModal
								.open('warningAlert', {
									title: 'default.noEmail',
									icon: 'fa-warning',
									body: 'warning.noContactsOnAccount'
								})
								.finally(function () {
									return $q.reject();
								});
						}
					}

					// if we have a edit
					if (
						$modalParams.edit &&
						results.edit.data &&
						(results.edit.data.type === 'sch' || results.edit.data.status === 'SCHEDULED')
					) {
						var date = results.edit.data.sendDate || results.edit.data.date;

						return $upModal
							.open('warningConfirm', {
								title: 'mail.scheduled',
								icon: 'fa-clock-o',
								resolveTrue: 'default.startEdit',
								no: 'default.abortEdit',
								body:
									$translate.instant('mail.scheduledTo') +
									' ' +
									moment(date).format('L LT') +
									'.<br><br>' +
									$translate.instant('mail.unscheduleToEdit')
							})
							.then(function () {
								var resource = $modalParams.campaignId ? MailCampaign : Mail;

								var method = '';
								if ($modalParams.campaignId) {
									method = 'save';
									results.edit.data.isDraft = true;
								} else {
									results.edit.data.date = undefined;
									method = 'delete';
								}

								return resource
									.customer(customerId)
									[method](results.edit.data, { skipNotification: true })
									.then(function () {
										return results;
									});
							});
					}

					return results;
				});
			}

			return AppService.loadedPromise.then(async function () {
				const mailIntegration = Tools.AppService.getMailIntegration();

				/* This is the case when we are comming back from an oauth2 signon */
				if ($modalParams.auth) {
					await store.dispatch(initFromId($modalParams.auth.integrationId));
					await store.dispatch(getAndSaveOauthToken($modalParams.auth));
					return mailIntegration;
				} else if (mailIntegration) {
					if (await mailIntegrationIsValid(mailIntegration, $q.reject)) {
						return mailIntegration;
					} else {
						if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_CHOOSE_MAIL_INTEGRATION')) {
							openModal('ChooseMailIntegration', {
								sendEmailModalParams: $modalParams,
								startSelectedId: mailIntegration.id,
								onClose: integration => {
									if (integration) {
										handleIntegration(integration);
									}
								}
							});
							return;
						}

						return $upModal
							.open('chooseMailIntegration', {
								sendEmailModalParams: $modalParams,
								startSelectedId: mailIntegration.id
							})
							.then(handleIntegration);
					}
				} else {
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_CHOOSE_MAIL_INTEGRATION')) {
						openModal('ChooseMailIntegration', {
							sendEmailModalParams: $modalParams,
							onClose: integration => {
								if (integration) {
									handleIntegration(integration);
								}
							}
						});
						return;
					}
					/* This is the when we have no mailIntegration */
					return $upModal
						.open('chooseMailIntegration', { sendEmailModalParams: $modalParams })
						.then(handleIntegration);
				}
			});
		};
	}
]);
