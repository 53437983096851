import React from 'react';
import { Link, StateFrame, Flex, Help } from '@upsales/components';
import { PrimaryButton } from '@upsales/components/Buttons';
import { useTranslation } from 'Components/Helpers/translate';
import Client from 'App/resources/Model/Client';
import { trunc } from 'lodash';
import { useSoftDeployAccess } from 'App/components/hooks';

type Props = Pick<Client, 'operationalAccount'> & Pick<React.ComponentProps<typeof StateFrame>, 'space'>;

const SubAccountStateFrame = ({ operationalAccount, space }: Props) => {
	const { t } = useTranslation();
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');

	const goToAccount = (id: number) => () => {
		Tools.$state.go('account.dashboard', { id }, { inherit: false });
	};

	if (!hasSubAccounts || !operationalAccount) {
		return null;
	}

	return (
		<StateFrame
			icon="info-circle"
			state="info"
			title={[
				t('account.subaccounts.stateframe.title'),
				<Link color="blue" onClick={goToAccount(operationalAccount.id)} key="parent-link">
					{operationalAccount.name}
				</Link>
			]}
			subtitle={t('account.subaccounts.stateframe.subtitle')}
			className="SubAccountStateFrame"
			space={space}
		>
			<Flex gap="u3" alignItems="center">
				<PrimaryButton onClick={goToAccount(operationalAccount.id)} size="sm">
					{t('account.subaccount.goToMainCompany', {
						company: trunc(operationalAccount.name, { length: 20 })
					})}
				</PrimaryButton>
				<Help articleId={1514} sidebar />
			</Flex>
		</StateFrame>
	);
};

export default SubAccountStateFrame;
