import { getSoftDeployAccessFromState, getFeatureAvailableFromState } from 'App/components/hooks/featureHelper';
import { AccountSelf } from 'App/resources/AllIWant';
import { IconName } from '@upsales/components';
import { openDrawer } from 'Services/Drawer';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import { Feature } from 'Store/actions/FeatureHelperActions';
import getAngularModule from 'App/babel/angularHelpers/getAngularModule';
import T from 'Components/Helpers/translate';

type Action = {
	id: string;
	visible?: (accountSelf: AccountSelf) => boolean;
	onClick: () => void;
	icon: IconName;
	title: string;
};

export type CreateMenuAvailableWithoutClient = {
	companies: boolean;
	contacts: boolean;
	activities: boolean;
	phonecalls: boolean;
	appointment: boolean;
	opportunities: boolean;
	orders: boolean;
	agreement: boolean;
	support: boolean;
};

export const createMenuAvailableWithoutClient = (accountSelf: AccountSelf): CreateMenuAvailableWithoutClient => {
	return {
		companies: getFeatureAvailableFromState(accountSelf, Feature.COMPANIES_AND_CONTACTS),
		contacts: getFeatureAvailableFromState(accountSelf, Feature.COMPANIES_AND_CONTACTS),
		activities: getFeatureAvailableFromState(accountSelf, Feature.ACTIVITIES_AND_APPOINTMENTS),
		phonecalls: getFeatureAvailableFromState(accountSelf, Feature.ACTIVITIES_AND_APPOINTMENTS),
		appointment: getFeatureAvailableFromState(accountSelf, Feature.ACTIVITIES_AND_APPOINTMENTS),
		opportunities: getFeatureAvailableFromState(accountSelf, Feature.PIPELINE),
		orders: getFeatureAvailableFromState(accountSelf, Feature.ORDERS),
		agreement:
			getFeatureAvailableFromState(accountSelf, Feature.RECURRING_ORDER) &&
			Tools.AppService.getAccessRights().Agreement,
		support: getFeatureAvailableFromState(accountSelf, Feature.CUSTOMER_SUPPORT)
	};
};

export const createActionsWithoutClient = (accountSelf: AccountSelf): Action[] => {
	const hasSubScriptionModal = getSoftDeployAccessFromState(accountSelf, 'SUBSCRIPTION_MODAL');
	const hasDontWaitSubscriptions = getSoftDeployAccessFromState(accountSelf, 'DONT_WAIT_SUBSCRIPTIONS');
	const meta = Tools.AppService.getMetadata();

	const canCreateEntity = createMenuAvailableWithoutClient(accountSelf);

	return [
		{
			id: 'companies',
			icon: 'building-o',
			title: T('default.addAccount'),
			visible: () => canCreateEntity.companies,
			onClick: () => {
				if (shouldOpenModal('EditClient')) {
					openModal('EditClient');
				} else {
					const $upModal = getAngularModule('$upModal');
					$upModal.open('editAccount');
				}
			}
		},
		{
			id: 'contacts',
			icon: 'user',
			title: T('clientCard.splitButton.contact'),
			visible: () => canCreateEntity.contacts,
			onClick: () => Tools.$upModal.open('editContact')
		},
		{
			id: 'activities',
			icon: 'activity',
			title: T('clientCard.splitButton.activity'),
			visible: () =>
				canCreateEntity.activities && !getSoftDeployAccessFromState(accountSelf, 'REMOVE_ACTIVITIES'),
			onClick: () => Tools.$upModal.open('editActivity')
		},
		{
			id: 'todo',
			icon: 'todo',
			title: T('clientCard.splitButton.todo'),
			visible: () => canCreateEntity.activities && getSoftDeployAccessFromState(accountSelf, 'TODO_LIST'),
			onClick: () => openDrawer('CreateTodo')
		},
		{
			id: 'phonecalls',
			icon: 'phone',
			title: T('clientCard.splitButton.phonecall'),
			visible: () => canCreateEntity.activities && getSoftDeployAccessFromState(accountSelf, 'TODO_LIST'),
			onClick: () => openDrawer('CreateCall')
		},
		{
			id: 'appointment',
			icon: 'calendar',
			title: T('clientCard.splitButton.appointment'),
			visible: () => canCreateEntity.appointment,
			onClick: () => Tools.$upModal.open('editAppointment')
		},
		{
			id: 'opportunities',
			icon: 'opportunity',
			title: T('clientCard.splitButton.opportunity'),
			visible: () => canCreateEntity.opportunities,
			onClick: () => Tools.$upModal.open('editOrder', { type: 'opportunity' })
		},
		{
			id: 'orders',
			icon: 'dollar',
			title: T('clientCard.splitButton.order'),
			visible: () => canCreateEntity.orders,
			onClick: () => Tools.$upModal.open('editOrder')
		},
		{
			id: 'subscription',
			icon: 'recurring-order',
			title: T('clientCard.splitButton.subscription'),
			visible: () => canCreateEntity.agreement && meta.params.AgreementEnabled,
			onClick: () => {
				if (!hasSubScriptionModal) {
					return Tools.$upModal.open('editAgreement');
				}
				openModal('CreateSubscription', {
					createdFrom: 'accountAddButton',
					dontWait: hasDontWaitSubscriptions
				});
			}
		},
		{
			id: 'support',
			icon: 'customer-support',
			title: T('clientCard.splitButton.support'),
			visible: () => canCreateEntity.support,
			onClick: () => openModal('EditTicket')
		}
	];
};
