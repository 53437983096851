import {
	getTierFromOrderRow,
	getTierPriceByCurrency,
	isTieredOrderRow,
	isTieredTotalOrderRow
} from 'App/babel/helpers/order';
import _ from 'lodash';
import PrecreateOptionsButtonSelect from 'App/components/PrecreateOptionsButtonSelect';
import MoveSubscriptionButton from 'App/components/MoveSubscription/MoveSubscriptionButton';
import NoticePeriod from 'App/components/NoticePeriod';
import MoveSubscription from 'App/components/MoveSubscription';
import AgreementHistoryComponent from 'App/components/AgreementHistory';
import AgreementHistoryFooterComponent from 'App/components/AgreementHistory/AgreementHistoryFooter';
import AgreementCustomPeriodLength from 'App/pages/Agreement/AgreementCustomPeriodLength';
import {
	periodLengthOptions,
	getIntervalPeriodBasedOnPeriodLength,
	resetCustomPeriodToCompatiblePeriodLength
} from 'App/pages/Agreement/AgreementCustomPeriodLength/agreementPeriodHelpers';
import AgreementInitialPeriodToggle from 'App/pages/Agreement/AgreementInitialPeriodToggle';
import AgreementFollowUpChip from 'App/pages/Agreement/AgreementFollowUpChip';
import { Tabs, Tab, ColorSwitcher, Card } from '@upsales/components';
import { circle } from 'Components/Helpers/styleHelper';
import T from 'Components/Helpers/translate';
import { subscriptionsTracker } from 'App/babel/helpers/Tracker';
import openModal from 'App/services/Modal';
import { DefaultButton } from '@upsales/components/Buttons';
import { globalTracker } from 'App/babel/helpers/Tracker';
import editAgreementForm from 'App/upsales/domain/agreement/views/editAgreementForm.html?file';
import editAgreementFormOrder from 'App/upsales/domain/agreement/views/editAgreementFormOrder.html?file';
import SubscriptionPromoBanner from 'App/components/EditSubscription/SubscriptionPromo/SubscriptionPromoBanner';
import { getCMWithRROption, hasRRWithCM } from 'App/helpers/salesModelHelpers';
import documentResource from 'Resources/document';

angular.module('domain.agreement').controller('EditAgreement', [
	'$scope',
	'$rootScope',
	'$modalParams',
	'RequestBuilder',
	'Contact',
	'$upModal',
	'FixOrder',
	'AppService',
	'Agreement',
	'Order',
	'utils',
	'ScriptService',
	'FeatureHelper',
	'accountRelations',
	'Account',
	'ActionProperties',
	'$safeApply',
	'ParseFormula',
	'selectHelper',
	function (
		$scope,
		$rootScope,
		$modalParams,
		RequestBuilder,
		Contact,
		$upModal,
		FixOrder,
		AppService,
		Agreement,
		Order,
		utils,
		ScriptService,
		FeatureHelper,
		accountRelations,
		Account,
		ActionProperties,
		$safeApply,
		ParseFormula,
		selectHelper
	) {
		var EditAgreement = this;
		var meta = $modalParams.meta;
		var customerId = $modalParams.customerId || AppService.getCustomerId();
		var prevent = false;
		$scope.parseFormula = ParseFormula;
		EditAgreement.editAgreementFormHtmlPath = editAgreementForm;
		EditAgreement.editAgreementFormOrderHtmlPath = editAgreementFormOrder;
		EditAgreement.SubscriptionPromoBanner = SubscriptionPromoBanner;

		EditAgreement.isDisabledField = (fieldName, isDisabled) => {
			return isDisabled;
		};

		EditAgreement.isVisibleField = (fieldName, isVisible = true) => {
			return isVisible;
		};

		EditAgreement.onOrderRowCustomFieldChange = () => {};

		// Yes, i did this... But this code can be 100% reused when modal is ported to react
		EditAgreement.AgreementTabs = ({ activeTab, guide, showHistory, onChange, orderDisabled }) => {
			return (
				<Card className="AgreementModal__tabs">
					<Tabs noFlex color="white" onChange={g => onChange(g)} selected={activeTab}>
						<Tab id="form">
							{guide ? <ColorSwitcher style={circle()}>{1}</ColorSwitcher> : null}
							{T('order.periodAndBilling')}
						</Tab>
						<Tab id="agreementForm" disabled={orderDisabled}>
							{guide ? <ColorSwitcher style={circle()}>{2}</ColorSwitcher> : null}
							{T('order.orderDetails')}
						</Tab>
						{showHistory ? (
							<Tab id="agreementHistory">
								{guide ? <ColorSwitcher style={circle()}>{3}</ColorSwitcher> : null}
								{T('order.agreementHistoryTab')}
							</Tab>
						) : null}
					</Tabs>
				</Card>
			);
		};
		EditAgreement.PreviewButton = ({ onClick, disabled }) => {
			return (
				<DefaultButton
					disabled={disabled}
					onClick={e => {
						EditAgreement.numbersOfPreviews += 1;
						globalTracker.track('Clicked preview subscription button');
						onClick(e);
					}}
				>
					{T('agreement.previewSubscriptionButton')}
				</DefaultButton>
			);
		};
		EditAgreement.saving = false;
		EditAgreement.hasDocumentTemplates = false;
		EditAgreement.documentTemplates = null;
		EditAgreement.relatedClients = [];
		EditAgreement.customerId = customerId;

		EditAgreement.descriptionMaxLength = 130;

		EditAgreement.MoveSubscriptionButton = MoveSubscriptionButton;

		EditAgreement.cachedProductSearches = {};
		EditAgreement.cacheProductSearch = (query, results) => {
			if (Tools.FeatureHelper.hasSoftDeployAccess('SPEED_EDIT_ORDER_PRODUCT_SEARCH')) {
				EditAgreement.cachedProductSearches[query] = results;
			}
		};

		EditAgreement.NoticePeriod = NoticePeriod;
		EditAgreement.MoveSubscription = MoveSubscription;
		EditAgreement.showMoveSubscription = false;
		EditAgreement.onMoveSubscriptionButtonClick = () => {
			EditAgreement.showMoveSubscription = true;
			$safeApply($scope);
		};

		EditAgreement.onMoveSubscriptionCancelClick = () => {
			EditAgreement.showMoveSubscription = false;
			$safeApply($scope);
		};

		EditAgreement.AgreementHistory = AgreementHistoryComponent;
		EditAgreement.AgreementHistoryFooter = AgreementHistoryFooterComponent;

		EditAgreement.setPeriodLength = (periodLength, updateFields = true) => {
			EditAgreement.agreement.metadata.periodLength = parseInt(periodLength);
			EditAgreement.agreement.metadata.periodLengthOld = EditAgreement.agreement.metadata.periodLength;
			if (updateFields) {
				EditAgreement.periodChanged();
				EditAgreement.datesChanged();
			}
			$safeApply($scope);
		};

		const updateIsValidNoticePeriod = () => {
			const metadata = EditAgreement.agreement.metadata;
			if (metadata.periodLength === 0) {
				EditAgreement.isValidNoticePeriod = true;
			} else {
				EditAgreement.isValidNoticePeriod =
					metadata.noticePeriod <= Math.max(metadata.periodLength, metadata.agreementIntervalPeriod);
			}
		};

		EditAgreement.setNoticePeriod = noticePeriod => {
			EditAgreement.agreement.metadata.noticePeriod = noticePeriod;
			updateIsValidNoticePeriod();
			$safeApply($scope);
		};

		EditAgreement.hasCustomPeriodLengthFeature = FeatureHelper.hasSoftDeployAccess(
			'AGREEMENT_CUSTOM_PERIOD_LENGTH'
		);
		EditAgreement.AgreementCustomPeriodLength = AgreementCustomPeriodLength;
		EditAgreement.periodLengthOptions = periodLengthOptions;
		EditAgreement.showCustomPeriod = false;

		EditAgreement.hideCustomPeriod = () => {
			const periodLength = resetCustomPeriodToCompatiblePeriodLength(
				EditAgreement.agreement.metadata.periodLength,
				EditAgreement.agreement.metadata.agreementIntervalPeriod
			);
			if (periodLength) {
				EditAgreement.setPeriodLength(periodLength);
			}
			EditAgreement.showCustomPeriod = false;
			$safeApply($scope);
		};

		EditAgreement.agreementVersions = {};
		EditAgreement.openUpAgreementVersion = (agreementVersionId, agreementVersionsAndDates) => {
			$upModal.open('editAgreement', {
				agreementVersionId,
				customerId: customerId,
				readOnlyAgreementVersion: true,
				agreementVersionsAndDates
			});
		};
		EditAgreement.readOnlyAgreementVersion = $modalParams.readOnlyAgreementVersion;
		EditAgreement.agreementVersionsAndDates = $modalParams.agreementVersionsAndDates;
		EditAgreement.agreementVersionId = $modalParams.agreementVersionId;
		EditAgreement.displayRegularFooter = () =>
			!EditAgreement.readOnlyAgreementVersion &&
			(EditAgreement.tabs.isActive('form') ||
				EditAgreement.tabs.isActive('agreementForm') ||
				EditAgreement.tabs.isActive('agreementHistoryTab'));

		EditAgreement.createOrderInAdvanceOptions = (
			AppService.getMetadata().params.SubscriptionInAdvanceOptions || []
		).sort((a, b) => a - b);

		EditAgreement.getCreateOrderInAdvanceOptionText = option => {
			if (option === 0) {
				return T('default.no');
			}
			const dayTranslationKey = option === 1 ? 'calendar.day' : 'calendar.days';
			return `${T('default.yes')}, ${option} ${T(dayTranslationKey).toLowerCase()}  ${T('default.early')}`;
		};

		EditAgreement.PrecreateOptionsButtonSelect = PrecreateOptionsButtonSelect;
		EditAgreement.onPrecreateOptionButtonSelectChange = value => {
			EditAgreement.agreement.metadata.agreementOrderCreationTime = value;
			EditAgreement.datesChanged();
			$safeApply($scope);
		};

		EditAgreement.showPrecreateSelector = () => {
			return EditAgreement.agreementPrecreate && EditAgreement.createOrderInAdvanceOptions.length > 1;
		};

		EditAgreement.showPrecreateOptionsButtonSelect = EditAgreement.createOrderInAdvanceOptions.length === 2;

		$scope.$on('modal.rejected', function (e) {
			if (EditAgreement.tabs.isActive('form') || EditAgreement.tabs.isActive('agreementForm')) {
				prevent = $scope.Agreement.$dirty;
				if (prevent) {
					e.preventDefault();

					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						openModal('UnsavedChangesAlert', {
							onClose: async confirmed => {
								if (confirmed === undefined) {
									return;
								}
								if (confirmed) {
									await EditAgreement.save();
								}

								prevent = false;
								$scope.Agreement.$setPristine();
								$scope.reject();

								// Resume state change if we had one
								if (e.state) {
									e.resumeState();
								}
							}
						});
						return;
					}

					// eslint-disable-next-line promise/catch-or-return
					$upModal
						.open('warningConfirm', {
							title: 'default.abort',
							body: 'confirm.abortEdit',
							resolveTrue: 'default.abortEdit',
							no: 'default.returnToEdit',
							icon: 'fa-warning'
						})
						.then(function () {
							prevent = false;
							$scope.Agreement.$setPristine();
							$scope.reject();

							// Resume state change if we had one
							if (e.state) {
								e.resumeState();
							}
						});
				}
			}
		});

		// Tabs
		EditAgreement.tabs = {
			dashboard: 'upsales/domain/agreement/views/editAgreementDashboard.html',
			form: 'upsales/domain/agreement/views/editAgreementForm.html',
			agreementForm: 'upsales/domain/agreement/views/editAgreementFormOrder.html',
			isActive: function (tab) {
				return EditAgreement.tabs[tab] === EditAgreement.activeTab;
			},
			setActive: function (tab) {
				EditAgreement.activeTab = EditAgreement.tabs[tab];
				$safeApply($scope);
			},
			getActiveTabName: function () {
				if (EditAgreement.tabs.isActive('form')) {
					return 'form';
				} else if (EditAgreement.tabs.isActive('agreementForm')) {
					return 'agreementForm';
				} else if (EditAgreement.tabs.isActive('agreementHistory')) {
					return 'agreementHistory';
				}
			}
		};

		// Order row calculation functions
		EditAgreement.calc = {
			totalGross: function () {
				return EditAgreement.agreement.orderRow.reduce(function (prev, current) {
					const price = current.price > current.$listPrice ? current.price : current.$listPrice;
					if (isTieredTotalOrderRow(current)) {
						return prev + price * 1;
					} else {
						return prev + price * current.quantity;
					}
				}, 0);
			},
			totalDiscount: function () {
				return EditAgreement.agreement.orderRow.reduce(function (prev, current) {
					return prev + parseFloat(current.$discountRaw);
				}, 0);
			},
			totalNet: function () {
				return EditAgreement.agreement.orderRow.reduce(function (prev, current) {
					return (
						prev + (isTieredTotalOrderRow(current) ? current.price * 1 : current.price * current.quantity)
					);
				}, 0);
			},
			totalContributionMargin: function () {
				return EditAgreement.agreement.orderRow.reduce(function (val, row) {
					if (!row) {
						return val;
					}

					if (isTieredTotalOrderRow(row)) {
						return val + ((row.price || 0) - (row.purchaseCost || 0) * row.quantity);
					} else {
						return val + ((row.price || 0) - (row.purchaseCost || 0)) * row.quantity;
					}
				}, 0);
			},
			totalContributionMarginPercentage: () => {
				const totalNet = EditAgreement.calc.totalNet();
				if (!totalNet) {
					return 0;
				}
				const per = EditAgreement.calc.totalContributionMargin() / totalNet;
				return !isNaN(per) ? (per * 100).toFixed(2) : 0;
			},
			totalRR: function () {
				return EditAgreement.agreement.orderRow.reduce(function (val, row) {
					const monthlyValue = (row.price || 0) / EditAgreement.agreement.metadata.agreementIntervalPeriod;
					const annualValue = monthlyValue * 12;

					const value = EditAgreement.salesModelOption === 'mrr' ? monthlyValue : annualValue;
					const quantity = isTieredTotalOrderRow(row) ? 1 : row.quantity;

					return val + (value || 0) * quantity;
				}, 0);
			},
			totalAccountSales: function () {
				return EditAgreement.accountTotal;
			},
			convertedNet: function () {
				return this.totalNet() * (1 / EditAgreement.selectedCurrency.rate);
			},
			updatePriceBasedOnInterval: function (row, interval) {
				interval = interval || row.product?.recurringInterval || EditAgreement.defaultInterval;
				if (row.product?.isRecurring && interval) {
					row.price = row.price * (EditAgreement.agreement.metadata.agreementIntervalPeriod / interval);
					row.purchaseCost =
						row.purchaseCost * (EditAgreement.agreement.metadata.agreementIntervalPeriod / interval);
					row.listPrice =
						row.listPrice * (EditAgreement.agreement.metadata.agreementIntervalPeriod / interval);
					row.$listPrice =
						row.$listPrice * (EditAgreement.agreement.metadata.agreementIntervalPeriod / interval);
				}
			},
			productChange: function (row) {
				if (!row.product) {
					return;
				}
				// Set listprice here and overwrite if multicurrency and selected prod has multi values
				row.listPrice = row.product.listPrice;
				row.$listPrice = row.product.listPrice;

				row.category = row.product.category;
				row.quantity = row.tierQuantity = 1;
				row.$discount = 0;

				// Check if we have a matching tier and set price
				const tierObj = getTierFromOrderRow(row);
				const priceObj = _.find(row.product.currencies, { currency: EditAgreement.selectedCurrency.iso });

				const fallbackValue = EditAgreement.selectedCurrency.masterCurrency ? row.product.purchaseCost : 0;
				row.purchaseCost = priceObj?.purchaseCost || fallbackValue;

				if (!tierObj && row.product.isMultiCurrency && !EditAgreement.selectedCurrency.masterCurrency) {
					row.$listPrice = priceObj ? priceObj.price : 0;
				}

				if (tierObj) {
					const price = getTierPriceByCurrency(tierObj.tier, EditAgreement.selectedCurrency.iso);
					row.$listPrice = row.listPrice = price;
					row.price = price;

					if (tierObj.tier.isTotalPrice) {
						row.tierQuantity = row.quantity;
					}
				}
				EditAgreement.calc.updatePriceBasedOnInterval(row);

				row = fixProductCustomFields(row);

				EditAgreement.calc.discountChange(row);
			},
			// On discountChange
			discountChange: function (orderRow) {
				if (orderRow.$discount !== undefined && !isNaN(orderRow.$discount)) {
					orderRow.$discountPercent = undefined;
					orderRow.$discountRaw = parseFloat(orderRow.$discount);
					let quantity = orderRow.quantity;

					if (orderRow.$discount !== 0 && orderRow.$listPrice === 0) {
						orderRow.$percentPlaceholder = '∞';
					} else {
						if (isTieredTotalOrderRow(orderRow)) {
							quantity = 1;
						}

						orderRow.$percentPlaceholder = (
							(orderRow.$discountRaw / (orderRow.$listPrice * quantity)) *
							100
						).toFixed(EditAgreement.numOfDecimalsPrice);
						if (isNaN(orderRow.$percentPlaceholder)) {
							orderRow.$percentPlaceholder = 0;
						}
					}

					// calc price
					orderRow.price = orderRow.$listPrice - orderRow.$discountRaw / quantity;
					orderRow.listPrice = orderRow.$listPrice;

					if (isNaN(orderRow.price)) {
						orderRow.price = 0;
					}
					if (orderRow.$discount < 0) {
						orderRow.$discount = orderRow.$discountRaw = orderRow.$percentPlaceholder = 0;
					}
				}
			},

			// On discount percent
			discountPercentChange: function (orderRow) {
				if (orderRow.$discountPercent !== undefined && !isNaN(orderRow.$discountPercent)) {
					orderRow.$discount = undefined;

					let quantity = orderRow.quantity;
					if (isTieredTotalOrderRow(orderRow)) {
						quantity = 1;
					}

					orderRow.$discountRaw = parseFloat(
						((orderRow.$discountPercent / 100) * orderRow.$listPrice * quantity).toFixed(
							EditAgreement.numOfDecimalsPrice
						)
					);
					if (isNaN(orderRow.$discountRaw)) {
						orderRow.$discountRaw = 0;
					}

					// calc price
					orderRow.price = orderRow.$listPrice - orderRow.$discountRaw / quantity;

					if (isNaN(orderRow.price)) {
						orderRow.price = 0;
					}
				}
			},
			quantityChange: function (orderRow) {
				// Check if we have a matching tier and update price
				const tierObj = getTierFromOrderRow({ ...orderRow, tierQuantity: orderRow.quantity });
				if (tierObj) {
					let price = getTierPriceByCurrency(tierObj.tier, EditAgreement.selectedCurrency.iso);
					const productInterval = orderRow.product.recurringInterval || EditAgreement.defaultInterval;
					if (orderRow.product.isRecurring && productInterval) {
						price = price * (EditAgreement.agreement.metadata.agreementIntervalPeriod / productInterval);
					}

					const hasDiscount = orderRow.$discountRaw !== 0;
					const hasPercentDiscount =
						orderRow.$discountPercent !== undefined && !isNaN(orderRow.$discountPercent);
					let quantity = orderRow.quantity;
					orderRow.$listPrice = price;

					if (isTieredTotalOrderRow({ ...orderRow, tierQuantity: quantity })) {
						quantity = 1;
						orderRow.tierQuantity = orderRow.quantity;
					}

					// Check if we ended up in another tier and if so calc new discount
					if (hasDiscount) {
						if (hasPercentDiscount) {
							const dPercent = 100 - (orderRow.price / orderRow.listPrice) * 100;
							orderRow.$discountRaw = parseFloat(
								((dPercent / 100) * orderRow.$listPrice * quantity).toFixed(
									EditAgreement.numOfDecimalsPrice
								)
							);
						} else {
							orderRow.$percentPlaceholder = (orderRow.$discountRaw / price) * 100;
						}

						if (isNaN(orderRow.$discountRaw)) {
							orderRow.$discountRaw = 0;
						}

						// calc price
						orderRow.price = price - orderRow.$discountRaw / quantity;

						if (isNaN(orderRow.price)) {
							orderRow.price = 0;
						}
					} else {
						orderRow.price = price;
					}

					orderRow.listPrice = price;
				} else {
					// Calculate discount

					let quantity = orderRow.quantity;

					if (isTieredTotalOrderRow(orderRow)) {
						quantity = 1;
					}

					var percent = 0;
					if (orderRow.price > orderRow.$listPrice) {
						orderRow.$discountRaw = 0;
					} else {
						orderRow.$discountRaw = ((orderRow.$listPrice - orderRow.price) * quantity).toFixed(
							EditAgreement.numOfDecimalsPrice
						);
						percent = parseFloat(
							(100 - (orderRow.price / orderRow.$listPrice) * 100).toFixed(
								EditAgreement.numOfDecimalsPrice
							)
						);
					}

					// Failsafe
					if (isNaN(orderRow.$discountRaw)) {
						orderRow.$discountRaw = 0;
					}

					// check if percent was active and keep it active
					if (orderRow.$discountPercent !== undefined && !isNaN(orderRow.$discountPercent)) {
						orderRow.$discount = undefined;
						orderRow.$discountPercent = percent;
					} else {
						// else set money discount as active
						orderRow.$discountPercent = undefined;
						orderRow.$percentPlaceholder = percent;
						orderRow.$discount = parseFloat(orderRow.$discountRaw);
					}
				}
			},
			priceChange: function (orderRow) {
				var productCurrencyPrice;

				if (orderRow.product.isMultiCurrency && !EditAgreement.selectedCurrency.masterCurrency) {
					productCurrencyPrice = _.find(orderRow.product.currencies, {
						currency: EditAgreement.selectedCurrency.iso
					});

					productCurrencyPrice = productCurrencyPrice ? productCurrencyPrice.price : 0;
				}

				if (orderRow.product.listPrice === 0 || productCurrencyPrice === 0) {
					orderRow.$listPrice = orderRow.price;
					orderRow.$discount = 0;
					orderRow.$discountRaw = 0;
					orderRow.$percentPlaceholder = 0;
					orderRow.$discountPercent = undefined;
				} else {
					// Calculate discount
					var percent = 0;
					if (orderRow.price > orderRow.$listPrice) {
						orderRow.$discountRaw = 0;
					} else {
						orderRow.$discountRaw = parseFloat(
							((orderRow.$listPrice - orderRow.price) * orderRow.quantity).toFixed(
								EditAgreement.numOfDecimalsPrice
							)
						);
						percent = parseFloat(
							(100 - (orderRow.price / orderRow.$listPrice) * 100).toFixed(
								EditAgreement.numOfDecimalsPrice
							)
						);
					}

					// Failsafe
					if (isNaN(orderRow.$discountRaw)) {
						orderRow.$discountRaw = 0;
					}

					// Calculate percent

					// check if percent was active and keep it active
					if (orderRow.$discountPercent !== undefined && !isNaN(orderRow.$discountPercent)) {
						orderRow.$discount = undefined;
						orderRow.$discountPercent = percent;
					} else {
						// else set money discount as active
						orderRow.$discountPercent = undefined;
						orderRow.$percentPlaceholder = percent;
						orderRow.$discount = parseFloat(orderRow.$discountRaw);
					}
				}
			}
		};

		// Function to get new contacts when the client field changes
		EditAgreement.accountChange = function (keepContact) {
			if (EditAgreement.agreement.client) {
				EditAgreement.agreement.contact = keepContact ? EditAgreement.agreement.contact : undefined;
				EditAgreement.contacts = [];

				if (EditAgreement.agreement.client.id && EditAgreement.agreement.client.id > 0) {
					Account.customer(customerId)
						.get(EditAgreement.agreement.client.id)
						.then(function (res) {
							EditAgreement.account = res.data;
							getConnectedClients();
						})
						.catch(e => {
							console.error('Failed to fetch client', e);
						});

					const clientIds = [EditAgreement.agreement.client.id];
					// When this is run from init we may have a connectedClient
					if (_.get(EditAgreement.agreement, 'clientConnection.id') > 0) {
						clientIds.push(EditAgreement.agreement.clientConnection.id);
					}

					utils.req
						.clientContactsAndRelations(clientIds)
						.then(function (res) {
							EditAgreement.contacts = res.data;
							var data = EditAgreement.select.contact.data;
							data.splice(0, data.length);
							data.push.apply(data, res.data);
						})
						.catch(e => {
							console.error('Failed to get clientContactsAndRelations', e);
						});
				}
			} else {
				EditAgreement.select.contact.data = [];
			}
		};

		EditAgreement.relatedAccountChange = function () {
			function setData(data) {
				data.unshift({ id: null, name: '' });
				const contactSelectData = EditAgreement.select.contact.data;
				contactSelectData.splice(0, contactSelectData.length);
				contactSelectData.push.apply(contactSelectData, data);

				if (EditAgreement.agreement.contact) {
					const found = _.find(contactSelectData, { id: EditAgreement.agreement.contact.id });
					EditAgreement.agreement.contact = found ? found : null;
				}
			}
			const clientIds = [];

			if (_.get(EditAgreement.agreement, 'client.id') > 0) {
				clientIds.push(EditAgreement.agreement.client.id);
			}
			if (_.get(EditAgreement.agreement, 'clientConnection.id') > 0) {
				clientIds.push(EditAgreement.agreement.clientConnection.id);
			}
			if (clientIds.length) {
				const filters = new RequestBuilder();
				filters.addFilter(Contact.attr.account.attr.id, filters.comparisonTypes.Equals, clientIds);
				filters.addSort(Contact.attr.name, true);

				Contact.customer(customerId)
					.find(filters.build())
					.then(function (res) {
						if (Array.isArray(res.data)) {
							setData(res.data);
						}
					})
					.catch(e => {
						console.error('Failed to fetch contacts', e);
						setData([]);
					});
			} else {
				setData([]);
			}
		};

		EditAgreement.createContact = function () {
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('editContact', {
					customerId: customerId,
					account: EditAgreement.agreement.client
				})
				.then(function (contact) {
					if (contact.active) {
						EditAgreement.select.contact.data.push(contact);
						EditAgreement.agreement.contact = contact;
					}
				});
		};

		EditAgreement.createCopy = function () {
			if (!EditAgreement.agreement.id) {
				return;
			}

			var opts = {
				customerId: customerId,
				copyId: EditAgreement.agreement.id
			};

			$upModal.open('editAgreement', opts);
		};

		// Create document
		EditAgreement.createDocument = function (template) {
			var params = {
				entityId: EditAgreement.agreement.id,
				templateId: template.id,
				type: 'agreement',
				name: template.name,
				accountId: EditAgreement.agreement.client.id
			};

			if (EditAgreement.agreement.contact) {
				params.contactId = EditAgreement.agreement.contact.id;
			}

			prevent = $scope.Agreement.$dirty;

			if (!prevent) {
				if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
					return openModal('PreviewPdfModal', {
						isUploadedTemplate: true,
						documentResource: {
							resource: documentResource,
							entityId: params.entityId,
							templateId: params.templateId,
							type: params.type,
							documentName: params.name,
							accountId: params.accountId,
							contact: params.contact,
							contactId: params.contactId
						}
					});
				} else {
					return $upModal.open('pdfPreview', params);
				}
			} else {
				$scope.Agreement.$setDirty(false);
				$scope.Agreement.$setPristine(true);
				EditAgreement.save(true)
					.then(function () {
						if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
							openModal('PreviewPdfModal', {
								isUploadedTemplate: true,
								documentResource: {
									resource: documentResource,
									entityId: params.entityId,
									templateId: params.templateId,
									type: params.type,
									documentName: params.name,
									accountId: params.accountId,
									contact: params.contact,
									contactId: params.contactId
								}
							});
						} else {
							$upModal.open('pdfPreview', params);
						}
					})
					.catch(e => {
						console.error('Failed to save agreement', e);
					});
			}
		};

		// function to select yourself§
		EditAgreement.selectMe = function () {
			EditAgreement.agreement.user = EditAgreement.self;
		};

		// // Triggered when the order currency is changes
		EditAgreement.changeCurrency = function () {
			if (EditAgreement.selectedCurrency) {
				angular.forEach(EditAgreement.agreement.orderRow, function (row) {
					EditAgreement.calc.productChange(row);
				});
				EditAgreement.agreement.currency = EditAgreement.selectedCurrency.iso;
				EditAgreement.agreement.currencyRate = EditAgreement.selectedCurrency.rate;
				EditAgreement.convert = EditAgreement.agreement.currency !== EditAgreement.masterCurrency;
			}
		};

		// add order row function
		EditAgreement.addOrderRow = function (row) {
			var orderRow = row || Order.newRow();
			var length = EditAgreement.agreement.orderRow.push(orderRow);

			// Find prev row
			if (EditAgreement.agreement.orderRow[length - 2]) {
				// Set sort id on added row to one more than prev
				EditAgreement.agreement.orderRow[length - 1].sortId =
					EditAgreement.agreement.orderRow[length - 2].sortId + 1;
			}

			// update modal position
			$scope.reloadModalPosition();
		};

		// delete order row
		EditAgreement.deleteOrderRow = function (orderRow) {
			_.pull(EditAgreement.agreement.orderRow, orderRow);
			if (!EditAgreement.agreement.orderRow.length) {
				EditAgreement.addOrderRow();
			}
		};
		// copy order row
		EditAgreement.copyOrderRow = function (orderRow) {
			var copy = _.cloneDeep(orderRow);
			delete copy.id;
			delete copy.$$hashKey;

			EditAgreement.addOrderRow(copy);

			// update modal position
			$scope.reloadModalPosition();
		};

		const changeRowOrder = (index, newIndex) => {
			const [row] = EditAgreement.agreement.orderRow.splice(index, 1);
			EditAgreement.agreement.orderRow.splice(newIndex, 0, row);
			EditAgreement.agreement.orderRow.forEach((r, i) => (r.sortId = i + 1));
			$scope.Agreement.$setDirty(true);
			$safeApply($scope);
		};

		EditAgreement.sortRowUp = (row, index, enabled) => {
			if (!enabled) {
				return;
			}
			changeRowOrder(index, index - 1);
		};
		EditAgreement.sortRowDown = (row, index, enabled) => {
			if (!enabled) {
				return;
			}
			changeRowOrder(index, index + 1);
		};

		// Show save button or not
		EditAgreement.showSave = function () {
			if (!EditAgreement.editable) {
				return false;
			}

			if (EditAgreement.edit || EditAgreement.tabs.isActive('agreementForm')) {
				return true;
			}
		};

		EditAgreement.startDateChanged = function () {
			if (!EditAgreement.agreement.metadata.agreementStartdate) {
				EditAgreement.agreement.metadata.agreementStartdate = moment().toDate();
			}

			if (
				moment(EditAgreement.agreement.metadata.agreementInvoiceStartdate).isBefore(
					EditAgreement.agreement.metadata.agreementStartdate
				)
			) {
				EditAgreement.agreement.metadata.agreementInvoiceStartdate =
					EditAgreement.agreement.metadata.agreementStartdate;
			}

			EditAgreement.datesChanged();
		};

		EditAgreement.invoiceStartDateChanged = function () {
			if (!EditAgreement.agreement.metadata.agreementInvoiceStartdate) {
				EditAgreement.agreement.metadata.agreementInvoiceStartdate = moment().toDate();
			}

			if (
				moment(EditAgreement.agreement.metadata.agreementInvoiceStartdate).isBefore(
					EditAgreement.agreement.metadata.agreementStartdate
				)
			) {
				EditAgreement.agreement.metadata.agreementStartdate =
					EditAgreement.agreement.metadata.agreementInvoiceStartdate;
			}

			EditAgreement.datesChanged();
		};

		const onIntervalChange = () => {
			for (const row of EditAgreement.agreement.orderRow) {
				EditAgreement.calc.updatePriceBasedOnInterval(
					row,
					EditAgreement.agreement.metadata.agreementIntervalPeriodOld
				);
			}
			EditAgreement.agreement.metadata.agreementIntervalPeriodOld =
				EditAgreement.agreement.metadata.agreementIntervalPeriod;
		};

		EditAgreement.intervalChanged = function () {
			if (
				parseInt(EditAgreement.agreement.metadata.periodLength) %
					parseInt(EditAgreement.agreement.metadata.agreementIntervalPeriod) !==
					0 &&
				EditAgreement.agreement.metadata.periodLength !== '0'
			) {
				if (parseInt(EditAgreement.agreement.metadata.agreementIntervalPeriod) === 4) {
					EditAgreement.setPeriodLength(12);
				} else {
					EditAgreement.setPeriodLength(EditAgreement.agreement.metadata.agreementIntervalPeriod);
				}
			}

			onIntervalChange();
			updateIsValidNoticePeriod();
			$safeApply($scope);
		};

		const setAgreementIntervalPeriodBasedOnPeriodLength = () => {
			const periodLength = parseInt(EditAgreement.agreement.metadata.periodLength);
			const intervalPeriod = parseInt(EditAgreement.agreement.metadata.agreementIntervalPeriod);
			const highestCompatibleIntervalOption = getIntervalPeriodBasedOnPeriodLength(periodLength, intervalPeriod);
			if (!highestCompatibleIntervalOption) {
				return;
			}

			EditAgreement.agreement.metadata.agreementIntervalPeriod = highestCompatibleIntervalOption;
			onIntervalChange();
		};

		EditAgreement.periodChanged = function () {
			if (EditAgreement.agreement.metadata.periodLength === 'custom') {
				const previousPeriodLength = parseInt(EditAgreement.agreement.metadata.periodLengthOld);
				EditAgreement.setPeriodLength(previousPeriodLength || EditAgreement.periodLengthOptions[0], false);
				setAgreementIntervalPeriodBasedOnPeriodLength();

				EditAgreement.showCustomPeriod = true;
				$safeApply($scope);
				return;
			} else {
				EditAgreement.setPeriodLength(EditAgreement.agreement.metadata.periodLength, false);
			}

			/* eslint-disable eqeqeq */
			if (EditAgreement.agreement.metadata.periodLength == 0) {
				/* eslint-enable eqeqeq */
				EditAgreement.agreement.metadata.agreementRenewalDate = null;
			} else {
				setAgreementIntervalPeriodBasedOnPeriodLength();
			}
			updateIsValidNoticePeriod();
			$safeApply($scope);
		};

		EditAgreement.hasAgreementInitialPeriodFeature = FeatureHelper.hasSoftDeployAccess('AGREEMENT_INITIAL_PERIOD');
		EditAgreement.AgreementFollowUpChip = AgreementFollowUpChip;
		EditAgreement.AgreementInitialPeriodToggle = AgreementInitialPeriodToggle;
		EditAgreement.toggleInitialPeriod = function () {
			$scope.createFollowUpPeriod = !$scope.createFollowUpPeriod;
			$safeApply($scope);
		};

		EditAgreement.calculateRenewalDate = function () {
			/* eslint-disable eqeqeq */
			if (EditAgreement.agreement.metadata.periodLength != 0) {
				/* eslint-enable eqeqeq */
				var periodLength = EditAgreement.agreement.metadata.periodLength;
				var earliestRenewalDate = moment(EditAgreement.agreement.metadata.agreementInvoiceStartdate);
				earliestRenewalDate = moment(earliestRenewalDate).add(periodLength, 'month');

				if (!moment(earliestRenewalDate).isBefore(EditAgreement.agreement.metadata.agreementRenewalDate)) {
					EditAgreement.agreement.metadata.agreementRenewalDate = earliestRenewalDate.toDate();
				}

				EditAgreement.agreement.metadata.temporaryNextOrderDate = getNextOrderDate(true);
			}
		};

		EditAgreement.datesChanged = function () {
			EditAgreement.agreement.metadata.temporaryNextOrderDate = getNextOrderDate(true);

			/* eslint-disable eqeqeq */
			if (!EditAgreement.agreement.id && EditAgreement.agreement.metadata.periodLength != 0) {
				/* eslint-enable eqeqeq */
				// Calculate renewal date
				var periodLength = EditAgreement.agreement.metadata.periodLength;
				var earliestRenewalDate = moment(EditAgreement.agreement.metadata.agreementInvoiceStartdate).add(
					periodLength,
					'months'
				);
				EditAgreement.agreement.metadata.agreementRenewalDate = earliestRenewalDate.toDate();
			}
			$safeApply($scope);
		};

		function getNextOrderDate(onChange) {
			// next order date calculation from doCreateNextOrder in base/src/domain/repositories/elastic/agreement.js
			const theStartDate =
				EditAgreement.agreement.metadata.agreementInvoiceStartdate ||
				EditAgreement.agreement.metadata.agreementStartdate;
			const offset = EditAgreement.agreement.metadata.agreementOrderCreationTime || 0;

			const renewalDate = EditAgreement.agreement.metadata.agreementRenewalDate;
			const defaultEndOfAgreementPeriod = moment(theStartDate).add(
				EditAgreement.agreement.metadata.periodLength,
				'months'
			);

			let nextOrderDate;
			if (EditAgreement.agreement.metadata.agreementNextOrderDate) {
				// Next order date exists already so we should use it
				nextOrderDate = moment(EditAgreement.agreement.metadata.agreementNextOrderDate);
				if (onChange) {
					// If order creation time changes we need to add the difference to the order date
					const dayDiff =
						EditAgreement.initAgreementOrderCreationTime -
						EditAgreement.agreement.metadata.agreementOrderCreationTime;
					nextOrderDate.add(dayDiff, 'days');
				}

				const dateOfNextPeriodOrder = defaultEndOfAgreementPeriod.subtract(
					EditAgreement.initAgreementOrderCreationTime,
					'days'
				);
				if (
					EditAgreement.agreement.metadata.periodLength &&
					renewalDate &&
					nextOrderDate.isSameOrAfter(dateOfNextPeriodOrder)
				) {
					nextOrderDate = moment(renewalDate).subtract(offset, 'days');
				}
			} else {
				nextOrderDate = moment(theStartDate).subtract(offset, 'days');
			}

			// Calculate end of current invoice period
			let endOfCurrentInvoicePeriod;

			if (!EditAgreement.agreement.metadata.orderSequenceNr) {
				endOfCurrentInvoicePeriod = moment(theStartDate);
			} else {
				nextOrderDate.add(offset, 'days');
				endOfCurrentInvoicePeriod = nextOrderDate.clone();
				nextOrderDate.subtract(offset, 'days');

				if (renewalDate && endOfCurrentInvoicePeriod.isSameOrAfter(defaultEndOfAgreementPeriod)) {
					endOfCurrentInvoicePeriod = moment(renewalDate);
				}
			}

			// No next order date if agreement ends before end of current invoice period
			const agreementEnddate = EditAgreement.agreement.metadata.agreementEnddate;
			if (agreementEnddate && moment(agreementEnddate).isSameOrBefore(endOfCurrentInvoicePeriod)) {
				return null;
			}

			// If next order date is before today, set it to today
			if (
				(EditAgreement.agreement.metadata.willCreateMoreOrders || !EditAgreement.agreement.id) &&
				nextOrderDate.isBefore(moment())
			) {
				return moment().format();
			}

			return nextOrderDate.format();
		}

		EditAgreement.initDates = function () {
			EditAgreement.agreement.metadata.temporaryNextOrderDate = getNextOrderDate();
		};

		EditAgreement.hasTotalValueIncreased = function () {
			var oldTotalValue = 0;
			var newTotalValue = 0;

			angular.forEach(EditAgreement.agreement.orderRow, function (newRow) {
				newTotalValue += newRow.price * newRow.quantity;
			});

			angular.forEach(EditAgreement.untouchedAgreement.orderRow, function (oldRow) {
				oldTotalValue += oldRow.price * oldRow.quantity;
			});

			return newTotalValue > oldTotalValue;
		};

		EditAgreement.shouldCreateDiffOrder = function () {
			if (!EditAgreement.agreement.hasOwnProperty('id')) {
				return false;
			} else if (EditAgreement.agreement.id === 0) {
				return false;
			} else if (!EditAgreement.hasTotalValueIncreased()) {
				return false;
			} else if (!EditAgreement.agreement.metadata.orderSequenceNr) {
				return false;
			}

			return true;
		};

		EditAgreement.previewSubscription = function () {
			const { metadata } = EditAgreement.agreement;
			openModal('SubscriptionPreview', {
				periodLength: parseInt(metadata.periodLength),
				startDate: metadata.agreementStartdate,
				orderDate: metadata.temporaryNextOrderDate,
				orderInterval: parseInt(metadata.agreementIntervalPeriod),
				renewalDate: metadata.agreementRenewalDate,
				agreement: EditAgreement.agreement,
				orderCreationTime: parseInt(metadata.agreementOrderCreationTime),
				endDate: metadata.agreementEnddate
			});
		};

		EditAgreement.saveConfirm = function () {
			if (EditAgreement.shouldPreviewSubscription) {
				globalTracker.track('Number of preview-clicks', { clicks: EditAgreement.numbersOfPreviews });
			}
			if (!EditAgreement.shouldCreateDiffOrder()) {
				return EditAgreement.save();
			}

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'agreement.createDiffOrder',
					body: 'agreement.createDiffOrderMessage',
					confirmButtonText: 'default.yes',
					cancelButtonText: 'default.no',
					headerIcon: 'info',
					onClose: confirmed => {
						if (confirmed === undefined) {
							return;
						}

						if (confirmed) {
							EditAgreement.agreement.createDiffOrder = true;
						}
						EditAgreement.save();
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('defaultConfirm', {
					title: 'agreement.createDiffOrder',
					body: 'agreement.createDiffOrderMessage',
					resolveTrue: 'default.yes',
					no: 'default.no',
					icon: 'fa-info'
				})
				.then(function () {
					EditAgreement.agreement.createDiffOrder = true;
				})
				.finally(function () {
					EditAgreement.save();
				});
		};

		// Save function
		EditAgreement.save = function () {
			if (EditAgreement.saving) {
				return;
			}
			EditAgreement.saving = true;

			// Remove empty rows
			EditAgreement.agreement.orderRow = _.filter(EditAgreement.agreement.orderRow, function (row) {
				return row.product && row.product.id;
			});

			return ScriptService.agreement
				.save(EditAgreement.agreement)
				.then(function () {
					var agreement = _.cloneDeep(EditAgreement.agreement);

					// CFs
					agreement.custom = [];
					_.each(EditAgreement.orderCustomFields, function (field) {
						if (field.hasOwnProperty('value')) {
							agreement.custom.push({
								fieldId: field.id,
								value: ActionProperties.getCustomFieldValue(field)
							});
						}
					});

					agreement.metadata.custom = [];
					_.each(EditAgreement.customFields, function (field) {
						if (field.hasOwnProperty('value')) {
							agreement.metadata.custom.push({
								fieldId: field.id,
								value: ActionProperties.getCustomFieldValue(field)
							});
						}
					});

					agreement.value = EditAgreement.calc.totalNet();
					agreement.account = agreement.client;

					var sortId = 1;

					angular.forEach(agreement.orderRow, function (row) {
						row.listPrice = row.$listPrice / agreement.currencyRate;
						row.price = row.price / agreement.currencyRate;

						row.sortId = sortId++;

						row.custom = _.map(row.$mappedCustom, function (field, fieldId) {
							return { fieldId: parseInt(fieldId), value: ActionProperties.getCustomFieldValue(field) };
						});

						// Set back quantity to 1 for tiered isTotalPrice products and keep "real quantity" in tierQuantity
						if (isTieredOrderRow(row)) {
							row.tierQuantity = row.quantity;
							if (isTieredTotalOrderRow(row)) {
								row.quantity = 1;
							}
						}
					});

					if (!agreement.metadata.Description || agreement.metadata.Description === '') {
						agreement.metadata.Description = '<<' + T('default.noDescription') + '>>';
					}

					Agreement.customer(customerId)
						.save(agreement)
						.then(function (response) {
							setTimeout(function () {
								// Go to overview tab
								EditAgreement.edit = true;
								EditAgreement.agreement.id = response.data.id;

								subscriptionsTracker.track(subscriptionsTracker.events.NOTICE_PERIOD, {
									noticePeriod: EditAgreement.agreement.metadata.noticePeriod,
									subscriptionId: response.data.id
								});

								var newAgreement = FixOrder.print(response.data);
								newAgreement.yearlyValue =
									newAgreement.value * (12 / newAgreement.metadata.agreementIntervalPeriod);

								if (response.metadata.createdOrderId) {
									if (agreement.createdFromOrderId === response.metadata.createdOrderId) {
										$scope.resolve();
										return;
									}

									Order.customer(customerId)
										.get(response.metadata.createdOrderId)
										.then(function (res) {
											if (res && res.data) {
												$rootScope.$broadcast('order.added', res.data);
											}
										})
										.catch(e => {
											console.error('Failed to get order', e);
										});
								}

								if (response.metadata.diffCreated) {
									var params = _.cloneDeep($modalParams);
									params.id = response.metadata.createdOrderId;

									$upModal.open('editOrder', params);
								}

								EditAgreement.saving = false;

								if ($scope.createFollowUpPeriod) {
									var opts = {
										customerId: customerId,
										followUpId: EditAgreement.agreement.id
									};

									$upModal.open('editAgreement', opts);
								}

								$scope.resolve();
							}, 2000);
						})
						.catch(function () {
							EditAgreement.saving = false;
						});
				})
				.catch(function () {
					EditAgreement.saving = false;
				});
		};

		EditAgreement.orderDisabled = function () {
			if (EditAgreement.readOnlyAgreementVersion) {
				return false;
			}
			if (
				!EditAgreement.agreement.metadata.agreementDescription ||
				!EditAgreement.agreement.metadata.agreementDescription.length
			) {
				return true;
			}

			if ($scope.createFollowUpPeriod && !EditAgreement.agreement.metadata.agreementEnddate) {
				return true;
			}

			if (!EditAgreement.isValidNoticePeriod) {
				return true;
			}

			var customFieldsRequired = false;
			_.each(EditAgreement.customFields, function (f) {
				const isNumber = typeof f.value === 'number' && !Number.isNaN(f.value);
				const isTrueish = !!f.value;
				const hasLength = !!f.value?.length;
				const hasValue = isNumber || isTrueish || hasLength;
				if ((f.editable || f.visible) && f.obligatoryField && !hasValue) {
					customFieldsRequired = true;
				}
			});
			return customFieldsRequired;
		};

		function getConnectedClients() {
			if (
				!EditAgreement.clientOrderRelation ||
				!EditAgreement.agreement.client ||
				!EditAgreement.agreement.client.id
			) {
				return;
			}
			EditAgreement.relatedClients = [];

			accountRelations
				.get(EditAgreement.account, true)
				.then(function (res) {
					EditAgreement.relatedClients = res;
					EditAgreement.select.relatedClients.data.splice(0, EditAgreement.select.relatedClients.data.length);
					EditAgreement.select.relatedClients.data.push.apply(
						EditAgreement.select.relatedClients.data,
						EditAgreement.relatedClients
					);
				})
				.catch(e => {
					console.error('Failed to get account relations', e);
				});
		}

		var mapRowCustom = function (customArr) {
			var mapped = {};
			angular.forEach(customArr, function (field) {
				if (!field.fieldId) {
					field.fieldId = field.id;
				}
				mapped[field.fieldId] = field;
			});
			return mapped;
		};

		var setCustomDefault = function (custom) {
			if (!EditAgreement.edit) {
				if (custom.datatype === 'Select') {
					custom.value = custom.value || (custom.dropdownDefault ? custom.dropdownDefault : '');
				} else if (custom['default'] !== null && custom['default'] !== undefined && custom['default'] !== '') {
					custom.value = custom['default'];
				}
			}
			if (custom.datatype === 'Integer') {
				custom.value = parseInt(custom.value);
			}
		};

		function fixProductCustomFields(row) {
			if (row.product) {
				var product = _.find(AppService.getProducts(), { id: row.product.id });
				if (product) {
					product.custom = _.map(product.custom, function (c) {
						var field = _.find(AppService.getCustomFields('product'), { id: c.fieldId });
						if (field) {
							c.datatype = field.datatype;
						}
						return c;
					});
					row.product = product;
				}
			}

			return row;
		}

		// Init function
		var init = function () {
			EditAgreement.isAvailable = {
				orderRowCustom: FeatureHelper.isAvailable(FeatureHelper.Feature.ORDER_ROW_CUSTOM),
				companyRelations: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANY_RELATIONS)
			};

			// Metadata
			var metadata = AppService.getMetadata();

			EditAgreement.hasContributionMargin = metadata.params.UseContributionMargin || hasRRWithCM(metadata);

			EditAgreement.recurringIntervals = AppService.getStaticValues('recurringInterval');

			const cmWithRROption = getCMWithRROption(metadata);
			EditAgreement.salesModelOption = cmWithRROption ?? metadata.params.SalesModelOption;

			const hasRecurringOrder = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER);
			const hasRecurringSalesModel = metadata.params.SalesModel === 'rr';
			EditAgreement.hasRecurringInterval = (hasRecurringOrder && hasRecurringSalesModel) || !!cmWithRROption;
			EditAgreement.defaultInterval = metadata.params.SubscriptionDefaultInterval || 1;

			// requiredFields
			EditAgreement.requiredFields = metadata.requiredFields.Order;

			// SELF
			EditAgreement.self = AppService.getSelf();
			EditAgreement.accountSelf = AppService.getAccountSelf();

			// The objects data
			EditAgreement.agreement = meta.agreement.data;

			if (EditAgreement.agreement.isFollowUp) {
				EditAgreement.followUpDescription = EditAgreement.agreement.description;
				EditAgreement.startDateChanged();
			}

			// Making sure to always display selected agreementOrderCreationTime
			const agreementOrderCreationTime = EditAgreement.agreement.metadata?.agreementOrderCreationTime || 0;
			EditAgreement.initAgreementOrderCreationTime = agreementOrderCreationTime;
			const nonExistingPrecreateOption =
				!EditAgreement.createOrderInAdvanceOptions.includes(agreementOrderCreationTime);
			if (nonExistingPrecreateOption) {
				EditAgreement.createOrderInAdvanceOptions.push(agreementOrderCreationTime);
				EditAgreement.createOrderInAdvanceOptions.sort((a, b) => a - b);
			}

			// Is edit or nah
			EditAgreement.edit = !!EditAgreement.agreement.id;

			//Should show preview instead of save if not edit and has flag
			EditAgreement.shouldPreviewSubscription = !EditAgreement.edit;

			// Selectable contacts
			EditAgreement.contacts = meta.contacts.data;

			// User rights
			EditAgreement.ownRightsId =
				EditAgreement.self.createRights.Order === 'OWN' ? EditAgreement.self.id : undefined;

			// Can precreate
			EditAgreement.agreementPrecreate = metadata.params.AgreementPrecreate;

			// Has extra client
			const clientRelationField = _.get(metadata.standardFields, 'Order.ClientOrderRelation', {});
			const isClientRelationActive =
				clientRelationField.active && FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
			const [language] = (Tools.AppService.getSelf().language || '').split('-');
			EditAgreement.clientOrderRelation = isClientRelationActive
				? _.get(clientRelationField, `fieldNameTranslations[${language}].value`) ||
				  T(clientRelationField.nameTag)
				: metadata.params.clientOrderRelation;

			// ClientParam for number of decimals on price
			EditAgreement.numOfDecimalsPrice = metadata.params.OrderedProductPriceDecimals || 2;

			// Stages
			var stages = meta.stages.data;
			EditAgreement.stages = stages;

			// Set active tab
			EditAgreement.tabs.setActive('form');

			// Agreement customfields
			EditAgreement.customFields = meta.customFields.data;
			EditAgreement.customFieldsLength = _.filter(meta.customFields.data, function (f) {
				return f.editable || f.visible;
			}).length;

			// Order customfields
			EditAgreement.orderCustomFields = meta.orderCustomFields.data;
			EditAgreement.orderCustomFieldsLength = _.filter(meta.orderCustomFields.data, function (f) {
				return f.$hasAccess && (f.editable || f.visible);
			}).length;

			// Order row custom
			EditAgreement.orderRowCustomFields = meta.orderrowCustomFields.data;
			EditAgreement.orderRowCustomFieldsLength = _.filter(meta.orderrowCustomFields.data, function (f) {
				return f.$hasAccess && (f.editable || f.visible);
			}).length;

			// set custom field default values
			EditAgreement.customFields.forEach(setCustomDefault);
			EditAgreement.orderCustomFields.forEach(setCustomDefault);

			var allOrderStages = AppService.getStages('won', true);

			// if this is an edit
			if (!EditAgreement.edit) {
				// set default stage if agreement have no stage (happens if created from order)
				if (!EditAgreement.agreement.stage && metadata.params.SubscriptionDefaultStageId) {
					// Select default stage if we have one and we can find it else select first
					EditAgreement.agreement.stage = _.find(stages.length ? stages : allOrderStages, {
						id: metadata.params.SubscriptionDefaultStageId
					});
				}

				if (!EditAgreement.agreement.stage) {
					// Select default stage if we have one and we can find it else select first
					EditAgreement.agreement.stage = _.find(stages.length ? stages : allOrderStages, {
						id: metadata.params.defaultStageId
					});
				}

				if (!EditAgreement.agreement.stage) {
					EditAgreement.agreement.stage = stages.length ? stages[0] : allOrderStages[0];
				}

				// Trim description if length is greater than max length. THis could happen when creating agreement through order.
				const agreementDescription = EditAgreement.agreement.metadata.agreementDescription;
				if (agreementDescription && agreementDescription.length > EditAgreement.descriptionMaxLength) {
					EditAgreement.agreement.metadata.agreementDescription = agreementDescription.substring(
						0,
						EditAgreement.descriptionMaxLength
					);
				}
			} else {
				/* You will not be able to choose all of this stages when editing the agreement, but we dont want to crash the UI if an agreement for some reason have an opportunity stage. */
				if (!EditAgreement.agreement.stage) {
					EditAgreement.agreement.stage = { probability: 100 };
				} else {
					EditAgreement.agreement.stage.probability = 100;
				}
			}

			// fix them orderRows
			if (EditAgreement.agreement.orderRow) {
				var sortId = 0;
				EditAgreement.agreement.orderRow.forEach(function (row) {
					sortId++;
					row.$listPrice = row.listPrice * EditAgreement.agreement.currencyRate;

					row.$discount = parseFloat(
						((row.listPrice - row.price) * row.quantity * EditAgreement.agreement.currencyRate).toFixed(
							EditAgreement.numOfDecimalsPrice
						)
					);
					row.$mappedCustom = mapRowCustom(row.custom);
					EditAgreement.calc.discountChange(row);

					if (row.sortId === undefined) {
						row.sortId = sortId;
					}

					// Set purchaseCost
					row.purchaseCost = row.purchaseCost === null ? 0 : row.purchaseCost;
					row = fixProductCustomFields(row);

					// "fix quantity" for tiered products
					// if a product is tiered we set quantity = tierQuantity so that the editor will work
					// Before save we set tierQuantity = quantity and quantity = 1 so that backend calculations and integrations will work
					if (isTieredOrderRow(row)) {
						row.quantity = row.tierQuantity;
					}
				});

				// Sort by sortId on init
				EditAgreement.agreement.orderRow = _.sortBy(EditAgreement.agreement.orderRow, 'sortId');
			}

			if (!EditAgreement.agreement.orderRow) {
				EditAgreement.agreement.orderRow = [];
			}

			// Use for comparison when calculating if diff order should be created
			EditAgreement.untouchedAgreement = _.cloneDeep(EditAgreement.agreement);

			// set currency
			EditAgreement.selectedCurrency = _.find(metadata.customerCurrencies, { iso: meta.agreement.data.currency });
			EditAgreement.masterCurrency = _.find(metadata.customerCurrencies, { masterCurrency: true }).iso;
			EditAgreement.hasMultiCurrency = metadata.params.MultiCurrency;
			EditAgreement.initDates();

			// Set editable
			EditAgreement.editable =
				!EditAgreement.readOnlyAgreementVersion &&
				(EditAgreement.edit ? EditAgreement.agreement.userEditable : true);

			// Setup select2 configs
			EditAgreement.select = {
				// user
				user: {
					formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
					formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
					data: meta.users.data,
					matcher: function (term, undef, obj) {
						return obj.name.toUpperCase().indexOf(term.toUpperCase()) !== -1;
					}
				},
				currency: {
					formatSelection: (obj, container, escape) => escape(_.property('iso')(obj)),
					formatResult: (obj, container, query, escape) => escape(_.property('iso')(obj)),
					data: metadata.customerCurrencies.filter(currency => currency.active),
					id: 'iso',
					matcher: function (term, undef, obj) {
						return obj.iso.toUpperCase().indexOf(term.toUpperCase()) === 0;
					}
				},
				contact: {
					data: EditAgreement.contacts,
					formatSelection: function (contact) {
						return utils.select2.clientContactsAndRelations.formatSelection(
							contact,
							EditAgreement.agreement.client.id
						);
					},
					formatResult: function (contact) {
						return utils.select2.clientContactsAndRelations.formatResult(
							contact,
							EditAgreement.agreement.client.id
						);
					},
					allowClear: true,
					matcher: function (term, undef, obj) {
						return obj.name.toUpperCase().indexOf(term.toUpperCase()) !== -1;
					}
				},
				relatedClients: {
					allowClear: 1,
					formatSelection: (obj, container, encode) => encode(_.property('name')(obj)),
					formatResult: (obj, container, query, escape) => escape(_.property('name')(obj)),
					data: EditAgreement.relatedClients,
					matcher: function (term, undef, obj) {
						return obj.name.toUpperCase().indexOf(term.toUpperCase()) === 0;
					}
				},
				contactAjax: {
					data: [],
					formatSelection: selectHelper.wrapFormatSelectionLink(
						function (contact) {
							return utils.select2.clientContactsAndRelations.formatSelection(
								contact,
								EditAgreement.agreement.client ? EditAgreement.agreement.client.id : null
							);
						},
						function (contact, $state) {
							$state.go('contact.dashboard', { id: contact.id });
						}
					),
					formatResult: function (contact) {
						if (!contact.id) {
							return '<i class="grey">' + T('default.noContact') + '</i>';
						}
						return utils.select2.clientContactsAndRelations.formatResult(
							contact,
							EditAgreement.agreement.client ? EditAgreement.agreement.client.id : null
						);
					},
					minimumResultsForSearch: 8,
					minimumInputLength: 1,
					allowClear: true,
					matcher: function (term, undef, obj) {
						return obj.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
					},
					ajax: {
						data: function (term) {
							return term;
						},
						transport: function (query) {
							if (query.data && EditAgreement.agreement.client) {
								var clientIds = [EditAgreement.agreement.client.id];
								if (
									EditAgreement.agreement.clientConnection &&
									EditAgreement.agreement.clientConnection.id &&
									EditAgreement.agreement.clientConnection.id > 0
								) {
									clientIds.push(EditAgreement.agreement.clientConnection.id);
								}

								var contactFilter = new RequestBuilder();
								contactFilter.addFilter(
									Contact.attr.name,
									contactFilter.comparisonTypes.Search,
									query.data
								);
								contactFilter.addFilter(
									Contact.attr.active,
									contactFilter.comparisonTypes.Equals,
									true
								);

								const orFilter = contactFilter.orBuilder();
								orFilter.next();
								orFilter.addFilter(
									{ field: 'client.id' },
									contactFilter.comparisonTypes.Equals,
									clientIds
								);
								orFilter.next();
								orFilter.addFilter(
									{ field: 'connectedClients.relatedToClientId' },
									contactFilter.comparisonTypes.Equals,
									clientIds
								);
								orFilter.done();

								return Contact.customer(customerId).find(contactFilter.build()).then(query.success);
							}

							return query.success({
								data: []
							});
						},
						results: function (res) {
							return {
								results: res.data
							};
						}
					}
				}
			};

			EditAgreement.accountChange(true);

			EditAgreement.chart = {
				func: function (/*chart*/) {
					// We expose the real highchart-object
					// Chart = chart;
				},
				options: {
					xAxis: {
						tickWidth: 0,
						labels: {
							formatter: function () {
								return '';
							}
						},
						type: 'datetime',
						units: [
							['month', [1, 3, 6]],
							['year', null]
						],
						plotLines: []
					},
					yAxis: {
						labels: {
							enabled: false
						},
						title: {
							enabled: false
						}
					},
					chart: {
						height: 100,
						animation: Highcharts.svg
					},
					tooltip: {
						borderColor: '#000',
						borderRadius: 5,
						backgroundColor: '#000',
						shadow: false,
						style: {
							padding: '5px',
							backgroundColor: '#000',
							fontSize: '11px',
							color: '#fff'
						},
						snap: 1,
						formatter: function () {
							return 'order';
						}
					},
					plotOptions: {
						series: {
							stacking: ''
						}
					},
					legend: {
						enabled: false
					}
				},
				subtitle: {
					enabled: false
				},
				loading: false,
				credits: {
					enabled: false
				},
				title: {
					text: ''
				},
				series: [
					{
						data: [],
						type: 'column'
					}
				]
			};

			// Use discount or not
			EditAgreement.useDiscount = metadata.params.UseDiscount;

			EditAgreement.hasDocumentTemplates = meta.documentTemplates.data && meta.documentTemplates.data.length;
			EditAgreement.documentTemplates = meta.documentTemplates.data;

			EditAgreement.stageIsLocked = false;

			// check if stage is locked
			if (
				EditAgreement.agreement.id &&
				EditAgreement.agreement.stage &&
				metadata.params.SubscriptionLockedStages &&
				metadata.params.SubscriptionLockedStages.indexOf(EditAgreement.agreement.stage.id) !== -1
			) {
				// Lock agreement if admins can't edit or if they can but user is not admin
				if (
					!metadata.params.SubscriptionAdminCanEditLockedStage ||
					(metadata.params.SubscriptionAdminCanEditLockedStage && !EditAgreement.self.administrator)
				) {
					EditAgreement.editable = false;
					EditAgreement.stageIsLocked = true;
				}
			}
			EditAgreement.setPeriodLength(EditAgreement.agreement.metadata.periodLength);
			EditAgreement.isValidNoticePeriod = true;
			if (EditAgreement.agreement.metadata.noticePeriod === undefined) {
				// defaulting to 0 if not set
				EditAgreement.agreement.metadata.noticePeriod = 0;
			}

			EditAgreement.showCustomPeriod =
				EditAgreement.agreement?.metadata.periodLength &&
				!EditAgreement.periodLengthOptions.includes(EditAgreement.agreement.metadata.periodLength);

			EditAgreement.numbersOfPreviews = 0;

			ScriptService.agreement.init(EditAgreement.agreement);
		};

		// run modifying scripts on order changes
		$scope.$watch(
			'EditAgreement.agreement',
			function () {
				EditAgreement.agreement.orderRow.forEach(function (row) {
					row.custom.forEach(function (field) {
						if (field.datatype === 'Calculation' && FeatureHelper.isAvailable('CALCULATING_FIELDS')) {
							var newValue = window.calculateField.calculate(field.formula, row);
							if (field.value !== newValue) {
								field.value = newValue;
							}
						}
					});
				});

				EditAgreement.agreement.custom.forEach(function (field) {
					if (field.datatype === 'Calculation' && FeatureHelper.isAvailable('CALCULATING_FIELDS')) {
						var newValue = window.calculateField.calculate(field.formula, EditAgreement.agreement);
						if (field.value !== newValue) {
							field.value = newValue;
						}
					}
				});
			},
			true
		);

		// Run init
		init();
	}
]);
