'use strict';

const { default: openModal } = require('App/services/Modal/Modal');

angular.module('upDirectives').directive('upEventEsign', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventEsign.tpl.html?file'),
		require: 'upEventEsign',
		scope: {
			event: '=upEventEsign',
			noIcon: '@'
		},
		controller: [
			'$scope',
			'$upModal',
			function ($scope, $upModal) {
				$scope.customerId = Tools.AppService.getCustomerId();

				$scope.openEsign = function () {
					var options = {
						id: $scope.event.entityId
					};

					if (Tools.FeatureHelper.hasSoftDeployAccess('CONFIRM_ESIGN_REACT')) {
						openModal('ConfirmEsignModal', options);
					} else {
						$upModal.open('confirmEsign', options);
					}
				};

				$scope.openOpportunity = () => $upModal.open('editOrder', { id: $scope.event.esign.opportunity.id });
			}
		]
	};
});
