import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Bem from '@upsales/components/Utils/bemClass';
import {
	Card,
	CardHeader,
	Table,
	TableHeader,
	TableRow,
	TableColumn,
	Toggle,
	Button,
	Input,
	Loader,
	Icon,
	AssistChip,
	Flex,
	Block
} from '@upsales/components';
import {
	doSort,
	init,
	setActive,
	startEditBrand,
	stopEditBrand,
	changeEditProp,
	newBrand,
	saveBrand
} from 'Store/reducers/AdminBrandReducer';
import AdminHeader from './AdminHeader';
import RoleSelect from 'Components/RoleSelect';

import './Brand.scss';
import { PrimaryButton } from '@upsales/components/Buttons';

const mapStateToProps = state => ({ ...state.AdminBrand });
const mapDispatchToProps = { doSort, init, setActive, startEditBrand, stopEditBrand, changeEditProp, saveBrand };

class Brands extends React.Component {
	constructor(props) {
		super(props);

		const t = Tools.$translate;

		this.lang = {
			brands: t('default.brands'),
			description: t('admin.brand.description'),
			name: t('default.name'),
			roles: t('default.roles'),
			active: t('default.active'),
			logo: t('admin.logo'),
			newBrand: t('admin.brand.newBrand'),
			save: t('default.save'),
			cancel: t('cancel'),
			excludedRoles: t('admin.brand.excludedRoles'),
			excludedRolesInfo: t('admin.brand.excludedRolesInfo')
		};

		this.props.init();
	}

	componentWillUnmount() {
		if (this.cancelablePromise) {
			this.cancelablePromise.cancel();
		}
	}

	nameChanged = event => {
		this.props.changeEditProp('name', event.target.value);
	};

	activeChanged = value => {
		this.props.changeEditProp('active', value);
	};

	rolesChanged = values => {
		this.props.changeEditProp('roles', _.pluck(values, 'id'));
	};

	renderRow(brand) {
		const { setActive, startEditBrand } = this.props;
		//const AdminbrandListBem = new Bem('AdminBrandList');
		const roles = Tools.AppService.getRoles();

		const mappedRoles = brand.roles.reduce((res, roleId) => {
			const role = _.find(roles, { id: roleId });

			if (role) {
				res.push(role);
			}
			return res;
		}, []);

		return (
			<TableRow inactive={!brand.active} onClick={() => startEditBrand(brand)} key={brand.id}>
				<TableColumn title={brand.name} size="lg" />
				<TableColumn>
					<ReactTemplates.admin.tableTokens items={mappedRoles} />
				</TableColumn>
				<TableColumn onClick={ReactTemplates.TOOLS.stopProp}>
					<Toggle
						color="medium-green"
						size="lg"
						checked={brand.active}
						onChange={value => setActive(value, brand)}
					/>
				</TableColumn>
				<TableColumn />
			</TableRow>
		);
	}

	renderEditRow(brand) {
		const { saving, saveBrand, stopEditBrand } = this.props;
		const AdminbrandListBem = new Bem('AdminBrandList');
		const roles = Tools.AppService.getRoles();
		const hasTreeSelect = Tools.FeatureHelper.hasSoftDeployAccess('TREE_SELECT');
		const lang = this.lang;

		const mappedRoles = brand.roles.reduce((res, roleId) => {
			const role = _.find(roles, { id: roleId });

			if (role) {
				res.push(role);
			}
			return res;
		}, []);

		return (
			<TableRow className={AdminbrandListBem.elem('edit-row').b()} key={`edit-${brand.id}`}>
				<TableColumn>
					<Input value={brand.name} placeholder={lang.name} onChange={this.nameChanged} autofocus />
				</TableColumn>
				<TableColumn>
					{hasTreeSelect ? (
						<RoleSelect roles={roles} selectedRoles={mappedRoles} onChange={this.rolesChanged} />
					) : (
						<ReactTemplates.INPUTS.upRoles
							id="admin-stages-role-input"
							data={roles}
							value={mappedRoles}
							multiple={true}
							onChange={this.rolesChanged}
						/>
					)}
				</TableColumn>
				<TableColumn onClick={ReactTemplates.TOOLS.stopProp}>
					<Toggle color="medium-green" size="lg" checked={brand.active} onChange={this.activeChanged} />
				</TableColumn>
				<TableColumn align="right">
					<PrimaryButton loading={saving} onClick={saveBrand} size="sm" disabled={!brand?.name?.length}>
						{lang.save}
					</PrimaryButton>
					<Button shadow="none" onClick={stopEditBrand} type="link" color="grey">
						{lang.cancel}
					</Button>
				</TableColumn>
			</TableRow>
		);
	}

	renderExcludedRoles = bemClass => {
		const lang = this.lang;
		const users = Tools.AppService.getUsers();
		const roleIdsWithUsers = [...new Set(users.map(u => u.role?.id).filter(role => role !== undefined))];
		const { brands } = this.props;
		const activeRoleIds = new Set(brands.filter(b => b.active).flatMap(b => b.roles));
		const excludedRoleIds = [...roleIdsWithUsers].filter(num => !activeRoleIds.has(num));
		const excludedRoles = Tools.AppService.getRoles().filter(r => excludedRoleIds.includes(r.id));
		if (!excludedRoles.length) return null;

		return (
			<div className={bemClass.elem('excludedRoles').b()}>
				<Block space="prm">
					<p className={bemClass.elem('info')}>{lang.excludedRolesInfo}</p>
				</Block>
				<Flex wrap="wrap">
					<Block space="prm pbm">
						<p className={bemClass.elem('bold').b()}>{lang.excludedRoles}</p>
					</Block>
					{excludedRoles.map(role => (
						<Block key={role.id} space="prm pbm">
							<AssistChip title={role.name} type="alert" />
						</Block>
					))}
				</Flex>
			</div>
		);
	};

	render() {
		const { loading, sort, doSort, brands, edit, startEditBrand } = this.props;
		const lang = this.lang;
		const AdminbrandListBem = new Bem('AdminBrandList');
		const tableRows = brands.map(brand => {
			return edit && edit.id === brand.id ? this.renderEditRow(edit) : this.renderRow(brand);
		});

		if (edit && !edit.id) {
			tableRows.unshift(this.renderEditRow(edit));
		}

		return (
			<div id="admin-root">
				<AdminHeader title={lang.brands} description={lang.description} image="salesboard-card.svg" />
				<div id="admin-content">
					<Card>
						<CardHeader title={lang.brands}>
							{edit ? null : (
								<Button size="sm" shadow="none" onClick={() => startEditBrand(newBrand())}>
									<Icon style={{ marginRight: 10 }} name="plus" />
									{lang.newBrand}
								</Button>
							)}
						</CardHeader>
						{this.renderExcludedRoles(AdminbrandListBem)}
						<Table className={AdminbrandListBem.b()}>
							<TableHeader
								columns={[
									{ title: lang.name, sort: 'name' },
									{ title: lang.roles },
									{ title: lang.active },
									{ title: '' }
								]}
								onSortChange={doSort}
								sorting={sort.field}
								asc={sort.asc}
							/>
							{loading ? (
								<TableRow>
									<TableColumn size="lg" colSpan={4} align="center">
										<Loader size="sm" noU={true} />
									</TableColumn>
								</TableRow>
							) : (
								tableRows
							)}
						</Table>
					</Card>
				</div>
			</div>
		);
	}
}

Brands.propTypes = {
	brands: PropTypes.array,
	edit: PropTypes.object,
	sort: PropTypes.object,
	doSort: PropTypes.func,
	init: PropTypes.func,
	setActive: PropTypes.func,
	startEditBrand: PropTypes.func,
	stopEditBrand: PropTypes.func,
	changeEditProp: PropTypes.func,
	saveBrand: PropTypes.func,
	saving: PropTypes.bool,
	loading: PropTypes.bool
};

export const detached = Brands;
const Component = connect(mapStateToProps, mapDispatchToProps)(Brands);
export default Component;

window.AdminBrands = Component;
