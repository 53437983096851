import BemClass from '@upsales/components/Utils/bemClass';
import { Block, Button, Headline, Icon, Input } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import React, { useEffect, useRef, useState } from 'react';
import LoginError, { LoginErrors } from '../LoginError';
import { DefaultButton } from '@upsales/components/Buttons';
import { loginTwoFA } from 'App/helpers/loginHelper';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';

type Props = {
	token: null | string;
	onBack: () => void;
	rememberMe: boolean;
};

export default function Login2fa({ token, onBack, rememberMe }: Props) {
	const classes = new BemClass('Login2fa');
	const { t } = useTranslation();
	const [error, setError] = useState<null | LoginErrors>(null);
	const [code, setCode] = useState('');
	const [loginLoading, setLoginLoading] = useState(false);
	const loginReq = useRef<null | CancelablePromise<Awaited<ReturnType<typeof loginTwoFA>>>>(null);
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		return () => {
			loginReq.current?.cancel();
		};
	}, []);

	useEffect(() => {
		if (token) {
			// Focus input when token is set
			const focusTimer = setTimeout(() => inputRef.current?.focus(), 300);
			return () => {
				clearTimeout(focusTimer);
			};
		}
	}, [token]);

	const doLogin = () => {
		if (!token || !code) {
			return;
		}

		setLoginLoading(true);
		setError(null);

		loginReq.current = makeCancelable(loginTwoFA(token, code, rememberMe));

		loginReq.current.promise.catch(err => {
			setLoginLoading(false);
			setError(err);
		});
	};

	return (
		<div className={classes.b()}>
			<form
				onSubmit={e => {
					e.preventDefault();
					doLogin();
				}}
			>
				<Block space="mbxl">
					<Headline size="md" color="super-light-green">
						{t('default.loginToUpsales')}
					</Headline>
				</Block>
				<Input
					size="lg"
					type="text"
					placeholder={t('default.smskey')}
					value={code}
					onChange={e => setCode(e.target.value.replace(/[^0-9]/g, ''))}
					inputRef={inputRef}
				/>
				{error ? <LoginError error={error} onClear={() => setError(null)} /> : null}
				<Block space="mtxl">
					<DefaultButton submit block size="xl" loading={loginLoading} disabled={!code}>
						{t('default.login')}
					</DefaultButton>
				</Block>
				<Block space="mtxl">
					<Button
						type="link"
						color="super-light-green"
						block
						size="lg"
						disabled={loginLoading}
						onClick={() => onBack()}
					>
						<Icon name="angle-left" space="mrs" />
						{`${t('login.loginAgain')}`}
					</Button>
				</Block>
			</form>
		</div>
	);
}
