'use strict';

import logError from 'App/babel/helpers/logError';
import openModal from 'App/services/Modal';
import { openIntegrationModal } from 'Components/IntegrationModal/IntegrationModal';

angular.module('domain.esign').controller('ConfirmEsign', [
	'$scope',
	'AppService',
	'$modalParams',
	'Esign',
	'$upModal',
	'$state',
	'$timeout',
	function ($scope, AppService, $modalParams, Esign, $upModal, $state, $timeout) {
		var ConfirmEsign = this;
		var meta = $modalParams.meta;

		// Listen for esign updated
		$scope.$on('esign.updated', function (e, esign) {
			if (esign.id === ConfirmEsign.esign.id) {
				// Extend dem peoples
				angular.forEach(ConfirmEsign.esign.involved, function (person) {
					var updatedPerson = _.find(esign.involved, { id: person.id });

					angular.extend(person, updatedPerson);
				});

				setUnsignedUsers();
			}
		});

		ConfirmEsign.sending = false;

		ConfirmEsign.confirm = function () {
			ConfirmEsign.sending = true;

			var esign = angular.copy(ConfirmEsign.esign);
			esign.state = Esign.stateEnum.PENDING;
			esign.sendToScrive = true;

			Esign.save(esign, { skipNotification: true })
				.then(function (res) {
					$timeout(function () {
						$scope.resolve();

						$upModal.open('confirmEsign', {
							esign: res.data
						});

						ConfirmEsign.sending = false;
					}, 1000);
				})
				.catch(function () {
					ConfirmEsign.sending = false;
				});
		};

		const onSendReminder = () => {
			ConfirmEsign.sendingReminder = true;
			Esign.sendReminder(ConfirmEsign.esign.id)
				.then(function () {
					$scope.reject();
				})
				.catch(function () {
					ConfirmEsign.sendingReminder = true;
				});
		};

		ConfirmEsign.sendReminder = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'esign.sendReminder',
					body: 'esign.sendReminderConfirm',
					confirmButtonText: 'esign.sendReminder',
					headerIcon: 'envelope',
					alertType: 'info',
					onClose: async confirmed => {
						if (confirmed) {
							onSendReminder();
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('infoConfirm', {
					title: 'esign.sendReminder',
					body: 'esign.sendReminderConfirm',
					resolveTrue: 'esign.sendReminder',
					no: 'default.abort',
					icon: 'fa-envelope'
				})
				.then(function () {
					onSendReminder();
				});
		};

		ConfirmEsign.cancel = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'esign.revokeEsign',
					body: 'esign.revokeEsignConfirm',
					confirmButtonText: 'esign.revokeEsign',
					headerIcon: 'warning',
					onClose: confirmed => {
						if (confirmed) {
							Esign.cancel(ConfirmEsign.esign.id)
								.then(function () {
									$scope.reject();
								})
								.catch(function (err) {
									logError(err, 'Esign.cancel rejected');
								});
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: 'esign.revokeEsign',
					body: 'esign.revokeEsignConfirm',
					resolveTrue: 'esign.revokeEsign',
					no: 'default.abort',
					icon: 'fa-warning'
				})
				.then(function () {
					Esign.cancel(ConfirmEsign.esign.id)
						.then(function () {
							$scope.reject();
						})
						.catch(function (err) {
							logError(err, 'Esign.cancel rejected');
						});
				});
		};

		ConfirmEsign.goToList = function () {
			$state.go('esign');
			$scope.reject();
		};

		ConfirmEsign.copy = function () {
			var esign = ConfirmEsign.esign;
			var data = {
				client: esign.client,
				user: esign.user,
				file: esign.file,
				title: esign.title,
				involved: _.map(esign.involved, function (p) {
					return {
						fstname: p.fstname,
						sndname: p.sndname,
						email: p.email,
						userId: p.userId,
						contactId: p.contactId,
						deliveryMethod: p.deliveryMethod,
						authMethod: p.authMethod
					};
				}),
				opportunity: esign.opportunity,
				orderStage: esign.orderStage
			};

			$upModal.open('editEsign', {
				esign: data
			});
			$scope.reject();
		};
		ConfirmEsign.openHtmlFrameNew = function () {
			var esign = angular.copy(ConfirmEsign.esign);
			var opts = {
				name: 'esignDocument',
				type: 'editEsign',
				integrationId: ConfirmEsign.esign.integrationId,
				objectId: esign.id || null,
				fullscreen: true
			};
			openIntegrationModal(opts);
			$scope.reject();
		};

		ConfirmEsign.openHtmlFrame = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('ESIGN_VIEW_NEW')) {
				ConfirmEsign.openHtmlFrameNew();
			} else {
				var esign = angular.copy(ConfirmEsign.esign);
				esign.sendToScrive = true;
				Esign.save(esign)
					.then(function (res) {
						var opts = {
							name: 'esignDocument',
							type: 'editEsign',
							integrationId: ConfirmEsign.esign.integrationId,
							objectId: res.data.id || null,
							fullscreen: true
						};
						openIntegrationModal(opts);
						$scope.reject();
					})
					.catch(function (err) {
						logError(err, 'Failed to save esign');
					});
			}
		};

		function setUnsignedUsers() {
			ConfirmEsign.unsignedUsers = _.filter(ConfirmEsign.esign.involved, function (p) {
				if (!p.sign && p.userId && !p.signedByCounterpart) {
					return true;
				}
				return false;
			}).length;
		}

		var init = function () {
			ConfirmEsign.esign = meta.esign.data;
			ConfirmEsign.view = ConfirmEsign.esign.state !== Esign.stateEnum.DRAFT;
			ConfirmEsign.documentType = meta.settings.documentType;

			ConfirmEsign.showListBtn = $state.current.name !== 'esign';

			if (ConfirmEsign.esign.file || (ConfirmEsign.esign.integrationId && ConfirmEsign.esign.title)) {
				ConfirmEsign.downloadURL = Esign.getDownloadUrl(ConfirmEsign.esign);
			}

			if (ConfirmEsign.view) {
				var future = moment().add(1, 'year').toDate();
				ConfirmEsign.esign.involved = _.chain(ConfirmEsign.esign.involved)
					.sortBy(function (p) {
						if (!p.sign) {
							return future;
						}
						return p.sign;
					})
					.sortBy(function (person) {
						return person.userId ? 0 : 1;
					})
					.map(function (p) {
						if (!p.sign && ConfirmEsign.esign.state === 30) {
							p.signedByCounterpart = true;
						}
						return p;
					})
					.value();

				setUnsignedUsers();
			}

			ConfirmEsign.signCount = _.filter(ConfirmEsign.esign.involved, function (p) {
				return p.sign !== null || p.signedByCounterpart;
			}).length;
		};

		init();
	}
]);
