export type StoredFlashActivityInLocalStorage = {
	minimized: boolean;
	closedActivitiesCurrentBlixt: number;
	bookedAppointmentsCurrentBlixt: number;
	flashTime: number;
	flashTimeDuration: number;
	appointmentListWidgetRoles: unknown;
	appointmentListWidgetTypes: unknown;
	activityOutcomesListWidgetRoles: unknown;
	activityOutcomesListWidgetTypes: unknown;
	activityListWidgetRoles: unknown;
	activityListWidgetTypes: unknown;
	data: {
		currentId: number;
		filter: unknown;
	};
};

type StoredFlashActivities = {
	[customerId: string]: {
		[customerUserId: string]: StoredFlashActivityInLocalStorage;
	};
};

export const getMinimizedFlashActivities = (customerId?: number, currentUserId?: number) => {
	if (!customerId || !currentUserId) {
		return null;
	}
	try {
		const storedData = localStorage.getItem('flash');
		if (storedData !== null) {
			const parsedData = (JSON.parse(storedData) as StoredFlashActivities)[customerId][currentUserId];
			return parsedData.minimized ? parsedData : null;
		}
	} catch (err) {
		return null;
	}
};
