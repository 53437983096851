import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import { ButtonBox, Flex, Loader, OutsideClick, Text } from '@upsales/components';
import React, { useEffect, useMemo, useState, useRef } from 'react';
import BemClass from '@upsales/components/Utils/bemClass';
import { HTML5Backend } from 'react-dnd-html5-backend';
import FoldersResource from 'Resources/FileFolder';
import T from 'Components/Helpers/translate';
import openModal from 'App/services/Modal';
import FolderModal from './FolderModal';
import logError from 'Helpers/logError';
import { DndProvider } from 'react-dnd';
import GridView from './GridView';
import ListView from './ListView';
import Header from './Header';

import './AccountFiles.scss';

export type FolderType = {
	id: number;
	name: string;
	folderId: number | null;
};

export type FolderTreeType = {
	id: number;
	name: string;
};

export type FileType = {
	id: number;
	name: string;
	size: number;
	extension: string;
	date: string;
	userId: number;
	folderId: number | null;
	relatedId?: number;
	related?: string;
};

const AccountFiles = ({ clientId }: { clientId: number }) => {
	const classes = new BemClass('AccountFiles');

	const promises = useRef<CancelablePromise[]>([]);

	const [allFolders, setAllFolders] = useState<FolderType[]>([]);
	const [allFiles, setAllFiles] = useState<FileType[]>([]);
	const [folderTree, setFolderTree] = useState<FolderTreeType[]>([]);

	const [listMode, setListMode] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
	const [showAddFolderModal, setShowAddFolderModal] = useState(false);
	const [searchString, setSearchString] = useState<string | null>(null);

	const currentFolderId = folderTree[folderTree.length - 1]?.id ?? null;

	const foldersToShow = useMemo(() => {
		if (searchString !== null) {
			return allFolders.filter(folder => folder.name.toLowerCase().includes(searchString));
		}
		return allFolders.filter(folder => folder.folderId === currentFolderId);
	}, [currentFolderId, allFolders, searchString]);

	const filesToShow = useMemo(() => {
		if (searchString !== null) {
			return allFiles.filter(file => file.name.toLowerCase().includes(searchString));
		}
		return allFiles.filter(file => file.folderId === currentFolderId);
	}, [currentFolderId, allFiles, searchString]);

	const fetchAllFilesAndFolders = async () => {
		setIsLoading(true);

		const promise = makeCancelable(FoldersResource.getFilesAndFolders(clientId));
		promises.current.push(promise);
		promise.promise
			.then(res => {
				setAllFolders(res.folders);
				setAllFiles(res.files);
			})
			.catch(e => logError(e, 'Could not fetch files and folders'))
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		fetchAllFilesAndFolders();
		if (localStorage.getItem('boxViewDocuments') === '1') {
			setListMode(false);
		}

		return () => {
			promises.current.forEach(p => p.cancel());
		};
	}, []);

	// This might not be that good?
	useEffect(() => {
		const listener = Tools.$rootScope.$on('file.uploaded', (e, file) => {
			file.name = file.filename;
			setAllFiles(currentFiles => [...currentFiles, { ...file, folderId: currentFolderId }]);
		});
		return () => listener();
	}, [currentFolderId]);

	const buildFolderTree = (folderId: number) => {
		const tree = [];
		let id: number | null = folderId;
		while (id) {
			const parentFolder = allFolders.find(folder => folder.id === id);
			if (parentFolder) {
				tree.unshift({ id: parentFolder.id, name: parentFolder.name });
				id = parentFolder?.folderId;
			} else {
				break;
			}
		}
		setFolderTree(tree);
		setSearchString(null);
	};

	const openFolder = async (folderId: number, name: string) => {
		if (searchString !== null && folderId) {
			buildFolderTree(folderId);
			return;
		}
		setFolderTree(currentTree => [...currentTree, { id: folderId, name }]);
	};

	const changeFolderFromTree = async (folderId?: number | null) => {
		if (folderId === currentFolderId) return;

		setFolderTree(currentTree => {
			const indexOfFolder = currentTree.findIndex(folder => folder.id === folderId);
			const newfolderTree = [...currentTree];
			newfolderTree.length = indexOfFolder + 1;
			return newfolderTree;
		});
	};

	const createFolder = (name: string) => {
		const promise = makeCancelable(FoldersResource.save({ name, clientId, folderId: currentFolderId }));
		promises.current.push(promise);
		promise.promise
			.then(res => {
				setAllFolders(currentFolders => [...currentFolders, { ...res.data, folderId: currentFolderId }]);
			})
			.catch(e => logError(e, 'Could not create folder'));
	};

	const changeFolderFolder = (id: number, folderId: number | null) => {
		const promise = makeCancelable(FoldersResource.save({ clientId, id, folderId }));
		promises.current.push(promise);
		promise.promise
			.then(() => {
				const newFolders = [...allFolders].map(folder => {
					if (folder.id === id) {
						folder.folderId = folderId;
					}
					return folder;
				});
				setAllFolders(newFolders);
			})
			.catch(e => logError(e, 'Could not move folder'));
	};

	const changeFileFolder = (id: number, folderId: number | null) => {
		const promise = makeCancelable(FoldersResource.changeFileFolder(id, folderId));
		promises.current.push(promise);

		promise.promise
			.then(() => {
				const newFiles = [...allFiles].map(file => {
					if (file.id === id) {
						file.folderId = folderId;
					}
					return file;
				});
				setAllFiles(newFiles);
			})
			.catch(e => logError(e, 'Could not move file'));
	};

	const addFile = () => {
		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_UPLOAD_FILE_MODAL')) {
			return openModal('UploadFileModal', {
				accountId: clientId,
				folderId: currentFolderId,
				closeOnUpload: true
			});
		}
		Tools.$upModal
			.open('uploadFile', {
				accountId: clientId,
				folderId: currentFolderId,
				closeOnUpload: true
			})
			.catch(e => logError(e, 'Could not upload file'));
	};

	const deleteFolder = (id: number) => {
		const promise = makeCancelable(FoldersResource.delete(id));
		promises.current.push(promise);
		promise.promise
			.then(() =>
				setAllFolders(currentFolders => {
					const newFolders = [...currentFolders];
					const index = currentFolders.findIndex(folder => folder.id === id);
					newFolders.splice(index, 1);
					return newFolders;
				})
			)
			.catch(err => {
				if (err.response.status === 400) {
					Tools.NotificationService.addNotification({
						style: Tools.NotificationService.style.ERROR,
						icon: 'times',
						title: 'default.error',
						body: 'foler.folderNotEmpty'
					});
				} else {
					logError(err, 'Could not delete folder');
				}
			});
	};

	const deleteFile = (id: number) => {
		const promise = makeCancelable(Tools.File.delete({ id }, { noConfirm: true }));
		promises.current.push(promise);
		promise.promise
			.then(res => {
				if (!res) {
					return;
				}
				setAllFiles(currentFiles => {
					const newFiles = [...currentFiles];
					const index = currentFiles.findIndex(file => file.id === id);
					newFiles.splice(index, 1);
					return newFiles;
				});
			})
			.catch(e => {
				logError(e, 'Could not delete file');
			});
	};

	const changeNameOfFolder = (id: number, name: string) => {
		const promise = makeCancelable(FoldersResource.save({ name, clientId, id }));
		promises.current.push(promise);
		promise.promise
			.then(() => {
				setFolderTree(currentFolderTree =>
					currentFolderTree.map(folder => {
						if (folder.id === id) {
							folder.name = name;
						}
						return folder;
					})
				);
				setAllFolders(currentFolders =>
					currentFolders.map(folder => {
						if (folder.id === id) {
							folder.name = name;
						}
						return folder;
					})
				);
			})
			.catch(e => logError(e, 'Could not change name'));
	};

	const content = () => {
		let element;
		if (!filesToShow.length && !foldersToShow.length) {
			if (searchString) {
				element = <Text size="lg">{T('noResult.generic')}</Text>;
			} else if (showAddFolderModal) {
				element = (
					<OutsideClick outsideClick={() => setShowAddFolderModal(false)} targetClass={'FolderModal'}>
						<div>
							<FolderModal
								animate
								noDropDown
								onClick={name => {
									createFolder(name);
									setShowAddFolderModal(false);
								}}
							/>
						</div>
					</OutsideClick>
				);
			} else {
				element = (
					<>
						<ButtonBox selectable={false} onClick={addFile} icon="file" title={T('file.addFile')} />
						<ButtonBox
							selectable={false}
							onClick={() => setShowAddFolderModal(true)}
							icon="folder"
							title={T('file.addFolder')}
						/>
					</>
				);
			}

			return (
				<Flex className={classes.elem('center').b()} gap="u10" justifyContent="center" alignItems="center">
					{element}
				</Flex>
			);
		}

		const filesViewProps = {
			folders: foldersToShow,
			files: filesToShow,
			openFolder,
			deleteFolder,
			deleteFile,
			changeFolderFolder,
			changeFileFolder
		};

		if (listMode) {
			return <ListView {...filesViewProps} />;
		}
		return <GridView {...filesViewProps} />;
	};

	if (isLoading) {
		return (
			<div className={classes.elem('center').b()}>
				<Loader size="xl" />
			</div>
		);
	}
	return (
		<div className={classes.b()}>
			<DndProvider backend={HTML5Backend}>
				<Header
					folders={folderTree}
					addFolder={createFolder}
					addFile={addFile}
					changeFolder={changeFolderFromTree}
					changeNameOfFolder={changeNameOfFolder}
					setSearchString={setSearchString}
					searchString={searchString}
					setListMode={setListMode}
					listMode={listMode}
					changeFolderFolder={changeFolderFolder}
					changeFileFolder={changeFileFolder}
				/>
				{content()}
			</DndProvider>
		</div>
	);
};

export default AccountFiles;
