'use strict';
import { openDrawer } from 'Services/Drawer';
import logError from 'App/babel/helpers/logError';
import openModal from 'App/services/Modal/Modal';

angular.module('domain.scoreboard').controller('ScoreDrilldown', [
	'$scope',
	'AppService',
	'$filter',
	'$upModal',
	'$drilldownParams',
	function ($scope, AppService, $filter, $upModal, $drilldownParams) {
		var translate = $filter('translate');
		var customerId = AppService.getCustomerId();
		var meta = AppService.getMetadata();
		$scope.customerId = customerId;

		var openVisit = function (visitId) {
			$upModal.open('viewVisit', { id: visitId });
		};

		var openSubmit = function (submitId) {
			$upModal.open('viewSubmit', { id: submitId });
		};

		$scope.openMail = function (mailId) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
				openDrawer('SentMail', { mail: { id: mailId } });
			} else {
				$upModal.open('sentMail', { customerId: customerId, id: mailId });
			}
		};

		var openMarketingCustom = function (appEvent) {
			$upModal.open('appWidget', {
				obj: appEvent,
				integrationId: appEvent.marketingCustom.integrationId,
				title: translate('default.marketEvent') + ' ' + (appEvent.subType || ''),
				name: 'marketingCustom'
			});
		};

		$scope.resetScore = function () {
			$upModal
				.open('resetScore', {
					account: $scope.account,
					userId: meta.user.id,
					customerId: customerId
				})
				.then(function () {
					// Remove row from board laters
					$scope.account.score = 0;
				})
				.catch(err => {
					logError(err, 'Could not reset score');
				});
		};

		$scope.mergeAccounts = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_MERGE_CLIENTS')) {
				openModal('MergeClients', {
					id: $scope.account.id,
					customerId: customerId,
					meta: { account: { data: $scope.account } }
				});
			} else {
				$upModal.open('mergeClients', { customerId: customerId, id: $scope.account.id });
			}
		};

		$scope.openDetails = function (event) {
			switch (event.entityType) {
				case 'Form':
				case 'Submit':
					openSubmit(event.entityId);
					break;
				case 'MarketingCustom':
					openMarketingCustom(event);
					break;
				case 'Visit':
					openVisit(event.entityId);
					break;
			}
		};

		$scope.openOpportunity = function (event, isOrder) {
			var options = {
				customerId: customerId,
				type: isOrder ? 'order' : 'opportunity',
				id: event.entityId
			};

			$upModal.open('editOrder', options);
		};

		$scope.openActivity = function (event) {
			var options = {
				id: event.entityId
			};

			$upModal.open('editActivity', options);
		};

		$scope.getMailCampaignTooltip = function (mails) {
			return _.reduce(
				mails,
				function (res, mail, index) {
					if (mail.contacts && mail.contacts.length && index > 0) {
						res.push(mail.contacts[0].name);
					}
					return res;
				},
				[]
			).join('\n');
		};

		$scope.getNumberOfClicks = function (mails) {
			return _.reduce(
				mails,
				function (res, mail) {
					return res + (mail.lastClickDate ? 1 : 0);
				},
				0
			);
		};

		$scope.getNumberOfOpens = function (mails) {
			return _.reduce(
				mails,
				function (res, mail) {
					return res + (mail.lastReadDate ? 1 : 0);
				},
				0
			);
		};

		$scope.account = $drilldownParams.account;

		$scope.activityWidget = { accountId: $scope.account.id };
		$scope.leadScoreWidget = { account: $scope.account, score: $scope.account.score };

		$scope.multipleAccountManagers = meta.params.teamAccountManager;
	}
]);
