import JourneyStepDisplay from 'Components/JourneyStepDisplay';
import DisqualifyDropDown from 'Components/JourneyStepDisplay/DisqualifyDropDown';
import { Icon, Button } from '@upsales/components';
import BuyFromDataSourceInline from 'Components/Modals/BuyFromDataSourceInline';
import store from 'Store';
import { Provider } from 'react-redux';
import GroupTreeColumn from 'Components/Prospecting/ProspectingGroupTree';
import JourneyStepIcon from 'Components/JourneyIcon/JourneyStepIcon';
import Client from 'App/resources/Client';
import logError from 'App/babel/helpers/logError';
import { openAssignModal } from 'Components/AssignModal/AssignModal';

ReactTemplates.ROWSTATICS.visitorRow = function (tools) {
	var statics = {};
	statics.at = tools.$translate('default.at');
	statics.undidentifiedVisit = tools.$translate('default.undidentifiedVisit');
	statics.undidentifiedVisitDescription = tools.$translate('default.undidentifiedVisitDescription');
	statics.add = tools.$translate('default.add');
	statics.CreateAccount = tools.$translate('scoreboard.CreateAccount');
	statics.noData = tools.$translate('default.noData');
	statics.noName = tools.$translate('default.noName');
	statics.unknownSource = tools.$translate('leadSource.unknownSource');
	statics.upsalesAds = tools.$translate('leadSource.upsalesads');
	statics.upsalesMail = tools.$translate('leadSource.upsalesmail');
	statics.assign = tools.$translate('assign.assign');

	return statics;
};

ReactTemplates.ROW.visitorRow = window.ReactCreateClass({
	displayName: 'visitorRow',
	UNSAFE_componentWillUpdate: function () {},
	click: function (e) {
		this.props.tools.opts.clickedEntry(this.props.item, e);
	},
	findBisnodeInt: function (array) {
		return _.find(array, function (item) {
			return item.alias === 'BISNODE_INTERNATIONAL';
		});
	},
	bisnodeInternationalActive: function () {
		var object = { bool: false };
		var availableIntegrations = Tools.AppService.getMetadata().integrations;

		if (
			availableIntegrations.inits &&
			availableIntegrations.inits.dataSource &&
			availableIntegrations.inits.dataSource.length
		) {
			var obj = this.findBisnodeInt(availableIntegrations.inits.dataSource);

			if (obj) {
				object.bool = true;
			}

			object.object = obj;
		}

		return object;
	},
	createAccount: function (e) {
		var self = this;
		self.setState({ loading: true });
		self.props.tools.opts
			.createAccount(self.props.item, e)
			.then(function () {
				self.setState({ loading: false });
			})
			.catch(() => self.setState({ loading: false }));
	},
	getInitialState: function () {
		return { loading: false };
	},
	openEmail: function (mailId, event) {
		event.preventDefault();
		event.stopPropagation();

		this.props.tools.$upModal.open('sentMail', { id: mailId });
	},
	openAdCampaign: function (mailCampaignId, event) {
		event.preventDefault();
		event.stopPropagation();

		this.props.tools.$state.go('viewAd.dashboard', { id: mailCampaignId });
	},
	openProcessedByModal: function (e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		openAssignModal({ client: this.props.item.client });
	},
	openActivity: function (e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		var $upModal = this.props.tools.$upModal;
		var client = this.props.item.client;

		$upModal.open('editActivity', { id: client.processedBy.id });
	},
	unassign: function (e) {
		this.props.tools.opts.unassign(this.props.item, e);
	},
	disqualifyAccount: function (e) {
		var self = this;
		this.setState({ loading: true });
		this.props.tools.opts
			.disqualify(this.props.item, e)
			.then(function () {
				self.setState({ loading: false });
			})
			.catch(() => self.setState({ loading: false }));
	},
	openAppointment: function (e) {
		e.stopPropagation();
		e.nativeEvent.stopImmediatePropagation();

		var $upModal = this.props.tools.$upModal;
		var customerId = this.props.tools.AppService.getCustomerId();
		var client = this.props.item.client;

		$upModal.open('editAppointment', { customerId: customerId, id: client.processedBy.id });
	},
	getCountryNameFromCountryCode: function (code) {
		var country = Tools.CountryCodes.getCountry(code);
		return country ? Tools.$translate(country.lang) : code;
	},
	render: function () {
		var self = this;
		var item = this.props.item;
		var tools = this.props.tools;
		var options = tools.opts;
		var statics = this.props.statics;
		var customerId = tools.AppService.getCustomerId();
		var attrs = this.props.attrs;
		var currencyRange = tools.$filter('currencyRange');
		var style, icon;

		var getTime = function (visit) {
			var seconds = moment(visit.endDate).diff(moment(visit.startDate), 'seconds');
			var duration = moment().startOf('day').add('s', seconds);
			var format = '';

			if (!duration.hour() && !duration.minute() && !duration.second()) {
				return '-';
			}

			if (duration.hour() > 0) {
				format += 'H[h] ';
			}

			if (duration.minute() > 0) {
				format += 'm[m] ';
			}

			format += 's[s]';

			return duration.format(format);
		};

		var getColumn = function (column, item) {
			var address;
			var key = 'column-' + column;

			switch (column) {
				case 'country':
					return (
						<td key={key}>
							<i
								style={{ marginRight: 5 }}
								className={'flag-icon flag-icon-' + (item.country || '').toLowerCase()}
							/>
							{self.getCountryNameFromCountryCode(item.country)}
						</td>
					);
				case 'startDate':
					return (
						<td key={key} className="column-startDate">
							{ReactTemplates.TOOLS.dateCalendar(item.startDate, 'L')}{' '}
							{moment(item.startDate).format('HH:mm')}
						</td>
					);
				case 'account':
				case 'client':
					var contact = null;
					var client = null;

					if (item.contact) {
						contact = (
							<span>
								{ReactTemplates.TOOLS.contactsText(customerId, [item.contact], tools)}
								<span> {statics.at.toLowerCase()} </span>
							</span>
						);
					}
					if (item.client && !item.client.isExternal && item.client.name) {
						client = (
							<ReactTemplates.TOOLS.accountHref
								customerId={customerId}
								id={item.client.id}
								text={item.client.name}
								tools={tools}
							/>
						);
					} else if (!item.client || !item.client.name) {
						client = (
							<span className="grey">
								<i>{statics.undidentifiedVisit} </i>
								{ReactTemplates.TOOLS.withTooltip(
									<i className="fa fa-info-circle" />,
									statics.undidentifiedVisitDescription,
									{ placement: 'top' }
								)}
							</span>
						);
					} else if (item.client.isExternal) {
						address = item.client.mailAddress;
						client = (
							<span
								onClick={function (event) {
									event.preventDefault();
									event.stopPropagation();
								}}
							>
								{item.client.name}
								{self.state.loading ? (
									<b style={{ marginLeft: '5px' }} className="fa fa-refresh fa-spin" />
								) : (
									<Provider store={store}>
										<BuyFromDataSourceInline
											onConfirm={clientId => {
												if (clientId) {
													Client.save({
														id: clientId,
														sourceType: 'visit',
														sourceId: item.id
													}).catch(err => logError(err, 'Could not save client'));
												}
											}}
											account={item.client}
											hasDataSource={self.bisnodeInternationalActive().bool}
											dataSourceId={
												self.bisnodeInternationalActive().object &&
												self.bisnodeInternationalActive().object.id
											}
										/>
									</Provider>
								)}
							</span>
						);
					}
					return (
						<td key={key}>
							{contact}
							{client}
						</td>
					);

				case 'location':
					if (!item.client) {
						return ReactTemplates.TOOLS.emptyTD('', key);
					}

					address = item.client.mailAddress;

					if (!address.city && !address.country) {
						return ReactTemplates.TOOLS.emptyTD('', key);
					}
					var txt = '';
					style = { width: '30px', textAlign: 'center' };
					icon = <b className="icon fa fa-map-marker" style={style} />;

					if (address.city?.length) {
						txt = address.city;
					}

					if (!txt && address.state?.length) {
						txt = address.state;
					}

					if (address.country) {
						icon = (
							<b className={'icon flag-icon flag-icon-' + address.country.toLowerCase()} style={style} />
						);
					}

					return (
						<td key={key}>
							{icon}
							{txt}
						</td>
					);

				case 'sales':
					return (
						<td key={key}>
							{item.client && item.client.turnover ? (
								<span>{currencyRange(item.client.turnover * 1000, item.client.currency || '')}</span>
							) : (
								<i className="grey">{statics.noData}</i>
							)}
						</td>
					);

				case 'groupStructure':
					return (
						<td key={key}>
							<GroupTreeColumn
								prospectingId={item.client.prospectingId}
								country={item.client.country}
								existingClient={item.client}
							></GroupTreeColumn>
						</td>
					);

				case 'status':
					return (
						<td key={key}>
							{item.client ? (
								<ReactTemplates.TOOLS.leadStatus
									theKey={item.id}
									tools={tools}
									activity={item.client.hasActivity}
									activityOld={item.client.hadActivity}
									appointment={item.client.hasAppointment}
									appointmentOld={item.client.hadAppointment}
									opportunity={item.client.hasOpportunity}
									opportunityOld={item.client.hadOpportunity}
									order={item.client.hasOrder}
									orderOld={item.client.hadOrder}
								/>
							) : null}
						</td>
					);

				case 'time':
					return <td key={key}>{getTime(item)}</td>;

				case 'score':
					return (
						<td key={key} className="no-overflow">
							<b>{item.score + 'p'}</b>
						</td>
					);

				case 'pages':
					return <td key={key}>{item.pages.length}</td>;

				case 'assigned':
					return (
						<ReactTemplates.processedByColumn
							disabled={!item.client || item.client.isExternal || !item.client.userEditable}
							client={item.client}
							item={item}
							tools={tools}
							options={options}
							theKey={key}
						/>
					);

				case 'leadSource':
					return (
						<td key={key} className="column-leadSource">
							{window.LeadSourceColumn({ visit: item })}
						</td>
					);

				case 'utmCampaign':
				case 'utmContent':
				case 'utmMedium':
				case 'utmSource':
				case 'utmTerm': {
					const config = attrs[column];
					const value = _.get(item, config.field, '');

					return <td key={key}>{value}</td>;
				}

				case 'journeyStep':
				case 'clientJourneyStep':
					var clientJourneyStep = item.client ? item.client.journeyStep : undefined;
					return (
						<td key={key} className="account-contact-col">
							<JourneyStepIcon id={clientJourneyStep} />
						</td>
					);
				case 'contactJourneyStep':
					var contactJourneyStep = item.contact ? item.contact.journeyStep : undefined;
					return (
						<td key={key} className="account-contact-col">
							<JourneyStepIcon id={contactJourneyStep} />
						</td>
					);

				default:
					return ReactTemplates.TOOLS.genericTD(tools, attrs, column, item, key);
			}
		};

		var tds = this.props.columns.map(function (column) {
			return getColumn(column, item);
		});

		var chk = <ReactTemplates.TOOLS.checkboxTD tools={tools} item={item} />;

		if (tools.opts.noCheck) {
			chk = null;
		}

		let dqlLeadCol;
		if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.JOURNEY_STATUS)) {
			dqlLeadCol =
				item.client &&
				JourneyStepDisplay.statusDisqualifiable(item.client.journeyStep) &&
				!item.client.isExternal ? (
					<DisqualifyDropDown
						id={item.clientId}
						className="DisqualifyDropDown"
						onDisqualify={this.disqualifyAccount}
						loading={self.state.loading}
						buttonText={tools.$translate('default.disqualify')}
						tooltip={tools.$translate('default.disqualifyCompany')}
					/>
				) : null;
		} else {
			dqlLeadCol = ReactTemplates.TOOLS.withTooltip(
				<Button onClick={this.unassign} type="link">
					<Icon name="thumbs-down" />
				</Button>,
				tools.$translate('default.setPointsToZero')
			);
		}

		return (
			<tr key={'item-' + item.id} onClick={this.props.noClickEvt ? null : this.click} ref="row">
				{chk}
				{tds}
				<td className="column-tools">{dqlLeadCol}</td>
			</tr>
		);
	}
});
