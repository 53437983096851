'use strict';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';

angular.module('upDirectives').directive('mailTo', [
	'$parse',
	'$upModal',
	'$stateParams',
	'AppService',
	function ($parse, $upModal, $stateParams, AppService) {
		return {
			restrict: 'A',
			link: function (scope, element, attrs) {
				var opts = $parse(attrs.mailTo)(scope);
				// eslint-disable-next-line promise/catch-or-return
				AppService.loadedPromise.then(function () {
					var meta = AppService.getMetadata();
					const hasMailIntegration = !!meta?.integrations.inits.mail?.length;

					// if user can use mail-function
					if (Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.EMAIL) && hasMailIntegration) {
						opts.customerId = $stateParams.customerId;
						element.on('click', function (e) {
							e.preventDefault();
							const email = e.currentTarget.href.replace('mailto:', '');
							if (email) {
								if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
									openNewMailWithContact(opts.contact);
								} else {
									opts.email = email;
									$upModal.open('sendEmail', opts);
								}
							}
						});
					} else {
						element.attr('target', '_top');
					}
				});
			}
		};
	}
]);
