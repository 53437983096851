/* eslint-disable promise/catch-or-return */
/* eslint-disable no-use-before-define */
import { openEditOptOutModal } from 'Components/Admin/OptOutModal';
import AdminOptOutList from '../components/Admin/AdminOptOutList';

window.AdminOptOutListCtrl = scope => {
	let rootNode;
	let dataStore;
	let allCategories;

	scope.$on('$destroy', () => {
		ReactDOM.unmountComponentAtNode(rootNode);
		rootNode = undefined;
	});

	scope.$on('groupMailCategory.added', (e, added) => {
		allCategories.push(added);
		groupCategories(allCategories);
	});

	scope.$on('groupMailCategory.updated', (e, updated) => {
		const i = _.findIndex(allCategories, { id: updated.id });
		console.log(i);
		if (i !== -1) {
			allCategories[i] = updated;
			groupCategories(allCategories);
		}
	});

	const render = props => {
		if (!rootNode) {
			return;
		}
		ReactDOM.render(React.createElement(AdminOptOutList, props), rootNode);
	};

	const groupCategories = data => {
		const active = [];
		const inactive = [];
		data.forEach(cat => {
			if (cat.active) {
				active.push(cat);
			} else {
				inactive.push(cat);
			}
		});
		dataStore.setStore({ categories: active, inactiveCategories: inactive });
	};

	const getCategories = () => {
		Tools.GroupMailCategory.find({}).then(res => {
			allCategories = res.data;
			groupCategories(allCategories);
		});
	};

	const actions = {
		setSort: (sort, asc) => dataStore.setStore({ sort, asc }),
		setInactiveSort: (inactiveSort, inactiveAsc) => dataStore.setStore({ inactiveSort, inactiveAsc }),
		addOptOut: () => openEditOptOutModal(),
		onEdit: id => openEditOptOutModal({ id })
	};

	const initialStore = {
		categories: [],
		inactiveCategories: [],
		sort: 'title',
		asc: false,
		inactiveSort: 'title',
		inactiveAsc: false
	};

	const init = () => {
		rootNode = document.getElementById('admin-opt-out-root');
		dataStore = new DataStore(render, actions, initialStore);

		getCategories();
	};

	Tools.AppService.loadedPromise.then(init);
};
