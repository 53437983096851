'use strict';
import { easyBookingTracker } from 'App/babel/helpers/Tracker';
import { openFileBrowserModal } from 'Components/FileBrowser';
import openModal from 'App/services/Modal/Modal';
import moment from 'moment';

angular.module('services.editorCk', []).factory('EditorCk', [
	'$translate',
	'$upModal',
	'MailAccount',
	function ($translate, $upModal, MailAccount) {
		var getButton = function (title, icon) {
			var a = angular.element('<a class="cke_button cke_button__image cke_button_off" />');

			a.attr({
				title: $translate.instant(title),
				role: 'button',
				tabindex: '-1',
				hidefocus: true
			});

			if (icon) {
				a.addClass(icon);
				var iconElement = angular.element('<span class="cke_button_icon cke_button__image_icon"></span>');
				iconElement.addClass(icon + '_icon');
				a.append(iconElement);
			} else {
				var text = angular.element(
					'<span class="cke_button_label cke_button__source_label" aria-hidden="false" />'
				);
				text.text($translate.instant(title));
				a.append(text);
			}

			return a;
		};

		var getDropdown = function (title, signatures, editor) {
			var span = angular.element(
				'<span class="cke_combo cke_combo__format cke_combo_off"  style="position: unset;"  />'
			);

			span.attr({
				role: 'presentation'
			});

			var innerSpan = angular.element('<span class="cke_combo_label" />');
			innerSpan.text($translate.instant(title));

			span.append(innerSpan);

			var a = angular.element('<a class="cke_combo_button" />');
			a.attr({
				title: $translate.instant(title),
				role: 'button',
				tabindex: '-1',
				hidefocus: true,
				style: 'z-index: 5; position: relative;'
			});

			a.append(
				angular
					.element(
						'<span class="cke_combo_text cke_combo_inlinelabel" style="width: auto; min-width: 85px" />'
					)
					.text($translate.instant(title))
			);

			var ckeComboArrowContainer = angular.element('<span class="cke_combo_open" />');
			ckeComboArrowContainer.append(angular.element('<span class="cke_combo_arrow" />'));
			a.append(ckeComboArrowContainer);

			span.append(a);

			var henkesHeight = signatures.length * 28;
			if (signatures.length > 5) {
				henkesHeight = 140;
			}

			var resultContainer = angular.element(
				'<div class="cke_panel_block cke_dropdown_result_list" style="display: none; z-index: 3; box-shadow: 0 0 5px rgba(200,200,200,0.8); margin-top: 30px;background: #fff;height: ' +
					henkesHeight +
					'px; position: absolute; width: 170px;border-radius: 3px;list-style: none;border: 1px solid #ddd;background-image: linear-gradient(rgb(255, 255, 255), rgb(253, 253, 253));border-color: rgb(225, 225, 225);" />'
			);

			const hideResultContainer = event => {
				if (!$(event.target).closest(span).length && resultContainer.is(':visible')) {
					resultContainer.hide();
				}
			};

			$(document).on('click', hideResultContainer);

			editor.on('destroy', function () {
				$(document).off('click', hideResultContainer);
			});

			var resultList = angular.element('<ul style="list-style: none;height: 100%;overflow-y: auto;" />');

			_.each(signatures, function (sign) {
				var elm = angular.element(
					'<li style="font-size: 12px; padding: 7px 10px;background: rgba(0, 0, 0, 0);white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" />'
				);
				elm.text(sign.name);

				elm.attr({
					'data-id': sign.id
				});

				elm.bind('mouseover', function () {
					var color = $(this).css('background-color');

					$(this).css('background', '#ddd');

					$(this).bind('mouseout', function () {
						$(this).css('background', color);
					});
				});

				resultList.append(elm);
			});

			resultContainer.append(resultList);
			span.append(resultContainer);
			return [span, hideResultContainer];
		};

		var refresh = function (editor) {
			editor.setData(editor.getData());
		};

		return {
			getOptions: function (opts) {
				opts = opts || {};
				return {
					language: 'en',
					uiColor: '#fafafa',
					height: opts.height || '800px',
					resize_enabled: false,
					toolbar: [
						{ name: 'styles', items: ['Font', 'FontSize', 'Format'] },
						{
							name: 'basicstyles',
							items: [
								'Bold',
								'Italic',
								'Underline',
								'Strike',
								'Subscript',
								'Superscript',
								'-',
								'RemoveFormat'
							]
						},
						{ name: 'colors', items: ['TextColor', 'BGColor'] },
						{
							name: 'paragraph',
							items: [
								'NumberedList',
								'BulletedList',
								'-',
								'Outdent',
								'Indent',
								'-',
								'JustifyLeft',
								'JustifyCenter',
								'JustifyRight',
								'JustifyBlock'
							]
						},
						{ name: 'plugins', items: ['lineheight'] },
						{ name: 'insert', items: ['Table', 'HorizontalRule'] },
						{
							name: 'clipboard',
							items: ['Cut', 'Copy', '-', 'Undo', 'Redo']
						},
						{ name: 'document', items: ['Source'] },
						{ name: 'links', items: ['Link', 'Unlink'] }
					],
					allowedContent: true,
					extraAllowedContent:
						'*[id];*(*);*{*};p(*)[*]{*};div(*)[*]{*};li(*)[*]{*};ul(*)[*]{*};span(*)[*]{*};table(*)[*]{*};td(*)[*]{*}',
					entities: false,
					enterMode: CKEDITOR.ENTER_BR,
					fontSize_sizes:
						'8/8px;9/9px;10/10px;11/11px;12/12px;13/13px;14/14px;15/15px;16/16px;18/18px;19/19px;20/20px;21/21px;22/22px;23/23px;24/24px;25/25px;26/26px;27/27px;28/28px;29/29px;30/30px;32/32px;34/34px;36/36px;38/38px;40/40px;',
					init: function (e) {
						var editor = e.editor;
						var editorElem = angular.element(editor.container.$);
						var top = editorElem.find('.cke_top');
						var toolbar = top.find('.cke_toolbox');
						const hasInsertTagPorted = Tools.FeatureHelper.hasSoftDeployAccess('REACT_INSERT_TAG');

						// Set name on textarea
						angular.element(editor.element.$).data('ckName', editor.name);

						// Fix
						// top.css('box-sizing', 'content-box').removeClass('cke_reset_all').addClass('cke_reset');

						var newGroupWrap;
						var newGroup;
						const editorIframe = document.getElementsByClassName('cke_wysiwyg_frame')[0].contentDocument;
						if (!opts.hideTags || !opts.hideImage || !opts.hideMailSignatures) {
							newGroupWrap = angular.element('<span class="cke_toolbar"/>');
							// Create a new btn group
							newGroup = angular.element('<span class="cke_toolgroup" />');
						}

						if (!opts.hideImage) {
							// Img btn
							var addImg = getButton('default.image', 'cke_button__image');
							newGroup.append(addImg);

							addImg.on('click', function (e) {
								e.preventDefault();

								// eslint-disable-next-line promise/catch-or-return
								openFileBrowserModal({
									types: ['image'],
									public: true,
									fileEntity: opts.fileEntity
								}).then(function (selectedFiles) {
									var img =
										'<img alt="" data-widget="image2" src="' +
										selectedFiles[0].$$publicUrl +
										'" />';
									var imgHtml = CKEDITOR.dom.element.createFromHtml(img);
									editor.insertElement(imgHtml);
									// editor.insertHtml('<img src="'+selectedFiles[0].$$publicUrl+'" data-widget="image2">');
									refresh(editor);
								});
							});
						}

						if (Tools.AppService.getMetadata().activatedFeatures.easyBooking.active) {
							const ezbData = Tools.AppService.getMetadata().activatedFeatures.easyBooking;

							// new version
							const bookingPages = ezbData.bookingPages || [];

							const [addEasyBookingLinkDropdown, hideResultContainer] = getDropdown(
								'editor.insertEasyBookingLink',
								bookingPages
									.filter(({ active }) => active)
									.map(({ title, id }) => ({ id, name: title })),
								editor
							);

							editorIframe.addEventListener('click', hideResultContainer);

							const observer = new MutationObserver(() => {
								editorIframe.addEventListener('click', hideResultContainer);
							});
							observer.observe(editorIframe, {
								childList: true
							});

							newGroup.append(addEasyBookingLinkDropdown);

							addEasyBookingLinkDropdown.on('click', function (event) {
								event.preventDefault();
								addEasyBookingLinkDropdown.find('.cke_dropdown_result_list').toggle();
							});

							addEasyBookingLinkDropdown.find('.cke_dropdown_result_list li').on('click', function (ev) {
								ev.preventDefault();

								var clickedId = $(this).attr('data-id');

								const { bookingUrl } = bookingPages.find(({ id }) => `${id}` === clickedId);

								easyBookingTracker.track(easyBookingTracker.events.COPY_LINK, {
									date: moment().format('YYYY-MM-DD'),
									location: 'email'
								});

								editor.insertElement(
									CKEDITOR.dom.element.createFromHtml(
										`<a href="${bookingUrl}">${$translate.instant('editor.easyBookingTile')}</a>`
									)
								);
							});
						}

						if (!opts.hideTags) {
							// Add tag btn
							var addTag = getButton('editor.insertTag');
							newGroup.append(addTag);

							addTag.on('click', function (e) {
								e.preventDefault();

								var tagOpts = {};
								if (opts.tagEntity) {
									tagOpts.tagEntity = opts.tagEntity;
								}
								if (opts.hideIdTags) {
									tagOpts.hideIdTags = opts.hideIdTags;
								}
								if (opts.extraTagGroups) {
									tagOpts.extraTagGroups = opts.extraTagGroups;
								}
								if (opts.strictTagList) {
									tagOpts.strictTagList = opts.strictTagList;
								}
								if (hasInsertTagPorted) {
									openModal('insertTag', {
										...tagOpts,
										onClose: selectedTag => {
											if (selectedTag.insertTag) {
												const addedTag = selectedTag.insertTag();
												if (addedTag) {
													return;
												}
												editor.insertText(selectedTag.value);
											} else if (selectedTag.html) {
												editor.insertHtml(selectedTag.value);
											} else {
												editor.insertText(selectedTag.value);
											}
										}
									});
								} else {
									// eslint-disable-next-line promise/catch-or-return
									$upModal.open('insertTag', tagOpts).then(function (selectedTag) {
										if (selectedTag.insertTag) {
											const addedTag = selectedTag.insertTag();
											if (addedTag) {
												return;
											}
											editor.insertText(selectedTag.value);
										} else if (selectedTag.html) {
											editor.insertHtml(selectedTag.value);
										} else {
											editor.insertText(selectedTag.value);
										}
									});
								}
							});
						}

						if (!opts.hideTags || !opts.hideImage) {
							// Add the group to the toolbar
							newGroupWrap.append(newGroup);

							toolbar.append(newGroupWrap);
						}

						if (
							Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.MAIL_SIGNATURE) &&
							opts.mailSignatures
						) {
							var addSignaturesToDropDown = function () {
								const [addComboBox, hideResultContainer] = getDropdown(
									'default.mailSignature',
									opts.mailSignatures,
									editor
								);

								editorIframe.addEventListener('click', hideResultContainer);

								const observer = new MutationObserver(() => {
									editorIframe.addEventListener('click', hideResultContainer);
								});
								observer.observe(editorIframe, {
									childList: true
								});

								if (opts.mailSignatures.length) {
									toolbar.append(addComboBox);
								}

								addComboBox.on('click', function (event) {
									event.preventDefault();
									MailAccount.get()
										.then(function (account) {
											addComboBox.find('.cke_dropdown_result_list').toggle(function () {
												addComboBox
													.find('.cke_dropdown_result_list li')
													.on('click', function (ev) {
														ev.preventDefault();

														var target = null;
														var clickedId = $(this).attr('data-id');

														$.each(opts.mailSignatures, function (index, value) {
															if (value.id === parseInt(clickedId)) {
																target = value;
															}
														});

														var currentUser = Tools.AppService.getSelf();

														// parse the value for tags
														target.body = target.body.replaceAll(
															'{{General.CurrentUserName}}',
															currentUser.name
														);
														target.body = target.body.replaceAll(
															'{{General.CurrentUserTitle}}',
															currentUser.userTitle || ''
														);
														target.body = target.body.replaceAll(
															'{{General.CurrentUserCellPhone}}',
															currentUser.userCellPhone || ''
														);
														target.body = target.body.replaceAll(
															'{{General.CurrentUserEmail}}',
															currentUser.email
														);
														target.body = target.body.replaceAll(
															'{{General.CurrentUserPhone}}',
															currentUser.userPhone
														);
														target.body = target.body.replaceAll(
															'{{General.CompanyName}}',
															account.data.name
														);
														target.body = target.body.replaceAll(
															'{{General.CompanyAddress}}',
															account.data.address
														);
														target.body = target.body.replaceAll(
															'{{General.CompanyZipCode}}',
															account.data.zipCode
														);
														target.body = target.body.replaceAll(
															'{{General.CompanyCity}}',
															account.data.city
														);
														target.body = target.body.replaceAll(
															'{{General.CompanyCountry}}',
															account.data.country
														);

														// fix regular html
														target.body = target.body.replace(
															'<figure class="image">',
															'<figure class="image" style="background-color: transparent; border: none; padding: 0; margin: 1em 0;">'
														);
														target.body = target.body.replace(
															/<p>/g,
															'<p style="margin: 0 0 10px 0;">'
														);
														target.body = target.body.replace(
															/<p class="small">/g,
															'<p class="small" style="margin: 0 0 10px 0;">'
														);
														target.body = target.body.replace(
															/<h3>/g,
															'<h3 style="margin: 0 0 10px 0;"><span style="font-size:24px;">'
														);
														target.body = target.body.replace(/<\/h3>/g, '</span></h3>');

														editor.setData(editor.getData() + target.body);
													});
											});
										})
										.catch(function (err) {
											console.warn(err);
										});
									//.css('display', 'block');
								});
							};

							if (!opts.hideMailSignatures && opts.mailSignatures && opts.mailSignatures.length) {
								// add mailSignatures dropdown
								addSignaturesToDropDown();
							} else {
								setTimeout(function () {
									addSignaturesToDropDown();
								}, 300);
							}
						}

						// Type timeout
						var typeTimeout;
						var sourceKeyup = function () {
							if (typeTimeout) {
								clearTimeout(typeTimeout);
							}

							typeTimeout = setTimeout(function () {
								refresh(editor);
								editor.fire('change');
							}, 200);
						};
						$(document).on('keyup', 'textarea.cke_source', sourceKeyup);

						// Listen for destroy
						editor.on('destroy', function () {
							// remove listeners
							$(document).off('keyup', 'textarea.cke_source', sourceKeyup);
						});

						if (opts.init) {
							opts.init(editor);
						}
					}
				};
			},
			replaceRawTags: function (body) {
				var taggedBody = body.replace(/%%([a-zA-Z_]*?)%%/g, function (tag, tagName) {
					return (
						'<span contentEditable="false" class="inserted-tag" data-tag="' +
						tag +
						'">' +
						$translate.instant('mailTags.' + tagName) +
						'</span>'
					);
				});
				return taggedBody;
			},
			getTaggedHtml: function () {
				// var html = jQuery.parseHTML(raw);
				// return div.html();
			}
		};
	}
]);

angular.module('services.editorCk').directive('fullHeight', function () {
	return {
		restrict: 'A',
		link: function ($scope, $element) {
			var editor;
			var setHeight = function (modalElement) {
				var wrapper = $element.parent();
				var doc = angular.element(window);
				var fullHeight = doc.height();
				modalElement = modalElement || angular.element('.up-modal:visible');

				fullHeight -= 40; // Modal margin (top+bottom)
				fullHeight -= 20; // Collapsed div margin-bottom
				fullHeight -= angular.element('#top-options').outerHeight(); // up modal header height
				fullHeight -= modalElement.find('.up-modal-header').outerHeight(); // up modal header height
				fullHeight -= modalElement.find('.up-modal-controls').outerHeight(); // up modal footer height
				fullHeight -= 25; // up modal padding
				fullHeight -= 10; // fix

				// Run expand function
				editor.resize(wrapper.width(), fullHeight);
			};

			$scope.$on('ckeditor.ready', function (e, instance) {
				editor = instance;
				if (editor) {
					setHeight();

					if ($scope.reloadModalPosition) {
						$scope.reloadModalPosition();
					}

					$scope.$on('modal.positionChanged', function (e, eventData) {
						setHeight(eventData.modalElement);
					});
				}
			});
		}
	};
});
