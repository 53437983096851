import Client from 'App/resources/Model/Client';
import Lead from 'App/resources/Model/Lead';
import T from 'Components/Helpers/translate';
import React, { useMemo } from 'react';
import moment from 'moment';
import BemClass from '@upsales/components/Utils/bemClass';
import { Button, Icon, Text, Tooltip } from '@upsales/components';
import { openAssignModal } from 'Components/AssignModal/AssignModal';

import './ProcessedBy.scss';

type ProcessedByProps = {
	client: Client | Lead;
	disabled?: boolean;
	formFields?: any;
	formId?: string;
};

const ProcessedBy: React.FC<ProcessedByProps> = ({ client, disabled = false, formFields, formId }) => {
	const classes = new BemClass('ProcessedBy');

	const lang = {
		assign: T('default.assign'),
		noDate: T('accountGrowth.acctivity.noDate'),
		lastAssigned: T('assign.lastAssigned'),
		assignToNew: T('assign.again'),
		openActivity: T('assign.openActivity')
	};

	const processedBy = client?.processedBy?.user;
	const isUserActive = !!processedBy?.active;
	const processedThrough = client?.processedBy?.entityType;
	const processedAtDate = client?.processedBy?.date;
	const processedAtTime = client?.processedBy?.time;

	const isOverdue = useMemo(() => {
		if (processedBy && processedThrough === 'Activity') {
			const dateTime = moment(processedAtDate);
			if (processedAtTime) {
				const timeParts: number[] = processedAtTime?.split(':').map(part => parseInt(part));
				dateTime.hours(timeParts[0]).minutes(timeParts[1]);
			} else {
				// Assume it is 23:59
				dateTime.hours(23).minutes(59);
			}

			return moment().isAfter(dateTime);
		} else {
			return false;
		}
	}, [client]);

	const openProcessedByModal = () => {
		openAssignModal({
			client,
			contactId: undefined,
			from: 'listAccounts',
			formFields: formFields,
			formId: formId
		});
	};

	return (
		<div
			className={classes.b()}
			onClick={event => {
				if (!disabled) {
					event.stopPropagation();
					openProcessedByModal();
				}
			}}
		>
			{processedBy ? (
				<Tooltip title={lang.openActivity} position="top" distance={20}>
					<div className={classes.elem('container').b()}>
						{isUserActive ? (
							<ReactTemplates.TOOLS.avatar
								className={classes.elem('avatar').b()}
								key={processedBy.id}
								user={processedBy}
								size={20}
								avatarService={Tools.avatarService}
							/>
						) : (
							<div className={classes.elem('inactive-user').b()}>
								<Icon name={'user-times'} />
							</div>
						)}

						<div
							className={classes
								.elem('text')
								.mod({ red: isOverdue && isUserActive })
								.b()}
						>
							<Text size={'sm'}>{processedBy?.name || ''}</Text>
							<div className={classes.elem('subtitle').b()}>
								<Icon name={'activity'} />{' '}
								<Text size={'sm'} color={isOverdue && isUserActive ? 'red' : undefined}>
									{processedAtDate ? moment(processedAtDate).format('L') : lang.noDate}
								</Text>
							</div>
						</div>
					</div>
				</Tooltip>
			) : (
				<div className={classes.elem('container').b()}>
					<Tooltip title={lang.assignToNew} position="top" distance={20}>
						<Button disabled={disabled} size={'sm'} className={classes.elem('button').b()}>
							<Icon name={'user-plus'} />
							{('lastAssigned' in client &&
								!client?.lastAssigned &&
								(client?.assignedSalesperson === 'none' || !client?.assignedSalesperson)) ||
							(!('lastAssigned' in client) && !client?.assigned?.user)
								? lang.assign
								: null}
						</Button>
					</Tooltip>
					{('lastAssigned' in client && client?.lastAssigned && client?.assignedSalesperson) ||
					(!('lastAssigned' in client) && client?.assigned?.user) ? (
						<div className={classes.elem('text').mod('last-assigned-text').b()}>
							<Text size={'sm'}>
								{moment(
									'lastAssigned' in client ? client?.lastAssigned : client?.assigned?.regDate
								).format('L')}
							</Text>
							<div className={classes.elem('subtitle').b()}>
								<Text size={'sm'}>
									{lang.lastAssigned}{' '}
									{'lastAssigned' in client
										? client?.assignedSalesperson
										: client?.assigned?.user
										? client?.assigned?.user.name
										: null}
								</Text>
							</div>
						</div>
					) : null}
				</div>
			)}
		</div>
	);
};

export default ProcessedBy;
