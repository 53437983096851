import { ClickableItem, Flex, Tooltip } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import ContactResource from 'App/resources/Contact';
import OrderResource from 'App/resources/Order';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import logError from 'Helpers/logError';
import React from 'react';
import ActivityResource from 'App/babel/resources/Activity';
import { useSelector } from 'App/components/hooks';
import { RootState } from 'Store/index';

export type Entity = 'contact' | 'order' | 'activity';

export enum Action {
	Edit,
	Delete,
	CreateDocument
}

type Props = {
	entity: Entity;
	object: { id: number; userEditable: boolean; userRemovable: boolean; isAppointment?: boolean };
	actions: Action[];
};

export const ActionColumn = ({ entity, object, actions }: Props) => {
	const { documentTemplates } = useSelector(({ App }: RootState) => App);
	const orderTemplates = documentTemplates.order;

	const { t } = useTranslation();
	const lang = {
		edit: t('default.edit'),
		delete: t('default.delete'),
		entity: t(`default.${entity}`),
		createDocument: t('default.createDocument')
	};

	const runAction = async (action: Action) => {
		switch (action) {
			case Action.Edit: {
				switch (entity) {
					case 'activity':
						Tools.$upModal.open(object.isAppointment ? 'editAppointment' : 'editActivity', {
							id: object.id
						});
						break;
					case 'contact':
						Tools.$upModal.open('editContact', { id: object.id });
						break;
					case 'order':
						Tools.$upModal.open('editOrder', { id: object.id });
						break;
					default:
						throw new Error('Invalid entity for edit');
				}
				break;
			}
			case Action.Delete: {
				let resource;
				switch (entity) {
					case 'activity':
						resource = ActivityResource;
						break;
					case 'contact':
						resource = ContactResource;
						break;
					case 'order':
						resource = OrderResource;
						break;
					default:
						throw new Error('Invalid entity for delete');
				}

				if (resource) {
					try {
						await resource.delete(object.id);
					} catch (err) {
						logError(err, `[ActionColumn] failed to remove the ${entity} with id ${object.id}`);
					}
				}

				break;
			}
			case Action.CreateDocument:
				Tools.$upModal.open('createDocument', {
					type: 'agreement',
					id: object.id,
					templates: entity === 'order' ? orderTemplates : []
				});
				break;
			default:
				throw new Error('Invalid action');
		}
	};

	const hasEdit = actions.indexOf(Action.Edit) >= 0 && object.userEditable;
	const hasDelete = actions.indexOf(Action.Delete) >= 0 && object.userRemovable;
	const hasCreateDocument = actions.indexOf(Action.CreateDocument) >= 0;

	return (
		<Flex justifyContent="flex-end" alignItems="center">
			{hasCreateDocument ? (
				<Tooltip title={lang.createDocument}>
					<ClickableItem
						size="sm"
						icon="file"
						onClick={e => {
							e.stopPropagation();
							runAction(Action.CreateDocument);
						}}
						borderRadius
					/>
				</Tooltip>
			) : null}
			{hasEdit ? (
				<Tooltip title={`${lang.edit} ${lang.entity}`}>
					<ClickableItem
						size="sm"
						icon="edit"
						onClick={e => {
							e.stopPropagation();
							runAction(Action.Edit);
						}}
						borderRadius
					/>
				</Tooltip>
			) : null}
			{hasDelete ? (
				<InlineConfirm keepTabPosition onConfirm={() => runAction(Action.Delete)} show entity={lang.entity}>
					<Tooltip title={`${lang.delete} ${lang.entity}`}>
						<ClickableItem size="sm" icon="trash" borderRadius />
					</Tooltip>
				</InlineConfirm>
			) : null}
		</Flex>
	);
};

export default ActionColumn;
