import { Text, Flex, ButtonSelect, Toggle } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import BemClass from '@upsales/components/Utils/bemClass';
import React, { useState } from 'react';

import './ListTab.scss';

type Props = {
	lists: {
		value: string;
		title: string;
		component: JSX.Element;
	}[];
	title?: string;
	groupList?: JSX.Element;
	isGroupBySubaccount?: boolean;
};

const ListTab = ({ title, lists, groupList, isGroupBySubaccount }: Props) => {
	const { t } = useTranslation();

	const [isGrouping, setIsGrouping] = useState(false);
	const [currentList, setCurrentList] = useState(lists[0]?.value);

	if (!currentList) {
		return null;
	}

	const listView = isGrouping ? groupList : lists.find(list => list.value === currentList)?.component;
	const groupingLabel = isGroupBySubaccount ? t('account.subaccounts') : t('company');
	const hasGrouping = !!groupList;

	const classes = new BemClass('ListTab');

	return (
		<Flex direction="column" className={classes.b()}>
			<Flex className={classes.elem('Header').b()} space="mll" alignItems="center" gap="u2">
				<Text>{title}</Text>
				<ButtonSelect
					size="sm"
					disabled={isGrouping}
					options={lists}
					value={isGrouping ? '' : currentList}
					onChange={setCurrentList}
				></ButtonSelect>
				{hasGrouping ? (
					<>
						<Toggle checked={isGrouping} onChange={setIsGrouping} />
						<Text>{`${t('default.groupBy')} ${groupingLabel.toLowerCase()}`}</Text>
					</>
				) : null}
			</Flex>

			{listView}
		</Flex>
	);
};

export default ListTab;
