import React, { useState, useEffect } from 'react';
import logError from 'App/babel/helpers/logError';
import { TableColumn } from '@upsales/components';
import Todo from 'App/resources/Model/Todo';
import Activity from 'App/resources/Model/Activity';
import './TodoListTableRowDescriptionCol.scss';
import Appointment from 'App/resources/Model/Appointment';
import ActivityResource from 'Resources/Activity';
import RelatedButtons from 'App/components/Buttons/RelatedButtons';
import BemClass from '@upsales/components/Utils/bemClass';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';

interface Props {
	todo: Todo | Activity;
}
const TodoListTableRowDescriptionCol = ({ todo }: Props) => {
	const [parentAppointment, setParentAppointment] = useState<Appointment | null>(null);
	const [parentActivity, setParentActivity] = useState<Activity | null>(null);

	useEffect(() => {
		const getParentEntities = async () => {
			try {
				if (todo.parentAppointmentId) {
					const res = await Tools.Appointment.customer(Tools.AppService.getCustomerId()).get(
						todo.parentAppointmentId
					);
					setParentAppointment(res.data);
				} else {
					setParentAppointment(null);
				}
				if (todo.parentActivityId) {
					const res = await ActivityResource.get(todo.parentActivityId);
					setParentActivity(res.data);
				} else {
					setParentActivity(null);
				}
			} catch (err: any) {
				if (!(err && err.status && err.status !== 404)) {
					logError(err, 'Failed to fetch parent entities');
				}
			}
		};
		getParentEntities();
	}, [todo.parentAppointmentId, todo.parentActivityId]);

	const fullSizeRelation = Tools.FeatureHelper.hasSoftDeployAccess('TASKS_COLUMN_IMPROVEMENTS');
	const { todoTypes } = useSelector((state: RootState) => state.App);
	const isTodo = (todo as Todo).type === 'todo' || (todo as Activity).activityType?.id === todoTypes?.TODO?.id;

	let title;
	if (!fullSizeRelation && !isTodo) {
		title = todo.description;
	}

	const classes = new BemClass('TodoListTableRowDescriptionCol');
	return (
		<TableColumn className={classes.b()}>
			<RelatedButtons
				projectPlan={todo.projectPlan}
				opportunity={todo.opportunity}
				activity={parentActivity}
				appointment={parentAppointment}
				title={title}
				fullSize={fullSizeRelation}
			/>
		</TableColumn>
	);
};

export default TodoListTableRowDescriptionCol;
