'use strict';

const { Toggle } = require('@upsales/components');
const { openFileBrowserModal } = require('Components/FileBrowser');

ReactTemplates.form.editorBackgroundElement = window.ReactCreateClass({
	savedBg: null,
	getInitialState: function () {
		return {
			background: this.props.background,
			transparentHeader: this.props.transparentHeader || false
		};
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			setImage: t('bannerEditor.setImage'),
			background: t('bannerEditor.background'),
			changeImage: t('bannerEditor.changeImage'),
			opacity: t('bannerEditor.opacity'),
			useImage: t('bannerEditor.useImage'),
			blur: t('bannerEditor.blur'),
			fullWidth: t('default.fullWidth'),
			imgSize: t('landingpage.imgSize'),
			imgRepeat: t('landingpage.imgRepeat'),
			transparentHeader: t('landingpage.transparentHeader')
		};

		var sizeOpts = ['initial', 'cover', 'contain'];
		this.sizeOpts = _.map(sizeOpts, function (size, i) {
			return (
				<option key={'size' + i} value={size}>
					{t('landingpage.img.' + size)}
				</option>
			);
		});

		var repeatOpts = ['no-repeat', 'repeat', 'repeat-x', 'repeat-y'];
		this.repeatOpts = _.map(repeatOpts, function (repeat, i) {
			return (
				<option key={'repeat' + i} value={repeat}>
					{t('landingpage.img.' + repeat)}
				</option>
			);
		});
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState({ background: nextProps.background });
	},
	bgFullWidthToggle: function () {
		var background = this.state.background;

		if (background.fullWidth) {
			background.fullWidth = false;
		} else {
			background.fullWidth = true;
		}

		this.setState({ background: background });
		this.props.backgroundChange(background);
	},
	bgImgToggle: function () {
		var background = this.state.background;
		var enabled = background.img === null;

		if (enabled) {
			background.img = this.savedBg ? this.savedBg : '';
		} else {
			this.savedBg = background.img;
			background.img = null;
		}
		this.setState({ background: background });
		this.props.backgroundChange(background);
	},
	opacityChanged: function (value) {
		var background = this.state.background;
		background.imgOpacity = value;
		this.setState({ background: background });
		this.props.backgroundChange(background);
	},
	blurChanged: function (value) {
		var background = this.state.background;
		background.imgBlur = value;
		this.setState({ background: background });
		this.props.backgroundChange(background);
	},
	colorChange: function (value) {
		var background = this.state.background;
		background.color = value;
		this.setState({ background: background });
		this.props.backgroundChange(background);
	},
	sizeChange: function (e) {
		var background = this.state.background;
		background.imgSize = e.target.value;
		this.setState({ background: background });
		this.props.backgroundChange(background);
	},
	repeatChange: function (e) {
		var background = this.state.background;
		background.imgRepeat = e.target.value;
		this.setState({ background: background });
		this.props.backgroundChange(background);
	},
	browseImages: function () {
		var self = this;
		// eslint-disable-next-line promise/catch-or-return
		openFileBrowserModal({
			types: ['image'],
			public: true,
			selectOnUpload: true
		}).then(function (files) {
			var background = self.state.background;

			background.img = files[0].$$publicUrl;
			if (!background.imgOpacity) {
				background.imgOpacity = 100;
			}

			self.setState({ background: background });
			self.props.backgroundChange(background);
		});
	},
	transparentHeaderChanged: function (value) {
		this.setState({ transparentHeader: value });
		this.props.transparentHeaderChanged(value);
	},
	render: function () {
		var self = this;
		var previewStyle = { backgroundColor: self.state.background.color };
		var imgElem = null;
		var hasTransparentHeaderOpt = self.props.transparentHeader !== undefined;

		if (self.state.background.img) {
			var style = {
				opacity: self.state.background.imgOpacity / 100,
				backgroundImage: "url('" + self.state.background.img + "')"
			};
			imgElem = <div className="img-element" style={style}></div>;
		}

		return (
			<div className="tool-main-section background-picker">
				{hasTransparentHeaderOpt ? (
					<div className="tool-section" key="transheader-row">
						<label>{self.lang.transparentHeader}</label>
						<div className="pull-right">
							<Toggle
								checked={self.state.transparentHeader}
								onChange={self.transparentHeaderChanged}
								color="medium-green"
							/>
						</div>
					</div>
				) : null}
				{!self.state.transparentHeader ? (
					<div>
						<div className="tool-section" key="fullw-row">
							<label>{self.lang.fullWidth}</label>
							<div className="pull-right">
								<Toggle
									checked={!!self.state.background.fullWidth}
									onChange={self.bgFullWidthToggle}
									color="medium-green"
								/>
							</div>
						</div>
						<div className="tool-section" key="color-row">
							<label>{self.lang.background}</label>
							<div className="pull-right">
								{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
									value: self.state.background.color,
									isText: false,
									presets: self.props.colorVariables,
									addPreset: self.props.addPreset,
									onChange: self.colorChange
								})}
							</div>
						</div>
						<div className="tool-section" key="use-image-row">
							<label>{self.lang.useImage}</label>
							<div className="pull-right">
								<Toggle
									checked={self.state.background.img !== null}
									onChange={self.bgImgToggle.bind(self, 0)}
									color="medium-green"
								/>
							</div>
						</div>
						{self.state.background.img !== null ? (
							[
								<div className="tool-section" key="img-row">
									<div className="img-preview" style={previewStyle}>
										{imgElem}

										<button className="up-btn btn-green" onClick={self.browseImages}>
											{imgElem ? self.lang.changeImage : self.lang.setImage}
										</button>
									</div>
								</div>,
								<div className="tool-section" key="opacity-row">
									<label>{self.lang.opacity}</label>
									<div className="pull-right">
										<ReactTemplates.upStepInt
											min={0}
											max={100}
											value={self.state.background.imgOpacity}
											className="input-sm pull-right"
											onChange={self.opacityChanged}
										/>
									</div>
								</div>,
								<div className="tool-section" key="blur-row">
									<label>{self.lang.blur}</label>
									<div className="pull-right">
										<ReactTemplates.upStepInt
											min={0}
											max={100}
											value={self.state.background.imgBlur}
											className="input-sm pull-right"
											onChange={self.blurChanged}
										/>
									</div>
								</div>,
								<div className="tool-section" key="size-row">
									<label>{self.lang.imgSize}</label>
									<select
										className="form-control inline-select input-sm pull-right"
										value={self.state.background.imgSize}
										onChange={self.sizeChange}
									>
										{self.sizeOpts}
									</select>
								</div>,
								<div className="tool-section" key="repeat-row">
									<label>{self.lang.imgRepeat}</label>
									<select
										className="form-control inline-select input-sm pull-right"
										value={self.state.background.imgRepeat}
										onChange={self.repeatChange}
									>
										{self.repeatOpts}
									</select>
								</div>
							]
						) : (
							<div className="tool-section" key="img-row">
								<div className="img-preview" style={previewStyle}>
									<button className="up-btn btn-green" onClick={self.browseImages}>
										{imgElem ? self.lang.changeImage : self.lang.setImage}
									</button>
								</div>
							</div>
						)}
					</div>
				) : null}
			</div>
		);
	}
});
