import tippy from 'tippy.js';
import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import {
	FOUND_LEAD_ACTIONS,
	OUTCOME_TYPES,
	ANSWER_ACTIONS_GREAT,
	ANSWER_ACTIONS
} from 'App/babel/enum/activityOutcome';
import { DISQUALIFIED, statusDisqualifiable } from 'Components/Helpers/journeyStep';
import logError from 'App/babel/helpers/logError';
import { activityOutcomeTracker } from 'App/babel/helpers/Tracker';
import UpActivityTypes from 'Components/Inputs/UpActivityTypes';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';
import documentResource from 'Resources/document';

angular.module('domain.activity').controller('EditActivity', [
	'$scope',
	'$modalParams',
	'Contact',
	'Order',
	'Links',
	'Activity',
	'RequestBuilder',
	'Account',
	'NotificationService',
	'$translate',
	'$parse',
	'$filter',
	'$upModal',
	'AppService',
	'ScriptService',
	'$q',
	'utils',
	'$state',
	'Appointment',
	'selectHelper',
	'FeatureHelper',
	'URL',
	'API',
	'$safeApply',
	'$element',
	function (
		$scope,
		$modalParams,
		Contact,
		Order,
		Links,
		Activity,
		RequestBuilder,
		Account,
		NotificationService,
		$translate,
		$parse,
		$filter,
		$upModal,
		AppService,
		ScriptService,
		$q,
		utils,
		$state,
		Appointment,
		selectHelper,
		FeatureHelper,
		URL,
		API,
		$safeApply,
		$element
	) {
		var EditActivity = this;
		var customerId;
		var isDirty = false;
		var metadata;
		var hasRequiredContactFields;

		$scope.$on('modal.ready', (event, data) => {
			EditActivity.dateAnchor = data.element[0];
			EditActivity.dateScrollContainer = data.element[0].getElementsByClassName('up-modal-content')[0];
			$safeApply($scope);
		});

		EditActivity.time = undefined;
		EditActivity.contactRemoved = $modalParams.meta.contactRemoved;
		EditActivity.timeModel = {
			endTime: undefined
		};
		EditActivity.editable = true;
		EditActivity.contactPerson = null;
		EditActivity.documentTemplates = null;
		EditActivity.editContact = false;
		EditActivity.editContactModel = {};
		EditActivity.contactFormIsEdit = false;
		EditActivity.lockedAccount = false;
		EditActivity.openPhoneCallId = null;
		EditActivity.customerId = null;
		EditActivity.promptRemoval = false;
		EditActivity.callsCollapsed = true;
		EditActivity.isTodoType = false;

		EditActivity.links = [];
		EditActivity.hasActivityOutcomeAccess = FeatureHelper.isAvailable(
			Tools.FeatureHelper.Feature.ACTIVITY_OUTCOMES
		);
		EditActivity.outcomes = AppService.getStaticValues('activityOutcome') || [];
		EditActivity.hasActivityTimelineAccess = FeatureHelper.hasSoftDeployAccess('ACTIVITY_TIMELINE');
		$scope.showInlineAction = 'none';

		const isTodoType = activityTypeId => {
			const todoTypes = AppService.getTodoTypes();
			return (
				(todoTypes.TODO && todoTypes.TODO.id === activityTypeId) ||
				(todoTypes.PHONE_CALL && todoTypes.PHONE_CALL.id === activityTypeId)
			);
		};

		const setSaving = saving => {
			EditActivity.saving = saving;
		};

		const tracker = outcome => {
			let datePreset = 'no_postpone';
			if (outcome.type === 'postpone' || outcome.outcome === 'postponed') {
				datePreset = outcome.date;
			}
			activityOutcomeTracker.track(activityOutcomeTracker.events.CREATE_EVENT, {
				location: 'modal',
				type: outcome.type,
				outcome: outcome.outcome,
				date_preset: datePreset
			});
		};

		const outcomeContactActions = [ANSWER_ACTIONS.TALK_TO_SOMEONE, FOUND_LEAD_ACTIONS.ADDED_CONTACT];
		$scope.UpActivityTypes = UpActivityTypes;
		$scope.isPriorityEnable = FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION');

		var getLinks = function () {
			EditActivity.links = [];
			if (!EditActivity.activity || !EditActivity.activity.id) {
				return;
			}
			EditActivity.loadingLinks = true;
			Links.customer(customerId)
				.get('activity', EditActivity.activity.id)
				.then(function (response) {
					EditActivity.links = response.data;
				})
				.catch(function (err) {
					EditActivity.linksErr = err;
				})
				.finally(function () {
					EditActivity.loadingLinks = false;
				});
		};

		var resetEditContactModel = function () {
			EditActivity.editContactModel.name = '';
			EditActivity.editContactModel.firstName = '';
			EditActivity.editContactModel.lastName = '';
			EditActivity.editContactModel.title = '';
			EditActivity.editContactModel.titleCategory = {};
			EditActivity.editContactModel.phone = '';
			EditActivity.editContactModel.cellPhone = '';
			EditActivity.editContactModel.email = '';
			EditActivity.editContactModel.optins = [];
		};

		var fixWebpage = function () {
			if (EditActivity.account.webpage && EditActivity.account.webpage.indexOf('http') !== 0) {
				EditActivity.account.webpage = 'http://' + EditActivity.account.webpage;
			}
		};

		$scope.closeModal = function (position) {
			if ($scope.ActivityForm.$dirty) {
				$scope.showInlineAction = position;
			} else {
				$scope.showInlineAction = 'none';
				$scope.reject();
			}
		};

		$scope.rejectChanges = function () {
			$scope.reject();
		};

		$scope.saveChanges = function () {
			EditActivity.save();
		};

		$scope.closeInlineAction = function () {
			$scope.showInlineAction = 'none';
			$safeApply($scope);
		};

		EditActivity.togglePriority = function () {
			if (EditActivity.saving || EditActivity.lockedActivity || !EditActivity.editable) {
				return;
			}
			EditActivity.activity.priority = EditActivity.activity.priority
				? ACTIVITY_PRIORITY.NONE
				: ACTIVITY_PRIORITY.HIGH;
			$scope.ActivityForm.$setDirty();
		};

		EditActivity.onSetJourneyStepClient = function (journeyStep) {
			if (!EditActivity.account) {
				return $q.resolve();
			}
			return Account.customer(customerId)
				.save({
					id: EditActivity.account.id,
					journeyStep: journeyStep
				})
				.then(function (res) {
					EditActivity.account.journeyStep = res.data.journeyStep;
					EditActivity.account = angular.copy(EditActivity.account);
				});
		};

		EditActivity.onSetJourneyStepContact = function (journeyStep) {
			if (!EditActivity.contactPerson) {
				return $q.resolve();
			}
			return Contact.customer(customerId)
				.save({
					id: EditActivity.contactPerson.id,
					journeyStep: journeyStep
				})
				.then(function (res) {
					EditActivity.contactPerson.journeyStep = res.data.journeyStep;
					EditActivity.contactPerson = angular.copy(EditActivity.contactPerson);
				});
		};

		EditActivity.emailContact = function (e, contact) {
			e.preventDefault();
			e.stopPropagation();
			if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
				openNewMailWithContact(contact);
			} else {
				$upModal.open('sendEmail', { customerId: customerId, contactId: contact.id, contact: contact }, e);
			}
		};

		EditActivity.openPhoneCall = function (call, e) {
			var classList = e.target.classList;
			if (!classList.contains('recipent') && !classList.contains('call-date')) {
				return;
			}

			if (
				(EditActivity.phoneIntegration &&
					EditActivity.phoneIntegration.capabilities &&
					EditActivity.phoneIntegration.capabilities.noRecordings) ||
				!(EditActivity.phoneIntegration && EditActivity.phoneIntegration.id)
			) {
				return;
			}

			if (call && EditActivity.openPhoneCallId === call.id) {
				EditActivity.openPhoneCallId = null;
			} else {
				if (call) {
					EditActivity.openPhoneCallId = call.id;
				}
				if (call.conversationUrl) {
					EditActivity.phonrCallSrc =
						URL +
						API +
						'function/voice/recording/?id=' +
						call.conversationUrl +
						'&type=recording&integrationId=' +
						EditActivity.phoneIntegration.id;
				}
			}
		};

		EditActivity.updateDefDesc = function () {
			EditActivity.defDesc = _.get(EditActivity.activity, 'activityType.name', '');
		};
		EditActivity.onActivityTypeChange = function (val) {
			EditActivity.activity.activityType = val;
			EditActivity.updateDefDesc();
			$safeApply($scope);
		};
		EditActivity.activityTypeLabel = AppService.getActivityTypes().length
			? $translate.instant('default.activityType')
			: $translate.instant('default.type');

		EditActivity.fixTime = function () {
			if ($scope.ActivityForm.time) {
				$scope.ActivityForm.time.$setValidity('time', true);
				$scope.ActivityForm.time.$setPristine();
			}
		};

		EditActivity.addTime = function (time) {
			if (typeof time === 'string') {
				time = new Date(time);
			}

			if (typeof EditActivity.activity.date === 'string') {
				EditActivity.activity.date = new Date(EditActivity.activity.date);
			}

			EditActivity.time = time;

			// set null to clear time if time is not set
			if (!time) {
				EditActivity.activity.time = null;
			}

			if (time) {
				EditActivity.activity.date.setHours(time.getHours(), time.getMinutes(), 0, 0);
			}

			EditActivity.activity.showTime = !!time;

			if ($scope.ActivityForm.time) {
				$scope.ActivityForm.time.$setValidity('time', true);
				$scope.ActivityForm.time.$setPristine();
			}
		};

		EditActivity.updateTime = function (time) {
			EditActivity.addTime(time);
			if (time) {
				time = moment(time).format('HH:mm:ss');
			}

			EditActivity.activity.time = time;
			$safeApply($scope);
		};

		EditActivity.addDate = function (date) {
			if (!date) {
				return;
			}

			EditActivity.activity.date = date;

			if (EditActivity.time) {
				EditActivity.activity.date.setHours(EditActivity.time.getHours());
				EditActivity.activity.date.setMinutes(EditActivity.time.getMinutes());
			}
			$safeApply($scope);
		};

		EditActivity.presetDateChange = function (date) {
			EditActivity.time = null;
			EditActivity.activity.time = null;
			EditActivity.addDate(date);
		};

		$scope.enableReminderCheckbox = function () {
			const isAppointment = _.get(EditActivity.activity, 'activityType.$isAppointment');
			var valid =
				EditActivity.time &&
				!EditActivity.activity.closeDate &&
				!EditActivity.lockedActivity &&
				!isAppointment &&
				EditActivity.editable;
			if (valid) {
				var date = EditActivity.activity.date;
				date.setHours(EditActivity.time.getHours(), EditActivity.time.getMinutes(), 0, 0);
				var isFuture = moment(date).isAfter(moment());

				$scope.showReminderCheckbox = valid && isFuture;
			}
		};

		// Create document
		EditActivity.createDocument = function (template) {
			var params = {
				entityId: EditActivity.activity.id,
				templateId: template.id,
				type: 'activity',
				name: template.name
			};
			isDirty = $scope.ActivityForm.$dirty;

			if (EditActivity.contactPerson && EditActivity.contactPerson.email) {
				params.contactId = EditActivity.contactPerson.id;
			}
			if (!isDirty) {
				if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
					return openModal('PreviewPdfModal', {
						isUploadedTemplate: true,
						documentResource: {
							resource: documentResource,
							entityId: params.entityId,
							templateId: params.templateId,
							type: params.type,
							documentName: params.name,
							accountId: params.accountId,
							contact: params.contact,
							contactId: params.contactId
						}
					});
				} else {
					return $upModal.open('pdfPreview', params);
				}
			}

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'default.saveChanges',
					body: 'confirm.saveAndCreateDocument',
					confirmButtonText: 'default.save',
					headerIcon: 'warning',
					onClose: confirmed => {
						if (confirmed) {
							EditActivity.save(false, false)
								.then(function () {
									setSaving(false);
									$scope.ActivityForm.$dirty = false;
									if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
										openModal('PreviewPdfModal', {
											isUploadedTemplate: true,
											documentResource: {
												resource: documentResource,
												entityId: params.entityId,
												templateId: params.templateId,
												type: params.type,
												documentName: params.name,
												accountId: params.accountId,
												contact: params.contact,
												contactId: params.contactId
											}
										});
									} else {
										$upModal.open('pdfPreview', params);
									}
								})
								.catch(e => {
									logError(e, 'Failed to save activity');
								});
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: 'default.saveChanges',
					body: 'confirm.saveAndCreateDocument',
					resolveFalse: 'default.save',
					resolveFalseBtnClass: 'btn-submit',
					no: 'default.abort',
					icon: 'fa-warning'
				})
				.then(function () {
					EditActivity.save(false, false)
						.then(function () {
							setSaving(false);
							$scope.ActivityForm.$dirty = false;
							if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
								openModal('PreviewPdfModal', {
									isUploadedTemplate: true,
									documentResource: {
										resource: documentResource,
										entityId: params.entityId,
										templateId: params.templateId,
										type: params.type,
										documentName: params.name,
										accountId: params.accountId,
										contact: params.contact,
										contactId: params.contactId
									}
								});
							} else {
								$upModal.open('pdfPreview', params);
							}
						})
						.catch(e => {
							logError(e, 'Failed to save activity');
						});
				});
		};

		EditActivity.createOrder = function (isOrder) {
			const afterSave = order => {
				EditActivity.setOutcome({
					type: OUTCOME_TYPES.ANSWER,
					outcome: isOrder ? ANSWER_ACTIONS_GREAT.CREATE_ORDER : ANSWER_ACTIONS_GREAT.CREATE_OPPORTUNITY
				});
				EditActivity.activity.opportunity = order;
				EditActivity.save(true);
				tracker(EditActivity.activity.outcome);
			};
			const options = {
				customerId: customerId,
				clientId: EditActivity.activity.client ? EditActivity.activity.client.id : null,
				contactId: EditActivity.activity.contacts ? EditActivity.activity.contacts.id : null,
				campaign: EditActivity.activity.project ? EditActivity.activity.project : null,
				source: {
					type: 'activity',
					id: EditActivity.activity.id
				},
				type: isOrder ? 'order' : 'opportunity',
				resolveOnSave: true,
				afterSave: order => afterSave(order)
			};

			$upModal.open('editOrder', options);
		};

		EditActivity.createRelatedOpportunity = function () {
			var options = {
				customerId: customerId,
				clientId: EditActivity.activity.client ? EditActivity.activity.client.id : null,
				contactId: EditActivity.activity.contacts ? EditActivity.activity.contacts.id : null,
				campaign: EditActivity.activity.project ? EditActivity.activity.project : null,
				notes: EditActivity.activity.notes,
				type: 'opportunity',
				source: {
					type: 'activity',
					id: EditActivity.activity.id
				},
				resolveOnSave: true,
				activityId: EditActivity.activity.id
			};

			// eslint-disable-next-line promise/catch-or-return
			return $upModal.open('editOrder', options).then(function (opportunity) {
				EditActivity.activity.opportunity = opportunity;
			});
		};

		EditActivity.createOpportunityAndSave = function () {
			EditActivity.createOrder(false);
		};

		EditActivity.createOrderAndSave = function () {
			EditActivity.createOrder(true);
		};

		EditActivity.editAccount = function () {
			if (!EditActivity.activity.client) {
				return;
			}

			if (shouldOpenModal('EditClient')) {
				openModal('EditClient', {
					id: EditActivity.activity.client.id,
					onClose: account => {
						if (account) {
							EditActivity.activity.client = account;
							EditActivity.account = account;
							$safeApply($scope);
						}
					}
				});
			} else {
				// eslint-disable-next-line promise/catch-or-return
				$upModal.open('editAccount', { id: EditActivity.activity.client.id }).then(function (account) {
					EditActivity.activity.client = account;
					EditActivity.account = account;
				});
			}
		};

		EditActivity.createFollowup = function (isAppointment) {
			var promise;
			// Close (if not allready closed)
			if (!EditActivity.activity.closeDate) {
				promise = EditActivity.save(true, true);
			} else {
				promise = EditActivity.save(false, true);
			}

			// Build init-data and open modal
			promise
				.then(function (res) {
					var item;
					if (isAppointment) {
						item = Appointment.new().data;
						item.sourceType = 'activity';
						item.sourceId = EditActivity.activity.id;
						item.activityType = null;
					} else {
						item = Activity.new().data;
						item.activityType = EditActivity.activity.activityType;
						item.custom = res?.data.custom;
						item.description = EditActivity.activity.description;
					}

					item.notes = EditActivity.activity.notes;
					item.client = EditActivity.activity.client;
					item.project = EditActivity.activity.project;
					item.users = _.pick(EditActivity.self, ['id', 'name', 'role', 'email']);
					item.opportunity = EditActivity.activity.opportunity;

					if (EditActivity.activity.closeDate) {
						$scope.resolve(EditActivity.activity);
					}

					if (isAppointment) {
						item.contacts = EditActivity.activity.contacts ? [EditActivity.activity.contacts] : null;
						$upModal.open('editAppointment', {
							appointment: item
						});
					} else {
						item.contacts = EditActivity.activity.contacts;
						$upModal.open('editActivity', {
							activity: item
						});
					}
				})
				.catch(e => {
					logError(e, 'Failed to save activity');
				});
		};

		EditActivity.bookAppointment = function () {
			const appointment = Appointment.new().data;

			appointment.notes = EditActivity.activity.notes;
			appointment.client = EditActivity.activity.client;
			appointment.project = EditActivity.activity.project;
			appointment.users = _.pick(EditActivity.self, ['id', 'name', 'role', 'email']);
			appointment.contacts = EditActivity.activity.contacts ? [EditActivity.activity.contacts] : null;
			appointment.activityType = null;

			if (EditActivity.activity.id) {
				appointment.sourceType = 'activity';
				appointment.sourceId = EditActivity.activity.id;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal.open('editAppointment', { appointment }).then((appointment = {}) => {
				EditActivity.setOutcome({
					type: OUTCOME_TYPES.ANSWER,
					outcome: ANSWER_ACTIONS_GREAT.BOOK_APPOINTMENT,
					appointmentId: _.get(appointment, 'id')
				});
				EditActivity.save(true);
			});
		};

		EditActivity.contactChange = function () {
			if (EditActivity.activity.contacts && EditActivity.activity.contacts.id) {
				// Get all data for contact and save in variable
				Contact.customer(customerId)
					.get(EditActivity.activity.contacts.id)
					.then(function (res) {
						EditActivity.contactPerson = res.data;
						EditActivity.contactPerson.manualOptins = _.filter(
							EditActivity.contactPerson.optins,
							function (o) {
								return o.type === 'manual';
							}
						);
					})
					.catch(e => {
						logError(e, 'Failed to save activity');
					});
			} else {
				EditActivity.contactPerson = null;
				EditActivity.activity.contacts = null;
			}

			saveActivityWithNewContact();
		};

		function saveActivityWithNewContact() {
			//if you have activityoutcomes need to save when contact changes in order ffor changelog to save save that info
			if (EditActivity.hasActivityOutcomeAccess && EditActivity.activity.id) {
				const outcome = EditActivity.activity.outcome;
				const hasContact = EditActivity.activity.contacts && EditActivity.activity.contacts.id;

				if (outcome && (!hasContact || !outcomeContactActions.includes(outcome.outcome))) {
					EditActivity.activity.outcome = null;
				}

				setSaving(true);
				const saveData = {
					id: EditActivity.activity.id,
					contacts: EditActivity.activity.contacts,
					outcome: EditActivity.activity.outcome
				};
				return Activity.customer(customerId)
					.save(saveData)
					.then(({ data }) => {
						EditActivity.activity.id = data.id;
						EditActivity.activity.outcome = null;
					})
					.catch(err => logError(err, 'Failed to updated activity with new contact'))
					.finally(() => {
						setSaving(false);
					});
			}
		}

		EditActivity.abortContactEdit = function () {
			EditActivity.editContact = false;
			// Clear all models
			resetEditContactModel();
		};

		EditActivity.showContactEdit = function (edit) {
			EditActivity.editContact = true;
			EditActivity.contactFormIsEdit = edit;
			// Set all models
			if (edit && EditActivity.contactPerson) {
				EditActivity.editContactModel.name = EditActivity.contactPerson.name;
				EditActivity.editContactModel.firstName = EditActivity.contactPerson.firstName;
				EditActivity.editContactModel.lastName = EditActivity.contactPerson.lastName;
				EditActivity.editContactModel.title = EditActivity.contactPerson.title;
				EditActivity.editContactModel.titleCategory = EditActivity.contactPerson.titleCategory;
				EditActivity.editContactModel.phone = EditActivity.contactPerson.phone;
				EditActivity.editContactModel.cellPhone = EditActivity.contactPerson.cellPhone;
				EditActivity.editContactModel.email = EditActivity.contactPerson.email;
				EditActivity.editContactModel.active = EditActivity.contactPerson.active;
				EditActivity.editContactModel.optins = EditActivity.contactPerson.manualOptins;
			} else {
				resetEditContactModel();
			}

			$scope.$emit('createFocus');
		};

		EditActivity.showMoreOptIns = function ($event, e) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_LIST')) {
				openModal('ListModal', {
					title: 'default.optIns',
					columns: [
						{ title: 'default.name', value: 'title' },
						{ title: 'default.regDate', value: 'date' }
					],
					data: _.map(EditActivity.contactPerson.manualOptins, function (optin) {
						optin.date = moment(optin.regDate).format('L LT');
						return optin;
					})
				});
			} else {
				$upModal.open(
					'list',
					{
						title: 'default.optIns',
						columns: [
							{ title: 'default.name', value: 'title' },
							{ title: 'default.regDate', value: 'date' }
						],
						data: _.map(EditActivity.contactPerson.manualOptins, function (optin) {
							optin.date = moment(optin.regDate).format('L LT');
							return optin;
						})
					},
					e
				);
			}
		};

		EditActivity.saveContactOnEnter = function (event) {
			if (event && event.keyCode === 13) {
				event.preventDefault();
				event.stopPropagation();
				EditActivity.contactEditSave();
			}
		};

		EditActivity.createContact = function () {
			// if contact har required fields we open real modal, else show quickCreate
			if (hasRequiredContactFields) {
				// eslint-disable-next-line promise/catch-or-return
				$upModal.open('editContact', { account: EditActivity.activity.client }).then(function (addedContact) {
					// Set created contact as selected
					EditActivity.contactSelect.data.push(addedContact);
					EditActivity.activity.contacts = addedContact;
					EditActivity.contactPerson = addedContact;
					EditActivity.contactPerson.manualOptins = _.filter(EditActivity.contactPerson.optins, function (o) {
						return o.type === 'manual';
					});
					saveActivityWithNewContact();
				});
			} else {
				EditActivity.showContactEdit();
			}
		};

		EditActivity.contactEditSaveDisabled = function () {
			return (
				EditActivity.editContactLoading ||
				!(
					(!EditActivity.isAvailable.newFields && EditActivity.editContactModel.name) ||
					(EditActivity.isAvailable.newFields &&
						EditActivity.editContactModel.firstName &&
						EditActivity.editContactModel.lastName)
				) ||
				(EditActivity.requiredFieldsContact.Email && !EditActivity.editContactModel.email) ||
				(EditActivity.requiredFieldsContact.Phone && !EditActivity.editContactModel.phone) ||
				(EditActivity.requiredFieldsContact.Cellphone && !EditActivity.editContactModel.cellPhone) ||
				(EditActivity.requiredFieldsContact.Title && !EditActivity.editContactModel.title) ||
				(EditActivity.requiredFieldsContact.TitleCategory && !EditActivity.editContactModel.titleCategory)
			);
		};

		EditActivity.contactEditSave = function () {
			EditActivity.editContactLoading = true;
			var contact = {
				name: EditActivity.editContactModel.name,
				firstName: EditActivity.editContactModel.firstName,
				lastName: EditActivity.editContactModel.lastName,
				title: EditActivity.editContactModel.title,
				titleCategory: EditActivity.editContactModel.titleCategory,
				phone: EditActivity.editContactModel.phone,
				cellPhone: EditActivity.editContactModel.cellPhone,
				email: EditActivity.editContactModel.email,
				client: { id: EditActivity.activity.client.id },
				active: EditActivity.editContactModel.active
			};

			if (EditActivity.contactFormIsEdit) {
				contact.id = EditActivity.contactPerson.id;
			} else {
				contact.active = true;
			}

			if (EditActivity.contactPerson) {
				contact.optins = EditActivity.editContactModel.optins.concat(
					_.filter(EditActivity.contactPerson.optins, function (o) {
						//Concat with non manual optins to not loose them
						return o.type !== 'manual';
					})
				);
			}

			Contact.customer(customerId)
				.save(contact)
				.then(function (res) {
					EditActivity.editContact = false;
					EditActivity.editContactLoading = false;
					// Update list of contacts
					if (EditActivity.contactFormIsEdit) {
						// find contact in list and update
						var i = _.findIndex(EditActivity.contactSelect.data, { id: res.data.id });

						if (i !== -1) {
							EditActivity.contactSelect.data[i] = res.data;
						}
					} else {
						// Add contact to list
						EditActivity.contactSelect.data.push(res.data);
					}
					EditActivity.contactPerson = res.data;
					EditActivity.contactPerson.manualOptins = _.filter(EditActivity.contactPerson.optins, function (o) {
						return o.type === 'manual';
					});
					EditActivity.activity.contacts = res.data;
					// Dont save activity if no access (contact will still save)
					if (EditActivity.activity.userEditable) {
						saveActivityWithNewContact();
					}
				})
				.catch(function () {
					EditActivity.editContactLoading = false;
				});
		};

		EditActivity.setDisqualifyToClient = function () {
			const journeyStep = _.get(EditActivity.activity, 'client.journeyStep');
			const disqualify = _.get(EditActivity.activity, 'outcome.disqualify');

			if (disqualify && journeyStep && statusDisqualifiable(journeyStep)) {
				EditActivity.onSetJourneyStepClient(DISQUALIFIED).catch(e =>
					logError(e, 'Failed to disqualify on close activity')
				);
			}
		};

		EditActivity.save = function (close, followup) {
			setSaving(true);

			if (EditActivity.hasActivityOutcomeAccess && EditActivity.activity.outcome) {
				const shouldClose = _.get(EditActivity.activity.outcome, 'closeActivity', false);
				close = close || shouldClose;
				EditActivity.setDisqualifyToClient();
			}

			if (close) {
				EditActivity.closing = true;
			}

			var activity = angular.copy(EditActivity.activity);

			return ScriptService.activity
				.save(activity)
				.then(function () {
					activity.description = activity.description || EditActivity.defDesc;

					if (activity.$done) {
						activity.closeDate = new Date();
					}

					if (activity.contacts && !_.isArray(activity.contacts)) {
						activity.contacts = [activity.contacts];
					}

					if (close) {
						activity.closeDate = new Date();
					}

					if (!EditActivity.time) {
						activity.date = moment(activity.date).format('YYYY-MM-DD');
					}

					//FIXME: do we really need to do this anymore??
					var failedValidation = false;
					if (EditActivity.requiredFields.Project && !activity.project) {
						NotificationService.addNotification({
							style: NotificationService.style.WARN,
							icon: 'warning',
							title: 'default.error',
							body: 'default.youHaveFormErrorsMissingCampaign'
						});
						failedValidation = true;
					}

					if (
						EditActivity.requiredFields.Contact &&
						(!activity.contacts || (_.isArray(activity.contacts) && !activity.contacts.length))
					) {
						NotificationService.addNotification({
							style: NotificationService.style.WARN,
							icon: 'warning',
							title: 'default.error',
							body: 'default.youHaveFormErrorsMissingContact'
						});
						failedValidation = true;
					}

					if (
						EditActivity.requiredFields.User &&
						(!activity.users || (_.isArray(activity.users) && !activity.users.length))
					) {
						NotificationService.addNotification({
							style: NotificationService.style.WARN,
							icon: 'warning',
							title: 'default.error',
							body: 'default.youHaveFormErrorsMissingUser'
						});
						failedValidation = true;
					}
					if (EditActivity.activity.outcome?.type && !EditActivity.activity.outcome.outcome) {
						NotificationService.addNotification({
							style: NotificationService.style.WARN,
							icon: 'warning',
							title: 'default.error',
							body: 'default.youHaveFormErrorsMissingOutcome'
						});
						failedValidation = true;
					}
					if (failedValidation) {
						setSaving(false);
						EditActivity.closing = false;
						return;
					}
					// End of FIXME

					var opportunitiesByThisUser = _.filter(EditActivity.opportunitySelect.data, function (opportunity) {
						return opportunity.user && opportunity.user.id === activity.users.id;
					});

					// Remove unnecessary bloat. Only need ID.
					var tempId = activity.opportunity && activity.opportunity.id;
					if (!activity.id && !activity.opportunity && opportunitiesByThisUser.length === 1) {
						tempId = opportunitiesByThisUser[0].id;
					}
					if (tempId) {
						activity.opportunity = undefined;
						activity.opportunity = { id: tempId };
					}

					// Save
					return Activity.customer(customerId)
						.save(activity)
						.then(function (response) {
							EditActivity.lockedAccount = !EditActivity.isTodoType;

							// Close modal
							if (!isDirty) {
								$scope.resolve(response.data);
							}

							// Ask if the activity is connected to a opportunity
							// (if this is a create, we have opportunities and we did not choose one)
							if (
								!EditActivity.activity.id &&
								!EditActivity.activity.opportunity &&
								opportunitiesByThisUser.length > 1 &&
								!followup
							) {
								if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_SELECT_OPPORTUNITY')) {
									openModal('SelectOpportunity', {
										opportunies: opportunitiesByThisUser,
										onClose: opportunity => {
											if (!opportunity) {
												return;
											}
											Activity.customer(customerId).save({
												id: response.data.id,
												opportunity: opportunity
											});
										}
									});
								} else {
									// eslint-disable-next-line promise/catch-or-return
									$upModal
										.open('selectOpportunity', { opportunies: opportunitiesByThisUser })
										.then(function (opportunity) {
											// Update activity
											Activity.customer(customerId).save({
												id: response.data.id,
												opportunity: opportunity
											});
										});
								}
							}
							if (EditActivity.activity.outcome) {
								tracker(EditActivity.activity.outcome);
							}
							return response;
						})
						.catch(function () {
							setSaving(false);
							EditActivity.closing = false;
						});
				})
				.catch(function () {
					setSaving(false);
					EditActivity.closing = false;
					return;
				});
		};

		EditActivity.accountChange = function (init) {
			var contactData = EditActivity.contactSelect.data;
			var opportunityData = EditActivity.opportunitySelect.data;

			if (!init) {
				EditActivity.activity.contacts = null;
				EditActivity.activity.opportunity = null;
				contactData.splice(0, contactData.length);
				opportunityData.splice(0, opportunityData.length);
			}

			if (
				EditActivity.activity.client &&
				EditActivity.activity.client.id &&
				EditActivity.activity.client.id > 0
			) {
				Account.customer(customerId)
					.get(EditActivity.activity.client.id)
					.then(function (res) {
						EditActivity.account = res.data;

						fixWebpage();
					})
					.catch(e => {
						if (e.status === 404) {
							EditActivity.account = Object.assign({}, EditActivity.account, {
								userEditable: false,
								userRemovable: false,
								missingRights: true
							});
						} else {
							logError(e, 'Failed to get account');
						}
					});

				var fields = [
					'id',
					'name',
					'email',
					'cellPhone',
					'phone',
					'client',
					'title',
					'titleCategory',
					'optins',
					'journeyStep',
					'active'
				];
				utils.req
					.clientContactsAndRelations(EditActivity.activity.client.id, fields)
					.then(function (res) {
						var contacts = Array.isArray(res.data) ? res.data : [];
						contacts.unshift({ id: null, name: '' });
						contactData.push.apply(contactData, contacts);

						// Set selected contact
						if (
							init &&
							EditActivity.activity &&
							EditActivity.activity.contacts &&
							EditActivity.activity.contacts.id &&
							res.data.length
						) {
							var found = _.find(res.data, { id: EditActivity.activity.contacts.id });
							if (found) {
								EditActivity.activity.contacts = found;
								EditActivity.contactPerson = found;
								EditActivity.contactPerson.manualOptins = _.filter(
									EditActivity.contactPerson.optins,
									function (o) {
										return o.type === 'manual';
									}
								);
							}
						}

						if (contacts.length && !init) {
							setTimeout(function () {
								$scope.$broadcast('editActivity.accountChanged');
							}, 0);
						}

						if (res.metadata.total > 1000) {
							EditActivity.useAjaxContactSelect = true;
						} else {
							EditActivity.useAjaxContactSelect = false;
						}
					})
					.catch(e => {
						logError(e, 'Failed to get client contact and relations');
					});

				if (!init) {
					var orderFilter = new RequestBuilder();
					orderFilter.addFilter(
						Order.attr.account,
						orderFilter.comparisonTypes.Equals,
						EditActivity.activity.client.id
					);
					orderFilter.addFilter(Order.attr.probability, orderFilter.comparisonTypes.NotEquals, 100);
					orderFilter.addFilter(Order.attr.probability, orderFilter.comparisonTypes.NotEquals, 0);

					Order.customer(customerId)
						.find(orderFilter.build())
						.then(function (res) {
							opportunityData.push.apply(opportunityData, res.data);
						})
						.catch(e => {
							logError(e, 'Failed to find orders');
						});
				}

				setTimeout(function () {
					tippy('#notes-area', {
						animation: 'fade',
						arrow: true,
						hideOnClick: false,
						interactive: true,
						maxWidth: '230px',
						placement: 'left',
						size: 'large',
						theme: 'standard-field',
						trigger: 'focus',
						zIndex: 11000
					});
				});
			} else {
				EditActivity.useAjaxContactSelect = false;
				if (EditActivity.isTodoType) {
					EditActivity.account = null;
					EditActivity.activity.contacts = null;
					EditActivity.contactPerson = null;
				}
			}
		};

		EditActivity.promptRemoveEntry = function () {
			if (EditActivity.activity.userRemovable) {
				EditActivity.promptRemoval = !EditActivity.promptRemoval;
			}
		};

		EditActivity.removeEntry = function () {
			$scope.resolve();
			Activity.customer(customerId).delete(EditActivity.activity);
		};

		EditActivity.setStartDate = function (dateStr) {
			EditActivity.activity.date = {
				today: moment().startOf('day').toDate(),
				tomorrow: moment().add(1, 'day').startOf('day').toDate(),
				sevenDays: moment().add(7, 'day').startOf('day').toDate(),
				oneMonth: moment().add(1, 'month').startOf('day').toDate(),
				halfYear: moment().add(6, 'month').startOf('day').toDate()
			}[dateStr];

			// Set time in dateObject if we have a time set
			if (EditActivity.time) {
				EditActivity.activity.date.setHours(EditActivity.time.getHours());
				EditActivity.activity.date.setMinutes(EditActivity.time.getMinutes());
			}
			$safeApply($scope);
		};

		EditActivity.createCampaign = function () {
			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('editCampaign', { customerId: $modalParams.customerId, noRedirect: true })
				.then(function (campaign) {
					// Select the added campaign
					EditActivity.activity.project = { id: campaign.id, name: campaign.name };
				});
		};

		EditActivity.parentCompanyClick = function () {
			// Go to existing parent
			if (EditActivity.account.parent) {
				return $state.go('account.dashboard', { id: EditActivity.account.parent.id });
			}
			// Check for company in db
			var duns = EditActivity.account.soliditet.profileData.parentCompanyDunsNumber;
			var filters = new RequestBuilder();
			filters.addFilter(Account.attr.dunsNo, filters.comparisonTypes.Equals, duns.toString());
			filters.limit = 1;
			filters.fields = ['id'];

			Account.customer(customerId)
				.find(filters.build())
				.then(function (results) {
					// If results
					if (results.data.length) {
						// Save account with found parent and go there
						var data = { id: EditActivity.activity.client.id, parent: { id: results.data[0].id } };
						Account.customer(customerId).save(data, {
							skipNotification: true,
							skipErrorNotification: true
						});
						$state.go('account.dashboard', { id: results.data[0].id });
					} else {
						// Ask to buy the account from soliditet
						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('confirmSoliditetBuyParent', {
								customerId: customerId,
								name: EditActivity.account.soliditet.profileData.parentCompanyName,
								duns: duns
							})
							.then(function (account) {
								// Set parent on account after buy
								var data = { id: EditActivity.activity.client.id, parent: { id: account.id } };
								Account.customer(customerId).save(data, {
									skipNotification: true,
									skipErrorNotification: true
								});
								// Go to account
								$state.go('account.dashboard', { id: account.id });
							});
					}
				})
				.catch(e => {
					logError(e, 'Failed to find account');
				});
		};

		EditActivity.changePhoneNumber = function (phone) {
			EditActivity.editContactModel.phone = phone;
			$safeApply($scope);
		};

		EditActivity.changeCellPhoneNumber = function (cellPhone) {
			EditActivity.editContactModel.cellPhone = cellPhone;
			$safeApply($scope);
		};

		var getDesc = function (obj) {
			return _.escape(obj.description);
		};

		var loadOpportunity = function (activity) {
			if (activity && activity.client) {
				$scope.$evalAsync(function () {
					var orderFilter = new RequestBuilder();
					orderFilter.addFilter(Order.attr.account, orderFilter.comparisonTypes.Equals, activity.client.id);
					orderFilter.addFilter(Order.attr.probability, orderFilter.comparisonTypes.NotEquals, 100);
					orderFilter.addFilter(Order.attr.probability, orderFilter.comparisonTypes.NotEquals, 0);
					Order.customer(customerId)
						.find(orderFilter.build())
						.then(function (res) {
							// Set select-input data
							EditActivity.opportunitySelect.data.push.apply(
								EditActivity.opportunitySelect.data,
								res.data
							);

							// Find selected one if there is one on the activity
							if (activity.opportunity && activity.opportunity.id) {
								const found = _.find(EditActivity.opportunitySelect.data, {
									id: activity.opportunity.id
								});
								if (found) {
									EditActivity.activity.opportunity = found;
								}
							}
							// If this is a create-activity we try to auto-connect an opportunity if there only is one that belongs to this user
							else if (!activity.id && EditActivity.opportunitySelect.data.length) {
								var selfOpportunities = _.filter(EditActivity.opportunitySelect.data, function (opp) {
									return opp && opp.user && opp.user.id === EditActivity.self.id;
								});
								// Only auto connect if this belongs to the signed in user
								if (selfOpportunities.length === 1) {
									EditActivity.activity.opportunity = selfOpportunities[0];
								}
							}
						})
						.catch(e => {
							logError(e, 'Failed to find order');
						});
				});
			}
		};

		EditActivity.clearDate = () => {
			if (!EditActivity.isTodoType) {
				return;
			}
			EditActivity.activity.date = null;
			EditActivity.time = null;
		};

		EditActivity.toggleCallsCollapsed = function () {
			EditActivity.callsCollapsed = !EditActivity.callsCollapsed;
		};

		EditActivity.setOutcome = function (outcome, cb) {
			const dateTimeStr = moment.utc().format();
			const clientJourney = _.get(EditActivity.activity, 'client.journeyStep');

			EditActivity.activity.outcome = outcome ? { ...outcome, date: dateTimeStr, clientJourney } : null;
			$scope.ActivityForm?.$setDirty(); // $scope.ActivityForm is undefined if the modal is not open
			cb?.();
			$safeApply($scope);
		};

		EditActivity.setContact = function (contact) {
			EditActivity.activity.contacts = contact;

			EditActivity.contactChange();
		};

		EditActivity.openContactDropdown = function () {
			angular.element('.edit-activity-modal #contact-select').select2('open');
		};

		function addNewContactButton() {
			if (EditActivity.hasActivityOutcomeAccess) {
				setTimeout(() => {
					const select2Drop = angular.element('#select-contact-init .select2-drop');
					const addNewContactBtn = jQuery(
						'<div class="add-new-contact Button up-btn btn-bright-blue btn-link btn-block"/>'
					).text($translate.instant('default.createAContact'));
					select2Drop.append(addNewContactBtn);
					select2Drop.on('click', '.add-new-contact', () => {
						angular.element('#contact-select').select2('close');
						EditActivity.createContact();
						$safeApply($scope);
					});
				}, 200);
			}
		}

		EditActivity.setAppointment = function (id, notes) {
			if (!id) return;

			// eslint-disable-next-line promise/catch-or-return
			EditActivity.save(true).then(() =>
				$upModal.open('editAppointment', {
					id,
					appointment: { notes: `${notes}\n${EditActivity.activity.notes}` }
				})
			);
		};

		var init = function () {
			$element[0].classList.remove('initially-hidden');
			$upModal.unhideCurtain();
			customerId = AppService.getCustomerId();
			EditActivity.customerId = customerId;
			metadata = AppService.getMetadata();
			EditActivity.hasSoftDeployContactTitleCategoryAccess =
				Tools.FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');

			var meta = $modalParams.meta;
			var users = meta.users.data;
			// var activityCustomFields = AppService.getCustomFields('activity');
			var activityTypes = AppService.getActivityTypes('activity', true);

			EditActivity.isAvailable = {
				opportunity: FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE),
				document: FeatureHelper.isAvailable(FeatureHelper.Feature.DOCUMENTS),
				optin: FeatureHelper.isAvailable(FeatureHelper.Feature.OPT_IN),
				newFields: FeatureHelper.hasSoftDeployAccess(Tools.FeatureHelper.Feature.NEW_FIELDS)
			};

			EditActivity.requiredFields = metadata.requiredFields.Activity;
			EditActivity.requiredFieldsContact = metadata.requiredFields.Contact;
			EditActivity.standardFields = metadata.standardFields.Activity;

			EditActivity.self = AppService.getSelf();
			EditActivity.masterCurrency = _.find(metadata.customerCurrencies, { masterCurrency: true });
			EditActivity.ownRightsId =
				EditActivity.self.createRights.Activity === 'OWN' ? EditActivity.self.id : undefined;

			var nextMonth = moment().add(1, 'month').startOf('day');
			EditActivity.nextMonthString = nextMonth.format('MMMM');

			EditActivity.hasDocumentTemplates = meta.documentTemplates.data && meta.documentTemplates.data.length;
			EditActivity.documentTemplates = meta.documentTemplates.data;
			EditActivity.type = 'activity';

			var displayUser = function (user) {
				return _.escape(user.name);
			};

			var contactSelect = {
				formatSelection: selectHelper.wrapFormatSelectionLink(
					function (contact) {
						return utils.select2.clientContactsAndRelations.formatSelection(
							contact,
							EditActivity.activity.client ? EditActivity.activity.client.id : null
						);
					},
					function (contact, $state) {
						$state.go('contact.dashboard', { id: contact.id }, { reload: true });
					}
				),
				formatResult: function (contact) {
					if (!contact.id) {
						return '<i class="grey">' + $translate.instant('default.noContact') + '</i>';
					}
					return utils.select2.clientContactsAndRelations.formatResult(
						contact,
						EditActivity.activity.client ? EditActivity.activity.client.id : null
					);
				},
				minimumResultsForSearch: 8,
				allowClear: 0,
				matcher: function (term, text, contact) {
					return contact.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				data: []
			};

			var contactSelectAjax = {
				formatSelection: selectHelper.wrapFormatSelectionLink(
					function (contact) {
						return utils.select2.clientContactsAndRelations.formatSelection(
							contact,
							EditActivity.activity.client ? EditActivity.activity.client.id : null
						);
					},
					function (contact, $state) {
						$state.go('contact.dashboard', { id: contact.id }, { reload: true });
					}
				),
				formatResult: function (contact) {
					if (!contact.id) {
						return '<i class="grey">' + $translate.instant('default.noContact') + '</i>';
					}
					return utils.select2.clientContactsAndRelations.formatResult(
						contact,
						EditActivity.activity.client ? EditActivity.activity.client.id : null
					);
				},
				minimumResultsForSearch: 8,
				minimumInputLength: 1,
				allowClear: true,
				matcher: function (term, text, contact) {
					return contact.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				data: [],
				ajax: {
					data: function (term) {
						return term;
					},
					transport: function (query) {
						if (query.data && EditActivity.activity.client) {
							var contactFilter = new RequestBuilder();
							contactFilter.addFilter(
								Contact.attr.name,
								contactFilter.comparisonTypes.Search,
								query.data
							);
							contactFilter.addFilter(
								Contact.attr.client.attr.id,
								contactFilter.comparisonTypes.Equals,
								EditActivity.activity.client.id
							);

							return Contact.customer(customerId).find(contactFilter.build()).then(query.success);
						}

						return query.success({
							data: []
						});
					},
					results: function (res) {
						return {
							results: res.data
						};
					}
				}
			};

			var userSelect = {
				formatSelection: displayUser,
				formatResult: displayUser,
				allowClear: 0,
				matcher: function (term, text, op) {
					return op.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				data: users
			};

			var opportunitySelect = {
				allowClear: 1,
				formatSelection: selectHelper.wrapFormatSelectionLink(getDesc, function (order) {
					$upModal.open('editOrder', { id: order.id });
				}),
				formatResult: function (opportunity, el, x, encode) {
					var currency = opportunity.currency || EditActivity.masterCurrency.iso;
					return (
						'<b>' +
						$filter('upsalesDate')(opportunity.date) +
						' ' +
						encode(opportunity.description) +
						'</b><br/>' +
						$filter('currencyFormat')(opportunity.value, currency) +
						'<span class="pull-right">' +
						opportunity.user.name +
						'</span>'
					);
				},
				matcher: function (term, text, opportunity) {
					return opportunity.description.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				data: []
			};

			EditActivity.lockedActivity = false;

			var activity = meta.activity.data;

			// if edit
			if ($parse('activity.data.id')(meta)) {
				EditActivity.isNew = false;
				EditActivity.editable = activity.userEditable;

				activity.custom = activity.custom || [];

				activity.showTime = true;

				if (!activity.time) {
					EditActivity.time = undefined;
					activity.showTime = false;
				} else if (activity.date && activity.time) {
					EditActivity.time = activity.date;
					activity.showTime = true;
				}
				// FIXME: Move this parsing to resource parse/map
				activity.users = $parse('users[0]')(activity);
				activity.contacts = $parse('contacts[0]')(activity);
				EditActivity.lockedActivity = !!activity.closeDate;
				activity.done = !!activity.closeDate;
				EditActivity.contactPerson = activity.contacts;

				if (EditActivity.contactPerson) {
					EditActivity.contactPerson.manualOptins = _.filter(EditActivity.contactPerson.optins, function (o) {
						return o.type === 'manual';
					});
				}
			} else {
				EditActivity.isNew = true;

				// FIXME: Move this parsing to resource parse/map
				// users
				if (Array.isArray(activity.users)) {
					activity.users = activity.users[0];
				}

				// contacts
				if (Array.isArray(activity.contacts)) {
					activity.contacts = activity.contacts[0];
				}
			}

			if (activity.client) {
				EditActivity.lockedAccount = true;
			}

			EditActivity.activity = activity;
			EditActivity.activity.phoneCalls = activity.phoneCalls.filter(function (p) {
				return p.client && p.phoneNumber;
			});

			// Added it to prevent trigger ngChange contacts on initial step
			EditActivity.activity.contacts = activity.contacts || null;

			// Figure out if todo/phonecall-activity
			const isTodo = isTodoType(EditActivity.activity.activityType ? EditActivity.activity.activityType.id : 0);
			if (isTodo) {
				EditActivity.isTodoType = true;
			}

			// If this is an edit and the activity has a type selected that we cannot use
			// If the default activityType was removed we need to set a new one
			if (
				!EditActivity.activity.activityType ||
				(!isTodo && !_.find(activityTypes, { id: EditActivity.activity.activityType.id }))
			) {
				EditActivity.activity.activityType = activityTypes[0] || {};
			}

			EditActivity.activityCustomLength = _.filter(EditActivity.activity.custom, function (f) {
				return f.$hasAccess && (f.editable || f.visible);
			}).length;

			// Selects
			EditActivity.contactSelect = contactSelect;
			EditActivity.contactSelectAjax = contactSelectAjax;
			EditActivity.userSelect = userSelect;
			EditActivity.opportunitySelect = opportunitySelect;
			EditActivity.users = users;

			if (meta.contact && !meta.missingContactRights) {
				EditActivity.contactPerson = meta.contact.data;
				EditActivity.contactPerson.manualOptins = _.filter(meta.contact.data.optins, function (o) {
					return o.type === 'manual';
				});
			}

			// get links
			getLinks();

			// Selects
			loadOpportunity(activity);

			EditActivity.accountChange(true);

			EditActivity.phoneIntegration = AppService.getPhoneIntegration();
			EditActivity.updateDefDesc();

			// Check if contact has required fields
			var defaultRequiredFields = false;
			angular.forEach(metadata.requiredFields.Contact, function (required, field) {
				if (field !== 'Email' && required) {
					defaultRequiredFields = true;
				}
			});

			if (EditActivity.hasActivityOutcomeAccess) {
				$scope.$watch('EditActivity.activity.date', date => {
					EditActivity.isOutDated = !EditActivity.activity.closeDate && moment(date).endOf('day').isBefore();
				});
			}

			var customRequiredFields = _.filter(AppService.getCustomFields('contact'), 'obligatoryField').length;
			hasRequiredContactFields = defaultRequiredFields || customRequiredFields;

			ScriptService.activity.init(EditActivity.activity);

			if (EditActivity.hasActivityOutcomeAccess) {
				$scope.$watch('EditActivity.activity.activityType', function (activityType = {}) {
					const activityTypes = AppService.getActivityTypes('activity', true);
					EditActivity.isOutcomeEnabled = _.get(
						_.find(activityTypes, { id: activityType.id }),
						'hasOutcome',
						false
					);
				});
			}

			// SI ui-elements
			if (metadata.integrations && metadata.integrations.uiElements) {
				EditActivity.uiElements = metadata.integrations.uiElements.editActivity;
			}

			if (EditActivity.activity.showTime) {
				EditActivity.time = EditActivity.activity.date;
			}

			addNewContactButton();
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);
