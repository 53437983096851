// The changes in this file was pretty much moved here from upNavbarRoot. It is not that pretty right now but i will refactor this in an upcoming ticket and move the notification state to redux.
import React, { useEffect, useRef, useState } from 'react';
import { Icon } from '@upsales/components';
import { openDrawer } from 'App/babel/services/Drawer';
import classNames from 'classnames';
import NavbarButton from 'App/navbar/NavbarButton';
import logError from 'Helpers/logError';
import openAgreement from 'App/helpers/openSubscriptionHelper';
import t from 'Components/Helpers/translate';
import config from '../../../../../../config';
import { useSelectedBrandId, useSelector } from 'App/components/hooks';
import { makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import RequestBuilder from 'Resources/RequestBuilder';
import { removeItem, replaceItem } from 'Store/helpers/array';
import history from 'App/pages/routes/history';
import openModal from 'App/services/Modal/Modal';

const getExportUrl = customerId => {
	return config.URL + config.API + customerId + '/resources/download/internal/';
};

const showImport = function (notification, type) {
	if (notification.entityId) {
		const entityState = {
			contact: 'contacts',
			client: 'accounts',
			product: 'administration.products'
		};
		const state = entityState[type];

		if (type === 'product') {
			Tools.$state.go(state, { import: notification.entityId });
			return;
		}

		Tools.$state
			.go(state, {})
			.then(function () {
				const isContact = type === 'contact';
				const filters = {
					FromImport: { value: [notification.entityId] }
				};
				const urlFilters = Tools.FilterHelper.convertForURL(filters, isContact ? 'contact' : 'account');
				Tools.$location.search({
					q: urlFilters,
					id: 2
				});
			})
			.catch(e => {
				logError(e, `Failed to go to state ${state}`);
			});
	}
};

const onNotificationClick = function (notification, e) {
	const customerId = Tools.AppService.getCustomerId();
	if (
		e &&
		e.target &&
		e.target.tagName === 'A' &&
		!(
			notification.type === Tools.PushNotifications.Types.EXPORT &&
			notification.message &&
			notification.message.split(',').length > 1
		)
	) {
		return;
	}

	const error = {
		title: 'default.error',
		style: 'error',
		icon: 'times'
	};

	switch (notification.type) {
		case Tools.PushNotifications.Types.ORDER:
			if (notification.order && notification.order.id !== undefined) {
				Tools.$upModal.open('editOrder', { id: notification.order.id, customerId: customerId });
			} else {
				error.body = 'openError.order';
				Tools.NotificationService.addNotification(error);
			}
			break;
		case Tools.PushNotifications.Types.TICKET:
			if (notification.entityId) {
				openDrawer('EditTicket', { ticketId: notification.entityId });
			} else {
				error.body = 'openError.ticket';
				Tools.NotificationService.addNotification(error);
			}
			break;
		case Tools.PushNotifications.Types.AGREEMENT:
			if (notification.agreement?.id !== undefined) {
				openAgreement({ agreementId: notification.agreement.id });
			} else {
				error.body = 'openError.agreement';
				Tools.NotificationService.addNotification(error);
			}
			break;
		case Tools.PushNotifications.Types.ASSIGNED:
			if (notification.entityId !== undefined) {
				Tools.$upModal.open('editActivity', { id: notification.entityId });
			} else {
				error.body = 'openError.activity';
				Tools.NotificationService.addNotification(error);
			}
			break;
		case Tools.PushNotifications.Types.MAIL:
			if (notification.entityId !== undefined) {
				if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
					openDrawer('SentMail', { mail: { id: notification.entityId } });
				} else {
					Tools.$upModal.open('sentMail', { customerId: customerId, id: notification.entityId });
				}
			} else {
				error.body = 'openError.email';
				Tools.NotificationService.addNotification(error);
			}
			break;
		case Tools.PushNotifications.Types.LISTVIEW:
		case Tools.PushNotifications.Types.REPORTVIEW: {
			let udoNr = '';
			const getState = function (entity) {
				switch (entity) {
					case 'activity':
						return 'activities';
					case 'advancedSearchAccount':
						return 'advancedSearch.account';
					case 'advancedSearchContact':
						return 'advancedSearch.contact';
					case 'advancedSearchActivity':
						return 'advancedSearch.activity';
					case 'advancedSearchAppointment':
						return 'advancedSearch.appointment';
					case 'advancedSearchOpportunity':
						return 'advancedSearch.opportunity';
					case 'advancedSearchOrder':
						return 'advancedSearch.order';
					case 'callList':
						return 'react-root-calllists';
					case 'campaign':
						return 'react-root-campaigns';
					case 'client':
						return 'accounts';
					case 'flow':
						return 'react-root-flows';
					case 'form':
						return 'react-root-forms';
					case 'leads2':
						return 'react-root-leads';
					case 'mail':
						return 'react-root-mail';
					case 'mailTemplate':
						return 'listMailTemplates';
					case 'page':
						return 'react-root-landingpages';
					case 'support':
						return 'react-root-support';
					case 'formSubmit':
						return 'react-root-formSubmits';
					case 'mailCampaign':
						return 'react-root-mail-campaigns';
					case 'opportunity':
						return 'react-root-opportunities';
					case 'order':
						return 'react-root-orders';
					case 'projectPlan':
						return 'react-root-projects';
					case 'salesboard':
						return 'react-root-salesboard';
					case 'segment':
						return 'react-root-segments';
					case 'soliditet':
						return 'findProspects';

					case 'userDefinedObject1':
					case 'userDefinedObject2':
					case 'userDefinedObject3':
					case 'userDefinedObject4':
						udoNr = entity.substring(entity.length - 1);
						return 'react-root-userdefinedobjects';
					case 'phoneCall':
						return 'react-root-phonecalls';
					case 'visitor':
						return 'react-root-visitors';
					case 'account':
					case 'appointment':
					case 'periodization':
					case 'contact':
					case 'agreement':
						return entity.toLowerCase() + 's';
					default:
						return 'unknown-entity:' + entity;
				}
			};

			let state = getState(notification.action);
			const params = {
				customerId,
				notificationEntityId: notification.entityId,
				typeId: udoNr
			};

			if (notification.type === Tools.PushNotifications.Types.REPORTVIEW) {
				state = 'reportcenter';
				params.reportEntity = notification.action;
				params.id = notification.id;
			}

			Tools.$state
				.go(state, params)
				.then(function () {
					Tools.$location.search({
						id: notification.entityId
					});
				})
				.catch(error => {
					const errorsWithTranslations = [
						'requireAdmin',
						'requireAdminOrMailAdmin',
						'requireAdminOrSoliditetMulti',
						'requireMailAdmin'
					];

					let body;

					if (errorsWithTranslations.includes(error)) {
						const cause = t(`sharedViews.noaccess.${error}`);
						body = t('sharedViews.noaccess.cause', { cause });
					} else if (error === 'featureNotAvailable') {
						body = 'sharedViews.noaccess';
					} else {
						logError(e, `Failed to go to state ${state}`, JSON.stringify(params));
						body = 'sharedViews.noaccess';
					}

					Tools.NotificationService.addNotification({
						title: 'default.error',
						body: body,
						style: Tools.NotificationService.style.ERROR,
						icon: 'times'
					});
				});

			break;
		}
		case Tools.PushNotifications.Types.SUBMIT:
			if (notification.client && notification.client.id !== undefined) {
				history.push(`/form-editor/${notification.form.id}`);
			} else {
				error.body = 'openError.account';
				Tools.NotificationService.addNotification(error);
			}
			break;
		case Tools.PushNotifications.Types.PROVISIONING:
			Tools.$state.go('administration.billing');
			break;
		case Tools.PushNotifications.Types.EXPORT:
			if (notification.message && notification.message.split(',').length > 1) {
				Tools.$upModal.open('downloadList', {
					title: 'export.downloadTitle',
					downloadIds: notification.message.split(','),
					exportUrl: getExportUrl(customerId)
				});
			}
			break;
		case Tools.PushNotifications.Types.ESIGN:
			if (notification.esign && notification.esign.id !== undefined) {
				if (Tools.FeatureHelper.hasSoftDeployAccess('CONFIRM_ESIGN_REACT')) {
					openModal('ConfirmEsignModal', {
						id: notification.esign.id
					});
				} else {
					Tools.$upModal.open('confirmEsign', {
						id: notification.esign.id
					});
				}
			}
			break;
		case Tools.PushNotifications.Types.APP: {
			if (notification.message === 'invalid_grant') {
				const integrations = Tools.AppService.getMetadata().integrations.active;
				const integration = integrations.find(integration => integration.name === notification.action);
				if (integration) {
					Tools.$state.go('administration.integration', {
						id: integration.id,
						configure: 'user',
						setOauthToNull: true
					});
				}
			} else {
				if (notification.entityId) {
					if (notification.message?.includes('"easybooking"')) {
						let appointmentId;
						try {
							const message = JSON.parse(notification.message);
							const { appointment } = message.easybooking;
							appointmentId = appointment.id;
						} catch (e) {
							logError(e, 'Could not parse json');
							break;
						}
						if (appointmentId) {
							Tools.$upModal.open('editAppointment', { id: appointmentId });
							break;
						}
					}
					Tools.$upModal.open('appWidget', {
						logId: notification.entityId,
						title: notification.action,
						name: 'notification',
						obj: notification
					});
				}
			}
			break;
		}
		case Tools.PushNotifications.Types.DEFAULT:
			if (notification.action === 'ActivityReminder') {
				const message = JSON.parse(notification.message);
				if (message) {
					if (Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST') && message.reminder.projectId) {
						Tools.routerHistory.push(`/todo/custom-${message.reminder.projectId}`);
					} else if (Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
						Tools.$state.go('react-root-todo');
					} else {
						Tools.$state.go('activities');
					}
				}
				break;
			} else if (notification.action === 'FiscalYearChange') {
				Tools.$state.go('administration.dashboard');
			}
	}
};

const notificationFilter = function (notification) {
	switch (notification.type) {
		case Tools.PushNotifications.Types.ORDER:
			return notification.order &&
				notification.order.id !== undefined &&
				notification.client &&
				notification.client.id
				? true
				: false;
		case Tools.PushNotifications.Types.AGREEMENT:
			return notification.agreement &&
				notification.agreement.id !== undefined &&
				notification.client &&
				notification.client.id
				? true
				: false;
		case Tools.PushNotifications.Types.ASSIGNED:
			return notification.entityId !== undefined ? true : false;
		case Tools.PushNotifications.Types.SUBMIT:
			return notification.client &&
				notification.client.id !== undefined &&
				notification.form &&
				notification.form.id
				? true
				: false;
	}

	return true;
};

const isOld = function (date) {
	const ms = moment().diff(date);
	const d = moment.duration(ms);
	const s = Math.floor(d.asHours());

	return s > 12;
};

const limit = 50;

// TODO: Refactor, move and ts this
const NotificationDropdown = () => {
	const [activeNotification, setActiveNotification] = useState(null);
	const [notifications, setNotifications] = useState([]);
	const [notificationsLoading, setNotificationsLoading] = useState(false);
	const [jobs, setJobs] = useState([]);
	const [unreadCount, setUnreadCount] = useState(0);
	const { customerId, self } = useSelector(({ App }) => ({
		customerId: App.customerId,
		self: App.self
	}));
	const selectedBrandId = useSelectedBrandId();
	const setReadPromise = useRef(null);
	const getNotificationsPromise = useRef(null);
	const activeTimeout = useRef(null);

	const getNotifications = offset => {
		if (notificationsLoading) {
			return;
		}
		setNotificationsLoading(true);

		const filters = new RequestBuilder();
		filters.addFilter(Tools.PushNotifications.attr.userIds, filters.comparisonTypes.Equals, self.id);

		filters.addSort(Tools.PushNotifications.attr.date, false);

		filters.limit = limit;
		filters.offset = offset;

		getNotificationsPromise.current = makeCancelable(
			Tools.PushNotifications.customer(customerId).find(filters.build())
		);

		getNotificationsPromise.current.promise
			.then(res => {
				let unread = 0;
				const newJobs = [];
				const newNotifications = [];

				if (res?.data?.length) {
					res.data = res.data.filter(notificationFilter);
				}

				res.data.forEach(notification => {
					const isJob =
						notification.type === Tools.PushNotifications.Types.JOB ||
						notification.type === Tools.PushNotifications.Types.EXPORT ||
						notification.type === Tools.PushNotifications.Types.IMPORT;

					// If we have broken jobs we show them as failed
					if (isJob && notification.status !== 100 && isOld(notification.date)) {
						notification.status = -1;
						notification.read = true;
					}

					if (isJob && notification.status !== 100 && notification.status !== -1) {
						newJobs.push(notification);
					} else {
						newNotifications.push(notification);
						if (!notification.read && !notification.type?.includes('Prospecting:Signals')) {
							unread++;
						}
					}
				});

				if (offset === 0) {
					setUnreadCount(unread);
					setJobs(newJobs);
					setNotifications(newNotifications);
				} else {
					setUnreadCount(unreadCount + unread);
					setJobs([...jobs, ...newJobs]);
					setNotifications([...notifications, ...newNotifications]);
				}
			})
			.catch(e => {
				logError(e, 'Failed to get notifications');
			})
			.finally(() => {
				setNotificationsLoading(false);
			});
	};
	const queueActiveNotificationHide = data => {
		if (activeTimeout.current) {
			clearTimeout(activeTimeout.current);
		}
		setActiveNotification(data);
		activeTimeout.current = setTimeout(function () {
			setActiveNotification(null);
		}, 4000);
	};

	const onNotification = data => {
		const browserNotificationOpts = {
			onClick: onNotificationClick,
			hasIcon: true,
			autoHide: false
		};

		// Build queue for notifications here
		// check for job
		if (
			data.type === Tools.PushNotifications.Types.JOB ||
			data.type === Tools.PushNotifications.Types.EXPORT ||
			data.type === Tools.PushNotifications.Types.IMPORT
		) {
			// If done or failed
			if (data.status === 100 || data.status === -1) {
				// Move to notifications
				const index = jobs.findIndex(j => j.id === data.id);
				if (index !== -1) {
					setJobs(removeItem(jobs, index));
				}

				// Check if we somehow have the notification
				const exists = notifications.findIndex(n => n.id === data.id);
				if (exists !== -1) {
					setNotifications(replaceItem(notifications, exists, { ...notifications[exists], ...data }));
				} else {
					setNotifications([data, ...notifications]);
					setUnreadCount(unreadCount + 1);
				}

				queueActiveNotificationHide(data);

				window.BabelServices.BrowserNotifications.show(data, browserNotificationOpts);
			} else {
				// if not done (status update)
				// If the job exists
				const exists = jobs.findIndex(j => j.id === data.id);
				const existDone = notifications.findIndex(n => n.id === data.id);
				if (exists !== -1) {
					setJobs(replaceItem(jobs, exists, { ...jobs[exists], ...data }));
				} else if (existDone !== -1) {
					// Do nuthin' (sometimes 0-status events emits after failed(-1) events API bug???)
				} else {
					// Add this new job
					setJobs([...jobs, data]);
					queueActiveNotificationHide(data);
				}
			}
		} else {
			// If not job
			if (!data.type?.includes('Prospecting:Signals')) {
				setUnreadCount(unreadCount + 1);
				queueActiveNotificationHide(data);
				window.BabelServices.BrowserNotifications.show(data, browserNotificationOpts);
			}
			setNotifications([data, ...notifications]);
		}
	};

	useEffect(() => {
		getNotifications(0);
	}, [selectedBrandId]);

	useEffect(() => {
		document.originalTitle ||= document.title;
		window.document.title =
			unreadCount >= 1 ? document.originalTitle + ' (' + unreadCount + ')' : document.originalTitle;
	}, [unreadCount]);

	// It aint pretty but it works, this file is up fore som refactoring
	useEffect(() => {
		const unsub = Tools.$rootScope.$on('pushNotification.data', (e, data) => {
			onNotification(data);
		});

		return () => {
			unsub();
		};
	}, [notifications, jobs, unreadCount]);

	useEffect(() => {
		window.BabelServices.BrowserNotifications.requestPermission();

		return () => {
			setReadPromise.current?.cancel();
			getNotificationsPromise.current?.cancel();
			if (activeTimeout.current) {
				clearTimeout(activeTimeout.current);
			}
		};
	}, []);

	const onNotificationsClose = function () {
		const notificationIds = notifications.reduce((res, notification) => {
			if (!notification.read) {
				res.push(notification.id);
			}
			return res;
		}, []);

		if (notificationIds.length) {
			setNotifications(notifications.map(notification => ({ ...notification, read: true })));
			setUnreadCount(0);
			setReadPromise.current = makeCancelable(
				Tools.PushNotifications.customer(customerId).markAsRead(notificationIds)
			);
		}
	};

	const toggle = function () {
		const customerId = Tools.AppService.getCustomerId();
		openDrawer('NotificationDropdown', {
			notifications,
			onNotificationClick,
			exportUrl: getExportUrl(customerId),
			showImport,
			jobs,
			onClose: () => onNotificationsClose(notifications),
			mountDelay: 0,
			unmountDelay: 0
		});
	};

	let activeJobsIndicator = null;
	const iconClassName = classNames('notification-icon', { 'empty-notification': !unreadCount });

	const notificationAlert = React.createElement(ReactTemplates.navbar.notificationAlert, {
		notification: activeNotification
	});

	if (jobs.length) {
		activeJobsIndicator = <b id="active-job-spinner" className="fa fa-refresh fa-spin"></b>;
	}

	return (
		<NavbarButton onClick={toggle} className="notification-dropdown">
			{activeJobsIndicator || <Icon name="bell" color="super-light-green" className={iconClassName} />}
			{unreadCount ? <div className="notification-badge">{unreadCount}</div> : null}
			{notificationAlert}
		</NavbarButton>
	);
};

export default NotificationDropdown;
