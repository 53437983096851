import { globalTracker } from 'App/babel/helpers/Tracker';
import AccountCard from 'Resources/AccountCard';
import logError from 'App/babel/helpers/logError';
import { openDrawer } from 'Services/Drawer';
import UpsalesAI from 'App/babel/resources/UpsalesAI';
import { Tabs as TabsEnum, onDrawerChanged, setShouldInit } from 'Store/reducers/Prospecting';
import { Boxes } from 'Components/Prospecting/enumBoxes';
import SubaccountsColumn from 'Components/Account/AccountListContacts/Columns/SubaccountsColumn';
import store from 'Store';
import { phoneNumberHelper } from '@upsales/common';
import AboutDropDown from 'Components/AboutDropDown';
import ClientCardSplitButton from 'App/babel/components/ClientCardSplitButton';
import SubaccountSelector from 'App/pages/ClientCard/Sidebar/Summary/SubaccountSelector';
import GroupSelector from 'App/pages/ClientCard/Sidebar/Summary/GroupSelector';
import AccountPlanSidebar from 'App/components/AccountPlanSidebar';
import colorMappings from '@upsales/components/Utils/colorMappings';
import SignalsFilter from 'Resources/SignalsFilter';
import SignalsToggle from 'Components/SignalsToggle';
import AlertIcon from 'Components/AlertIcon';
import ValidationService from 'App/babel/services/ValidationService';
import { Button } from '@upsales/components';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import Prospecting from 'App/babel/resources/Prospecting';
import { comparisonTypes } from 'Resources/RequestBuilder';
import { CurrencyFormat, NumberFormat } from 'App/babel/utils/numberFormat';
import openAgreement from 'App/helpers/openSubscriptionHelper';
import { shouldUseAngularPage } from 'App/pages/ClientCard/ClientCardHelpers';
import UiElements from 'Components/UiElements';
import { getRatingTranslation } from 'Components/Misc/RatingLabel/RatingLabel';
import { getInactiveName } from 'App/helpers/priceListHelper';
import ReduxRootComponent from 'Components/ReduxComponentRoot';
import RefreshAccount from 'Components/Modals/RefreshAccount/RefreshAccount';
import {
	getAvailableCountries,
	getCountryFromProspectingId,
	getAvailableCountryFromProspectingId,
	getDefaultCurrencyFromProspectingId
} from 'App/services/prospectingService';
import TicketResource from 'App/resources/Ticket';
import ProjectPlanResource from 'Resources/ProjectPlan';

import { Equals } from 'Resources/ComparisonTypes';

import { EllipsisTooltip, Tooltip, Icon } from '@upsales/components';
import React from 'react';
import { getIntegrations, makeSearch } from 'Components/Modals/RefreshAccount/helpers';
import InlineInput from 'Components/Inputs/InlineInput';
import { makeCancelable } from 'Helpers/promise';
import AccountResource from 'App/resources/Client';
import CompanyGroup from 'App/pages/ClientCard/Sidebar/CompanyGroup/CompanyGroup';
import ClientCardContacts from 'App/pages/ClientCard/ContentPages/Contacts/Contacts';
import { Detached as ContactsTab } from 'App/pages/ContactsTab';
import { openAssignModal } from 'Components/AssignModal/AssignModal';
import { openAccountRelationModal } from 'Components/Account/AccountRelationModal/AccountRelationModal';
import { SubaccountDrawerView } from 'Components/SubaccountDrawer/SubaccountDrawer';
import LocalStorage from 'Components/Helpers/LocalStorage';
import { openNewMarketEvent } from 'App/components/NewMarketEvent/NewMarketEvent';

angular.module('domain.account').controller('Account', [
	'$scope',
	'$rootScope',
	'meta',
	'$upModal',
	'$state',
	'$translate',
	'NotificationService',
	'Account',
	'Campaign',
	'RequestBuilder',
	'Contact',
	'LatestAccountsService',
	'Activity',
	'Appointment',
	'SaveInline',
	'User',
	'Order',
	'Opportunity',
	'Agreement',
	'AccountRelation',
	'Event',
	'ActivityList',
	'MailBodyParser',
	'Mail',
	'Links',
	'accountRelations',
	'$parse',
	'$filter',
	'AppService',
	'$q',
	'ScriptService',
	'File',
	'Report',
	'FeatureHelper',
	'UdoLink',
	'UserDefinedObject',
	'Esign',
	'StandardIntegration',
	'$safeApply',
	'$stateParams',
	function (
		$scope,
		$rootScope,
		meta,
		$upModal,
		$state,
		$translate,
		NotificationService,
		Account,
		Campaign,
		RequestBuilder,
		Contact,
		LatestAccountsService,
		Activity,
		Appointment,
		SaveInline,
		User,
		Order,
		Opportunity,
		Agreement,
		AccountRelation,
		Event,
		ActivityList,
		MailBodyParser,
		Mail,
		Links,
		accountRelations,
		$parse,
		$filter,
		AppService,
		$q,
		ScriptService,
		File,
		Report,
		FeatureHelper,
		UdoLink,
		UserDefinedObject,
		Esign,
		StandardIntegration,
		$safeApply,
		$stateParams
	) {
		$scope.encodeSearchKeyword = keyword => encodeURIComponent(keyword);

		$scope.marketActivities = 0;

		// TODO(hasse) Gör om hela account med denna
		var AccountCtrl = this;
		var documentTemplates = null;
		AccountCtrl.signalsToggleClass = 'center';

		AccountCtrl.hasCountry = false;
		AccountCtrl.orderAgreementTotal = 0;
		AccountCtrl.showTabs = false;

		const metadata = AppService.getMetadata();
		const self = Tools.AppService.getSelf();
		const customerId = AppService.getCustomerId();

		var excludedStages = [];
		var accountManagers = [];
		var customFieldAlias = {
			NO_EMPLOYEES_COMPANY: 'profileData.employeesQuantityFinancialStatement1',
			TURNOVER: 'financialData.turnover1*1000',
			ORG_NO: 'generalData.countryRegistrationNumber',
			RATING: 'financialData.rating'
		};
		AccountCtrl.createElement = React.createElement;
		AccountCtrl.EllipsisTooltip = EllipsisTooltip;
		AccountCtrl.InlineInput = InlineInput;
		AccountCtrl.Tooltip = Tooltip;
		AccountCtrl.Icon = Icon;
		AccountCtrl.SubaccountsColumn = SubaccountsColumn;
		AccountCtrl.SignalsToggle = SignalsToggle;
		AccountCtrl.AlertIcon = AlertIcon;
		AccountCtrl.notesLimit = 80;
		AccountCtrl.ratingLabel = null;
		AccountCtrl.soliditetCurrency = null;
		AccountCtrl.accountManager = null;
		AccountCtrl.unsavedManagers = true;
		AccountCtrl.showAccountManagers = false;

		AccountCtrl.companyGroupCustomers = null;
		AccountCtrl.hasFolderStructure = FeatureHelper.hasSoftDeployAccess('FILE_FOLDER_STRUCTURE');
		AccountCtrl.Apps = [];

		AccountCtrl.AboutDropDown = AboutDropDown;
		AccountCtrl.AccountPlanSidebar = AccountPlanSidebar;
		AccountCtrl.Button = Button;
		AccountCtrl.ClientCardSplitButton = ClientCardSplitButton;
		AccountCtrl.findSimilarButtonText = $translate.instant('default.findSimilar');
		AccountCtrl.isTooltipText = $translate.instant('default.is').toLowerCase();
		AccountCtrl.UiElements = UiElements;

		AccountCtrl.hasReactCard = FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD');
		AccountCtrl.hasSupportService = FeatureHelper.isAvailable(FeatureHelper.Feature.CUSTOMER_SUPPORT);
		AccountCtrl.hasProjectPlan = FeatureHelper.isAvailable(FeatureHelper.Feature.PROJECT_PLAN);
		AccountCtrl.splitButtonClientCard = FeatureHelper.hasSoftDeployAccess('SPLIT_BUTTON_CLIENT_CARD');

		// Group stuff, not to be confused with subaccounts
		AccountCtrl.hasGroupBonanza = FeatureHelper.hasSoftDeployAccess('GROUP_BONANZA');
		AccountCtrl.GroupSelector = GroupSelector;
		AccountCtrl.CompanyGroupWidget = CompanyGroup;
		AccountCtrl.showCompanyGroupCustomers =
			!AccountCtrl.hasGroupBonanza && FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_BASIC);

		// Subaccount stuff
		AccountCtrl.hasSubaccounts = FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS');
		AccountCtrl.hasSubaccountsV2 = FeatureHelper.hasSoftDeployAccess('SUB_ACCOUNTS_V2');
		AccountCtrl.hasOperationalAccount = !!meta.account.data.operationalAccount;
		AccountCtrl.hasOperationalAccountSubaccountsV2 =
			AccountCtrl.hasSubaccounts && AccountCtrl.hasSubaccountsV2 && AccountCtrl.hasOperationalAccount;
		AccountCtrl.mainAccount = AccountCtrl.hasSubaccountsV2 && meta.account.data.operationalAccount;
		AccountCtrl.SubaccountSelector = SubaccountSelector;
		AccountCtrl.isSubaccount =
			(AccountCtrl.hasSubaccounts || AccountCtrl.hasSubaccountsV2) && AccountCtrl.hasOperationalAccount;
		AccountCtrl.allSubaccounts = meta.subAccountIds;

		AccountCtrl.ClientCardContacts = AccountCtrl.hasSubaccountsV2 ? ContactsTab : ClientCardContacts;
		AccountCtrl.clientIds = [meta.account.data.id, ...meta.subAccountIds];
		AccountCtrl.orgNumbers = [meta.account.data.orgNo, ...meta.subAccountOrgNumbers];

		const localStorage = new LocalStorage();

		const selectSubaccountClientIds = localStorage.getValue('filteredSubaccounts' + $stateParams.id);
		if (selectSubaccountClientIds) {
			AccountCtrl.subAccountIds = selectSubaccountClientIds;
		} else {
			AccountCtrl.subAccountIds = meta.subAccountIds;
		}

		const includeSubAccount = localStorage.getValue('includeSubaccountClientId' + $stateParams.id);
		AccountCtrl.includingSubaccountData = includeSubAccount ?? true;

		AccountCtrl.isOperationalAccount =
			AccountCtrl.hasSubaccounts && AccountCtrl.includingSubaccountData && AccountCtrl.subAccountIds.length > 0;

		AccountCtrl.headerLinks = {
			dashboard:
				AccountCtrl.hasReactCard && !shouldUseAngularPage('overview')
					? "react-root-clientCard({id: AccountCtrl.account.id, page: 'overview'})"
					: 'account.dashboard',
			contacts:
				AccountCtrl.hasReactCard && !shouldUseAngularPage('contacts')
					? "react-root-clientCard({id: AccountCtrl.account.id, page: 'contacts'})"
					: 'account.contacts',
			activities:
				AccountCtrl.hasReactCard && !shouldUseAngularPage('activities')
					? "react-root-clientCard({id: AccountCtrl.account.id, page: 'activities'})"
					: 'account.activities',
			opportunities:
				AccountCtrl.hasReactCard && !shouldUseAngularPage('opportunities')
					? "react-root-clientCard({id: AccountCtrl.account.id, page: 'opportunities'})"
					: 'account.opportunities',
			orders:
				AccountCtrl.hasReactCard && !shouldUseAngularPage('sales')
					? "react-root-clientCard({id: AccountCtrl.account.id, page: 'sales'})"
					: 'account.orders',
			financial:
				AccountCtrl.hasReactCard && !shouldUseAngularPage('financial')
					? "react-root-clientCard({id: AccountCtrl.account.id, page: 'financial'})"
					: 'account.financial',
			market:
				AccountCtrl.hasReactCard && !shouldUseAngularPage('marketing')
					? "react-root-clientCard({id: AccountCtrl.account.id, page: 'marketing'})"
					: 'account.market',
			relations:
				AccountCtrl.hasReactCard && !shouldUseAngularPage('relations')
					? "react-root-clientCard({id: AccountCtrl.account.id, page: 'relations'})"
					: 'account.relations',
			files:
				AccountCtrl.hasReactCard && !shouldUseAngularPage('documents')
					? "react-root-clientCard({id: AccountCtrl.account.id, page: 'documents'})"
					: 'account.files',
			udo:
				AccountCtrl.hasReactCard && !shouldUseAngularPage('udo')
					? "react-root-clientCard({id: AccountCtrl.account.id, page: 'udo'})"
					: 'account.udo({udoId:udo.id})',
			apps:
				AccountCtrl.hasReactCard && !shouldUseAngularPage('apps')
					? "react-root-clientCard({id: AccountCtrl.account.id, page: 'apps'})"
					: 'account.apps',
			addresses: 'account.addresses',
			support:
				AccountCtrl.hasReactCard && !shouldUseAngularPage('support') && AccountCtrl.hasSupportService
					? "react-root-clientCard({id: AccountCtrl.account.id, page: 'support'})"
					: 'account.support',
			projectPlan: 'account.projectPlan'
		};

		if (FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD')) {
			const page = $state.current.name.split('account.')[1];
			const redirectString = AccountCtrl.headerLinks[page] ?? AccountCtrl.headerLinks['dashboard'];
			const redirectToReact = redirectString?.includes('react');
			if (redirectToReact) {
				const reactPage = redirectString.split('page')[1].split("'")[1];
				Tools.$state.go('react-root-clientCard', { id: $stateParams.id, page: reactPage });
				return;
			}
		}

		AccountCtrl.getCurrentPage = () => {
			const page = $state.current.name.split('account.')[1];
			return page;
		};

		AccountCtrl.setSubaccounts = (ids, timeout = 150) => {
			AccountCtrl.subAccountIds = ids;
			localStorage.setValue('filteredSubaccounts' + $stateParams.id, ids);

			setTimeout(() => {
				const params = { filteredSubaccounts: true };
				Tools.$rootScope.$broadcast('account.refresh', params);
			}, timeout);
		};

		AccountCtrl.isThisAccountOrSubaccounts = clientEntity =>
			clientEntity?.id &&
			(clientEntity.id === AccountCtrl.account.id ||
				(AccountCtrl.hasSubaccounts &&
					AccountCtrl.includingSubaccountData &&
					AccountCtrl.subAccountIds?.includes(clientEntity.id)));

		var getStandardIntegrations = function (integrationIds) {
			var filters = new RequestBuilder();
			filters.addFilter({ field: 'id' }, filters.comparisonTypes.Equals, integrationIds);
			filters.fields = ['id', 'configJson', 'name', 'color', 'imageLink'];

			StandardIntegration.find(filters.build())
				.then(function (response) {
					response.data.forEach(integration => {
						var cfg = {};

						try {
							cfg = JSON.parse(integration.configJson);
						} catch (error) {
							console.log('couldnt parse config');

							try {
								cfg = JSON.parse(JSON.stringify(integration.configJson));
							} catch (error2) {
								console.log('couldnt parse a stringifed config');
							}
						}

						if (typeof cfg.iframe === 'boolean' && cfg.iframe === true) {
							AccountCtrl.Apps.push({
								id: integration.id,
								integrationId: integration.id,
								name: integration.name,
								color: integration.color,
								imageLink: integration.imageLink
							});
						}
					});
				})
				.catch(e => {
					logError(e, 'Failed to load standard integrations', { integrationIds });
				});
		};

		AccountCtrl.soliditetIsActive = self.userParams.soliditetIsActive;
		const hasActiveDataSources = metadata.integrations.inits?.dataSource?.length ? true : false;
		const hasProspecting = FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_BASIC);
		AccountCtrl.showRefreshAccount = hasActiveDataSources || AccountCtrl.soliditetIsActive || hasProspecting;
		AccountCtrl.hasGroupTree =
			FeatureHelper.isAvailable(FeatureHelper.Feature.GROUP_SIZE) ||
			FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO);
		AccountCtrl.hasProspectingPro = FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO);
		const groupedIntegrations = _.groupBy(metadata.integrations.active, 'userConfigurable');

		// Non user configurable integrations
		if (groupedIntegrations.false) {
			getStandardIntegrations(groupedIntegrations.false.map(integration => integration.id));
		}

		// User configurable integrations
		if (groupedIntegrations.true) {
			const promises = groupedIntegrations.true.map(integration =>
				StandardIntegration.userSetting(customerId)
					.get(integration.id)
					.then(userSettingResponse => {
						if (userSettingResponse.data && userSettingResponse.data.active) {
							return integration.id;
						}
						return;
					})
					.catch(e => {
						if (e && e.status === 404) {
							// User has not activated this app
							return;
						}
						logError(e, 'Failed to load standard integration settings', { integrationId: integration.id });
					})
			);

			Promise.all(promises)
				.then(integrationIds => {
					// remove nullish values
					const filteredIntegrations = integrationIds.filter(integrationId => integrationId);
					if (filteredIntegrations.length) {
						getStandardIntegrations(filteredIntegrations);
					}
				})
				.catch(e => {
					logError(e, 'Failed to load standard integration settings');
				});
		}

		$scope.$on('account.refresh', (event, params = {}) => {
			// https://ui-router.github.io/ng1/docs/0.2.18/#/api/ui.router.state.$state
			$state.transitionTo($state.current, { ...$state.params, ...params }, { reload: true, inherit: false });
		});

		$scope.$on('account.subaccountsAdded', (event, { clients }) => {
			const additionalIds = clients.map(client => client.id);

			AccountCtrl.allSubaccounts = [...AccountCtrl.allSubaccounts, ...additionalIds];
			AccountCtrl.subAccountIds = [...AccountCtrl.subAccountIds, ...additionalIds];

			AccountCtrl.setSubaccounts(AccountCtrl.subAccountIds, 1_500);
		});

		$scope.$on('account.updated', function (e, updated) {
			// update this account if this was edited
			if (updated.id === AccountCtrl.account.id) {
				// var oldCustom = AccountCtrl.account.custom;
				// angular.forEach(oldCustom, function(field) {
				// 	var updatedField = _.find(updated.custom, {'fieldId': field.id});
				// 	field.value = updatedField ? updatedField.value : null;
				// });
				AccountCtrl.account = angular.extend({}, AccountCtrl.account, updated);
				if (!AccountCtrl.multipleAccountManagers && Array.isArray(AccountCtrl.account.users)) {
					AccountCtrl.accountManager = AccountCtrl.account.users[0];
				}
				if (AccountCtrl.hasSubaccounts && updated.hasOwnProperty('operationalAccount')) {
					AccountCtrl.isSubaccount = !!updated.operationalAccount;
				}

				getRatingLabel();
				getNORelatedClients();
				getLinks();

				generateCustomFields(Object.values(AccountCtrl.account.$mappedCustom));
				mapCustomFields();

				fixWebpage();
				handleSocialLinks();
				AccountCtrl.fetchSimilarCompanies();

				if (AccountCtrl.account.priceList && !AccountCtrl.account.priceList.active) {
					AccountCtrl.account.priceList.name = getInactiveName(AccountCtrl.account.priceList.name);
					$safeApply($scope);
				}
			}
		});

		$scope.$on('account.merged', function (e, res) {
			if (res.merged.id === AccountCtrl.account.id) {
				AccountCtrl.refetchAccount();

				// Reload stuff
				getContacts(); // this also sets NO. contacts for the menu
				getNORelatedClients();
				getLinks();
				getNOOngoingProjects();
				getNOProjects();

				// get NO. of activities
				getNOActivities();

				// get NO. of opportunities
				getNOOpportunities();

				// get NO. of order
				getNOOrder();

				getNOMarketEvents();
				sumRecentScore();

				getFilesAndEsign();

				fixWebpage();
				generateCustomFields(AccountCtrl.account.custom);

				handleSocialLinks();
			}
		});

		// EVENTS

		// Added events changes
		$scope.$on('activity.added', function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client)) {
				setTimeout(function () {
					getNOActivities();
				}, 700);
			}
		});

		$scope.$on('activity.updated', function (e, updated) {
			if (AccountCtrl.isThisAccountOrSubaccounts(updated.client)) {
				setTimeout(function () {
					getNOActivities();
				}, 700);
			}
		});

		$scope.$on('appointment.added', function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client)) {
				setTimeout(function () {
					getNOActivities();
				}, 700);
			}
		});

		$scope.$on('appointment.updated', function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client)) {
				setTimeout(function () {
					getNOActivities();
				}, 700);
			}
		});

		$scope.$on('opportunity.added', function (e, added) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(added.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(added.clientConnection)
			) {
				getNOOpportunities();
			}
		});

		$scope.$on('order.added', function (e, added) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(added.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(added.clientConnection)
			) {
				if (added.probability === 100) {
					setTimeout(function () {
						getNOOrder();
					}, 700);
				} else {
					setTimeout(function () {
						getNOOpportunities();
					}, 700);
				}
			}
		});

		$scope.$on('contact.added', function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client)) {
				AccountCtrl.contactsTotal++;
				if (added.active) {
					AccountCtrl.onlyInactiveContacts = false;
				}
				AccountCtrl.showContactTab =
					((Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC) ||
						Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO)) &&
						AccountCtrl.account.prospecting &&
						AccountCtrl.account.prospecting.directors) ||
					AccountCtrl.contactsTotal > 0;
			}
		});

		$scope.$on('agreement.added', function (e, added) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(added.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(added.clientConnection)
			) {
				setTimeout(function () {
					getNOOrder();
				}, 700);
			}
		});

		$scope.$on('projectPlan.added', function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client)) {
				setTimeout(function () {
					getNOOngoingProjects();
				}, 700);
			}
		});

		$scope.$on('agreement.updated', function (e, updated) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(updated.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(updated.clientConnection)
			) {
				setTimeout(function () {
					getNOOrder();
				}, 700);
			}
		});

		$scope.$on('mail.added', function (e, added) {
			if (AccountCtrl.isThisAccountOrSubaccounts(added.client)) {
				AccountCtrl.activitiesTotal++;
				if (added.type !== 'sch') {
					AccountCtrl.marketActivities++;
				}
			}
		});

		$scope.$on('ticket.added', function () {
			getNOSupportTickets();
		});

		$scope.$on('ticket.updated', function () {
			getNOSupportTickets();
		});

		$scope.$on('projectPlan.updated', function () {
			getNOOngoingProjects();
		});

		// Deleted events
		$scope.$on('mail.deleted', function (e, deleted) {
			if (AccountCtrl.isThisAccountOrSubaccounts(deleted.client)) {
				setTimeout(function () {
					getNOActivities();
				}, 700);
			}
		});

		$scope.$on('activity.deleted', function (e, deleted) {
			if (AccountCtrl.isThisAccountOrSubaccounts(deleted.client)) {
				setTimeout(function () {
					getNOActivities();
				}, 700);
			}
		});

		$scope.$on('appointment.deleted', function (e, deleted) {
			if (AccountCtrl.isThisAccountOrSubaccounts(deleted.client)) {
				setTimeout(function () {
					getNOActivities();
				}, 700);
			}
		});

		$scope.$on('order.deleted', function (e, deleted) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(deleted.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(deleted.clientConnection)
			) {
				setTimeout(function () {
					getNOOpportunities();
					getNOOrder();
				}, 700);
			}
		});

		$scope.$on('opportunity.deleted', function (e, deleted) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(deleted.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(deleted.clientConnection)
			) {
				setTimeout(function () {
					getNOOpportunities();
				}, 700);
			}
		});

		$scope.$on('agreement.deleted', function (e, deleted) {
			if (
				AccountCtrl.isThisAccountOrSubaccounts(deleted.client) ||
				AccountCtrl.isThisAccountOrSubaccounts(deleted.clientConnection)
			) {
				AccountCtrl.agreementTotal--;
				AccountCtrl.orderAgreementTotal--;
				setTimeout(() => {
					getNOOrder();
				}, 700);
			}
		});

		$scope.$on('ticket.deleted', function () {
			getNOSupportTickets();
		});

		$scope.$on('file.uploaded', function (e, file) {
			if (AccountCtrl.isThisAccountOrSubaccounts(file.client)) {
				AccountCtrl.filesTotal++;
			}
			$safeApply($scope);
		});

		$scope.$on('file.deleted', function (e, deleted) {
			if (AccountCtrl.isThisAccountOrSubaccounts(deleted.client)) {
				AccountCtrl.filesTotal--;
			}
		});

		$scope.$on('esign.deleted', function (e, deleted) {
			if (AccountCtrl.isThisAccountOrSubaccounts(deleted.client)) {
				AccountCtrl.filesTotal--;
			}
		});

		$scope.$on('esign.updated', function (e, esign) {
			if (AccountCtrl.isThisAccountOrSubaccounts(esign.client)) {
				setTimeout(function () {
					getFilesAndEsign();
				}, 700);
			}
		});

		$scope.$on('contact.deleted', function (e, contact) {
			AccountCtrl.showContactTab =
				((Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC) ||
					Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO)) &&
					AccountCtrl.account.prospecting &&
					AccountCtrl.account.prospecting.directors) ||
				AccountCtrl.contactsTotal > 0;
			/**
			 * TODO: Make this work for normal accounts and subaccounts
			 * I don't think this ever worked, not at least with the List component it is using now
			 * It is receiving just the contact id, not the client.
			 * The same happens with AccountPlans and UDO
			 */
			if (AccountCtrl.isThisAccountOrSubaccounts(contact.client)) {
				setTimeout(function () {
					getContacts();
				}, 700);
			}
		});
		$scope.$on('opportunity.updated', function (e, updated) {
			if (AccountCtrl.isThisAccountOrSubaccounts(updated.client)) {
				setTimeout(function () {
					getNOOpportunities();
					getNOOrder();
				}, 700);
			}
		});

		$scope.$on('order.updated', function (e, updated) {
			if (AccountCtrl.isThisAccountOrSubaccounts(updated.client)) {
				setTimeout(function () {
					getNOOpportunities();
					getNOOrder();
				}, 700);
			}
		});

		$scope.$on('account.resetScore', function (e, clientId) {
			if (AccountCtrl.isThisAccountOrSubaccounts({ id: clientId })) {
				setTimeout(function () {
					AccountCtrl.account.score = 0;
					// marketScoreAllTime will be updated from getNOMarketEvents
					AccountCtrl.account.assigned = null;
					getNOMarketEvents();
					sumRecentScore();
				}, 700);
			}
		});

		// Updated events
		$scope.$on('contact.updated', function (e, updated) {
			if (AccountCtrl.isThisAccountOrSubaccounts(updated.client)) {
				var contact = _.find(AccountCtrl.contacts, { id: updated.id });
				getContacts();

				if (contact) {
					// Update if active - else remove it from active contacts
					if (updated.active) {
						angular.extend(contact, updated);
					} else {
						var i = _.findIndex(AccountCtrl.contacts, { id: updated.id });
						AccountCtrl.contacts.splice(i, 1);
					}
				}
			}
		});

		$scope.$on('account.assigned', function (e, res) {
			if (AccountCtrl.isThisAccountOrSubaccounts(res.account)) {
				getNOMarketEvents();

				if (res.user) {
					AccountCtrl.account.assigned = !AccountCtrl.account.assigned ? {} : AccountCtrl.account.assigned;
					AccountCtrl.account.assigned.user = res.user;
				}
			}
		});

		$scope.$on('market.added', function (e, res) {
			if (AccountCtrl.isThisAccountOrSubaccounts(res?.client)) {
				AccountCtrl.account.score += res.score;
				getNOMarketEvents();
				sumRecentScore();
			}
		});

		$scope.$on('account.unassigned', function (e, accountId) {
			if (AccountCtrl.isThisAccountOrSubaccounts({ id: accountId })) {
				getNOMarketEvents();
			}
		});

		$scope.$on('accountRelation.deleted', function (e, deleted) {
			var index = _.findIndex(AccountCtrl.account.connectedClients, { id: deleted.id });

			if (index !== -1) {
				AccountCtrl.account.connectedClients.splice(index, 1);
				AccountCtrl.relationsTotal--;
			}
		});

		$scope.$on('accountRelation.added', function (e, added) {
			if (added.clientId === AccountCtrl.account.id || added.relatedToClientId === AccountCtrl.account.id) {
				AccountCtrl.account.connectedClients.push(added);
				AccountCtrl.relationsTotal++;
			}
		});

		$scope.$on('accountRelation.updated', function (e, updated) {
			if (updated.clientId === AccountCtrl.account.id || updated.relatedToClientId === AccountCtrl.account.id) {
				var index = _.findIndex(AccountCtrl.account.connectedClients, { id: updated.id });

				if (index !== -1) {
					angular.extend(AccountCtrl.account.connectedClients[index], updated);
				} else {
					AccountCtrl.account.connectedClients.push(updated);
					AccountCtrl.relationsTotal++;
				}
			}
		});

		$scope.$on('esign.added', function (e, esign) {
			if (AccountCtrl.isThisAccountOrSubaccounts(esign.client)) {
				AccountCtrl.filesTotal++;
			}
		});

		$scope.$on('addresses.updated', function () {
			AccountCtrl.visitAddress = _.find(AccountCtrl.account.addresses, { type: 'Visit' });
		});

		$scope.$on('addresses.removed', function () {
			AccountCtrl.visitAddress = _.find(AccountCtrl.account.addresses, { type: 'Visit' });
		});

		function handleSocialLinks() {
			AccountCtrl.hasStandardFieldLinkedIn = false;

			$scope.standardFields.social = _.filter(metadata.standardFields.Client, function (field) {
				var firstCheck = field.group === 'social' && field.active;
				var secondCheck = AccountCtrl.account[field.field] && AccountCtrl.account[field.field].length;
				if (firstCheck && secondCheck && field.name === 'LinkedIn') {
					AccountCtrl.hasStandardFieldLinkedIn = true;
				}

				return firstCheck && secondCheck;
			});
		}

		function visibleCustomFields(fields) {
			return _.filter(_.cloneDeep(fields), function (field) {
				if (!field.visible || !field.$hasAccess) {
					return false;
				}
				if (!field.value || field.value === '') {
					return false;
				}
				if (field.datatype === 'Link') {
					return false;
				}
				var soliditetPath = customFieldAlias[field.alias];
				if (field.alias && soliditetPath) {
					// Never show rating
					if (field.alias === 'RATING') {
						return false;
					}

					var value = $parse(soliditetPath)(AccountCtrl.account.soliditet);

					if (value) {
						field.$$oldValue = field.value;
						field.value = value;
					}
				}
				return field.value !== undefined && field.value !== null;
			});
		}

		function generateCustomFields(customFields) {
			AccountCtrl.customFields = visibleCustomFields(customFields);
			AccountCtrl.canShowFormGroups = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.FORM_GROUPS);
			if (AccountCtrl.canShowFormGroups) {
				AccountCtrl.formGroups = Array.from(
					AccountCtrl.customFields
						.sort((a, b) => a.sortId - b.sortId)
						.reduce((previous, field) => {
							previous.add(field.formGroup);
							return previous;
						}, new Set())
				);
			} else {
				AccountCtrl.formGroups = [null];
			}
		}

		// Update rating label
		function getRatingLabel() {
			if (AccountCtrl.account.creditRating) {
				AccountCtrl.ratingLabel = {
					split: AccountCtrl.account.creditRating.split(' - ')[0],
					full: AccountCtrl.account.creditRating
				};
				return;
			} else if (
				AccountCtrl.account.soliditet &&
				AccountCtrl.account.soliditet.financialData &&
				AccountCtrl.account.soliditet.financialData.rating
			) {
				AccountCtrl.ratingLabel = {
					split: AccountCtrl.account.soliditet.financialData.rating.split(' - ')[0],
					full: getRatingTranslation(AccountCtrl.account.soliditet.financialData.rating)
				};
				return;
			} else {
				var ratingCustomField = _.find(AccountCtrl.account.custom, { alias: 'RATING' });

				if (ratingCustomField && ratingCustomField.value) {
					AccountCtrl.ratingLabel = {
						split: ratingCustomField.value.split(' - ')[0],
						full: ratingCustomField.value
					};
					return;
				}
			}
			AccountCtrl.ratingLabel = null;
		}

		var convertSum = function (potent, absVal) {
			var weightValue = Math.pow(10, potent);
			var res = absVal / weightValue;
			var ceilRes = Math.ceil(res);

			if (ceilRes - res >= 0.5) {
				ceilRes -= 0.5;
			}
			return ceilRes * weightValue;
		};

		var convertToMillion = function (value) {
			var absVal = Math.abs(value);
			var potent = Math.floor(Math.log10(absVal));

			if (potent >= 8) {
				return convertSum(8, absVal);
			} else if (potent > 4) {
				return convertSum(potent, absVal);
			} else {
				return convertSum(4, absVal);
			}
		};

		function mapCustomFields() {
			// get fields
			$scope.links = [];

			angular.forEach(AccountCtrl.account.$mappedCustom, function (field) {
				if (field.datatype === 'Link' && typeof field.value === 'string' && field.value) {
					$scope.links.push(field);
				}
			});
		}

		AccountCtrl.saveContact = function (data) {
			const saveContact = { id: AccountCtrl.account.id, ...data };
			makeCancelable(AccountResource.save(saveContact));
		};

		function generateChart(currency, data, categories, onClick = () => {}) {
			const currencyFormat = new CurrencyFormat(currency);
			const numberFormat = new NumberFormat();
			AccountCtrl.economyChart = {
				title: { text: '' },
				subtitle: { text: null },
				size: { width: '', height: '' },
				credits: { enabled: false },
				options: {
					legend: {
						enabled: true,
						verticalAlign: 'top',
						symbolHeight: 9,
						symbolWidth: 9,
						itemStyle: {
							fontSize: '10px'
						},
						width: 250,
						labelFormatter: function () {
							return (
								$translate.instant('default.' + this.name) +
								' <span style="color:#6B7C93; font-size:8px"> ' +
								currency +
								'</span>'
							);
						}
					},
					chart: {
						type: 'column',
						backgroundColor: 'transparent',
						marginLeft: 45,
						marginRight: 10,
						spacingBottom: 0,
						spacingTop: 0,
						events: {
							click: function () {
								onClick();
							}
						}
					},
					colors: [colorMappings.get('bright-green'), colorMappings.get('green')],
					plotOptions: {
						series: {
							dataLabels: {
								enabled: false,
								style: { color: '#1E252E' },
								staggerLines: 3,
								formatter: function () {
									if (parseInt(this.series.dataMax) === parseInt(this.y)) {
										return this.y;
									} else {
										return '';
									}
								}
							}
						},
						column: {
							pointPadding: 0,
							borderWidth: 1
						}
					},
					tooltip: {
						borderColor: 'transparent',
						backgroundColor: '#1E252E',
						shadow: false,
						useHTML: true,
						style: {
							position: 'absolute',
							zIndex: 10000,
							padding: 0,
							left: 0,
							right: 0
						},
						positioner: function () {
							return { x: 0, y: 0 };
						},
						formatter: function () {
							var style =
								'left: 0; right: 0; width: 280px; text-align: center; padding: 5px; background-color: #1E252E; font-size: 11px; color: #fff; border-radius: 5px; display: block;';
							return (
								'<div style="' +
								style +
								'">' +
								$translate.instant('default.' + this.series.name) +
								' ' +
								this.key +
								': ' +
								'<b>' +
								currencyFormat.short(this.y) +
								'</b></div>'
							);
						}
					}
				},
				yAxis: {
					gridLineWidth: 1,
					allowDecimals: false,
					endOnTick: true,
					minorGridLineWidth: 0,
					gridLineDashStyle: 'Dash',
					tickPositioner: function () {
						var firstValue;
						var lastValue;
						if (this.dataMax < 0) {
							firstValue = convertToMillion(this.dataMin / 2);
							lastValue = convertToMillion(this.dataMin);
							return [-lastValue, -firstValue, 0];
						} else if (this.dataMax > 0 && this.dataMin < 0) {
							firstValue = convertToMillion(this.dataMin);
							lastValue = convertToMillion(this.dataMax);
							return [-firstValue, 0, lastValue];
						}

						lastValue = convertToMillion(this.dataMax);
						firstValue = lastValue / 2;
						return [0, firstValue, lastValue];
					},
					labels: {
						enabled: true,
						style: { textAlign: 'right', fontSize: '10px', whiteSpace: 'nowrap' },
						x: -3,
						y: 3,
						formatter: function () {
							return numberFormat.short(this.value, 1);
						}
					},
					title: { enabled: false }
				},
				xAxis: {
					type: 'category',
					labels: {
						rotation: 0,
						y: 10,
						style: { fontSize: '9px' }
					},
					lineWidth: 1,
					lineColor: '#A4B3C7',
					tickWidth: 0,
					categories: categories
				},
				series: data
			};
		}

		var buildEconomyChart = function () {
			var soliditetObj = AccountCtrl.account.soliditet;
			var currency = null;
			const hasProspectingFinancialData =
				meta.account.data.prospecting?.accounts?.length > 0 && !!meta.account.data.prospecting.headquarters;

			if (soliditetObj?.financialData || hasProspectingFinancialData) {
				if (hasProspectingFinancialData) {
					AccountCtrl.showEconomyChart = true;

					const prospectingTurnover = [];
					const prospectingResult = [];
					const years = [];
					const sortedAccounts = meta.account.data.prospecting.accounts.sort((a, b) =>
						moment(a.dateTo) < moment(b.dateTo) ? 1 : -1
					);

					const currency = getDefaultCurrencyFromProspectingId(meta.account.data.prospectingId);

					for (const account of sortedAccounts) {
						prospectingTurnover.push(account.plNetOperatingIncome);
						prospectingResult.push(account.plProfLossAfterFinItems);
						years.push(moment(account.dateTo).format('YYYY'));
					}
					while (years.length < 5) {
						years.push(
							moment(years[years.length - 1] + '-01-01')
								.subtract(1, 'year')
								.year()
						);
						prospectingResult.push(0);
						prospectingTurnover.push(0);
					}
					const data = [];
					data.push({
						type: 'column',
						name: 'turnover',
						data: prospectingTurnover.map(value => value * 1000)
					});

					data.push({
						type: 'column',
						name: 'result',
						data: prospectingResult.map(value => value * 1000)
					});

					generateChart(currency, data, years, () => {
						if (FeatureHelper.hasSoftDeployAccess('REACT_CLIENT_CARD')) {
							$state.go('react-root-clientCard', { id: meta.account.data.id, page: 'financial' });
						} else {
							$state.go('account.financial', { id: meta.account.data.id });
						}
					});
				} else if (
					(parseInt(soliditetObj.financialData.turnover1) === 0 &&
						parseInt(soliditetObj.financialData.turnover2) === 0 &&
						parseInt(soliditetObj.financialData.turnover3) === 0 &&
						parseInt(soliditetObj.financialData.turnover4) === 0 &&
						parseInt(soliditetObj.financialData.turnover5) === 0 &&
						parseInt(soliditetObj.financialData.resultAfterFinancialItems1) === 0 &&
						parseInt(soliditetObj.financialData.resultAfterFinancialItems2) === 0 &&
						parseInt(soliditetObj.financialData.resultAfterFinancialItems3) === 0 &&
						parseInt(soliditetObj.financialData.resultAfterFinancialItems4) === 0 &&
						parseInt(soliditetObj.financialData.resultAfterFinancialItems5) === 0) ||
					soliditetObj.financialData.annualReportPeriod1 === null ||
					soliditetObj.financialData.annualReportPeriod1 === undefined ||
					soliditetObj.financialData.annualReportPeriod2 === null ||
					soliditetObj.financialData.annualReportPeriod2 === undefined ||
					soliditetObj.financialData.annualReportPeriod3 === null ||
					soliditetObj.financialData.annualReportPeriod3 === undefined ||
					soliditetObj.financialData.annualReportPeriod4 === null ||
					soliditetObj.financialData.annualReportPeriod4 === undefined ||
					soliditetObj.financialData.annualReportPeriod5 === null ||
					soliditetObj.financialData.annualReportPeriod5 === undefined
				) {
					AccountCtrl.showEconomyChart = false;
				} else {
					AccountCtrl.showEconomyChart = true;

					switch (soliditetObj.generalData.countryName) {
						case 'Sverige':
							currency = 'SEK';
							break;
						case 'Norge':
							currency = 'NOK';
							break;
						case 'Danmark':
							currency = 'DKK';
							break;
						case 'Finland':
							currency = 'EUR';
							break;
						default:
							currency = 'SEK';
							break;
					}

					// Get year range
					var columns = [];
					for (var i = 1; i < 6; i++) {
						var year = soliditetObj.financialData['annualReportPeriod' + i];
						columns.push(year);
					}

					var haveTurnOver = false,
						haveResult = false;
					for (var x = 1, y = 5; x <= y; x++) {
						if (!haveTurnOver && soliditetObj.financialData['turnover' + x] > 0) {
							haveTurnOver = true;
						}

						if (!haveResult && soliditetObj.financialData['resultAfterFinancialItems' + x] !== 0) {
							haveResult = true;
						}
					}
					var data = [];
					if (haveTurnOver) {
						data.push({
							type: 'column',
							name: 'turnover',
							data: [
								soliditetObj.financialData.turnover1 * 1000,
								soliditetObj.financialData.turnover2 * 1000,
								soliditetObj.financialData.turnover3 * 1000,
								soliditetObj.financialData.turnover4 * 1000,
								soliditetObj.financialData.turnover5 * 1000
							]
						});
					}
					if (haveResult) {
						data.push({
							type: 'column',
							name: 'result',
							data: [
								soliditetObj.financialData.resultAfterFinancialItems1 * 1000,
								soliditetObj.financialData.resultAfterFinancialItems2 * 1000,
								soliditetObj.financialData.resultAfterFinancialItems3 * 1000,
								soliditetObj.financialData.resultAfterFinancialItems4 * 1000,
								soliditetObj.financialData.resultAfterFinancialItems5 * 1000
							]
						});
					}
					generateChart(currency, data, columns);
				}
			} else {
				AccountCtrl.showEconomyChart = false;
			}
		};

		// return true if current state is same as provided
		AccountCtrl.isState = function (state) {
			return $state.is('account.' + state);
		};

		AccountCtrl.getSignalsToggleTooltip = function () {
			const enabled = AccountCtrl.isSignalsEnabled;
			const disabled = false;
			if (enabled && !disabled) {
				return 'account.signals.enabled';
			} else if (!enabled && !disabled) {
				return 'account.signals.disabled';
			} else {
				return '';
			}
		};

		AccountCtrl.toggleSignals = function () {
			const clientId = AccountCtrl.account.id;
			const enabled = AccountCtrl.isSignalsEnabled;
			AccountCtrl.isSignalsEnabled = !enabled;
			let promise;
			if (enabled) {
				promise = SignalsFilter.excludeClient(clientId);
			} else {
				promise = SignalsFilter.includeClient(clientId);
			}
			promise
				.then(() => {
					return $rootScope.$broadcast('signals.toggle', !enabled);
				})
				.catch(err => {
					logError(err, 'could not toggle prospecting signals');
				});
		};

		function getCompanyGroupCustomers(childCompanies) {
			return [
				// companies which match, returned in a compact format
				...childCompanies
					.filter(child => child.matchInUpsales && child.matchInUpsales.journeyStep === 'customer')
					.map(matchingCompany => ({
						name: matchingCompany.name,
						type: matchingCompany.matchInUpsales.journeyStep
					})),
				// recursively continue in children
				...childCompanies.flatMap(child => getCompanyGroupCustomers(child.children))
			];
		}

		AccountCtrl.loadCompanyGroupCustomers = async function () {
			if (AccountCtrl.account.prospectingId) {
				const rb = new RequestBuilder();
				rb.addFilter({ field: 'prospectingId' }, comparisonTypes.Equals, AccountCtrl.account.prospectingId);
				rb.extraParams.push({
					key: 'country',
					value: getCountryFromProspectingId(AccountCtrl.account.prospectingId)
				});
				try {
					const res = await Prospecting.findGroupstructure(rb.build());
					AccountCtrl.companyGroupCustomers = getCompanyGroupCustomers(res.data);
				} catch (e) {
					AccountCtrl.showCompanyGroupCustomers = false;
				}
			} else {
				AccountCtrl.showCompanyGroupCustomers = false;
			}

			$safeApply($scope);
		};

		const tooltipHint = AccountCtrl.hasProspectingPro
			? $translate.instant('account.companyGroup.clickToShow')
			: $translate.instant('account.companyGroup.buyAddonHint');

		AccountCtrl.getCompanyGroupCustomersTooltip = function () {
			const showHint = !!AccountCtrl.companyGroupCustomers;

			if (AccountCtrl.companyGroupCustomers.length === 0) {
				return showHint ? tooltipHint : '';
			}

			return (
				$translate.instant('account.companyGroup.customersInGroup') +
				'\n' +
				AccountCtrl.companyGroupCustomers.map(c => '• ' + c.name).join('\n') +
				(showHint ? '\n\n(' + tooltipHint + ')' : '')
			);
		};

		AccountCtrl.hasGroupSize = function () {
			if (AccountCtrl.account.prospecting?.groupSize > 0 && AccountCtrl.account.prospectingId) {
				return true;
			}
			return false;
		};

		AccountCtrl.hasParent = function () {
			if (
				AccountCtrl.hasGroupBonanza &&
				AccountCtrl.account.prospecting?.groupSize > 1 &&
				AccountCtrl.account.prospectingId
			) {
				return false;
			}

			// In company database
			if (AccountCtrl.account.parent && AccountCtrl.account.parent.id !== 0) return true;

			// Creditsafe
			if (
				AccountCtrl.account.prospecting?.parent &&
				AccountCtrl.account.prospecting?.parent?.prospectingId.startsWith('SE')
			)
				return true;

			// Soliditet
			if (
				AccountCtrl.account.soliditet?.profileData?.parentCompanyName &&
				AccountCtrl.account.soliditet?.profileData?.parentCompanyDunsNumber
			)
				return true;
			return false;
		};

		AccountCtrl.refetchAccount = async function () {
			const { data } = await Account.customer(customerId).get(AccountCtrl.account.id);
			if (data) {
				AccountCtrl.account = data;
				$safeApply($scope);
			}
		};

		AccountCtrl.parentCompanyClick = function () {
			// Go to existing parent
			if (AccountCtrl.account.parent) {
				return $state.go('account.dashboard', { id: AccountCtrl.account.parent.id });
			}
			// Check for company in db

			const prospectingId = AccountCtrl.account.prospecting?.parent?.prospectingId;
			const duns = AccountCtrl.account.soliditet?.profileData?.parentCompanyDunsNumber;
			const filters = new RequestBuilder();
			filters.limit = 1;
			if (prospectingId) {
				filters.addFilter(Account.attr.prospectingId, 'eq', prospectingId);
				filters.field = ['prospectingId'];
			} else if (duns) {
				filters.addFilter(Account.attr.dunsNo, filters.comparisonTypes.Equals, duns.toString());
				filters.fields = ['id'];
			}

			Account.customer(customerId)
				.find(filters.build())
				.then(function (results) {
					// If results
					if (results.data.length) {
						// Save account with found parent and go there
						var data = { id: AccountCtrl.account.id, parent: { id: results.data[0].id } };
						Account.customer(customerId).save(data, {
							skipNotification: true,
							skipErrorNotification: true
						});
						$state.go('account.dashboard', { id: results.data[0].id });
					} else {
						if (prospectingId) {
							// eslint-disable-next-line promise/catch-or-return
							$upModal
								.open('confirmParentProspecting', {
									customerId: customerId,
									name: AccountCtrl.account.prospecting.parent?.name,
									prospectingId: prospectingId
								})
								.then(account => {
									const data = { id: AccountCtrl.account.id, parent: { id: account.id } };
									Account.customer(customerId).save(data, {
										skipNotification: true,
										skipErrorNotification: true
									});
									// Go to account
									$state.go('account.dashboard', { id: account.id });
								});
						} else {
							// Ask to buy the account from soliditet
							// eslint-disable-next-line promise/catch-or-return
							$upModal
								.open('confirmSoliditetBuyParent', {
									customerId: customerId,
									name: AccountCtrl.account.soliditet.profileData.parentCompanyName,
									duns: duns
								})
								.then(function (account) {
									// Set parent on account after buy
									const data = { id: AccountCtrl.account.id, parent: { id: account.id } };
									Account.customer(customerId).save(data, {
										skipNotification: true,
										skipErrorNotification: true
									});
									// Go to account
									$state.go('account.dashboard', { id: account.id });
								});
						}
					}
				})
				.catch(e => {
					logError(e, 'Failed to get account', { filters: filters.build() });
				});
		};

		AccountCtrl.parseSubject = function (mail) {
			return MailBodyParser.parseSubject(mail);
		};

		AccountCtrl.refreshAccount = function (extraProps = {}) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RefreshAccount', {
					className: 'form-md allow-overflow',
					account: AccountCtrl.account,
					name: AccountCtrl.account.name,
					customerId: customerId,
					accountId: AccountCtrl.account.id,
					duns: AccountCtrl.account.dunsNo,
					onClose: account => {
						if (account) {
							Tools.$state.reload();
						}
					},
					...extraProps
				});
			} else {
				const component = props => <ReduxRootComponent component={RefreshAccount} {...props} />;
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open(
						'alert',
						Object.assign(
							{
								className: 'form-md allow-overflow',
								dialog: component,
								account: AccountCtrl.account,
								name: AccountCtrl.account.name,
								customerId: customerId,
								accountId: AccountCtrl.account.id,
								duns: AccountCtrl.account.dunsNo
							},
							extraProps
						)
					)
					.then(function () {
						Tools.$state.reload();
					});
			}
		};

		$scope.checkRights = function () {
			var state = $state.$current.toString().split('.')[1];
			switch (state) {
				case 'orders':
					return AccountCtrl.account.createRights.Order;
				case 'opportunities':
					return AccountCtrl.account.createRights.Opportunity;
				case 'contacts':
					return AccountCtrl.account.createRights.Contact;
				case 'activities':
					return AccountCtrl.account.createRights.Activity;
				case 'addresses':
					return true;
				case 'financial':
					return true;
			}
		};

		AccountCtrl.track = function (type) {
			globalTracker.track('add', { type: type, location: 'account_card' });
		};

		AccountCtrl.openContact = function (id) {
			return $state.go('contact.dashboard', { id: id });
		};

		AccountCtrl.openAdCampaign = function (id) {
			return $state.go('viewAd.dashboard', { id: id });
		};

		AccountCtrl.openAccount = function (id) {
			return $state.go('account.dashboard', { id: id });
		};

		AccountCtrl.openAddresses = function () {
			return $state.go('account.addresses', { id: AccountCtrl.account.id });
		};

		AccountCtrl.createDocument = function (obj, type, e) {
			if (e) {
				e.stopPropagation();
			}
			if (!obj) {
				obj = AccountCtrl.account;
				type = 'account';
			}

			var opts = {
				type: type,
				id: obj.id,
				templates: documentTemplates[type],
				accountId: AccountCtrl.account.id
			};

			if (type === 'order' && obj.contact) {
				opts.contactId = obj.contact.id;
			}
			if (Tools.FeatureHelper.hasSoftDeployAccess('CREATE_DOCUMENT_REACT')) {
				openModal('CreateDocumentModal', opts);
			} else {
				$upModal.open('createDocument', opts);
			}
		};

		AccountCtrl.editOrder = function (order) {
			var options = {
				customerId: customerId
			};

			if (order) {
				options.id = order.id || order.entityId;
			} else {
				options.clientId = AccountCtrl.account.id;
			}

			$upModal.open('editOrder', options);
		};

		AccountCtrl.editEsign = function (esign) {
			var options = {
				esign: {
					client: {
						id: AccountCtrl.account.id
					}
				}
			};

			if (esign) {
				options.id = esign.id;
			}

			// Only open edit if saved as draft
			if (!esign || esign.state === Esign.stateEnum.DRAFT) {
				$upModal.open('editEsign', options);
			} else {
				if (Tools.FeatureHelper.hasSoftDeployAccess('CONFIRM_ESIGN_REACT')) {
					openModal('ConfirmEsignModal', options);
				} else {
					$upModal.open('confirmEsign', options);
				}
			}
		};

		AccountCtrl.editClientTarget = () => {
			openModal('EditClientTarget', {
				clientId: AccountCtrl.account.id,
				quotas: AccountCtrl.account.clientQuotas
			});
		};

		AccountCtrl.editTicket = () => {
			openModal('EditTicket', {
				client: {
					id: AccountCtrl.account.id,
					name: AccountCtrl.account.name,
					operationalAccount: AccountCtrl.account.operationalAccount
				}
			});
		};

		$scope.editLead = function (lead) {
			var params = { customerId: customerId, account: AccountCtrl.account };
			if (lead) {
				params.id = lead.id;
			}
			$upModal.open('editLead', params);
		};

		AccountCtrl.editActivity = function (e, activity) {
			var params = {
				activity: {
					client: { id: AccountCtrl.account.id }
				}
			};

			if (activity) {
				// Because editActivity fn is used in the combined lists
				if (activity.isAppointment) {
					AccountCtrl.editAppointment(e, activity);
					return;
				}
				params.id = activity.id;
			}

			$upModal.open('editActivity', params);
		};

		AccountCtrl.editAppointment = function (e, appointment) {
			var params = {
				appointment: {
					client: { id: AccountCtrl.account.id }
				}
			};

			if (appointment) {
				params.id = appointment.id;
			}

			$upModal.open('editAppointment', params);
		};

		AccountCtrl.editAccount = function (e) {
			if (shouldOpenModal('EditClient')) {
				openModal('EditClient', {
					id: AccountCtrl.account.id,
					onClose: data => {
						if (data) {
							AccountCtrl.account = data;
							$safeApply($scope);
						}
					}
				});
			} else {
				var copiedAccount = _.cloneDeep(AccountCtrl.account);

				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('editAccount', { customerId: customerId, account: copiedAccount }, e)
					.then(function (data) {
						AccountCtrl.account = data;
					});
			}
		};

		AccountCtrl.addSubaccount = function () {
			openDrawer('SubaccountDrawer', {
				client: {
					id: AccountCtrl.account.id,
					name: AccountCtrl.account.name,
					hasAccess: true
				},
				account: AccountCtrl.account,
				defaultView: SubaccountDrawerView.Add,
				includingSubaccountData: AccountCtrl.includingSubaccountData
			});
		};

		AccountCtrl.moreNotes = function (e) {
			const editable = AccountCtrl.account.userEditable;
			if (!Tools.FeatureHelper.hasSoftDeployAccess('REACT_EDIT_NOTE')) {
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open(
						'editNote',
						{
							icon: 'pencil',
							notes: AccountCtrl.account.notes,
							mutable: editable,
							textareaTooltip: metadata.standardFields.Client.Notes.tooltip
						},
						e
					)
					.then(newNotes => {
						AccountCtrl.account.notes = newNotes;
						SaveInline(newNotes, null, 'notes', AccountCtrl.account.id, 'Account');
					});
			} else {
				openModal('EditNote', {
					icon: 'pencil',
					notes: AccountCtrl.account.notes,
					mutable: editable,
					textareaTooltip: metadata.standardFields.Client.Notes.tooltip,
					onClose: notes => {
						if (notes) {
							AccountCtrl.account.notes = notes;
							$safeApply($scope);
						}
					}
				});
			}
		};

		AccountCtrl.moreAbout = function (e) {
			if (!Tools.FeatureHelper.hasSoftDeployAccess('REACT_EDIT_NOTE')) {
				$upModal.open('editNote', { notes: AccountCtrl.account.about, mutable: false }, e);
			} else {
				openModal('EditNote', {
					notes: AccountCtrl.account.about,
					mutable: false
				});
			}
		};

		AccountCtrl.editContact = function (contact, e) {
			if (e) {
				e.stopPropagation();
			}
			var params = { customerId: customerId, account: AccountCtrl.account };
			if (contact) {
				params.id = contact.id;
			}

			$upModal.open('editContact', params, e);
		};

		AccountCtrl.editRelation = function (relation) {
			var params = {
				relation: relation,
				account: AccountCtrl.account,
				customerId: customerId
			};
			openAccountRelationModal(params);
		};

		AccountCtrl.addRelation = function () {
			var params = {
				account: AccountCtrl.account,
				customerId: customerId
			};

			if (AccountCtrl.hasGroupBonanza && !AccountCtrl.isSubaccount) {
				openDrawer('CreateRelation', {
					client: AccountCtrl.account
				});
			} else {
				openAccountRelationModal(params);
			}
		};

		AccountCtrl.removeRelation = function (relation) {
			if (!relation.id) {
				return $q.resolve();
			}
			return AccountRelation.customer(customerId)
				['delete'](relation)
				.then(function () {
					AccountCtrl.results = _.reject(AccountCtrl.results, { id: relation.id });
				});
		};

		AccountCtrl.removeActivity = function (activity) {
			var Service = activity.isAppointment ? Appointment : Activity;
			return Service.customer(customerId)
				['delete'](activity)
				.then(function () {
					AccountCtrl.results = _.reject(AccountCtrl.results, { id: activity.id });
				});
		};

		AccountCtrl.removeMail = function (mail) {
			return Mail.customer(customerId)
				.delete(mail)
				.then(function () {
					AccountCtrl.results = _.reject(AccountCtrl.results, { id: mail.id });
				});
		};

		AccountCtrl.removeAgreement = function (agreement) {
			return Agreement.customer(customerId)
				['delete'](agreement)
				.then(function () {
					AccountCtrl.results = _.reject(AccountCtrl.results, { id: agreement.id });
				});
		};

		AccountCtrl.removeContact = function (contact) {
			return Contact.customer(customerId)
				['delete'](contact)
				.then(function () {
					AccountCtrl.results = _.reject(AccountCtrl.results, { id: contact.id });
				});
		};

		AccountCtrl.removeOpportunity = function (opportunity) {
			return Order.customer(customerId)
				['delete'](opportunity)
				.then(function () {
					AccountCtrl.results = _.reject(AccountCtrl.results, { id: opportunity.id });
				});
		};

		AccountCtrl.removeOrder = function (order) {
			return Order.customer(customerId)
				['delete'](order)
				.then(function () {
					AccountCtrl.results = _.reject(AccountCtrl.results, { id: order.id });
				});
		};

		AccountCtrl.createOpportunity = function () {
			var options = {
				customerId: customerId,
				clientId: AccountCtrl.account.id,
				type: 'opportunity'
			};
			$upModal.open('editOrder', options);
		};

		AccountCtrl.createUdo = function (id) {
			$upModal.open('editUserDefinedObject', {
				typeId: id,
				object: {
					client: { id: AccountCtrl.account.id, name: AccountCtrl.account.name }
				}
			});
		};

		AccountCtrl.editOpportunity = function (opportunity) {
			var options = {
				customerId: customerId,
				type: 'opportunity'
			};
			if (opportunity) {
				options.id = opportunity.id || opportunity.entityId;
			} else {
				options.account = AccountCtrl.account.id;
			}
			$upModal.open('editOrder', options);
		};

		AccountCtrl.checkUnsaved = function () {
			var a, b, c;

			a = _.pluck(accountManagers, 'id');
			b = _.pluck(AccountCtrl.account.users, 'id');
			c = _.difference(a, b).length || _.difference(b, a).length;
			AccountCtrl.showAccountManagers = true;
			AccountCtrl.unsavedManagers = c === 0;
		};

		AccountCtrl.cancelAccountManager = function (event) {
			event.stopPropagation();

			AccountCtrl.account.users = _.cloneDeep(accountManagers);
			AccountCtrl.showAccountManagers = false;
		};

		AccountCtrl.saveInlineManager = function (promise) {
			AccountCtrl.showAccountManagers = false;
			AccountCtrl.unsavedManagers = true;

			let saveUsers = AccountCtrl.account.users;
			if (AccountCtrl.accountManager) {
				saveUsers = [AccountCtrl.accountManager];
			} else if (!Array.isArray(AccountCtrl.account.users)) {
				saveUsers = [AccountCtrl.account.users];
			}

			const value = saveUsers.map(user => parseInt(user.id));
			SaveInline(value, promise, 'users.id', AccountCtrl.account.id, 'Account')
				.then(() => {
					accountManagers = _.cloneDeep(saveUsers);
					AccountCtrl.account.users = saveUsers;
					$safeApply($scope);
				})
				.catch(() => {
					AccountCtrl.accountManager = accountManagers[0];
					AccountCtrl.account.users = accountManagers;
					$safeApply($scope);
				});
		};

		AccountCtrl.saveInlineActivity = function (value, promise, field, activity) {
			var Service = activity.isAppointment ? 'Appointment' : 'Activity';
			SaveInline(value, promise, field, activity.id, Service);
		};

		const onCloseActivity = activity => {
			const data = { id: activity.id, closeDate: new Date() };
			Activity.customer(customerId)
				.save(data, { updateSuccessBody: 'default.activityClosed' })
				.then(function () {
					activity.closeDate = data.closeDate;
				})
				.catch(e => {
					logError(e, 'Failed to save activity', { data });
				});
		};

		// close activity
		AccountCtrl.closeActivity = function (activity) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('Alert', {
					title: 'default.closeActivity',
					body: 'confirm.markActivityAsDone',
					confirmButtonText: 'default.closeActivity',
					headerIcon: 'check-square-o',
					onClose: confirmed => {
						if (confirmed) {
							onCloseActivity(activity);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('defaultConfirm', {
					title: 'default.closeActivity',
					body: 'confirm.markActivityAsDone',
					resolveTrue: 'default.closeActivity',
					icon: 'fa-check-square-o'
				})
				.then(function () {
					onCloseActivity(activity);
				});
		};

		AccountCtrl.moveActivity = function (activity) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_MOVE_OR_CLOSE_ACTIVITY')) {
				openModal('MoveOrCloseActivity', {
					icon: 'long-arrow-right',
					notes: activity.notes,
					description: activity.description,
					isMove: true,
					onClose: closeData => {
						if (closeData) {
							const data = {
								id: activity.id,
								notes: closeData.notes,
								date: closeData.time ? closeData.date : moment(closeData.date).format('YYYY-MM-DD')
							};

							Activity.customer(customerId).save(data, { updateSuccessBody: 'default.moveActivity' });
						}
					}
				});
			} else {
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open('moveOrCloseActivity', {
						icon: 'fa-long-arrow-right',
						id: activity.id,
						notes: activity.notes,
						description: activity.description,
						isMove: true
					})
					.then(function (res) {
						const data = {
							id: activity.id,
							notes: res.notes,
							date: res.time ? res.Date : moment(res.Date).format('YYYY-MM-DD')
						};

						Activity.customer(customerId).save(data, { updateSuccessBody: 'default.moveActivity' });
					});
			}
		};

		AccountCtrl.showMoreCategories = function (index, e) {
			var data = AccountCtrl.account.$standardCategories;
			if (index > 0) {
				data = AccountCtrl.account.$extraCategories[index];
			}
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_LIST')) {
				openModal('ListModal', {
					title: 'default.categories',
					columns: [{ title: 'default.name', value: 'name' }],
					data: data
				});
			} else {
				$upModal.open(
					'list',
					{
						title: 'default.categories',
						columns: [{ title: 'default.name', value: 'name' }],
						data: data
					},
					e
				);
			}
		};

		AccountCtrl.showMoreCampaigns = function ($event, e) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_LIST')) {
				openModal('ListModal', {
					title: 'default.campaigns',
					columns: [{ title: 'default.name', value: 'name' }],
					data: AccountCtrl.account.projects,
					onClose: row => {
						$state.go('campaign.dashboard', { id: row.id });
					}
				});
			} else {
				// eslint-disable-next-line promise/catch-or-return
				$upModal
					.open(
						'list',
						{
							title: 'default.campaigns',
							columns: [{ title: 'default.name', value: 'name' }],
							data: AccountCtrl.account.projects
						},
						e
					)
					.then(function (row) {
						$state.go('campaign.dashboard', { id: row.id });
					});
			}
		};

		AccountCtrl.editAgreement = function (agreement) {
			if (agreement?.id) {
				return openAgreement({ agreement });
			}

			if (!Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
				const options = {
					customerId: customerId,
					accountId: AccountCtrl.account.id
				};
				return $upModal.open('editAgreement', options);
			}

			openModal('CreateSubscription', {
				client: AccountCtrl.account,
				createdFrom: 'accountAddButton',
				dontWait: Tools.FeatureHelper.hasSoftDeployAccess('DONT_WAIT_SUBSCRIPTIONS')
			});
		};

		AccountCtrl.merge = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_MERGE_CLIENTS')) {
				openModal('MergeClients', {
					id: AccountCtrl.account.id,
					customerId: customerId,
					meta: { account: { data: AccountCtrl.account } }
				});
			} else {
				$upModal.open('mergeClients', { id: AccountCtrl.account.id, customerId: customerId });
			}
		};

		AccountCtrl.addMarketEvent = function () {
			openNewMarketEvent({ account: AccountCtrl.account });
		};

		AccountCtrl.assign = function () {
			openAssignModal({ client: AccountCtrl.account });
		};

		AccountCtrl.unassign = function () {
			var synch = (AccountCtrl.contactsTotal || 0) > 1000 ? false : true;

			$upModal.open('resetScore', {
				account: AccountCtrl.account,
				userId: metadata.user.id,
				customerId: customerId,
				synch: synch
			});
		};

		AccountCtrl.onSetJourneyStepClient = function (journeyStep) {
			if (!AccountCtrl.account) {
				return $q.resolve();
			}
			return Account.customer(customerId)
				.save({
					id: AccountCtrl.account.id,
					journeyStep: journeyStep
				})
				.then(function (res) {
					AccountCtrl.account.journeyStep = res.data.journeyStep;
					AccountCtrl.account = _.cloneDeep(AccountCtrl.account);
				});
		};

		AccountCtrl.uploadFile = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_UPLOAD_FILE_MODAL')) {
				return openModal('UploadFileModal', { accountId: AccountCtrl.account.id });
			}
			$upModal.open('uploadFile', {
				accountId: AccountCtrl.account.id
			});
		};

		AccountCtrl.createTodo = function () {
			openDrawer('CreateTodo', { client: AccountCtrl.account });
		};

		AccountCtrl.createPhonecall = function () {
			openDrawer('CreateCall', { client: AccountCtrl.account });
		};

		AccountCtrl.createAppointment = function () {
			Tools.$upModal.open('editAppointment', {
				appointment: {
					client: { id: AccountCtrl.account.id }
				}
			});
		};

		AccountCtrl.goToContactSub = function (state, contactId) {
			$state.go('contact.' + state, { id: contactId });
		};

		AccountCtrl.formatCurrency = function (value, currency) {
			let defaultCurrency = AccountCtrl.metadata.defaultCurrency.iso;
			if (AccountCtrl.account.prospectingId) {
				defaultCurrency = getDefaultCurrencyFromProspectingId(AccountCtrl.account.prospectingId);
			}
			const currencyFormat = new CurrencyFormat(currency || defaultCurrency);
			return currencyFormat.short(value);
		};

		const selectOptionsCache = {};
		const formatSelectionCache = {};

		function getSelectOptions(selectOptions) {
			return (
				selectOptionsCache[selectOptions] ??
				(selectOptionsCache[selectOptions] = Tools.AppService.getStaticValues(selectOptions))
			);
		}

		AccountCtrl.formatSelect = function (field, value) {
			if (value) {
				const key = `${field.id}:${value}`;

				if (formatSelectionCache[key]) {
					return formatSelectionCache[key];
				}
				const selectOptions = getSelectOptions(field.selectOptions);
				const selectedOption = _.find(selectOptions, { id: '' + value });

				if (selectedOption) {
					formatSelectionCache[key] = selectedOption.name;
				}

				return selectedOption ? selectedOption.name : value;
			} else {
				return value;
			}
		};

		AccountCtrl.formatAbout = function (text) {
			return text === text.toUpperCase() ? text.toLowerCase() : text;
		};

		AccountCtrl.goToProspecting = function () {
			globalTracker.track('Find similar');

			const orgNo = AccountCtrl.account.orgNo.replace(
				AccountCtrl.prospectingCountry === 'GB' ? /[^A-Za-z0-9]/g : /\D/g,
				''
			);

			store.dispatch(setShouldInit(false));
			store.dispatch(
				onDrawerChanged(
					TabsEnum.AI,
					{
						type: Boxes.SimilarTo,
						loading: false,
						showInfoText: false,
						orgNumbers: [orgNo],
						companies: AccountCtrl.similarCompanies.data,
						total: AccountCtrl.similarCompanies.metadata.total,
						cameFromClientName: AccountCtrl.account.name,
						cameFromClientId: AccountCtrl.account.id
					},
					AccountCtrl.prospectingCountry
				)
			);
			$state.go('react-root-prospecting');
		};

		AccountCtrl.getFetchSimilarCompaniesTooltip = function () {
			if (AccountCtrl.hasSimilarCompaniesLoading) {
				return '';
			}
			if (!AccountCtrl.canSearchSimilarCompanies) {
				return $translate.instant('account.findSimilar.noProspectingId');
			}
			if (!AccountCtrl.hasSimilarCompanies) {
				return $translate.instant('account.findSimilar.noMatchesFound');
			}
			return '';
		};

		AccountCtrl.fetchSimilarCompanies = function () {
			if (!AccountCtrl.hasProspectingAccess) {
				return;
			}

			AccountCtrl.hasSimilarCompanies = false;
			AccountCtrl.canSearchSimilarCompanies = false;
			AccountCtrl.hasSimilarCompaniesLoading = false;
			AccountCtrl.prospectingCountry = null;

			if (!AccountCtrl.account.orgNo) {
				return;
			}
			const addresses = Object.values(AccountCtrl.account.$addresses || {});
			const availableCountries = getAvailableCountries();
			const country = AccountCtrl.account.prospectingId
				? getAvailableCountryFromProspectingId(AccountCtrl.account.prospectingId)
				: addresses.find(({ country }) => availableCountries.includes(country))?.country;

			if (country) {
				AccountCtrl.prospectingCountry = country;

				const orgNo = AccountCtrl.account.orgNo?.replace(country === 'GB' ? /[^A-Za-z0-9]/g : /\D/g, '');
				const validOrgNo = ValidationService.validateOrgNumber(orgNo, country);

				if (validOrgNo) {
					AccountCtrl.hasSimilarCompaniesLoading = true;
					AccountCtrl.canSearchSimilarCompanies = true;

					UpsalesAI.find({ orgNumbers: [orgNo], country, offset: 0 })
						.then(res => {
							AccountCtrl.hasSimilarCompaniesLoading = false;
							if (res?.data?.length > 0 && res.data[0].data?.length > 0) {
								AccountCtrl.hasSimilarCompanies = true;
								AccountCtrl.similarCompanies = res.data[0];
							}
							$safeApply($scope);
						})
						.catch(err => {
							logError(err, 'Failed to fetch similar companies');
							AccountCtrl.hasSimilarCompaniesLoading = false;
							$safeApply($scope);
						});
				}
			}

			$safeApply($scope);
		};

		AccountCtrl.formatPhone = phoneNumberHelper.formatNumber;

		AccountCtrl.getScore = function () {
			if (AccountCtrl.hasSubaccounts && AccountCtrl.subAccountIds?.length) {
				return AccountCtrl.marketScoreAllTime ?? 0;
			} else {
				return AccountCtrl.account?.score ?? 0;
			}
		};

		// save account inline function
		$scope.saveInline = function (value, promise, field) {
			SaveInline(value, promise, field, AccountCtrl.account.id, 'Account');
		};

		// save to latestAccounts
		var saveLatestAccount = function (account) {
			// save to local storage
			LatestAccountsService.addAccount(customerId, metadata.user.id, account);
		};

		var getStages = function () {
			var stages = meta.orderStages.data;
			$scope.orderStages = _.filter(stages, { probability: 100 });
			$scope.opportunityStages = stages;
		};

		const handleContactsResponse = res => {
			AccountCtrl.contactsTotal = 0;
			if (res.active?.metadata?.total ?? 0 > 0) {
				AccountCtrl.contactsTotal = res.active.metadata.total;
				AccountCtrl.inactiveContactTotal = res.inactive.metadata.total;
				AccountCtrl.onlyInactiveContacts = false;
			} else if (res.inactive?.metadata?.total ?? 0 > 0) {
				AccountCtrl.contactsTotal = res.inactive.metadata.total;
				AccountCtrl.onlyInactiveContacts = true;
			}
			AccountCtrl.showContactTab =
				((Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_BASIC) ||
					Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO)) &&
					AccountCtrl.account.prospecting &&
					AccountCtrl.account.prospecting.directors) ||
				AccountCtrl.contactsTotal > 0;
		};

		function getContacts() {
			return AccountCard.get(AccountCtrl.account.id, {
				params: { method: ['activeContacts', 'inactiveContacts'], subAccountIds: AccountCtrl.subAccountIds }
			}).then(({ data } = {}) =>
				handleContactsResponse({ active: data.activeContacts, inactive: data.inactiveContacts })
			);
		}

		function getUsers() {
			var filters = new RequestBuilder();
			filters.addSort(User.attr.name, true);

			$scope.allUsers = AppService.getUsers(AppService.AccessType.ORDER);
			$scope.users = AppService.getActiveUsers();

			// Fix for when a user are selected and no longer exist
			if (AccountCtrl.account.users) {
				AccountCtrl.account.users.forEach(function (user) {
					if (user && !_.some($scope.users, { id: user.id })) {
						$scope.users.push(user);
					}
				});
			}

			AccountCtrl.users = $scope.users;
		}

		const handleMarketEventsResponse = res => {
			if (res.metadata.total > 0) {
				AccountCtrl.marketExists = true;
			} else {
				AccountCtrl.marketExists = false;
			}
		};

		function getNOMarketEvents() {
			return AccountCard.get(AccountCtrl.account.id, {
				params: { method: ['marketEvents'], subAccountIds: AccountCtrl.subAccountIds }
			}).then(({ data } = {}) => handleMarketEventsResponse(data.marketEvents));
		}

		const handleMarketingResponse = results => {
			if (results && results.metadata) {
				AccountCtrl.marketScoreLast90Days = results.metadata.totalScore;
				AccountCtrl.marketScoreAllTime = results.metadata.allTimeScore;
				AccountCtrl.hasMail = !!results.metadata.mailCount;
				AccountCtrl.hasForm = !!results.metadata.formCount;
				AccountCtrl.hasVisit = !!results.metadata.visitCount;
				AccountCtrl.hasMarketingCustom = !!results.metadata.marketingcustomCount;
				AccountCtrl.haveImpressions = !!results.metadata.impressionCount;
			} else {
				AccountCtrl.marketScoreLast90Days = 0;
				AccountCtrl.marketScoreAllTime = 0;
				AccountCtrl.hasMail = false;
				AccountCtrl.hasForm = false;
				AccountCtrl.hasVisit = false;
				AccountCtrl.hasMarketingCustom = false;
				AccountCtrl.haveImpressions = false;
			}
		};

		function sumRecentScore() {
			return AccountCard.get(AccountCtrl.account.id, {
				params: { method: ['marketing'], subAccountIds: AccountCtrl.subAccountIds }
			})
				.then(({ data } = {}) => handleMarketingResponse(data.marketing))
				.finally(() => {
					$safeApply($scope);
				});
		}

		var getDefaultActivityFilter = function () {
			var filter = new RequestBuilder();
			filter.addFilter(Activity.attr.account, filter.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);
			return filter;
		};

		var getDefaultActivityListFilter = function (isClosed, isDelayed, skipDelayed) {
			var filter = new RequestBuilder();
			var or = filter.orBuilder();

			filter.addFilter(ActivityList.attr.account, filter.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);
			filter.addFilter(ActivityList.attr.projectPlan.attr.id, filter.comparisonTypes.Equals, null);

			or.next();
			or.addFilter(ActivityList.attr.isAppointment, filter.comparisonTypes.Equals, true);
			or.addFilter(
				ActivityList.attr.endDate,
				isClosed ? filter.comparisonTypes.LessThan : filter.comparisonTypes.GreaterThan,
				new Date()
			);
			or.next();
			or.addFilter(ActivityList.attr.isAppointment, filter.comparisonTypes.Equals, false);
			or.addFilter(
				ActivityList.attr.closeDate,
				isClosed ? filter.comparisonTypes.NotEquals : filter.comparisonTypes.Equals,
				null
			);
			//Todo's can have null date
			if (FeatureHelper.hasSoftDeployAccess('TODO_LIST')) {
				or.next();
				or.addFilter(ActivityList.attr.isAppointment, filter.comparisonTypes.Equals, false);
				or.addFilter(
					ActivityList.attr.closeDate,
					isClosed ? filter.comparisonTypes.NotEquals : filter.comparisonTypes.Equals,
					null
				);
				or.addFilter(Activity.attr.date, filter.comparisonTypes.Equals, null);
			}
			if (isClosed) {
				or.next();
				or.addFilter({ field: 'isMap' }, filter.comparisonTypes.Equals, false);
			}
			if (isDelayed) {
				or.addFilter(Activity.attr.date, filter.comparisonTypes.LessThan, moment());
			} else if (!skipDelayed) {
				or.addFilter(Activity.attr.date, filter.comparisonTypes.GreaterThan, moment().subtract(1, 'day'));
			}
			or.done();

			return filter;
		};

		function getNOActivities() {
			// Nr of delayed
			var delayedFilter = getDefaultActivityFilter();
			delayedFilter.addFilter(Activity.attr.closeDate, delayedFilter.comparisonTypes.Equals, null);
			delayedFilter.addFilter(ActivityList.attr.projectPlan.attr.id, delayedFilter.comparisonTypes.Equals, null);
			delayedFilter.addFilter(
				Activity.attr.date,
				delayedFilter.comparisonTypes.LessThan,
				moment().subtract(1, 'day').endOf('day')
			);
			delayedFilter.limit = 0;

			//AccountCtrl.activityCloseDate = null;
			//AccountCtrl.activityOverDue = false;
			return Activity.customer(customerId)
				.find(delayedFilter.build())
				.then(function (delayedRes) {
					if (!delayedRes.metadata.total) {
						AccountCtrl.activityOverDue = false;
						// Open non delayed activities or appointments
						var openFilter = getDefaultActivityListFilter(false, false);
						openFilter.limit = 0;
						ActivityList.customer(customerId)
							.find(openFilter.build(), { includes: 'activities,appointments', mapCustom: true })
							.then(function (openRes) {
								if (!openRes.metadata.total) {
									// Latest closed
									var closedFilter = getDefaultActivityListFilter(true, false, true);
									closedFilter.addSort(Activity.attr.date, false);
									closedFilter.limit = 1;
									return ActivityList.customer(customerId)
										.find(closedFilter.build(), {
											includes: 'activities,appointments,mail',
											mapCustom: true
										})
										.then(function (closedRes) {
											AccountCtrl.activitiesTotal = closedRes.metadata.total;
											if (closedRes.data.length) {
												AccountCtrl.activityCloseDate =
													closedRes.data[0].date ?? closedRes.data[0].closeDate;
											}
										});
								} else {
									AccountCtrl.activityCloseDate = null;
									AccountCtrl.activityOverDue = false;
									AccountCtrl.activitiesTotal = openRes.metadata.total;
								}
							})
							.catch(e => {
								logError(e, 'Failed to load activity list');
							});
					} else {
						AccountCtrl.activitiesTotal = delayedRes.metadata.total;
						AccountCtrl.activityCloseDate = null;
						AccountCtrl.activityOverDue = true;
					}
				});
		}

		var getDefaultOpportunityFilter = function () {
			var filter = new RequestBuilder();
			var or = filter.orBuilder();
			or.next();
			or.addFilter(Opportunity.attr.account, filter.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);
			or.next();
			or.addFilter(Opportunity.attr.clientConnection, filter.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);
			or.done();
			filter.addFilter(Opportunity.attr.probability, filter.comparisonTypes.NotEquals, 100);

			return filter;
		};

		function getNOOpportunities() {
			var openFilter = getDefaultOpportunityFilter();
			openFilter.addFilter(Opportunity.attr.probability, openFilter.comparisonTypes.NotEquals, 0);
			openFilter.addSort(Opportunity.attr.date, true);
			openFilter.limit = 1;
			AccountCtrl.opportunitiesOverdue = null;

			return Opportunity.customer(customerId)
				.find(openFilter.build())
				.then(function (openRes) {
					AccountCtrl.opportunitiesCount = openRes.metadata.total;

					const shouldFetchNewJourneyStatus =
						(AccountCtrl.account.journeyStep === 'pipeline' && AccountCtrl.opportunitiesCount === 0) ||
						(['lead', 'mql', 'assigned', 'sql'].includes(AccountCtrl.account.journeyStep) &&
							AccountCtrl.opportunitiesCount === 1);
					if (shouldFetchNewJourneyStatus) {
						AccountCtrl.refetchAccount();
					}

					if (!openRes.data.length) {
						var lostFilter = getDefaultOpportunityFilter();

						lostFilter.addFilter(Opportunity.attr.probability, lostFilter.comparisonTypes.Equals, 0);
						lostFilter.addFilter(
							Opportunity.attr.date,
							lostFilter.comparisonTypes.LessThanEquals,
							moment()
						);
						lostFilter.addSort(Opportunity.attr.date, false);
						lostFilter.limit = 1;

						return Opportunity.customer(customerId)
							.find(lostFilter.build())
							.then(function (closedRes) {
								AccountCtrl.opportunitiesCount = closedRes.metadata.total;
								if (closedRes.data.length) {
									AccountCtrl.opportunitiesTotal = 1;
									AccountCtrl.opportunityCloseDate = closedRes.data[0].date;
								} else {
									AccountCtrl.opportunityCloseDate = null;
									AccountCtrl.opportunitiesTotal = 0;
								}
								return AccountCtrl.opportunitiesTotal;
							});
					} else {
						AccountCtrl.opportunitiesOverdue = moment(openRes.data[0].date).endOf('day').isBefore();
						var sumOpenFilter = getDefaultOpportunityFilter();
						sumOpenFilter.addFilter(
							Opportunity.attr.probability,
							sumOpenFilter.comparisonTypes.NotEquals,
							0
						);
						var valueAgg = sumOpenFilter.aggregationBuilder();
						valueAgg.addAggregation(
							sumOpenFilter.aggregationTypes.Sum,
							Order.attr.valueInRoleCurrency.field
						);
						valueAgg.done();
						return Report.customer(customerId)
							.setType(Report.type.OPPORTUNITY)
							.find(sumOpenFilter.build())
							.then(function (sumOpenRes) {
								if (sumOpenRes.data && sumOpenRes.data.sum_valueInRoleCurrency) {
									AccountCtrl.opportunitiesTotal = sumOpenRes.data.sum_valueInRoleCurrency.value;
									AccountCtrl.opportunityCloseDate = null;
								}

								return AccountCtrl.opportunitiesTotal;
							});
					}
				});
		}

		function getNOSupportTickets() {
			if (AccountCtrl.hasSupportService) {
				const rb = new RequestBuilder();

				rb.addSort('lastUpdated', false);
				rb.addFilter({ field: 'status.closed' }, Equals, false);
				rb.addFilter({ field: 'client.id' }, Equals, [AccountCtrl.account.id, ...AccountCtrl.subAccountIds]);
				rb.addFilter({ field: 'isArchived' }, Equals, false);

				return TicketResource.find(rb.build())
					.then(data => {
						return (AccountCtrl.supportTicketsTotal = data.metadata.total);
					})
					.catch(error => {
						logError(error, 'Error when getting list of tickets');
						return (AccountCtrl.supportTicketsTotal = 0);
					})
					.finally(() => {
						$safeApply($scope);
					});
			}
			if (!AccountCtrl.hasSupportService) {
				AccountCtrl.supportTicketsTotal = 0;
				AccountCtrl.openSupportTicketsTotal = 0;
				return;
			}

			const buildTicketRequest = (rb, filters = []) => {
				rb.limit = 0;
				rb.addSort('lastUpdated', false);
				filters.forEach(({ field, comparison, value }) => {
					rb.addFilter({ field }, comparison, value);
				});
				return rb.build();
			};

			// First, check if there are any tickets at all
			const rbTotal = new RequestBuilder();
			const totalTicketsFilter = [
				{
					field: 'client.id',
					comparison: rbTotal.comparisonTypes.Equals,
					value: [AccountCtrl.account.id, ...meta.subAccountIds]
				}
			];

			return TicketResource.find(buildTicketRequest(rbTotal, totalTicketsFilter))
				.then(data => {
					const totalTickets = data.metadata?.total ?? 0;
					AccountCtrl.supportTicketsTotal = totalTickets;

					if (totalTickets === 0) {
						return (AccountCtrl.supportTicketsTotal = 0);
					}

					// If there were any tickets at all, we check if there are any open tickets
					const rbOpen = new RequestBuilder();
					const openTicketsFilter = [
						{ field: 'status.closed', comparison: rbOpen.comparisonTypes.Equals, value: false },
						{
							field: 'client.id',
							comparison: rbOpen.comparisonTypes.Equals,
							value: [AccountCtrl.account.id, ...meta.subAccountIds]
						},
						{ field: 'isArchived', comparison: rbOpen.comparisonTypes.Equals, value: false }
					];

					return TicketResource.find(buildTicketRequest(rbOpen, openTicketsFilter))
						.then(data => {
							const openTickets = data.metadata?.total ?? 0;
							AccountCtrl.openSupportTicketsTotal = openTickets;
						})
						.catch(error => {
							logError(error, 'Error when getting list of open tickets');
							AccountCtrl.openSupportTicketsTotal = 0;
						});
				})
				.catch(error => {
					logError(error, 'Error when getting list of total tickets');
					AccountCtrl.supportTicketsTotal = 0;
				})
				.finally(() => {
					$safeApply($scope);
				});
		}

		function getNOOngoingProjects() {
			if (AccountCtrl.hasProjectPlan) {
				const rb = new RequestBuilder();

				rb.addFilter({ field: 'projectPlanStage.id' }, comparisonTypes.NotEquals, 3);
				rb.addFilter({ field: 'client.id' }, Equals, [AccountCtrl.account.id, ...AccountCtrl.subAccountIds]);

				return ProjectPlanResource.find(rb.build())
					.then(data => {
						return (AccountCtrl.onGoingProjects = data.metadata.total);
					})
					.catch(error => {
						logError(error, 'Error when getting list of ongoing projects');
						return (AccountCtrl.onGoingProjects = 0);
					})
					.finally(() => {
						$safeApply($scope);
					});
			}

			AccountCtrl.onGoingProjects = 0;
		}

		function getNOProjects() {
			if (AccountCtrl.hasProjectPlan) {
				const rb = new RequestBuilder();

				rb.limit = 0;
				rb.addFilter({ field: 'client.id' }, Equals, [AccountCtrl.account.id, ...AccountCtrl.subAccountIds]);

				return ProjectPlanResource.find(rb.build())
					.then(data => {
						return (AccountCtrl.totalProjects = data.metadata.total);
					})
					.catch(error => {
						logError(error, 'Error when getting list of total projects');
						return (AccountCtrl.totalProjects = 0);
					})
					.finally(() => {
						$safeApply($scope);
					});
			}

			AccountCtrl.totalProjects = 0;
		}

		var getOrderFilter = function (excludeExcludedStages) {
			var filter = new RequestBuilder();
			if (excludeExcludedStages) {
				filter.addFilter(Order.attr.stage, filter.comparisonTypes.NotEquals, excludedStages);
			}
			var or = filter.orBuilder();
			or.next();
			or.addFilter(Order.attr.account, filter.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);
			or.next();
			or.addFilter(Order.attr.clientConnection, filter.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);
			or.done();
			filter.limit = 0;

			return filter;
		};

		function getNOOrder() {
			var orderFilter = getOrderFilter(true);
			orderFilter.addFilter(Order.attr.probability, orderFilter.comparisonTypes.Equals, 100);
			orderFilter.addFilter(
				Order.attr.date,
				orderFilter.comparisonTypes.GreaterThan,
				moment().subtract(1, 'year')
			);
			var valueAgg = orderFilter.aggregationBuilder();
			valueAgg.addAggregation(orderFilter.aggregationTypes.Sum, Order.attr.valueInRoleCurrency.field);
			valueAgg.done();
			var contributionMarginAgg = orderFilter.aggregationBuilder();
			contributionMarginAgg.addAggregation(
				orderFilter.aggregationTypes.Sum,
				Order.attr.contributionMarginInRoleCurrency.field
			);
			contributionMarginAgg.done();
			var hasOrderFilter = getOrderFilter(true);
			hasOrderFilter.addFilter(Order.attr.probability, hasOrderFilter.comparisonTypes.Equals, 100);
			var hasAgreementFilter = getOrderFilter();

			var agreementFilter = getOrderFilter();
			agreementFilter.addFilter(
				Agreement.attr.startDate,
				agreementFilter.comparisonTypes.LessThanEquals,
				moment().utc()
			);

			var or = agreementFilter.orBuilder();
			or.next();
			or.addFilter(Agreement.attr.endDate, agreementFilter.comparisonTypes.Equals, null);
			or.next();
			or.addFilter(Agreement.attr.endDate, agreementFilter.comparisonTypes.GreaterThan, moment().utc());
			or.done();

			var agreementAgg = agreementFilter.aggregationBuilder();
			agreementAgg.addAggregation(
				orderFilter.aggregationTypes.Sum,
				Agreement.attr.yearlyValueInRoleCurrency.field
			);
			agreementAgg.done();

			return $q
				.all({
					order: Report.customer(customerId).setType(Report.type.ORDER).find(orderFilter.build()),
					hasOrder: Order.customer(customerId).find(hasOrderFilter.build()),
					agreement: Agreement.customer(customerId).find(agreementFilter.build()),
					agreementReport: Report.customer(customerId)
						.setType(Report.type.AGREEMENT)
						.find(agreementFilter.build()),
					hasAgreement: Agreement.customer(customerId).find(hasAgreementFilter.build())
				})
				.then(function (res) {
					var orderAgreementTotal =
						res.order.data.sum_valueInRoleCurrency.doc_count + res.agreement.metadata.total;
					AccountCtrl.hasOrders =
						(res.hasOrder.metadata.total > 0 || res.hasAgreement.metadata.total > 0) &&
						FeatureHelper.isAvailable(FeatureHelper.Feature.CLIENT_ORDERS);

					if (!orderAgreementTotal) {
						var oldOrderFilter = getOrderFilter();
						oldOrderFilter.addFilter(Order.attr.probability, oldOrderFilter.comparisonTypes.Equals, 100);
						oldOrderFilter.addFilter(
							Order.attr.date,
							oldOrderFilter.comparisonTypes.LessThan,
							moment().subtract(1, 'year')
						);
						oldOrderFilter.addSort(Order.attr.date, false);
						oldOrderFilter.limit = 1;

						var oldAgreementFilter = getOrderFilter();
						oldAgreementFilter.addFilter(
							Agreement.attr.endDate,
							oldAgreementFilter.comparisonTypes.LessThan,
							moment().utc()
						);

						return $q
							.all({
								oldOrder: Order.customer(customerId).find(oldOrderFilter.build()),
								inactiveAgreement: Agreement.customer(customerId).find(oldAgreementFilter.build())
							})
							.then(function (oldRes) {
								AccountCtrl.orderAgreementTotal =
									oldRes.inactiveAgreement.metadata.total + oldRes.oldOrder.metadata.total;

								if (oldRes.oldOrder.metadata.total) {
									AccountCtrl.orderLastDate = oldRes.oldOrder.data[0].date;
									AccountCtrl.orderTotal = 0;
								} else {
									AccountCtrl.orderLastDate = null;
									AccountCtrl.orderTotal = 0;
								}

								if (oldRes.inactiveAgreement.metadata.total) {
									AccountCtrl.hasInactiveAgreement = true;
									AccountCtrl.agreementTotal = oldRes.inactiveAgreement.metadata.total;
									AccountCtrl.agreementTotalValue = 0;
								} else {
									AccountCtrl.hasInactiveAgreement = false;
									AccountCtrl.agreementTotal = 0;
									AccountCtrl.agreementTotalValue = 0;
								}
							});
					} else if (!res.agreement.metadata.total) {
						AccountCtrl.agreementTotal = 0;
						AccountCtrl.agreementTotalValue = 0;
						AccountCtrl.orderAgreementTotal = orderAgreementTotal;
						AccountCtrl.orderTotal = res.order.data.sum_valueInRoleCurrency.value;
						AccountCtrl.contributionMarginTotal = res.order.data.sum_contributionMarginInRoleCurrency.value;
						AccountCtrl.contributionMarginRatio =
							AccountCtrl.contributionMarginTotal / AccountCtrl.orderTotal;
						AccountCtrl.orderLastDate = null;
						AccountCtrl.hasInactiveAgreement = false;
					} else {
						AccountCtrl.orderAgreementTotal = orderAgreementTotal;
						AccountCtrl.orderTotal = res.order.data.sum_valueInRoleCurrency.value;
						AccountCtrl.contributionMarginTotal = res.order.data.sum_contributionMarginInRoleCurrency.value;
						AccountCtrl.contributionMarginRatio =
							AccountCtrl.contributionMarginTotal / AccountCtrl.orderTotal;
						AccountCtrl.agreementTotal = res.agreement.metadata.total;
						AccountCtrl.orderLastDate = null;
						AccountCtrl.hasInactiveAgreement = false;

						AccountCtrl.agreementTotalValue = res.agreementReport.data.sum_yearlyValueInRoleCurrency.value;
						if (AccountCtrl.salesModelOption === 'mrr') {
							AccountCtrl.agreementTotalValue = AccountCtrl.agreementTotalValue / 12;
						}
					}

					AccountCtrl.orderTabValue = AccountCtrl.orderTotal;
					AccountCtrl.tabTooltip = `${$translate.instant(
						'account.tooltip.orderValue'
					)}: ${AccountCtrl.formatCurrency(AccountCtrl.orderTabValue)}`;
					if (AccountCtrl.salesModel === 'cm') {
						if (AccountCtrl.salesModelOption === 'cm') {
							AccountCtrl.orderTabValue = AccountCtrl.contributionMarginTotal;
							AccountCtrl.tabTooltip = `${$translate.instant(
								'account.tooltip.contributionMargin'
							)}: ${AccountCtrl.formatCurrency(AccountCtrl.contributionMarginTotal)}\n${
								AccountCtrl.tabTooltip
							}`;
						} else {
							AccountCtrl.tabTooltip = `${AccountCtrl.tabTooltip}\n${$translate.instant(
								'account.tooltip.contributionMargin'
							)}: ${AccountCtrl.formatCurrency(AccountCtrl.contributionMarginTotal)}`;
						}
					} else if (AccountCtrl.salesModel === 'rr') {
						AccountCtrl.orderTabValue = AccountCtrl.agreementTotalValue;
						if (AccountCtrl.salesModelOption === 'mrr') {
							AccountCtrl.tabTooltip = `${$translate.instant(
								'account.tooltip.mrr'
							)}: ${AccountCtrl.formatCurrency(AccountCtrl.orderTabValue)}\n${AccountCtrl.tabTooltip}`;
						} else {
							AccountCtrl.tabTooltip = `${$translate.instant(
								'account.tooltip.arr'
							)}: ${AccountCtrl.formatCurrency(AccountCtrl.orderTabValue)}\n${AccountCtrl.tabTooltip}`;
						}
					}
				});
		}

		function getFilesAndEsign() {
			var promises = {};
			if (AccountCtrl.hasDocumentFeature || AccountCtrl.hasClientDocuments) {
				var fileFilter = new RequestBuilder();
				fileFilter.addFilter(File.attr.account.attr.id, fileFilter.comparisonTypes.Equals, [
					AccountCtrl.account.id,
					...AccountCtrl.subAccountIds
				]);
				fileFilter.limit = 0;
				promises.files = File.customer(customerId).find(fileFilter.build());
			}

			if (AccountCtrl.hasEsign || AccountCtrl.hasClientEsigns) {
				var esignFilter = new RequestBuilder();
				esignFilter.addFilter(
					Esign.attr.account.attr.id,
					esignFilter.comparisonTypes.Equals,
					AccountCtrl.account.id
				);
				promises.esign = Esign.find(esignFilter.build());
			}

			return $q.all(promises).then(function (res) {
				if (res.files && res.files.metadata) {
					AccountCtrl.filesTotal = res.files.metadata.total;
				} else {
					AccountCtrl.filesTotal = 0;
				}

				if (res.esign && res.esign.metadata) {
					AccountCtrl.openEsign = _.find(res.esign.data, { state: 10 });
					AccountCtrl.filesTotal += res.esign.metadata.total;
				}
			});
		}

		function getNORelatedClients() {
			return accountRelations.getCount(AccountCtrl.account, AccountCtrl.subAccountIds).then(function (count) {
				AccountCtrl.relationsTotal = count;
			});
		}

		const handleLinksResponse = response => {
			AccountCtrl.links = response.data;
			AccountCtrl.loadingLinks = false;
		};

		function getLinks() {
			AccountCtrl.links = [];
			AccountCtrl.loadingLinks = true;
			return AccountCard.get(AccountCtrl.account.id, {
				params: { method: ['links'] },
				subAccountIds: AccountCtrl.subAccountIds
			})
				.then(({ data } = {}) => handleLinksResponse(data.links))
				.catch(err => {
					logError(err, 'Failed to load links');
					AccountCtrl.loadingLinks = false;
				});
		}

		function fixWebpage() {
			if (AccountCtrl.account.webpage && AccountCtrl.account.webpage.indexOf('http') !== 0) {
				AccountCtrl.account.webpage = 'http://' + AccountCtrl.account.webpage;
			}
		}

		function getNoUDO() {
			if (!AccountCtrl.hasUdo) {
				return false;
			}

			var promises = {};
			AccountCtrl.udoTotal = {};

			// Account filter
			var filters = new RequestBuilder();
			filters.limit = 0;
			filters.addFilter(UserDefinedObject.attr.account.attr.id, filters.comparisonTypes.Equals, [
				AccountCtrl.account.id,
				...AccountCtrl.subAccountIds
			]);

			angular.forEach(AccountCtrl.udo, function (object) {
				// Get total items for each object (for this account)
				AccountCtrl.udoTotal[object.id] = 0;

				promises[object.id] = UserDefinedObject.setId(object.id)
					.find(filters.build())
					.then(function (res) {
						AccountCtrl.udoTotal[object.id] = res.metadata.total;
					});
			});

			return $q.all(promises);
		}

		// Only run once!!! (it initializes event listeners)
		var checkforUDO = function () {
			AccountCtrl.udo = _.filter(metadata.params.UserDefinedObject, { link: UdoLink.ACCOUNT });

			// Set var so we know there is some accountUdos
			if (AccountCtrl.udo.length && FeatureHelper.isAvailable(FeatureHelper.Feature.UDO)) {
				AccountCtrl.hasUdo = true;

				angular.forEach(AccountCtrl.udo, function (udo) {
					$scope.$on('userDefinedObject' + udo.id + '.added', function (e, updated) {
						if (updated.client && updated.client.id === AccountCtrl.account.id) {
							AccountCtrl.udoTotal[udo.id]++;
						}
					});
					$scope.$on('userDefinedObject' + udo.id + '.deleted', function (e, deleted) {
						if (deleted.client && deleted.client.id === AccountCtrl.account.id) {
							AccountCtrl.udoTotal[udo.id]--;
						}
					});
				});
			} else {
				AccountCtrl.hasUdo = false;
			}
		};

		var handleRefreshAccountTooltip = function (date) {
			const titleText = $translate.instant('soliditet.refreshAccount');
			let content = titleText;
			if (date) {
				const updatedText = $translate.instant('soliditet.refreshAccountUpdated');
				const dateText = moment(date).format('LL');
				content += `\n\n${updatedText}: ${dateText}`;
			}
			AccountCtrl.refreshAccountTooltip = content;
		};

		const handleToggleSignals = function () {
			AccountCtrl.signalsToggleAlignment =
				AccountCtrl.account.userEditable && AccountCtrl.account.userRemovable && AccountCtrl.showRefreshAccount
					? 'center'
					: 'right';

			const country = getCountryFromProspectingId(AccountCtrl.account.prospectingId);
			const isValidSignalsCountry = country === 'SE';

			AccountCtrl.hasSignalsFeature =
				FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_SIGNALS) &&
				FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO) &&
				isValidSignalsCountry;

			AccountCtrl.showSignalsToggle =
				(self.userParams.signalsModifyAccess || self.administrator) && isValidSignalsCountry;

			AccountCtrl.showSignalsFilter = isValidSignalsCountry;

			if (AccountCtrl.showSignalsToggle) {
				const clientId = AccountCtrl.account.id;
				SignalsFilter.isEnabled(clientId)
					.then(state => {
						AccountCtrl.isSignalsEnabled = state;
					})
					.catch(err => {
						logError(err, 'failed fetching signals status');
					});
			}
		};

		var init = async function () {
			// set account
			AccountCtrl.account = meta.account.data;
			AccountCard.get(meta.account.data.id, {
				params: {
					subAccountIds: AccountCtrl.subAccountIds
				}
			})
				.then(({ data = {} } = {}) => {
					handleContactsResponse({ active: data.activeContacts, inactive: data.inactiveContacts });
					handleMarketingResponse(data.marketing);
					handleLinksResponse(data.links);
					handleMarketEventsResponse(data.marketEvents);
					$safeApply($scope);
				})
				.catch(e => {
					logError(e, 'Failed to load account card', { id: meta.account.data.id });
				});

			handleToggleSignals();

			handleRefreshAccountTooltip(meta.account.data?.prospectingUpdateDate);

			const self = Tools.AppService.getSelf();
			AccountCtrl.isSupportOnly = self.support && !self.crm;

			AccountCtrl.hasTodoList = Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
			excludedStages = AppService.getStages('excludedIds', true);
			AccountCtrl.hideMarketTab =
				FeatureHelper.isAvailableProduct(FeatureHelper.Product.MA) &&
				!FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM);

			AccountCtrl.createMenuAvailable = {
				contact: FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANIES_AND_CONTACTS),
				activity: FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS),
				appointment: FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS),
				opportunity: FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE),
				order: FeatureHelper.isAvailable(FeatureHelper.Feature.ORDERS),
				agreement:
					FeatureHelper.isAvailable(FeatureHelper.Feature.RECURRING_ORDER) &&
					AppService.getAccessRights().Agreement,
				document: FeatureHelper.isAvailable(FeatureHelper.Feature.DOCUMENTS),
				relation:
					FeatureHelper.isAvailable(FeatureHelper.Feature.COMPANY_RELATIONS) &&
					!(AccountCtrl.hasGroupBonanza && !!meta.account.data.operationalAccount),
				marketEvent: FeatureHelper.isAvailable(FeatureHelper.Feature.MARKETING_EVENTS),
				assign: FeatureHelper.isAvailable(FeatureHelper.Feature.LEADS),
				resetScore: FeatureHelper.isAvailableProduct(FeatureHelper.Product.MA),
				uploadFile: FeatureHelper.isAvailable(FeatureHelper.Feature.DOCUMENTS),
				esign: FeatureHelper.isAvailable(FeatureHelper.Feature.ESIGN),
				udo: FeatureHelper.isAvailable(FeatureHelper.Feature.UDO),
				support: FeatureHelper.isAvailable(FeatureHelper.Feature.CUSTOMER_SUPPORT),
				projectPlan:
					FeatureHelper.hasSoftDeployAccess(FeatureHelper.Feature.PROJECT_PLAN) ||
					FeatureHelper.isAvailable(FeatureHelper.Feature.PROJECT_PLAN)
			};
			AccountCtrl.removeActivities =
				Tools.FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES') &&
				Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST');
			AccountCtrl.hasFeatureAnyAccountmanager = Tools.FeatureHelper.hasSoftDeployAccess('ANY_ACCOUNTMANAGER');
			AccountCtrl.hasAccountPlan = FeatureHelper.hasSoftDeployAccess('ACCOUNT_PLAN');
			AccountCtrl.hasProspectingAccess = FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO);
			AccountCtrl.showProspectingFinancials = false;
			AccountCtrl.isProspectingProTrialAddon =
				!!Tools.FeatureHelper.getOngoingTrialAddons().PROSPECTING_PRO_SIGNALS;
			if (
				AccountCtrl.hasProspectingAccess &&
				meta.account.data?.prospecting?.accounts?.length > 0 &&
				!!meta.account.data?.prospecting?.headquarters
			) {
				AccountCtrl.showProspectingFinancials = true;
			} else if (
				FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_BASIC) &&
				meta.account.data?.prospecting?.revenue &&
				FeatureHelper.hasSoftDeployAccess('BILLING_ADDONS') &&
				!!meta.account.data?.prospecting?.headquarters
			) {
				AccountCtrl.showProspectingFinancials = true;
			}
			AccountCtrl.fetchSimilarCompanies();

			if (AccountCtrl.showCompanyGroupCustomers) {
				AccountCtrl.loadCompanyGroupCustomers();
			}

			if (AccountCtrl.showProspectingFinancials) {
				const currency = getDefaultCurrencyFromProspectingId(meta.account.data.prospectingId);
				const currencyFormat = new CurrencyFormat(currency);
				AccountCtrl.turnOver = currencyFormat.short(meta.account.data.prospecting.revenue);

				if (
					FeatureHelper.isAvailable(FeatureHelper.Feature.PROSPECTING_PRO) &&
					meta.account.data?.prospecting?.accounts?.length > 0
				) {
					const sortedAccounts = meta.account.data.prospecting.accounts.sort((a, b) =>
						moment(a.dateTo) < moment(b.dateTo) ? 1 : -1
					);
					AccountCtrl.tooltip = $translate.instant('financials.tooltip', {
						year: moment(sortedAccounts[0].dateTo).format('YYYY')
					});
				}
			}
			AccountCtrl.canDeactivate = FeatureHelper.isAvailable(FeatureHelper.Feature.INACTIVE_COMPANIES);
			AccountCtrl.hasNewFields = FeatureHelper.hasSoftDeployAccess('NEW_FIELDS');
			AccountCtrl.hasClientTarget =
				FeatureHelper.hasSoftDeployAccess('CLIENT_TARGET') &&
				FeatureHelper.isAvailable(FeatureHelper.Feature.CLIENT_TARGET);
			AccountCtrl.hasSupportService = FeatureHelper.isAvailable(FeatureHelper.Feature.CUSTOMER_SUPPORT);

			AccountCtrl.hasClientOpportunities = FeatureHelper.isAvailable(FeatureHelper.Feature.CLIENT_OPPORTUNITIES);
			AccountCtrl.hasClientActivities = FeatureHelper.isAvailable(FeatureHelper.Feature.CLIENT_ACTIVITIES);
			AccountCtrl.hasClientMarketing = FeatureHelper.isAvailable(FeatureHelper.Feature.CLIENT_MARKETING);
			AccountCtrl.hasClientDocuments = FeatureHelper.isAvailable(FeatureHelper.Feature.CLIENT_DOCUMENTS);
			AccountCtrl.hasClientEsigns = FeatureHelper.isAvailable(FeatureHelper.Feature.CLIENT_ESIGNS);
			AccountCtrl.hasClientCardRelations = FeatureHelper.isAvailable(FeatureHelper.Feature.CLIENT_CARD_RELATIONS);
			AccountCtrl.showTabs = false;
			AccountCtrl.masterCurrency = _.find(metadata.customerCurrencies, 'masterCurrency');

			AccountCtrl.clientConnectionName = metadata.params.clientOrderRelation;

			$scope.customerId = customerId;

			AccountCtrl.salesModel = metadata.params.SalesModel;
			AccountCtrl.salesModelOption = metadata.params.SalesModelOption;

			AccountCtrl.orderStages = meta.orderStages.data;
			AccountCtrl.showAccountManagers = false;
			$scope.categoryTypes = AppService.getCategoryTypes('account');

			$scope.standardFields = {};
			$scope.standardFields.standard = _.filter(metadata.standardFields.Client, function (field) {
				return field.group === 'standard' && field.canHide && field.active;
			});
			AccountCtrl.aboutActive =
				metadata.standardFields.Client.About && metadata.standardFields.Client.About.active;
			handleSocialLinks();

			getRatingLabel();
			AccountCtrl.multipleAccountManagers = metadata.params.teamAccountManager;

			accountManagers = _.cloneDeep(AccountCtrl.account.users);
			if (!AccountCtrl.multipleAccountManagers && Array.isArray(AccountCtrl.account.users)) {
				AccountCtrl.accountManager = AccountCtrl.account.users[0];
			}

			generateCustomFields(AccountCtrl.account.custom);
			AccountCtrl.leaderActivated = metadata.map.tracking;
			AccountCtrl.mailActivated = true;
			AccountCtrl.metadata = metadata;
			AccountCtrl.defaultCurrencyFormat = new CurrencyFormat(AccountCtrl.metadata.defaultCurrency.iso);

			documentTemplates = {
				account: meta.documentTemplates.data,
				order: meta.documentTemplatesOrder.data,
				agreement: meta.documentTemplatesAgreement.data
			};

			AccountCtrl.hasDocumentTemplates = meta.documentTemplates.data && meta.documentTemplates.data.length;
			AccountCtrl.hasDocumentTemplatesOrder =
				meta.documentTemplatesOrder.data && meta.documentTemplatesOrder.data.length;
			AccountCtrl.hasDocumentTemplatesAgreement =
				meta.documentTemplatesAgreement.data && meta.documentTemplatesAgreement.data.length;

			AccountCtrl.recurringOrderActive =
				metadata.params.AgreementEnabled &&
				FeatureHelper.isAvailable(FeatureHelper.Feature.RECURRING_ORDER) &&
				AppService.getAccessRights().Agreement;

			AccountCtrl.hasDocumentFeature = FeatureHelper.isAvailable(FeatureHelper.Feature.DOCUMENTS);

			AccountCtrl.hasFileStorageApps = AppService.getFileStorageIntegrations().length;

			var esignIntegrations = AppService.getEsignIntegrations();
			AccountCtrl.hasEsign = FeatureHelper.isAvailable(FeatureHelper.Feature.ESIGN) && esignIntegrations.length;

			if (AccountCtrl.account.priceList && !AccountCtrl.account.priceList?.active) {
				AccountCtrl.account.priceList.name = getInactiveName(AccountCtrl.account.priceList.name);
			}

			const account = AccountCtrl.account;
			const isSynced = account.dunsNo || account.prospectingId;
			const willShowRefresh = AccountCtrl.account.userEditable && AccountCtrl.showRefreshAccount;
			if (!isSynced && willShowRefresh) {
				const integrations = getIntegrations({ account });
				const promises = integrations.map(dataSource =>
					makeSearch({ dataSource, account, duns: account.dunsNo, limit: 0 })
				);
				Promise.all(promises)
					.then(results => {
						const foundMatch = results.some(res => res?.resMetadata?.ALL > 0);
						if (foundMatch) {
							AccountCtrl.shouldSync = true;
							$safeApply($scope);
						}
					})
					.catch(err => {
						logError(err);
					});
			}

			saveLatestAccount(AccountCtrl.account);

			if (AccountCtrl.account.addresses && AccountCtrl.account.addresses.length) {
				AccountCtrl.hasCountry = AccountCtrl.account.addresses[0].country ? true : false;
				AccountCtrl.visitAddress = _.find(AccountCtrl.account.addresses, { type: 'Visit' });
			}

			const timerMap = {};
			AccountCtrl.toggleTodoDone = function (e, activity) {
				if (e) {
					e.stopPropagation();
				}
				activity.hasChanged = !activity.hasChanged;
				activity.closeDate = activity.closeDate ? null : new Date();
				if (timerMap[activity.id]) {
					clearTimeout(timerMap[activity.id]);
				}
				timerMap[activity.id] = setTimeout(() => {
					delete timerMap[activity.id];
					if (activity.hasChanged) {
						Activity.customer(customerId).save(activity);
					}
				}, 3000);
			};

			// Fix webpage
			fixWebpage();

			checkforUDO();

			// GET IT for the tabs!
			var sidebarPromise = [
				getNOActivities(),
				getNOOpportunities(),
				getNOOrder(),
				getNORelatedClients(),
				getFilesAndEsign(),
				getNoUDO(),
				getNOSupportTickets(),
				getNOOngoingProjects(),
				getNOProjects()
			];

			$q.all(sidebarPromise)
				.then(function () {
					AccountCtrl.showTabs = true;
				})
				.catch(e => {
					logError(e, 'Failed to load sidebar promise');
				});

			getStages();
			getUsers();

			// Map custom
			mapCustomFields();

			buildEconomyChart();

			// Check for UDO on client then find some for this account

			ScriptService.account.open(AccountCtrl.account);

			// SI ui-elements
			if (metadata.integrations && metadata.integrations.uiElements) {
				AccountCtrl.hasAppFrameworkUiElementsAccountCardTop = FeatureHelper.hasSoftDeployAccess(
					'APP_FRAMEWORK_UI_ELEMENTS_TOP_OF_ACCOUNT_CARD'
				);
				AccountCtrl.uiElements = metadata.integrations.uiElements.account;
			}
		};

		// init
		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);
