import RequestBuilder, { comparisonTypes as ComparisonTypes } from 'Resources/RequestBuilder';
import { ListViewTableProvided } from 'App/components/ListView/ListViewTable/ListViewTable';
import { ListViewDefaultColumn } from 'App/components/ListView/ListViewRenderHelpers';
import ActionColumn, { Action } from '../../CompanyGroupCard/ActionColumn';
import ActivityAttributes from 'App/babel/attributes/ActivityAttributes';
import activityFilters from 'App/babel/filterConfigs/Activity';
import { useTranslation } from 'Components/Helpers/translate';
import { TableColumn, TableRow } from '@upsales/components';
import ActivityListResource from 'App/resources/ActivityList';
import type Activity from 'App/resources/Model/Activity';
import ListView from 'App/components/ListView';
import React, { useCallback, useMemo, useState } from 'react';
import { closedActivitiesFilter, openActivitiesFilter } from './ActivityListHelpers';
import TodoListTableRowDescriptionCol from 'App/pages/TodoList/TodoListTableRow/TodoListTableRowDescriptionCol';
import BemClass from '@upsales/components/Utils/bemClass';

type Props = {
	columns?: string[];
	clientIds: number[];
	hideHeader?: boolean;
	filterValue: 'open' | 'closed';
	getDataFilter: (rb: RequestBuilder, clientIds: number[]) => void;
};

const ActivityList = ({
	clientIds,
	hideHeader,
	filterValue,
	getDataFilter,
	columns = ['activity', 'client', 'contact', 'description', 'opportunity', 'users', '']
}: Props) => {
	const { t } = useTranslation();
	const ActivityFilters = useMemo(() => activityFilters(), []);
	const classes = new BemClass('ActivityList');
	const [initialSorting, setInitialSorting] = useState([
		{ attribute: 'client.operationalAccount.name', ascending: true },
		{ attribute: 'client.name', ascending: true }
	]);

	const customActivityAttributes = useMemo(() => {
		const attributesCopy = { ...ActivityAttributes };
		attributesCopy.contact = {
			...attributesCopy.contact,
			title: 'todoListTable.withWhom'
		};
		return attributesCopy;
	}, []);

	const renderTableRow = (activity: Activity, { columns, attributes }: ListViewTableProvided<Activity>) => {
		const goTo = (activity: Activity) => {
			Tools.$upModal.open(activity.isAppointment ? 'editAppointment' : 'editActivity', { id: activity.id });
		};

		const columnElements = columns.map(column => {
			let content = null;
			switch (column) {
				case 'relations': {
					return <TodoListTableRowDescriptionCol todo={activity} />;
				}
				case '': {
					content = (
						<ActionColumn object={activity} entity="activity" actions={[Action.Edit, Action.Delete]} />
					);
					break;
				}

				default: {
					return (
						<ListViewDefaultColumn<Activity>
							key={column}
							item={activity}
							attributes={attributes}
							column={column}
						/>
					);
				}
			}
			return <TableColumn key={column + activity.id}>{content}</TableColumn>;
		});

		return (
			<TableRow key={activity.id} onClick={() => goTo(activity)}>
				{columnElements}
			</TableRow>
		);
	};

	const getData = useCallback(
		async (rb: RequestBuilder) => {
			if (clientIds.length === 0) {
				return {
					data: [],
					metadata: { total: 0, limit: 50, offset: 0 }
				};
			}

			getDataFilter(rb, clientIds);
			rb.addFilter({ field: 'projectPlan.id' }, ComparisonTypes.Equals, null);

			if (filterValue === 'open') {
				openActivitiesFilter(rb);
			}
			if (filterValue === 'closed') {
				closedActivitiesFilter(rb);
			}

			return ActivityListResource.find(rb.build(), { includes: 'activities,appointments' });
		},
		[clientIds, filterValue]
	);

	return (
		<ListView<Activity>
			key={initialSorting.map(sort => `${sort.ascending}`).join('-')}
			filterConfigs={ActivityFilters}
			hideFilters
			className={classes.b()}
			broadcastType="activity"
			getData={getData}
			attributes={customActivityAttributes}
			renderTableRow={renderTableRow}
			columns={columns}
			skipSortById
			renderHeader={hideHeader ? () => null : undefined}
			quickSearchFilter={'ListSearch'}
			quickSearchPlaceholder={t('account.searchActivitesPlaceHolder')}
			renderToolsColumn={false}
			canSortCustomFields={false}
			initialSorting={initialSorting}
			onSortChange={({ field }) => {
				if (field === 'client.operationalAccount.name') {
					setInitialSorting(prev =>
						prev.map(sort => ({
							...sort,
							ascending: !sort.ascending,
							missing: sort.ascending ? 'first' : 'last'
						}))
					);
				}
			}}
		/>
	);
};

export default ActivityList;
