require('../../upsales/domain/special/whoCalled.js');

require('../../upsales/domain/invite/invite.js');
require('../../upsales/domain/invite/meta/invite.js');

require('../../upsales/domain/account/account.js');
require('../../upsales/domain/account/controllers/listAccounts.js');
require('../../upsales/domain/account/controllers/editAccount.js');
require('../../upsales/domain/account/controllers/account.js');
require('../../upsales/domain/account/controllers/accountDashboard.js');
require('../../upsales/domain/account/controllers/accountActivities.js');
require('../../upsales/domain/account/controllers/accountOpportunities.js');
require('../../upsales/domain/account/controllers/accountMarket.js');
require('../../upsales/domain/account/controllers/accountOrders.js');
require('../../upsales/domain/account/controllers/accountAddresses.js');
require('../../upsales/domain/account/controllers/updateClientMulti.js');
require('../../upsales/domain/account/controllers/accountFiles.js');
require('../../upsales/domain/account/controllers/accountApps.js');
require('../../upsales/domain/account/controllers/accountSupport.js');
require('../../upsales/domain/account/controllers/accountProjectPlan.ts');
require('../../upsales/domain/account/meta/editAccount.js');
require('../../upsales/domain/account/meta/account.js');
require('../../upsales/domain/account/meta/updateClientMulti.js');
require('../../upsales/domain/account/meta/mergeClients.js');
require('../../upsales/domain/account/controllers/accountUdo.js');

require('../../upsales/domain/activity/activity.js');
require('../../upsales/domain/activity/controllers/listActivities.js');

require('../../upsales/domain/order/order.js');
require('../../upsales/domain/order/controllers/editOrder.js');
require('../../upsales/domain/order/controllers/updateOrderMulti.js');
require('../../upsales/domain/order/controllers/stageList.js');
require('../../upsales/domain/order/meta/editOrder.js');
require('../../upsales/domain/order/meta/updateOrderMulti.js');

require('../../upsales/domain/agreement/agreement.js');
require('../../upsales/domain/agreement/controllers/listAgreements.js');
require('../../upsales/domain/agreement/controllers/editAgreement.js');
require('../../upsales/domain/agreement/meta/editAgreement.js');

require('../../upsales/domain/opportunity/opportunity.js');
require('../../upsales/domain/opportunity/controllers/listPeriodizations.js');

require('../../upsales/domain/activity/controllers/editActivity.js');
require('../../upsales/domain/activity/controllers/closeActivityMulti.js');
require('../../upsales/domain/activity/controllers/createActivityMulti.js');
require('../../upsales/domain/activity/controllers/updateActivityMulti.js');
require('../../upsales/domain/activity/controllers/moveOrCloseActivity.js');
require('../../upsales/domain/activity/meta/updateActivityMulti.js');
require('../../upsales/domain/activity/meta/createActivity.js');
require('../../upsales/domain/activity/meta/editActivity.js');

require('../../upsales/domain/appointment/appointment.js');
require('../../upsales/domain/appointment/controllers/listAppointment.js');
require('../../upsales/domain/appointment/controllers/updateAppointmentMulti.js');
require('../../upsales/domain/appointment/meta/editAppointment.js');
require('../../upsales/domain/appointment/meta/updateAppointmentMulti.js');

require('../../upsales/domain/contact/contact.js');
require('../../upsales/domain/contact/controllers/editContact.js');
require('../../upsales/domain/contact/controllers/contact.js');
require('../../upsales/domain/contact/controllers/listContacts.js');
require('../../upsales/domain/contact/controllers/contactDashboard.js');
require('../../upsales/domain/contact/controllers/contactActivities.js');
require('../../upsales/domain/contact/controllers/contactOpportunities.js');
require('../../upsales/domain/contact/controllers/contactOrder.js');
require('../../upsales/domain/contact/controllers/contactMarket.js');
require('../../upsales/domain/contact/controllers/contactRelations.js');
require('../../upsales/domain/contact/controllers/updateContactMulti.js');
require('../../upsales/domain/contact/controllers/moveContact.js');
require('../../upsales/domain/contact/controllers/editContactRelation.js');
require('../../upsales/domain/contact/controllers/contactFiles.js');
require('../../upsales/domain/contact/controllers/mergeContacts.js');
require('../../upsales/domain/contact/controllers/contactUdo.js');
require('../../upsales/domain/contact/controllers/contactSupport.ts');
require('../../upsales/domain/contact/meta/editContact.js');
require('../../upsales/domain/contact/meta/contact.js');
require('../../upsales/domain/contact/meta/updateContactMulti.js');
require('../../upsales/domain/contact/meta/mergeContacts.js');

require('../../upsales/domain/lead/lead.js');
require('../../upsales/domain/lead/controllers/editLeads.js');
require('../../upsales/domain/lead/controllers/resetLeadMulti.js');
require('../../upsales/domain/lead/meta/editLead.js');

require('../../upsales/domain/campaign/campaign.js');
require('../../upsales/domain/campaign/controllers/campaign.js');
require('../../upsales/domain/campaign/controllers/editCampaign.js');
require('../../upsales/domain/campaign/controllers/campaignDashboard.js');
require('../../upsales/domain/campaign/controllers/campaignSocialEvents.js');
require('../../upsales/domain/campaign/controllers/campaignContacts.js');
require('../../upsales/domain/campaign/controllers/campaignActivities.js');
require('../../upsales/domain/campaign/controllers/campaignAppointments.js');
require('../../upsales/domain/campaign/controllers/campaignOpportunities.js');
require('../../upsales/domain/campaign/controllers/campaignOrder.js');
require('../../upsales/domain/campaign/controllers/campaignAccounts.js');
require('../../upsales/domain/campaign/controllers/campaignForms.js');
require('../../upsales/domain/campaign/controllers/campaignMailinglists.js');
require('../../upsales/domain/campaign/meta/editCampaign.js');
require('../../upsales/domain/campaign/meta/campaign.js');
require('../../upsales/domain/campaign/controllers/campaignUdo.js');

require('../../upsales/domain/voice/voice.js');
require('../../upsales/domain/voice/controllers/listPhoneCalls.js');
require('../../upsales/domain/voice/controllers/openCall.js');

require('../../upsales/domain/admin/admin.js');
require('../../upsales/domain/admin/controllers/admin.js');
require('../../upsales/domain/admin/controllers/main.js');
require('../../upsales/domain/admin/controllers/sub.js');
require('../../upsales/domain/admin/controllers/settings/settings.js');
require('../../upsales/domain/admin/controllers/automations/listAutomations.js');
require('../../upsales/domain/admin/controllers/automations/editAutomation.js');
require('../../upsales/domain/admin/controllers/automations/editAction.js');
require('../../upsales/domain/admin/controllers/automations/createLeadAction.js');
require('../../upsales/domain/admin/controllers/automations/sendEmailAction.js');
require('../../upsales/domain/admin/controllers/automations/updateClientAction.js');
require('../../upsales/domain/admin/controllers/automations/updateContactAction.js');
require('../../upsales/domain/admin/controllers/automations/sendWebhookAction.js');
require('../../upsales/domain/admin/controllers/automations/createActivityAction.js');
require('../../upsales/domain/admin/controllers/automations/createAppointmentAction.js');
require('../../upsales/domain/admin/controllers/EditRole.js');
require('../../upsales/domain/admin/controllers/triggers/listTriggers.js');
require('../../upsales/domain/admin/controllers/triggers/editTrigger.js');
require('../../upsales/domain/admin/controllers/mail/mailAdmin.js');
require('../../upsales/domain/admin/meta/mail/mailAdmin.js');
require('../../upsales/domain/admin/controllers/standardIntegration/configureIntegration.js');
require('../../upsales/domain/admin/controllers/standardIntegration/editIntegration.js');
require('../../upsales/domain/admin/controllers/standardIntegration/integrations.js');
require('../../upsales/domain/admin/meta/salesboard/salesboards.js');
require('../../upsales/domain/admin/controllers/salesboard/salesboards.js');
require('../../upsales/domain/admin/meta/salesboard/salesboard.js');
require('../../upsales/domain/admin/controllers/salesboard/salesboard.js');
require('../../upsales/domain/admin/meta/dynamiclink/dynamiclink.js');
require('../../upsales/domain/admin/controllers/dynamiclink/dynamiclinks.js');
require('../../upsales/domain/admin/controllers/visit/marketRejectlist.js');
require('../../upsales/domain/admin/controllers/visit/visitSettings.js');
require('../../upsales/domain/admin/meta/engage/engage.js');
require('../../upsales/domain/admin/controllers/engage/engage.js');
require('../../upsales/domain/admin/controllers/settings/accountProfile.js');
require('../../upsales/domain/admin/controllers/apiKey/listApiKeys.js');
require('../../upsales/domain/admin/controllers/apiKey/editApikeyModal.js');
require('../../upsales/domain/admin/controllers/currencies/currencySettings.js');
require('../../upsales/domain/admin/controllers/accountSettings.js');
require('../../upsales/domain/admin/controllers/looker.js');
require('../../upsales/domain/admin/controllers/activityQuota.js');
require('../../upsales/domain/admin/controllers/salesQuota.js');
require('../../upsales/domain/admin/controllers/genericQuota.js');
require('../../upsales/domain/admin/controllers/user/editUser.js');
require('../../upsales/domain/admin/controllers/agreement/agreement.js');
require('../../upsales/domain/admin/controllers/editDocumentTemplateModal.js');
require('../../upsales/domain/admin/controllers/import/import.js');
require('../../upsales/domain/admin/controllers/deleteLog.js');
require('../../upsales/domain/admin/controllers/category.js');

require('../../upsales/domain/admin/controllers/AddProductCategoryModal.js');

require('../../upsales/common/helpers/optin.js');
require('../../upsales/domain/admin/controllers/optIn/optInAdmin.js');
require('../../upsales/domain/admin/controllers/optIn/optInModal.js');

require('../../upsales/domain/mail/mail.js');
require('../../upsales/domain/mail/controllers/scheduleMail.js');
require('../../upsales/domain/mail/controllers/startMailCampaign.js');
require('../../upsales/domain/mail/controllers/sendEmail.js');
require('../../upsales/domain/mail/controllers/listMailTemplates.js');
require('../../upsales/domain/mail/controllers/previewInfo.js');
require('../../upsales/domain/mail/controllers/mailCampaign.js');
require('../../upsales/domain/mail/controllers/mailCampaignDashboard.js');
require('../../upsales/domain/mail/controllers/mailCampaignVisits.js');
require('../../upsales/domain/mail/controllers/mailCampaignSubmits.js');
require('../../upsales/domain/mail/controllers/mailCampaignRecipients.js');

require('../../upsales/domain/mail/meta/sendEmail.js');
require('../../upsales/domain/mail/controllers/sentMail.js');
require('../../upsales/domain/mail/meta/sentMail.js');
require('../../upsales/domain/mail/meta/mailCampaign.js');

require('../../upsales/domain/scoreboard/scoreboard.js');
require('../../upsales/domain/scoreboard/controllers/resetScore.js');
require('../../upsales/domain/scoreboard/controllers/scoreDrilldown.js');
require('../../upsales/domain/scoreboard/controllers/selectUser.js');

require('../../upsales/domain/soliditet/soliditet.js');
require('../../upsales/domain/soliditet/controllers/findProspects.js');
require('../../upsales/domain/soliditet/controllers/confirmSoliditetUpdate.js');
require('../../upsales/domain/soliditet/controllers/confirmSoliditetBuyParent.js');
require('../../upsales/domain/soliditet/controllers/confirmBuyProspects.js');
require('../../upsales/domain/soliditet/controllers/updateClientDuns.js');
require('../../upsales/domain/soliditet/controllers/matcher.js');
require('../../upsales/domain/soliditet/controllers/clientListModal.js');

require('../../upsales/domain/soliditet/meta/confirmSoliditetUpdate.js');
require('../../upsales/domain/soliditet/meta/confirmSoliditetBuyParent.js');
require('../../upsales/domain/soliditet/meta/updateClientDuns.js');

require('../../upsales/domain/form/form.js');
require('../../upsales/domain/form/controllers/formSubmit.js');
require('../../upsales/domain/form/controllers/editFormSubmit.js');
require('../../upsales/domain/form/controllers/landingpageEditor.js');
require('../../upsales/domain/form/controllers/selectField.js');
require('../../upsales/domain/form/controllers/validDomainController.js');
require('../../upsales/domain/form/meta/form.js');
require('../../upsales/domain/form/meta/formEditor.js');
require('../../upsales/domain/form/meta/landingpageEditor.js');

require('../../upsales/domain/userdefinedobject/userdefobj.js');
require('../../upsales/domain/userdefinedobject/meta/editUserDefinedObject.js');
require('../../upsales/domain/userdefinedobject/controller/editUserDefinedObject.js');

require('../../upsales/domain/engage/engage.js');
require('../../upsales/domain/engage/meta/list.js');
require('../../upsales/domain/engage/meta/edit.js');
require('../../upsales/domain/engage/meta/view.js');
require('../../upsales/domain/engage/controllers/list.js');
require('../../upsales/domain/engage/controllers/edit.js');
require('../../upsales/domain/engage/controllers/view.js');
require('../../upsales/domain/engage/controllers/viewDashboard.js');
require('../../upsales/domain/engage/controllers/viewAccounts.js');
require('../../upsales/domain/engage/controllers/viewCreatives.js');
require('../../upsales/domain/engage/meta/creativeEditor.js');
require('../../upsales/domain/engage/meta/selectCreativeTemplate.js');
require('../../upsales/domain/engage/controllers/creativeEditor.js');
require('../../upsales/domain/engage/controllers/selectCreativeTemplate.js');

require('../../upsales/domain/looker/looker.js');

require('../../upsales/domain/advanced-search/advancedSearch.js');
require('../../upsales/domain/advanced-search/controllers/advancedSearch.js');
require('../../upsales/domain/advanced-search/controllers/advancedSearchAccount.js');
require('../../upsales/domain/advanced-search/controllers/advancedSearchContact.js');
require('../../upsales/domain/advanced-search/controllers/advancedSearchActivity.js');
require('../../upsales/domain/advanced-search/controllers/advancedSearchAppointment.js');
require('../../upsales/domain/advanced-search/controllers/advancedSearchOpportunity.js');
require('../../upsales/domain/advanced-search/controllers/advancedSearchOrder.js');

require('../../upsales/domain/advanced-search/directives/accountInfoSearchSection.js');
require('../../upsales/domain/advanced-search/directives/contactInfoSearchSection.js');
require('../../upsales/domain/advanced-search/directives/accountLocationSearchSection.js');
require('../../upsales/domain/advanced-search/directives/accountContactinfoSearchSection.js');
require('../../upsales/domain/advanced-search/directives/accountHistorySearchSection.js');
require('../../upsales/domain/advanced-search/directives/customfieldSearchSection.js');
require('../../upsales/domain/advanced-search/directives/contactContactinfoSearchSection.js');
require('../../upsales/domain/advanced-search/directives/marketeventsSearchSection.js');
require('../../upsales/domain/advanced-search/directives/activityInfoSearchSection.js');
require('../../upsales/domain/advanced-search/directives/appointmentInfoSearchSection.js');
require('../../upsales/domain/advanced-search/directives/advancedSearchViewPicker.js');
require('../../upsales/domain/advanced-search/directives/opportunityInfoSearchSection.js');
require('../../upsales/domain/advanced-search/directives/orderInfoSearchSection.js');
require('../../upsales/domain/advanced-search/directives/sidebar.js');

require('../../upsales/domain/esign/esign.js');
require('../../upsales/domain/esign/controllers/listEsign.js');
require('../../upsales/domain/esign/controllers/editEsign.js');
require('../../upsales/domain/esign/controllers/confirmEsign.js');
require('../../upsales/domain/esign/meta/editEsign.js');
require('../../upsales/domain/esign/meta/confirmEsign.js');

require('../../upsales/domain/onboarding/onboarding.js');
require('../../upsales/domain/onboarding/controllers/productPlaceholder.js');
require('../../upsales/domain/onboarding/controllers/featurePlaceholder.js');

require('../../upsales/domain/segment/segment.js');
require('../../upsales/domain/segment/controllers/editFlow.js');
require('../../upsales/domain/segment/controllers/editSegment.js');
require('../../upsales/domain/segment/controllers/newSegment.js');
require('../../upsales/domain/segment/controllers/flowEditor.js');

require('../../upsales/domain/segment/controllers/newSegmentModal.js');
require('../../upsales/domain/segment/controllers/addToSegmentModal.js');
require('../../upsales/domain/segment/meta/editSegment.js');
