import { hot } from 'react-hot-loader/root';
import React from 'react';
import { Provider } from 'react-redux';
import store from './babel/store';
import Routes from 'App/pages/routes';
import AngularApp from './AngularApp';
import Modals from './components/Modals';
import AppOnboarding from 'App/pages/AppOnboarding'; // Rename if not intended to replace current onboarding
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SystemNotifications from 'Components/SystemNotifications/SystemNotifications';
import { Route, HashRouter as Router } from 'react-router-dom';
import Login from 'App/pages/Login';
import { doStaticSetup } from './helpers/appHelper';
import Session, { onlyRenderWhenAuthenticated } from './Session';
import NavbarComponent from './navbar/Navbar';
import AppSidebarComponent from 'App/components/AppSidebar';
import MeasureUI from 'App/components/MeasureUI';
import { useFeature } from './services/feature';

doStaticSetup();

const Navbar = onlyRenderWhenAuthenticated(NavbarComponent);
const AppSidebar = onlyRenderWhenAuthenticated(AppSidebarComponent);

const App = () => {
	const removeAngular = useFeature('REACT_LOGIN');
	window.useReactInit = removeAngular; // set window variable so angular can check it

	return (
		<Router>
			<Provider store={store}>
				{/* Make Session component handle init and dispatch actions */}
				<Session removeAngular={removeAngular}>
					<MeasureUI>
						{removeAngular ? <Route path="/login" component={Login} /> : null}
						<AngularApp>
							<DndProvider backend={HTML5Backend}>
								<div>
									<Navbar />
									<AppSidebar />
									<Routes />
									<Modals />
									<AppOnboarding />
									{/* Port this one to use new modal service later on */}
								</div>
								<SystemNotifications />
							</DndProvider>
						</AngularApp>
					</MeasureUI>
				</Session>
			</Provider>
		</Router>
	);
};

export default hot(App);
