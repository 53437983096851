import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import { Avatar, Block, Button, Headline, Icon, Input, Text } from '@upsales/components';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import './LoginForm.scss';
import { DefaultButton } from '@upsales/components/Buttons';
import LoginError, { LoginErrors } from '../LoginError';
import { getEnvString, getSavedUser, login, setSavedUser, getLoginMethod } from 'App/helpers/loginHelper';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import logError from 'Helpers/logError';
import { SlideFade } from '@upsales/components/animations';

type Props = {
	onResetPassword: () => void;
	onForceResetPassword: ({ email, oldPassword }: { email: string; oldPassword: string }) => void;
	on2fa: (token: string) => void;
	setRememberMe: (rememberMe: boolean) => void;
	rememberMe: boolean;
};

type SessionRes = Awaited<ReturnType<typeof login>>;
type LoginMethodRes = Awaited<ReturnType<typeof getLoginMethod>>;

const LoginForm = ({ onResetPassword, onForceResetPassword, on2fa, setRememberMe, rememberMe }: Props) => {
	const classes = new BemClass('LoginForm');
	const { t } = useTranslation();
	const [loginLoading, setLoginLoading] = useState(false);
	const [gettingMethodInit, setGettingMethodInit] = useState(true);
	const [isSSO, setIsSSO] = useState(false);
	const [error, setError] = useState<null | LoginErrors>(null);
	const [savedUserName, setSavedUserName] = useState<null | string>(null);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [SSOUrl, setSSOURL] = useState<string | null>(null);
	const envString = getEnvString();
	const loginReq = useRef<null | CancelablePromise<SessionRes>>(null);
	const methodReq = useRef<null | CancelablePromise<LoginMethodRes>>(null);

	const doGetLoginMethod = (email: string, noRedirect?: boolean) => {
		methodReq.current = makeCancelable(getLoginMethod(email, noRedirect));

		methodReq.current.promise
			.then(res => {
				setGettingMethodInit(false);
				if (res.method === 'saml') {
					setSSOURL(res.url);
					setIsSSO(true);
				}
			})
			.catch(e => logError(e, 'Failed to get login method'));
	};

	useEffect(() => {
		const savedUser = getSavedUser();

		// If user was saved we hide the passwordField until we know is the user has sso or not
		if (savedUser) {
			setEmail(savedUser.email);
			setSavedUserName(savedUser.name);
			setRememberMe(true);
			doGetLoginMethod(savedUser.email, true);
		} else {
			setGettingMethodInit(false);
		}

		return () => {
			// Cancel any pending requests
			loginReq.current?.cancel();
			methodReq.current?.cancel();
		};
	}, []);

	const clearRememberedUser = () => {
		setEmail('');
		setSavedUserName(null);
		setSavedUser(null, null);
		setIsSSO(false);
		setSSOURL(null);
	};

	const doLogin = () => {
		// If user was quick to click login and has SSO we redirect to SSOUrl
		if (SSOUrl) {
			window.location.href = SSOUrl;
			return;
		}

		if (!email || !password) {
			return;
		}
		setLoginLoading(true);

		// Reset any prev errors
		setError(null);

		loginReq.current = makeCancelable(login(email, password, rememberMe));

		loginReq.current.promise
			.then(res => {
				if (res.isTwoFactorAuth) {
					on2fa(res.token);
				}
			})
			.catch(err => {
				if (err === LoginErrors.FORCE_RESET_PASSWORD) {
					onForceResetPassword({ oldPassword: password, email });
					return;
				}
				setError(err);
			})
			.finally(() => {
				setLoginLoading(false);
			});
	};

	return (
		<div className={classes.b()}>
			<form
				onSubmit={e => {
					e.preventDefault();
					doLogin();
				}}
			>
				<Headline size="md" color="super-light-green">
					{savedUserName ? t('default.welcomeBack') : t('default.loginToUpsales')}
				</Headline>
				<Block space="mtxl mbl">
					{savedUserName ? (
						<Block
							className={classes.elem('saved-user').b()}
							onClick={() => clearRememberedUser()}
							space="pll mbxl"
						>
							<Avatar size="lg" email={email} initials={savedUserName} />
							<Text color="super-light-green" size="xl" bold>
								{savedUserName}
							</Text>
							<Icon name="times" />
						</Block>
					) : (
						<Fragment>
							<Input
								size="lg"
								placeholder={t('default.email')}
								value={email}
								disabled={loginLoading}
								onChange={e => setEmail(e.target.value)}
								onBlur={() => doGetLoginMethod(email)}
							/>
							{email.length ? (
								<div
									className={classes.elem('remember-me').b()}
									onClick={() => setRememberMe(!rememberMe)}
								>
									{rememberMe ? <Icon name="check" space="mrs" /> : null}
									{t('default.rememberMe')}
								</div>
							) : null}
						</Fragment>
					)}
				</Block>
				<SlideFade visible={!gettingMethodInit && !isSSO} direction="top" height>
					<Input
						size="lg"
						type="password"
						placeholder={t('default.password')}
						value={password}
						disabled={loginLoading}
						onChange={e => setPassword(e.target.value)}
					/>
				</SlideFade>
				{error ? <LoginError error={error} onClear={() => setError(null)} /> : null}
				<Block space="mtxl">
					<DefaultButton
						submit
						block
						size="xl"
						loading={loginLoading}
						disabled={!email || (!password && !isSSO)}
					>{`${t('default.login')}${envString ? ' ' + envString : ''}`}</DefaultButton>
				</Block>
			</form>
			<Block space="mtxl">
				<Button
					type="link"
					color="super-light-green"
					block
					size="lg"
					disabled={loginLoading}
					onClick={() => onResetPassword()}
				>
					{t('login.forgotPass')}
				</Button>
			</Block>
		</div>
	);
};

export default LoginForm;
