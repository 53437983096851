import React from 'react';
import { Flex, Headline, Text, Title, ButtonSelect } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import { DefaultButton, PrimaryButton } from '@upsales/components/Buttons';
import BemClass from '@upsales/components/Utils/bemClass';
import { useMetadata } from 'App/components/hooks/appHooks';

import './CompanyGroupTreeHeader.scss';

type CompanyGroupTreeHeaderProps = {
	groupSales?: string;
	groupPipeline?: string;
	numberOfCustomers: number | null;
	numberOfContacts: number | null;
	tree: any;
	treeMeta: any;
	showConsolidated?: boolean;
	view?: string;
	setView?: (view: string) => void;
	isRelationsTab?: boolean;
	addAllAccounts?: () => void;
	goToCompanyGroup?: () => void;
	manualRelationsCounter?: number;
};

const CompanyGroupTreeHeader = ({
	groupSales,
	groupPipeline,
	numberOfCustomers,
	numberOfContacts,
	tree,
	treeMeta,
	showConsolidated,
	setView,
	view,
	isRelationsTab,
	addAllAccounts,
	goToCompanyGroup,
	manualRelationsCounter
}: CompanyGroupTreeHeaderProps) => {
	const classes = new BemClass('CompanyGroupTreeHeader');
	const { t } = useTranslation();
	const metadata = useMetadata();
	const { SalesModelOption: salesModelOption } = metadata?.params || {
		SalesModel: 'sales'
	};

	const groupSalesSubtitle = () => {
		switch (salesModelOption) {
			case 'arr':
				return t('default.currentARR');
			case 'mrr':
				return t('default.currentMRR');
			case 'cm':
				return t('default.contributionMargin12Months');
			default:
				return t('default.sales12Months');
		}
	};

	return (
		<>
			{showConsolidated ? <Title space="pll prl ptl">{t('account.relations.showConsolidated')}</Title> : null}
			<Flex className={classes.elem('salesNumbers').b()} justifyContent="space-between" space="pll prl ptl pbl">
				{[
					{
						headline: groupSales,
						text: groupSalesSubtitle()
					},
					{
						headline: groupPipeline,
						text: 'companyGroup.startPage.widget.pipeline'
					},
					{
						loading: numberOfCustomers === null || tree === null,
						headline: `${numberOfCustomers ?? 0}/${treeMeta.total - treeMeta.unknowns.length}`,
						text: 'companyGroup.startPage.widget.customers'
					},
					{
						loading: numberOfContacts === null,
						headline: numberOfContacts,
						text: 'companyGroup.startPage.widget.contacts'
					}
				]
					.filter(v => !!v)
					.map((item, i) => (
						<Flex key={i} direction="column">
							<Headline className={classes.elem('header').b()} loading={item?.loading}>
								{item?.headline}
							</Headline>
							<Text size="sm" color="grey-11" loading={item?.loading}>
								{item ? t(item.text) : ''}
							</Text>
						</Flex>
					))}
			</Flex>
			{isRelationsTab ? (
				<>
					<Flex space="mbl mll">
						<PrimaryButton onClick={goToCompanyGroup} size="sm">
							{t('account.showGroup.overview')}
						</PrimaryButton>
					</Flex>
					<Flex space="ptl pll prl pbl" gap="u2" justifyContent="space-between" className={classes.b()}>
						<ButtonSelect
							size="sm"
							options={[
								{ value: 'groupTree', title: t('account.relations.groupSize.groupSize') },
								{
									value: 'manualRelations',
									title:
										manualRelationsCounter === 1
											? manualRelationsCounter + ' ' + t('account.relation.manual').toLowerCase()
											: manualRelationsCounter + ' ' + t('account.relations.manual').toLowerCase()
								}
							]}
							onChange={v => setView && setView(v ?? 'groupTree')}
							value={view}
						></ButtonSelect>
						{view === 'groupTree' ? (
							<DefaultButton icon={'plus'} size="sm" onClick={addAllAccounts}>
								{t('account.relations.groupSize.addEntireGroupSize')}
							</DefaultButton>
						) : null}
					</Flex>
				</>
			) : null}
		</>
	);
};

export default CompanyGroupTreeHeader;
