import WonOpportunity from 'App/components/WonOpportunity';
import openModal from 'App/services/Modal/Modal';

angular.module('domain.order', ['ngRoute', 'upResources', 'security.authorization']).config([
	'$upModalProvider',
	function ($upModalProvider) {
		$upModalProvider
			.modal('editOrder', {
				templateUrl: require('App/upsales/domain/order/views/editOrder.html?file'),
				controller: 'EditOrder as EditOrder',
				style: 'form dynamic default',
				constantHeight: '100%',
				hash: true,
				closeOnCurtain: true,
				rememberHeight: true,
				resolve: {
					authUser: function ($routeGuard) {
						return $routeGuard.requireUser();
					},
					meta: function ($modalParams, EditOrderMeta) {
						return EditOrderMeta($modalParams);
					},
					openNewModal: function ($modalParams) {
						if (Tools.FeatureHelper.hasSoftDeployAccess('EDIT_ORDER_REACT')) {
							openModal('EditOrder', $modalParams);
							return Promise.reject();
						}
					}
				}
			})
			.modal('updateOrderMulti', {
				templateUrl: require('App/upsales/domain/order/views/updateOrderMulti.html?file'),
				controller: 'UpdateOrderMulti',
				style: 'form-md default',
				resolve: {
					meta: function ($modalParams, UpdateOrderMultiMeta) {
						return UpdateOrderMultiMeta($modalParams);
					}
				}
			})
			.modal('stageList', {
				templateUrl: require('App/upsales/domain/order/views/stageList.html?file'),
				controller: 'StageList as StageListCtrl',
				closeOnCurtain: true,
				style: 'confirm'
			})
			.modal('wonOpportunity', {
				template: '<div></div>',
				controller: function ($scope, $modalParams) {
					window.SetupComponent($scope, WonOpportunity, null, $modalParams, {
						redux: true,
						modal: true
					});
				},
				style: 'form-wide default auto-height',
				closeOnEscape: false
			});
	}
]);
