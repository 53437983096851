import colorMappings from '@upsales/components/Utils/colorMappings';
import FlowStepFormSubmit, { MAX_HEIGHT as FlowStepFormSubmitMax } from 'App/components/FlowStep/FlowStepFormSubmit';
import FlowStepSendMail, { MAX_HEIGHT as FlowStepSendMailMax } from 'App/components/FlowStep/FlowStepSendMail';
import FlowStepStart, { MAX_HEIGHT as FlowStepStartMax } from 'App/components/FlowStep/FlowStepStart';
import FlowStepEnd, { MAX_HEIGHT as FlowStepEndMax } from 'App/components/FlowStep/FlowStepEnd';
import FlowStepSnooze, { MAX_HEIGHT as FlowStepSnoozeMax } from 'App/components/FlowStep/FlowStepSnooze';
import FlowStepUpdateClient, {
	MAX_HEIGHT as FlowStepUpdateClientMax
} from 'App/components/FlowStep/FlowStepUpdateClient';
import FlowStepUpdateContact, {
	MAX_HEIGHT as FlowStepUpdateContactMax
} from 'App/components/FlowStep/FlowStepUpdateContact';
import FlowStepCreateActivity, {
	MAX_HEIGHT as FlowStepCreateActivityMax
} from 'App/components/FlowStep/FlowStepCreateActivity';
import FlowStepCreatePhoneCall, {
	MAX_HEIGHT as FlowStepCreatePhoneCallMax
} from 'App/components/FlowStep/FlowStepCreatePhoneCall';
import FlowStepCreateTodo, { MAX_HEIGHT as FlowStepCreateTodoMax } from 'App/components/FlowStep/FlowStepCreateTodo';
import FlowStepAssign, { MAX_HEIGHT as FlowStepAssignMax } from 'App/components/FlowStep/FlowStepAssign';
import logError from 'Helpers/logError';
import { openDrawer } from 'Services/Drawer';
import Flow from 'App/resources/Flow';
import { openAssignModalLead } from 'Components/AssignModal/AssignModalLead';

const STEP_COMPONENT_MAP = {
	snooze: FlowStepSnooze,
	sendMail: FlowStepSendMail,
	updateClient: FlowStepUpdateClient,
	updateContact: FlowStepUpdateContact,
	formSubmit: FlowStepFormSubmit,
	createActivity: FlowStepCreateActivity,
	createPhoneCall: FlowStepCreatePhoneCall,
	createTodo: FlowStepCreateTodo,
	assign: FlowStepAssign
};

export const COMPONENT_MAX_HEIGHT = {
	formSubmit: FlowStepFormSubmitMax,
	sendMail: FlowStepSendMailMax,
	FlowStepStart: FlowStepStartMax,
	FlowStepEnd: FlowStepEndMax,
	snooze: FlowStepSnoozeMax,
	updateClient: FlowStepUpdateClientMax,
	updateContact: FlowStepUpdateContactMax,
	createActivity: FlowStepCreateActivityMax,
	createPhoneCall: FlowStepCreatePhoneCallMax,
	createTodo: FlowStepCreateTodoMax,
	assign: FlowStepAssignMax
};

function isPhoneCallStep(step) {
	const todoTypes = Tools.AppService.getTodoTypes();
	return (
		Array.isArray(step.params) &&
		step.params.some(property => property.name === 'ActivityType' && property.value === todoTypes.PHONE_CALL.id)
	);
}

function isTodoStep(step) {
	const todoTypes = Tools.AppService.getTodoTypes();
	return (
		Array.isArray(step.params) &&
		step.params.some(property => property.name === 'ActivityType' && property.value === todoTypes.TODO.id)
	);
}

ReactTemplates.segment.flow = window.ReactCreateClass({
	getInitialState: function () {
		this.initialPos = { x: 0, y: 0 };
		this.currentPos = { x: 0, y: 0 };

		return {
			zoomLevel: 0.0,
			popup: null,
			accountProfile: null
		};
	},
	debug: false,
	deltaX: 0,
	zoomDisabled: false,
	deltaY: 0,
	zoomWidth: 0,
	zoomHeight: 0,
	maxZoom: 1.0,
	minZoom: -2.0,
	zoomStep: 0.5,
	zoomChanged: false,
	splitTypes: ['formSubmit', 'clickedMail', 'readMail', 'phoneOutcome'], // Step types that split into two paths
	endLines: [],
	elements: [],
	reactNodes: [],
	canvas: null,
	xMin: 0,
	xMax: 0,
	endY: 0, // this is updated each step iteration
	params: {
		lineLength: 80,
		lineWidth: 3,
		itemSize: 35,
		horizontalSpacing: 60,
		dashArray: '- ',
		lineCurve: 20
	},
	colors: {
		dassigGrey: '#9DAFB3',
		success: '#388E3C',
		red: colorMappings.get('red'),
		turcose: colorMappings.get('turcose'),
		orange: colorMappings.get('orange'),
		upsalesBrightBlue: colorMappings.get('bright-blue'),
		upsalesBlue: colorMappings.get('green'),
		purple: colorMappings.get('purple'),
		yellow: colorMappings.get('yellow'),
		green: colorMappings.get('green')
	},
	editPointAnimateTimeout: null,
	getLineLength: function () {
		return this.hasNewFlowSteps ? 170 : 80;
	},
	curve: {
		leftDown: function (curve) {
			return 'c -' + curve + ' 0 -' + curve + ' 0 -' + curve + ' ' + curve;
		},
		rightDown: function (curve) {
			return 'c ' + curve + ' 0 ' + curve + ' 0 ' + curve + ' ' + curve;
		},
		downLeft: function (curve) {
			return 'c 0 ' + curve + ' 0 ' + curve + ' -' + curve + ' ' + curve;
		},
		downRight: function (curve) {
			return 'c 0 ' + curve + ' 0 ' + curve + ' ' + curve + ' ' + curve;
		}
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.lang = {
			dayUnit: t('date.dayUnit'),
			weekUnit: t('date.weekUnit'),
			monthUnit: t('date.monthUnit'),
			start: t('flow.start'),
			contacts: t('default.contacts2'),
			yes: t('default.yes'),
			no: t('default.no'),
			add: t('default.add'),
			addStepDisabled: t('flow.addStepDisabled'),
			clickToAdd: t('flow.clickToAdd')
		};

		this.hasNewFlowSteps = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FLOW_STEPS');
		this.hasLoopFlows = Tools.FeatureHelper.hasSoftDeployAccess('LOOP_FLOW');
	},
	unmountReactNodes: function () {
		this.reactNodes.forEach(el => {
			ReactDOM.unmountComponentAtNode(el);
		});
	},
	getUnit: function (u) {
		var unit = '';
		switch (u) {
			case 'day':
				unit = this.lang.dayUnit;
				break;
			case 'week':
				unit = this.lang.weekUnit;
				break;
			case 'month':
				unit = this.lang.monthUnit;
				break;
		}
		return unit;
	},
	setClickedPoint: function (node) {
		const points = document.querySelectorAll('.flow-clicked-point');
		for (const point of points) {
			point.classList.remove('flow-clicked-point');
		}
		node?.classList.add('flow-clicked-point');
	},
	flowComponents: {
		externalComponent: function (x, y, Component, maxHeight) {
			const self = this;
			const width = 320;
			const fo = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');
			fo.setAttribute('height', maxHeight);
			fo.setAttribute('width', width);
			fo.setAttribute('x', x - width / 2);
			fo.setAttribute('y', y);
			fo.style.overflow = 'visible';

			fo.onclick = function () {
				self.setClickedPoint(fo);
			};

			ReactDOM.render(Component, fo);
			this.canvas.canvas.appendChild(fo);
			this.reactNodes.push(fo);
			return fo;
		},
		// Point for adding steps
		point: function (x, y) {
			const el = this.canvas.circle(x, y - 3, 6).attr({ fill: this.colors.dassigGrey, 'stroke-width': 0 });
			this.elements.push(el);
		},
		// Line for indicating no further steps
		dashedLine: function (x, y, l, customColor) {
			var dashedLineColor = customColor || this.colors.dassigGrey;
			const length = l !== undefined ? l : this.getLineLength();

			const el = this.canvas
				.path(`M ${x} ${y} l 0 ${length}`)
				.attr({
					fill: 'none',
					stroke: dashedLineColor,
					'stroke-width': this.params.lineWidth,
					'stroke-dasharray': this.params.dashArray,
					opacity: 0.5
				})
				.toBack();
			this.elements.push(el);
			return el;
		},
		dashedLineEmptySplit: function (x, y, initialHorizontalSpacing, objectPath) {
			var curve = this.params.lineCurve;
			var horizontalSpacing = initialHorizontalSpacing - curve;
			const lineLength = this.getLineLength();
			const lineLengthCurve = lineLength - curve * 2;
			var path = this.canvas
				.path(
					'M ' +
						x +
						' ' +
						y /* move to startpoint */ +
						' l -' +
						horizontalSpacing +
						' 0' /* draw to the left */ +
						' ' +
						this.curve.leftDown(curve) /* curve */ +
						' l 0 ' +
						lineLengthCurve /* draw down */ +
						' c 0 ' +
						curve +
						' 0 ' +
						curve +
						' ' +
						curve +
						' ' +
						curve /* curve */ +
						' l ' +
						horizontalSpacing +
						' 0' /* draw right, to the center */ +
						'M ' +
						x +
						' ' +
						y /* move to startingpoint again */ +
						' l ' +
						horizontalSpacing +
						' 0' /* draw to the right */ +
						' c ' +
						curve +
						' 0 ' +
						curve +
						' 0 ' +
						curve +
						' ' +
						curve /* curve */ +
						' l 0 ' +
						lineLengthCurve /* draw down */ +
						' c 0 ' +
						curve +
						' 0 ' +
						curve +
						' -' +
						curve +
						' ' +
						curve /* curve */ +
						' l -' +
						horizontalSpacing +
						' 0' /* draw left, to the center */
				)
				.attr({
					fill: 'none',
					stroke: this.colors.dassigGrey,
					'stroke-width': this.params.lineWidth,
					'stroke-linejoin': 'round',
					'stroke-dasharray': this.params.dashArray,
					opacity: 0.5
				})
				.toBack();
			this.elements.push(path);

			// Add edit point if editable
			if (this.props.editable) {
				this.renderComponent(
					'editPoint',
					x - initialHorizontalSpacing,
					y + lineLength / 2,
					objectPath + '.childNo'
				);
				this.renderComponent(
					'editPoint',
					x + initialHorizontalSpacing,
					y + lineLength / 2,
					objectPath + '.childYes'
				);
			}

			// Create 0 length line to use when connecting to end later
			return this.renderComponent('dashedLine', x, y + lineLength, 0);
		},
		abTestSplit: function (x, y, initialHorizontalSpacing, legMap, step) {
			var curve = this.params.lineCurve;
			var horizontalSpacing = initialHorizontalSpacing - curve;

			const lineLength = this.getLineLength();
			const lineLengthCurve = lineLength - curve * 2;
			var path = this.canvas
				.path(
					'M ' +
						x +
						' ' +
						y /* move to startpoint */ +
						' l -' +
						horizontalSpacing +
						' 0' /* draw to the left */ +
						' ' +
						this.curve.leftDown(curve) /* curve */ +
						' l 0 ' +
						lineLengthCurve /* draw down */ +
						' c 0 ' +
						curve +
						' 0 ' +
						curve +
						' ' +
						curve +
						' ' +
						curve /* curve */ +
						' l ' +
						horizontalSpacing +
						' 0' /* draw right, to the center */ +
						'M ' +
						x +
						' ' +
						y /* move to startingpoint again */ +
						' l ' +
						horizontalSpacing +
						' 0' /* draw to the right */ +
						' c ' +
						curve +
						' 0 ' +
						curve +
						' 0 ' +
						curve +
						' ' +
						curve /* curve */ +
						' l 0 ' +
						lineLengthCurve /* draw down */ +
						' c 0 ' +
						curve +
						' 0 ' +
						curve +
						' -' +
						curve +
						' ' +
						curve /* curve */ +
						' l -' +
						horizontalSpacing +
						' 0' /* draw left, to the center */
				)
				.attr({
					fill: 'none',
					stroke: this.colors.dassigGrey,
					'stroke-width': this.params.lineWidth,
					'stroke-linejoin': 'round',
					'stroke-dasharray': '',
					opacity: 1
				})
				.toBack();

			this.elements.push(path);

			if (step.merge) {
				var lowerLine = this.canvas
					.path('M ' + x + ' ' + (y + lineLength) + ' l 0 60')
					.attr({
						fill: 'none',
						stroke: this.colors.dassigGrey,
						'stroke-width': this.params.lineWidth,
						'stroke-linejoin': 'round',
						'stroke-dasharray': '',
						opacity: 1
					})
					.toBack();

				this.elements.push(lowerLine);
			}

			var xCoordYes = x + initialHorizontalSpacing;
			var xCoordNo = x - initialHorizontalSpacing;
			step.childNo.previewOnly = true;
			step.childYes.previewOnly = true;
			step.childNo.stats = step.childNo.stats || {};
			step.childYes.stats = step.childYes.stats || {};

			var yesLegMap = _.cloneDeep(legMap);
			var noLegMap = _.cloneDeep(legMap);
			yesLegMap.objectPath += 'childYes';
			noLegMap.objectPath += 'childNo';

			this.renderComponent('step', xCoordNo, y + lineLength / 2, step.childNo, noLegMap);
			this.renderComponent('step', xCoordYes, y + lineLength / 2, step.childYes, yesLegMap);

			if (!step.merge) {
				if (this.props.editable) {
					this.renderComponent(
						'editPoint',
						x,
						y + (lineLength * 2 - 30),
						legMap.objectPath + '.merge',
						step.type
					);
				}

				return this.renderComponent('dashedLine', x, y + lineLength, 0);
			}
		},
		solidLine: function (x, y, specifiedLength, objectPath) {
			var el = this.canvas
				.path('M ' + x + ' ' + y + ' l 0 ' + specifiedLength)
				.attr({
					fill: 'none',
					stroke: this.colors.dassigGrey,
					'stroke-width': this.params.lineWidth,
					'upsales:linePath': objectPath
				})
				.toBack();
			this.elements.push(el);
		},
		yesLine: function (x, y, dashed, horizontalSpacing, objectPath) {
			const lineLength = this.hasNewFlowSteps ? 100 : this.params.lineLength;
			const el = this.canvas
				.path(
					'M ' +
						x +
						' ' +
						y /* move to startingpoint */ +
						' l ' +
						(horizontalSpacing - this.params.lineCurve) +
						' 0' /* draw to the right */ +
						' ' +
						this.curve.rightDown(this.params.lineCurve) /* curve */ +
						' l 0 ' +
						(lineLength - this.params.lineCurve) /* draw down */
				)
				.attr({
					fill: 'none',
					stroke: this.colors.dassigGrey,
					'stroke-width': this.params.lineWidth,
					'stroke-dasharray': dashed ? this.params.dashArray : '',
					opacity: dashed ? 0.5 : 1
				})
				.toBack();
			this.elements.push(el);

			// Add edit point if editable
			if (dashed && this.props.editable) {
				this.renderComponent('editPoint', x + horizontalSpacing, y + lineLength, objectPath + '.childYes');
			}
			return el;
		},
		noLine: function (x, y, dashed, horizontalSpacing, objectPath) {
			const lineLength = this.hasNewFlowSteps ? 100 : this.params.lineLength;
			const el = this.canvas
				.path(
					'M ' +
						x +
						' ' +
						y /* move to startingpoint */ +
						' l -' +
						(horizontalSpacing - this.params.lineCurve) +
						' 0' /* draw to the right */ +
						' ' +
						this.curve.leftDown(this.params.lineCurve) /* curve */ +
						' l 0 ' +
						(lineLength - this.params.lineCurve) /* draw down */
				)
				.attr({
					fill: 'none',
					stroke: this.colors.dassigGrey,
					'stroke-width': this.params.lineWidth,
					'stroke-dasharray': dashed ? this.params.dashArray : '',
					opacity: dashed ? 0.5 : 1
				})
				.toBack();
			this.elements.push(el);

			// Add edit point if editable
			if (dashed && this.props.editable) {
				this.renderComponent('editPoint', x - horizontalSpacing, y + lineLength, objectPath + '.childNo');
			}
			return el;
		},
		step: function (x, y, step, legMap) {
			var self = this;
			var item;
			var r = this.params.itemSize / 2;
			var centeredX = x - r;
			var centeredY = y - r;
			var iconElem = null;
			var round = false;
			var hasTooltip = false;
			var hasSnooze = false;
			var isDrawer = false;

			// Related to LINE-7270 - remove when all flows are updated
			if (step.type === '0') {
				step.type = 'clickedMail';
				console.warn('Flow corrupted. Flow step type 0 found, changed to clickedMail');
			}
			switch (step.type) {
				case 'abTesting':
					var coolSvgIconX = centeredX - 9;
					item = this.canvas.image('../../../../../img/split-test-flow.svg', coolSvgIconX, centeredY, 56, 46);

					var leftNumber = step.params.variantA.value;
					var rightNumber = step.params.variantB.value;

					iconElem = this.canvas.text(x + 1, y + 17, leftNumber + ' / ' + rightNumber);
					iconElem.attr({
						'font-family': '"Roboto", sans-serif',
						'font-size': '10px',
						'font-weight': 'bold',
						fill: this.colors.dassigGrey
					});
					break;
				case 'formSubmit':
					item = this.canvas.rect(centeredX, centeredY, this.params.itemSize, this.params.itemSize);
					item.attr({
						fill: this.colors.turcose,
						r: 3
					});
					item.transform('r45');
					iconElem = this.canvas.text(x, y + 2, 'm');
					iconElem.attr({
						'font-family': 'upicons',
						'font-size': '14px',
						fill: '#fff'
					});
					hasSnooze = true;
					isDrawer = true;
					break;
				case 'clickedMail':
					item = this.canvas.rect(centeredX, centeredY, this.params.itemSize, this.params.itemSize);
					item.attr({
						fill: this.colors.turcose,
						r: 3
					});
					item.transform('r45');
					iconElem = this.canvas.text(x + 2, y, '\uf245');
					iconElem.attr({
						'font-family': 'FontAwesome',
						'font-size': '14px',
						fill: '#fff'
					});
					hasSnooze = true;
					isDrawer = true;
					break;
				case 'assign':
					item = this.canvas.rect(centeredX, centeredY, this.params.itemSize, this.params.itemSize);
					item.attr({
						fill: this.colors.purple,
						r: 3
					});
					iconElem = this.canvas.text(x - 1, y, '\uf234');
					iconElem.attr({
						'font-family': 'FontAwesome',
						'font-size': '14px',
						fill: '#fff'
					});
					isDrawer = true;
					break;
				case 'readMail':
					item = this.canvas.rect(centeredX, centeredY, this.params.itemSize, this.params.itemSize);
					item.attr({
						fill: this.colors.turcose,
						r: 3
					});
					item.transform('r45');
					iconElem = this.canvas.text(x, y, '\uf06e');
					iconElem.attr({
						'font-family': 'FontAwesome',
						'font-size': '14px',
						fill: '#fff'
					});
					hasSnooze = true;
					isDrawer = true;
					break;
				case 'sendMail':
					item = this.canvas.rect(centeredX, centeredY, this.params.itemSize, this.params.itemSize);
					item.attr({
						fill: this.colors.purple,
						r: 3
					});
					iconElem = this.canvas.text(x - 1, y, '\uf1d8');
					iconElem.attr({
						'font-family': 'FontAwesome',
						'font-size': '14px',
						fill: '#fff'
					});
					isDrawer = true;
					break;
				case 'createActivity': {
					const isPhoneCall = isPhoneCallStep(step);
					item = this.canvas.rect(centeredX, centeredY, this.params.itemSize, this.params.itemSize);
					item.attr({
						fill: this.colors.upsalesBrightBlue,
						r: 3
					});
					iconElem = this.canvas.text(x - 1, y, isPhoneCall ? '\uf095' : '\uf00c');
					iconElem.attr({
						'font-family': 'FontAwesome',
						'font-size': '14px',
						fill: '#fff'
					});
					isDrawer = true;
					break;
				}
				case 'updateClient':
					item = this.canvas.rect(centeredX, centeredY, this.params.itemSize, this.params.itemSize);
					item.attr({
						fill: this.colors.upsalesBrightBlue,
						r: 3
					});
					iconElem = this.canvas.text(x - 1, y, '\uf015');
					iconElem.attr({
						'font-family': 'FontAwesome',
						'font-size': '14px',
						fill: '#fff'
					});
					isDrawer = true;
					break;
				case 'updateContact':
					item = this.canvas.rect(centeredX, centeredY, this.params.itemSize, this.params.itemSize);
					item.attr({
						fill: this.colors.upsalesBrightBlue,
						r: 3
					});
					iconElem = this.canvas.text(x - 1, y, '\uf007');
					iconElem.attr({
						'font-family': 'FontAwesome',
						'font-size': '14px',
						fill: '#fff'
					});
					isDrawer = true;
					break;
				case 'snooze':
					item = this.canvas.circle(x, y, r);
					item.attr({
						fill: '#4A4A4A',
						'stroke-width': 0
					});
					round = true;
					var unit = this.getUnit(step.waitUnit);
					var waitTime = this.canvas.text(x, y, step.waitTime + '' + unit);
					waitTime.attr({
						'font-family': 'Roboto,sans-serif',
						'font-size': '14px',
						fill: '#fff'
					});
					this.elements.push(waitTime);
					var snoozeIcon = this.canvas.text(x + r + 5, y - r + 2, 'z');
					snoozeIcon.attr({
						'font-family': 'upicons',
						'font-size': '12px',
						fill: '#4A4A4A'
					});
					this.elements.push(snoozeIcon);
					if (!this.props.editable) {
						hasTooltip = true;
					}
					isDrawer = true;
					break;
				default:
					throw new Error('Step type missing');
			}
			item.attr({
				stroke: 0
			});
			if (step.type !== 'abTesting') {
				this.elementShadow(item);
			}

			this.elements.push(item);

			if (iconElem) {
				this.elements.push(iconElem);
			}

			if (hasSnooze) {
				var snoozebg = this.canvas.circle(x + r - 5, y - r + 5, 10);
				snoozebg.attr({
					fill: '#4A4A4A',
					'stroke-width': 0
				});
				this.elements.push(snoozebg);
				var snoozeUnit = this.getUnit(step.waitUnit);
				var snooze = this.canvas.text(x + r - 4, y - r + 4, step.waitTime + '' + snoozeUnit);
				snooze.attr({
					'font-family': 'Roboto,sans-serif',
					'font-size': '10px',
					fill: '#fff',
					'text-anchor': 'middle'
				});
				this.elements.push(snooze);
			}

			var rad = this.params.itemSize / 2;
			var hoverTrigger;
			if (round) {
				hoverTrigger = this.canvas.circle(x, y, rad);
			} else {
				hoverTrigger = this.canvas.rect(x - rad, y - rad, this.params.itemSize, this.params.itemSize);
			}
			hoverTrigger.attr({
				fill: 'white',
				'stroke-width': 0,
				opacity: 0,
				cursor: 'pointer'
			});
			var isPreview = step.previewOnly || !self.props.editable;

			hoverTrigger[0].classList.add(
				'flow-editor-' +
					(isPreview ? 'preview' : 'edit') +
					'-step' +
					(legMap.objectPath ? legMap.objectPath.replace(/\./g, '-') : '')
			);

			if (!step.refusePopup) {
				this.elements.push(hoverTrigger);
			}

			hoverTrigger.node.onclick = function () {
				var popupType = step.type;

				if (isPreview) {
					popupType += 'Preview';
				}
				self.clickAction = true;
				self.zoomDisabled = true;
				self.setClickedPoint(hoverTrigger.node);
				self.setState(
					{
						popup: {
							type: popupType,
							path: legMap.objectPath,
							step: step,
							edit: true,
							isDrawer
						}
					},
					() => {
						const { width, height } = self.canvas.canvas;
						self.zoomWidth = width.baseVal.value;
						self.zoomHeight = height.baseVal.value;
						self.canvas.setViewBox(
							self.currentPos.x,
							self.currentPos.y,
							self.zoomWidth,
							self.zoomHeight,
							false
						);
					}
				);
			};

			// Indicate if step is valid
			if (!legMap.valid) {
				var warning = this.canvas.text(x - 15, y - 25, '\uf071');
				warning.attr({
					'font-family': 'FontAwesome',
					'font-size': '14px',
					fill: this.colors.orange
				});
				this.elements.push(warning);
			}

			if (hasTooltip && step.stats) {
				this.renderComponent('tooltip', x - this.params.itemSize / 2 - 5, y, step.stats.currentlyInStep);
			}

			return item;
		},
		end: function (x, y) {
			let endNode;
			const self = this;
			if (this.hasNewFlowSteps) {
				const onClick =
					self.props.editable === false || self.hasLoopFlows
						? this.getStepOnClick('end', null, { type: 'end', params: [] })
						: undefined;
				endNode = this.renderComponent(
					'externalComponent',
					x,
					y,
					<FlowStepEnd
						locked={this.props.editable && !this.hasLoopFlows}
						onClick={onClick}
						flow={this.props.flow}
						editable={this.props.editable}
					/>,
					COMPONENT_MAX_HEIGHT.FlowStepEnd
				);

				if (!this.props.editable) {
					this.renderComponent(
						'tooltip',
						x - 320 / 2 - 5,
						y,
						this.props.flow.completedContactCount,
						true,
						this.props.flow.reachedGoal
					);
				}
			} else {
				const end = this.canvas.circle(x, y, this.params.itemSize / 2);
				endNode = end.node;
				const cursor = this.props.editable ? 'default' : 'pointer';
				end.attr({
					fill: this.colors.success,
					'stroke-width': 0,
					cursor
				});
				this.elements.push(end);

				var iconElem = this.canvas.text(x, y, '\uf11e');
				iconElem.attr({
					'font-family': 'FontAwesome',
					'font-size': '14px',
					fill: '#fff',
					cursor
				});
				this.elements.push(iconElem);

				// add shadow to end
				this.elementShadow(end);

				if (!this.props.editable) {
					this.renderComponent(
						'tooltip',
						x - this.params.itemSize / 2 - 5,
						y,
						this.props.flow.completedContactCount
					);
				}

				end.node.onclick = onEndClick;
				iconElem.node.onclick = onEndClick;
			}

			function onEndClick() {
				if (self.props.editable === false) {
					self.clickAction = true;
					self.zoomDisabled = true;
					self.setClickedPoint(endNode);

					self.setState({
						popup: {
							type: 'endPreview',
							path: null,
							step: 'end',
							edit: true,
							isDrawer: true
						}
					});
				}
			}
		},
		clickToAddTooltip: function (posX, posY, text) {
			const padding = 10;
			const pointerWidth = 5;

			const pointerElementX = posX;
			const tooltipElementX = posX + pointerWidth;
			const textElementX = posX + pointerWidth + padding;

			// Text
			const textElement = this.canvas.text(textElementX, posY, text);
			textElement.attr({
				'font-family': 'Roboto,sans-serif',
				'font-size': '14px',
				fill: this.colors.upsalesBrightBlue,
				'text-anchor': 'start'
			});

			textElement[0].classList.add('flow-editor-clickToAddTooltip-text');
			this.elements.push(textElement);

			const width = padding * 2 + textElement.getBBox().width;

			// bg
			const tooltipElement = this.canvas.rect(tooltipElementX, posY - 15, width, 30);
			tooltipElement.attr({
				fill: '#fff',
				stroke: 'none',
				r: 3,
				filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2))'
			});

			tooltipElement[0].classList.add('flow-editor-clickToAddTooltip');
			this.elements.push(tooltipElement);

			// pointer
			const pointerElement = this.canvas.path(`
				M ${pointerElementX} ${posY}
				l ${pointerWidth} -${pointerWidth}
				l 0 ${pointerWidth * 2}
				l -${pointerWidth} -${pointerWidth}
			`);
			pointerElement.attr({
				fill: '#fff',
				stroke: 'none'
			});
			pointerElement[0].classList.add('flow-editor-clickToAddTooltip-pointer');
			this.elements.push(pointerElement);
			textElement.toFront();
		},
		tooltip: function (posX, posY, tot, showGoal = false, reachedGoal = 0) {
			let width = 0;
			const pointerWidth = 5;
			let reachedGoalText;
			let trophyElem;
			let trophyElemWidth = 0;

			// Icon
			const iconElem = this.canvas.text(posX, posY, '\uf007');
			iconElem.attr({
				'font-family': 'FontAwesome',
				'font-size': '14px',
				fill: '#fff',
				'text-anchor': 'start'
			});
			this.elements.push(iconElem);

			// Total number text
			const text = this.canvas.text(posX, posY, tot || 0);
			text.attr({
				'font-family': 'Roboto,sans-serif',
				'font-size': '14px',
				fill: '#fff',
				'text-anchor': 'start'
			});
			const textWidth = text.getBBox().width;
			const iconWidth = iconElem.getBBox().width;
			width += 10 + iconWidth + 5 + textWidth;
			this.elements.push(text);

			if (showGoal) {
				trophyElem = this.canvas.text(posX, posY, '\uf091');
				trophyElem.attr({
					'font-family': 'FontAwesome',
					'font-size': '14px',
					fill: '#fff',
					'text-anchor': 'start'
				});
				this.elements.push(trophyElem);

				reachedGoalText = this.canvas.text(posX, posY, reachedGoal || 0);
				reachedGoalText.attr({
					'font-family': 'Roboto,sans-serif',
					'font-size': '14px',
					fill: '#fff',
					'text-anchor': 'start'
				});
				trophyElemWidth = trophyElem.getBBox().width;
				width += 10 + reachedGoalText.getBBox().width + 5 + trophyElemWidth; // text width + icon
				this.elements.push(reachedGoalText);
			}

			width += 10;

			// Position all tooltip elements after each other
			const x = posX - width - pointerWidth;
			const y = posY - 12.5;
			const iconX = x + 10;
			iconElem.attr({ x: iconX });
			const textX = iconX + iconWidth + 5;
			text.attr({ x: textX });
			if (showGoal) {
				const trophyElemX = textX + textWidth + 10;
				trophyElem.attr({ x: trophyElemX });
				const reachedGoalTextX = trophyElemX + trophyElemWidth + 5;
				reachedGoalText.attr({ x: reachedGoalTextX });
			}

			// bg
			const tooltip = this.canvas.rect(x, y, width, 25);
			tooltip.attr({
				fill: this.colors.upsalesBlue,
				stroke: 'none',
				r: 3
			});
			this.elements.push(tooltip);

			// pointer
			var pointer = this.canvas.path(
				'M ' +
					posX +
					' ' +
					posY +
					' l -' +
					pointerWidth +
					' -' +
					pointerWidth +
					' l 0 ' +
					pointerWidth * 2 +
					' l ' +
					pointerWidth +
					' -' +
					pointerWidth
			);
			pointer.attr({
				fill: this.colors.upsalesBlue,
				stroke: 'none'
			});
			this.elements.push(pointer);

			// Bring all text to front to make tem end up above the background
			iconElem.toFront();
			text.toFront();
			if (showGoal) {
				trophyElem.toFront();
				reachedGoalText.toFront();
			}
		},
		editPoint: function (x, y, objectPath, currentType, hide) {
			const isMailBehaviour = currentType === 'readMail' || currentType === 'clickedMail';
			var self = this;
			var r = 6;
			var easing = 'linear';
			var ms = 200;
			var point = self.canvas
				.circle(x, y, r)
				.attr({ opacity: hide ? 0 : 1, fill: self.colors.dassigGrey, 'stroke-width': 0 });
			self.elements.push(point);

			var plus = isMailBehaviour ? self.canvas.text(x, y, '\uf00d') : self.canvas.text(x, y + 1, '\uf067');
			plus.attr({
				'font-family': 'FontAwesome',
				'font-size': '14px',
				fill: isMailBehaviour ? self.colors.red : self.colors.upsalesBrightBlue,
				opacity: 0
			});
			self.elements.push(plus);

			var addText = self.canvas.text(
				x + r * 2 + 5,
				y,
				isMailBehaviour ? self.lang.addStepDisabled : self.lang.add
			);
			addText.attr({
				'font-size': '14px',
				'font-family': 'Roboto,sans-serif',
				'text-anchor': 'start',
				fill: isMailBehaviour ? self.colors.red : self.colors.upsalesBrightBlue,
				opacity: 0
			});
			self.elements.push(addText);

			var hoverTrigger = this.canvas.rect(x - r * 5, y - r * 4, r * 10, r * 10).attr({
				fill: 'white',
				opacity: 0,
				cursor: 'pointer'
			});
			self.elements.push(hoverTrigger);

			let direction = false;
			function pulse() {
				if (direction) {
					point.stop().animate({ r: r * 2.5, fill: '#fff' }, 400, 'ease-in');
				} else {
					point.stop().animate({ r: 1.5 * r, fill: '#fff' }, 400, 'ease-in');
				}
				direction = !direction;
				self.editPointAnimateTimeout = setTimeout(pulse, 400);
			}

			if (self.props.flow.path === null) {
				self.renderComponent('clickToAddTooltip', x + 4 * r, y, self.lang.clickToAdd);
				plus.attr({ opacity: 1 });
				pulse();
			} else {
				clearInterval(self.editPointAnimateTimeout);

				hoverTrigger.hover(
					function () {
						point.stop().animate(
							{
								opacity: 1,
								r: r * 2,
								fill: isMailBehaviour ? self.colors.dassigGrey : '#fff'
							},
							ms,
							easing
						);
						plus.stop().animate({ opacity: 1 }, ms, easing);
						addText.attr({ opacity: 1 });
					},
					function () {
						point.stop().animate({ opacity: hide ? 0 : 1, r: r, fill: self.colors.dassigGrey }, ms, easing);
						plus.stop().animate({ opacity: 0 }, ms, easing);
						addText.attr({ opacity: 0 });
					}
				);
			}

			hoverTrigger[0].classList.add('flow-editor-add-step' + (objectPath ? objectPath.replace(/\./g, '-') : ''));

			if (!isMailBehaviour) {
				hoverTrigger.node.onclick = function () {
					self.clickAction = true;
					self.zoomDisabled = true;
					self.setClickedPoint(point.node);
					self.setState(
						{
							popup: {
								type: currentType === 'abTesting' ? 'behavior' : 'selectType',
								path: objectPath,
								edit: false,
								step: {
									isMerge: currentType === 'abTesting'
								}
							}
						},
						() => {
							const { width, height } = self.canvas.canvas;
							self.zoomWidth = width.baseVal.value;
							self.zoomHeight = height.baseVal.value;
							self.canvas.setViewBox(
								self.currentPos.x,
								self.currentPos.y,
								self.zoomWidth,
								self.zoomHeight,
								false
							);
						}
					);
				};
			}

			return point;
		}
	},
	elementShadow: function (element) {
		var glow = element.glow({
			color: '#000',
			width: 1,
			offsety: 2,
			offsetx: 0,
			opacity: 0.1,
			fill: true
		});
		this.elements.push(glow);
		var glow2 = element.glow({
			color: '#000',
			width: 1,
			offsety: 1,
			offsetx: 0,
			opacity: 0.1,
			fill: true
		});
		this.elements.push(glow2);
	},
	getHorizontalSpaceing: function (insideSteps) {
		if (this.hasNewFlowSteps) {
			// 55 seems to be the sweet spot. If in some cases branches collid, then try 60 or add 2 steps instead of 1
			return insideSteps * 215;
		}
		// Math.PI
		return Math.max(insideSteps, 1) * (this.hasNewFlowSteps ? 110 : this.params.horizontalSpacing);
	},
	renderComponent: function (componentName) {
		var args = [].slice.call(arguments);
		args = args.slice(1);
		return this.flowComponents[componentName].apply(this, args);
	},
	isSplitStep: function (step) {
		// If this step is of type sendMail and the next is readMail or clickedMail we want to simulate split on the sendMail step
		if (this.hasNewFlowSteps && this.isStepWithBehavior(step)) {
			return true;
		}
		return this.splitTypes.indexOf(step.type) !== -1;
	},
	isStepWithBehavior: step =>
		(step.type === 'sendMail' && ['readMail', 'clickedMail'].includes(step.childYes?.type)) ||
		(step.type === 'createActivity' && step.childYes?.type === 'phoneOutcome'),
	parseStep: function (step, legMap, x, y, skipLine, parseI, maxLevels) {
		parseI++;
		const lineLength = this.getLineLength();
		// Draw line to step
		if (!skipLine) {
			this.renderComponent('solidLine', x, y, lineLength, 'regular');
		}
		if (this.props.editable) {
			var hide = true;
			var offset = lineLength / 2;
			if (skipLine) {
				offset = (lineLength / 5) * 2;
			}
			this.renderComponent('editPoint', x, y + offset, legMap.objectPath, step.type, hide);
		}

		// Draw step item
		var lineEndY = y + lineLength;

		let componentType = step.type;
		if (isPhoneCallStep(step)) {
			componentType = 'createPhoneCall';
		}
		if (isTodoStep(step)) {
			componentType = 'createTodo';
		}
		const isNewStep = this.hasNewFlowSteps && STEP_COMPONENT_MAP[componentType];

		const isStepWithBehavior = this.isStepWithBehavior(step);

		const childYes = isNewStep && isStepWithBehavior ? step.childYes?.childYes : step.childYes;
		const childNo = isNewStep && isStepWithBehavior ? step.childYes?.childNo : step.childNo;
		const yesPath = isNewStep && isStepWithBehavior ? legMap.yesPath?.yesPath : legMap.yesPath;
		const noPath = isNewStep && isStepWithBehavior ? legMap.yesPath?.noPath : legMap.noPath;
		const insideYes = isNewStep && isStepWithBehavior ? legMap.yesPath?.insideYes : legMap.insideYes;
		const insideNo = isNewStep && isStepWithBehavior ? legMap.yesPath?.insideNo : legMap.insideNo;
		const thisStepPath = legMap.objectPath;

		if (isNewStep) {
			const onClick = this.getStepOnClick(componentType, thisStepPath, step);
			const onClickBehaviour = isStepWithBehavior
				? this.getStepOnClick(step.childYes.type, thisStepPath + '.childYes', step.childYes)
				: null;

			const onDelete = event => {
				if (event?.stopPropagation) {
					event.stopPropagation();
				}
				this.props.onRemoveStep(thisStepPath);
			};
			const onDeleteBehavior = event => {
				if (event?.stopPropagation) {
					event.stopPropagation();
				}
				this.props.onRemoveStep(thisStepPath + '.childYes');
			};

			const onAddBehavior = (type, skipPopup) => {
				if (skipPopup) {
					this.props.onAddStep(Flow.newStep(type), thisStepPath + '.childYes', false);
				} else {
					this.openPopup({
						type,
						path: thisStepPath + '.childYes',
						step: Flow.newStep(type),
						edit: false,
						isDrawer: false
					});
				}
			};

			const Component = STEP_COMPONENT_MAP[componentType];
			const maxHeight = COMPONENT_MAX_HEIGHT[componentType];

			const componentProps = {
				step,
				onClick,
				onClickBehaviour,
				onDelete,
				editable: this.props.editable,
				isInvalid: !this.validateStep(step)
			};

			if (step.type === 'sendMail' || step.type === 'createActivity') {
				componentProps.onAddBehavior = onAddBehavior;
				componentProps.onDeleteBehavior = onDeleteBehavior;
			}
			if (step.type === 'snooze' && step.stats && !this.props.editable) {
				this.renderComponent('tooltip', x - 320 / 2 - 5, lineEndY, step.stats.currentlyInStep);
			}

			this.renderComponent('externalComponent', x, lineEndY, <Component {...componentProps} />, maxHeight);
		} else {
			this.renderComponent('step', x, lineEndY, step, legMap);
		}

		var isSplitStep = this.isSplitStep(step);
		var dashedLine;
		var xCoord = x;

		// Update highest Y
		this.endY = Math.max(this.endY, lineEndY + lineLength);

		const LINE_OFFSET_FROM_CENTER = 68;

		// This will be refactored later when we have all of the steps as new components
		if (isNewStep) {
			let nextXYes = x;

			// If split step we need two lines
			if (isSplitStep) {
				const LINE_LENGTH = 70;

				const horizontalSpacingYes = this.getHorizontalSpaceing(insideYes);
				const horizontalSpacingNo = this.getHorizontalSpaceing(insideNo);
				const oneStep = this.getHorizontalSpaceing(1);

				const lineOffsetRight = x + LINE_OFFSET_FROM_CENTER;
				const lineOffsetLeft = x - LINE_OFFSET_FROM_CENTER;

				nextXYes = x + horizontalSpacingYes;
				const nextXNo = x - horizontalSpacingNo;

				// Move down and to the right
				if (childYes) {
					this.renderComponent('solidLine', lineOffsetRight, lineEndY, LINE_LENGTH, 'regular');
				} else if (childNo) {
					this.renderComponent('dashedLine', lineOffsetRight, lineEndY, LINE_LENGTH);
				}
				// Move down and to the left
				if (childNo) {
					this.renderComponent('solidLine', lineOffsetLeft, lineEndY, LINE_LENGTH, 'regular');
				} else if (childYes) {
					this.renderComponent('dashedLine', lineOffsetLeft, lineEndY, LINE_LENGTH);
				}

				if (childNo || childYes) {
					const yesLine = this.renderComponent(
						'yesLine',
						lineOffsetRight,
						lineEndY + LINE_LENGTH,
						!childYes,
						(childYes ? horizontalSpacingYes : oneStep) - LINE_OFFSET_FROM_CENTER,
						isStepWithBehavior ? legMap.yesPath.objectPath : thisStepPath
					);
					const noLine = this.renderComponent(
						'noLine',
						lineOffsetLeft,
						lineEndY + LINE_LENGTH,
						!childNo,
						(childNo ? horizontalSpacingNo : oneStep) - LINE_OFFSET_FROM_CENTER,
						isStepWithBehavior ? legMap.yesPath.objectPath : thisStepPath
					);
					if (!childNo) {
						this.endLines.push(noLine);
					}
					if (!childYes) {
						this.endLines.push(yesLine);
					}
				}

				// Render next no step
				if (childNo) {
					this.parseStep(childNo, noPath, nextXNo, lineEndY, true, parseI, maxLevels);
				}
			} else if (childYes) {
				this.renderComponent('solidLine', x, lineEndY, lineLength, 'regular');
			}

			// Render next step
			if (childYes) {
				this.parseStep(childYes, yesPath, nextXYes, lineEndY, true, parseI, maxLevels);
			}
		} else {
			var horizontalSpacing;
			if (step.type === 'abTesting') {
				if (step.merge) {
					step.merge.isMerge = true;
					step.merge.statsA = step.childNo.stats || {};
					step.merge.statsB = step.childYes.stats || {};

					step.merge.statsA.link = step.childNo.link;
					step.merge.statsB.link = step.childYes.link;

					this.parseStep(step.merge, legMap.merge, xCoord, lineEndY + lineLength, false, parseI, maxLevels);
				}
			} else if (childYes) {
				horizontalSpacing = this.getHorizontalSpaceing(legMap.insideYes);

				var yesX = xCoord;

				if (isSplitStep) {
					// Move down and to the right
					if (isNewStep) {
						this.renderComponent('solidLine', x + 68, lineEndY, 20, 'regular');
						yesX = xCoord + horizontalSpacing;
						this.renderComponent(
							'yesLine',
							x + 68,
							lineEndY + 20,
							false,
							horizontalSpacing,
							isStepWithBehavior ? legMap.yesPath.objectPath : thisStepPath
						);
					} else {
						// Slide to the right
						yesX = xCoord + horizontalSpacing;
						this.renderComponent('yesLine', x, lineEndY, false, horizontalSpacing, thisStepPath);
					}
				}

				this.parseStep(childYes, yesPath, yesX, lineEndY, isSplitStep, parseI, maxLevels);
			} else if (isSplitStep && childNo) {
				// yes was empty
				horizontalSpacing = this.getHorizontalSpaceing(legMap.insideYes);

				if (isNewStep) {
					this.renderComponent('dashedLine', x + 68, lineEndY, 20);
					dashedLine = this.renderComponent(
						'yesLine',
						x + 68,
						lineEndY,
						true,
						horizontalSpacing,
						isStepWithBehavior ? legMap.yesPath.objectPath : thisStepPath
					);
				} else {
					// Create dashed line here
					dashedLine = this.renderComponent('yesLine', x, lineEndY, true, horizontalSpacing, thisStepPath);
				}
			}

			if (childNo && isSplitStep) {
				horizontalSpacing = this.getHorizontalSpaceing(legMap.insideNo);
				// Move down and to the left
				let noX = xCoord;
				if (isNewStep) {
					this.renderComponent('solidLine', x - 55, lineEndY, 20, 'regular');
					noX = xCoord - horizontalSpacing;
					this.renderComponent('noLine', x - 55, lineEndY + 20, false, horizontalSpacing);
				} else {
					// Slide to the left
					noX = xCoord - horizontalSpacing;
					this.renderComponent('noLine', x, lineEndY, false, horizontalSpacing);
				}

				this.parseStep(childNo, noPath, noX, lineEndY, true, parseI, maxLevels);
			} else if (isSplitStep && childYes) {
				// no was empty
				horizontalSpacing = this.getHorizontalSpaceing(legMap.insideNo);
				// Create dashed line here
				dashedLine = this.renderComponent('noLine', x, lineEndY, true, horizontalSpacing, thisStepPath);
			}

			// Add YES/NO text if split
			if (isSplitStep && !this.hasNewFlowSteps) {
				var yes = this.canvas.text(x + 25, lineEndY + 10, this.lang.yes.toUpperCase()).attr({
					'text-anchor': 'start',
					fill: '#7c8b8e',
					'font-weight': 'bold',
					'font-size': '9px',
					'font-family': 'Roboto,sans-serif'
				});
				this.elements.push(yes);
				var no = this.canvas.text(x - 25, lineEndY + 10, this.lang.no.toUpperCase()).attr({
					'text-anchor': 'end',
					fill: '#7c8b8e',
					'font-weight': 'bold',
					'font-size': '9px',
					'font-family': 'Roboto,sans-serif'
				});
				this.elements.push(no);
			}

			if (this.debug && isSplitStep) {
				var t3 = this.canvas.text(
					x - 35,
					lineEndY - 10,
					'inside n: ' + legMap.insideNo + ' y:' + legMap.insideYes
				);
				var t4 = this.canvas.text(x - 35, lineEndY, 'level: ' + parseI);
				var t5 = this.canvas.text(x - 35, lineEndY + 10, 'max: ' + maxLevels);
				var t6 = this.canvas.text(x - 35, lineEndY + 20, 'inverted: ' + (maxLevels - parseI));
				t3.attr('fill', 'red');
				t4.attr('fill', 'red');
				t5.attr('fill', 'red');
				t6.attr('fill', 'red');
				this.elements.push(t3);
				this.elements.push(t4);
				this.elements.push(t5);
				this.elements.push(t6);
			}
		}

		// Render dashed line and save it to endLines array
		if (!childYes && !childNo) {
			// Go to end if we had no steps
			if (isSplitStep) {
				// do special line if splitted
				horizontalSpacing = this.hasNewFlowSteps ? LINE_OFFSET_FROM_CENTER : this.getHorizontalSpaceing(1);
				dashedLine = this.renderComponent(
					'dashedLineEmptySplit',
					x,
					lineEndY,
					horizontalSpacing,
					isStepWithBehavior ? legMap.yesPath.objectPath : thisStepPath
				);
			} else if (step.type === 'abTesting') {
				horizontalSpacing = this.getHorizontalSpaceing(1);
				dashedLine = this.renderComponent('abTestSplit', x, lineEndY, horizontalSpacing, legMap, step);
			} else {
				dashedLine = this.renderComponent('dashedLine', x, lineEndY, lineLength);
				// Add edit point if editable
				if (this.props.editable) {
					this.renderComponent('editPoint', x, lineEndY + lineLength, thisStepPath + '.childYes');
				}
			}
		} else if (step.type === 'abTesting') {
			horizontalSpacing = this.getHorizontalSpaceing(1);
			dashedLine = this.renderComponent('abTestSplit', x, lineEndY, horizontalSpacing, legMap, step);
		}

		// Push to array with dashed lines we need to update every step we iterate
		if (dashedLine) {
			this.endLines.push(dashedLine);
		}

		if (isSplitStep && childYes && !childNo) {
			xCoord = xCoord - horizontalSpacing;
		}
		if (isSplitStep && childNo && !childYes) {
			xCoord = xCoord + horizontalSpacing;
		}

		this.xMax = Math.max(this.xMax, xCoord);
		this.xMin = Math.min(this.xMin, xCoord);
	},
	insideSumYes: function (step, map, sum) {
		const isSplitStep = this.isSplitStep(step) && step.type !== 'sendMail' && step.type !== 'createActivity';
		if (step.childNo && isSplitStep) {
			sum = this.insideSumYes(step.childNo, map.noPath, sum + (map.insideNo || 1));
		} else if (step.childYes && !isSplitStep) {
			sum = this.insideSumYes(step.childYes, map.yesPath, sum);
		} else if (isSplitStep) {
			sum = sum + (this.hasNewFlowSteps ? 0 : 1);
		}
		return sum;
	},
	insideSumNo: function (step, map, sum) {
		const isSplitStep = this.isSplitStep(step) && step.type !== 'sendMail' && step.type !== 'createActivity';
		if (step.childYes && isSplitStep) {
			sum = this.insideSumNo(step.childYes, map.yesPath, sum + (map.insideYes || 1));
		} else if (step.childYes && !isSplitStep) {
			sum = this.insideSumNo(step.childYes, map.yesPath, sum);
		} else if (isSplitStep) {
			sum = sum + (this.hasNewFlowSteps ? 0 : 1);
		}
		return sum;
	},
	openPopup: function (popup) {
		this.clickAction = true;
		this.zoomDisabled = true;

		this.setState({ popup }, () => {
			const { width, height } = this.canvas.canvas;
			this.zoomWidth = width.baseVal.value;
			this.zoomHeight = height.baseVal.value;
			this.canvas.setViewBox(this.currentPos.x, this.currentPos.y, this.zoomWidth, this.zoomHeight, false);
		});
	},
	getStepOnClick: function (componentType, objectPath, step) {
		// Maby move this logic into the components when everything is done
		if (this.props.editable) {
			if (componentType === 'createTodo') {
				return () => {
					const properties = step.params.length
						? step.params
						: [{ name: 'User', value: [Tools.AppService.getSelf().id] }];
					openDrawer('CreateTodosAction', {
						properties,
						onSave: params => {
							this.props.onAddStep({ params }, objectPath, true);
						}
					});
				};
			} else if (componentType === 'createPhoneCall') {
				return () => {
					const properties = step.params.length
						? step.params
						: [{ name: 'User', value: [Tools.AppService.getSelf().id] }];
					openDrawer('PlanPhonecallsAction', {
						properties,
						assignOneUser: true,
						onSave: params => {
							this.props.onAddStep({ params }, objectPath, true);
						}
					});
				};
			} else if (['createActivity', 'updateClient', 'updateContact', 'assign'].includes(componentType)) {
				return () => {
					const options = {
						properties: step.params || null,
						activeUsers: Tools.AppService.getUsers(),
						roles: Tools.AppService.getRoles(),
						tagEntity: 'contact',
						hideDate: true,
						isTrigger: true,
						isAutomation: true,
						isFlow: true
					};

					let promise;

					switch (componentType) {
						case 'createActivity':
							options.activityCustomFields = Tools.AppService.getCustomFields('activity');
							options.activityTypes = Tools.AppService.getActivityTypes('activity');
							promise = Tools.$upModal.open('CreateActivityAction', options);
							break;
						case 'updateClient':
							options.accountCategories = Tools.AppService.getCategories('account');
							options.accountCustomFields = Tools.AppService.getCustomFields('account');
							promise = Tools.$upModal.open('UpdateClientAction', options);
							break;
						case 'updateContact':
							options.contactCategories = Tools.AppService.getCategories('contact');
							options.contactCustomFields = Tools.AppService.getCustomFields('contact');
							promise = Tools.$upModal.open('UpdateContactAction', options);
							break;
						case 'assign':
							promise = openAssignModalLead(options);
							break;
					}

					// eslint-disable-next-line promise/catch-or-return
					promise.then(params => this.props.onAddStep({ params }, objectPath, true));
				};
			} else if (componentType === 'end') {
				return () => {
					openDrawer('FlowLoopSettings', {
						loop: this.props.flow.loop,
						loopTime: this.props.flow.loopTime,
						loopUnit: this.props.flow.loopUnit,
						onClose: params => {
							if (params) {
								this.props.onFlowPropChange(params);
							}
						}
					});
				};
			}
		}

		return () => {
			const isPreview = step.previewOnly || !this.props.editable;
			const type = isPreview ? `${step.type}Preview` : step.type;
			const isDrawer = true;

			this.openPopup({
				type,
				step,
				isDrawer,
				edit: true,
				path: objectPath
			});
		};
	},
	validateStep: function (step) {
		if (!step.params) {
			return false;
		}
		switch (step.type) {
			case 'assign': {
				const missingUser = !step.params.find(property => property.name === 'User' && property.value);
				const missingRole = !step.params.find(property => property.name === 'Role' && property.value);

				if (missingUser && missingRole) {
					return false;
				}
				break;
			}
			case 'clickedMail':
				if (!step.waitTime || !step.waitUnit) {
					return false;
				}
				break;
			case 'readMail':
				if (!step.waitTime || !step.waitUnit) {
					return false;
				}
				break;
			case 'formSubmit':
				if (!step.formId || !step.waitTime || !step.waitUnit) {
					return false;
				}
				break;
			case 'abTesting':
				if (!step.params || !step.params.variantA || !step.params.variantB) {
					return false;
				}
				break;
			case 'sendMail':
				if (!step.templateId) {
					return false;
				}
				break;
			case 'snooze':
				if (!step.waitTime || !step.waitUnit) {
					return false;
				}
				break;
			case 'createActivity': {
				const isPhoneCall = isPhoneCallStep(step);
				const missingUser = !step.params.find(p => p.name === 'User' && p.value?.length);
				const missingDesc = !step.params.find(p => p.name === 'Description' && p.value?.length);
				const missingDelay = !step.params.find(
					p => p.name === 'ActivityDelayInDays' && !isNaN(parseInt(p.value))
				);
				// Validate phonecall
				if (isPhoneCall && (missingUser || missingDesc || missingDelay)) {
					return false;
				}
				break;
			}
		}
		return true;
	},
	iterateDry: function (step, levels, path) {
		var map = {
			yesPath: null,
			noPath: null,
			merge: null,
			insideYes: 0,
			insideNo: 0,
			levels: levels + 1,
			objectPath: path,
			valid: this.validateStep(step)
		};

		if (!map.valid) {
			this.hasErrors = true;
		}

		var isSplitStep = this.isSplitStep(step);

		// Iterate children
		if (step.merge) {
			map.merge = this.iterateDry(step.merge, map.levels, path + '.merge');
		}

		// Iterate children
		if (step.childYes) {
			map.yesPath = this.iterateDry(step.childYes, map.levels, path + '.childYes');
		}

		// You Only Have No In Split, YOHNIS
		if (isSplitStep && step.childNo) {
			map.noPath = this.iterateDry(step.childNo, map.levels, path + '.childNo');
		}

		// Count levels inside to the right path
		if (step.childYes) {
			map.insideYes = this.insideSumYes(step.childYes, map.yesPath, isSplitStep ? 1 : 0);
		} else if (isSplitStep) {
			map.insideYes = this.hasNewFlowSteps ? 0 : 1;
		}

		// Count levels inside to the no path
		if (step.childNo) {
			map.insideNo = this.insideSumNo(step.childNo, map.noPath, isSplitStep ? 1 : 0);
		} else if (isSplitStep) {
			map.insideNo = this.hasNewFlowSteps ? 0 : 1;
		}

		map.levels = Math.max(map.yesPath ? map.yesPath.levels : 0, map.noPath ? map.noPath.levels : 0, map.levels);

		return map;
	},
	renderFlow: function () {
		if (this.zoomChanged) {
			if (this.debug) {
				console.log('zoom changed, skipping render');
			}
			this.zoomChanged = false;
			return;
		} else if (this.clickAction) {
			if (this.debug) {
				console.log('flow was clicked, skipping render');
			}
			this.clickAction = false;
			return;
		}

		// Unmount any nodes from last render
		this.unmountReactNodes();

		var self = this;

		if (this.debug) {
			console.time('renderFlow');
		}
		// Calc center x coord
		var centerX = 0;
		var startY = this.hasNewFlowSteps ? 60 : 30;
		const lineLength = this.getLineLength();

		// Reset endLines, elements and coord variables
		this.endLines = [];
		this.elements = [];
		this.reactNodes = [];
		this.xMin = 0;
		this.xMax = 0;
		this.endY = 0;
		this.deltaX = 0;
		this.deltaY = 0;
		this.hasErrors = false;

		// Clear the canvas
		this.canvas.clear();

		// Draw start circle
		if (this.hasNewFlowSteps) {
			this.renderComponent(
				'externalComponent',
				centerX,
				startY,
				<FlowStepStart flow={this.props.flow} locked={this.props.editable} />,
				COMPONENT_MAX_HEIGHT.FlowStepStart
			);
		} else {
			const start = this.canvas
				.circle(centerX, startY, 10)
				.attr({ fill: this.colors.dassigGrey, 'stroke-width': 0 });
			this.elements.push(start);
		}

		// Start traversing tree of steps if we have some
		if (this.props.flow.path) {
			var legMap = this.iterateDry(this.props.flow.path, 0, '');
			var parseI = 0;
			this.parseStep(this.props.flow.path, legMap, centerX, startY, false, parseI, legMap.levels);
		} else {
			// Go to end if we had no steps
			var line = this.renderComponent('dashedLine', centerX, startY);
			this.endLines.push(line);

			// Add edit point if editable
			if (this.props.editable) {
				this.renderComponent('editPoint', centerX, startY + lineLength, '');
			}
		}

		// Update endY
		self.endY = self.endY + (!this.props.flow.path ? lineLength * 2 : lineLength);

		// Move all endlines to the end point
		_.each(this.endLines, function (line) {
			// Get last stroke
			var last = line.attrs.path[line.attrs.path.length - 1];

			// Update x if needed
			if (last[1] !== centerX) {
				// go to end y position - 40, then set x to center
				if (last[1] < centerX) {
					line.attr('path').push(['L', last[1], self.endY - self.params.lineCurve]);
					// curve to the right
					var downRightCurve = self.curve.downRight(self.params.lineCurve).split(' ');
					line.attr('path').push(downRightCurve);
				} else if (last[1] > centerX) {
					line.attr('path').push(['L', last[1], self.endY - self.params.lineCurve]);
					var downLeftCurve = self.curve.downLeft(self.params.lineCurve).split(' ');
					line.attr('path').push(downLeftCurve);
				} else {
					line.attr('path').push(['L', last[1], self.endY]);
				}
				line.attr('path').push(['L', centerX, self.endY]);
			}

			// Update y coord
			line.attr('path').push(['L', centerX, self.endY]);

			// Set new path
			line.attr('path', line.attr('path'));
		});

		// Create dashed end line
		this.renderComponent('dashedLine', centerX, this.endY, 40);

		// Draw end
		this.renderComponent(
			'end',
			centerX,
			this.endY + (this.hasNewFlowSteps ? COMPONENT_MAX_HEIGHT.FlowStepEnd : 40)
		);

		var center = Math.round(
			(this.canvas.canvas.width.baseVal.value
				? this.canvas.canvas.width.baseVal.value
				: document.documentElement.clientWidth) / 2
		);

		// Move elements to the center
		this.elements.forEach(function (el) {
			el.transform('...T' + center + ',0');
		});

		// This is for the react mounted elements
		this.reactNodes.forEach(el => {
			el.setAttribute('transform', 'translate(' + (center - centerX) + ')');
		});

		if (this.debug) {
			console.timeEnd('renderFlow');
		}

		// Callback to parent if errors changed
		this.onErrorChange(this.hasErrors);
	},
	onErrorChange: function (val) {
		if (this.props.onErrorChange) {
			this.props.onErrorChange(val);
		}
	},
	zoomIn: function () {
		if (this.zoomDisabled) {
			return;
		}
		// Set zoomLevel and trigger re-render...
		if (this.state.zoomLevel < this.maxZoom) {
			var newZoom = this.state.zoomLevel + this.zoomStep;
			newZoom = Math.min(newZoom, this.maxZoom);
			var svg = this.canvas.canvas;
			this.zoomWidth = svg.width.baseVal.value * (1 - newZoom * this.zoomStep);
			this.zoomHeight = svg.height.baseVal.value * (1 - newZoom * this.zoomStep);
			this.redraw();
			this.zoomChanged = true;
			this.setState({ zoomLevel: newZoom });
		}
	},
	zoomOut: function () {
		if (this.zoomDisabled) {
			return;
		}
		if (this.state.zoomLevel > this.minZoom) {
			var newZoom = this.state.zoomLevel - this.zoomStep;
			newZoom = Math.max(newZoom, this.minZoom);
			var svg = this.canvas.canvas;
			this.zoomWidth = svg.width.baseVal.value * (1 - newZoom * this.zoomStep);
			this.zoomHeight = svg.height.baseVal.value * (1 - newZoom * this.zoomStep);
			this.redraw();
			this.zoomChanged = true;
			this.setState({ zoomLevel: newZoom });
		}
	},
	resizeTimer: null,
	resize: function () {
		var self = this;
		if (self.resizeTimer) {
			clearTimeout(self.resizeTimer);
		}
		self.resizeTimer = setTimeout(function () {
			self.renderFlow();
		}, 200);
	},
	findPos: function (obj) {
		var posX = obj.offsetLeft,
			posY = obj.offsetTop,
			posArray;
		while (obj.offsetParent) {
			if (obj === document.getElementsByTagName('body')[0]) {
				break;
			} else {
				posX = posX + obj.offsetParent.offsetLeft;
				posY = posY + obj.offsetParent.offsetTop;
				obj = obj.offsetParent;
			}
		}
		posArray = [posX, posY];
		return posArray;
	},
	getRelativePosition: function (e, obj) {
		var x, y, pos;
		if (e.pageX || e.pageY) {
			x = e.pageX;
			y = e.pageY;
		} else {
			x = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
			y = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
		}

		pos = this.findPos(obj);
		x -= pos[0];
		y -= pos[1];

		return { x: x, y: y };
	},
	redraw: function () {
		var minVisible = 20;
		var nextX = this.currentPos.x + this.deltaX;
		var nextY = this.currentPos.y + this.deltaY;

		var svg = this.canvas.canvas;
		var zoomWidth = svg.width.baseVal.value * this.zoomStep;

		// Limit left
		var maxLeft = zoomWidth + this.xMax - minVisible;
		if (nextX >= maxLeft) {
			nextX = maxLeft;
		}

		// Limit right
		var maxRight = Math.abs(zoomWidth - this.xMin - minVisible);
		if (nextX < 0 && Math.abs(nextX) >= maxRight) {
			nextX = maxRight * -1;
		}

		// Limit top
		var maxTop = this.endY - minVisible;
		if (nextY >= maxTop) {
			nextY = maxTop;
		}

		// Limit bottom
		var maxBottom = this.zoomHeight - 20 - minVisible;
		if (nextY < 0 && Math.abs(nextY) >= maxBottom) {
			nextY = maxBottom * -1;
		}

		this.currentPos.x = nextX;
		this.currentPos.y = nextY;

		this.canvas.setViewBox(this.currentPos.x, this.currentPos.y, this.zoomWidth, this.zoomHeight, false);
	},
	// Start panning
	panStart: function (e) {
		if (this.zoomDisabled || e.button === 2) {
			e.preventDefault();
			return false;
		}
		var container = this.canvas.canvas.parentNode;
		// Get drag start position
		this.initialPos = this.getRelativePosition(e, container);

		// Bind drag function to container
		container.onmousemove = this.dragging;

		// Set class to container
		container.classList.add('dragging');

		// Prevent other dragging
		document.onmousemove = function () {
			return false;
		};
		if (e.preventDefault) {
			e.preventDefault();
		} else {
			e.returnValue = false;
		}
		return false;
	},
	// Remove temp drag events
	panEnd: function () {
		document.onmousemove = null;
		this.canvas.canvas.parentNode.onmousemove = null;

		// Remove class to container
		this.canvas.canvas.parentNode.classList.remove('dragging');
	},
	dragging: function (e) {
		var container = this.canvas.canvas.parentNode;
		var svg = this.canvas.canvas;
		this.zoomWidth = svg.width.baseVal.value * (1 - this.state.zoomLevel * this.zoomStep);
		this.zoomHeight = svg.height.baseVal.value * (1 - this.state.zoomLevel * this.zoomStep);
		var newPoint = this.getRelativePosition(e, container);
		this.deltaX = ((this.zoomWidth * (newPoint.x - this.initialPos.x)) / svg.width.baseVal.value) * -1;
		this.deltaY = ((this.zoomHeight * (newPoint.y - this.initialPos.y)) / svg.height.baseVal.value) * -1;
		this.initialPos = newPoint;

		this.redraw();

		if (e.preventDefault) {
			e.preventDefault();
		} else {
			e.returnValue = false;
		}
		return false;
	},
	positionPopup: function (didMove) {
		// If popup is open
		if (this.state.popup && this._popup && this._wrap) {
			// Find clicked element in svg
			var clickedElement = document.querySelector('.flow-clicked-point');

			if (clickedElement) {
				const isMailBehaviour = ['readMail', 'clickedMail'].includes(this.state.popup.step.type);
				const isNewStep =
					this.hasNewFlowSteps && (isMailBehaviour || STEP_COMPONENT_MAP[this.state.popup.step.type]);

				// Get position of clicked element
				const pos = clickedElement.getBoundingClientRect();
				// get pos of wrapper
				const parentPos = this._wrap.getBoundingClientRect();
				// get height of clicked
				const height = clickedElement.getBBox().height;

				let left = 0,
					top = 0;

				if (isNewStep) {
					const popupWidth = document.getElementById('flow-popup')?.getBoundingClientRect().width;
					const stepComponentHeight = clickedElement.firstChild.offsetHeight;
					top = pos.top - parentPos.top - stepComponentHeight / 2;
					// I do not understand why the 50 is needed...
					left = pos.left + parentPos.left + 50 - (popupWidth - pos.width) / 2 + 34;

					// Set popup new position
					this._popup.style.top = top + 'px';
					this._popup.style.left = left + 'px';
				} else {
					// Calculate real left top relative to parent edges
					top = pos.top + height / 2 - parentPos.top;
					left = pos.left + height / 2 - parentPos.left;

					if (this.props.editable && this.state.popup.step.previewOnly) {
						top += 18;
						left += 20;
					}

					const flowPopupRect = document.getElementById('flow-popup')?.getBoundingClientRect();
					if (flowPopupRect?.width > 360) {
						left -= (flowPopupRect.width - 360) / 2;
					}

					// Set popup new position
					this._popup.style.top = top + 'px';
					this._popup.style.left = left + 'px';
				}

				// If popup was moved before we end here
				if (didMove) {
					return;
				}

				// Calculate popup bounds
				var style = window.getComputedStyle(this._popup);
				var offsetLeft = Math.abs(parseInt(style.getPropertyValue('margin-left')));
				var boundLeft = left - offsetLeft;
				var boundRight = left + this._popup.clientWidth - offsetLeft;
				var boundBottom = top + this._popup.clientHeight;
				var moved = false;
				var margin = 20; // The min margin we want to the edges

				// Below we check if popup position is out of bounds
				// If it is we move the canvas so the popup is visible + margin
				if (boundLeft <= 0) {
					// Move to the right
					this.deltaX -= Math.abs(boundLeft) + margin;
					this.redraw();
					moved = true;
				} else if (boundRight >= this._wrap.clientWidth) {
					// Move to the left
					this.deltaX += Math.abs(boundRight - this._wrap.clientWidth) + margin;
					this.redraw();
					moved = true;
				}

				if (boundBottom >= this._root.clientHeight) {
					// Move up
					this.deltaY += Math.abs(boundBottom - this._root.clientHeight) + margin;
					this.redraw();
					moved = true;
				}

				// If we moved the canvas we reposition the popup again so it matches the clicked element
				if (moved) {
					this.positionPopup(true); // Pass moved flag to prevent inf-loop
				}
			}
		}
	},
	//Should not close Drawer if a modal is open
	hasParentModal: function (el) {
		if (el.classList?.toString().includes('up-modal')) {
			return true;
		} else if (el.parentNode) {
			return this.hasParentModal(el.parentNode);
		}
		return false;
	},
	closePopup: function (render, target) {
		if (target && this.hasParentModal(target)) {
			return;
		}
		this.clickAction = !render;
		this.zoomDisabled = false;
		this.setState({
			popup: null
		});
	},
	componentDidMount: function () {
		var root = this._root;
		this.canvas = new Raphael(root, '100%', '100%');
		this.renderFlow();

		window.addEventListener('resize', this.resize);
		root.addEventListener('mousedown', this.panStart);
		root.addEventListener('mouseup', this.panEnd);

		Tools.AccountProfile.get()
			.then(res => this.setState({ accountProfile: res.data }))
			.catch(err => logError(err, 'Could not get account profile'));
	},
	componentWillUnmount: function () {
		var root = this._root;
		window.removeEventListener('resize', this.resize);
		root.removeEventListener('mousedown', this.panStart);
		root.removeEventListener('mouseup', this.panEnd);
		this.unmountReactNodes();
	},
	componentDidUpdate: function () {
		this.renderFlow();
		this.positionPopup();
	},
	render: function () {
		var self = this;

		var popup = null;
		var popupClass = '';

		if (this.state.popup) {
			popupClass += this.state.popup.type;
			popupClass += ' visible';
			var mergeProps;

			if (this.props.editable) {
				mergeProps = {
					onSave: function (data) {
						if (data.type === 'abTesting') {
							var abTesting = _.cloneDeep(data);
							abTesting.params = {
								yesValue: data.params.variantB.value
							};
							self.props.onAddStep(data, self.state.popup.path, self.state.popup.edit);

							var variantA = {
								type: 'sendMail',
								templateId: data.params.variantA.templateId,
								params: { category: data.params.category }
							};
							self.props.onAddStep(variantA, self.state.popup.path + '.childNo', self.state.popup.edit);

							var variantB = {
								type: 'sendMail',
								templateId: data.params.variantB.templateId,
								params: { category: data.params.category }
							};
							self.props.onAddStep(variantB, self.state.popup.path + '.childYes', self.state.popup.edit);

							var mergeStep = {
								type: 'clickedMail',
								parentType: 'abTesting',
								relationData: [data.params.variantA.relationData, data.params.variantB.relationData]
							};

							self.props.onAddStep(mergeStep, self.state.popup.path + '.merge', self.state.popup.edit);
						} else {
							self.props.onAddStep(data, self.state.popup.path, self.state.popup.edit);
						}

						self.closePopup(true);
					},
					removeStep: function () {
						self.props.onRemoveStep(self.state.popup.path);
						self.closePopup(true);
					}
				};
			} else {
				popupClass += ' preview-popup';
				mergeProps = {
					closePopup: this.closePopup.bind(this, false)
				};

				if (this.state.popup && this.state.popup.type === 'snoozePreview') {
					popupClass += ' snooze-preview';
				}

				if (this.state.popup.step.isMerge) {
					popupClass += ' ab-testing-preview';
				}
			}
			var props = _.merge({}, this.props, mergeProps);
			props.popup = self.state.popup;
			props.positionPopup = self.positionPopup;
			props.accountProfile = self.state.accountProfile;
			props.closePopup = self.closePopup;
			popup = React.createElement(ReactTemplates.segment.flowPopup, props);
		}

		var isDrawer = this.state.popup && this.state.popup.isDrawer && !this.props.editable;
		const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1; // Drag doesn't work after zooming in firefox so we disable zoom
		return (
			<div
				id="flow-preview"
				ref={function (ref) {
					self._wrap = ref;
				}}
			>
				<div
					id="flow-inner"
					className={this.state.popup ? 'flow-inner--popup-open' : ''}
					ref={function (ref) {
						self._root = ref;
					}}
				/>
				{!isFirefox ? (
					<div id="flow-zoom-controls">
						<button
							type="button"
							onClick={this.zoomIn}
							disabled={this.state.zoomLevel === this.maxZoom || this.zoomDisabled}
							ref={function (ref) {
								self._zoomIn = ref;
							}}
						>
							<b className="fa fa-plus" />
						</button>
						<button
							type="button"
							onClick={this.zoomOut}
							disabled={this.state.zoomLevel === this.minZoom || this.zoomDisabled}
							ref={function (ref) {
								self._zoomOut = ref;
							}}
						>
							<b className="fa fa-minus" />
						</button>
					</div>
				) : null}

				<window.Portal isOpen={isDrawer} hasBackdrop={false} onClose={this.closePopup.bind(this, false)}>
					{popup}
				</window.Portal>
				{!isDrawer
					? [
							/* eslint-disable */
							<div
								key="popup-curtain"
								id="popup-curtain"
								className={this.state.popup ? 'visible' : null}
								onClick={this.closePopup.bind(this, false)}
							/>,
							<div
								key="flow-popup"
								id="flow-popup"
								ref={function (ref) {
									self._popup = ref;
								}}
								className={popupClass}
							>
								{popup}
							</div>
							/* eslint-enable */
					  ]
					: null}
			</div>
		);
	}
});
