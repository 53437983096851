import AdminHeader from 'Components/Admin/AdminHeader';
import { Icon, Toggle, Button } from '@upsales/components';
import openModal from 'App/services/Modal';

ReactTemplates.admin.usersAndRoles = window.ReactCreateClass({
	getInitialState: function () {
		return {
			closedRoles: [],
			searchStr: '',
			showInactive: false
		};
	},

	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;

		this.doSearch = _.debounce(this.doSearch, 300);
		this.lang = {
			title: t('admin.usersAndRoles'),
			desc: t('admin.usersAndRoles.description'),
			table: {
				title: t('admin.usersAndRoles.handle')
			},
			new: t('default.new'),
			user: t('default.user'),
			users: t('default.users'),
			inactive: t('default.inactiveAndVisible'),
			removed: t('default.inactive'),
			role: t('default.role'),
			remove: t('default.remove'),
			addNewRole: t('admin.addRole'),
			name: t('default.name'),
			newNameForRole: t('admin.newNameForRole'),
			removedUsers: t('admin.removedUsers'),
			activeUsers: t('admin.activeUsers'),
			allUsers: t('admin.allUsers'),
			removedAndInactiveUsers: t('admin.removedAndInactiveUsers'),
			cannotDeleteRoleWithUsers: t('admin.cannotDeleteRoleWithUsers'),
			admin: t('admin.admin'),
			includeInactive: t('ads.includeInactive')
		};

		this.numberActiveUsers = this.props.rootData.pageData.activeUsers.reduce((sum, user) => {
			return user.active && !user.ghost ? sum + 1 : sum;
		}, 0);

		const combinedUsers = this.props.rootData.pageData.deletedAndInactiveUsers.concat(
			this.props.rootData.pageData.activeUsers
		);
		this.numberInactiveUsers = combinedUsers.filter((user, index, arr) => {
			return arr.findIndex(u => u.id === user.id) === index;
		}).length;
	},

	onNewUser: function () {
		this.props.tools.$state.go('administration.user');
	},

	onNewRole: function () {
		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_EDIT_ROLE_MODAL')) {
			return openModal('EditRoleModal');
		}
		this.props.tools.$upModal.open('EditRole');
	},

	getUserByState: function (state, users) {
		switch (state) {
			case 'active':
				return users.filter(user => user.active === 1 && user.ghost === 0);

			case 'inactive':
				return users.filter(user => user.active === 1 && user.ghost === 1);

			case 'removed':
				return users.filter(user => user.active === 0 && user.ghost === 0);
		}
	},

	FindByAttributeValue: function (attribute, value) {
		var All = document.getElementsByClassName('admin-table-row');
		for (var i = 0; i < All.length; i++) {
			if (All[i].getAttribute(attribute) === value) {
				return All[i];
			}
		}
	},

	editRole: function (role) {
		// Open edit modal
		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_EDIT_ROLE_MODAL')) {
			return openModal('EditRoleModal', { roleId: role.id });
		}
		this.props.tools.$upModal.open('EditRole', { roleId: role.id });
	},

	deleteRole: function (roleId, e) {
		e.stopPropagation();

		var self = this;
		var tools = self.props.tools;

		if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
			openModal('RemoveAlert', {
				title: self.lang.role,
				body: 'confirm.removeRole',
				onClose: confirmed => {
					if (confirmed) {
						tools.Role.delete(roleId);
					}
				}
			});
			return;
		}

		// eslint-disable-next-line promise/catch-or-return
		tools.$upModal
			.open('warningConfirm', {
				title: self.lang.remove + ' ' + self.lang.role.toLowerCase(),
				body: 'confirm.removeRole',
				resolveTrue: 'default.remove',
				icon: 'fa-warning'
			})
			.then(function () {
				tools.Role.delete(roleId);
			});
	},

	toggleCategory: function (id) {
		var self = this;
		var ids = [...self.state.closedRoles];

		if (ids.includes(id)) {
			const index = ids.indexOf(id);
			if (index !== -1) {
				ids.splice(index, 1);
			}
		} else {
			ids.push(id);
		}

		self.setState({ closedRoles: ids });
	},

	buildTree: function (users, roles) {
		var self = this;
		const usersWithoutRole = [];

		users.forEach((item, index) => {
			var productSortId = index;

			if (item.role && roles[item.role.id]) {
				roles[item.role.id].children.push({
					id: item.id,
					type: 'user',
					name: item.name,
					sortId: productSortId,
					active: item.active,
					role: item.role,
					email: item.email,
					ghost: item.ghost,
					billingAdmin: item.billingAdmin,
					administrator: item.administrator,
					support: item.support,
					crm: item.crm
				});
			} else {
				usersWithoutRole.push({
					id: item.id,
					type: 'user',
					name: item.name,
					sortId: productSortId,
					active: item.active,
					role: item.role,
					email: item.email,
					ghost: item.ghost,
					billingAdmin: item.billingAdmin,
					administrator: item.administrator,
					support: item.support,
					crm: item.crm
				});
			}
		});

		Object.values(roles).forEach(role => {
			if (role.parentId) {
				roles[role.parentId].children.push(role);
			}
		});
		let count = 0;
		const topLevelRoles = usersWithoutRole
			.concat(Object.values(roles).filter(role => role.parentId === 0))
			.map(r => {
				count += self.getChildren(r);
				return r;
			});
		self.numberActiveUsers = count;
		return topLevelRoles;
	},

	itemTemplate: function (item) {
		return {
			key: 'users-dnd-' + item.id,
			targets: {
				id: item.id,
				parentId: item.role ? item.role.id : null,
				parentName: item.role ? item.role.name : null,
				sortId: item.sortId
			},
			originalItem: item,
			type: 'role',
			categoryName: 'role'
		};
	},

	roleObject: function (rolesById, category) {
		rolesById[category.id] = {
			id: category.id,
			type: 'role',
			name: category.name,
			sortId: null,
			children: [],
			parentId: category.parent ? category.parent.id : 0
		};

		return rolesById;
	},

	doSearch: function (str) {
		this.setState({ searchStr: str });
	},

	onSearch: function (e) {
		this.doSearch(e.target.value);
	},

	onChange: function (items, savingItem) {
		this.props.rootData.onChange(items, savingItem);
	},

	goToUser: function (user) {
		Tools.$state.go('administration.user', { id: user.id });
	},

	toggleInactive: function (val) {
		this.setState({ showInactive: val });
	},

	renderHeader: function (item, level, closed) {
		var self = this;
		var trash = null;
		var classNames = 'admin-table-row is-header';
		var hasEditFeatures = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ADVANCED_ROLE_SETTINGS);
		if (item.id !== 0 && hasEditFeatures) {
			classNames += ' clickable';
			var btn = (
				<Button
					type="link"
					color="grey"
					hoverColor="red"
					disabled={!!item.children.length}
					className={!item.children.length ? 'delete-btn' : null}
					onClick={self.deleteRole.bind(self, item)}
				>
					<Icon name="trash" />
				</Button>
			);
			if (item.children.length) {
				trash = (
					<div className="tooltip-wrapper">
						{ReactTemplates.TOOLS.withTooltip(
							<span
								className="delete-btn"
								onClick={function (e) {
									e.stopPropagation();
								}}
							>
								{btn}
							</span>,
							this.lang.cannotDeleteRoleWithUsers,
							{ placement: 'left' }
						)}
					</div>
				);
			} else {
				trash = btn;
			}
		}

		var style = { paddingLeft: 10 * level + 20 + 'px' };
		var styleCol = { left: 10 * level + 20 + 'px' };

		return (
			<div
				className={classNames}
				key={'header-cell-' + item.name.replace(' ', '-') + '-' + item.id}
				onClick={item.id !== 0 && hasEditFeatures ? self.editRole.bind(self, item) : null}
			>
				{item.id !== 0 ? (
					<div className="admin-table-cell" style={style}>
						<Icon
							name={closed ? 'folder' : 'folder-open'}
							onClick={function (e) {
								e.stopPropagation();
								self.toggleCategory(item.id);
							}}
						/>
					</div>
				) : null}
				<div className="admin-table-cell header-title" style={styleCol}>
					<div className="role-name">
						<div className="role-name-inner">{item.name}</div>
						<span className="user-count">
							<Icon name="user" />
							{item.total}
						</span>
					</div>
					{item.id !== 0 && hasEditFeatures ? <Icon name="edit" /> : null}
					{trash}
				</div>
			</div>
		);
	},

	renderRow: function (user, level) {
		var self = this;
		var status = null;
		var classNames = 'admin-table-row clickable';
		if (user.role) {
			classNames +=
				Array.isArray(self.props.closedRoles) && self.props.closedRoles.includes(user.role.id) ? ' closed' : '';
		}
		let inactiveRow = false;

		var style = { paddingLeft: 10 * level + 30 + 'px' };
		if (!user.active) {
			status = this.lang.removed;
			classNames += ' admin-table-row-user-inactive';
			inactiveRow = true;
		} else if (user.ghost) {
			status = this.lang.inactive;
			classNames += ' admin-table-row-user-inactive';
			inactiveRow = true;
		} else if (user.administrator) {
			status = this.lang.admin;
		}

		const contactCount = this.state.showInactive
			? this.numberInactiveUsers + this.numberActiveUsers
			: this.numberActiveUsers;
		const shouldRenderAvatar = contactCount <= 1000;

		return (
			<div
				className={classNames}
				key={'user-' + user.id}
				onClick={function () {
					self.goToUser(user);
				}}
			>
				<div className="admin-table-cell user-name-col" style={style}>
					{shouldRenderAvatar ? (
						<ReactTemplates.TOOLS.avatar
							user={user}
							size={25}
							style={{ marginTop: '7px' }}
							skipGravatarCall={user.active ? false : true}
						/>
					) : null}
					<div className="user-name-inner">
						{user.name}
						<span className="email">{user.email}</span>
					</div>
				</div>
				<div className="admin-table-cell is-admin-col">
					{status}
					<Icon name="edit" className="user-edit-icon" />
					{user.administrator && user.billingAdmin && !inactiveRow ? <Icon name="usd" space="mlm" /> : null}
					{user.support && !inactiveRow ? <Icon name="customer-support" space="mlm" /> : null}
				</div>
			</div>
		);
	},

	getChildren: function (item, hasParentMatched = false) {
		var self = this;
		const { searchStr = '' } = self.state;
		let count = 0;
		const matchedSearchStr =
			item.name.toLowerCase().includes(searchStr.toLowerCase()) ||
			item.email?.toLowerCase().includes(searchStr.toLowerCase());
		if (item.type === 'user') {
			item.renderThisItem = matchedSearchStr;
			return matchedSearchStr ? 1 : 0;
		}
		for (const child of item?.children ?? []) {
			if (child.type === 'role') {
				child.renderThisItem = hasParentMatched || matchedSearchStr;
				count += self.getChildren(child, matchedSearchStr || hasParentMatched);
			} else if (
				(searchStr && child.name.toLowerCase().includes(searchStr.toLowerCase())) ||
				child.email?.toLowerCase().includes(searchStr.toLowerCase()) ||
				matchedSearchStr ||
				hasParentMatched
			) {
				child.renderThisItem = true;
				count++;
			}
		}
		item.renderThisItem = matchedSearchStr || hasParentMatched || count > 0;
		item.total = count;
		return count;
	},

	getRows: function (array, level, index) {
		var self = this;

		return array.reduce((rows, item) => {
			index++;

			if (item.type === 'role' && item.renderThisItem) {
				var closed = self.state.closedRoles.includes(item.id);
				rows.push(self.renderHeader(item, level + 1, closed));
				if (!closed) {
					var childValues = self.getRows(
						item.children.filter(i => i.renderThisItem),
						level + 1,
						index
					);
					rows = rows.concat(childValues);
				}

				return rows;
			} else if (item.renderThisItem) {
				rows.push(self.renderRow(item, level + 1));
			}
			return rows;
		}, []);
	},

	renderTree: function (users) {
		var i = 1; // cannot be 0

		var rolesById = this.props.rootData.pageData.roles.reduce(this.roleObject, {});
		var tree = this.buildTree(users, rolesById);
		return this.getRows(tree, 0, i);
	},

	render: function () {
		var self = this;
		var pageData = self.props.rootData.pageData;

		const { activeUsers, deletedAndInactiveUsers } = pageData;
		const { showInactive } = self.state;
		const userFilter = user =>
			user.name.toLowerCase().includes(self.state.searchStr.toLowerCase()) ||
			user.email?.toLowerCase().includes(self.state.searchStr.toLowerCase()) ||
			user.role?.name.toLowerCase().includes(self.state.searchStr.toLowerCase());

		let users;
		if (showInactive) {
			const combinedUsers = [...activeUsers, ...deletedAndInactiveUsers];
			users = combinedUsers
				.filter((user, index, self) => index === self.findIndex(u => u.id === user.id))
				.filter(user => userFilter(user));
		} else {
			users = activeUsers.filter(user => user.active && !user.ghost && userFilter(user));
		}

		users.sort((a, b) => {
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			return 0;
		});

		const canAddRole = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.ADVANCED_ROLE_SETTINGS);

		return (
			<div id="admin-page-users-roles">
				<AdminHeader title={this.lang.title} description={this.lang.desc} image="users.svg" articleId={563} />

				<div id="admin-content">
					<div className="admin-table">
						<div className="admin-table-top table-top-flex">
							<span className="admin-table-title table-title-flex">{self.lang.table.title}</span>
							<div>
								<Toggle
									checked={self.state.showInactive}
									onChange={this.toggleInactive}
									color="medium-green"
								/>{' '}
								<label style={{ display: 'inline-block', color: '#999', marginRight: '10px' }}>
									{self.lang.includeInactive}
								</label>
								<div className="table-search" style={{ display: 'inline-block', marginRight: '10px' }}>
									<input type="text" onChange={self.onSearch} />
									<b className="fa fa-search"></b>
								</div>
								{canAddRole ? (
									<Button onClick={self.onNewRole} size="sm" style={{ marginRight: '10px' }}>
										{self.lang.new} {self.lang.role.toLowerCase()}
									</Button>
								) : null}
								<Button onClick={self.onNewUser} size="sm">
									{self.lang.new} {self.lang.user.toLowerCase()}
								</Button>
							</div>
						</div>

						<div
							className="admin-table-body"
							ref={function (r) {
								self._mainTableBody = r;
							}}
						>
							{self.renderHeader(
								{
									id: 0,
									name: self.state.showInactive ? self.lang.allUsers : self.lang.activeUsers,
									total: users.length
								},
								0,
								false
							)}
							{this.renderTree(users)}
						</div>
					</div>
				</div>
			</div>
		);
	}
});
