import RequestBuilder, { comparisonTypes as ComparisonTypes } from 'Resources/RequestBuilder';

export const openActivitiesFilter = (query: RequestBuilder) => {
	const orBuilder = query.orBuilder();
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, false);
	orBuilder.addFilter({ field: 'closeDate' }, ComparisonTypes.Equals, null);
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, true);
	orBuilder.addFilter({ field: 'outcome' }, ComparisonTypes.Equals, 'planned');
	orBuilder.done();
};

export const closedActivitiesFilter = (query: RequestBuilder) => {
	const orBuilder = query.orBuilder();
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, false);
	orBuilder.addFilter({ field: 'closeDate' }, ComparisonTypes.NotEquals, null);
	orBuilder.next();
	orBuilder.addFilter({ field: 'isAppointment' }, ComparisonTypes.Equals, true);
	orBuilder.addFilter({ field: 'outcome' }, ComparisonTypes.NotEquals, 'planned');
	orBuilder.done();
};
