import _ from 'lodash';
import logError from 'App/babel/helpers/logError';
import openModal from 'App/services/Modal';
import { openIntegrationModal } from 'Components/IntegrationModal/IntegrationModal';
import { openFileBrowserModal } from 'Components/FileBrowser';

angular.module('domain.esign').controller('EditEsign', [
	'$scope',
	'AppService',
	'URL',
	'API',
	'$modalParams',
	'$translate',
	'Esign',
	'browserService',
	'pdfDelegate',
	'$upModal',
	'File',
	'$timeout',
	'utils',
	'RequestBuilder',
	'Contact',
	'selectHelper',
	'$filter',
	'$q',
	'$http',
	'NotificationService',
	'StandardIntegration',
	'$safeApply',
	function (
		$scope,
		AppService,
		URL,
		API,
		$modalParams,
		$translate,
		Esign,
		browserService,
		pdfDelegate,
		$upModal,
		File,
		$timeout,
		utils,
		RequestBuilder,
		Contact,
		selectHelper,
		$filter,
		$q,
		$http,
		NotificationService,
		StandardIntegration,
		$safeApply
	) {
		var EditEsign = this;
		var customerId;
		var meta = $modalParams.meta;
		var pdfHelper;
		var metadata;
		EditEsign.stages = [];

		EditEsign.esignAppPlaceholder = ReactTemplates.esignAppPlaceholder;
		EditEsign.esignAppPlaceholderProps = {
			visible: false,
			integrations: [],
			selectMode: false,
			loading: false,
			onClick: function (integration) {
				EditEsign.esignAppPlaceholderProps.loading = true;
				EditEsign.esign.integrationId = integration.id;
				Esign.runAction({
					type: 'settings',
					integrationId: integration.id
				})
					.then(function (res) {
						EditEsign.esignAppPlaceholderProps.loading = false;
						EditEsign.esignAppPlaceholderProps.visible = false;
						EditEsign.hasAppPlaceholder = false;
						applySettings(res.data);
					})
					.catch(function (e) {
						console.error('Failed to load esign integration settings', e);
						EditEsign.esignAppPlaceholderProps.loading = false;
						EditEsign.esign.integrationId = null;
						NotificationService.addNotification({
							title: 'default.error',
							body: $translate.instant('esign.integrationError', { name: integration.name }),
							style: NotificationService.style.ERROR,
							icon: 'times'
						});
					});
			}
		};

		var getUrl = function (id) {
			return URL + API + customerId + '/resources/download/internal/' + id + '?inline=true';
		};

		// Validate phone for +
		var validPhoneNr = function (nr) {
			if (nr && nr.length && nr[0] !== '+') {
				return false;
			}
			return true;
		};

		var validEmail = function (email) {
			var re =
				/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
			var isEmail = re.test(email);
			if (email && isEmail) {
				return true;
			}

			return false;
		};

		var validateInvolvedForView = function (person) {
			var phoneValid = validPhoneNr(person.mobile);
			var emailValid = validEmail(person.email);

			if (!phoneValid) {
				person.$invalidPhone = true;
				person.deliveryMethod = 'email';
			} else {
				person.$invalidPhone = false;
			}

			if (!emailValid) {
				person.$invalidEmail = true;
			} else {
				person.$invalidEmail = false;
			}
		};

		var loadPdf = function () {
			EditEsign.pdfLoading = true;
			EditEsign.pdfPreviewError = false;

			if (!EditEsign.noPreview) {
				// Timeout preview after 5 secs
				var kill = $scope.$watch(
					function () {
						return pdfHelper.getPageCount();
					},
					function (pages) {
						if (pages) {
							EditEsign.pdfLoading = false;
							EditEsign.pdfPreviewError = false;
							EditEsign.pages = pages;
							$timeout(function () {
								$scope.reloadModalPosition();
							}, 100);
							kill();
						}
					}
				);
				$timeout(function () {
					// Still loading?
					if (EditEsign.pdfLoading) {
						EditEsign.pdfLoading = false;
						EditEsign.pdfPreviewError = true;
						kill();
					}
				}, 5000);
			} else {
				EditEsign.pdfLoading = false;
			}
		};

		var isUser = function (person) {
			return person.role !== undefined;
		};

		var personSelected = function (person) {
			return !!(
				_.find(EditEsign.esign.involved, function (p) {
					return (p.userId === person.id && isUser(person)) || (p.contactId === person.id && !isUser(person));
				}) ||
				(EditEsign.senderSigns && isUser(person) && person.userId === EditEsign.esign.user.id)
			);
		};

		var findSenderInInvolvees = function () {
			return _.find(EditEsign.esign.involved, function (person) {
				return person.userId === EditEsign.esign.user.id;
			});
		};

		var formatPersonSelection = function (person, container, escape) {
			return escape(person.name);
		};

		var formatPersonResult = function (person, jqObj, query, escape) {
			if (personSelected(person)) {
				jqObj.hide();
				person.disabled = true;
				return escape(person.name);
			} else {
				person.disabled = false;
				return utils.select2.formatParticipantResult(
					person,
					EditEsign.esign.client ? EditEsign.esign.client.id : null,
					jqObj
				);
			}
		};

		var getDesc = function (obj) {
			return _.escape(obj.description);
		};

		var validateForm = function (sendToScrive) {
			var defered = $q.defer();

			if (
				sendToScrive &&
				!(EditEsign.esign.file && EditEsign.esign.file.id) &&
				EditEsign.documentType !== 'html'
			) {
				defered.reject('MISSING_FILE');
			} else if (!EditEsign.esign.involved || !EditEsign.esign.involved.length) {
				defered.reject('NO_INVOLVEES');
			} else {
				var invalidEmailPerson = _.find(EditEsign.esign.involved, { $invalidEmail: true });
				if (invalidEmailPerson) {
					defered.reject('INVALID_EMAIL');
				} else {
					defered.resolve();
				}
			}

			return defered.promise;
		};

		var setFile = function (file) {
			EditEsign.fileStatus = EditEsign.fileStatuses.SET;
			EditEsign.pdfPath = getUrl(file.id);

			$http
				.get(EditEsign.pdfPath)
				.then(function () {
					// Set file to esign
					EditEsign.esign.file = {
						id: file.id,
						filename: file.filename,
						entity: file.entity,
						entityId: file.entityId,
						$$previewUrl: getUrl(file.id)
					};
					EditEsign.esign.title = file.filename;

					// Generate url
					loadPdf();
				})
				.catch(function () {
					EditEsign.fileStatus = EditEsign.fileStatuses.ERROR;

					NotificationService.addNotification({
						title: 'default.error',
						body: 'mail.uploadFail',
						style: NotificationService.style.ERROR,
						icon: 'times'
					});
				});
		};

		var clearFileAndSave = function () {
			EditEsign.fileStatus = EditEsign.fileStatuses.EMPTY;
			EditEsign.pdfPath = null;
			EditEsign.esign.file = null;

			// Save esign with no file
			if (EditEsign.esign.id) {
				Esign.save({ id: EditEsign.esign.id, file: null }, { skipNotification: true });
			}
		};

		EditEsign.fileStatuses = {
			EMPTY: 'EMPTY',
			UPLOADING: 'UPLOADING',
			SET: 'SET',
			ERROR: 'ERROR'
		};

		EditEsign.noPreview = browserService.isUnsupported() || browserService.isIE9();
		EditEsign.fileStatus = EditEsign.fileStatuses.EMPTY;
		EditEsign.saving = false;
		EditEsign.sending = false;
		EditEsign.pdfLoading = false;
		EditEsign.pages = 0;
		EditEsign.currentPage = 1;
		EditEsign.personToAdd = null;

		EditEsign.selectedPerson = null;
		EditEsign.showSenderInfo = false;
		EditEsign.moveToStage = false;

		EditEsign.bankIDCountrySelect = {
			width: 60,
			minimumResultsForSearch: -1,
			data: [],
			containerCss: { height: '20px' },
			formatSelection: function (item, container, escape) {
				return '<b class="icon flag-icon flag-icon-' + escape(item.id) + '"></b>';
			},
			formatResult: function (item, container, query, escape) {
				return '<b class="icon flag-icon flag-icon-' + escape(item.id) + '"></b>';
			}
		};

		EditEsign.save = function (send) {
			// Validate form
			return validateForm(send)
				.then(function () {
					var esign = angular.copy(EditEsign.esign);

					// Insert sender to involvees
					if (EditEsign.senderSigns && !findSenderInInvolvees()) {
						esign.involved.unshift(EditEsign.senderSignModel);
					}

					if (send) {
						EditEsign.sending = true;
						esign.sendToScrive = true;
					} else {
						EditEsign.saving = true;
						esign.sendToScrive = false;
					}

					var savePromise;
					if (send && EditEsign.documentType !== 'html') {
						savePromise = new Promise((resolve, reject) => {
							if (Tools.FeatureHelper.hasSoftDeployAccess('CONFIRM_ESIGN_REACT')) {
								openModal('ConfirmEsignModal', {
									esign,
									onClose: confirmed => (confirmed ? resolve() : reject())
								});
							} else {
								$upModal
									.open('confirmEsign', {
										esign: esign
									})
									.then(resolve)
									.catch(reject);
							}
						});
					} else {
						var integration = _.find(Tools.AppService.getEsignIntegrations(), { id: esign.integrationId });
						if (!esign.title && integration) {
							esign.title = integration.name;
						}
						savePromise = Esign.save(esign);
					}

					return savePromise
						.then(function (res) {
							$scope.resolve();
							return res;
						})
						.catch(() => {})
						.finally(function () {
							EditEsign.saving = false;
							EditEsign.sending = false;
						});
				})
				.catch(function (err) {
					// Ignore if modal was rejected due to state change
					if (err === 'State changed') {
						return;
					}
					if (typeof err === 'string') {
						if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
							openModal('Alert', {
								title: 'esign.formError',
								body: 'esign.formError.' + err,
								no: 'default.close',
								icon: 'warning'
							});
							return;
						}

						$upModal.open('warningConfirm', {
							title: 'esign.formError',
							body: 'esign.formError.' + err,
							no: 'default.close',
							icon: 'fa-warning'
						});
					} else {
						throw err;
					}
				});
		};

		EditEsign.removeFile = function () {
			// We do not want to delete non-esign files
			if (EditEsign.esign.file.entity !== 'Esign') {
				clearFileAndSave();
				return;
			}

			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					title: 'file.file',
					body: 'esign.removeFileConfirm',
					onClose: confirmed => {
						if (confirmed) {
							// Delete file then set new stuff in ctrl
							File.customer(customerId)
								.delete(EditEsign.esign.file)
								.then(function () {
									clearFileAndSave();
								})
								.catch(e => {
									logError(e, 'Failed deleting file');
								});
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title: 'file.removeFile',
					body: 'esign.removeFileConfirm',
					resolveTrue: 'default.remove',
					icon: 'fa-warning'
				})
				.then(function () {
					// Delete file then set new stuff in ctrl
					File.customer(customerId)
						.delete(EditEsign.esign.file)
						.then(function () {
							clearFileAndSave();
						})
						.catch(e => {
							logError(e, 'Failed deleting file');
						});
				});
		};

		EditEsign.next = function () {
			pdfHelper.next();
			EditEsign.currentPage = pdfHelper.getCurrentPage();
		};

		EditEsign.prev = function () {
			pdfHelper.prev();
			EditEsign.currentPage = pdfHelper.getCurrentPage();
		};

		EditEsign.senderSignsChange = function () {
			EditEsign.showSenderInfo = EditEsign.senderSigns;

			if (EditEsign.showSenderInfo) {
				var user = Esign.newInvolved({ user: EditEsign.esign.user });
				validateInvolvedForView(user);
				EditEsign.senderSignModel = user;
				EditEsign.senderSignModel.bankIdCountry = {
					id: EditEsign.bankId ? EditEsign.bankIDCountrySelect.data[0].id : null
				};
			}
			EditEsign.selectedPerson = null;
		};

		EditEsign.showSenderSign = function () {
			EditEsign.showSenderInfo = true;
			EditEsign.selectedPerson = null;
		};

		EditEsign.removeInvolvee = function (index) {
			EditEsign.selectedPerson = null;
			EditEsign.esign.involved.splice(index, 1);
		};

		EditEsign.userChange = function () {
			var user = Esign.newInvolved({ user: EditEsign.esign.user });
			validateInvolvedForView(user);
			EditEsign.senderSignModel = user;
		};

		EditEsign.addInvolvee = function () {
			var add;
			if (EditEsign.personToAdd.role !== undefined) {
				add = Esign.newInvolved({ user: EditEsign.personToAdd });
			} else {
				add = Esign.newInvolved({ contact: EditEsign.personToAdd });
			}

			validateInvolvedForView(add);

			add.bankIdCountry = { id: EditEsign.bankId ? EditEsign.bankIDCountrySelect.data[0].id : null };

			EditEsign.esign.involved.push(add);

			var involvedIndex = _.findIndex(EditEsign.esign.involved, function (p) {
				if (EditEsign.personToAdd.role !== undefined) {
					return p.userId === EditEsign.personToAdd.id;
				}
				return p.contactId === EditEsign.personToAdd.id;
			});
			EditEsign.personToAdd = null;
			EditEsign.selectPerson(involvedIndex);
		};

		EditEsign.selectPerson = function (index) {
			if (EditEsign.selectedPerson === index) {
				EditEsign.selectedPerson = null;
				return;
			}
			EditEsign.selectedPerson = index;
			EditEsign.showSenderInfo = false;
		};

		EditEsign.opportunityChange = function () {
			if (!EditEsign.esign.opportunity) {
				EditEsign.moveToStage = false;
			}
		};

		EditEsign.moveToStageChange = function () {
			if (EditEsign.moveToStage) {
				EditEsign.esign.orderStage = EditEsign.stages[0];
			} else {
				EditEsign.esign.orderStage = null;
			}
		};

		EditEsign.editContact = function (person) {
			if (person.contactId) {
				// eslint-disable-next-line promise/catch-or-return
				$upModal.open('editContact', { id: person.contactId }).then(function (updatedContact) {
					var contactInvolved = Esign.newInvolved({ contact: updatedContact });
					validateInvolvedForView(contactInvolved);
					angular.extend(person, contactInvolved);
				});
			}
		};

		EditEsign.browseFiles = function () {
			var browserOptions = {
				types: ['pdf'],
				disableUpload: true,
				filters: [{ a: File.attr.account.attr.id, c: 'eq', v: EditEsign.esign.client.id }]
			};

			// eslint-disable-next-line promise/catch-or-return
			openFileBrowserModal(browserOptions).then(function (file) {
				setFile(file[0]);
			});
		};

		EditEsign.openHtmlFrameNew = async function () {
			if (!EditEsign.openingHtmlFrame) {
				EditEsign.openingHtmlFrame = true;
				let esignId = EditEsign.esign.id;

				if (!esignId) {
					try {
						const res = await EditEsign.save(true);
						if (!res || !res.data || !res.data.id) {
							return;
						}
						esignId = res.data.id;
					} catch (e) {
						logError(e, 'Failed saving Esign');
					} finally {
						EditEsign.openingHtmlFrame = false;
						$safeApply($scope);
					}
				}

				EditEsign.openingHtmlFrame = false;
				const opts = {
					name: 'esignDocument',
					type: 'editEsign',
					integrationId: EditEsign.esign.integrationId,
					objectId: esignId || null,
					fullscreen: true
				};
				openIntegrationModal(opts);
				EditEsign.openingHtmlFrame = false;
			}
		};

		EditEsign.openHtmlFrame = function () {
			if (Tools.FeatureHelper.hasSoftDeployAccess('ESIGN_VIEW_NEW')) {
				EditEsign.openHtmlFrameNew();
			} else if (!EditEsign.openingHtmlFrame) {
				EditEsign.openingHtmlFrame = true;
				EditEsign.save(true)
					.then(function (res) {
						// This will be the case if the validation in save fails
						if (!res || !res.data || !res.data.id) {
							return;
						}
						EditEsign.openingHtmlFrame = false;
						var opts = {
							name: 'esignDocument',
							type: 'editEsign',
							integrationId: EditEsign.esign.integrationId,
							objectId: res.data.id || null,
							fullscreen: true
						};
						openIntegrationModal(opts);
					})
					.catch(e => {
						logError(e, 'Failed saving Esign');
					})
					.finally(() => {
						EditEsign.openingHtmlFrame = false;
					});
			}
		};

		function applySettings(settings) {
			EditEsign.smsDelivery = settings.delivery.indexOf('sms') !== -1;
			EditEsign.allowDraft = settings.allowDraft;
			// Map languages
			EditEsign.languages = _.map(settings.languages, function (lang) {
				return { title: 'lang.' + lang, value: lang };
			});

			if (EditEsign.languages.indexOf(EditEsign.esign.localeLanguage) === -1) {
				EditEsign.esign.localeLanguage = EditEsign.languages.length ? EditEsign.languages[0].value : null;
			}

			// Map bankId countries
			EditEsign.bankIDCountrySelect.data = _.map(settings.bankIdCountries, function (lang) {
				return { id: lang };
			});
			EditEsign.bankId = EditEsign.bankIDCountrySelect.data.length;
			EditEsign.multiSign = settings.multiSign;

			EditEsign.documentType = settings.documentType;

			EditEsign.fields = [];
			if (settings.fields) {
				EditEsign.fields = settings.fields;
			}

			EditEsign.userCanSign = settings.userCanSign;

			hideCenter();
		}

		function hideCenter() {
			if (EditEsign.documentType === 'html') {
				$scope.addModalClass('esign-narrow');
				EditEsign.hideCenter = true;
			}
		}

		EditEsign.init = function () {
			customerId = AppService.getCustomerId();
			metadata = AppService.getMetadata();

			EditEsign.stages = AppService.getStages('all');

			EditEsign.stages = EditEsign.stages.sort(function (a, b) {
				if (a.probability > b.probability) {
					return -1;
				} else if (a.probability < b.probability) {
					return 1;
				} else if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) {
					return -1;
				} else if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) {
					return 1;
				}

				return 0;
			});

			var users = meta.users.data;
			var contacts = meta.contacts.data;
			var masterCurrency = _.find(metadata.customerCurrencies, { masterCurrency: true });

			EditEsign.accountHasFiles = meta.accountFiles.metadata.total;
			EditEsign.esign = meta.esign.data;
			EditEsign.isEdit = EditEsign.esign.id;

			var esignIntegrations = AppService.getEsignIntegrations();
			EditEsign.esignAppPlaceholderProps.visible = false;

			// Show placeholder if we have 0 or more than 1 esign app
			if (!EditEsign.esign.id && (!esignIntegrations.length || esignIntegrations.length > 1)) {
				EditEsign.esignAppPlaceholderProps.visible = true;
				if (esignIntegrations.length) {
					EditEsign.esignAppPlaceholderProps.selectMode = true;
				}
			}

			if (!EditEsign.esign.fieldValues) {
				EditEsign.esign.fieldValues = {};
			}

			EditEsign.hasAppPlaceholder = EditEsign.esignAppPlaceholderProps.visible;

			// Get apps
			if (EditEsign.esignAppPlaceholderProps.visible) {
				EditEsign.esignAppPlaceholderProps.loading = true;
				var filters = {
					onlyPublished: true,
					init: 'esign'
				};
				StandardIntegration.find(filters)
					.then(function (res) {
						if (esignIntegrations.length) {
							// If there are active integrations we only want to show these, if not we show all available so the user can activate one
							EditEsign.esignAppPlaceholderProps.integrations = res.data.filter(integration => {
								return esignIntegrations.find(i => integration.id === i.id);
							});
						} else {
							EditEsign.esignAppPlaceholderProps.integrations = res.data;
						}
						EditEsign.esignAppPlaceholderProps.loading = false;
					})
					.catch(e => {
						logError(e, 'Failed finding standard integrations');
					});
			} else if ($modalParams.meta.settings) {
				applySettings($modalParams.meta.settings);
			}

			EditEsign.headerText =
				$translate.instant(EditEsign.isEdit ? 'default.edit' : 'default.new2') +
				' ' +
				$translate.instant('default.eSign') +
				' ' +
				$translate.instant('default.at').toLowerCase() +
				' ' +
				EditEsign.esign.client.name;

			EditEsign.buttonText = $translate.instant(EditEsign.isEdit ? 'esign.viewEsign' : 'esign.createEsign');

			// Pdf element
			pdfHelper = pdfDelegate.$getByHandle('preview-pdf');

			EditEsign.fileUploadConfig = {
				url: URL + API + customerId + '/resources/upload/internal/esign/0',
				event: 'file.uploaded',
				acceptedFiles: 'application/pdf', // only accept pdf
				sending: function () {
					EditEsign.fileStatus = EditEsign.fileStatuses.UPLOADING;
				},
				success: function (fileMetadata) {
					setFile(fileMetadata);
				},
				error: function () {
					EditEsign.fileStatus = EditEsign.fileStatuses.ERROR;
				}
				// Add on progeress here later
			};

			// Set fileStatus
			if (EditEsign.esign.file) {
				EditEsign.fileStatus = EditEsign.fileStatuses.SET;
				EditEsign.pdfPath = EditEsign.esign.file.$$previewUrl;
				loadPdf();
			} else {
				EditEsign.fileStatus = EditEsign.fileStatuses.EMPTY;
			}

			// Pull sender from involvees and set models
			if (EditEsign.esign.involved.length) {
				// Check each involveds phone nr
				angular.forEach(EditEsign.esign.involved, validateInvolvedForView);

				// check for sender in involvees
				var foundSender = findSenderInInvolvees();

				if (foundSender) {
					EditEsign.senderSigns = true;
					EditEsign.senderSignModel = foundSender;
					_.remove(EditEsign.esign.involved, foundSender);
				} else {
					EditEsign.senderSigns = false;
				}
			}

			// Involvees select
			if (meta.contacts.metadata.total > 1000) {
				EditEsign.useAjaxParticipantSelect = true;
			} else {
				EditEsign.useAjaxParticipantSelect = false;
			}

			// Stage visibility
			if (EditEsign.esign.orderStage) {
				EditEsign.moveToStage = true;
			}

			// fix old esigns
			if (!EditEsign.esign.localeLanguage) {
				EditEsign.esign.localeLanguage = 'sv';
			}

			EditEsign.participantSelect = {
				formatSelection: formatPersonSelection,
				formatResult: formatPersonResult,
				matcher: function (term, text, item) {
					return item.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				data: function () {
					return {
						results: [
							{ name: $translate.instant('default.contacts'), children: angular.copy(contacts) },
							{ name: $translate.instant('default.users'), children: angular.copy(users) }
						]
					};
				}
			};

			var participantQuery = '';

			EditEsign.participantSelectAjax = {
				formatSelection: formatPersonSelection,
				formatResult: formatPersonResult,
				matcher: function (term, text, item) {
					return item.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				data: [
					{ name: $translate.instant('default.contacts'), children: [] },
					{ name: $translate.instant('default.users'), children: users }
				],
				minimumInputLength: 1,
				allowClear: true,
				ajax: {
					data: function (term) {
						return term;
					},
					transport: function (query) {
						participantQuery = query.data ? query.data.toLowerCase() : '';

						if (query.data && EditEsign.esign.client) {
							var contactFilter = new RequestBuilder();
							contactFilter.addFilter(
								Contact.attr.name,
								contactFilter.comparisonTypes.Search,
								query.data
							);
							contactFilter.addFilter(
								Contact.attr.client.attr.id,
								contactFilter.comparisonTypes.Equals,
								EditEsign.esign.client.id
							);
							return Contact.customer(customerId).find(contactFilter.build()).then(query.success);
						}

						return query.success({
							data: []
						});
					},
					results: function (res) {
						var filteredUsers = _.filter(users, function (user) {
							return user.name && user.name.toLowerCase().indexOf(participantQuery) > -1;
						});

						return {
							results: [
								{ name: $translate.instant('default.contacts'), children: res.data },
								{ name: $translate.instant('default.users'), children: filteredUsers }
							]
						};
					}
				}
			};

			EditEsign.opportunitySelect = {
				allowClear: true,
				formatSelection: selectHelper.wrapFormatSelectionLink(getDesc, function (order) {
					$upModal.open('editOrder', { id: order.id });
				}),
				formatResult: function (opportunity, el, x, encode) {
					return (
						'<b>' +
						$filter('upsalesDate')(opportunity.date) +
						' ' +
						encode(opportunity.description) +
						'</b><br/>' +
						$filter('currencyFormat')(opportunity.value, masterCurrency.iso) +
						'<span class="pull-right">' +
						(opportunity.user ? opportunity.user.name : '') +
						'</span>'
					);
				},
				matcher: function (term, text, opportunity) {
					return opportunity.description.toUpperCase().indexOf(term.toUpperCase()) >= 0;
				},
				data: meta.opportunities.data
			};

			if ($modalParams.openInvolved) {
				EditEsign.selectPerson(0);
			}

			if (EditEsign.esign && EditEsign.esign.contact) {
				EditEsign.personToAdd = EditEsign.esign.contact;
				EditEsign.addInvolvee();
			}
			if (EditEsign.esign && EditEsign.esign.opportunityId) {
				if (meta.opportunities && meta.opportunities.data) {
					meta.opportunities.data.forEach(oppertunity => {
						if (oppertunity.id === EditEsign.esign.opportunityId) {
							EditEsign.esign.opportunity = oppertunity;
						}
					});
				}
			}

			setTimeout(function () {
				hideCenter();
			}, 0);
		};

		EditEsign.init();
	}
]);
