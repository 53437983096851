import logError from 'App/babel/helpers/logError';
import { DISQUALIFIED, statusDisqualifiable } from 'Components/Helpers/journeyStep';
import { activityOutcomeTracker } from 'App/babel/helpers/Tracker';
import {
	OUTCOME_TYPES,
	ANSWER_ACTIONS_GREAT,
	FOUND_LEAD_ACTIONS,
	ANSWER_ACTIONS
} from 'App/babel/enum/activityOutcome';
import openModal, { shouldOpenModal } from 'App/services/Modal';
import { openNewMailWithContact } from 'App/helpers/mailHelpers';
import documentResource from 'Resources/document';
import { getMinimizedFlashActivities } from 'App/components/SidebarSavedModalButton/minimizedFlashActvititiesHelpers';

angular.module('upDirectives').directive('upFlashRoot', function () {
	return {
		restrict: 'A',
		template: '<div></div>',
		replace: true,
		controller: [
			'$scope',
			'AppService',
			'Activity',
			'RequestBuilder',
			'$translate',
			'$upModal',
			'$stateParams',
			'$state',
			'Ads',
			'localStorageService',
			'$q',
			'NotificationService',
			'$rootScope',
			'avatarService',
			'Report',
			'Appointment',
			'LatestAccountsService',
			'Account',
			'Contact',
			'FilterHelper',
			'Role',
			'ActivityType',
			'$datepicker',
			'$filter',
			'Campaign',
			'Opportunity',
			'FeatureHelper',
			'ScriptService',
			'Links',
			'VoiceService',
			'URL',
			'API',
			'Order',
			function (
				$scope,
				AppService,
				Activity,
				RequestBuilder,
				$translate,
				$upModal,
				$stateParams,
				$state,
				Ads,
				localStorageService,
				$q,
				NotificationService,
				$rootScope,
				avatarService,
				Report,
				Appointment,
				LatestAccountsService,
				Account,
				Contact,
				FilterHelper,
				Role,
				ActivityType,
				$datepicker,
				$filter,
				Campaign,
				Opportunity,
				FeatureHelper,
				ScriptService,
				Links,
				VoiceService,
				URL,
				API,
				Order
			) {
				var FlashCtrl = this;
				var customerId;
				var self;
				var activityIds = [];
				var activityIndex = { previous: -1, current: -1, next: -1 };
				var filter;
				var gotError = false;
				var theWindow = null;

				var nextActivity, previousActivity;
				var getLinks = function (id) {
					return Links.customer(customerId).get('activity', id);
				};

				var fixWebpage = function (webpage) {
					if (webpage && webpage.indexOf('http') !== 0) {
						webpage = 'http://' + webpage;
					}

					return webpage;
				};

				const outcomeContactActions = [ANSWER_ACTIONS.TALK_TO_SOMEONE, FOUND_LEAD_ACTIONS.ADDED_CONTACT];

				const tracker = outcome => {
					let datePreset = 'no_postpone';

					if (!outcome) return null;

					if (outcome.type === 'postpone' || outcome.outcome === 'postponed') {
						datePreset = outcome.date;
					}
					activityOutcomeTracker.track(activityOutcomeTracker.events.CREATE_EVENT, {
						location: 'flash',
						type: outcome.type,
						outcome: outcome.outcome,
						date_preset: datePreset
					});
				};

				var getActivity = function (index) {
					if ((!index && index !== 0) || !activityIds[index]) {
						return Promise.resolve();
					}

					var idFilter = new RequestBuilder();
					idFilter.addFilter(Activity.attr.id, idFilter.comparisonTypes.Equals, activityIds[index]);

					return Activity.customer(customerId)
						.get(activityIds[index])
						.then(function (res) {
							var activity = res.data;

							if (!activity) {
								activityIds.splice(index, 1);
								return [];
							}

							FlashCtrl.rootData.contactRemoved = false;
							FlashCtrl.rootData.submitted = false;
							FlashCtrl.rootData.formInvalid = false;
							FlashCtrl.rootData.dirty = false;

							// I dont know why we return null in this case but i dont dare change the cureent behaviour
							if (!activity.client) {
								return null;
							}

							var accountFilter = new RequestBuilder();
							accountFilter.addFilter(
								Account.attr.id,
								accountFilter.comparisonTypes.Equals,
								activity.client.id
							);

							var promises = {
								links: getLinks(activity.id),
								account: Account.customer(customerId).find(accountFilter.build())
							};

							if (activity.contacts && activity.contacts.length) {
								var contactFilter = new RequestBuilder();
								contactFilter.addFilter(
									Account.attr.id,
									accountFilter.comparisonTypes.Equals,
									activity.contacts[0].id
								);
								promises.contact = Contact.customer(customerId).find(contactFilter.build());
							}

							return $q.all(promises).then(function (res) {
								activity.links = res.links.data;

								if (res.account.data.length) {
									activity.client = res.account.data[0];
									activity.client.webpage = fixWebpage(activity.client.webpage);
								}
								if (res.contact && res.contact.data.length) {
									activity.contacts = [res.contact.data[0]];
								} else if (activity.contacts && activity.contacts.length) {
									// if we had a contact on the activity
									FlashCtrl.rootData.contactRemoved = activity.contacts[0];
								}
								FlashCtrl.render();
								return activity;
							});
						})
						.catch(err => {
							if (err?.status === 404 && err?.data?.error?.key === 'NoSuchEntity') {
								NotificationService.addNotification({
									title: 'default.error',
									body: 'errorNotFound.activity',
									style: 'error',
									icon: 'times'
								});
							}
						});
				};

				var getActivityList = function (filter) {
					return Activity.customer(customerId)
						.find(filter)
						.then(function (res) {
							activityIds = res.data
								.filter(activity => activity.activityType?.name !== 'Todo')
								.map(activity => activity.id);

							FlashCtrl.rootData.nrOfActivities = activityIds.length;

							if (FlashCtrl.rootData.nrOfActivities === 0) {
								FlashCtrl.rootData.isFinished = true;
							} else {
								FlashCtrl.rootData.isFinished = false;
							}
						});
				};

				var getOffsetActivities = function () {
					var promises = {};
					activityIndex.next = activityIndex.current + 1 < activityIds.length ? activityIndex.current + 1 : 0;
					activityIndex.previous = activityIndex.current - 1 >= 0 ? activityIndex.current - 1 : -1;

					promises.next = activityHelper(activityIndex.next, true);
					if (activityIndex.current - 1 >= 0) {
						promises.previous = activityHelper(activityIndex.previous, false, true);
					}

					$q.all(promises)
						.then(function (res) {
							var hasNext;
							var hasPrev;

							if (res.next && res.previous) {
								hasNext = true;
								hasPrev = true;
								nextActivity = res.next;
								previousActivity = res.previous;
							} else if (res.next && !res.previous) {
								hasNext = true;
								hasPrev = false;
								nextActivity = res.next;
								previousActivity = null;
								activityIndex.previous = -1;
							} else if (!res.next && res.previous) {
								hasNext = false;
								hasPrev = true;

								activityIndex.next = 0;
								nextActivity = null;
								previousActivity = res.previous;
							} else {
								hasNext = false;
								hasPrev = false;

								activityIndex.next = 0;
								nextActivity = null;

								previousActivity = null;
								activityIndex.previous = -1;
							}

							FlashCtrl.rootData.hasNext = hasNext;
							FlashCtrl.rootData.hasPrev = hasPrev;
							FlashCtrl.render();
						})
						.catch(e => {
							logError(e, 'Failed to get offset activities');
						});
				};

				function activityHelper(index, isNext, isPrev) {
					var defer = $q.defer();
					if (!activityIds[index]) {
						return Promise.resolve(null);
					}

					getActivity(index)
						.then(function (res) {
							var index;

							if (!res) {
								getActivityList(filter)
									.then(function () {
										if (isNext) {
											// IF next take current id index and check if you can take next --> else return to 0
											index = activityIds.indexOf(activityIndex.current);
											index = index !== -1 && index + 1 < activityIds.length ? index + 1 : 0;

											getActivity(index)
												.then(function (res2) {
													if (res) {
														FlashCtrl.rootData.hasNext = true;
														defer.resolve(res2);
														activityIndex.next = index + 1;
													}
												})
												.catch(defer.reject);
										} else if (isPrev) {
											index = activityIds.indexOf(activityIndex.current);
											index = index !== -1 && index - 1 >= 0 ? index - 1 : -1;
											if (index === -1) {
												defer.resolve(null);
											} else {
												getActivity(index)
													.then(function (res2) {
														if (res) {
															FlashCtrl.rootData.hasPrev = true;
															defer.resolve(res2);
															activityIndex.previous = index + 1;
														}
													})
													.catch(defer.reject);
											}
										} else {
											index = activityIds.indexOf(activityIndex.current);
											index = index !== -1 ? index : 0;

											getActivity(index)
												.then(function (res2) {
													// Här kan man aldrig komma in
													if (res2) {
														defer.resolve(res2);
														activityIndex.current = index;
													}
												})
												.catch(defer.reject);
										}
									})
									.catch(defer.reject);
							} else {
								defer.resolve(res);
							}
						})
						.catch(defer.reject);

					return defer.promise;
				}

				var disableScroll = function () {
					var body = angular.element(document.body);
					var oldWidth = body.innerWidth();
					body.css('overflow', 'hidden');
					body.width(oldWidth);
				};

				var enableScroll = function () {
					var body = angular.element(document.body);
					body.css('overflow', 'auto');
					body.width('auto');
				};

				var open = function (data) {
					gotError = false;
					var duration = 0;
					if (data && data.fromMinimized) {
						duration = FlashCtrl.tools.cache.get('flashTimeDuration') || 0;
						if (!data.id) {
							const minimizedActivity = getMinimizedFlashActivities(
								AppService.getCustomerId(),
								AppService.getSelf().id
							);
							if (minimizedActivity?.data) {
								data = { ...data, ...minimizedActivity.data };
							}
						}
					}

					var ts = +moment.utc();
					FlashCtrl.tools.cache.update('flashTime', ts);

					FlashCtrl.rootData.time = ts - duration; // subtract the old duration

					disableScroll();

					// Show Flash
					FlashCtrl.rootData.open = true;

					FlashCtrl.rootData.hasPhoneCall = VoiceService.callInProgress();

					if (!data.fromMinimized || (data.fromMinimized && data.filter && !FlashCtrl.rootData.dirty)) {
						if (data.filter) {
							filter = data.filter;
						}
						// Get all activityIds based on filter
						filter.f = ['id', 'activityType'];
						filter.limit = 2000;
						var currentId = data.currentId;

						// GOT ALL ACTIVITIES
						getActivityList(filter)
							.then(function () {
								// LOAD CURRENT ACTIVITY FIRST --> THEN NEXT AND PREV
								if (activityIds.length) {
									activityIndex.current = activityIds.indexOf(currentId);

									if (activityIndex.current === -1) {
										activityIndex.current = 0;
										activityIndex.next = 1;
										activityIndex.previous = -1;
									}

									activityHelper(activityIndex.current)
										.then(function (res) {
											if (res) {
												FlashCtrl.rootData.activity = res;
												FlashCtrl.rootData.formInvalid = !formValid();
												FlashCtrl.render(function () {
													ScriptService.flash.init(res);
												});
												// LOAD NEXT AND PREV...
												activityIndex.previous =
													activityIndex.current - 1 >= 0 ? activityIndex.current - 1 : -1;
												activityIndex.next =
													activityIndex.current + 1 < activityIds.length
														? activityIndex.current + 1
														: 0;
												getOffsetActivities();

												// Things may have changed while minimized
												if (FlashCtrl.tools.cache.isInitialized()) {
													getTopListData();
												}
											}
										})
										.catch(e => {
											logError(e, 'Failed to run activity helper');
											gotError = true;
										});
								}
							})
							.catch(e => {
								logError(e, 'Failed to get activity list');
								gotError = true;
							});
					} else {
						ScriptService.flash.init(FlashCtrl.rootData.activity);
						FlashCtrl.render();

						// Things may have changed while minimized
						if (FlashCtrl.tools.cache.isInitialized()) {
							getTopListData();
						}
					}
				};

				var _close = function () {
					FlashCtrl.rootData.open = false;
					FlashCtrl.rootData.timeDuration = 0;

					// This is unnessescary here (i think :)
					FlashCtrl.tools.cache.reset();

					$scope.$root.$broadcast('flashClosed');

					enableScroll();

					FlashCtrl.render();
				};

				var onClose = function () {
					// Ask if dirty
					if (FlashCtrl.rootData.dirty && !FlashCtrl.rootData.isFinished) {
						if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
							openModal('UnsavedChangesAlert', {
								body: 'flash.closeConfirm',
								confirmButtonText: 'flash.saveAndClose',
								cancelButtonText: 'default.close',
								onClose: confirmed => {
									if (confirmed === undefined) {
										return;
									}
									if (confirmed) {
										onSave(FlashCtrl.rootData.activity, false, true)
											.then(_close)
											.catch(e => {
												if (e !== 'formInvalid') {
													logError(e, 'Failed to save');
												}
											});
									} else {
										_close();
									}
								}
							});
							return;
						}

						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('warningConfirm', {
								title: 'default.abort',
								body: 'flash.closeConfirm',
								no: 'flash.returnToFlash',
								resolveTrue: 'flash.saveAndClose',
								resolveFalse: 'default.close',
								icon: 'fa-warning'
							})
							.then(function (save) {
								if (save) {
									onSave(FlashCtrl.rootData.activity, false, true)
										.then(_close)
										.catch(e => {
											if (e !== 'formInvalid') {
												logError(e, 'Failed to save');
											}
										});
								} else {
									_close();
								}
							});
					} else {
						_close();
					}
				};

				var onHide = function () {
					FlashCtrl.rootData.open = false;

					var duration = +moment.utc() - FlashCtrl.rootData.time;
					FlashCtrl.tools.cache.update('flashTimeDuration', duration);
					FlashCtrl.tools.cache.update('minimized', true);

					$scope.$root.$broadcast('flashHidden');

					enableScroll();

					FlashCtrl.render();
				};

				var onActivityChange = function (activity) {
					FlashCtrl.rootData.submitted = false;
					FlashCtrl.rootData.dirty = true;

					// If contact changed
					var oldContactId =
						FlashCtrl.rootData.activity.contacts && FlashCtrl.rootData.activity.contacts.length
							? FlashCtrl.rootData.activity.contacts[0].id
							: null;
					var newContactId = activity.contacts && activity.contacts.length ? activity.contacts[0].id : null;
					if (newContactId && oldContactId !== newContactId) {
						Contact.customer(customerId)
							.get(newContactId)
							.then(function (res) {
								// Render with new contact
								FlashCtrl.rootData.activity.contacts = [res.data];
								FlashCtrl.render();
							})
							.catch(e => {
								logError(e, 'Failed to get contact');
							});
					}

					if (oldContactId !== newContactId) {
						saveActivityWithNewContact();
					}

					// Render react with new activity
					FlashCtrl.rootData.activity = activity;
					FlashCtrl.rootData.formInvalid = !formValid();
					FlashCtrl.render();
				};

				var onCloseActions = function (activity) {
					var defer = $q.defer();

					defer.resolve({ data: activity });

					return defer.promise;
				};

				var onSaveSimple = function (activity) {
					if (!formValid()) {
						FlashCtrl.rootData.submitted = true;
						FlashCtrl.rootData.formInvalid = true;
						FlashCtrl.render();

						return $q.reject('formInvalid');
					}

					if (activity.contacts && !activity.contacts.length) {
						activity.contacts = null;
					}

					if (!activity.time) {
						activity.date = moment(activity.date).format('YYYY-MM-DD');
					}

					FlashCtrl.rootData.saving = true;
					FlashCtrl.render();

					return ScriptService.flash.save(activity).then(function () {
						return Activity.customer(customerId)
							.save(activity, { skipNotification: true })
							.then(function () {
								FlashCtrl.rootData.saving = false;
								FlashCtrl.render();
							})
							.catch(function (err) {
								if (
									['NoEditRights', 'NoSuchEntity', 'ClientAccessDenied'].indexOf(
										_.get(err, 'data.error.key')
									) > -1
								) {
									/*
										I dont fully understand the flow here so i set this to false so that the state is the same
										as in onSave when it fails.
									*/
									FlashCtrl.rootData.hasNext = false;
									FlashCtrl.rootData.hasPrev = false;

									if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
										openModal('Alert', {
											title: 'saveError.activity',
											body: err?.data?.error?.translated || 'flash.saveError',
											confirmButtonText: 'flash.goToNextActivity',
											icon: 'warning',
											onClose: confirmed => {
												if (confirmed) {
													next(true);
												}
											}
										});
										return $q.reject(err);
									}

									// eslint-disable-next-line promise/catch-or-return
									$upModal
										.open('errorConfirm', {
											title: 'saveError.activity',
											body: _.get(err, 'data.error.translated') || 'flash.saveError',
											resolveTrue: 'flash.goToNextActivity',
											icon: 'fa-warning'
										})
										.then(function () {
											gotError = true;
											// splice this index...
											next(true);
										});

									return $q.reject(err);
								}
							});
					});
				};

				function setContact(contact) {
					FlashCtrl.rootData.activity.contacts = contact ? [contact] : null;

					saveActivityWithNewContact();
				}

				function saveActivityWithNewContact() {
					const hasActivityOutcomeAccess = FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITY_OUTCOMES);

					if (hasActivityOutcomeAccess) {
						const outcome = FlashCtrl.rootData.activity.outcome;
						const hasContact =
							FlashCtrl.rootData.activity.contacts && FlashCtrl.rootData.activity.contacts.length;

						if (outcome && (!hasContact || !outcomeContactActions.includes(outcome.outcome))) {
							FlashCtrl.rootData.activity.outcome = null;
						}

						// eslint-disable-next-line promise/catch-or-return
						return Activity.customer(customerId)
							.save({
								id: FlashCtrl.rootData.activity.id,
								contacts: FlashCtrl.rootData.activity.contacts,
								outcome: FlashCtrl.rootData.activity.outcome
							})
							.then(() => {
								FlashCtrl.rootData.activity.outcome = null;
							})
							.catch(err => logError(err, 'Failed to updated activity with new contact'));
					}
				}

				function onSave(activity, closeActivity, fromArrow, followup) {
					var hasNext = FlashCtrl.rootData.hasNext;
					var hasPrev = FlashCtrl.rootData.hasPrev;
					FlashCtrl.rootData.hasNext = false;
					FlashCtrl.rootData.hasPrev = false;
					const hasActivityOutcomeAccess = FeatureHelper.isAvailable(FeatureHelper.Feature.ACTIVITY_OUTCOMES);

					if (!formValid()) {
						FlashCtrl.rootData.submitted = true;
						FlashCtrl.rootData.formInvalid = true;
						if (activityIndex.current !== activityIndex.next) {
							FlashCtrl.rootData.hasNext = true;
							FlashCtrl.rootData.hasPrev = true;
						}

						FlashCtrl.render();

						return $q.reject('formInvalid');
					}

					if (hasActivityOutcomeAccess && activity.outcome) {
						const shouldClose = _.get(activity.outcome, 'closeActivity', false);
						closeActivity = closeActivity || shouldClose;
						setDisqualifyToClient(activity);
					}

					if (closeActivity) {
						FlashCtrl.rootData.saving = true;
						FlashCtrl.rootData.closing = true;
						FlashCtrl.render();
						activity.closeDate = new Date();
					}

					if (activity.contacts && !activity.contacts.length) {
						activity.contacts = null;
					}

					if (!activity.time) {
						activity.date = moment(activity.date).format('YYYY-MM-DD');
					}

					return ScriptService.flash.save(activity).then(function () {
						return Activity.customer(customerId)
							.save(activity, { skipNotification: true })
							.then(function (res) {
								// check if navigate back or shall navigate forward?
								if (closeActivity) {
									activityIds.splice(activityIndex.current, 1);
									FlashCtrl.rootData.nrOfActivities -= 1;
								}

								if (FlashCtrl.rootData.nrOfActivities < 1) {
									FlashCtrl.rootData.nrOfActivities = 0;
									FlashCtrl.rootData.isFinished = true;
								}

								FlashCtrl.rootData.saving = false;
								FlashCtrl.rootData.closing = false;

								FlashCtrl.render();
								if (!fromArrow) {
									next(true, closeActivity);
								}

								if (closeActivity && !followup) {
									return onCloseActions(res.data);
								} else {
									return res;
								}
							})
							.catch(function (err) {
								FlashCtrl.rootData.saving = false;
								FlashCtrl.rootData.closing = false;

								/* Reset closeDate as save failed */
								if (closeActivity) {
									FlashCtrl.rootData.activity.closeDate = null;
								}

								if (
									['NoEditRights', 'NoSuchEntity', 'ClientAccessDenied'].indexOf(
										_.get(err, 'data.error.key')
									) > -1
								) {
									$upModal
										.open('errorConfirm', {
											title: 'saveError.activity',
											body: _.get(err, 'data.error.translated') || 'flash.saveError',
											resolveTrue: 'flash.goToNextActivity',
											icon: 'fa-warning'
										})
										.then(function () {
											gotError = true;
											// splice this index...
											next(true);
										})
										.catch(function () {
											FlashCtrl.rootData.hasNext = hasNext;
											FlashCtrl.rootData.hasPrev = hasPrev;
											FlashCtrl.render();
										});
									return;
								}

								return $q.reject(err);
							});
					});
				}

				function next(fromSave, isClosed) {
					if (!activityIds.length) {
						FlashCtrl.rootData.isFinished = true;
						return;
					} else if (!formValid()) {
						FlashCtrl.rootData.submitted = true;
						FlashCtrl.rootData.formInvalid = true;
						FlashCtrl.render();
						return;
					}

					var savePromise;

					if (fromSave || !FlashCtrl.rootData.activity.userEditable) {
						savePromise = $q.when(null);
					} else {
						savePromise = onSave(FlashCtrl.rootData.activity, false, true);
					}
					if (FlashCtrl.rootData.activity.outcome) {
						tracker(FlashCtrl.rootData.activity.outcome);
					}

					savePromise
						.then(function (res) {
							if (!isClosed) {
								activityIndex.current = activityIndex.next;
							}
							if (nextActivity) {
								if (nextActivity.id === _.get(res, 'data.id')) {
									nextActivity = res.data;
								}
								FlashCtrl.rootData.activity = angular.copy(nextActivity);
								FlashCtrl.rootData.formInvalid = !formValid();
								ScriptService.flash.init(FlashCtrl.rootData.activity);
								if (FlashCtrl.rootData.activity.client.webpage) {
									goToUrl(FlashCtrl.rootData.activity.client.webpage);
								}
							}
							FlashCtrl.rootData.transitioning = 'right';
							FlashCtrl.render();
							var data = FlashCtrl.tools.cache.get('data');
							if (data) {
								data.currentId = FlashCtrl.rootData.activity.id;
								FlashCtrl.tools.cache.update('data', data);
							}

							return getActivityList(filter);
						})
						.then(function () {
							if (activityIds.length) {
								var index = FlashCtrl.rootData.activity
									? activityIds.indexOf(FlashCtrl.rootData.activity.id)
									: -1;

								if (index === -1 && !fromSave) {
									if (!gotError) {
										// eslint-disable-next-line promise/catch-or-return
										$upModal
											.open('errorConfirm', {
												title: 'saveError.activity',
												body: 'flash.saveError',
												resolveTrue: 'flash.goToNextActivity',
												icon: 'fa-warning'
											})
											.then(function () {
												// splice this index... do not set next index.. load this index because it shall be spliced anyway ;
												gotError = true;
												next(true, true);
											});
									} else {
										resetNavigation();
									}
								} else {
									getOffsetActivities();
								}
							}
						})
						.catch(e => {
							gotError = true;
							if (e !== 'formInvalid') {
								logError(e, 'Failed to save');
							}
						});
				}

				var prev = function (fromSave) {
					if (!formValid()) {
						FlashCtrl.rootData.submitted = true;
						FlashCtrl.rootData.formInvalid = true;
						FlashCtrl.render();
						return;
					}

					//3. On previous --> switch to previous and set current to previous and search for new previous
					//set current to next
					if (!activityIds.length) {
						FlashCtrl.rootData.isFinished = true;
						return;
					}

					if (activityIndex.current - 1 >= 0) {
						var savePromise;
						if (fromSave || !FlashCtrl.rootData.activity.userEditable) {
							savePromise = $q.when(null);
						} else {
							savePromise = onSave(FlashCtrl.rootData.activity, false, true);
						}

						savePromise
							.then(function () {
								// UPDATE LIST OF ALL ACTIVITIES
								return getActivityList(filter);
							})
							.then(function () {
								if (activityIds.length) {
									var index = FlashCtrl.rootData.activity
										? activityIds.indexOf(FlashCtrl.rootData.activity.id)
										: -1;

									if (index === -1 && !fromSave) {
										activityIds.splice(activityIndex.current, 1);
										if (!gotError) {
											// eslint-disable-next-line promise/catch-or-return
											$upModal
												.open('errorConfirm', {
													title: 'saveError.activity',
													body: 'flash.saveError',
													resolveTrue: 'flash.goToNextActivity',
													icon: 'fa-warning'
												})
												.then(function () {
													gotError = true;
													if (FlashCtrl.rootData.hasPrev) {
														prev(true);
													} else if (FlashCtrl.rootData.hasNext) {
														next(true, true);
													}
												});
										} else {
											resetNavigation();
										}
									} else {
										getOffsetActivities();
									}
								}
							})
							.catch(e => {
								if (e !== 'formInvalid') {
									logError(e, 'Failed to save');
								}
								gotError = true;
							});

						if (!fromSave) {
							activityIndex.current = activityIndex.previous;
						}
						if (previousActivity) {
							FlashCtrl.rootData.activity = angular.copy(previousActivity);
							FlashCtrl.rootData.formInvalid = !formValid();
							FlashCtrl.rootData.transitioning = 'left';
							ScriptService.flash.init(FlashCtrl.rootData.activity);
							if (FlashCtrl.rootData.activity.client.webpage) {
								goToUrl(FlashCtrl.rootData.activity.client.webpage);
							}
						} else {
							FlashCtrl.rootData.hasPrev = false;
						}

						FlashCtrl.render();
						var data = FlashCtrl.tools.cache.get('data');

						if (data && FlashCtrl.rootData.activity) {
							data.currentId = FlashCtrl.rootData.activity.id;
							FlashCtrl.tools.cache.update('data', data);
						}
					}
				};

				function resetNavigation() {
					getActivity(0)
						.then(function (res) {
							FlashCtrl.rootData.activity = res;
							FlashCtrl.rootData.formInvalid = !formValid();
							previousActivity = null;
							activityIndex.previous = -1;
							gotError = false;
							FlashCtrl.render();
						})
						.catch(e => {
							logError(e, 'Failed to get activity');
							gotError = false;
						});

					activityIndex.next = FlashCtrl.rootData.nrOfActivities > 1 ? 1 : -1;
					if (activityIndex.next !== -1) {
						activityHelper(activityIndex.next, true)
							.then(function (res) {
								if (res) {
									nextActivity = res;
								} else {
									FlashCtrl.rootData.hasNext = false;
									activityIndex.next = -1;
									nextActivity = null;
								}
							})
							.catch(e => {
								logError(e, 'ActivityHelper failed');
								gotError = false;
							});
					}
				}

				var mapUsers = function (data) {
					data.forEach(function (bucket) {
						var user = _.find(FlashCtrl.rootData.users, { id: bucket.key });
						bucket.name = user && user.name ? user.name : '';
						bucket.email = user && user.email ? user.email : '';
					});
				};

				var onCreateContact = function () {
					// eslint-disable-next-line promise/catch-or-return
					$upModal
						.open('editContact', {
							customerId: customerId,
							account: FlashCtrl.rootData.activity.client
						})
						.then(function (contact) {
							if (contact.active) {
								saveActivityWithNewContact();
								FlashCtrl.rootData.activity.contacts = [contact];
								FlashCtrl.render();
							}
						});
				};

				var onEmailContact = function () {
					var contact = FlashCtrl.rootData.activity.contacts[0];
					if (Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')) {
						openNewMailWithContact(contact);
					} else {
						$upModal.open('sendEmail', { customerId: customerId, contactId: contact.id, contact: contact });
					}
				};

				var onContactSave = function (contact, isEdit) {
					FlashCtrl.rootData.contactSaving = true;
					FlashCtrl.render();

					contact.client = { id: FlashCtrl.rootData.activity.client.id };

					if (isEdit) {
						contact.id = FlashCtrl.rootData.activity.contacts[0].id;
					} else {
						contact.active = true;
					}

					return Contact.customer(customerId)
						.save(contact)
						.then(function (res) {
							FlashCtrl.rootData.activity.contacts = [res.data];
							FlashCtrl.rootData.contactSaving = false;
							FlashCtrl.render();
						})
						.catch(function () {
							FlashCtrl.rootData.contactSaving = false;
							FlashCtrl.render();
						});
				};

				// Create document
				var onCreateDocument = function (template) {
					var activity = FlashCtrl.rootData.activity;
					var params = {
						entityId: FlashCtrl.rootData.activity.id,
						templateId: template.id,
						type: 'activity',
						name: template.name
					};

					if (
						activity.contacts &&
						activity.contacts.length &&
						activity.contacts &&
						activity.contacts[0].email
					) {
						params.contactId = activity.contacts[0].id;
					}

					if (!FlashCtrl.rootData.dirty) {
						if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
							return openModal('PreviewPdfModal', {
								isUploadedTemplate: true,
								documentResource: {
									resource: documentResource,
									entityId: params.entityId,
									templateId: params.templateId,
									type: params.type,
									documentName: params.name,
									accountId: params.accountId,
									contact: params.contact,
									contactId: params.contactId
								}
							});
						} else {
							return $upModal.open('pdfPreview', params);
						}
					}

					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
						openModal('Alert', {
							title: 'default.saveChanges',
							body: 'confirm.saveAndCreateDocument',
							confirmButtonText: 'default.save',
							headerIcon: 'warning',
							onClose: confirmed => {
								if (confirmed) {
									onSave(activity, false, true)
										.then(function () {
											if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
												openModal('PreviewPdfModal', {
													isUploadedTemplate: true,
													documentResource: {
														resource: documentResource,
														entityId: params.entityId,
														templateId: params.templateId,
														type: params.type,
														documentName: params.name,
														accountId: params.accountId,
														contact: params.contact,
														contactId: params.contactId
													}
												});
											} else {
												$upModal.open('pdfPreview', params);
											}
										})
										.catch(e => {
											if (e !== 'formInvalid') {
												logError(e, 'Failed to save');
											}
										});
								}
							}
						});
						return;
					}

					// eslint-disable-next-line promise/catch-or-return
					$upModal
						.open('warningConfirm', {
							title: 'default.saveChanges',
							body: 'confirm.saveAndCreateDocument',
							resolveFalse: 'default.save',
							resolveFalseBtnClass: 'btn-submit',
							no: 'default.abort',
							icon: 'fa-warning'
						})
						.then(function () {
							onSave(activity, false, true)
								.then(function () {
									if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
										openModal('PreviewPdfModal', {
											isUploadedTemplate: true,
											documentResource: {
												resource: documentResource,
												entityId: params.entityId,
												templateId: params.templateId,
												type: params.type,
												documentName: params.name,
												accountId: params.accountId,
												contact: params.contact,
												contactId: params.contactId
											}
										});
									} else {
										$upModal.open('pdfPreview', params);
									}
								})
								.catch(e => {
									if (e !== 'formInvalid') {
										logError(e, 'Failed to save');
									}
								});
						});
				};

				var onCreateFollowup = function (isAppointment) {
					var promise;
					var activity = FlashCtrl.rootData.activity;
					// Close (if not allready closed)
					if (!activity.closeDate) {
						promise = onSave(activity, true, false, true);
					} else {
						promise = onSave(activity, false, false, true);
					}

					// Build init-data and open modal
					promise
						.then(function (res) {
							var item;
							if (isAppointment) {
								item = Appointment.new().data;
								item.activityType = null;
							} else {
								item = Activity.new().data;
								item.activityType = activity.activityType;
								item.custom = res.data.custom;
								item.description = activity.description;
							}

							item.notes = activity.notes;
							item.client = activity.client;
							item.project = activity.project;
							item.users = _.pick(self, ['id', 'name', 'role', 'email']);

							if (isAppointment) {
								item.contacts = activity.contacts ? activity.contacts : null;

								$upModal.open('editAppointment', {
									appointment: item
								});
							} else {
								item.contacts =
									activity.contacts && activity.contacts.length ? activity.contacts[0] : null;
								$upModal.open('editActivity', {
									activity: item
								});
							}
						})
						.catch(e => {
							if (e !== 'formInvalid') {
								logError(e, 'Failed to save');
							}
						});
				};

				var bookAppointment = function () {
					const activity = FlashCtrl.rootData.activity;
					const newAppointment = Appointment.new().data;

					newAppointment.notes = activity.notes;
					newAppointment.client = activity.client;
					newAppointment.project = activity.project;
					newAppointment.users = _.pick(self, ['id', 'name', 'role', 'email']);
					newAppointment.contacts = activity.contacts ? activity.contacts : null;
					newAppointment.activityType = null;

					// eslint-disable-next-line promise/catch-or-return
					$upModal
						.open('editAppointment', {
							appointment: newAppointment
						})
						.then((appointment = {}) => {
							_.set(activity, 'outcome.appointmentId', appointment.id);
							setOutcome({
								type: OUTCOME_TYPES.ANSWER,
								outcome: ANSWER_ACTIONS_GREAT.BOOK_APPOINTMENT
							});
							onSave(activity, true, false, true);
							tracker(FlashCtrl.rootData.activity.outcome);
						});
				};

				function setOutcome(outcome) {
					const dateTimeStr = moment.utc().format();
					const clientJourney = _.get(FlashCtrl.rootData.activity, 'client.journeyStep');

					FlashCtrl.rootData.activity.outcome = outcome
						? { ...outcome, date: dateTimeStr, clientJourney }
						: null;
				}

				var nullUndefined = function (val) {
					return val === null || val === undefined;
				};

				function formValid() {
					var valid = true;
					var activity = FlashCtrl.rootData.activity;
					var rf = FlashCtrl.tools.metadata.requiredFields.Activity;

					if (rf.Description && (nullUndefined(activity.description) || !activity.description.length)) {
						valid = false;
					}

					if (rf.Notes && (nullUndefined(activity.notes) || !activity.notes.length)) {
						valid = false;
					}

					if (rf.Client && nullUndefined(activity.client)) {
						valid = false;
					}

					if (rf.Contact && (nullUndefined(activity.contacts) || !activity.contacts.length)) {
						valid = false;
					}

					if (rf.Project && nullUndefined(activity.project)) {
						valid = false;
					}

					if (rf.Type && nullUndefined(activity.activityType)) {
						valid = false;
					}

					if (nullUndefined(activity.users) || !activity.users.length) {
						valid = false;
					}

					if (rf.Date && (nullUndefined(activity.date) || !moment(activity.date).isValid())) {
						valid = false;
					}

					if (rf.Time && (nullUndefined(activity.time) || !moment(activity.time).isValid())) {
						valid = false;
					}

					activity.custom.forEach(function (cf) {
						if (
							cf.editable &&
							cf.obligatoryField &&
							cf.$hasAccess &&
							(nullUndefined(cf.value) || cf.value === '' || cf.value === false)
						) {
							valid = false;
						} else if (cf.value && cf.value.length && cf.datatype === 'Email') {
							// eslint-disable-next-line
							var re =
								/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
							var emailValid = re.test(cf.value);
							if (!emailValid) {
								valid = false;
							}
						} else if (cf.value && cf.datatype === 'Date') {
							if (!moment(cf.value).isValid()) {
								valid = false;
							}
						}
					});

					return valid;
				}

				var getDocumentTemplates = function () {
					FlashCtrl.rootData.documentTemplates = AppService.getDocumentTemplates('activity');
				};

				// TODO(Use attributes from Resources)
				function getTopListData() {
					var startOfDay = moment().utc().startOf('day').format();
					var currentUserId = AppService.getSelf().id;
					var userIds = _.pluck(FlashCtrl.rootData.users, 'id');

					/** MY APPOINTMENT **/
					var myAppointmentRb = new RequestBuilder();
					myAppointmentRb.addFilter(
						{ field: 'regDate' },
						myAppointmentRb.comparisonTypes.GreaterThanEquals,
						startOfDay
					);
					myAppointmentRb.addFilter(
						{ field: 'regBy.id' },
						myAppointmentRb.comparisonTypes.Equals,
						currentUserId
					);
					myAppointmentRb.addFilter({ field: 'client.id' }, myAppointmentRb.comparisonTypes.NotEquals, null);
					myAppointmentRb.limit = 0;

					/** MY ACTIVITY **/
					var myActivityRb = new RequestBuilder();
					myActivityRb.addFilter(
						{ field: 'closeDate' },
						myActivityRb.comparisonTypes.GreaterThanEquals,
						startOfDay
					);
					myActivityRb.addFilter({ field: 'user.id' }, myActivityRb.comparisonTypes.Equals, currentUserId);
					myActivityRb.limit = 0;

					/** APPOINTMENT **/
					var appointmentRoles = FlashCtrl.tools.cache.get('appointmentListWidgetRoles')
						? FlashCtrl.tools.cache.get('appointmentListWidgetRoles')
						: [];
					var appointmentTypes = FlashCtrl.tools.cache.get('appointmentListWidgetTypes')
						? FlashCtrl.tools.cache.get('appointmentListWidgetTypes')
						: [];

					var AppointmentRb = new RequestBuilder();
					AppointmentRb.addFilter(
						{ field: 'regDate' },
						AppointmentRb.comparisonTypes.GreaterThanEquals,
						startOfDay
					);
					AppointmentRb.addFilter({ field: 'regBy.id' }, AppointmentRb.comparisonTypes.Equals, userIds);
					AppointmentRb.addFilter({ field: 'client.id' }, AppointmentRb.comparisonTypes.NotEquals, null);

					if (appointmentRoles.length) {
						AppointmentRb.addFilter(
							{ field: 'regBy.role.id' },
							AppointmentRb.comparisonTypes.Equals,
							appointmentRoles
						);
					}
					if (appointmentTypes.length) {
						AppointmentRb.addFilter(
							{ field: 'activityType.id' },
							AppointmentRb.comparisonTypes.Equals,
							appointmentTypes
						);
					}

					var appointmentAggBuilder = AppointmentRb.aggregationBuilder();
					appointmentAggBuilder.addAggregation(AppointmentRb.aggregationTypes.Terms, 'regBy.id');
					appointmentAggBuilder.aggregationOrder('_count', false);
					appointmentAggBuilder.done();

					/** ACTIVITY **/
					var activityRoles = FlashCtrl.tools.cache.get('activityListWidgetRoles')
						? FlashCtrl.tools.cache.get('activityListWidgetRoles')
						: [];
					var activityTypes = FlashCtrl.tools.cache.get('activityListWidgetTypes')
						? FlashCtrl.tools.cache.get('activityListWidgetTypes')
						: [];

					var activityRb = new RequestBuilder();
					activityRb.addFilter(
						{ field: 'closeDate' },
						activityRb.comparisonTypes.GreaterThanEquals,
						startOfDay
					);
					activityRb.addFilter({ field: 'user.id' }, activityRb.comparisonTypes.Equals, userIds);

					if (activityRoles.length) {
						activityRb.addFilter(
							{ field: 'user.role.id' },
							activityRb.comparisonTypes.Equals,
							activityRoles
						);
					}
					if (activityTypes.length) {
						activityRb.addFilter(
							{ field: 'activityType.id' },
							activityRb.comparisonTypes.Equals,
							activityTypes
						);
					}

					var activityAggBuilder = activityRb.aggregationBuilder();
					activityAggBuilder.addAggregation(activityRb.aggregationTypes.Terms, 'user.id');
					activityAggBuilder.aggregationOrder('_count', false);
					activityAggBuilder.done();

					/*Outcome rb*/
					const outcomeRb = new Tools.RequestBuilder();
					const dateAgg = outcomeRb.aggregationBuilder();
					dateAgg.addAggregation(outcomeRb.aggregationTypes.Terms, { field: 'outcomes.type' });
					dateAgg.addFilter(
						{ field: 'outcomes.user.id' },
						outcomeRb.comparisonTypes.Equals,
						AppService.getSelf().id
					);
					dateAgg.addFilter(
						{ field: 'outcomes.date' },
						outcomeRb.comparisonTypes.GreaterThanEquals,
						startOfDay
					);
					dateAgg.addFilter({ field: 'outcomes.type' }, outcomeRb.comparisonTypes.Equals, [
						OUTCOME_TYPES.ANSWER,
						OUTCOME_TYPES.NO_ANSWER
					]);
					dateAgg.done();

					/* OutcomeUserRb */
					const activityOutcomesListWidgetRoles =
						FlashCtrl.tools.cache.get('activityOutcomesListWidgetRoles') || [];
					const activityOutcomesListWidgetTypes =
						FlashCtrl.tools.cache.get('activityOutcomesListWidgetTypes') || [];
					const outcomeUserRb = new RequestBuilder();
					if (activityOutcomesListWidgetRoles.length) {
						outcomeUserRb.addFilter(
							{ field: 'user.role.id' },
							outcomeUserRb.comparisonTypes.Equals,
							activityOutcomesListWidgetRoles
						);
					}
					if (activityOutcomesListWidgetTypes.length) {
						outcomeUserRb.addFilter(
							{ field: 'activityType.id' },
							outcomeUserRb.comparisonTypes.Equals,
							activityOutcomesListWidgetTypes
						);
					}
					const outcomeUserAggBuilder = outcomeUserRb.aggregationBuilder();
					outcomeUserAggBuilder.addAggregation(outcomeUserRb.aggregationTypes.Terms, {
						field: 'outcomes.user.id'
					});
					outcomeUserAggBuilder.aggregationOrder('_count', false);
					outcomeUserAggBuilder.addFilter(
						{ field: 'outcomes.date' },
						outcomeUserRb.comparisonTypes.GreaterThanEquals,
						startOfDay
					);
					outcomeUserAggBuilder.addFilter({ field: 'outcomes.type' }, outcomeUserRb.comparisonTypes.Equals, [
						OUTCOME_TYPES.ANSWER,
						OUTCOME_TYPES.NO_ANSWER
					]);
					outcomeUserAggBuilder.done();

					/* Order rb*/
					const orderRb = new RequestBuilder();
					orderRb.addFilter({ field: 'closeDate' }, orderRb.comparisonTypes.GreaterThanEquals, startOfDay);
					orderRb.addFilter({ field: 'user.id' }, orderRb.comparisonTypes.Equals, currentUserId);
					orderRb.addFilter({ field: 'probability' }, orderRb.comparisonTypes.Equals, 100);

					/* Opportunity rb*/
					const opportunityRb = new RequestBuilder();
					opportunityRb.addFilter(
						{ field: 'regDate' },
						opportunityRb.comparisonTypes.GreaterThanEquals,
						startOfDay
					);
					opportunityRb.addFilter({ field: 'user.id' }, opportunityRb.comparisonTypes.Equals, currentUserId);
					opportunityRb.addFilter({ field: 'probability' }, opportunityRb.comparisonTypes.NotEquals, 100);

					var promises = {
						APPOINTMENT: Report.customer(FlashCtrl.tools.customerId)
							.setType(Report.type.APPOINTMENT)
							.find(AppointmentRb.build()),
						ORDER: Report.customer(FlashCtrl.tools.customerId)
							.setType(Report.type.ORDER)
							.find(orderRb.build()),
						ACTIVITY: Report.customer(FlashCtrl.tools.customerId)
							.setType(Report.type.ACTIVITY)
							.find(activityRb.build()),
						MYAPPOINTMENT: Appointment.customer(FlashCtrl.tools.customerId).find(myAppointmentRb.build()),
						MYORDERS: Order.customer(FlashCtrl.tools.customerId).find(orderRb.build()),
						MYOPPORTUNITIES: Opportunity.customer(FlashCtrl.tools.customerId).find(opportunityRb.build()),
						MYACTIVITY: Activity.customer(FlashCtrl.tools.customerId).find(myActivityRb.build()),
						OUTCOME: Tools.Report.customer(FlashCtrl.tools.customerId)
							.setType(Tools.Report.type.ACTIVITY)
							.find(outcomeRb.build()),
						OUTCOMEUSER: Report.customer(FlashCtrl.tools.customerId)
							.setType(Report.type.ACTIVITY)
							.find(outcomeUserRb.build())
					};

					return $q.all(promises).then(function (res) {
						FlashCtrl.rootData.appointmentListWidget.data = res.APPOINTMENT.data.group_by_regBy_id.buckets;
						FlashCtrl.rootData.activityListWidget.data = res.ACTIVITY.data.group_by_user_id.buckets;
						FlashCtrl.rootData.activityOutcomesListWidget.data =
							res.OUTCOMEUSER.data.group_by_outcomes_user_id.buckets;
						FlashCtrl.rootData.activityOutcomesWidget.data =
							res.OUTCOME.data.group_by_outcomes_type.buckets;

						mapUsers(FlashCtrl.rootData.appointmentListWidget.data);
						mapUsers(FlashCtrl.rootData.activityListWidget.data);
						mapUsers(FlashCtrl.rootData.activityOutcomesListWidget.data);
						FlashCtrl.rootData.appointmentShieldWidget.data = res.MYAPPOINTMENT.metadata.total;
						FlashCtrl.rootData.activityShieldWidget.data = res.MYACTIVITY.metadata.total;
						FlashCtrl.rootData.totalOrders = res.MYORDERS.metadata.total;
						FlashCtrl.rootData.totalOpportunities = res.MYOPPORTUNITIES.metadata.total;
						FlashCtrl.render();
					});
				}

				var topListSettingsChanged = function (prefix, type, values) {
					if (type === 'Role' && Array.isArray(values)) {
						FlashCtrl.tools.cache.update(prefix + 'Roles', values);
						FlashCtrl.rootData[prefix].initialSelectedRoles = values;
					} else if (Array.isArray(values)) {
						FlashCtrl.tools.cache.update(prefix + 'Types', values);
						FlashCtrl.rootData[prefix].initialSelectedTypes = values;
					}
					getTopListData();
				};

				var onCreateCampaign = function () {
					// eslint-disable-next-line promise/catch-or-return
					$upModal
						.open('editCampaign', { customerId: customerId, noRedirect: true })
						.then(function (campaign) {
							// Select the added campaign
							FlashCtrl.rootData.activity.project = { id: campaign.id, name: campaign.name };
							FlashCtrl.render();
						});
				};

				var createOpportunity = function (skipSaveActivity) {
					var options = {
						customerId: customerId,
						clientId: FlashCtrl.rootData.activity.client ? FlashCtrl.rootData.activity.client.id : null,
						contactId:
							FlashCtrl.rootData.activity.contacts && FlashCtrl.rootData.activity.contacts.length
								? FlashCtrl.rootData.activity.contacts[0].id
								: null,
						notes: FlashCtrl.rootData.activity.notes,
						type: 'opportunity',
						resolveOnSave: true,
						activityId: !skipSaveActivity ? FlashCtrl.rootData.activity.id : null
					};

					// eslint-disable-next-line promise/catch-or-return
					return $upModal.open('editOrder', options).then(function (opportunity) {
						FlashCtrl.rootData.activity.opportunity = opportunity;
					});
				};

				var onCreateOpportunity = function () {
					// eslint-disable-next-line promise/catch-or-return
					createOpportunity().then(() => {
						FlashCtrl.render();
					});
				};

				var createOrder = function () {
					var options = {
						customerId: customerId,
						clientId: FlashCtrl.rootData.activity.client ? FlashCtrl.rootData.activity.client.id : null,
						contactId:
							FlashCtrl.rootData.activity.contacts && FlashCtrl.rootData.activity.contacts.length
								? FlashCtrl.rootData.activity.contacts[0].id
								: null,
						notes: FlashCtrl.rootData.activity.notes,
						type: 'order',
						resolveOnSave: true
					};

					// eslint-disable-next-line promise/catch-or-return
					return $upModal.open('editOrder', options).then(function (order) {
						FlashCtrl.rootData.activity.opportunity = order;
						tracker(FlashCtrl.rootData.activity.outcome);
					});
				};

				var createOpportunityAndSave = function () {
					// eslint-disable-next-line promise/catch-or-return
					createOpportunity(true).then(() => {
						setOutcome({
							type: OUTCOME_TYPES.ANSWER,
							outcome: ANSWER_ACTIONS_GREAT.CREATE_OPPORTUNITY
						});
						tracker(FlashCtrl.rootData.activity.outcome);
						onSave(FlashCtrl.rootData.activity, true);
					});
				};

				var createOrderAndSave = function () {
					// eslint-disable-next-line promise/catch-or-return
					createOrder().then(() => {
						setOutcome({
							type: OUTCOME_TYPES.ANSWER,
							outcome: ANSWER_ACTIONS_GREAT.CREATE_ORDER
						});
						onSave(FlashCtrl.rootData.activity, true);
					});
				};

				var onEditAccount = function () {
					if (!FlashCtrl.rootData.activity.client || !FlashCtrl.rootData.activity.client.userEditable) {
						return;
					}

					if (shouldOpenModal('EditClient')) {
						openModal('EditClient', {
							id: FlashCtrl.rootData.activity.client.id,
							onClose: account => {
								if (account) {
									FlashCtrl.rootData.activity.client = account;
									FlashCtrl.render();
								}
							}
						});
					} else {
						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('editAccount', { id: FlashCtrl.rootData.activity.client.id })
							.then(function (account) {
								FlashCtrl.rootData.activity.client = account;
								FlashCtrl.render();
							});
					}
				};

				var onSetJourneyStepClient = function (journeyStep) {
					if (!FlashCtrl.rootData.activity.client) {
						return Promise.resolve();
					}
					return Account.customer(customerId)
						.save({
							id: FlashCtrl.rootData.activity.client.id,
							journeyStep: journeyStep
						})
						.then(function (res) {
							FlashCtrl.rootData.activity.client.journeyStep = res.data.journeyStep;
							FlashCtrl.render();
						});
				};

				var onSetJourneyStepContact = function (journeyStep) {
					if (!FlashCtrl.rootData.activity.contacts || !FlashCtrl.rootData.activity.contacts.length) {
						return Promise.resolve();
					}
					return Contact.customer(customerId)
						.save({
							id: FlashCtrl.rootData.activity.contacts[0].id,
							journeyStep: journeyStep
						})
						.then(function (res) {
							FlashCtrl.rootData.activity.contacts[0].journeyStep = res.data.journeyStep;
							FlashCtrl.render();
						});
				};

				function setDisqualifyToClient(activity) {
					const journeyStep = _.get(activity, 'client.journeyStep');
					const disqualify = _.get(activity, 'outcome.disqualify');

					if (disqualify && journeyStep && statusDisqualifiable(journeyStep)) {
						onSetJourneyStepClient(DISQUALIFIED).catch(e =>
							logError(e, 'Failed to disqualify on close activity')
						);
					}
				}

				function goToUrl(url, focus) {
					if (theWindow && !theWindow.closed) {
						try {
							theWindow.location.replace(url);
						} catch (e) {
							console.warn('Failed to replace the location on the old window', e);
							theWindow = null;
						}
					} else if (focus) {
						try {
							theWindow = window.open(
								url,
								'newwindow',
								`resizable,toolbar,menubar,scrollbars,status,width=${screen.width / 2},height=${
									screen.height
								},left=${screen.width / 2},top=0`
							);
						} catch (err) {
							console.warn('Failed to open link', err);
							theWindow = null;
							Tools.NotificationService.addNotification({
								icon: 'times',
								style: Tools.NotificationService.style.ERROR,
								title: 'flash.couldNotOpenWebpageTitle',
								body: err ? 'flash.couldNotOpenWebpage' : ''
							});
						}
					}

					if (theWindow && focus) {
						theWindow.focus();
					}
				}

				FlashCtrl.rootData = {
					isFinished: false,
					open: false,
					onClose: onClose,
					onHide: onHide,
					onSave: onSave,
					onSaveSimple: onSaveSimple,
					onActivityChange: onActivityChange,
					onCreateDocument: onCreateDocument,
					onCreateFollowup: onCreateFollowup,
					bookAppointment: bookAppointment,
					onCreateContact: onCreateContact,
					onEmailContact: onEmailContact,
					onContactSave: onContactSave,
					onCreateCampaign: onCreateCampaign,
					onCreateOpportunity: onCreateOpportunity,
					createOpportunityAndSave: createOpportunityAndSave,
					createOrderAndSave: createOrderAndSave,
					onEditAccount: onEditAccount,
					onSetJourneyStepClient: onSetJourneyStepClient,
					onSetJourneyStepContact: onSetJourneyStepContact,
					setOutcome: setOutcome,
					setContact: setContact,
					next: next,
					prev: prev,
					hasPrev: false,
					saving: false,
					closing: false,
					transitioning: false,
					contactSaving: false,
					dirty: false,
					submitted: false,
					formInvalid: false,
					roles: {
						activity: [],
						appointment: []
					},
					appointmentListWidget: {
						onChange: function (type, values) {
							topListSettingsChanged('appointmentListWidget', type, values);
						},
						data: [],
						initialSelectedRoles: null,
						initialSelectedTypes: null
					},
					activityListWidget: {
						onChange: function (type, values) {
							topListSettingsChanged('activityListWidget', type, values);
						},
						data: [],
						initialSelectedRoles: null,
						initialSelectedTypes: null
					},
					activityOutcomesListWidget: {
						onChange: function (type, values) {
							topListSettingsChanged('activityOutcomesListWidget', type, values);
						},
						data: [],
						initialSelectedRoles: null,
						initialSelectedTypes: null
					},
					activityOutcomesWidget: {
						data: []
					},
					appointmentShieldWidget: {
						data: null
					},
					activityShieldWidget: {
						data: null
					},
					totalOrders: 0,
					totalOpportunities: 0,
					goToUrl
				};

				var CacheFactory = function () {
					var currentUserId = AppService.getSelf().id;
					var customerId = AppService.getCustomerId();

					var data = null;
					var init = false;

					return {
						update: function (field, value) {
							try {
								data[customerId][currentUserId][field] = value;
								// Don't crash when localstorage is full
								window.localStorage.setItem('flash', JSON.stringify(data));
							} catch (e) {
								logError(e, 'Failed to update flash item to cache');
							}
						},
						get: function (field) {
							return data?.[customerId][currentUserId][field];
						},
						remove: function () {
							window.localStorage.removeItem('flash');
						},
						reset: function () {
							try {
								data[customerId][currentUserId].closedActivitiesCurrentBlixt = 0;
								data[customerId][currentUserId].bookedAppointmentsCurrentBlixt = 0;
								data[customerId][currentUserId].flashTime = +moment.utc();
								data[customerId][currentUserId].flashTimeDuration = 0;
								data[customerId][currentUserId].minimized = false;
								// Don't crash when localstorage is full
								window.localStorage.setItem('flash', JSON.stringify(data));
							} catch (e) {
								logError(e, 'Failed to reset flash item to cache');
							}
						},
						isInitialized: function () {
							return init;
						},
						init: function () {
							var cacheObject = window.localStorage.getItem('flash');

							if (cacheObject !== null) {
								cacheObject = JSON.parse(cacheObject);

								if (!cacheObject[customerId]) {
									cacheObject[customerId] = {};
								}
								if (!cacheObject[customerId][currentUserId]) {
									cacheObject[customerId][currentUserId] = {};
								}
							} else {
								cacheObject = {};
								cacheObject[customerId] = {};
								cacheObject[customerId][currentUserId] = {};
							}

							// Reset session variables
							cacheObject[customerId][currentUserId].closedActivitiesCurrentBlixt = 0;
							cacheObject[customerId][currentUserId].bookedAppointmentsCurrentBlixt = 0;
							cacheObject[customerId][currentUserId].flashTime = +moment.utc();
							cacheObject[customerId][currentUserId].flashTimeDuration = 0;

							if (!cacheObject[customerId][currentUserId].appointmentListWidgetRoles) {
								cacheObject[customerId][currentUserId].appointmentListWidgetRoles = [];
							}
							if (!cacheObject[customerId][currentUserId].appointmentListWidgetTypes) {
								cacheObject[customerId][currentUserId].appointmentListWidgetTypes = [];
							}
							if (!cacheObject[customerId][currentUserId].activityOutcomesListWidgetRoles) {
								cacheObject[customerId][currentUserId].activityOutcomesListWidgetRoles = [];
							}
							if (!cacheObject[customerId][currentUserId].activityOutcomesListWidgetTypes) {
								cacheObject[customerId][currentUserId].activityOutcomesListWidgetTypes = [];
							}
							if (!cacheObject[customerId][currentUserId].activityListWidgetRoles) {
								cacheObject[customerId][currentUserId].activityListWidgetRoles = [];
							}
							if (!cacheObject[customerId][currentUserId].activityListWidgetTypes) {
								cacheObject[customerId][currentUserId].activityListWidgetTypes = [];
							}

							data = cacheObject;
							try {
								// Don't crash when localstorage is full
								window.localStorage.setItem('flash', JSON.stringify(data));
							} catch (e) {
								logError(e, 'Failed to init flash item to cache');
							}

							init = true;
						}
					};
				};

				var _openFlash = function (data) {
					var duration = 0;
					var ts = +moment.utc();

					// If we open the flash from the activitylist we rest
					if (!data.fromMinimized) {
						FlashCtrl.rootData.isFinished = false;
						FlashCtrl.tools.cache.reset();
						FlashCtrl.tools.cache.update('flashTime', ts);

						FlashCtrl.tools.cache.update('data', data);

						const filter = _.cloneDeep(data.filter);

						if (!filter.q) {
							filter.q = [];
						}

						filter.offset = 0;

						const activeFilter = {
							or: {
								q: [
									[
										{ a: 'closeDate', c: 'eq', v: null },
										{ a: 'isAppointment', c: 'eq', v: false }
									],
									[{ a: 'isAppointment', c: 'eq', v: true }]
								]
							}
						};
						filter.q.push(activeFilter);
						data.filter = filter;
					} else {
						duration = FlashCtrl.tools.cache.get('flashTimeDuration') || 0;
					}

					// This was the only fast solution to do this that i came up with.
					$('#flash-sibar-button-wrap').removeClass('visible');

					FlashCtrl.rootData.time = ts - duration; // subtract the old duration
					open(data);
				};

				var init = function () {
					const Store = require('Store').default;
					if (window.useReactInit ? !Store?.getState?.()?.App?.authenticated : !AppService.isLoggedIn()) {
						return null;
					}

					customerId = AppService.getCustomerId();
					var accountSelf = AppService.getAccountSelf();
					var metadata = AppService.getMetadata();
					self = AppService.getSelf();

					FlashCtrl.rootData.isAvailable = {
						document: FeatureHelper.isAvailable(FeatureHelper.Feature.DOCUMENTS),
						opportunity: FeatureHelper.isAvailable(FeatureHelper.Feature.PIPELINE)
					};

					var promises = {
						rolesAppointment: $q.when({ data: AppService.getRoles(AppService.AccessType.APPOINTMENT) }),
						rolesActivity: $q.when({ data: AppService.getRoles(AppService.AccessType.ACTIVITY) }),
						users: $q.when({ data: AppService.getUsers(AppService.AccessType.ACTIVITY) })
					};

					$q.all(promises)
						.then(function (res) {
							FlashCtrl.rootData.roles.activity = res.rolesAppointment.data;
							FlashCtrl.rootData.roles.appointment = res.rolesActivity.data;
							FlashCtrl.rootData.users = res.users.data;

							// This is done after we have roles so that if we have no filters in ceache we can set a default one from the roles
							FlashCtrl.tools.cache.init();

							FlashCtrl.rootData.appointmentListWidget.initialSelectedRoles =
								FlashCtrl.tools.cache.get('appointmentListWidgetRoles');
							FlashCtrl.rootData.appointmentListWidget.initialSelectedTypes =
								FlashCtrl.tools.cache.get('appointmentListWidgetTypes');
							FlashCtrl.rootData.activityOutcomesListWidget.initialSelectedRoles =
								FlashCtrl.tools.cache.get('activityOutcomesListWidgetRoles');
							FlashCtrl.rootData.activityOutcomesListWidget.initialSelectedTypes =
								FlashCtrl.tools.cache.get('activityOutcomesListWidgetTypes');
							FlashCtrl.rootData.activityListWidget.initialSelectedRoles =
								FlashCtrl.tools.cache.get('activityListWidgetRoles');
							FlashCtrl.rootData.activityListWidget.initialSelectedTypes =
								FlashCtrl.tools.cache.get('activityListWidgetTypes');
						})
						.catch(e => {
							logError(e, 'Failed initing stuff');
						});

					getDocumentTemplates();

					var unsubPhoneEvent = $scope.$root.$on('callStatusChanged', function (e, data) {
						FlashCtrl.rootData.phoneIsActive = data.ongoingCall;
						FlashCtrl.render();
					});

					var unsubOpenEvent = $scope.$root.$on('openFlash', function (e, data) {
						if (!data.fromMinimized) {
							var minimizedData = FlashCtrl.tools.cache.get('minimized');
							if (minimizedData) {
								if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
									openModal('Alert', {
										title: 'flash.existingFlashSessionTitle',
										body: 'flash.existingFlashSessionBody',
										confirmButtonText: 'flash.startNewFlash',
										cancelButtonText: 'flash.resumeLastFlash',
										headerIcon: 'warning',
										onClose: newFlash => {
											if (newFlash === undefined) {
												return;
											}
											if (newFlash) {
												_openFlash(data);
											} else {
												_openFlash({ fromMinimized: true });
											}
										}
									});
									return;
								}

								$upModal
									.open('warningConfirm', {
										title: 'flash.existingFlashSessionTitle',
										body: 'flash.existingFlashSessionBody',
										resolveFalse: 'flash.resumeLastFlash',
										resolveFalseBtnClass: 'btn-link btn-bright-blue',
										resolveTrue: 'flash.startNewFlash',
										icon: 'fa-warning'
									})
									.then(function (newFlash) {
										if (newFlash) {
											_openFlash(data);
										} else {
											_openFlash({ fromMinimized: true });
										}
									})
									.catch();
							} else {
								_openFlash(data);
							}
						} else {
							_openFlash(data);
						}
					});

					var unsubHashEvent = $scope.$root.$on('$stateChangeStart', function () {
						if (FlashCtrl.rootData.open) {
							onHide();
						}
					});

					$scope.$on('$destroy', function () {
						unsubOpenEvent();
						unsubHashEvent();
						unsubPhoneEvent();
					});

					$scope.$on('upsales.logout', function () {
						var duration = +moment.utc() - FlashCtrl.rootData.time;
						FlashCtrl.tools.cache.update('flashTimeDuration', duration);
						unsubOpenEvent();
					});

					$scope.$on('activity.updated', function (e, activity) {
						if (FlashCtrl.rootData.open && activity.closeDate) {
							FlashCtrl.tools.cache.update(
								'closedActivitiesCurrentBlixt',
								FlashCtrl.tools.cache.get('closedActivitiesCurrentBlixt') + 1
							);
						}

						getTopListData();
					});

					$scope.$on('appointment.added', function () {
						if (FlashCtrl.rootData.open) {
							FlashCtrl.tools.cache.update(
								'bookedAppointmentsCurrentBlixt',
								FlashCtrl.tools.cache.get('bookedAppointmentsCurrentBlixt') + 1
							);
						}

						getTopListData();
					});

					// Injects (do not remove anything here!!!)
					FlashCtrl.tools = {
						customerId: customerId,
						metadata: metadata,
						RequestBuilder: RequestBuilder,
						$translate: $translate.instant,
						$upModal: $upModal,
						$stateParams: $stateParams,
						$state: $state,
						Ads: Ads,
						Account: Account,
						Contact: Contact,
						AppService: AppService,
						localStorageService: localStorageService,
						$q: $q,
						NotificationService: NotificationService,
						accountSelf: accountSelf,
						self: self,
						$rootScope: $rootScope,
						avatarService: avatarService,
						LatestAccountsService: LatestAccountsService,
						FilterHelper: FilterHelper,
						ActivityType: ActivityType,
						$datepicker: $datepicker,
						$filter: $filter,
						Role: Role,
						cache: new CacheFactory(),
						Campaign: Campaign,
						Opportunity: Opportunity,
						VoiceService: VoiceService,
						URL: URL,
						API: API
					};

					// FlashCtrl.rootData.open = true; // force
					FlashCtrl.render();
				};
				if (window.useReactInit) {
					$rootScope.$on('react.authenticationDone', init);
				} else {
					$scope.$on('AppService.loaded', init);
				}
			}
		],
		link: function ($scope, $element, $attr, FlashCtrl) {
			let firstElement = $element[0];
			var renderTimeout;
			var haveTransition;

			var render = function (cb) {
				if (renderTimeout) {
					clearTimeout(renderTimeout);
				}

				if (haveTransition) {
					FlashCtrl.rootData.transitioning = false;
					haveTransition = null;
				} else {
					haveTransition = FlashCtrl.rootData.transitioning;
				}

				renderTimeout = setTimeout(function () {
					if (!firstElement) {
						return;
					}

					ReactDOM.render(
						React.createElement(ReactTemplates.flash.root, {
							// Data and stuff
							rootData: FlashCtrl.rootData,
							tools: FlashCtrl.tools
						}),
						firstElement,
						function () {
							FlashCtrl.rootData.submitted = false;

							setTimeout(function () {
								FlashCtrl.rootData.transitioning = false;
							}, 800);

							if (cb && typeof cb === 'function') {
								cb();
							}
						}
					);
				}, 20);
			};

			$scope.$on('$destroy', function () {
				ReactDOM.unmountComponentAtNode(firstElement);
				firstElement = undefined;
			});

			FlashCtrl.render = render;
		}
	};
});
