import React, { useMemo } from 'react';
import {
	TableHeader,
	TableColumn,
	TableRow,
	Table,
	Icon,
	Avatar,
	Text,
	Headline,
	Link,
	Tooltip,
	Button
} from '@upsales/components';
import T from 'Components/Helpers/translate';
import moment from 'moment';
import BemClass from '@upsales/components/Utils/bemClass';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import { currencyFormat } from 'Components/Filters/Currencies';
import Agreement from 'App/resources/Model/Agreement';
import Client from 'App/resources/Model/Client';
import { useSelector } from 'react-redux';
import { RootState } from 'Store/index';
import openModal from 'App/services/Modal';

import './AgreementList.scss';
import { getCMWithRROption } from 'App/helpers/salesModelHelpers';

type Props = {
	agreements?: Agreement[];
	editAgreement: (agreement: Agreement) => void;
	removeAgreement: (agreement: Agreement) => void;
	client?: Partial<Client> & Pick<Client, 'id' | 'name'>;
	totalValue: number;
	title: string;
};

const formatDate = (date: Date) => {
	return moment(date).format('L');
};

const AgreementList = ({ agreements, editAgreement, removeAgreement, client, totalValue, title }: Props) => {
	const classNames = new BemClass('AgreementList');
	const {
		metadata,
		documentTemplates: { agreement: agreementDocumentTemplates }
	} = useSelector(({ App }: RootState) => App);
	const {
		params: { clientOrderRelation }
	} = metadata ?? { params: {} };
	const salesModelOption = metadata?.params.SalesModelOption;
	const hasMRR = salesModelOption === 'mrr' || getCMWithRROption() === 'mrr';
	const hasDocumentTemplatesAgreement = !!agreementDocumentTemplates?.length;
	const masterCurrency = useMemo(() => {
		return metadata?.customerCurrencies?.find(({ masterCurrency }) => masterCurrency);
	}, []);

	if (!agreements?.length) {
		return null;
	}

	return (
		<div className={classNames.b()}>
			<Headline size="xs">{title}</Headline>
			<Text className={classNames.elem('totalValue').b()} color="grey-8">{`(${currencyFormat(
				totalValue,
				masterCurrency?.iso ?? ''
			)})`}</Text>
			<Table>
				<TableHeader
					columns={[
						{ title: T('default.startDate').toUpperCase() },
						{ title: T('agreement.nextDate').toUpperCase() },
						{ title: T('default.description').toUpperCase() },
						{ title: T('default.contact').toUpperCase() },
						{
							title: T(
								hasMRR ? 'agreement.list.column.monthlyValue' : 'default.yearlyValue'
							).toUpperCase()
						},
						{ title: T('default.user').toUpperCase() },
						{},
						{}
					]}
				/>
				{agreements
					?.sort(
						(a, b) =>
							new Date(b.metadata.agreementStartdate).getTime() -
							new Date(a.metadata.agreementStartdate).getTime()
					)
					?.map(agreement => (
						<TableRow
							className={classNames
								.elem('tableRow')
								.mod({ inactive: moment(agreement.metadata.agreementEnddate).isBefore() })
								.b()}
							key={agreement.id}
							onClick={() => editAgreement(agreement)}
						>
							<TableColumn>{formatDate(agreement.metadata.agreementStartdate)}</TableColumn>
							<TableColumn>
								{agreement.metadata.willCreateMoreOrders
									? formatDate(agreement.metadata.agreementNextOrderDate)
									: T('agreement.noNextOrderDate')}
							</TableColumn>
							<TableColumn>{agreement.description}</TableColumn>
							<TableColumn>
								{agreement.contact ? (
									<Link
										onClick={e => {
											e.stopPropagation();
											Tools.$state.go('contact.dashboard', {
												id: agreement?.contact?.id
											});
										}}
									>
										{agreement.contact.name}
									</Link>
								) : null}
							</TableColumn>
							<TableColumn>
								{currencyFormat(
									hasMRR ? (agreement.yearlyValue || 0) / 12 : agreement.yearlyValue || 0,
									agreement.currency || masterCurrency?.iso || ''
								)}
							</TableColumn>
							<TableColumn>
								{agreement.user ? (
									<div className={classNames.elem('userRow').b()}>
										<Avatar initials={agreement.user.name} email={agreement.user.email} size="sm" />
										<Text size="sm">{agreement.user.name}</Text>
									</div>
								) : (
									<Text size="sm">{T('default.noUser')}</Text>
								)}
							</TableColumn>
							<TableColumn>
								{client && agreement.clientConnection?.id === client.id ? (
									<Tooltip
										className={classNames.elem('tooltip-icon').b()}
										title={`${client.name} ${T(
											'default.is'
										).toLowerCase()} ${clientOrderRelation?.toLowerCase?.()}`}
									>
										<Icon name="sitemap"></Icon>
									</Tooltip>
								) : null}
							</TableColumn>
							<TableColumn align="right">
								{hasDocumentTemplatesAgreement ? (
									<Tooltip
										className={classNames.elem('tooltip-icon').b()}
										position="left"
										distance={18}
										title={`${T('default.create')} ${T('default.document').toLowerCase()}`}
									>
										<Icon
											name="file"
											color="grey-8"
											onClick={e => {
												e?.stopPropagation();
												if (Tools.FeatureHelper.hasSoftDeployAccess('CREATE_DOCUMENT_REACT')) {
													openModal('CreateDocumentModal', {
														type: 'agreement',
														id: agreement.id,
														templates: agreementDocumentTemplates,
														accountId: client?.id
													});
												} else {
													Tools.$upModal.open('createDocument', {
														type: 'agreement',
														id: agreement.id,
														templates: agreementDocumentTemplates,
														accountId: client?.id
													});
												}
											}}
										></Icon>
									</Tooltip>
								) : null}
								{agreement.userRemovable ? (
									<Tooltip
										title={T('default.delete')}
										position="left"
										className={classNames.elem('tooltip-icon').b()}
									>
										<InlineConfirm
											show
											keepTabPosition
											onConfirm={() => removeAgreement(agreement)}
											entity="default.agreement"
										>
											<Button type="link" color="grey" className="row-tool">
												<Icon name="trash" color="grey-8" />
											</Button>
										</InlineConfirm>
									</Tooltip>
								) : null}
							</TableColumn>
						</TableRow>
					))}
			</Table>
		</div>
	);
};

export default AgreementList;
