import { Button, ButtonGroup, Icon } from '@upsales/components';
import { openFileBrowserModal } from 'Components/FileBrowser';

export default window.ReactCreateClass({
	getInitialState: function () {
		return {
			element: this.props.element
		};
	},
	UNSAFE_componentWillReceiveProps: function (nextProps) {
		this.setState({ element: nextProps.element });
	},
	elementChanged: function (element) {
		this.setState({ element: element });
		this.props.elementChanged(element);
	},
	UNSAFE_componentWillMount: function () {
		var t = Tools.$translate;
		this.lang = {
			setImage: t('bannerEditor.setImage'),
			changeImage: t('bannerEditor.changeImage'),
			backgroundColor: t('form.backgroundColor'),
			textColor: t('form.textColor'),
			add: t('default.add'),
			logo: t('admin.logo'),
			logoLink: t('admin.logo') + ' ' + t('form.link').toLowerCase(),
			openNewWindow: t('editor.linkOpenInNewWindow'),
			maxWidth: t('editor.imageWidthMax')
		};
	},
	toggleEnabled: function () {
		var element = this.state.element;
		element.enabled = !element.enabled;
		this.elementChanged(element);
	},
	valueChanged: function (e) {
		var element = this.state.element;
		element.value.text = e.target.value;
		this.elementChanged(element);
	},
	colorChange: function (key, value) {
		var element = this.state.element;
		element.style[key] = value;
		this.elementChanged(element);
	},
	calcTextareaHeight: function () {
		if (this.state.element.type === 'text' || this.state.element.type === 'button') {
			var textarea = this._textarea;
			textarea.style.height = 'auto';
			textarea.style.height = textarea.scrollHeight + 'px';
		}
	},
	componentDidMount: function () {
		this.calcTextareaHeight();
	},
	componentDidUpdate: function () {
		this.calcTextareaHeight();
	},
	setRef: function (name, ref) {
		this[name] = ref;
	},
	browseImages: function () {
		// eslint-disable-next-line promise/catch-or-return
		openFileBrowserModal({
			types: ['image'],
			public: true,
			selectOnUpload: true
		})
			.then(files => {
				const src = files[0].$$publicUrl;
				return Promise.all([src, this.calculateWidth(src)]);
			})
			.then(([src, width]) => {
				const element = this.cloneElement();
				element.value.src = src;
				element.value.naturalWidth = width;
				element.value.width = width;
				this.elementChanged(element);
			});
	},
	cloneElement() {
		return { ...this.state.element, value: { ...this.state.element.value } };
	},
	calculateWidth(src) {
		return new Promise(resolve => {
			angular
				.element('<img/>')
				.attr('src', src)
				.load(function () {
					resolve(this.width);
				});
		});
	},
	viewImg: function () {
		var win = window.open(this.state.element.value, '_blank');
		win.focus();
	},
	addLink: function () {
		var element = this.state.element;
		element.value.options.push({ title: '', href: '' });
		this.elementChanged(element);
	},
	removeLink: function (index) {
		var element = this.state.element;
		element.value.options.splice(index, 1);
		this.elementChanged(element);
	},
	sizeChanged: function (value) {
		const element = this.cloneElement();
		element.value.width = value;
		this.elementChanged(element);
	},
	optionChange: function (key, index, e) {
		var element = this.state.element;
		if (element.type === 'image') {
			if (key === 'linkHref') {
				element.linkHref = e.target.value;

				if (
					element.linkHref !== undefined &&
					element.linkHref !== null &&
					!(
						'http://'.indexOf(element.linkHref.substring(0, 7)) === 0 ||
						'https://'.indexOf(element.linkHref.substring(0, 8)) === 0 ||
						'mailto:'.indexOf(element.linkHref.substring(0, 7)) === 0 ||
						'ftp://'.indexOf(element.linkHref.substring(0, 6)) === 0
					)
				) {
					element.linkHref = 'http://' + element.linkHref;
				}
			} else if (key === 'linkTarget') {
				if (element.linkTarget !== '_blank') {
					element.linkTarget = '_blank';
				} else {
					element.linkTarget = '';
				}
			} else if (key === 'align') {
				element.value.align = e;
			}
		} else {
			element.value.options[index][key] = e.target.value;
		}
		this.elementChanged(element);
	},
	render: function () {
		var self = this;
		var element = this.state.element;
		var t = Tools.$translate;
		var inputs = null;
		var toggle = null;
		var elemTools = [];

		if (!element.required) {
			toggle = (
				<ReactTemplates.upFilters.components.toggle
					key="toggle"
					checked={element.enabled}
					onChange={this.toggleEnabled}
					className="toggle-bright-blue pull-right"
				/>
			);
		}

		switch (element.type) {
			case 'button':
			case 'text':
				if (element.style.backgroundColor !== undefined) {
					elemTools.push(
						<div className="tool-section">
							<label>{self.lang.backgroundColor}</label>
							<div className="pull-right">
								{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
									value: element.style.backgroundColor || '#fffff',
									isText: false,
									key: 'backgroundColor',
									presets: self.props.colorVariables,
									addPreset: self.props.addPreset,
									onChange: self.colorChange.bind(self, 'backgroundColor')
								})}
							</div>
						</div>
					);
				}
				elemTools.push(
					<div className="tool-section">
						<label>{self.lang.textColor}</label>
						<div className="pull-right">
							{React.createElement(ReactTemplates.bannerEditor.colorPicker, {
								value: element.style.color || '#fffff',
								key: 'color',
								isText: false,
								presets: self.props.colorVariables,
								addPreset: self.props.addPreset,
								onChange: self.colorChange.bind(self, 'color')
							})}
						</div>
					</div>
				);

				inputs = (
					<div className="tool-section">
						<textarea
							className="form-control"
							value={element.value.text}
							onChange={this.valueChanged}
							ref={self.setRef.bind(self, '_textarea')}
						/>
					</div>
				);
				break;
			case 'image':
				var hasImg = element.value.src && element.value.src.length;
				var imageName = '';
				if (hasImg) {
					var splt = element.value.src.split('/');
					imageName = splt[splt.length - 1];
				}

				var titleClass = 'floating-label-input has-value';

				elemTools.push(
					<div className="tool-section">
						<div className={titleClass}>
							<label
								style={{
									fontWeight: 700,
									color: '#333',
									padding: 0
								}}
							>
								{self.lang.logoLink}
							</label>
							<input
								placeholder="https://www.example.com"
								type="text"
								value={element.linkHref}
								onChange={self.optionChange.bind(self, 'linkHref', 0)}
							/>
						</div>
						<label>{self.lang.openNewWindow}</label>
						<div
							style={{
								float: 'right',
								marginTop: '-3px'
							}}
						>
							<ReactTemplates.upFilters.components.toggle
								key="toggle"
								className="toggle-bright-blue"
								checked={element.linkTarget && element.linkTarget.length}
								onChange={self.optionChange.bind(self, 'linkTarget', 0)}
							/>
						</div>
					</div>
				);

				inputs = (
					<>
						<div className="tool-section">
							<label>{self.lang.logo}</label>
							{hasImg ? (
								<button
									key="view-btn"
									type="button"
									className="up-btn btn-link btn-sm btn-bright-blue btn-block change-img-btn"
									onClick={this.browseImages}
								>
									<b className="fa fa-image"></b> {imageName}
									<span className="text-grey on-hover pull-right">{self.lang.changeImage}</span>
								</button>
							) : (
								<button
									type="button"
									className="up-btn btn-link btn-sm btn-bright-blue"
									onClick={this.browseImages}
								>
									{self.lang.setImage}
								</button>
							)}
							<ButtonGroup block={true}>
								{['left', 'center', 'right'].map(align => (
									<Button
										key={align}
										shadow="none"
										size="sm"
										onClick={self.optionChange.bind(self, 'align', 0, align)}
										color={(element.value.align || 'left') === align ? 'bright-blue' : 'light-grey'}
									>
										<Icon name={'align-' + align} />
									</Button>
								))}
							</ButtonGroup>
						</div>
						{hasImg ? (
							<div className="tool-section" key="size-row">
								<label>{this.lang.maxWidth}</label>
								<div className="pull-right">
									<ReactTemplates.upStepInt
										min={0}
										max={Infinity}
										value={self.state.element.value.width || self.state.element.value.maxWidth || 0}
										className="input-sm pull-right"
										onChange={self.sizeChanged}
									/>
								</div>
							</div>
						) : null}
					</>
				);
				break;
			case 'links':
				var addOption = (
					<button type="button" className="up-btn btn-link btn-bright-blue btn-block" onClick={self.addLink}>
						<b className="fa fa-plus"></b> {self.lang.add}
					</button>
				);

				var options = _.map(element.value.options, function (option, i) {
					var titleClass = 'floating-label-input';
					if (option.title && option.title.length) {
						titleClass += ' has-value';
					}
					var hrefClass = 'floating-label-input link-href-input';
					if (option.href && option.href.length) {
						hrefClass += ' has-value';
					}
					return (
						<div className="tool-section">
							<div className="col-sm-6">
								<div className={titleClass}>
									<label>{self.lang.title}</label>
									<input
										type="text"
										value={option.title}
										onChange={self.optionChange.bind(self, 'title', i)}
									/>
								</div>
							</div>
							<div className="col-sm-6">
								<div className={hrefClass}>
									<label>{self.lang.href}</label>
									<input
										type="text"
										value={option.href}
										onChange={self.optionChange.bind(self, 'href', i)}
									/>
								</div>
								<button
									type="button"
									className="remove-link-btn up-btn btn-link btn-grey btn-hover-red btn-sm"
									onClick={self.removeLink}
								>
									<b className="fa fa-trash-o"></b>
								</button>
							</div>
						</div>
					);
				});

				inputs = (
					<div>
						{options}
						<div className="tool-section">{addOption}</div>
					</div>
				);
				break;
		}

		return (
			<div>
				<div className="tool-header">
					<h3>{t(element.lang)}</h3>
					{toggle}
				</div>

				<div className="tool-main-section element-tools">
					{elemTools}

					{inputs}
				</div>
			</div>
		);
	}
});
