import React, { useEffect, useState } from 'react';
import { SplitFactory } from '@splitsoftware/splitio';
import { SplitFactoryProvider, useSplitTreatments } from '@splitsoftware/splitio-react';
import AppLoader from 'App/components/AppLoader';
import config from '../../config';
// TODO: Extend these defaults with a git-ignored file
import localFeatures from './localFeatures.json';

const splitConfig: SplitIO.IBrowserSettings = {
	core: {
		authorizationKey: config.SPLIT_KEY,
		key: 'CUSTOMER_ID'
	}
};
if (config.SPLIT_KEY === 'localhost') {
	splitConfig.features = localFeatures;
}
const factory = SplitFactory(splitConfig);

const client = factory.client();

export function checkFeature(featureName: string): boolean {
	const treatment = client.getTreatment(featureName);
	return treatment === 'on';
}

export const useFeature = (featureName: string): boolean => {
	const { treatments } = useSplitTreatments({ names: [featureName] });
	return treatments[featureName].treatment === 'on';
};

const SPLIT_TIMEOUT = 5000;

const SplitReadyWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [shouldRender, setShouldRender] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			console.warn('Split.io timed out, rendering anyway');
			setShouldRender(true);
		}, SPLIT_TIMEOUT);

		client
			.ready()
			.then(() => {
				setShouldRender(true);
				clearTimeout(timer);
			})
			.catch(() => {
				console.warn('Split.io failed to load, rendering anyway');
				setShouldRender(true);
				clearTimeout(timer);
			});

		return () => clearTimeout(timer);
	}, []);

	return (
		<>
			<AppLoader visible={!shouldRender} />
			{shouldRender ? children : null}
		</>
	);
};

// Provider to wrap the entire application in. Will not render children until split is ready. If split takes longer than 5 seconds to load we will render the app anyway.
export const WithSplitProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	return (
		<SplitFactoryProvider factory={factory}>
			<SplitReadyWrapper>{children}</SplitReadyWrapper>
		</SplitFactoryProvider>
	);
};
