import React from 'react';
import { Column, Headline, Row, Text } from '@upsales/components';
import { useTranslation } from 'Components/Helpers/translate';
import WhatsIncludedCard from 'Components/CreateAccount/WhatsIncludedCard';
import { DefaultButton, ThirdButton } from '@upsales/components/Buttons';
import openModal from 'App/services/Modal/Modal';

type Props = {
	showLogin: () => void;
};

const LoginCreateAccount = ({ showLogin }: Props) => {
	const { t } = useTranslation();

	const createAccount = () => {
		openModal('CreateUpsalesAccount', {
			onClose: () => {
				showLogin();
			}
		});
	};

	return (
		<div className="LoginCreateAccount">
			<Text color="super-light-green">{t('login.welcomeToUpsales')}</Text>
			<Headline size="sm" color="super-light-green">
				{t('login.createAccountOrLogin')}
			</Headline>
			<Text size="lg" color="super-light-green">
				{t('login.createAccountExplainer')}
			</Text>
			<WhatsIncludedCard borderRadius space="mtxl mbxl" />
			<Row>
				<Column>
					<DefaultButton size="lg" block onClick={() => createAccount()}>
						{t('login.createAccount')}
					</DefaultButton>
				</Column>
				<Column>
					<ThirdButton size="lg" onDark block onClick={() => showLogin()}>
						{t('createAccount.iAlreadyHaveAccount')}
					</ThirdButton>
				</Column>
			</Row>
		</div>
	);
};

export default LoginCreateAccount;
