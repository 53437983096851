'use strict';
import React from 'react';
import _ from 'lodash';
import angular from 'angular';

(function () {
	ReactTemplates.TOOLS.selectHelper = {};

	var ref = '_input';
	var setRef = function (name, r) {
		this[name] = r;
	};

	// Returns the title or a provided message if the title is empty
	var emptyCheck = function (title, msg) {
		title = _.trim(title);
		if (!title.length || title === ' ') {
			return msg;
		}
		return title;
	};

	var getIsMultiple = function (self) {
		return self.props.multiple !== undefined
			? self.props.multiple
			: self._selectHelperOptions
			? self._selectHelperOptions.multiple
			: false;
	};

	var getOption = function (option, defaultValue, isFunction) {
		// Set option to provided (from directive)
		var opt = option;

		// If the option was set and it was a function (and not a isFunction optionpt)
		if (option && typeof option === 'function' && !isFunction) {
			opt = option() || defaultValue;
		}

		// if no option was set we use default value
		if (opt === undefined) {
			opt = defaultValue;
		}

		// return option value now
		return opt;
	};

	var baseSelect2Obj = function (self, idKey, titleKey, emptyMsg) {
		return {
			minimumInputLength: 0,
			quietMillis: 100,
			allowClear: 1,
			placeholder: ' ',
			initSelection: function (input, cb) {
				var val = null;
				try {
					if (getIsMultiple(self)) {
						val = input.data('value');
					} else {
						val = self.props.value;
					}
				} catch (e) {
					// error
				}
				cb(val);
			},
			formatResult: function (obj, el, x, encode) {
				return encode(emptyCheck(obj[titleKey], emptyMsg));
			},
			formatSelection: function (obj, x, encode) {
				return encode(emptyCheck(obj[titleKey], emptyMsg));
			},
			matcher: function (term, undef, object) {
				return (object[titleKey] ?? '').toLowerCase().indexOf(term.toLowerCase()) !== -1;
			},
			id: function (obj) {
				// return obj;
				return obj ? obj[idKey] : null;
			}
		};
	};

	var wrapFormatSelectionLink = function (formatSelection, goToFn) {
		// Link it
		if (formatSelection && angular.isFunction(formatSelection) && goToFn && angular.isFunction(goToFn)) {
			return _.debounce(
				function (obj, elem, encode) {
					var a = angular.element('<a href=""/>');
					a.addClass('select2-inner-link');
					a.attr('href', 'javascript:void(0)');
					a.html(formatSelection(obj, elem, encode));

					if (!elem.data('linked')) {
						elem.on('mousedown', '.select2-inner-link', function (e) {
							e.stopPropagation();
							goToFn();
						}).on('click', '.select2-inner-link', function (e) {
							e.stopPropagation();
							e.preventDefault();
						});
					}

					elem.data('linked', true);

					return a;
				},
				200,
				true
			);
		} else {
			// Return original selection
			return formatSelection;
		}
	};

	var getAjaxRb = function (self, customFilters, idAttr, titleAttr, sorting, fields) {
		var rb = window.Tools.RequestBuilder();

		if (customFilters) {
			customFilters(rb);
		}

		if (sorting) {
			rb.addSort(sorting.field, sorting.ascending);
		}

		rb.fields = [idAttr.field, titleAttr.field];

		if (fields) {
			rb.fields = _.unique(rb.fields.concat(fields));
		}

		return rb;
	};

	ReactTemplates.TOOLS.selectHelper.getInputComponent = function (self) {
		var val;
		var multiple = getIsMultiple(self);
		if (multiple && (!self.props.value || (Array.isArray(self.props.value) && self.props.value.length === 0))) {
			val = [];
		} else if (!multiple && !self.props.value) {
			val = self.props.value;
		} else {
			val = JSON.stringify(self.props.value);
		}

		return (
			<input
				type="hidden"
				autoComplete="new-password"
				ref={setRef.bind(self, ref)}
				name={self.props.name}
				onChange={self.onChange}
				disabled={self.props.disabled || null}
				required={self.props.required || null}
				multiple={multiple}
				className={self.props.className || 'form-control'}
				id={self.props.id || null}
				tabIndex={self.props.tabIndex || null}
				value={val || ''}
				data-value={val || ''}
			/>
		);
	};

	ReactTemplates.TOOLS.selectHelper.updateValue = function () {
		var val = this.props.value;
		var input = jQuery(this._input);

		if (getIsMultiple(this)) {
			if (val === undefined || val === null) {
				return input.select2('data', []);
			}
			if (!_.isArray(val)) {
				val = [val];
			}
			input.select2('data', val);
		} else {
			input.select2('val', val);
		}
	};

	ReactTemplates.TOOLS.selectHelper.getSelect2Options = function (self, defaultOptions, input, callback) {
		var _callback = function (opts) {
			callback(opts);
		};
		/*
			Some available options
			- ajax: the options is requested from the api as the user types
			- limit: If combined with ajax the select can be a normal select(no ajax) if there is less than the provided limit of the entity
			- asIds: The model is containing the ids of the selected objects instaid of the object itself
		*/
		defaultOptions = defaultOptions || {};

		var AppService = window.Tools.AppService;

		var options = {};

		angular.extend(options, defaultOptions);

		self._selectHelperOptions = options;

		var isMultiple =
			self.props.multiple !== undefined
				? self.props.multiple
				: self._selectHelperOptions
				? self._selectHelperOptions.multiple
				: false;

		//var required = self.props.required !== undefined;

		var isTooMany = false;
		// Is true if the ajax results was more than the limit

		// The number of allowed options before the select becomes an ajax-select (only when ajax option is set). Defaults to 200
		var MAX_LIMIT = getOption(options.limit, 500);

		var titleAttr = getOption(options.titleAttr, { field: 'name' });
		// Is used to print the title for each option and also the field you search when ajax

		var idAttr;
		// Is used to get objects based on ids

		var emptyMsg = getOption(options.emptyMsg, '-'); // defaults to '-';
		// Is printed out if the title value is empty

		var Resource = getOption(options.resource);
		// The resource to use whe ncollecting options for the select

		var customFilters;
		// customfilters used when getting the options from the api

		var data;
		// Holds the options for static selects

		var fields = getOption(options.fields, null);
		// Fields to get from resource other than {idAttr} and {titleAttr} fields

		var sorting = getOption(options.sorting);
		// request sort order

		var resourceType = getOption(options.resourceType);
		// if the resource require a type (like user) we set it here

		var getter;
		// The get function for the resource

		var formatResult;
		var formatSelection;

		const formatData = options.formatData || (data => data);
		// The format functions for select2

		var isAjax;

		var cachedTotal = getOption(options.cachedTotal, null);

		// var referenceData;
		// Holds reference data for selects with children

		// ngModel is the select2 model, for asIds selects this is not the same model as the ids are stored in
		// Wait for app to load
		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(function () {
			// Is used to get objects based on ids
			idAttr = getOption(options.idAttr, { field: 'id' }); // defaults to id;

			// If this is a static select or nah
			isAjax = getOption(options.ajax, false);

			// Set the static data array
			data = getOption(options.data, []);

			var customerId = AppService.getCustomerId();

			if (isAjax) {
				if (options.getter) {
					getter = options.getter;
				} else {
					getter = function (customerId, filter) {
						var internalGetter;
						if (resourceType) {
							if (Resource.customer) {
								internalGetter = Resource.customer(customerId).setType(resourceType);
							} else {
								internalGetter = Resource.setType(resourceType);
							}
						} else {
							if (Resource.customer) {
								internalGetter = Resource.customer(customerId);
							} else {
								internalGetter = Resource;
							}
						}

						return internalGetter.find(filter);
					};

					if (options.filters && typeof options.filters === 'function') {
						customFilters = options.filters;
					}
				}
			} else {
				if (options.getter) {
					getter = options.getter;
				} else {
					getter = function () {
						var $q = window.Tools.$q;

						if (typeof data === 'object' && data.referenceData) {
							// referenceData = data.referenceData;
							return $q.when({ data: data.data });
						}
						return $q.when({ data: data });
					};
				}
			}

			// If the model will be ids
			if (options.asIds) {
				// Setup the default state of the model, multiselect or not
				// var findInData;
				// if(options.findInData) {
				// 	findInData = options.findInData;
				// }
				// else {
				// 	findInData = function(findObj) {
				// 		if(referenceData) {
				// 			return _.find(referenceData, findObj);
				// 		}
				// 		return _.find(data, findObj);
				// 	};
				// }
				// Sets the select2 model based on id(s)
				// var setModelByIds = function(value) {
				// 	if(value) {
				// 		// Parse the id(s)
				// 		if(idAttr.type === FilterType.Number) {
				// 			if(isMultiple && Array.isArray(value)) {
				// 				value = _.map(value, function(val) {
				// 					return parseInt(val);
				// 				});
				// 			} else {
				// 				value = parseInt(value);
				// 			}
				// 		}
				// 		if(isAjax) {
				// 			// Setup filter and get the objects
				// 			if((isMultiple && Array.isArray(value) && value.length) || !isMultiple) {
				// 				var filter = new RequestBuilder();
				// 				var comparisonType = !!options.comparisonType ? options.comparisonType : filter.comparisonTypes.Equals;
				// 				filter.addFilter(idAttr, comparisonType, value);
				// 				getter(customerId, filter.build(), null).then(function(res) {
				// 					// Apply to the select2 model
				// 					if(isMultiple) {
				// 						input.select2('val', res.data);
				// 					} else {
				// 						input.select2('val', res.data[0]);
				// 					}
				// 				});
				// 			} else {
				// 				if(isMultiple) {
				// 					input.select2('val', []);
				// 				} else {
				// 					input.select2('val', null);
				// 				}
				// 			}
				// 		} else {
				// 			initialDataPromise.then(function() {
				// 				// Filter out all results based on the id
				// 				var findObj = {};
				// 				if(isMultiple) {
				// 					var objects = [];
				// 					_.each(value, function(id) {
				// 						findObj[idAttr.field] = id;
				// 						var found = findInData(findObj);
				// 						if(found) {
				// 							objects.push(found);
				// 						}
				// 					});
				// 					input.select2('val', objects);
				// 				} else {
				// 					findObj[idAttr.field] = value;
				// 					var found = findInData(findObj);
				// 					input.select2('val', found);
				// 				}
				// 			});
				// 		}
				// 	} else { // Empty value
				// 		if(isMultiple) {
				// 			input.select2('val', []);
				// 		} else {
				// 			input.select2('val', null);
				// 		}
				// 	}
				// };
				// Watch the select2 model and set the idModel when changed
				// scope.$watch('model', function(value) {
				// 	if(value !== undefined) {
				// 		// Multi select
				// 		if(isMultiple) {
				// 			scope.idModel = _.pluck(value || [], idAttr.field);
				// 		} else if(value && typeof value === 'object' && value[idAttr.field]) { // Single select
				// 			scope.idModel = value[idAttr.field];
				// 		} else {
				// 			scope.idModel = null; // Unset state for single select
				// 		}
				// 	}
				// }, true);
				// // Watch the id model for changes and set the select2 model (sets the initial value too)
				// scope.$watch('idModel', function(value) {
				// 	if((isMultiple && value) ||	(!isMultiple && value && value !== scope.model)) {
				// 		setModelByIds(value);
				// 	}
				// }, true);
			}

			// Trigger onChange
			input.on('change', function () {
				var val = null;

				try {
					if (isMultiple) {
						val = input.select2('data');
					} else {
						val = input.select2('data');
					}
				} catch (e) {
					// error
				}

				input.data('value', val);
				options.onChange(val);
			});

			// The select2 config object
			var select2Config = baseSelect2Obj(self, idAttr.field, titleAttr.field, emptyMsg);
			select2Config = _.merge(select2Config, options.select2 || {});

			// Set allow clear if field is not required
			select2Config.allowClear = !options.required ? 1 : 0;
			select2Config.multiple = isMultiple ? 1 : 0;
			select2Config.placeholder = getOption(options.placeholder, ' ', false);
			select2Config.dropdownCssClass = getOption(options.dropdownCssClass, '', false);

			// Function that return the function
			if (options.formatResultFn) {
				formatResult = getOption(options.formatResultFn, null, false);
			} else {
				formatResult = getOption(options.formatResult, null, true);
			}
			if (options.formatSelectionFn) {
				formatSelection = getOption(options.formatSelectionFn, null, false);
			} else {
				formatSelection = getOption(options.formatSelection, null, true);
			}

			if (formatResult) {
				select2Config.formatResult = formatResult;
			}
			if (formatSelection) {
				select2Config.formatSelection = formatSelection;
			}

			// Link it
			if (options.linked && options.goTo) {
				var oldFormatSelection = select2Config.formatSelection;
				select2Config.formatSelection = wrapFormatSelectionLink(oldFormatSelection, options.goTo);
			}

			// Custom search choice
			if (options.createSearchChoice) {
				select2Config.createSearchChoice = options.createSearchChoice;
			}

			// Setup add event listener if we have one
			if (options.addEvent) {
				var $rootScope = window.Tools.$rootScope;
				var FilterHelper = window.Tools.FilterHelper;

				$rootScope.$on(options.addEvent, function (e, added) {
					// If the list is static we might wanna push the new item
					// If not the item will show up when user types to search
					if (!isAjax || !isTooMany) {
						var rb = getAjaxRb(self, customFilters, idAttr, titleAttr, sorting, fields);
						if (FilterHelper.match(rb.build().q, added, options.filterType)) {
							select2Config.data.push(added);
						}
					}
				});
			}

			var setSelect2Ajax = function (total) {
				if (total > MAX_LIMIT || options.ignoreMaxLimitCheck) {
					if (!data || !data.length) {
						select2Config.minimumInputLength = 1;
					}
					select2Config.matcher = undefined;
					select2Config.ajax = {
						data: function (term) {
							return term;
						},
						transport: function (query) {
							if (query.data !== undefined) {
								// If we have initial data to show when no searchString is set
								if (query.data === '' && data && data.length) {
									return query.success({ data: data });
								}

								var filter = getAjaxRb(self, customFilters, idAttr, titleAttr, sorting, fields);
								filter.addFilter(titleAttr, filter.comparisonTypes.Search, query.data);
								filter.limit = 10;

								//return getter(customerId).find(filter.build()).then(query.success);
								return getter(customerId, filter.build(), query.data).then(({ data, ...rest }) =>
									query.success({ data: formatData(data), ...rest })
								);
							}
							return query.success({
								data: []
							});
						},
						results: function (res) {
							return { results: res.data };
						}
					};

					isTooMany = true;

					// Compile the element
					_callback(select2Config);
				} else {
					var rb = getAjaxRb(self, customFilters, idAttr, titleAttr, sorting, fields);
					rb.limit = MAX_LIMIT;
					return getter(customerId, rb.build(), null).then(function (res) {
						select2Config.data = formatData(res.data);

						// Compile the element
						_callback(select2Config);
					});
				}
			};

			// If this is an ajax
			if (isAjax) {
				if (cachedTotal !== null) {
					setSelect2Ajax(cachedTotal);
					return;
				}
				// Make filters and get total data so we know if there is more objects than the limit
				var rb = getAjaxRb(self, customFilters, idAttr, titleAttr, sorting, fields);
				rb.limit = 0;

				// eslint-disable-next-line promise/catch-or-return
				getter(customerId, rb.build(), null).then(function (res) {
					// If there is more than the limit we make this select an ajax
					setSelect2Ajax(res.metadata.total);
				});
			} else {
				// This select is static so we use the provided options
				// eslint-disable-next-line promise/catch-or-return
				getter(customerId, {}, null).then(function (res) {
					data = res.data;
					select2Config.data = data;

					// Compile the element
					_callback(select2Config);
				});
			}
		}); // End of AppService.loadedPromise
	};
})();
