import React from 'react';
import InlineConfirm from 'Components/Dialogs/InlineConfirm';
import {
	Checkbox,
	Tooltip,
	Row,
	Column,
	Link,
	Button,
	Icon,
	TableRow,
	TableColumn,
	Text,
	Title,
	ButtonGroup,
	EllipsisTooltip
} from '@upsales/components';
import logError from 'App/babel/helpers/logError';
import AdminHeader from 'Components/Admin/AdminHeader';
// Remove once 'NEW_CUSTOM_DOCUMENT_TEMPLATES' is implemented
import _ from 'lodash';
//
import history from 'App/pages/routes/history';
const moment = require('moment');
import t from 'Components/Helpers/translate';

import './documentTemplates.scss';
import { openTemplateEditorModal } from 'Components/Admin/DocumentTemplate/TemplateEditor';

ReactTemplates.admin.documentTemplates = window.ReactCreateClass({
	getInitialState: function () {
		this.initializeTemplates();
		return {
			showTags: false,
			searchStr: '',
			activeTagsId: 0,
			copiedTag: null,
			// Remove once 'NEW_CUSTOM_DOCUMENT_TEMPLATES' is implemented
			sort: 'name',
			desc: false,
			//
			sortCustomer: 'name',
			sortStandard: 'lastModified',
			descCustomer: false,
			descStandard: false,
			sortingTable: 'standardTemplates',
			roleEditRowId: false,
			// Remove once NEW_CUSTOM_DOCUMENT_TEMPLATES has been implemented
			standardTemplatesLoaded: false,
			standardTemplatesList: [],
			customTemplatesList: [],
			//
			templatesLoaded: false,
			templatesList: [],
			editRolesOpen: false,
			allTemplates: [],
			isTextOverflowing: false
		};
	},

	UNSAFE_componentWillMount: function () {
		this.lang = {
			title: t('admin.document.title'),
			desc: t('admin.document.description'),
			howItWorks: t('admin.document.itWorksLikeThis'),
			howItWorksDesc: t('admin.document.itWorksLikeThisDescription'),
			showTagsListLink: t('admin.document.showTagsListLink'),
			hideTagsListLink: t('admin.document.hideTagsListLink'),
			downloadTemplatesTitle: t('admin.document.downloadTemplatesTitle'),
			download: t('default.download'),
			copy: t('default.copy'),
			name: t('default.name'),
			tag: t('default.tag'),
			new: t('admin.documentTemplateModal.newTemplate'),
			permissionToRole: t('default.permissionToRole'),
			lastUpdated: t('admin.documentTemplate.lastUpdated'),
			createdBy: t('admin.documentTemplate.createdBy'),
			availableFor: t('admin.documentTemplate.availableFor'),
			type: t('admin.documentTemplateModal.typeShort'),
			availableTags: t('admin.documentTemplate.availableTags'),
			exampleLetter: t('admin.documentTemplate.exampleLetter'),
			exampleProposal: t('admin.documentTemplate.exampleProposal'),
			readMoreAboutTags: t('tag.readMoreAboutTags'),
			searchTags: t('tag.searchTags'),
			noTags: t('tag.noTags'),
			viewTagsInNewWin: t('tag.viewTagsInNewWin'),
			downloadTemplate: t('default.download'),
			uploadTemplate: t('admin.documentTemplate.uploadTemplate'),
			uploadedTemplates: t('admin.documentTemplate.uploadedTemplates'),
			copiedToClipboard: t('default.copiedToClipboard'),
			active: t('admin.standardTemplates.active'),
			error: {
				ACCOUNTPROFILE_DOES_NOT_EXIST: t('admin.documentTemplateEditor.accountprofile.doesNotExist')
			},
			// Remove once NEW_CUSTOM_DOCUMENT_TEMPLATES has been implemented
			standardTemplateTitle: t('admin.standardTemplates'),
			//
			customTemplateTitle: t('admin.customTemplates'),
			addCustomTemplate: t('admin.customTemplates.addNew'),
			addNewDocTemplate: t('admin.customTemplates.addNewDocTemplate'),
			addCustomTemplateLInk: t('admin.customTemplates.addNewLink'),
			noCustomTemplate: t('admin.customTemplates.emptyMessage'),
			addRoles: t('admin.standardTemplates.addRole'),
			preview: t('default.preview'),
			edit: t('default.edit'),
			delete: t('default.delete')
		};

		this.hasNewCustomDocumentTemplates = Tools.FeatureHelper.hasSoftDeployAccess('NEW_CUSTOM_DOCUMENT_TEMPLATES');
		this.hasRoleFeature = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.USER_PERMISSIONS_ADVANCED);
	},

	toggleTags: function () {
		if (this.state.showTags) {
			this.setState({
				showTags: false
			});
		} else {
			this.setState({
				showTags: true
			});
		}
	},

	copyValue: function (tag) {
		const self = this;
		const tempField = document.createElement('textarea');

		tempField.innerText = tag;

		document.body.appendChild(tempField);
		tempField.select();
		document.execCommand('copy');
		tempField.remove();

		this.setState({
			copiedTag: tag
		});

		setTimeout(function () {
			self.setState({
				copiedTag: null
			});
		}, 1000);
	},

	toggleDisplayTags: function (id) {
		this.setState({
			activeTagsId: id
		});
	},

	tagsInNewWin: function (e) {
		e.preventDefault();
		const preview = window.open(
			'',
			'',
			'height=700,width=800,scrollbars=yes,resizable=yes,toolbar=no,status=no,menu=no,titlebar=no,location=no,addressbar=no'
		);

		let tags = '';

		this.props.rootData.pageData.tags.forEach(function (tagObj) {
			tags += '<h3>' + tagObj.name + '</h3>';
			tags += '<table border=0 width="100%" cellpadding="0" cellspacing="0">';
			tagObj.tags.forEach(function (tag) {
				tags += '<tr>';
				tags += '<td>' + tag.name + '</td>';
				tags += '<td>' + tag.tag + '</td>';
				tags += '</tr>';
			});
			tags += '</table>';
		});
		const html = '<body>' + tags + '</body>';
		preview.document.write(html);
	},

	createTagList: function () {
		const self = this;

		const arr = this.props.rootData.pageData.tags[self.state.activeTagsId].tags
			.filter(tag => {
				if (self.state.searchStr && self.state.searchStr.length) {
					const matchName = tag.name.toLowerCase().includes(self.state.searchStr.toLowerCase());
					const matchTag = tag.tag.toLowerCase().includes(self.state.searchStr.toLowerCase());
					return matchName || matchTag;
				}
				return true; // If no search string, all tags are considered a match
			})
			.map(function (item, index) {
				return (
					<tr key={item.tag + index} style={{ height: '50px' }}>
						<td
							className="two-lines"
							style={{
								verticalAlign: 'middle',
								lineHeight: '1.4em'
							}}
						>
							{item.name}
							<small style={{ display: 'block' }}>{item.description}</small>
						</td>
						<td
							style={{
								fontFamily: 'monospace',
								fontSize: '12px',
								letterSpacing: '1px',
								lineHeight: '12px'
							}}
						>
							{item.tag}
						</td>
						<td className="admin-row-tools-wrap" style={{ paddingRight: '20px' }}>
							<div className="admin-row-tools">
								{self.state.copiedTag === item.tag ? (
									<span style={{ float: 'right' }} className="text-green">
										<b className="fa fa-check" /> {self.lang.copiedToClipboard}
									</span>
								) : (
									<button
										style={{ float: 'right' }}
										className="up-btn btn-bright-blue no-shadow btn-sm"
										onClick={self.copyValue.bind(self, item.tag)}
									>
										{self.lang.copy}
									</button>
								)}
							</div>
						</td>
					</tr>
				);
			});

		const buttons = this.props.rootData.pageData.tags.map((tagGroup, index) => {
			const onClick = () => self.toggleDisplayTags(index);

			const classNames =
				index === self.state.activeTagsId
					? 'up-btn btn-green no-shadow btn-sm document-tagselect-button'
					: 'up-btn btn-light-grey no-shadow btn-sm document-tagselect-button';

			return (
				<Button onClick={onClick} className={classNames} key={index}>
					{tagGroup.name}
				</Button>
			);
		});

		return (
			<div>
				<div className="admin-table-top">
					<span className="admin-table-title">{self.lang.availableTags}</span>
					<Button type="link" color="bright-blue" onClick={this.tagsInNewWin}>
						{this.lang.viewTagsInNewWin}
					</Button>
					<div className="pull-right">
						<div className="table-search" style={{ display: 'inline-block' }}>
							<input
								type="text"
								value={self.state.searchStr}
								onChange={self.searchTags}
								placeholder={this.lang.searchTags}
							/>
							<b className="fa fa-search" />
						</div>
					</div>
					<div className="document-tagselect-container">{buttons}</div>
				</div>

				<div className="table-wrapper" style={{ maxHeight: '600px', overflowY: 'scroll' }}>
					<table style={{ wordBreak: 'break-all' }}>
						{arr.length ? (
							<thead>
								<tr>
									<th style={{ width: '350px' }}>{self.lang.name}</th>
									<th>{self.lang.tag}</th>
									<th style={{ width: '100px' }} />
								</tr>
							</thead>
						) : null}
						<tbody>
							{arr.length ? (
								arr
							) : (
								<tr className="table-no-result">
									<td>{self.lang.noTags}</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		);
	},

	searchTags: function (e) {
		this.setState({ searchStr: e.target.value });
	},

	generateTemplatesHtml: function (list) {
		return list.map(doc => (
			<div key={doc.name} className={'template-download-wrap'}>
				<a href={doc.downloadLink} className="doc-template">
					<Icon name="download" color="grey-11" className="download-icon" />
					<div className="row">
						<div className="col-md-12">
							<p className="doctemp-dl">{this.lang.download}</p>
						</div>
						<div className="col-md-12">
							<p className="doctemp-name">{doc.name}</p>
						</div>
					</div>
				</a>
			</div>
		));
	},

	getUdoName: function (id) {
		const AppService = this.props.tools.AppService;
		const udos = AppService.getMetadata().params.UserDefinedObject;

		const udo = udos.find(udo => udo.id === id);

		return udo ? udo.name : '';
	},

	switchType: function (type) {
		switch (type) {
			case 'activity':
				return t('default.activityAndAppointment');

			case 'order':
			case 'admin.standardTemplates.availability.order':
				return t('default.order');

			case 'client':
				return t('default.contactAndClient');

			case 'agreement':
				return t('default.agreement');

			case 'object1':
				return this.getUdoName(1);

			case 'object2':
				return this.getUdoName(2);

			case 'object3':
				return this.getUdoName(3);

			case 'object4':
				return this.getUdoName(4);
		}
	},

	deleteTemplate: function (item) {
		return this.props.rootData.onSave('delete', item);
	},

	// Remove once 'NEW_CUSTOM_DOCUMENT_TEMPLATES' is implemented
	getSortAttr: function (item) {
		if (this.state.sort === 'type') {
			return this.switchType(item.type);
		}
		return item[this.state.sort];
	},
	//

	getSortAttrStandard: function (item, sort) {
		switch (sort) {
			case 'type':
				return this.switchType(item.availability);
			case 'createdBy':
				return (item.templateData.template_details.createdBy || '').toLowerCase();
			case 'lastModified':
				return moment(item.templateData.template_details.lastModified);
			case 'active':
				return item.active;
			default:
				return item.templateData.template_name.toLowerCase();
		}
	},

	getSortAttrYourTemplates: function (item) {
		if (this.state.sortCustomer === 'type') {
			return this.switchType(item.type);
		}

		return item[this.state.sortCustomer].toLowerCase();
	},

	buildCustomerTemplateTableRows: function (templates) {
		const self = this;
		const tools = self.props.tools;

		let rows = [...templates].sort((item1, item2) => {
			if (this.state.sortCustomer === 'type') {
				if (this.switchType(item1.type) > this.switchType(item2.type)) {
					return 1;
				} else if (this.switchType(item1.type) < this.switchType(item2.type)) {
					return -1;
				}
				return 0;
			} else {
				if (item1.name.toLowerCase() > item2.name.toLowerCase()) {
					return 1;
				} else if (item1.name.toLowerCase() < item2.name.toLowerCase()) {
					return -1;
				}
				return 0;
			}
		});

		if (this.state.descCustomer) {
			rows = rows.reverse();
		}

		rows = rows.map(function (templateItem) {
			const rolesString = <ReactTemplates.admin.tableTokens items={templateItem.roles} defaultText="all" />;
			const url = tools.URL + tools.API + 'documentTemplates/download/' + templateItem.id;
			return (
				<TableRow
					key={templateItem.id + '-' + templateItem.name}
					className="document-row admin-table-row"
					onClick={() => {
						self.editDocumentTemplate(templateItem);
					}}
				>
					<TableColumn className="document-cell-ellipsis" style={{ maxWidth: '280px' }}>
						{templateItem.name}
					</TableColumn>
					{self.hasRoleFeature ? (
						<td className="document-cell-ellipsis" style={{ maxWidth: '150px' }}>
							{rolesString}
						</td>
					) : null}
					<TableColumn className="document-cell-ellipsis" style={{ maxWidth: '100px' }}>
						{self.switchType(templateItem.type)}
					</TableColumn>
					<td className="TableColumn TableColumn--align-right admin-row-tools-wrap">
						<div className="admin-row-tools">
							<Tooltip title={self.lang.downloadTemplate} position="top" delay={100}>
								<Button
									type="link"
									color="grey"
									onClick={e => {
										e.stopPropagation();
										window.location.href = url;
									}}
								>
									<Icon name="download" />
								</Button>
							</Tooltip>

							<InlineConfirm
								show
								onConfirm={() => self.deleteTemplate(templateItem)}
								entity="default.template"
								btnTex="default.template"
							>
								<Tooltip title={self.lang.delete} position="top" delay={100}>
									<Button
										data-testid={
											'delete-customer-template-' + templateItem.id + '-' + templateItem.name
										}
										type="link"
										color="grey"
										className="row-tool"
									>
										<Icon name="trash" />
									</Button>
								</Tooltip>
							</InlineConfirm>
						</div>
					</td>
				</TableRow>
			);
		});

		return rows;
	},

	// Remove once NEW_CUSTOM_DOCUMENT_TEMPLATES has been implemented
	buldTableRows: function (templates) {
		var self = this;
		var tools = self.props.tools;

		var rows = _.chain(templates);

		rows = rows.sortBy(this.getSortAttr);

		if (this.state.desc && this.state.sortingTable === 'customerTemplates') {
			rows = rows.reverse();
		}

		rows = rows.map(function (item) {
			var onClick = self.editDocumentTemplate.bind(self, item);

			const rolesString = <ReactTemplates.admin.tableTokens items={item.roles} />;
			var url = tools.URL + tools.API + 'documentTemplates/download/' + item.id;

			return (
				<tr key={item.id + '-' + item.name} className="document-row admin-table-row" onClick={onClick}>
					<td className="document-cell-ellipsis" style={{ maxWidth: '280px' }}>
						{item.name}
					</td>
					{self.hasRoleFeature ? (
						<td className="document-cell-ellipsis" style={{ maxWidth: '150px' }}>
							{rolesString}
						</td>
					) : null}
					<td className="document-cell-ellipsis" style={{ maxWidth: '100px' }}>
						{self.switchType(item.type)}
					</td>
					<td className="admin-row-tools-wrap document-cell-tools document-templates-custom">
						<div className="admin-row-tools">
							{ReactTemplates.TOOLS.withTooltip(
								<div className="icon-wrap" onClick={ReactTemplates.TOOLS.stopProp}>
									<a href={url} download="template.doc">
										<i className="fa fa-file" />
									</a>
								</div>,
								self.lang.downloadTemplate,
								{ placement: 'left', delayShow: 200 }
							)}
							<InlineConfirm
								show
								onConfirm={() => self.deleteTemplate(item)}
								keepTabPosition={true}
								dropdownPosition="top"
								entity={'default.template'}
								btnTex={'default.template'}
							>
								<Button type="link" color="grey" className="row-tool">
									<Icon name="trash" />
								</Button>
							</InlineConfirm>
						</div>
					</td>
				</tr>
			);
		});
		return rows.value();
	},
	//

	editDocumentTemplate: function (documentTemplate) {
		const self = this;
		const $upModal = self.props.tools.$upModal;
		$upModal
			.open('editDocumentTemplate', {
				documentTemplate: documentTemplate
			})
			.then(function (res) {
				if (res && res.id) {
					if (documentTemplate && documentTemplate.id) {
						self.props.rootData.onSave('update', res);
					} else {
						self.props.rootData.onSave('create', res);
					}
				}
			})
			.catch(function (error) {
				console.log('modal closed with error', error);
			});
	},

	editRoles: function (rowId) {
		this.setState({ roleEditRowId: rowId });
	},

	openEditor: function (template = null) {
		const self = this;

		if (!template) {
			template = this.state.allTemplates.find(template => template.isSkeleton);
		}
		openTemplateEditorModal({
			documentTemplate: template,
			standardTemplates: this.state.allTemplates.filter(template => template.type === 'standard')
		}).catch(function () {
			self.initializeTemplates();
		});
	},

	changeActiveStatus: function (templateItem, allTemplates, saveTemplateActivation, sort, desc) {
		const self = this;
		const updatedItem = allTemplates.find(template => template.id === templateItem.id);

		updatedItem.active = !updatedItem.active;
		saveTemplateActivation(templateItem, updatedItem.active, templateItem.type);
		const templatesList = self.buildStandardTemplateTableRows(
			allTemplates,
			self.props.rootData.saveTemplateActivation,
			self.props.rootData.retrievePdf,
			sort,
			desc,
			self.state.sortingTable
		);
		self.setState({
			templatesLoaded: true,
			templatesList: templatesList
		});
	},

	// Remove once NEW_CUSTOM_DOCUMENT_TEMPLATES has been implemented
	saveUserRoles: function (value, template, filterBy) {
		const self = this;
		this.props.rootData
			.saveTemplateRoles(template, value, filterBy)
			.then(() => {
				self.initializeTemplates();
			})
			.catch(function (e) {
				logError(e, 'Error occured while saving roles');
			});
	},
	//

	deletePdfTemplate: function (item) {
		const self = this;
		this.props.rootData
			.deleteTemplate(item)
			.then(() => {
				self.initializeTemplates();
			})
			.catch(function (e) {
				logError(e, 'Error occured while deleting roles');
			});
	},

	setSort: function (sort, tableName) {
		if (this.hasNewCustomDocumentTemplates) {
			if (tableName === 'standardTemplates') {
				let desc = sort === this.state.sortStandard ? !this.state.descStandard : this.state.descStandard;

				// Remove once 'NEW_CUSTOM_DOCUMENT_TEMPLATES' is implemented
				if (!this.hasNewCustomDocumentTemplates) {
					desc = sort === this.state.sort ? !this.state.desc : this.state.desc;
				}
				//

				this.setState({
					// Remove once 'NEW_CUSTOM_DOCUMENT_TEMPLATES' is implemented
					sort: sort,
					desc: desc,
					//
					sortStandard: sort,
					descStandard: desc,
					sortingTable: tableName,
					templatesList: this.buildStandardTemplateTableRows(
						this.state.allTemplates,
						this.props.rootData.saveTemplateActivation,
						this.props.rootData.retrievePdf,
						sort,
						desc,
						tableName
					)
				});
			} else if (tableName === 'customerTemplates') {
				const desc = sort === this.state.sortCustomer ? !this.state.descCustomer : this.state.descCustomer;
				this.setState({
					sortCustomer: sort,
					descCustomer: desc,
					sortingTable: tableName
				});
			}
		} else {
			// Remove once 'NEW_CUSTOM_DOCUMENT_TEMPLATES' is implemented
			this.setState({
				sort: sort,
				desc: sort === this.state.sort ? !this.state.desc : this.state.desc,
				sortingTable: tableName
			});
			//
		}
	},

	renderTh: function (title, sortable, tableName) {
		if (!sortable) {
			return <th>{self.lang.name}</th>;
		}

		let sort;
		let desc;

		if (tableName === 'standardTemplates') {
			sort = this.state.sortStandard;
			desc = this.state.descStandard;
		} else if (tableName === 'customerTemplates') {
			sort = this.state.sortCustomer;
			desc = this.state.descCustomer;
		}

		// Remove once 'NEW_CUSTOM_DOCUMENT_TEMPLATES' is implemented
		if (!this.hasNewCustomDocumentTemplates) {
			sort = this.state.sort;
			desc = this.state.desc;
		}
		//

		return (
			<>
				{
					this.hasNewCustomDocumentTemplates ? (
						<th
							data-testid={'button-sort-by-' + sortable + '-' + tableName}
							className={'sortable' + (sort === sortable ? ' active' : '')}
							onClick={this.setSort.bind(null, sortable, tableName)}
							style={sortable === 'name' ? { width: '25%' } : { width: '14%' }}
						>
							{title}{' '}
							{sort === sortable ? (
								sortable === 'lastModified' ? (
									<b className={desc ? 'fa fa-sort-numeric-asc' : 'fa fa-sort-numeric-desc'} />
								) : (
									<b className={desc ? 'fa fa-sort-alpha-desc' : 'fa fa-sort-alpha-asc'} />
								)
							) : (
								<b className="fa fa-sort" />
							)}
						</th>
					) : (
						// Remove once 'NEW_CUSTOM_DOCUMENT_TEMPLATES' is implemented
						<th
							data-testid={'button-sort-by-' + sortable + '-' + tableName}
							className={'sortable' + (this.state.sort === sortable ? ' active' : '')}
							onClick={this.setSort.bind(null, sortable, tableName)}
						>
							{title}{' '}
							{this.state.sort === sortable ? (
								<b
									className={
										this.state.desc && this.state.sortingTable === tableName
											? 'fa fa-sort-alpha-desc'
											: 'fa fa-sort-alpha-asc'
									}
								/>
							) : (
								<b className="fa fa-sort" />
							)}
						</th>
					)
					//
				}
			</>
		);
	},

	// Remove once NEW_CUSTOM_DOCUMENT_TEMPLATES has been implemented
	openEditRoles: function (filterBy, id) {
		let customTemplatesList,
			standardTemplatesList = [];
		this.state.allTemplates.map(item => {
			if (item.id === id) {
				item.editing = true;
			}
			return item;
		});

		if (filterBy === 'custom') {
			customTemplatesList = this.oldBuildStandardTemplateTableRows(
				this.state.allTemplates,
				this.props.rootData.saveTemplateActivation,
				this.props.rootData.saveTemplateRoles,
				this.props.rootData.retrievePdf,
				this.props.rootData.pageData.templates,
				'custom'
			);
			this.setState({
				customTemplatesList: customTemplatesList
			});
		}

		if (filterBy === 'standard') {
			standardTemplatesList = this.oldBuildStandardTemplateTableRows(
				this.state.allTemplates,
				this.props.rootData.saveTemplateActivation,
				this.props.rootData.saveTemplateRoles,
				this.props.rootData.retrievePdf,
				this.props.rootData.pageData.templates,
				'standard'
			);
			this.setState({
				standardTemplatesList: standardTemplatesList
			});
		}
	},
	//

	compareItems: function (item1, item2, sort) {
		const sortAttr1 = this.getSortAttrStandard(item1, sort);
		const sortAttr2 = this.getSortAttrStandard(item2, sort);

		if (sort === 'lastModified') {
			if (sortAttr1.isBefore(sortAttr2)) {
				return 1;
			} else {
				return -1;
			}
		} else if (sort === 'active') {
			if (sortAttr1 < sortAttr2) {
				return 1;
			} else if (sortAttr1 > sortAttr2) {
				return -1;
			}
			return 0;
		} else {
			if (sortAttr1 > sortAttr2) {
				return 1;
			} else if (sortAttr1 < sortAttr2) {
				return -1;
			}
			return 0;
		}
	},

	buildStandardTemplateTableRows: function (
		templates,
		saveTemplateActivation,
		retrievePdf,
		sort,
		desc,
		sortingTable
	) {
		const self = this;
		const $upModal = self.props.tools.$upModal;

		let rows = [...templates].sort((item1, item2) => this.compareItems(item1, item2, sort));
		if (desc && sortingTable === 'standardTemplates') {
			rows.reverse();
		}
		const roles = Tools.AppService.getRoles();
		rows = rows.map(function (templateItem) {
			const mappedRoles = templateItem.roles.flatMap(templateRole =>
				roles.filter(role => templateRole.roleId === role.id)
			);
			const activeStatus = templateItem.active;
			const roleString = <ReactTemplates.admin.tableTokens items={mappedRoles} defaultText="all" />;
			return (
				<TableRow
					key={templateItem.uuid + '-' + templateItem.templateData.template_name}
					className="document-row"
					onClick={e => {
						e.stopPropagation();
						if (!templateItem.isEditorFormat) {
							// If the template is created with the old HTML editor we will open the old editor. We cannot interpret pure HTML into the new editor format in all cases.
							self.openEditor(templateItem);
						} else {
							history.push(`/document-template-editor/${templateItem.uuid}`);
						}
					}}
				>
					<TableColumn className="document-cell-ellipsis" style={{ maxWidth: '280px' }}>
						<EllipsisTooltip title={templateItem.templateData.template_name}>
							<Text>{templateItem.templateData.template_name}</Text>
						</EllipsisTooltip>
					</TableColumn>
					<TableColumn style={{ maxWidth: '280px' }}>
						{templateItem.templateData.template_details.createdBy}
					</TableColumn>
					<TableColumn style={{ maxWidth: '100px' }}>
						{moment(templateItem.templateData.template_details.lastModified).format('YYYY-MM-DD')}
					</TableColumn>
					{self.hasRoleFeature ? (
						<TableColumn data-testid={'role-' + templateItem.uuid}>{roleString}</TableColumn>
					) : null}
					<TableColumn style={{ maxWidth: '100px' }}>{t(templateItem.availability)}</TableColumn>
					<TableColumn style={{ maxWidth: '100px' }}>
						<div className="checkbox-holder">
							<Checkbox
								data-testid={'active-template-button-' + templateItem.uuid}
								key={templateItem.uuid + '-checkbox'}
								checked={activeStatus}
								onClick={e => {
									e.stopPropagation();
									self.changeActiveStatus(
										templateItem,
										templates,
										saveTemplateActivation,
										sort,
										desc
									);
								}}
							/>
						</div>
					</TableColumn>
					<TableColumn className="TableColumn--align-right admin-row-tools-wrap">
						<div className="admin-row-tools">
							<ButtonGroup>
								<Button
									data-testid={'preview-template-button' + '-' + templateItem.uuid}
									type="link"
									color="grey"
									className="row-tool preview-tool"
									onClick={e => {
										e.stopPropagation();
										retrievePdf(
											templateItem.uuid,
											$upModal,
											templateItem.templateData.template_name,
											templateItem.templateData.template_details.dataJson,
											templateItem.type,
											templateItem.templateData.template_details.language
										);
									}}
								>
									<Tooltip
										data-testid={'preview-template-tooltip' + '-' + templateItem.uuid}
										title={self.lang.preview}
										position="top"
										delay={100}
									>
										<Icon name="eye" />
									</Tooltip>
								</Button>
								{templateItem.type === 'custom' ? (
									<InlineConfirm
										show={true}
										onConfirm={() => Promise.resolve(self.deletePdfTemplate(templateItem))}
										entity={'default.template'}
										btnTex={'default.template'}
									>
										<Button
											data-testid={'delete-standard-template-' + templateItem.uuid}
											type="link"
											color="grey"
											className="row-tool"
										>
											<Tooltip title={self.lang.delete} position="top" delay={100}>
												<Icon name="trash" />
											</Tooltip>
										</Button>
									</InlineConfirm>
								) : (
									<></>
								)}
							</ButtonGroup>
						</div>
					</TableColumn>
				</TableRow>
			);
		});
		return rows;
	},

	// Remove once NEW_CUSTOM_DOCUMENT_TEMPLATES has been implemented
	oldBuildStandardTemplateTableRows: function (
		templates,
		activationFunc,
		saveRolesFunc,
		retrievePdf,
		cutomtemplates,
		filterBy
	) {
		const self = this;
		var rows = _.chain(templates).filter(template => template.type === filterBy);
		const $upModal = self.props.tools.$upModal;

		rows = rows.sortBy(this.getSortAttr);
		if (this.state.desc && this.state.sortingTable === 'standardTemplates') {
			rows = rows.reverse();
		}
		var roles = Tools.AppService.getRoles();
		rows = rows.map(function (item) {
			const mappedRoles = [];
			item.roles.forEach(templateRole => {
				roles.forEach(role => {
					if (templateRole.roleId === role.id) {
						mappedRoles.push(role);
					}
				});
			});

			const activeStatus = item.type === 'standard' ? item.active : item.templateData.published === 'true';

			const roleString = <ReactTemplates.admin.tableTokens items={mappedRoles} />;

			return (
				<tr key={item.uuid + '-' + item.templateData.template_name} className="document-row admin-table-row">
					<td
						className="document-cell-ellipsis"
						style={{ maxWidth: '280px' }}
						{...(filterBy === 'custom' && { onClick: self.openEditor.bind(self, item) })}
					>
						{item.templateData.template_name}
					</td>
					{self.hasRoleFeature ? (
						<td className="document-cell-ellipsis">
							<div style={{ display: item.editing ? 'block' : 'none' }}>
								<ReactTemplates.INPUTS.upRoles
									className="form-control"
									data={roles}
									value={mappedRoles}
									multiple={true}
									placeholder={self.lang.addRoles}
									onChange={value => self.saveUserRoles(value, item, filterBy)}
								/>
							</div>
							<div style={{ display: !item.editing ? 'block' : 'none' }}>
								<div onClick={() => self.openEditRoles(filterBy, item.id)}>{roleString}</div>
							</div>
						</td>
					) : null}
					<td className="document-cell-ellipsis" style={{ maxWidth: '100px' }}>
						{t(item.availability)}
					</td>
					<td className="document-cell-ellipsis" style={{ maxWidth: '100px' }}>
						<div className="checkbox-holder">
							<ReactTemplates.checkbox
								checked={activeStatus}
								onChange={value => activationFunc(item, value, filterBy)}
							/>
						</div>
					</td>

					<td className="admin-row-tools-wrap document-cell-tools document-templates-custom">
						<div className="admin-row-tools">
							{filterBy === 'standard' ? (
								ReactTemplates.TOOLS.withTooltip(
									<div className="icon-wrap" onClick={ReactTemplates.TOOLS.stopProp}>
										<i
											className="fa fa-eye"
											onClick={() =>
												retrievePdf(
													item.uuid,
													$upModal,
													item.templateData.template_name,
													item.templateData.template_details.dataJson,
													item.type
												)
											}
										/>
									</div>,
									self.lang.preview,
									{ placement: 'left', delayShow: 200 }
								)
							) : (
								<React.Fragment>
									<Button
										type="link"
										color="grey"
										className="row-tool"
										onClick={self.openEditor.bind(self, item)}
									>
										<Icon name="edit" />
									</Button>
									<InlineConfirm
										show
										onConfirm={() => Promise.resolve(self.deletePdfTemplate(item))}
										entity={'default.template'}
										btnTex={'default.template'}
									>
										<Button type="link" color="grey" className="row-tool">
											<Icon name="trash" />
										</Button>
									</InlineConfirm>
								</React.Fragment>
							)}
						</div>
					</td>
				</tr>
			);
		});
		return rows.value();
	},

	initializeTemplates: function () {
		this.props.rootData
			.getrows()
			.then(res => {
				this.setState({ allTemplates: res });
				const templates = res.filter(template => !template.isSkeleton);
				if (this.hasNewCustomDocumentTemplates) {
					const templatesList = this.buildStandardTemplateTableRows(
						templates,
						this.props.rootData.saveTemplateActivation,
						this.props.rootData.retrievePdf,
						this.state.sortStandard,
						this.state.descStandard,
						this.state.sortingTable
					);
					this.setState({
						templatesLoaded: true,
						templatesList: templatesList
					});
				} else {
					// This whole else block should be removed once reworked
					const standardTemplatesList = this.oldBuildStandardTemplateTableRows(
						templates,
						this.props.rootData.saveTemplateActivation,
						this.props.rootData.saveTemplateRoles,
						this.props.rootData.retrievePdf,
						this.props.rootData.pageData.templates,
						'standard'
					);
					const customTemplatesList = this.oldBuildStandardTemplateTableRows(
						templates,
						this.props.rootData.saveTemplateActivation,
						this.props.rootData.saveTemplateRoles,
						this.props.rootData.retrievePdf,
						this.props.rootData.pageData.templates,
						'custom'
					);

					this.setState({
						standardTemplatesLoaded: true,
						standardTemplatesList: standardTemplatesList,
						customTemplatesList: customTemplatesList
					});
				}
			})
			.catch(function (error) {
				console.log('Could not load templates', error);
			});
	},

	render: function () {
		const self = this;
		const tools = this.props.tools;
		const customerId = this.props.tools.AppService.getCustomerId();
		const downloadUrl = tools.URL + tools.API + customerId + '/files/static';
		let language = '';
		let tags = null;
		let keys = [];

		if (Tools.AppService.getSelf().language !== 'sv-SE') {
			language += '_en';
		}

		const standardTemplatesRaw = [
			{
				name: self.lang.exampleLetter,
				downloadLink: downloadUrl + '?path=documenttemplate/letter' + language + '.doc'
			},
			{
				name: self.lang.exampleProposal,
				downloadLink: downloadUrl + '?path=documenttemplate/proposal' + language + '.doc'
			}
		];

		if (this.state.showTags) {
			tags = this.createTagList();
		}

		const standardTemplates = this.generateTemplatesHtml(standardTemplatesRaw);
		if (this.hasNewCustomDocumentTemplates) {
			keys = this.buildCustomerTemplateTableRows(this.props.rootData.pageData.templates);
		} else {
			keys = this.buldTableRows(this.props.rootData.pageData.templates);
		}
		return (
			<div
				id="admin-page-doctemplates"
				data-testid="admin-page-doctemplates"
				className={this.hasNewCustomDocumentTemplates ? 'document-templates-table' : ''}
			>
				<AdminHeader
					title={this.lang.title}
					description={this.lang.desc}
					image="document-templates.svg"
					articleId={592}
				/>
				<div id="admin-content">
					{this.hasNewCustomDocumentTemplates ? null : (
						<div className="admin-table" data-testid="standard-template-table-test">
							<div className="admin-table-top">
								<Title size="lg">{this.lang.standardTemplateTitle}</Title>
							</div>
							<table>
								<thead>
									<tr>
										{this.renderTh(self.lang.name, 'name', 'standardTemplates')}
										{this.hasRoleFeature ? <th>{self.lang.permissionToRole}</th> : null}
										{this.renderTh(this.lang.type, 'type', 'standardTemplates')}
										<th>{this.lang.active} </th>
										<th style={{ width: '120px' }} />
									</tr>
								</thead>
								<tbody>{this.state.standardTemplatesList}</tbody>
							</table>
						</div>
					)}

					<div className="admin-table">
						<div className="admin-table-top">
							<Title size="lg">{this.lang.customTemplateTitle}</Title>
							<div className="pull-right">
								{this.hasNewCustomDocumentTemplates ? (
									<Button
										data-testid="new-create-custom-template"
										onClick={e => {
											e.stopPropagation();
											history.push('/document-template-editor/new');
										}}
									>
										<Icon name="plus" style={{ marginRight: '8px' }} />
										{this.lang.addNewDocTemplate}
									</Button>
								) : (
									<Button data-testid="create-custom-template" onClick={() => this.openEditor(false)}>
										{this.lang.addCustomTemplate}
									</Button>
								)}
							</div>
						</div>
						<table>
							<thead>
								<tr>
									{/* Remove conditional based on 'hasNewCustomDocumentTemplates' when it is fully implemented */}
									{this.renderTh(this.lang.name, 'name', 'standardTemplates')}
									{this.hasNewCustomDocumentTemplates
										? this.renderTh(this.lang.createdBy, 'createdBy', 'standardTemplates')
										: null}
									{this.hasNewCustomDocumentTemplates
										? this.renderTh(this.lang.lastUpdated, 'lastModified', 'standardTemplates')
										: null}
									{this.hasRoleFeature && this.hasNewCustomDocumentTemplates
										? this.renderTh(this.lang.availableFor, 'roles', 'standardTemplates')
										: null}
									{
										this.hasRoleFeature && !this.hasNewCustomDocumentTemplates ? (
											<th>{this.lang.permissionToRole}</th>
										) : null /* Remove once NEW_CUSTOM_DOCUMENT_TEMPLATES has been implemented*/
									}
									{this.renderTh(this.lang.type, 'type', 'standardTemplates')}
									{this.hasNewCustomDocumentTemplates ? (
										this.renderTh(this.lang.active, 'active', 'standardTemplates')
									) : (
										<th>{this.lang.active}</th>
									)}
								</tr>
							</thead>
							{this.hasNewCustomDocumentTemplates ? (
								<tbody data-testid="templates-list-test">{this.state.templatesList}</tbody>
							) : (
								<tbody data-testid="custom-templates-list-test">{this.state.customTemplatesList}</tbody>
							)}
						</table>
						{(this.hasNewCustomDocumentTemplates && this.state.templatesList.length === 0) ||
						(!this.hasNewCustomDocumentTemplates && this.state.customTemplatesList.length === 0) ? (
							<span>
								<Row style={{ paddingTop: '30px' }}>
									<Column size={12} align="center">
										<img src="img/empty-briefcase.png" />
									</Column>
								</Row>
								<Row>
									<Column size={12} align="center">
										<Text size="lg" style={{ padding: '30px 0' }}>
											{this.lang.noCustomTemplate}
											<Link
												nostyle
												onClick={() => {
													if (this.hasNewCustomDocumentTemplates) {
														history.push('/document-template-editor/new');
													} else {
														this.openEditor(false);
													}
												}}
											>
												{this.lang.addCustomTemplateLInk}
											</Link>
										</Text>
									</Column>
								</Row>
							</span>
						) : null}
					</div>

					<div className="admin-table">
						<div className="admin-table-top" style={{ height: '70px' }}>
							<Title size="lg">{self.lang.howItWorks}</Title>
						</div>
						<div className="admin-info-row">
							<div className="info-row-content">
								{standardTemplates}
								<div>
									<Button onClick={self.toggleTags}>
										{self.state.showTags ? self.lang.hideTagsListLink : self.lang.showTagsListLink}
									</Button>
									<ReactTemplates.elevio sidebar={true} articleId={592}>
										<Button color="bright-blue" type="link">
											{self.lang.readMoreAboutTags}
										</Button>
									</ReactTemplates.elevio>
								</div>
							</div>
						</div>
						{tags}
					</div>

					<div className="admin-table">
						<div className="admin-table-top">
							<Title size="lg">{self.lang.uploadedTemplates}</Title>
							<div className="pull-right">
								<Button onClick={() => self.editDocumentTemplate(null)} size="md">
									{self.lang.uploadTemplate}
								</Button>
							</div>
						</div>

						<table>
							<thead>
								<tr>
									{this.renderTh(this.lang.name, 'name', 'customerTemplates')}
									{this.hasRoleFeature && this.hasNewCustomDocumentTemplates ? (
										<th>{self.lang.availableFor}</th>
									) : null}
									{
										this.hasRoleFeature && !this.hasNewCustomDocumentTemplates ? (
											<th>{self.lang.permissionToRole}</th>
										) : null /* Remove once NEW_CUSTOM_DOCUMENT_TEMPLATES has been implemented*/
									}
									{this.renderTh(this.lang.type, 'type', 'customerTemplates')}
									<th style={{ width: '120px' }} />
								</tr>
							</thead>
							<tbody data-testid="customer-templates-list-test">{keys}</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
});
