import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
	Toggle,
	Flex,
	Button,
	Link,
	Label,
	Text,
	Block,
	ButtonSelect,
	AssistChip,
	Avatar,
	Tooltip,
	Icon
} from '@upsales/components';
import BillingAddSeatDrawer from '../../Billing/BillingAddSeatDrawer';
import { retrieveData } from 'Store/reducers/BillingReducer';
import { loadSingleSignOn } from 'Store/reducers/SingleSignOnReducer';
import store from 'Store';
import { connect } from 'react-redux';
import Breadcrumbs, { BreadcrumbItem } from 'App/upsales/common/components/react/Breadcrumbs';
import { DangerButton } from '@upsales/components/Buttons';
import openModal from 'App/services/Modal';
import LicenseTypeSettings from './LicenseTypeSettings/LicenseTypeSettings';
import LanguageSelect from 'App/components/Inputs/Selects/LanguageSelect';
import CountrySelect from 'App/components/Inputs/Selects/CountrySelect';
import { RoleSelectSingle } from 'Components/RoleSelect/RoleSelect';

const mapStateToProps = ({ Billing, SingleSignOn }) => ({
	invoiceSubscriptions: Billing.invoiceSubscriptions,
	singleSignOnEnabled: SingleSignOn.enabled
});

class EditUser extends Component {
	static propTypes = {
		tools: PropTypes.object,
		rootData: PropTypes.shape({
			pageData: PropTypes.shape({
				loggedInUserIsBillingAdmin: PropTypes.bool,
				user: PropTypes.shape({
					userParams: PropTypes.shape({
						dnbCountryCodes: PropTypes.string
					}),
					id: PropTypes.number
				}),
				customFieldChange: PropTypes.func.isRequired,
				userFieldChange: PropTypes.func.isRequired,
				userParamChange: PropTypes.func.isRequired,
				setStatus: PropTypes.func.isRequired,
				setEmails: PropTypes.func.isRequired,
				emailRegex: PropTypes.instanceOf(RegExp).isRequired,
				emails: PropTypes.arrayOf(PropTypes.string),
				noLicences: PropTypes.bool,
				roleWasActive: PropTypes.bool,
				setNoLicenses: PropTypes.func.isRequired,
				setAddSeatDrawerVisible: PropTypes.func.isRequired
			})
		}),
		invoiceSubscriptions: PropTypes.array.isRequired,
		singleSignOnEnabled: PropTypes.bool.isRequired
	};

	constructor(props) {
		super(props);
		store.dispatch(retrieveData());
		store.dispatch(loadSingleSignOn());
		this.wrapperRef = React.createRef();
		this.removeProfileImage = this.removeProfileImage.bind(this);
		this.fetchProfileImage = this.fetchProfileImage.bind(this);
	}

	state = {
		accessExpanded: false,
		originallyInactive: null,
		profileImage: null,
		hasFetchedImage: false,
		deleteImageLoading: false
	};

	_form = {};

	lang = {
		addOne: Tools.$translate('admin.addOne'),
		addMultiple: Tools.$translate('admin.addMultiple'),
		email: Tools.$translate('default.email'),
		name: Tools.$translate('admin.userName'),
		userTitle: Tools.$translate('default.title'),
		enterEmail: Tools.$translate('default.enterEmail'),
		enterEmails: Tools.$translate('default.enterEmails'),
		add: Tools.$translate('default.add'),
		langAndRegion: Tools.$translate('admin.langAndRegion'),
		language: Tools.$translate('default.language'),
		mainMarket: Tools.$translate('admin.mainMarket'),
		selectRole: Tools.$translate('admin.selectRole'),
		role: Tools.$translate('default.role'),
		administrator: Tools.$translate('default.administrator'),
		billingAdmin: Tools.$translate('admin.billingAdmin'),
		moreAccessSettings: Tools.$translate('admin.moreAccessSettings'),
		lessAccessSettings: Tools.$translate('admin.lessAccessSettings'),
		access: Tools.$translate('admin.access'),
		teamLeader: Tools.$translate('admin.teamLeader'),
		exportAccess: Tools.$translate('admin.exportAccess'),
		multiSoliditetAccess: Tools.$translate('admin.multiSoliditetAccess'),
		mailAdmin: Tools.$translate('admin.mailAdmin'),
		usersAndRoles: Tools.$translate('admin.usersAndRoles'),
		user: Tools.$translate('default.user'),
		editUser: Tools.$translate('admin.editUser'),
		addMultiUsersText: Tools.$translate('admin.addMultiUsersText'),
		userStatus: Tools.$translate('admin.userStatus'),
		rolesAndAccess: Tools.$translate('admin.rolesAndAccess'),
		status: {
			active: Tools.$translate('default.active'),
			inactive: Tools.$translate('default.inactive'),
			removed: Tools.$translate('admin.removed'),
			inactiveDescription1: Tools.$translate('admin.inactive.description1'),
			inactiveDescription2: Tools.$translate('admin.inactive.description2').toLowerCase()
		},
		userRoleSelectInfo: Tools.$translate('admin.userRoleSelectInfo'),
		userAccessInfo: Tools.$translate('admin.userAccessInfo'),
		userLanguageAndRegionInfo: Tools.$translate('admin.userLanguageAndRegionInfo'),
		userStatusToggleInfo: Tools.$translate('admin.userStatusToggleInfo'),
		tooManyUsers: Tools.$translate('admin.tooManyUsers'),
		activateUser: Tools.$translate('admin.tooManyUsers.activateUser'),
		activateUsers: Tools.$translate('admin.tooManyUsers.activateUsers'),
		firstLastName: Tools.$translate('default.firstLastName'),
		cellPhone: Tools.$translate('default.cellPhone'),
		twofaWarning: Tools.$translate('admin.userTwofaWarning'),
		userOtherFields: Tools.$translate('admin.profile.other.fields'),
		userOtherFieldInfo: Tools.$translate('admin.userOtherFieldInfo'),
		signalsToggle: Tools.$translate('admin.prospecting.signals.permissionToToggle'),
		visibleFilters: Tools.$translate('admin.visibleInFilters'),
		userImageBlockTitle: Tools.$translate('admin.userImageBlock.title'),
		userImageBlockDelete: Tools.$translate('admin.userImageBlock.delete'),
		userImageBlockDeleteTitle: Tools.$translate('admin.userImageBlock.delete.title'),
		userImageBlockDeleteDescription: Tools.$translate('admin.userImageBlock.delete.description'),
		addMultipleUsers: Tools.$translate('admin.addMultipleUsers.tooltip')
	};

	componentDidMount() {
		this.fetchProfileImage();
	}

	fetchProfileImage() {
		// get file find for profile image
		if (this.props.rootData?.pageData?.user?.id && !this.state.hasFetchedImage) {
			Tools.File.find({ entityId: this.props.rootData?.pageData?.user?.id, entity: 'ProfileImage' })
				.then(res => {
					const newState = { hasFetchedImage: true };
					if (res && res.data && res.data.length > 0) {
						newState.profileImage = res.data[0];
					}
					this.setState(newState);
				})
				.catch(error => {
					this.setState({ hasFetchedImage: true });
					console.error('Error fetching profile image:', error);
				});
		}
	}

	componentDidUpdate() {
		this.setEmailSelect2();
		this.fetchProfileImage();

		_.each(Object.keys(this._form), key => {
			if (this._form[key] && this._form[key].value !== this.props.rootData.pageData.user[key]) {
				this._form[key].value = this.props.rootData.pageData.user[key];
			}
		});
	}

	// eslint-disable-next-line camelcase
	UNSAFE_componentWillUpdate(nextProps) {
		// If we no longer on multi and we have select2
		if (!nextProps.rootData.pageData.multi && this._multiSelect) {
			jQuery(this._multiSelect).select2('destroy');
		}
	}

	setEmailSelect2 = () => {
		if (this._multiSelect) {
			jQuery(this._multiSelect)
				.select2({
					tags: [],
					simple_tags: true,
					dropdownCssClass: 'admin-user-add-multi-select',
					formatResult: obj => {
						return '<b class="fa fa-check"></b> ' + this.lang.add + ': ' + _.escape(obj.text);
					},
					createSearchChoice: term => {
						if (this.props.rootData.pageData.emailRegex.test(term)) {
							return { id: term, text: term };
						} else {
							return null;
						}
					},
					formatNoMatches: () => {
						return this.lang.enterEmail;
					}
				})
				.on('change', e => {
					this.props.rootData.pageData.setEmails(e.val);
				});

			var select2 = jQuery(this._multiSelect).data('select2');
			var searchInput = select2.search;
			searchInput.on('keyup keydown', this.toggleMultiFloatingLable);
		}
	};

	toggleMultiFloatingLable = e => {
		if (
			(e.type === 'keydown' && [8, 27].indexOf(e.keyCode) === -1) ||
			e.target.value.length ||
			this.props.rootData.pageData.emails.length
		) {
			this._multiFloatLabel.classList.add('has-value');
		} else {
			this._multiFloatLabel.classList.remove('has-value');
		}
	};

	userFieldChange = (key, e) => {
		this.props.rootData.pageData.userFieldChange(key, e.target.value);
	};

	renderUserField = (key, label, type, large, maxLength) => {
		var pageData = this.props.rootData.pageData;
		var user = pageData.user;
		var classNames = 'floating-label-input';
		if (user[key] && user[key].length > 0) {
			classNames += ' has-value';
		}
		if ((pageData.submitted || pageData.fieldTouched[key]) && pageData.errors[key]) {
			classNames += ' has-error';
		}
		if (large) {
			classNames += ' floating-label-lg';
		}
		return (
			<div key={key} id={key + '-input'} className={classNames}>
				<label>{label}</label>
				<input
					type={type}
					maxLength={maxLength ? maxLength : undefined}
					defaultValue={user[key]}
					onChange={e => {
						this.userFieldChange(key, e);
					}}
					onBlur={() => {
						pageData.setFieldTouched(key);
					}}
					ref={r => {
						this._form[key] = r;
					}}
				/>
			</div>
		);
	};

	toggleAccessExpanded = () => {
		this.setState({ accessExpanded: !this.state.accessExpanded });
	};

	renderToggle = (label, key, isUserParam, articleId, disabled, alwaysEnabled, size = 'sm') => {
		return (
			<div className="half-column toggle-row">
				<label className="toggle-label">
					{label} {articleId ? <ReactTemplates.elevio articleId={articleId} /> : null}
				</label>
				{alwaysEnabled ? (
					<Toggle checked size={size} color="medium-green" disabled></Toggle>
				) : (
					<Toggle
						checked={
							isUserParam
								? this.props.rootData.pageData.user.userParams[key]
								: this.props.rootData.pageData.user[key]
						}
						size={size}
						color="medium-green"
						disabled={disabled}
						onChange={val => {
							if (isUserParam) {
								this.props.rootData.pageData.userParamChange(key, val ? 1 : 0);
							} else {
								this.props.rootData.pageData.userFieldChange(key, val ? 1 : 0);
							}
						}}
					/>
				)}
			</div>
		);
	};
	getStatus = () => {
		var user = this.props.rootData.pageData.user;
		if (user.active && !user.ghost) {
			return 'active';
		}
		if (user.active && user.ghost) {
			return 'inactive';
		}
		return 'removed';
	};

	setStatus = statusValue => {
		this.props.rootData.pageData.setStatus(statusValue);
	};

	renderStatusButtons = (activeDisabled, isLastActiveUser) => {
		const { rootData } = this.props;
		const status = this.getStatus();

		const options = [
			{
				icon: 'user-check',
				title: 'Active',
				value: 'active'
			},
			{
				icon: 'user-slash',
				title: 'Inactive',
				value: 'inactive'
			}
		];

		// Conditionally set the value to 'inactive' when status is 'removed'
		const selectedValue = status === 'removed' ? 'inactive' : status;

		const canAddLicenses = this.props.rootData.pageData.loggedInUserIsBillingAdmin;
		const handleOnChange = value => {
			if (activeDisabled && canAddLicenses) {
				rootData.pageData.setAddSeatDrawerVisible(true);
			} else if (value !== status) {
				this.setStatus(value);
			}
		};
		return (
			<ButtonSelect
				disabled={isLastActiveUser || (!canAddLicenses && activeDisabled)}
				size="lg"
				onChange={handleOnChange}
				options={options}
				value={selectedValue}
			/>
		);
	};

	customChanged = (id, value) => {
		this.props.rootData.pageData.customFieldChange(id, value);
	};

	renderCustomFields = () => {
		var pageData = this.props.rootData.pageData;
		var user = pageData.user;
		var fields = Tools.AppService.getCustomFields('user');
		fields = (fields ?? []).filter(field => field.visible || field.editable);

		if (!fields || !fields.length) {
			return null;
		}

		var fieldElements = _.chain(fields)
			.sortBy('sortId')
			.map(field => {
				var valObj = _.find(user.custom, obj => {
					return parseInt(obj.fieldId) === parseInt(field.id);
				});

				const errorClass = pageData.errors?.custom?.[field.id] ? ' has-error' : '';
				return (
					<div className={'half-column' + errorClass} key={'field-' + field.id}>
						<Label required={!!field.obligatoryField}>{field.name}</Label>
						<ReactTemplates.customFieldInput
							field={Object.assign({}, field, { value: valObj ? valObj.value : null })}
							valueChange={value => this.customChanged(field.id, value)}
							entity={'user'}
							useNewTime
							useNumberInput
							anchor={this.wrapperRef.current}
						/>
					</div>
				);
			})
			.value();

		return (
			<div className="admin-card has-info">
				<div className="admin-card-top">
					<div className="admin-card-title">{this.lang.userOtherFields}</div>
				</div>

				<div className="admin-info-row">
					<div className="info-row-info">{this.lang.userOtherFieldInfo}</div>
					<div className="info-row-content half-row">{fieldElements}</div>
				</div>
			</div>
		);
	};
	removeProfileImage() {
		const { profileImage } = this.state;
		if (profileImage) {
			openModal('RemoveAlert', {
				title: this.lang.userImageBlockDeleteTitle,
				body: this.lang.userImageBlockDeleteDescription,
				confirmButtonText: 'default.delete',
				onClose: async confirmed => {
					if (confirmed) {
						this.setState({ loading: true });
						try {
							await Tools.File.delete({ id: profileImage?.id, entity: 'ProfileImage' });
							this.setState({ loading: false });
							this.setState({ profileImage: null });
						} catch (error) {
							console.error('Error deleting profile image', error);
							this.setState({ loading: false });
						}
					}
				}
			});
		}
	}

	renderAddLicenseText(licensesToBuy, newEmails) {
		const shouldRender = this.props.invoiceSubscriptions?.length <= 0;
		const canAddLicenses = this.props.rootData.pageData.loggedInUserIsBillingAdmin;

		if (shouldRender) {
			return null;
		}

		const startOfText = this.lang.tooManyUsers;

		const billingAdminText = (
			<>
				<Link
					onClick={() => {
						this.props.rootData.pageData.setAddSeatDrawerVisible(true);
					}}
				>
					{licensesToBuy > 1
						? Tools.$translate('admin.tooManyUsers.addSeats', {
								seats: licensesToBuy
						  })
						: Tools.$translate('admin.tooManyUsers.addSeat', {
								seat: licensesToBuy
						  })}
				</Link>
				{newEmails > 1 ? this.lang.activateUsers : this.lang.activateUser}
			</>
		);

		const billigAdmins = Tools.AppService.getActiveUsers().filter(user => !!user.billingAdmin);

		const nonBillingAdminText = (
			<>
				{Tools.$translate('ask.a') + ' '}
				<Tooltip title={`${billigAdmins.map(admin => admin.name).join('\n')}`}>
					{Tools.$translate('admin.billingAdmin').toLowerCase() + ' '}
				</Tooltip>
				{Tools.$translate('admin.tooManyUsers.addSeats.nonBillingAdmin')}
			</>
		);

		return (
			<Text color="orange" size="sm" space="mtm">
				<Icon name="warning" space="mrs" />
				{startOfText}
				{canAddLicenses ? billingAdminText : nonBillingAdminText}
			</Text>
		);
	}

	UserImageBlock(status, pageData) {
		const { profileImage } = this.state;
		const profileImageUrl =
			Tools.URL +
			Tools.API +
			Tools.AppService.getCustomerId() +
			'/resources/download/internal/' +
			profileImage?.id;
		return (
			<Block
				backgroundColor={status === 'active' ? 'super-light-blue' : 'yellow-2'}
				borderRadius
				space="mtl ptl pbl pll prl"
				className="profile-block"
			>
				<Avatar initials={pageData.user.name} src={profileImageUrl} size="xl" className="image-margin" />
				<div>
					<label>{this.lang.userImageBlockTitle}</label>
					{status === 'inactive' && profileImage && (
						<DangerButton
							size="sm"
							onClick={this.removeProfileImage}
							loading={this.state.deleteImageLoading}
						>
							{this.lang.userImageBlockDelete}
						</DangerButton>
					)}
				</div>
			</Block>
		);
	}
	renderStatusChip() {
		const status = this.getStatus();
		let icon, title, type;

		if (status === 'removed' || status === 'inactive') {
			icon = 'user-slash';
			title = this.lang.status.inactive;
			type = 'alert';
		} else {
			icon = 'user-check';
			title = this.lang.status.active;
			type = 'info';
		}

		return <AssistChip icon={icon} title={title} type={type} className="status-width" />;
	}

	render() {
		var pageData = this.props.rootData.pageData;

		const hasCustomerSupport = Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.CUSTOMER_SUPPORT);
		const activeUsers = Tools.AppService.getRealActiveUsersCount();
		const { licenses } = Tools.AppService.getMetadata();
		const newEmails = pageData.emails.length > 0 ? pageData.emails.length : 1;
		const selectAnchor = document.getElementById('admin-content');

		const licensesToBuy = newEmails + activeUsers - licenses;

		if (!pageData.user) {
			return null;
		}

		// If the user was set as support but the customer support feature is now not available anymore, we "silently" turn support off when entering the page, and enable the save button
		if (pageData.user && pageData.user.support && !hasCustomerSupport) {
			pageData.userFieldChange('support', 0);
			// The case where they don't have enough licenses is handled by the backend, they'll get a notification about it when trying to save
			pageData.userFieldChange('crm', 1);
		}

		const userLanguage = pageData.user.language ? pageData.user.language.split('-')[0] : null;

		if (this.state.originallyInactive === null && typeof pageData.user.active === 'boolean') {
			const originallyInactive = !pageData.user.active || (pageData.user.active && pageData.user.ghost);
			this.setState({ originallyInactive: originallyInactive });
		}

		var status = this.getStatus();
		if (status === 'removed') {
			status = 'inactive';
		}
		var activeDisabled =
			status !== 'active' &&
			licensesToBuy > 0 &&
			!this.props.rootData.pageData.roleWasActive &&
			pageData.user.crm;
		return (
			<div id="admin-add-user" className={pageData.edit ? 'edit' : null} ref={this.wrapperRef}>
				<div id="email-section">
					<div className="inner">
						<Breadcrumbs>
							<BreadcrumbItem state="administration.usersAndRoles" text={this.lang.usersAndRoles} />
							<BreadcrumbItem
								onClick={pageData.toggleMulti}
								text={
									pageData.edit
										? this.lang.editUser
										: this.lang.add + ' ' + this.lang.user.toLowerCase()
								}
							/>
							{!pageData.edit && pageData.multi ? <BreadcrumbItem text={this.lang.addMultiple} /> : null}
						</Breadcrumbs>
						{pageData.multi ? (
							<div id="multi-add">
								<label
									id="multi-floating-label"
									ref={r => (this._multiFloatLabel = r)}
									className={pageData.emails.length ? 'has-value' : null}
								>
									{this.lang.enterEmails}
								</label>
								<input type="hidden" ref={r => (this._multiSelect = r)} value={pageData.emails} />
							</div>
						) : (
							<div id="single-add">
								{this.renderUserField('email', this.lang.email, 'email', true, 75)}
								{this.renderUserField('name', this.lang.firstLastName, 'text', false, 50)}
								{this.renderUserField('userTitle', this.lang.userTitle, 'text', false, 50)}
								{this.renderUserField('userCellPhone', this.lang.cellPhone, 'text', false, 30)}
								{pageData.has2fa && (!pageData.user || !pageData.user.userCellPhone) ? (
									<div className="twofa-warning">{this.lang.twofaWarning}</div>
								) : null}
								{!pageData.edit ? (
									<div id="multi-add-info">
										<img src="img/users.png" />
										<div>{this.lang.addMultiUsersText}</div>
										<Tooltip
											title={this.props.singleSignOnEnabled ? this.lang.addMultipleUsers : ''}
										>
											<Button
												block={true}
												onClick={pageData.toggleMulti}
												disabled={this.props.singleSignOnEnabled}
											>
												{this.lang.addMultiple}
											</Button>
										</Tooltip>
									</div>
								) : null}
							</div>
						)}
					</div>
				</div>
				<div id="admin-content">
					{hasCustomerSupport ? (
						<LicenseTypeSettings
							user={pageData.user}
							onChange={pageData.userFieldChange}
							newEmails={newEmails}
						/>
					) : null}

					<div className="admin-card has-info">
						<div className="admin-card-top">
							<span className="admin-card-title">{this.lang.rolesAndAccess}</span>
						</div>
						<Flex justifyContent="space-between" direction="row-reverse">
							<div className="admin-info-row">
								<div className="info-row-info">{this.lang.userRoleSelectInfo}</div>
							</div>
							<div className="half-row role-select">
								<div className="half-column">
									<label className="has-elevio">
										{this.lang.role}
										<ReactTemplates.elevio articleId={563} />
									</label>
									{Tools.FeatureHelper.hasSoftDeployAccess('TREE_SELECT') ? (
										<RoleSelectSingle
											roles={pageData.roles}
											selectedRole={pageData.user.role}
											onChange={v => this.props.rootData.pageData.userFieldChange('role', v)}
										/>
									) : (
										<ReactTemplates.INPUTS.upRoles
											tools={this.props.tools}
											data={pageData.roles}
											value={pageData.user.role || null}
											onChange={v => this.props.rootData.pageData.userFieldChange('role', v)}
											placeholder={this.lang.selectRole}
										/>
									)}
								</div>
							</div>
						</Flex>
						{pageData.user.crm || !hasCustomerSupport ? (
							<div className="admin-info-row">
								<div className="info-row-info">{this.lang.userAccessInfo}</div>
								<div className="info-row-content">
									<div className="half-row">
										{this.renderToggle(
											this.lang.administrator,
											'administrator',
											false,
											1233,
											pageData.isLastAdmin || pageData.isMe
										)}
										{pageData.user.administrator
											? this.renderToggle(
													this.lang.billingAdmin,
													'billingAdmin',
													false,
													1136,
													!pageData.loggedInUserIsBillingAdmin
											  )
											: null}
									</div>
									<div className="half-row">
										{Tools.FeatureHelper.isAvailable(
											Tools.FeatureHelper.Feature.ADVANCED_ROLE_SETTINGS
										)
											? this.renderToggle(this.lang.teamLeader, 'teamLeader', false, 1237)
											: null}
										{this.renderToggle(
											this.lang.multiSoliditetAccess,
											'multiSoliditetAccess',
											true,
											1234
										)}
										{this.renderToggle(this.lang.exportAccess, 'export', false, 1236)}
										{Tools.FeatureHelper.isAvailableProduct(Tools.FeatureHelper.Product.MA)
											? this.renderToggle(
													this.lang.mailAdmin,
													'mailAdmin',
													true,
													1235,
													pageData.user.administrator,
													pageData.user.administrator
											  )
											: null}
										{Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_PRO) &&
										Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.PROSPECTING_SIGNALS)
											? this.renderToggle(
													this.lang.signalsToggle,
													'signalsModifyAccess',
													true,
													undefined,
													pageData.user.administrator,
													pageData.user.administrator
											  )
											: null}
									</div>
								</div>
							</div>
						) : null}
					</div>
					<div className="admin-card has-info">
						<div className="admin-card-top">
							<span className="admin-card-title">{this.lang.langAndRegion}</span>
						</div>

						<div className="admin-info-row">
							<div className="info-row-info">{this.lang.userLanguageAndRegionInfo}</div>
							<div className="info-row-content">
								<div className="half-row">
									<div className="half-column">
										<label>{this.lang.language}</label>
										<LanguageSelect
											value={userLanguage}
											onChange={lang => {
												this.props.rootData.pageData.userFieldChange('language', lang.iso);
											}}
											anchor={selectAnchor}
											required
										/>
									</div>
									<div className="half-column">
										<label>{this.lang.mainMarket}</label>
										<CountrySelect
											value={pageData.user.userParams.dnbCountryCodes}
											anchor={selectAnchor}
											onChange={country => {
												this.props.rootData.pageData.userParamChange(
													'dnbCountryCodes',
													country.id
												);
											}}
											availableCountries={['SE', 'NO', 'FI', 'DK']}
											required
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					{this.renderCustomFields()}

					<div className="admin-card has-info">
						<div className="admin-card-top">
							<div className="admin-card-title">
								<div className="admin-card-title-margin">{this.lang.userStatus}</div>
								{this.renderStatusChip()}
							</div>
						</div>
						<div className="admin-info-row">
							<div className="info-row-info">{this.lang.userStatusToggleInfo}</div>
							<div className="info-row-content">
								<div className="btn-group">
									{this.renderStatusButtons(activeDisabled, pageData.isLastActiveUser)}
								</div>
								{this.getStatus() === 'active' && this.UserImageBlock(status, pageData)}
								{activeDisabled ? this.renderAddLicenseText(licensesToBuy, newEmails) : null}
							</div>
							{this.getStatus() === 'removed' || this.getStatus() === 'inactive' ? (
								<div className="info-row-content">
									{status === 'inactive' && !this.state.originallyInactive ? (
										<Text className={'status-inactive-description'} color="grey-12" size="sm">
											{'*'}
											{this.lang.status.inactiveDescription1}{' '}
											{Tools.AppService.getSelf().billingAdmin ? (
												<a href={Tools.$state.href('administration.billing')}>
													{this.lang.status.inactiveDescription2}
												</a>
											) : (
												this.lang.status.inactiveDescription2
											)}
											{'.'}
										</Text>
									) : null}

									<div className="visible-toggle-row">
										<Toggle
											size="sm"
											color="medium-green"
											checked={this.getStatus() === 'inactive'}
											onChange={enabled => {
												this.setStatus(enabled ? 'inactive' : 'removed');
											}}
										/>
										<label className={'visible-toggle-description'}>
											{this.lang.visibleFilters}
										</label>
									</div>
									{this.getStatus() === 'inactive' && this.UserImageBlock(status, pageData)}
								</div>
							) : null}
						</div>
					</div>
				</div>
				{this.props.invoiceSubscriptions?.length > 0 ? (
					<BillingAddSeatDrawer
						visible={pageData.getAddSeatDrawerVisible()}
						onClose={confirm => {
							if (confirm) {
								this.props.rootData.pageData.setNoLicenses(confirm);
								this.setStatus('active');
							}
							this.props.rootData.pageData.setAddSeatDrawerVisible(false);
						}}
						activeUsers={newEmails + activeUsers}
					/>
				) : null}
			</div>
		);
	}
}

export const detached = EditUser;

export default connect(mapStateToProps)(EditUser);
