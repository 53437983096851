import PropTypes from 'prop-types';
import {
	Text,
	Button,
	Icon,
	Card,
	CardHeader,
	Table,
	Title,
	Link,
	TableColumn,
	TableHeader,
	TableRow,
	Flex
} from '@upsales/components';
import T from '../../Helpers/translate';
import InlineConfirm from '../../Dialogs/InlineConfirm';

export default function GenericRelationTable(props) {
	const {
		title,
		hideButton,
		buttonText,
		columns,
		data,
		openAccount,
		account,
		isSubsidiary,
		noCompanyGroup,
		showGroupSize
	} = props;
	const hasGroupBonanza = Tools.FeatureHelper.hasSoftDeployAccess('GROUP_BONANZA');
	// empty object push is because to get the edit links in place
	columns.push({});

	function LeadStatus(account) {
		return (
			<ReactTemplates.TOOLS.leadStatus
				theKey={account.id}
				tools={window.Tools}
				activity={account.hasActivity}
				activityOld={account.hadActivity}
				appointment={account.hasAppointment}
				appointmentOld={account.hadAppointment}
				opportunity={account.hasOpportunity}
				opportunityOld={account.hadOpportunity}
				order={account.hasOrder}
				orderOld={account.hadOrder}
			/>
		);
	}

	const TableData = data.map(item => {
		return (
			<TableRow key={`${item.id}-${item.relatedToClientId}`} onClick={() => {}}>
				<TableColumn onClick={() => openAccount(item.relatedToClientId)}>
					<Text>{item.name}</Text>
				</TableColumn>
				{!isSubsidiary && (
					<TableColumn onClick={() => openAccount(item.relatedToClientId)}>
						<Text>{item.descriptionChildParent}</Text>
					</TableColumn>
				)}
				<TableColumn onClick={() => openAccount(item.relatedToClientId)}>{LeadStatus(item)}</TableColumn>
				<TableColumn align="right">
					{!isSubsidiary && !item.subsidiary && account.createMenuAvailable.relation && (
						<Button type="link" color="grey" onClick={() => account.editRelation(item)}>
							<Icon name="edit" />
						</Button>
					)}
					{!isSubsidiary && !item.subsidiary && account.createMenuAvailable.relation && (
						<InlineConfirm
							show
							tooltip={T('default.remove')}
							onConfirm={() => account.removeRelation(item)}
							entity={T('default.relation')}
						>
							<Button type="link" color="grey">
								<Icon name="trash" />
							</Button>
						</InlineConfirm>
					)}
				</TableColumn>
			</TableRow>
		);
	});

	if (!data.length && !hasGroupBonanza) {
		TableData.push(
			<TableRow key="not-found">
				<TableColumn className="Not-Found" colSpan={4}>
					<Title size="sm" color="grey-10">
						{T('default.noData')}
					</Title>
				</TableColumn>
			</TableRow>
		);
	}

	const buttonObject = {};
	let isButtonVisible = false;
	if (account.account.userEditable && account.createMenuAvailable.relation) {
		isButtonVisible = true;

		buttonObject.onClick = () => {
			account.track('relation');
			account.addRelation();
		};
	}

	if (hideButton) {
		isButtonVisible = false;
	}

	return (
		<Card color="transparent" className="GenericRelationTable">
			{!hasGroupBonanza || !showGroupSize ? (
				<CardHeader title={title}>
					{isButtonVisible ? <Button {...buttonObject}>{buttonText}</Button> : null}
				</CardHeader>
			) : noCompanyGroup && hasGroupBonanza ? (
				<CardHeader className="NoProspectingIdHeader" title={title}>
					{isButtonVisible ? <Button {...buttonObject}>{buttonText}</Button> : null}
				</CardHeader>
			) : null}

			<Table>
				<TableHeader columns={columns} />
				{TableData}
			</Table>
			{!data.length && hasGroupBonanza ? (
				<>
					<Flex justifyContent="center" className="EmptyResultTable">
						<img src="img/empty-briefcase.svg" alt="upsales-briefcase" />
					</Flex>
					<Flex justifyContent="center" space="mtl">
						<Text>{T('account.relations.noRelations')}</Text>
					</Flex>
					<Flex justifyContent="center" space="mts">
						<Text size="sm" color="grey-11">
							{T('account.relations.nonAtTheMoment') + ' '}
							<Link onClick={account.addRelation}>
								{T('account.relations.createRelation').toLowerCase()}
							</Link>
						</Text>
					</Flex>
				</>
			) : null}
		</Card>
	);
}

GenericRelationTable.propTypes = {
	title: PropTypes.string,
	buttonText: PropTypes.string,
	columns: PropTypes.array,
	data: PropTypes.array,
	openAccount: PropTypes.func.isRequired,
	account: PropTypes.object,
	removeRelation: PropTypes.func,
	hideButton: PropTypes.bool,
	isSubsidiary: PropTypes.bool,
	noCompanyGroup: PropTypes.bool,
	showGroupSize: PropTypes.bool
};
