'use strict';

import openModal from 'App/services/Modal';

angular.module('upDirectives').directive('upEventMailCampaign', function () {
	return {
		restrict: 'A',
		templateUrl: require('App/upsales/common/directives/event/upEventMailCampaign.tpl.html?file'),
		require: 'upEventMailCampaign',
		scope: {
			event: '=upEventMailCampaign',
			noIcon: '@'
		},
		controller: [
			'$scope',
			'$safeDigest',
			'$timeout',
			'$state',
			'$upModal',
			'FeatureHelper',
			function ($scope, $safeDigest, $timeout, $state, $upModal, FeatureHelper) {
				$scope.customerId = Tools.AppService.getCustomerId();

				$scope.openContact = function (contactId) {
					$state.go('contact.dashboard', { id: contactId });
				};

				$scope.expand = function () {
					if ($scope.event.$$expand) {
						$scope.event.$$expand = false;
						return $timeout(function () {
							$safeDigest($scope);
						}, 400);
					}

					$scope.event.$$loading = false;
					$scope.event.$$expand = true;
				};

				$scope.showMoreUsers = function (e) {
					e.stopPropagation();
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_LIST')) {
						openModal('ListModal', {
							title: 'default.contacts',
							columns: [{ title: 'default.name', value: 'name' }],
							data: $scope.event.contacts,
							onClose: function (row) {
								$state.go('contact.dashboard', { id: row.id });
							}
						});
					} else {
						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open(
								'list',
								{
									title: 'default.contacts',
									columns: [{ title: 'default.name', value: 'name' }],
									data: $scope.event.contacts
								},
								e
							)
							.then(function (row) {
								$state.go('contact.dashboard', { id: row.id });
							});
					}
				};

				var init = function () {
					$scope.hasGroupMail = FeatureHelper.isAvailable(FeatureHelper.Feature.GROUP_MAIL);
					$scope.event.mails = _.filter($scope.event.mails, function (mail) {
						if (mail.contacts.length > 0) {
							return mail;
						}
					});

					var nrOfRead = 0,
						nrOfClicked = 0;
					$scope.event.contacts = _.map($scope.event.mails, function (mm) {
						if (mm.lastClickDate) {
							nrOfClicked++;
						}

						if (mm.lastReadDate) {
							nrOfRead++;
						}

						return mm.contacts[0];
					});

					$scope.event.contacts = _.compact($scope.event.contacts);

					if (!$scope.event.contacts) {
						$scope.event.contacts = [];
					}

					$scope.event.nrOfContacts = $scope.event.contacts.length;
					$scope.event.percentOfClicks = Math.round((nrOfClicked / $scope.event.nrOfContacts) * 100);
					if (isNaN($scope.event.percentOfClicks)) {
						$scope.event.percentOfClicks = 0;
					}

					$scope.event.percentOfSeen = Math.round((nrOfRead / $scope.event.nrOfContacts) * 100);
					if (isNaN($scope.event.percentOfSeen)) {
						$scope.event.percentOfSeen = 0;
					}

					if ($scope.event.contacts && $scope.event.contacts.length && $scope.event.nrOfContacts > 1) {
						var filteredContacts = _.filter($scope.event.contacts, function (contact) {
							if (contact) {
								return contact;
							}
						});

						$scope.event.contactsRest = filteredContacts
							.slice(1, $scope.nrOfContacts)
							// eslint-disable-next-line array-callback-return
							.map(function (cc) {
								if (cc) {
									return cc.name;
								}
							})
							.join(', ');
					}

					$scope.event.mailSender = {
						email: $scope.event.mails[0]?.from,
						name: $scope.event.mails[0]?.fromName
					};
					$scope.event.sentWithAlias =
						$scope.event.users.length === 1 &&
						$scope.event.mailSender.email &&
						$scope.event.users[0].email !== $scope.event.mailSender.email;
				};

				init();
			}
		]
	};
});
