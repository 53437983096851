'use strict';
import logError from 'App/babel/helpers/logError';
import fieldSort from '../../../../babel/helpers/fieldSort';
import openModal from 'App/services/Modal';
import documentResource from 'Resources/document';

angular.module('domain.userdefobj').controller('EditUserDefinedObject', [
	'$scope',
	'AppService',
	'$modalParams',
	'UserDefinedObject',
	'$translate',
	'$upModal',
	'selectHelper',
	'utils',
	function ($scope, AppService, $modalParams, UserDefinedObject, $translate, $upModal, selectHelper, utils) {
		var EditUserDefinedObject = this;
		var meta = $modalParams.meta;

		$scope.$on('modal.rejected', function (e) {
			var prevent = $scope.UserDefinedObject.$dirty;
			if (prevent) {
				e.preventDefault();

				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					openModal('Alert', {
						title: 'default.abort',
						body: 'confirm.abortEdit',
						confirmButtonText: 'default.abortEdit',
						cancelButtonText: 'default.returnToEdit',
						onClose: async confirmed => {
							if (!confirmed) {
								return;
							}
							prevent = false;
							$scope.UserDefinedObject.$setPristine();
							$scope.reject();

							// Resume state change if we had one
							if (e.state) {
								e.resumeState();
							}
						}
					});
				} else {
					// eslint-disable-next-line promise/catch-or-return
					$upModal
						.open('warningConfirm', {
							title: 'default.abort',
							body: 'confirm.abortEdit',
							resolveTrue: 'default.abortEdit',
							no: 'default.returnToEdit',
							icon: 'fa-warning',
							focus: 1
						})
						.then(function () {
							prevent = false;
							$scope.UserDefinedObject.$setPristine();
							$scope.reject();

							// Resume state change if we had one
							if (e.state) {
								e.resumeState();
							}
						});
				}
			}
		});

		EditUserDefinedObject.save = function () {
			EditUserDefinedObject.saving = true;
			UserDefinedObject.setId($modalParams.typeId)
				.save(EditUserDefinedObject.object)
				.then(function (res) {
					$scope.resolve(res.data);
				})
				.finally(function () {
					EditUserDefinedObject.saving = false;
				})
				.catch(err => {
					EditUserDefinedObject.saving = false;
					logError(err, 'Failed to save user defined object');
				});
		};

		// Save account data in variable
		EditUserDefinedObject.accountChange = function (init) {
			if (!init) {
				EditUserDefinedObject.object.contact = undefined;
			}
			EditUserDefinedObject.contacts = [];
			if (
				EditUserDefinedObject.object.client &&
				EditUserDefinedObject.object.client.id &&
				EditUserDefinedObject.object.client.id > 0
			) {
				var fields = ['id', 'name', 'email', 'cellPhone', 'phone', 'client', 'title'];
				utils.req
					.clientContactsAndRelations(EditUserDefinedObject.object.client.id, fields)
					.then(function (res) {
						var contactData = EditUserDefinedObject.select.contact.data;
						var contacts = res.data;
						contactData.push.apply(contactData, contacts);

						EditUserDefinedObject.contacts = contacts;
						contactData.splice(0, contactData.length);
						contactData.push.apply(contactData, res.data);

						// Set selected contact
						if (
							init &&
							EditUserDefinedObject.object &&
							EditUserDefinedObject.object.contact &&
							EditUserDefinedObject.object.contact.id &&
							res.data.length
						) {
							var found = _.find(res.data, { id: EditUserDefinedObject.object.contact.id });
							if (found) {
								EditUserDefinedObject.object.client = found.client;
								EditUserDefinedObject.object.contact = found;
							}
						}

						if (contacts.length && !init) {
							$scope.$broadcast('editUserDefinedObject.accountChanged');
						}
					})
					.catch(err => logError(err, 'Failed to fetch and set account data'));
			}
		};

		EditUserDefinedObject.changeContact = function () {
			EditUserDefinedObject.changingContact = !EditUserDefinedObject.changingContact;

			if (EditUserDefinedObject.changingContact) {
				EditUserDefinedObject.initialContact = EditUserDefinedObject.object.contact;
			} else {
				EditUserDefinedObject.object.contact = EditUserDefinedObject.initialContact;
			}
		};

		EditUserDefinedObject.changeAccount = function () {
			EditUserDefinedObject.changingAccount = !EditUserDefinedObject.changingAccount;

			if (EditUserDefinedObject.changingAccount) {
				EditUserDefinedObject.initialClient = EditUserDefinedObject.object.client;
				EditUserDefinedObject.initialContact = EditUserDefinedObject.object.contact;
			} else {
				EditUserDefinedObject.object.client = EditUserDefinedObject.initialClient;
				EditUserDefinedObject.object.contact = EditUserDefinedObject.initialContact;
				EditUserDefinedObject.accountChange(true);
			}
		};

		EditUserDefinedObject.createDocument = function (template) {
			var params = {
				entityId: EditUserDefinedObject.object.id,
				templateId: template.id,
				type: template.type,
				name: template.name
			};

			if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
				openModal('PreviewPdfModal', {
					isUploadedTemplate: true,
					documentResource: {
						resource: documentResource,
						entityId: params.entityId,
						templateId: params.templateId,
						type: params.type,
						documentName: params.name,
						accountId: params.accountId,
						contact: params.contact,
						contactId: params.contactId
					}
				});
			} else {
				$upModal.open('pdfPreview', params);
			}
		};

		var init = function () {
			EditUserDefinedObject.contacts = [];
			EditUserDefinedObject.select = {
				contact: {
					data: EditUserDefinedObject.contacts,
					formatSelection: selectHelper.wrapFormatSelectionLink(
						function (contact) {
							return utils.select2.clientContactsAndRelations.formatSelection(
								contact,
								EditUserDefinedObject.object.client ? EditUserDefinedObject.object.client.id : null
							);
						},
						function (contact, $state) {
							$state.go('contact.dashboard', { id: contact.id }, { reload: true });
						}
					),
					formatResult: function (contact) {
						if (!contact.id) {
							return '<i class="grey">' + $translate.instant('default.noContact') + '</i>';
						}
						return utils.select2.clientContactsAndRelations.formatResult(
							contact,
							EditUserDefinedObject.object.client ? EditUserDefinedObject.object.client.id : null
						);
					},
					minimumResultsForSearch: 8,
					allowClear: false,
					matcher: function (term, text, contact) {
						return contact.name.toUpperCase().indexOf(term.toUpperCase()) >= 0;
					}
				}
			};

			var metadata = AppService.getMetadata();
			EditUserDefinedObject.udo = _.find(metadata.params.UserDefinedObject, {
				id: parseInt($modalParams.typeId)
			});
			EditUserDefinedObject.object = meta.object.data;
			EditUserDefinedObject.object.custom = EditUserDefinedObject.object.custom.sort(fieldSort);
			EditUserDefinedObject.categoryTypeEntity = 'userDefined' + $modalParams.typeId;

			EditUserDefinedObject.hasNoLink =
				!EditUserDefinedObject.object.client &&
				!EditUserDefinedObject.object.contact &&
				!EditUserDefinedObject.object.project;

			const hasLink6 = EditUserDefinedObject.udo.link === 6;
			const hasLink4 = EditUserDefinedObject.udo.link === 4;
			const hasLink1 = EditUserDefinedObject.udo.link === 1;

			EditUserDefinedObject.contactRequired = hasLink6;
			EditUserDefinedObject.accountRequired = hasLink6 || hasLink4;
			EditUserDefinedObject.projectRequired = hasLink1;

			EditUserDefinedObject.notes = [];
			EditUserDefinedObject.notes.push({ title: $translate.instant('default.notes'), model: 'notes1' });

			EditUserDefinedObject.hasDocumentTemplates =
				meta.documentTemplates.data && meta.documentTemplates.data.length;
			EditUserDefinedObject.documentTemplates = meta.documentTemplates.data;
			EditUserDefinedObject.categoryTypes = AppService.getCategoryTypes(EditUserDefinedObject.categoryTypeEntity);
			EditUserDefinedObject.changeAccountText =
				$translate.instant('change') + ' ' + $translate.instant('account').toLowerCase();
			EditUserDefinedObject.changeContactText =
				$translate.instant('change') + ' ' + $translate.instant('contact').toLowerCase();
			EditUserDefinedObject.accountChange(true);
		};
		init();
	}
]);
