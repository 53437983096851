'use strict';

import logError from 'App/babel/helpers/logError';
import { globalTracker } from 'Helpers/Tracker';
import openModal from 'App/services/Modal';
import { openMailTemplateBrowserModal } from 'App/components/MailTemplateBrowserModal/MailTemplateBrowserModal';

angular.module('domain.segment').controller('FlowEditor', [
	'AppService',
	'$translate',
	'$stateParams',
	'Segment',
	'Flow',
	'$state',
	'$scope',
	'RequestBuilder',
	'MailCampaign',
	'GroupMailCategory',
	'Form',
	'Contact',
	'FilterHelper',
	'$filter',
	'$upModal',
	'MailTemplate',
	'NotificationService',
	function (
		AppService,
		$translate,
		$stateParams,
		Segment,
		Flow,
		$state,
		$scope,
		RequestBuilder,
		MailCampaign,
		GroupMailCategory,
		Form,
		Contact,
		FilterHelper,
		$filter,
		$upModal,
		MailTemplate,
		NotificationService
	) {
		var rootNode = document.getElementById('flow-editor-root');
		var oldFlow = null;
		var allowStateChange = false;

		var props = {
			tools: {
				$translate: $translate,
				$state: $state,
				AppService: AppService,
				MailCampaign: MailCampaign,
				RequestBuilder: RequestBuilder,
				GroupMailCategory: GroupMailCategory,
				Form: Form,
				formatNumber: $filter('numberFormat'),
				MailTemplate: MailTemplate
			},
			loading: true,
			segment: null,
			flow: null,
			contacts: 0,
			save: function (noRedirect) {
				return Flow.save(props.flow)
					.then(function () {
						allowStateChange = true;
						if (!noRedirect) {
							if (props.isStandalone) {
								$state.go('flow', { id: $stateParams.id, showTooltip: true });
							} else {
								$state.go('segment.flow', {
									id: $stateParams.segmentId,
									showTooltip: true,
									showSelectionTooltip: $stateParams.showSelectionTooltip
								});
							}
						}
					})
					.catch(e => logError(e, 'Failed to save flow'));
			},
			abortEdit: function () {
				if (props.isStandalone) {
					$state.go('flow', { id: $stateParams.id });
				} else {
					$state.go('segment.flow', {
						id: $stateParams.segmentId,
						showSelectionTooltip: $stateParams.showSelectionTooltip
					});
				}
			},
			getStep: function (type, props) {
				return Flow.newStep(type, props);
			},
			onFlowPropChange: partialFlow => {
				props.flow = { ...props.flow, ...partialFlow };
				render();
			},
			onAddStep: function (stepData, path, isEdit) {
				var newStep = _.get(props.flow, 'path' + path);
				var oldStep = _.cloneDeep(_.get(props.flow, 'path' + path));

				if (!isEdit) {
					newStep = Flow.newStep(stepData.type);
				}

				newStep = _.merge(newStep, stepData);

				if (oldStep && newStep.type === 'sendMail' && oldStep.templateId !== newStep.templateId) {
					let removedLinks = false;
					if (newStep.childYes && newStep.childYes.link) {
						newStep.childYes.link = null;
						removedLinks = true;
					}
					if (newStep.childNo && newStep.childNo.link) {
						newStep.childYes.link = null;
						removedLinks = true;
					}
					if (removedLinks) {
						NotificationService.addNotification({
							style: NotificationService.style.SUCCESS,
							icon: 'check',
							title: 'default.updated',
							body: 'flow.linksInNextStepRemoved'
						});
					}
				}

				/* I dont think you ever want to recursively merge the param array, but i dont dare to change that so I fix it for the new actions I added */
				if (
					newStep.type === 'createActivity' ||
					newStep.type === 'updateContact' ||
					newStep.type === 'updateClient'
				) {
					newStep.params = stepData.params;
				}

				_.set(props.flow, 'path' + path, newStep);
				if (!isEdit && oldStep) {
					_.set(props.flow, 'path' + path + '.childYes', oldStep);
				}

				render();
				globalTracker.track('Added flow step', { action: newStep.type });
			},
			onRemoveStep: function (path) {
				var title = 'flow.removeStep';
				var body = 'flow.removeStepInfo';
				var isRoot = !path || !path.length;
				var step = _.get(props.flow, 'path' + path);
				if (!step) {
					return;
				}
				const hasNewFlowSteps = Tools.FeatureHelper.hasSoftDeployAccess('NEW_FLOW_STEPS');
				const isNewStepWithBehavior =
					hasNewFlowSteps &&
					((step.type === 'sendMail' && ['readMail', 'clickedMail'].includes(step.childYes?.type)) ||
						(step.type === 'createActivity' && step.childYes?.type === 'phoneOutcome'));
				const hasSingleChild = !(isNewStepWithBehavior ? step.childYes?.childNo : step.childNo);
				// Do special for root remove when has been active
				if (props.flow.hasBeenActive && isRoot) {
					title = 'flow.removeRootStep';
					body = 'flow.removeRootStepInfo';
				} else if (props.flow.hasBeenActive) {
					// if has been active and other step than root
					title = 'flow.removeActiveStep';
					body = 'flow.removeActiveStepInfo';
				}

				if (hasSingleChild) {
					title = 'flow.removeStepOnly';
					body = 'flow.removeStepOnlyInfo';
				}

				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					openModal('Alert', {
						title: title,
						body: body,
						confirmButtonText: 'flow.removeStep',
						onClose: async confirmed => {
							if (confirmed === undefined) {
								return;
							}
							if (confirmed) {
								if (hasSingleChild) {
									const child = isNewStepWithBehavior ? step.childYes?.childYes : step.childYes;
									_.set(props.flow, 'path' + path, child);
								} else {
									_.set(props.flow, 'path' + path, null);
								}

								render();
							}
						}
					});
				} else {
					// eslint-disable-next-line promise/catch-or-return
					$upModal
						.open('warningConfirm', {
							title: title,
							body: body,
							icon: 'fa-warning',
							resolveTrue: 'flow.removeStep',
							no: 'default.abort'
						})
						.then(function () {
							if (hasSingleChild) {
								const child = isNewStepWithBehavior ? step.childYes?.childYes : step.childYes;
								_.set(props.flow, 'path' + path, child);
							} else {
								_.set(props.flow, 'path' + path, null);
							}

							render();
						});
				}
			},
			showEmailTemplateModal: function (selectedTemplateId) {
				return openMailTemplateBrowserModal({ requireUnsub: true, selectedTemplateId });
			}
		};

		var getFlow = function () {
			var filters = new RequestBuilder();

			if (props.isStandalone) {
				filters.addFilter(Flow.attr.id, filters.comparisonTypes.Equals, $stateParams.id);
			} else {
				filters.addFilter(Flow.attr.segment, filters.comparisonTypes.Equals, props.segment.id);
			}
			return Flow.find(filters.build(), { skipStats: true }).then(function (res) {
				if (res && res.data && res.data.length) {
					props.flow = res.data[0];
					oldFlow = Tools.LZString.compressToBase64(JSON.stringify(props.flow));
				} else {
					props.flow = getFromPreset($stateParams.preset);
					if ($stateParams.segmentId) {
						props.flow.segmentId = parseInt($stateParams.segmentId);
					}
					oldFlow = Tools.LZString.compressToBase64('');
				}
			});
		};

		var countContacts = function () {
			if (!props.segment) {
				props.contacts = 0;
				return Promise.resolve();
			}
			var contactsQuery = FilterHelper.mergeSegmentFilters(props.segment.filter);
			contactsQuery.limit = 0;

			return Contact.find(contactsQuery).then(function (res) {
				props.contacts = res.metadata.total;
			});
		};

		var getSegment = function () {
			var segmentId = $stateParams.segmentId;

			if (props.isStandalone && !props.flow.segmentId) {
				return Promise.resolve();
			} else if (props.isStandalone) {
				segmentId = props.flow.segmentId;
			}
			return Segment.get(segmentId).then(function (res) {
				props.segment = res.data;
			});
		};

		function render() {
			if (!rootNode) {
				return;
			}

			ReactDOM.render(React.createElement(ReactTemplates.segment.flowEditor, props), rootNode, function () {});
		}

		var getResumeState = function (stateObj) {
			return function (mergeParams) {
				var stateParams = _.merge(stateObj.stateParams, mergeParams || {});
				$state.go(stateObj.state.name, stateParams, stateObj.options);
			};
		};

		$scope.$on('$destroy', function () {
			ReactDOM.unmountComponentAtNode(rootNode);
			rootNode = undefined;
		});

		$scope.$on('$stateChangeStart', function (event, toState, toStateParams, fromState, fromStateParams, options) {
			if (!allowStateChange && oldFlow !== Tools.LZString.compressToBase64(JSON.stringify(props.flow))) {
				event.preventDefault();

				if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
					openModal('Alert', {
						title: 'confirm.abortEdit',
						body: 'confirm.changesWillBeLost',
						confirmButtonText: 'default.abortEdit',
						cancelButtonText: 'default.continueEdit',
						onClose: async confirmed => {
							if (!confirmed) {
								return;
							}
							allowStateChange = true;
							var stateObj = {
								state: toState,
								stateParams: toStateParams,
								options: { ...options, location: true }
							};
							getResumeState(stateObj)();
						}
					});
				} else {
					// eslint-disable-next-line promise/catch-or-return
					$upModal
						.open('warningConfirm', {
							title: 'confirm.abortEdit',
							body: 'confirm.changesWillBeLost',
							resolveTrue: 'default.abortEdit',
							no: 'default.continueEdit'
						})
						.then(function () {
							allowStateChange = true;
							var stateObj = {
								state: toState,
								stateParams: toStateParams,
								options: { ...options, location: true }
							};
							getResumeState(stateObj)();
						});
				}
			}
		});

		function getFromPreset(presetId) {
			var flow = Flow.new();
			switch (presetId) {
				case 'eventInvitation':
					flow.path = Flow.newStep('sendMail');
					flow.path.childYes = Flow.newStep('formSubmit', { waitTime: 2 });
					flow.path.childYes.childNo = Flow.newStep('sendMail');
					flow.path.childYes.childNo.childYes = Flow.newStep('formSubmit', { waitTime: 2 });
					flow.path.childYes.childNo.childYes.childNo = Flow.newStep('sendMail');
					break;
				case 'onboarding':
					flow.path = Flow.newStep('sendMail');
					flow.path.childYes = Flow.newStep('snooze', { waitTime: 7 });
					flow.path.childYes.childYes = Flow.newStep('sendMail');
					flow.path.childYes.childYes.childYes = Flow.newStep('snooze', { waitTime: 7 });
					flow.path.childYes.childYes.childYes.childYes = Flow.newStep('sendMail');
					break;
				case 'coldProspectNurturing':
					flow.path = Flow.newStep('sendMail');
					flow.path.childYes = Flow.newStep('snooze', { waitTime: 30 });
					flow.path.childYes.childYes = Flow.newStep('sendMail');
					flow.path.childYes.childYes.childYes = Flow.newStep('snooze', { waitTime: 90 });
					flow.path.childYes.childYes.childYes.childYes = Flow.newStep('sendMail');
					flow.path.childYes.childYes.childYes.childYes.childYes = Flow.newStep('snooze', { waitTime: 60 });
					flow.path.childYes.childYes.childYes.childYes.childYes.childYes = Flow.newStep('sendMail');
					break;
			}

			return flow;
		}

		var getOptOutCategories = function () {
			return GroupMailCategory.find({ active: 1 }).then(function (res) {
				props.optOutCategories = res.data;
			});
		};

		var init = function () {
			render();
			props.isStandalone = !$stateParams.segmentId;

			if (props.isStandalone) {
				getFlow()
					.then(getSegment)
					.then(getOptOutCategories)
					.then(countContacts)
					.then(function () {
						props.loading = false;
						render();
					})
					.catch(e => logError(e, 'Failed to get flow'));
			} else {
				getSegment()
					.then(getOptOutCategories)
					.then(countContacts)
					.then(getFlow)
					.then(function () {
						props.loading = false;
						render();
					})
					.catch(e => logError(e, 'Failed to get segment'));
			}
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);
