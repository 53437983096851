'use strict';

import moment from 'moment';

import { ACTIVITY_PRIORITY } from 'App/babel/enum/activity';
import openModal from 'App/services/Modal';
import logError from 'Helpers/logError';

angular.module('domain.activity').controller('ListActivities', [
	'$scope',
	'$translate',
	'$upModal',
	'Activity',
	'ActivityAttributes',
	'$multiSelect',
	'AppService',
	'FilterHelper',
	'FeatureHelper',
	'MultiRunnerActions',
	'Onboarding',
	function (
		$scope,
		$translate,
		$upModal,
		Activity,
		ActivityAttributes,
		$multiSelect,
		AppService,
		FilterHelper,
		FeatureHelper,
		MultiRunnerActions,
		Onboarding
	) {
		// Redirect to todos if they don't have access to activities
		if (FeatureHelper.hasSoftDeployAccess('TODO_LIST') && FeatureHelper.hasSoftDeployAccess('REMOVE_ACTIVITIES')) {
			Tools.$state.go('react-root-todo');
		}

		var Ctrl = this;
		var listViewCtrl = {};
		const currentDay = new Date().getDay();

		Ctrl.attributes = ActivityAttributes();
		Ctrl.disableAddFilter = false;
		Ctrl.showAddBtn = true;
		Ctrl.listType = 'activity';
		Ctrl.tableTemplate = 'activityRow';
		Ctrl.title = 'default.activities';
		Ctrl.useMultiActions = true;
		Ctrl.total = 0;
		Ctrl.secondList = 'appointments';
		Ctrl.secondListText = 'default.appointments';
		Ctrl.ACTIVITY_PRIORITY = ACTIVITY_PRIORITY;
		Ctrl.priority = ACTIVITY_PRIORITY.NONE;

		var activities = [];
		var documentTemplates = [];
		var customerId;
		var currentQuery = [];
		var currentRb = null;
		var uniqueCurrent = [];

		Ctrl.headerExtensionPath = require('App/upsales/domain/activity/views/listActivitiesHeaderExtension.html?file');
		Ctrl.headerExtension = { path: Ctrl.headerExtensionPath };
		Ctrl.hasPriority = Tools.FeatureHelper.hasSoftDeployAccess('ACTIVITY_PRIORITIZATION');

		Ctrl.headerListLinks = [
			{
				type: 'activity',
				state: 'activities',
				text: 'default.activities',
				icon: 'Icon Icon-activity'
			},
			{
				type: 'appointment',
				state: 'appointments',
				text: 'default.appointments',
				icon: 'fa-calendar'
			}
		];

		Ctrl.addButton = {
			getLabel: function () {
				return $translate.instant('default.addActivity');
			},
			createEntry: function () {
				Ctrl.createEntry();
				Onboarding.setListIntro('activity');
			},
			isEnabled: function () {
				const self = AppService.getSelf();
				return self.createRights.Activity !== 'NO';
			}
		};

		Ctrl.filterByPriority = function (priority) {
			if (priority === Ctrl.priority) {
				return;
			}

			Ctrl.priority = priority;
			if (!listViewCtrl.filters.Priority) {
				listViewCtrl.filters.Priority = {
					filterName: 'Priority',
					dataType: 'raw'
				};
			}

			listViewCtrl.page = 1;
			listViewCtrl.filters.Priority.value = priority;
		};

		Ctrl.getReference = function (_listViewCtrl) {
			listViewCtrl = _listViewCtrl;
			listViewCtrl.listType = Ctrl.listType;
		};

		$scope.$watch(
			() => listViewCtrl.filters,
			function () {
				if (listViewCtrl.filters) {
					const { Priority: priority } = listViewCtrl.filters;
					Ctrl.priority = priority ? priority.value : ACTIVITY_PRIORITY.NONE;
				}
			},
			true
		);

		Ctrl.quickSearch = {
			getFilter: function (value) {
				return {
					filterName: 'ListSearch',
					inactive: !value,
					value: value
				};
			},
			getValue: function (props) {
				var filter = props.activeFilters.ListSearch;
				return filter && filter.value ? filter.value : '';
			},
			getPlaceholder: function () {
				return $translate.instant('filters.quickSearch');
			}
		};

		var updateMultiselectTotal = function () {
			$multiSelect.setTotal(Ctrl.total);
		};

		// Listen for order changes
		$scope.$on('order.updated', function (e, order) {
			var activity = _.find(activities, { opportunity: { id: order.id } });

			if (activity) {
				activity.opportunity = order;
			}
		});

		$scope.$on('contact.updated', (_event, updatedContact) => {
			const filteredActivity = activities?.find(activity =>
				activity.contacts.some(contact => contact.id === updatedContact.id)
			);
			if (filteredActivity) {
				filteredActivity.contacts = filteredActivity.contacts.map(contact =>
					contact.id === updatedContact.id ? updatedContact : contact
				);
				$scope.$root.$broadcast('renderTable');
			}
		});

		$scope.$on('activity.added', function (e, added) {
			if (FilterHelper.match(uniqueCurrent.q, added, 'activity')) {
				Ctrl.total++;
				updateMultiselectTotal();
				activities.push(added);
				if (added.date < moment().startOf('day').toDate() && !added.closeDate) {
					Ctrl.nrOfDelayed++;
				}

				seeIfFileterHaveActiveActivities();
				$scope.$broadcast('listView.updateTableData', {
					data: activities,
					total: Ctrl.total
				});
			}
		});

		$scope.$on('activity.updated', function (e, updated) {
			var existingIndex = _.findIndex(activities, { id: updated.id });
			if (existingIndex !== -1 && FilterHelper.match(uniqueCurrent.q, updated, 'activity')) {
				activities[existingIndex] = updated;
			} else if (existingIndex !== -1) {
				Ctrl.total--;
				updateMultiselectTotal();
				activities.splice(existingIndex, 1);
			}

			getNumberOfDelayedEntries()
				.then(listViewCtrl.refreshSharedViews)
				.catch(e => logError(e, 'Failed to get number of delayed entries'));
			seeIfFileterHaveActiveActivities();
			$scope.$broadcast('listView.updateTableData', {
				data: activities,
				total: Ctrl.total
			});
		});

		$scope.$on('activity.deleted', function (e, deleted) {
			var existingIndex = _.findIndex(activities, { id: deleted.id });

			if (existingIndex !== -1) {
				Ctrl.total--;
				updateMultiselectTotal();
				activities.splice(existingIndex, 1);
			}

			getNumberOfDelayedEntries()
				.then(listViewCtrl.refreshSharedViews)
				.catch(e => logError(e, 'Failed to get number of delayed entries'));
			seeIfFileterHaveActiveActivities();
			$scope.$broadcast('listView.updateTableData', {
				data: activities,
				total: Ctrl.total
			});
		});

		$scope.$on('activity.list.multiRemove', (e, deletedIds) => {
			if (!deletedIds?.length) {
				return;
			}
			activities = activities.filter(activity => !deletedIds.includes(activity.id));
			Ctrl.total -= deletedIds.length;
			$scope.$broadcast('listView.updateTableData', {
				data: activities,
				total: Ctrl.total
			});
		});

		const unsubCloseEvent = $scope.$root.$on('flashClosed', () => {
			$scope.$root.$broadcast('listView.refresh');
		});

		const unsubHideEvent = $scope.$root.$on('flashHidden', () => {
			$scope.$root.$broadcast('listView.refresh');
		});

		$scope.$on('$destroy', () => {
			unsubCloseEvent();
			unsubHideEvent();
		});

		Ctrl.formatTotal = function () {
			var total = (Ctrl.total || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
			var nrDelayed = (Ctrl.nrOfDelayed || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
			var delayed = Ctrl.nrOfDelayed
				? ' (' + nrDelayed + ' ' + $translate.instant('default.delayed').toLowerCase() + ')'
				: '';

			if (Ctrl.total === 0) {
				return total + ' ' + $translate.instant('default.activities').toLowerCase() + delayed;
			} else if (Ctrl.total === 1) {
				return total + ' ' + $translate.instant('default.activity').toLowerCase() + delayed;
			} else {
				return total + ' ' + $translate.instant('default.activities').toLowerCase() + delayed;
			}
		};

		/**
		 * The add button
		 */
		Ctrl.createEntry = function () {
			$upModal.open('editActivity', {});
		};

		var getEntries = function (query) {
			return Activity.customer(customerId)
				.find(query)
				.then(function (res) {
					return res;
				});
		};

		function getNumberOfDelayedEntries() {
			if (currentQuery && currentRb) {
				var rb = currentRb.clone();
				var attr = Ctrl.attributes.attr;

				rb.addFilter(attr.date, rb.comparisonTypes.LessThanEquals, moment().startOf('day'));
				rb.addFilter(attr.isAppointment, rb.comparisonTypes.Equals, false);
				rb.addFilter(attr.closeDate, rb.comparisonTypes.Equals, null);

				var built = rb.build();
				built.limit = 0;

				return Activity.customer(customerId)
					.find(built)
					.then(function (res) {
						Ctrl.nrOfDelayed = res.metadata.total;
					});
			} else {
				Ctrl.nrOfDelayed = 0;
			}

			return Promise.resolve();
		}

		// Modify query before its used
		var fixQuery = function (requestBuilder) {
			var built = requestBuilder.build();
			built.sort = !built.sort ? [] : built.sort;
			built.sort.push({ a: 'id', s: 'A' });
			return built;
		};

		Ctrl.showFlashButton = false;
		Ctrl.quickDateFilters = 'Date';
		const weekFilter =
			currentDay >= 4 || currentDay === 0 // Thursday and bigger or Sunday
				? { value: 'nextWeek', title: $translate.instant('date.nextWeek') }
				: { value: 'currentWeek', title: $translate.instant('ads.graphSelect.currentWeek') };
		Ctrl.quickDateFilterOptions = [
			{ value: 'untilToday', title: $translate.instant('calendar.today') },
			{ value: 'nextDay', title: $translate.instant('date.nextDay') },
			weekFilter
		];
		Ctrl.quickActivityPriorityFilters = Ctrl.hasPriority ? 'Priority' : null;
		Ctrl.quickActivityPriorityFilterOptions = [
			{
				value: ACTIVITY_PRIORITY.NONE,
				title: $translate.instant('activityList.prioToggleAll'),
				icon: 'list',
				tooltip: $translate.instant('activityList.prioToggleAll'),
				tooltipPosition: 'top'
			},
			{
				value: ACTIVITY_PRIORITY.HIGH,
				title: $translate.instant('activityList.prioTogglePriority'),
				icon: 'flag',
				tooltip: $translate.instant('activityList.prioTogglePriority'),
				tooltipPosition: 'top'
			}
		];

		Ctrl.openFlashFromHeader = function () {
			// -1 will make the flash take the first result as it is an invalid id.
			$scope.$root.$broadcast('openFlash', { currentId: -1, filter: uniqueCurrent });
		};
		function seeIfFileterHaveActiveActivities() {
			var activeFilter = angular.copy(uniqueCurrent);
			// May result in two closeDate=eq:null filters (but who cares)

			if (activeFilter && activeFilter.q) {
				activeFilter.q.push({ a: 'closeDate', c: 'eq', v: null });
				activeFilter.limit = 0;
			}

			setTimeout(function () {
				getEntries(activeFilter)
					.then(function (res) {
						if (
							FeatureHelper.isAvailable(FeatureHelper.Feature.FLASH) &&
							res &&
							res.metadata &&
							res.metadata.total
						) {
							Ctrl.showFlashButton = true;
						} else {
							Ctrl.showFlashButton = false;
						}
					})
					.catch(e => logError(e, 'Failed to get entries'));
			}, 500);
		}

		// The getter function {listView} will need
		Ctrl.getEntries = function (query, requestBuilder) {
			currentRb = requestBuilder;
			currentQuery = fixQuery(requestBuilder);
			uniqueCurrent = angular.copy(currentQuery);
			Ctrl.total = 0;

			return getEntries(currentQuery)
				.then(function (res) {
					seeIfFileterHaveActiveActivities();

					Ctrl.total = res.metadata ? res.metadata.total : res.data.length;
					activities = res.data;

					return res;
				})
				.then(res => getNumberOfDelayedEntries().then(() => res));
		};

		Ctrl.init = function () {
			// Set customer it now when we know we have it
			customerId = AppService.getCustomerId();

			// Document templates
			documentTemplates = AppService.getDocumentTemplates('activity');

			Ctrl.addBtnLabel =
				$translate.instant('default.add') + ' ' + $translate.instant('default.activity').toLowerCase();

			Ctrl.customFields = AppService.getCustomFields('activity');

			Ctrl.listOpts = {
				type: 'activity',
				hasCheck: true,
				editable: true,
				placeholderKey: 'activity',
				hasDocumentTemplates: !!(documentTemplates && documentTemplates.length),
				openFlash: function (item) {
					$scope.$root.$broadcast('openFlash', { currentId: item.id, filter: uniqueCurrent });
				},
				clickedEntry: function (activity) {
					$upModal.open('editActivity', { id: activity.id });
				},
				editOrder: function (order, e) {
					e.stopPropagation();
					e.nativeEvent.stopImmediatePropagation();

					var type = order.probability === 0 || order.probabilit === 100 ? 'order' : 'opportunity';
					$upModal.open('editOrder', {
						id: order.id,
						customerId: customerId,
						type: type
					});
				},
				removeEntry: function (activity, fromDropdown) {
					if (fromDropdown) {
						// Confirm
						if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
							openModal('Alert', {
								title:
									$translate.instant('default.remove') +
									' ' +
									$translate.instant('default.activity').toLowerCase(),
								body: 'confirm.removeActivity',
								confirmButtonText: 'default.remove',
								headerIcon: 'warning',
								onClose: confirmed => {
									if (confirmed) {
										Activity.customer(customerId)['delete'](activity);
									}
								}
							});
							return;
						}

						// eslint-disable-next-line promise/catch-or-return
						return $upModal
							.open('errorConfirm', {
								title:
									$translate.instant('default.remove') +
									' ' +
									$translate.instant('default.activity').toLowerCase(),
								body: 'confirm.removeActivity',
								resolveTrue: 'default.remove',
								icon: 'fa-warning'
							})
							.then(function () {
								// Action -> notify
								return Activity.customer(customerId)['delete'](activity);
							});
					} else {
						return Activity.customer(customerId)['delete'](activity);
					}
				},
				createDocument: function (activity) {
					if (Tools.FeatureHelper.hasSoftDeployAccess('CREATE_DOCUMENT_REACT')) {
						openModal('CreateDocumentModal', {
							type: 'activity',
							id: activity.id,
							accountId: activity.client.id,
							templates: documentTemplates
						});
					} else {
						$upModal.open('createDocument', {
							type: 'activity',
							id: activity.id,
							accountId: activity.client.id,
							templates: documentTemplates
						});
					}
				},
				closeActivity: function (activity) {
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_MOVE_OR_CLOSE_ACTIVITY')) {
						openModal('MoveOrCloseActivity', {
							icon: 'check-square-o',
							notes: activity.notes,
							description: activity.description,
							isClose: true,
							onClose: closeData => {
								if (closeData) {
									const data = {
										id: activity.id,
										closeDate: new Date(),
										notes: closeData.notes
									};

									Activity.customer(customerId).save(data, {
										updateSuccessBody: 'default.activityClosed'
									});
									if (closeData.followUp) {
										const followUpData = Activity.new();
										followUpData.client = activity.client;
										followUpData.users = activity.users;
										followUpData.contacts = activity.contacts;
										followUpData.notes = closeData.notes;
										followUpData.description = activity.description;
										followUpData.activityType = { id: closeData.activityType };
										followUpData.date = closeData.time
											? closeData.date
											: moment(closeData.date).format('YYYY-MM-DD');

										Activity.customer(customerId).save(followUpData, {
											updateSuccessBody: 'default.didCreateActivity'
										});
									}
								}
							}
						});
					} else {
						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('moveOrCloseActivity', {
								icon: 'fa-check-square-o',
								id: activity.id,
								notes: activity.notes,
								description: activity.description,
								isClose: true
							})
							.then(function (res) {
								var data = {
									id: activity.id,
									closeDate: new Date(),
									notes: res.notes
								};

								Activity.customer(customerId).save(data, {
									updateSuccessBody: 'default.activityClosed'
								});
								if (res.followUp) {
									var followUpData = Activity.new();
									followUpData.client = activity.client;
									followUpData.users = activity.users;
									followUpData.contacts = activity.contacts;
									followUpData.notes = res.notes;
									followUpData.description = activity.description;
									followUpData.activityType = { id: res.activityType };
									followUpData.date = res.time ? res.Date : moment(res.Date).format('YYYY-MM-DD');

									Activity.customer(customerId).save(followUpData, {
										updateSuccessBody: 'default.didCreateActivity'
									});
								}
							});
					}
				},
				moveActivity: function (activity) {
					if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_MOVE_OR_CLOSE_ACTIVITY')) {
						openModal('MoveOrCloseActivity', {
							icon: 'long-arrow-right',
							notes: activity.notes,
							description: activity.description,
							isMove: true,
							onClose: closeData => {
								if (closeData) {
									const data = {
										id: activity.id,
										notes: closeData.notes,
										date: closeData.time
											? closeData.date
											: moment(closeData.date).format('YYYY-MM-DD')
									};

									Activity.customer(customerId).save(data, {
										updateSuccessBody: 'default.moveActivity'
									});
								}
							}
						});
					} else {
						// eslint-disable-next-line promise/catch-or-return
						$upModal
							.open('moveOrCloseActivity', {
								icon: 'fa-long-arrow-right',
								id: activity.id,
								notes: activity.notes,
								description: activity.description,
								isMove: true
							})
							.then(function (res) {
								var data = {
									id: activity.id,
									notes: res.notes,
									date: res.time ? res.Date : moment(res.Date).format('YYYY-MM-DD')
								};

								Activity.customer(customerId).save(data, { updateSuccessBody: 'default.moveActivity' });
							});
					}
				},
				setPriority: function (item) {
					const { priority } = item;

					return Activity.customer(customerId).save({
						id: item.id,
						priority: priority ? ACTIVITY_PRIORITY.NONE : ACTIVITY_PRIORITY.HIGH
					});
				}
			};

			Ctrl.multiActions = MultiRunnerActions.get(MultiRunnerActions.type.ACTIVITY);
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(Ctrl.init);
	}
]);
