import { Headline } from '@upsales/components';
import React from 'react';
import bemClass from '@upsales/components/Utils/bemClass';
import GroupTree from 'Components/Modals/CreateNewAccount/GroupTree';

import './GroupTreeModal.scss';
import FindProspectsBranchSubtitle from '../../../babel/domain/crm/client/findProspects/FindProspectsBranchSubtitle';
import CreditsafeClient from 'App/resources/Model/CreditsafeClient';
import CompanyGroup from 'App/resources/Model/CompanyGroup';

type GroupTreeModalProps = {
	account?: CreditsafeClient;
	companyGroup?: CompanyGroup;
	resolve: () => void;
};

const GroupTreeModal: React.FC<GroupTreeModalProps> = ({ account, companyGroup, resolve }) => {
	const classes = new bemClass('GroupTreeModal');

	const scrollToSelector = (selector: string) => {
		const node: any = document.querySelector('.GroupTreeModal__group-tree ' + selector);
		node.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};

	const closeModal = () => {
		resolve();
	};

	const actions = {
		goToAccount: (accountId: number) => Tools.$state.go('account.dashboard', { id: accountId })
	};

	return (
		<div className={classes.b()}>
			<button className="exit-button" onClick={closeModal}>
				<i className="fa fa-times" />
			</button>
			<div className={classes.elem('content').b()}>
				<div className="box-shadow">
					<div className="header-content full-screen-modal-content">
						<Headline size="sm">{account?.name}</Headline>
						{companyGroup ? (
							<FindProspectsBranchSubtitle
								data={companyGroup.data}
								rootDuns={companyGroup.rootDuns}
								unknownTotal={companyGroup.unknownTotal}
								total={companyGroup.total}
								onClick={container => {
									if (container === 'found') {
										scrollToSelector('.found-tree');
									} else {
										scrollToSelector('.unknown-tree');
									}
								}}
							/>
						) : null}
					</div>
				</div>
				<div className="search-results">
					<div className="full-screen-modal-content">
						{companyGroup && account ? (
							<GroupTree
								className={classes.elem('group-tree').b()}
								rootDuns={account.dunsNo}
								groupAccount={companyGroup}
								tree={companyGroup.tree}
								unknowns={companyGroup.unknowns}
								dataSourceId="prospecting"
								idField="prospectingId"
								viewOnly={true}
								actions={actions}
							/>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default GroupTreeModal;
