import Attribute, { Type, Attr, DisplayType } from './Attribute';

const DirectorAttributes = {
	name: Attribute({
		title: 'default.name',
		field: 'name',
		sortable: 'name',
		type: Type.String,
		displayKey: ['name', 'id'],
		selectableColumn: true
	}),
	role: Attribute({
		title: 'default.role',
		field: 'roles',
		displayKey: 'roles',
		type: Type.String,
		displayType: DisplayType.Text,
		selectableColumn: true
	}),
	clientName: Attribute({
		title: 'account',
		field: 'clientName',
		displayKey: 'clientName',
		type: Type.String,
		displayType: DisplayType.Text,
		sortable: 'clientName',
		selectableColumn: true
	}),
	memberSince: Attribute({
		title: 'account.memberSince',
		field: 'firstSeen',
		displayKey: 'fromDate',
		type: Type.Date,
		displayType: DisplayType.Year,
		selectableColumn: true
	})
} as { [key: string]: Attr };

export default DirectorAttributes;
