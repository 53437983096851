import { SubscriptionGroupState } from '../EditSubscription/Context/SubscriptionGroupState';
import { mapOldAgreement, mergeOldAndNewAgreements } from 'App/helpers/subscriptionHelper';
import Client, { ClientIdName, ClientIdNamePriceList } from 'App/resources/Model/Client';
import RequestBuilder, { comparisonTypes } from 'Resources/RequestBuilder';
import AgreementGroup from 'App/resources/Model/AgreementGroup';
import { getFilterFn } from 'Components/ListTab/ListTabHelper';
import AgreementGroupResource from 'Resources/AgreementGroup';
import { ContactIdName } from 'App/resources/Model/Contact';
import BemClass from '@upsales/components/Utils/bemClass';
import Agreement from 'App/resources/Model/Agreement';
import React, { useEffect, useState } from 'react';
import { useSoftDeployAccess } from '../hooks';
import CardsHeader from './CardsHeader';
import CardParents from './CardParents';

import './SubscriptionCards.scss';

const filterFn = getFilterFn(true);

type Props = {
	subAccountIds?: number[];
	contact?: ContactIdName;
	hasMRR?: boolean;
	showClientName?: boolean;
} & ({ client: ClientIdNamePriceList; clientId?: never } | { client?: never; clientId: number });

export type OldOrNewAgreement = (
	| AgreementGroup
	| {
			agreements: Agreement[];
			currentAgreement: Agreement;
			client: Pick<Client, 'id' | 'name'>;
			modDate: string;
			regDate: string;
			id?: number;
	  }
) & { state?: SubscriptionGroupState; tempId?: string };

const SubscriptionCards = ({
	client,
	clientId: initalClientId,
	contact,
	hasMRR = false,
	subAccountIds = [],
	showClientName = false
}: Props) => {
	const classNames = new BemClass('SubscriptionCards');
	const hasSubAccounts = useSoftDeployAccess('SUB_ACCOUNTS');
	const hasGroupBonanza = useSoftDeployAccess('GROUP_BONANZA');
	const [agreementGroups, setAgreementGroups] = useState<OldOrNewAgreement[]>([]);
	const [showInactive, setShowInactive] = useState(false);
	const clientId = (client?.id || initalClientId) as number;

	useEffect(() => {
		const fetchGroups = async () => {
			const { data } = await AgreementGroupResource.find({ clientId: clientId });
			const clientIds = [clientId];
			if ((hasGroupBonanza || hasSubAccounts) && subAccountIds.length) {
				clientIds.push(...subAccountIds);
			}
			const filters = new RequestBuilder();

			const clientOr = filters.orBuilder();
			clientOr.next();
			filterFn(clientOr, clientIds);
			clientOr.next();
			clientOr.addFilter(Tools.Agreement.attr.clientConnection, comparisonTypes.Equals, clientIds);
			clientOr.done();

			const { data: oldAgreements } = await Tools.Agreement.customer(Tools.AppService.getCustomerId()).find(
				filters.build()
			);

			const sorted = mergeOldAndNewAgreements(data, oldAgreements, clientId, contact?.id);

			setAgreementGroups(sorted);
		};
		fetchGroups();
	}, []);

	useEffect(() => {
		const isThisAccountOrSubaccounts = (clientEntity: ClientIdName | null) => {
			return (
				clientEntity &&
				(clientEntity.id === clientId || (hasSubAccounts && subAccountIds.includes(clientEntity.id)))
			);
		};
		const groupUpdated = (e: unknown, agreementGroup: OldOrNewAgreement) => {
			const groupIdx = agreementGroups.findIndex(
				({ id, tempId }) => id === agreementGroup.id || (!!tempId && tempId === agreementGroup.tempId)
			);
			if (groupIdx === -1) {
				return;
			}
			if (
				!isThisAccountOrSubaccounts(agreementGroup.client) &&
				!isThisAccountOrSubaccounts(agreementGroup.currentAgreement?.clientConnection)
			) {
				setAgreementGroups(agreementGroups.filter(group => group.id !== agreementGroup.id));
				return;
			}
			setAgreementGroups(Object.assign([], agreementGroups, { [groupIdx]: agreementGroup }));
		};
		const groupDeleted = (e: unknown, agreementGroup: { id: number; tempId?: string }) => {
			setAgreementGroups(
				agreementGroups.filter(
					group => group.id !== agreementGroup.id && group.tempId !== agreementGroup.tempId
				)
			);
		};
		const groupAdded = (e: unknown, agreementGroup: AgreementGroup) => {
			if (!isThisAccountOrSubaccounts(agreementGroup.client)) {
				return;
			}

			setAgreementGroups(currentGroups => [agreementGroup, ...currentGroups]);
		};

		const agreementUpdated = (e: unknown, agreement: Agreement) => {
			const groupIdx = agreementGroups.findIndex(
				group => group.currentAgreement.id === agreement.id && !group.id
			);
			if (groupIdx === -1) {
				return;
			}
			setAgreementGroups(Object.assign([], agreementGroups, { [groupIdx]: mapOldAgreement([agreement]) }));
		};
		const agreementDeleted = (e: unknown, agreement: { id: number }) => {
			setAgreementGroups(agreementGroups.filter(group => group.currentAgreement.id !== agreement.id));
		};
		const agreementAdded = (e: unknown, agreement: Agreement & { tempId?: string }) => {
			if (
				agreement.agreementGroupId ||
				agreement.tempId ||
				agreement.client.id !== clientId ||
				agreementGroups.find(a => a.currentAgreement.id === agreement.id)
			) {
				return;
			}
			setAgreementGroups(currentGroups => [mapOldAgreement([agreement]), ...currentGroups]);
		};

		const listeners = [
			Tools.$rootScope.$on('agreementGroup.updated', groupUpdated),
			Tools.$rootScope.$on('agreementGroup.deleted', groupDeleted),
			Tools.$rootScope.$on('agreementGroup.added', groupAdded),
			Tools.$rootScope.$on('agreement.updated', agreementUpdated),
			Tools.$rootScope.$on('agreement.deleted', agreementDeleted),
			Tools.$rootScope.$on('agreement.added', agreementAdded)
		];

		return () => {
			listeners.forEach(listener => listener());
		};
	}, [agreementGroups]);

	return (
		<div className={classNames.b()}>
			<CardsHeader
				client={client}
				contact={contact}
				showInactive={showInactive}
				setShowInactive={setShowInactive}
				agreementGroups={agreementGroups}
				hasMRR={hasMRR}
			/>
			{agreementGroups?.length ? (
				<CardParents
					client={client}
					hasMRR={hasMRR}
					showInactive={showInactive}
					showClientName={showClientName}
					agreementGroups={agreementGroups}
				/>
			) : null}
		</div>
	);
};

export default SubscriptionCards;
