import BemClass from '@upsales/components/Utils/bemClass';
import { useTranslation } from 'Components/Helpers/translate';
import { Block, Button, Headline, Icon, Input, Text } from '@upsales/components';
import React, { useRef, useState } from 'react';
import ResetPasswordResource from 'App/resources/ResetPassword';
import { DefaultButton } from '@upsales/components/Buttons';
import { CancelablePromise, makeCancelable } from '@upsales/components/Utils/CancelablePromise';
import LoginError, { LoginErrors } from '../LoginError';

type Props = {
	onBack: () => void;
};

const LoginRequestPasswordReset = ({ onBack }: Props) => {
	const classes = new BemClass('LoginRequestPasswordReset');
	const { t } = useTranslation();
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [sent, setSent] = useState(false);
	const [error, setError] = useState<null | LoginErrors>(null);
	const requestPromise = useRef<null | CancelablePromise<any>>(null);

	const requestResetLink = () => {
		setLoading(true);
		setError(null);
		requestPromise.current = makeCancelable(ResetPasswordResource.requestPasswordResetLink(email));

		requestPromise.current.promise
			.then(() => {
				setLoading(false);
				setSent(true);
			})
			.catch(err => {
				setLoading(false);
				setError(LoginErrors.REQUEST_PASSWORD_RESET_ERROR);
			});
	};

	return (
		<div className={classes.b()}>
			<form
				onSubmit={e => {
					e.preventDefault();
					requestResetLink();
				}}
			>
				<Headline size="md" color="super-light-green">
					{t('login.passwordReset')}
				</Headline>
				<Block space="mtxl">
					{sent ? (
						<Text color="super-light-green">{t('login.passwordSent')}</Text>
					) : (
						<Input
							size="lg"
							placeholder={t('default.email')}
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
					)}
				</Block>
				{error ? <LoginError error={error} onClear={() => setError(null)} /> : null}
				{sent ? null : (
					<Block space="mtxl">
						<DefaultButton submit block size="xl" loading={loading} disabled={!email}>
							{t('mail.send')}
						</DefaultButton>
					</Block>
				)}
			</form>
			<Block space="mtxl">
				<Button
					type="link"
					color="super-light-green"
					block
					size="lg"
					disabled={loading}
					onClick={() => {
						setSent(false);
						setEmail('');
						onBack();
					}}
				>
					<Icon name="angle-left" space="mrs" />
					{`${t('login.backTo')} ${t('default.login').toLowerCase()}`}
				</Button>
			</Block>
		</div>
	);
};

export default LoginRequestPasswordReset;
