'use strict';

import openModal from 'App/services/Modal';
import ApiKeys from 'Components/Admin/ApiKeys';

angular.module('domain.admin').controller('ListApiKeys', [
	'AppService',
	'$upModal',
	'$scope',
	'ApiKey',
	'$translate',
	function (AppService, $upModal, $scope, ApiKey, $translate) {
		var AdminSubCtrl = this;
		var AdminCtrl = $scope.AdminCtrl;

		AdminSubCtrl.rootData = AdminCtrl.getRootObject();
		AdminSubCtrl.rootData.pageComponent = ApiKeys;
		AdminSubCtrl.rootData.pageLoading = true;
		AdminSubCtrl.rootData.pageData = {
			keys: []
		};

		$scope.$on('apiKey.added', function (e, added) {
			AdminSubCtrl.rootData.pageData.keys.push(added);
		});

		$scope.$on('apiKey.updated', function (e, updated) {
			var foundIndex = _.findIndex(AdminSubCtrl.rootData.pageData.keys, { id: updated.id });
			if (foundIndex !== -1) {
				AdminSubCtrl.rootData.pageData.keys.splice(foundIndex, 1, updated);
			} else {
				AdminSubCtrl.rootData.pageData.keys.push(updated);
			}
		});

		$scope.$on('apiKey.deleted', function (e, deleted) {
			_.remove(AdminSubCtrl.rootData.pageData.keys, { id: deleted.id });
		});

		AdminSubCtrl.rootData.editKey = function (apiKey) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('GENERATE_API_KEY_REACT')) {
				openModal('ApiKey', { key: apiKey });
			} else {
				$upModal.open('apiKey', { key: apiKey });
			}
		};

		AdminSubCtrl.rootData.removeKey = function (apiKey) {
			if (Tools.FeatureHelper.hasSoftDeployAccess('REACT_ALERT_MODAL')) {
				openModal('RemoveAlert', {
					title: 'admin.apiKey',
					body: 'confirm.removeApiKey',
					onClose: confirmed => {
						if (confirmed) {
							ApiKey.delete(apiKey);
						}
					}
				});
				return;
			}

			// eslint-disable-next-line promise/catch-or-return
			$upModal
				.open('warningConfirm', {
					title:
						$translate.instant('default.remove') + ' ' + $translate.instant('admin.apiKey').toLowerCase(),
					body: 'confirm.removeApiKey',
					resolveTrue: 'default.remove',
					resolveTrueBtnClass: 'btn-red',
					icon: 'fa-warning'
				})
				.then(function () {
					ApiKey.delete(apiKey);
				});
		};

		var getKeys = function () {
			AdminSubCtrl.rootData.pageData.error = false;

			return ApiKey.find({})
				.then(function (res) {
					AdminSubCtrl.rootData.pageData.keys = res.data;
				})
				.catch(function () {
					AdminSubCtrl.rootData.pageData.error = true;
				});
		};

		var init = function () {
			// eslint-disable-next-line promise/catch-or-return
			getKeys().finally(function () {
				AdminSubCtrl.rootData.pageLoading = false;
			});
		};

		// eslint-disable-next-line promise/catch-or-return
		AppService.loadedPromise.then(init);
	}
]);
