import { openDrawer } from 'Services/Drawer';
import commandPermissions from './commandPermissions';
import openModal from 'App/services/Modal';
import history from 'App/pages/routes/history';
import { openCreateForm } from 'Components/CreateForm/CreateForm';

type Command = {
	name: string;
	action: () => void;
	altCommands?: string[];
	unavailable?: boolean;
};

export const getCommandMap = () => {
	const commandMap = new Map<string, Command>();
	//#region Navigation
	//#region Sales
	commandMap.set(':go tasks', {
		name: 'Go to tasks',
		action: () => Tools.$state.go('react-root-todo'),
		altCommands: [':gtsk'],
		unavailable: !commandPermissions('tasks')
	});
	commandMap.set(':go activities', {
		name: 'Go to activities',
		action: () => Tools.$state.go('activities'),
		altCommands: [],
		unavailable: commandPermissions('tasks')
	});
	commandMap.set(':go accounts', {
		name: 'Go to accounts',
		action: () => Tools.$state.go('accounts'),
		altCommands: [],
		unavailable: !commandPermissions('accountsAndContacts')
	});
	commandMap.set(':go findProspects', {
		name: 'Find prospects',
		action: () => Tools.$state.go('findProspects'),
		altCommands: []
	});
	commandMap.set(':go orders', { name: 'Go to orders', action: () => Tools.$state.go('orders'), altCommands: [] });
	commandMap.set(':go sentMails', {
		name: 'Go to sent mails',
		action: () => Tools.$state.go('listMail'),
		altCommands: []
	});
	commandMap.set(':go agreements', {
		name: 'Go to agreements',
		action: () => Tools.$state.go('agreements'),
		altCommands: [],
		unavailable: !commandPermissions('agreements')
	});
	commandMap.set(':go campaigns', {
		name: 'Go to campaigns',
		action: () => Tools.$state.go('campaigns'),
		altCommands: []
	});
	commandMap.set(':go accountGrowth', {
		name: 'Go to account growth',
		action: () => Tools.$state.go('react-root-accountGrowth'),
		altCommands: [],
		unavailable: !commandPermissions('accountGrowth')
	});
	//#endregion

	//#region Marketing
	commandMap.set(':go leads', {
		name: 'Go to leads',
		action: () => history.push('/leads2'),
		altCommands: [],
		unavailable: !commandPermissions('leads')
	});
	commandMap.set(':go visitors', {
		name: 'Go to visitors',
		action: () => Tools.$state.go('visitors'),
		altCommands: [],
		unavailable: !commandPermissions('visitors')
	});
	commandMap.set(':go forms', {
		name: 'Go to forms',
		action: () => Tools.$state.go('react-root-forms'),
		altCommands: [],
		unavailable: !commandPermissions('forms')
	});
	commandMap.set(':go formSubmits', {
		name: 'Go to form submits',
		action: () => Tools.$state.go('react-root-formSubmits'),
		altCommands: [],
		unavailable: !commandPermissions('formSubmits')
	});
	commandMap.set(':go landingPages', {
		name: 'Go to landing pages',
		action: () => Tools.$state.go('react-root-landingpages'),
		altCommands: [],
		unavailable: !commandPermissions('landingPages')
	});
	commandMap.set(':go segments', {
		name: 'Go to segments',
		action: () => history.push('/segments'),
		altCommands: [],
		unavailable: !commandPermissions('segments')
	});
	commandMap.set(':go mailCampaigns', {
		name: 'Go to mail campaigns',
		action: () => history.push('/mail-campaigns'),
		altCommands: [],
		unavailable: !commandPermissions('mailCampaigns')
	});
	commandMap.set(':go templates', {
		name: 'Go to templates',
		action: () => Tools.$state.go('react-root-mailtemplates'),
		altCommands: [],
		unavailable: !commandPermissions('templates')
	});
	commandMap.set(':go advertising', {
		name: 'Go to advertising',
		action: () => Tools.$state.go('listAds'),
		altCommands: [],
		unavailable: !commandPermissions('advertising')
	});
	//#endregion

	//#region Analytics
	commandMap.set(':go reportCenter', {
		name: 'Go to Reportcenter',
		action: () => Tools.$state.go('react-root-reportcenter'),
		altCommands: [':go rc', ':grc']
	});
	commandMap.set(':go insights', {
		name: 'Go to Insights',
		action: () => Tools.$state.go('looker'),
		altCommands: [],
		unavailable: !commandPermissions('insights')
	});
	//#endregion

	//#region Admin
	commandMap.set(':go admin', {
		name: 'Go to admin',
		action: () => Tools.$state.go('administration.dashboard'),
		altCommands: []
	});
	commandMap.set(':go billing', {
		name: 'Go to billing',
		action: () => Tools.$state.go('administration.billing'),
		altCommands: [],
		unavailable: !commandPermissions('billing')
	});
	commandMap.set(':go profile', {
		name: 'Go to your profile',
		action: () => Tools.$state.go('administration.profile'),
		altCommands: []
	});
	commandMap.set(':go targets', {
		name: 'Go to targets',
		action: () =>
			Tools.$state.go(
				`administration.${
					!(
						Tools.FeatureHelper.hasSoftDeployAccess('GOALS') &&
						Tools.FeatureHelper.hasSoftDeployAccess('TODO_LIST')
					)
						? 'activityquota'
						: Tools.FeatureHelper.isAvailable(Tools.FeatureHelper.Feature.RECURRING_ORDER)
						? 'subscriptionquota'
						: 'salesquota'
				}`
			),
		altCommands: [],
		unavailable: !commandPermissions('targets')
	});
	commandMap.set(':go companyProfile', {
		name: 'Go to company profile',
		action: () => Tools.$state.go('administration.accountProfile'),
		altCommands: []
	});
	//#endregion
	//#endregion

	//#region Quick-create
	commandMap.set(':create account', {
		name: 'Create new account',
		action: () => Tools.$upModal.open('createAccount'),
		altCommands: [],
		unavailable: !commandPermissions('accountsAndContacts')
	});
	commandMap.set(':create contact', {
		name: 'Create new contact',
		action: () => Tools.$upModal.open('editContact'),
		altCommands: [],
		unavailable: !commandPermissions('accountsAndContacts')
	});
	commandMap.set(':create campaign', {
		name: 'Create new campaign',
		action: () => Tools.$upModal.open('editCampaign'),
		altCommands: []
	});
	commandMap.set(':create mail', {
		name: 'Create new mail',
		action: () =>
			Tools.FeatureHelper.hasSoftDeployAccess('NEW_MAIL')
				? openDrawer('NewSingleMail')
				: Tools.$upModal.open('sendEmail', { type: 'mail' }),
		altCommands: []
	});
	commandMap.set(':create todo', {
		name: 'Create new todo',
		action: () => openDrawer('CreateTodo'),
		altCommands: [],
		unavailable: !commandPermissions('tasks')
	});
	commandMap.set(':create activity', {
		name: 'Create new activity',
		action: () => Tools.$upModal.open('editActivity'),
		altCommands: [],
		unavailable: commandPermissions('tasks')
	});
	commandMap.set(':create phonecall', {
		name: 'Create new phone call',
		action: () => openDrawer('CreateCall'),
		altCommands: []
	});
	commandMap.set(':create appointment', {
		name: 'Create new appointment',
		action: () => Tools.$upModal.open('editAppointment'),
		altCommands: []
	});
	commandMap.set(':create opportunity', {
		name: 'Create new opportunity',
		action: () => Tools.$upModal.open('editOrder', { type: 'opportunity' }),
		altCommands: [],
		unavailable: !commandPermissions('opportunity')
	});
	commandMap.set(':create order', {
		name: 'Create new order',
		action: () => Tools.$upModal.open('editOrder', { type: 'order' }),
		altCommands: []
	});
	commandMap.set(':create agreement', {
		name: 'Create new agreement',
		action: () => {
			if (!Tools.FeatureHelper.hasSoftDeployAccess('SUBSCRIPTION_MODAL')) {
				return Tools.$upModal.open('editAgreement');
			}

			openModal('CreateSubscription', { createdFrom: 'shortCommand' });
		},
		altCommands: [':cag'],
		unavailable: !commandPermissions('agreements')
	});
	commandMap.set(':create mailCampaign', {
		name: 'Create new mail campaign',
		action: () => Tools.$upModal.open('createGroupMail'),
		altCommands: [],
		unavailable: !commandPermissions('mailCampaigns')
	});
	commandMap.set(':create form', {
		name: 'Create new form',
		action: () => openCreateForm({ formType: 'form' }),
		altCommands: [],
		unavailable: !commandPermissions('forms')
	});
	commandMap.set(':create mailTemplate', {
		name: 'Create new mail template',
		action: () => Tools.$upModal.open('createMailTemplate'),
		altCommands: [],
		unavailable: !commandPermissions('templates')
	});
	commandMap.set(':create landingpage', {
		name: 'Create new landing page',
		action: () => openCreateForm({ formType: 'landing' }),
		altCommands: [],
		unavailable: !commandPermissions('landingPages')
	});
	commandMap.set(':create utm', {
		name: 'Create new utm link',
		action: () => openDrawer('UtmLinkGenerator'),
		altCommands: [],
		unavailable: !commandPermissions('utm')
	});
	commandMap.set(':create addonCampaign', {
		name: 'Create addon campaign',
		action: () => Tools.$state.go('editAd'),
		altCommands: [],
		unavailable: !commandPermissions('advertising')
	});
	//#endregion

	return commandMap;
};

export const handleCommand = (input: string) => {
	const commands = getCommandMap();
	const command = commands.get(input);
	const action = command?.action;
	if (action) return action();
};
