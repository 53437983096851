angular.module('domain.contact', ['upResources', 'security.authorization', 'ui.router']).config([
	'$upModalProvider',
	'$stateProvider',
	function ($upModalProvider, $stateProvider) {
		var templateHelper = function (view) {
			return function ($http, AppService) {
				return AppService.loadedPromise.then(function () {
					var url = require('App/upsales/domain/contact/views/' + view + '.html?file');

					var promise = $http.get(url).then(function (res) {
						return res.data;
					});

					return promise;
				});
			};
		};

		var controllerHelper = function (FeatureHelper, view, isAs) {
			return isAs ? view + ' as ' + isAs : view;
		};

		var dashBoardViewHelper = function () {
			return function (FeatureHelper, $http, AppService) {
				return AppService.loadedPromise.then(function () {
					var url;
					if (
						FeatureHelper.isAvailableProduct(FeatureHelper.Product.MA) &&
						!FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM)
					) {
						url = require('App/upsales/domain/contact/views/contactMarket.html?file');
					} else {
						url = require('App/upsales/domain/contact/views/contactDashboard.html?file');
					}
					var promise = $http.get(url).then(function (res) {
						return res.data;
					});

					return promise;
				});
			};
		};

		var sectionFn = function (accountSelf, self, $stateParams, location) {
			if (
				(!accountSelf.products.crm && accountSelf.features.companiesAndContacts) ||
				$stateParams.fromSegment ||
				(location.$$search && location.$$search.fromSegment)
			) {
				return 'market';
			}
			return 'sale';
		};

		$stateProvider
			.state('contact', {
				abstract: true,
				url: '/:customerId/contacts/:id/',
				//controller: 'Contact as ContactCtrl',
				templateProvider: templateHelper('contact'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'Contact', 'ContactCtrl');
					}
				],
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					],
					columnTemplate: [
						'TemplateCacher',
						function (TemplateCacher) {
							return TemplateCacher.cache('/upsales/common/components/columnTemplates/listContacts.html');
						}
					],
					meta: [
						'$stateParams',
						'ContactMeta',
						function ($stateParams, ContactMeta) {
							return ContactMeta($stateParams);
						}
					],
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.COMPANIES_AND_CONTACTS);
						}
					]
				}
			})
			.state('contact.dashboard', {
				url: '',
				//controller: 'ContactDashboard',
				templateProvider: dashBoardViewHelper(),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						if (
							FeatureHelper.isAvailableProduct(FeatureHelper.Product.MA) &&
							!FeatureHelper.isAvailableProduct(FeatureHelper.Product.CRM)
						) {
							return 'ContactMarket as ContactMarket';
						} else {
							return 'ContactDashboard as ContactDashboard';
						}
					}
				],
				sectionFn: sectionFn,
				section: 'sale'
			})
			.state('contact.activities', {
				url: 'activities/',
				//controller: 'ContactActivities',
				templateProvider: templateHelper('contactActivities'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'ContactActivities');
					}
				],
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ACTIVITIES_AND_APPOINTMENTS);
						}
					]
				}
			})
			.state('contact.opportunities', {
				url: 'opportunities/',
				//controller: 'ContactOpportunities',
				templateProvider: templateHelper('contactOpportunities'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'ContactOpportunities');
					}
				],
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.PIPELINE);
						}
					]
				}
			})
			.state('contact.order', {
				url: 'order/',
				//controller: 'ContactOrder as ContactOrderCtrl',
				templateProvider: templateHelper('contactOrder'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'ContactOrder', 'ContactOrderCtrl');
					}
				],
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.ORDERS);
						}
					]
				}
			})
			.state('contact.market', {
				url: 'market/',
				//controller: 'ContactMarket',
				templateProvider: templateHelper('contactMarket'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'ContactMarket');
					}
				],
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailableProductPromise(FeatureHelper.Product.MA);
						}
					]
				}
			})
			.state('contact.relations', {
				url: 'relations/',
				//controller: 'ContactRelations',
				templateProvider: templateHelper('contactRelations'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'ContactRelations');
					}
				],
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					isAvailable: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.COMPANY_RELATIONS);
						}
					]
				}
			})
			.state('contact.files', {
				url: 'files/',
				//controller: 'ContactFiles as ContactFiles',
				templateProvider: templateHelper('contactFiles'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'ContactFiles', 'ContactFiles');
					}
				],
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					hasAccess: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.DOCUMENTS);
						}
					]
				}
			})
			.state('contact.udo', {
				url: 'udo/:udoId',
				//controller: 'ContactUdo as ContactUdoCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				templateProvider: templateHelper('contactUdo'),
				controllerProvider: [
					'FeatureHelper',
					function (FeatureHelper) {
						return controllerHelper(FeatureHelper, 'ContactUdo', 'ContactUdoCtrl');
					}
				],
				resolve: {
					hasAccess: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.UDO);
						}
					]
				}
			})
			.state('contacts', {
				templateUrl: require('App/upsales/common/directives/templates/upListView2.tpl.html?file'),
				url: '/:customerId/contacts/',
				controller: 'ListContacts as listViewHelper',
				params: {
					fromGroupMailEdit: null,
					fromSegment: null,
					fromGroupMailRecipient: null,
					q: null,
					columns: null,
					id: null
				},
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					authUser: [
						'$routeGuard',
						function ($routeGuard) {
							return $routeGuard.requireUser();
						}
					],
					listViews: [
						'AppService',
						function (AppService) {
							return AppService.loadedPromise.then(function () {
								return AppService.getListViews('contact');
							});
						}
					]
				}
			})
			.state('contact.support', {
				url: 'support/',
				templateProvider: templateHelper('contactSupport'),
				controller: 'ContactSupport as ContactSupportCtrl',
				section: 'sale',
				sectionFn: sectionFn,
				resolve: {
					hasAccess: [
						'FeatureHelper',
						function (FeatureHelper) {
							return FeatureHelper.isAvailablePromise(FeatureHelper.Feature.CUSTOMER_SUPPORT);
						}
					]
				}
			});

		$upModalProvider
			.modal('editContact', {
				templateUrl: require('App/upsales/domain/contact/views/editContact.html?file'),
				style: 'form default',
				controller: 'EditContact as EditContact',
				openAnimation: true,
				closeAnimation: true,
				rememberHeight: true,
				resolve: {
					meta: function ($modalParams, EditContactMeta) {
						return EditContactMeta($modalParams);
					}
				}
			})
			.modal('editContactRelation', {
				templateUrl: require('App/upsales/domain/contact/views/editContactRelation.html?file'),
				controller: 'EditContactRelation',
				closeOnCurtain: true
			});

		$upModalProvider
			.modal('UpdateContactMulti', {
				templateUrl: require('App/upsales/domain/contact/views/updateContactAction.html?file'),
				controller: 'UpdateContactMulti',
				style: 'form-md default',
				resolve: {
					meta: function ($modalParams, UpdateContactMultiMeta) {
						return UpdateContactMultiMeta($modalParams);
					}
				}
			})
			.modal('moveContact', {
				templateUrl: require('App/upsales/domain/contact/views/moveContact.html?file'),
				controller: 'MoveContact as MoveContact',
				style: 'confirm default'
			})
			.modal('mergeContacts', {
				templateUrl: require('App/upsales/domain/contact/views/mergeContacts.html?file'),
				controller: 'MergeContacts as MergeContacts',
				style: 'form default',
				resolve: {
					meta: function ($modalParams, MergeContactsMeta) {
						return MergeContactsMeta($modalParams);
					}
				}
			});

		$upModalProvider.modal('exportContactData', {
			template: '<div id="export-contact-data-modal"></div>',
			controller: function ($scope) {
				$scope.$on('modal.ready', function () {
					new window.ExportContactDataCtrl($scope);
				});
			},
			style: 'modal400'
		});
	}
]);
