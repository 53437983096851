'use strict';
import openModal from 'App/services/Modal/Modal';
import documentResource from 'Resources/document';

angular.module('upModals.controllers').controller('CreateDocument', [
	'$scope',
	'$modalParams',
	'$upModal',
	function ($scope, $modalParams, $upModal) {
		var CreateDocument = this;

		// set available templates on scope
		CreateDocument.templates = $modalParams.meta.templates.data;

		// Contacts
		CreateDocument.contacts = $modalParams.meta.contacts.data;

		// If we have preset contactId
		CreateDocument.contactId = $modalParams.contactId;

		// when we select a template
		CreateDocument.select = function (template) {
			var params = {
				entityId: $modalParams.id,
				templateId: template.id,
				type: $modalParams.type,
				name: template.name
			};

			if ($modalParams.accountId) {
				params.accountId = $modalParams.accountId;
			}

			if (CreateDocument.contactId) {
				params.contactId = CreateDocument.contactId;
			}
			$scope.resolve();
			if (Tools.FeatureHelper.hasSoftDeployAccess('PREVIEW_PDF_REACT')) {
				openModal('PreviewPdfModal', {
					isUploadedTemplate: true,
					documentResource: {
						resource: documentResource,
						entityId: params.entityId,
						templateId: params.templateId,
						type: params.type,
						documentName: params.name,
						accountId: params.accountId,
						contact: params.contact,
						contactId: params.contactId
					}
				});
			} else {
				$upModal.open('pdfPreview', params);
			}
		};
	}
]);
